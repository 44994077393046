// // import React from 'react';
// // import { FiChevronRight, FiShoppingCart, FiFileText, FiChevronDown } from 'react-icons/fi';
// // import { useNavigate } from 'react-router-dom';

// // const Sales = () => {
// //     const navigate = useNavigate()

// //   const categories = [
// //     {
// //       title: 'Sales',
// //       icon: <FiShoppingCart className="w-5 h-5 text-blue-600" />,
// //       // color: 'bg-gradient-to-br from-blue-50 to-blue-100',
// //       color: 'bg-gray-100',
// //       borderColor: 'border-blue-200',
// //       items: [
// //         { code: '501', label: 'All Sales' },
// //         { code: '502', label: 'Sales By Fuel Grades' },
// //         { code: '503', label: 'Sales By Country' },
// //         { code: '504', label: 'Sales By State' },
// //         { code: '505', label: 'Sales By Customer' }
// //       ]
// //     },
// //     {
// //       title: 'Invoices',
// //       icon: <FiFileText className="w-5 h-5 text-blue-600" />,
// //       // color: 'bg-gradient-to-br from-blue-50 to-blue-100',
// //       color: 'bg-gray-100',
// //       borderColor: 'border-blue-200',
// //       items: [
// //         { code: '601', label: 'All Invoice Types' },
// //         { code: '602', label: 'Invoice By Customer' },
// //       ]
// //     },
// //   ];

// //   return (
// //     // <div className="w-full max-w-6xl mx-auto px-6">
// //     // <div className="flex items-start space-x-6">
// //     <div className="w-full px-6 py-4">
// //       <div className="max-w-6xl mx-auto space-y-8 custombgColor">
// //       {categories.map((category, idx) => (
// //         <div
// //           key={idx}   className="space-y-2 custombgColor"
// //           // className="flex-none basis-1/3 max-w-[33%] bg-white rounded-lg border border-gray-100 overflow-hidden"
// //         >
// //           <div className={`p-4 custombgColor ${category.color}`}>
// //             {/* <div className="flex items-center space-x-3 mb-4"> */}
// //             <div className="flex items-center space-x-2 text-gray-900 font-semibold text-lg mt-2">

// //               {category.icon}
// //               <h2 className="text-base font-semibold text-gray-900">{category.title}</h2>
// //             </div>
// //             <div className="grid grid-cols-3 gap-2 mt-1 mx-20">
// //               {category.items.map((item, itemIdx) => (
// //                 <div
// //                   key={itemIdx}
// //                   onClick={() => {
// //                     if (item.code === "401") {
// //                       navigate(`/SalesReport`);
// //                     } else if(item.code === "506"){
// //                       navigate(`/invoiceReport`); // 🔴 Navigate when first item is clicked

// //                     }
// //                   }}
// //                   //  className="flex items-center bg-white border border-gray-200 rounded-md p-3 hover:bg-gray-100 transition cursor-pointer"
// //                   className="flex items-center bg-white rounded-md p-3 hover:bg-white hover:shadow-sm transition-all duration-200 cursor-pointer group"
// //                 >
// //                   <div className="w-10 text-gray-500 font-mono text-sm font-medium">{item.code}</div>
// //                   <div className="flex-grow text-gray-700 text-sm">{item.label}</div>
// //  <FiChevronRight className="w-4 h-4 text-gray-400 opacity-0 group-hover:opacity-100 transition-opacity" />  {/* 🟢 react-icons */}
// //                 </div>
// //               ))}
// //             </div>
// //           </div>
// //         </div>
// //       ))}

// //       {/* Placeholder for third column when only two items exist */}
// //       {categories.length === 2 && (
// //         <div className="flex-none basis-1/3 max-w-[33%]"></div>
// //       )}
// //     </div>
// //   </div>

// //   );
// // };

// // export default Sales;

// import React, { useState } from "react";
// import { FiChevronRight, FiShoppingCart, FiFileText } from "react-icons/fi";
// import { useNavigate } from "react-router-dom";

// const Sales = () => {
//   const navigate = useNavigate();
//   const [activeItem, setActiveItem] = useState(null);
//   const [hoveredItem, setHoveredItem] = useState(null);
//   const itemsWithNavigation = ["501", "502", "503", "504", "505", "601", "602"];

//   const categories = [
//     {
//       title: "Sales",
//       icon: <FiShoppingCart className="w-6 h-6 text-blue-600" />,
//       color: "border-blue-200",
//       items: [
//         { code: "501", label: "All Sales" },
//         { code: "502", label: "Sales By Fuel Grades" },
//         { code: "503", label: "Sales By Country" },
//         { code: "504", label: "Sales By State" },
//         { code: "505", label: "Sales By Customer" }
//       ]
//     },
//     {
//       title: "Invoices",
//       icon: <FiFileText className="w-6 h-6 text-blue-600" />,
//       color: "border-blue-200",
//       items: [
//         { code: "601", label: "All Invoice Types" },
//         { code: "602", label: "Invoice By Customer" }
//       ]
//     }
//   ];

//   const handleNavigation = (item) => {
//     // Only set active state for items with navigation
//     if (itemsWithNavigation.includes(item.code)) {
//       setActiveItem(item.code);

//       // Navigate based on the item code
//       switch (item.code) {
//         case "501":
//           navigate(`/SalesReport`);
//           break;
//         case "601":
//           navigate(`/invoiceReport`);
//           break;

//         default:
//           console.log(`No navigation defined for item code: ${item.code}`);
//       }
//     } else {
//       console.log(`Item ${item.code} has no navigation function`);
//     }
//   };

//   // const handleNavigation = (item) => {
//   //   setActiveItem(item.code);

//   //   if (item.code === "501") {
//   //     navigate(`/SalesReport`);
//   //   } else if (item.code === "601") {
//   //     navigate(`/invoiceReport`);
//   //   }
//   // };

//   return (
//     <div className="w-full px-6 py-4 mt-5 bg-gray-50">
//       <div className="max-w-6xl mx-auto">
//         <div className="grid grid-cols-4 gap-6">
//           {categories.map((category, idx) => (
//             <div key={idx}>
//               <div className="flex items-center space-x-3 mb-5 pb-2 border-b">
//                 <div className="p-2 bg-blue-50 rounded-full">
//                   {category.icon}
//                 </div>
//                 <h2 className="text-lg font-semibold text-gray-900">
//                   {category.title}
//                 </h2>
//               </div>
//               <div className="space-y-3">
//                 {category.items.map((item, itemIdx) => {
//                   const isActive = activeItem === item.code;
//                   const isHovered = hoveredItem === `${item.code}-${idx}`;
//                   const hasNavigation = itemsWithNavigation.includes(item.code);
//                   return (
//                     <div
//                       // key={itemIdx}
//                       // onClick={() => handleNavigation(item)}
//                       // onMouseEnter={() => setHoveredItem(`${item.code}-${idx}`)}
//                       // onMouseLeave={() => setHoveredItem(null)}
//                       // style={{
//                       //   boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
//                       //   transform: isHovered ? 'translateY(-2px)' : 'none',
//                       //   transition: 'all 0.3s ease'
//                       // }}
//                       // className={`
//                       //   border rounded-md p-3 text-left
//                       //   flex items-center cursor-pointer
//                       //   ${isActive
//                       //     ? `bg-white hover:border-l-4 hover:border-l-blue-600 ${isHovered ? 'border-l-4 border-l-blue-600' : ''}`
//                       //     : `bg-white hover:border-l-4 hover:border-l-blue-600 ${isHovered ? 'border-l-4 border-l-blue-600' : ''}`
//                       //   }
//                       // `}
//                       key={itemIdx}
//                       onClick={() => handleNavigation(item)}
//                       onMouseEnter={() => setHoveredItem(`${item.code}-${idx}`)}
//                       onMouseLeave={() => setHoveredItem(null)}
//                       style={{
//                         boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
//                         transform: isHovered ? "translateY(-2px)" : "none",
//                         transition: "all 0.3s ease"
//                       }}
//                       className={`
//                         border rounded-md p-3 text-left
//                         flex items-center cursor-pointer
//                         ${
//                           isActive
//                             ? `bg-white border-l-4 border-l-blue-600`
//                             : `bg-white hover:border-l-4 hover:border-l-blue-600 ${
//                                 isHovered ? "border-l-4 border-l-blue-600" : ""
//                               }`
//                         }
//                       `}
//                     >
//                       <div
//                         className={`w-10 font-mono text-sm font-medium ${
//                           isHovered || isActive
//                             ? "text-black-500"
//                             : "text-black-700"
//                         }`}
//                       >
//                         {item.code}
//                       </div>
//                       <div
//                         className={`flex-grow text-sm font-medium ${
//                           isHovered || isActive
//                             ? "text-gray-500"
//                             : "text-gray-700"
//                         }`}
//                       >
//                         {item.label}
//                       </div>
//                       {/* <div className={`w-10 font-mono text-sm font-medium ${isHovered ? 'text-black-500' : 'text-black-700'}`}>
//                         {item.code}
//                       </div>
//                       <div className={`flex-grow text-sm font-medium ${isHovered ? 'text-gray-500' : 'text-gray-700'}`}>
//                         {item.label}
//                       </div> */}
//                       {/* <FiChevronRight 
//                         className={`w-5 h-5 ${isActive ? 'text-white' : isHovered ? 'text-blue-500 opacity-100' : 'opacity-0'}`}
//                         style={{ transition: 'all 0.2s ease' }} 
//                       /> */}
//                       {/* <FiChevronRight
//                         className={`w-5 h-5 ${
//                           isActive
//                             ? "text-blue-500 opacity-100"
//                             : isHovered
//                             ? "text-blue-500 opacity-100"
//                             : "opacity-0"
//                         }`}
//                         style={{ transition: "all 0.2s ease" }}
//                       /> */}
//                       <FiChevronRight 
//   className={`w-5 h-5 ${isHovered ? 'text-blue-500 opacity-100' : 'opacity-0'}`}
//   style={{ transition: 'all 0.2s ease' }} 
// />
//                     </div>
//                   );
//                 })}
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Sales;



import React, { useState } from "react";
import { FiChevronRight, FiShoppingCart, FiFileText } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

const Sales = () => {
  const navigate = useNavigate();
  const [activeItem, setActiveItem] = useState(null);
  const [hoveredItem, setHoveredItem] = useState(null);
  const itemsWithNavigation = ["501", "601"]; // Only include items that actually have navigation

  const categories = [
    {
      title: "Sales",
      icon: <FiShoppingCart className="w-6 h-6 text-blue-600" />,
      color: "border-blue-200",
      items: [
        { code: "501", label: "All Sales" },
        { code: "502", label: "Sales By Fuel Grades" },
        { code: "503", label: "Sales By Country" },
        { code: "504", label: "Sales By State" },
        { code: "505", label: "Sales By Customer" }
      ]
    },
    {
      title: "Invoices",
      icon: <FiFileText className="w-6 h-6 text-blue-600" />,
      color: "border-blue-200",
      items: [
        { code: "601", label: "All Invoice Types" },
        { code: "602", label: "Invoice By Customer" }
      ]
    }
  ];

  const handleNavigation = (item) => {
    // Only set active state and navigate for items with navigation
    if (itemsWithNavigation.includes(item.code)) {
      setActiveItem(item.code);

      // Navigate based on the item code
      switch (item.code) {
        case "501":
          navigate(`/SalesReport`);
          break;
        case "601":
          navigate(`/invoiceReport`);
          break;
        default:
          console.log(`No navigation defined for item code: ${item.code}`);
      }
    } else {
      // For non-navigable items, don't update the active state
      console.log(`Item ${item.code} has no navigation function`);
      // Ensure we don't set an active state for non-navigable items
      setActiveItem(null);
    }
  };

  return (
    <div className="w-full px-6 py-4 mt-5 bg-gray-50">
      <div className="max-w-6xl mx-auto">
        <div className="grid grid-cols-4 gap-6">
          {categories.map((category, idx) => (
            <div key={idx}>
              <div className="flex items-center space-x-3 mb-5 pb-2 border-b">
                <div className="p-2 bg-blue-50 rounded-full">
                  {category.icon}
                </div>
                <h2 className="text-lg font-semibold text-gray-900">
                  {category.title}
                </h2>
              </div>
              <div className="space-y-3">
                {category.items.map((item, itemIdx) => {
                  const isActive = activeItem === item.code;
                  const isHovered = hoveredItem === `${item.code}-${idx}`;
                  const hasNavigation = itemsWithNavigation.includes(item.code);
                  return (
                    <div
                      key={itemIdx}
                      onClick={() => handleNavigation(item)}
                      onMouseEnter={() => setHoveredItem(`${item.code}-${idx}`)}
                      onMouseLeave={() => setHoveredItem(null)}
                      style={{
                        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                        transform: isHovered ? "translateY(-2px)" : "none",
                        transition: "all 0.3s ease"
                      }}
                      className={`
                        border rounded-md p-3 text-left
                        flex items-center cursor-pointer
                        ${
                          hasNavigation && isActive
                            ? `bg-white border-l-4 border-l-blue-600`
                            : `bg-white ${
                                isHovered ? "border-l-4 border-l-blue-600" : ""
                              }`
                        }
                      `}
                    >
                      <div
                        className={`w-10 font-mono text-sm font-medium ${
                          isHovered || (hasNavigation && isActive)
                            ? "text-black-500"
                            : "text-black-700"
                        }`}
                      >
                        {item.code}
                      </div>
                      <div
                        className={`flex-grow text-sm font-medium ${
                          isHovered || (hasNavigation && isActive)
                            ? "text-gray-500"
                            : "text-gray-700"
                        }`}
                      >
                        {item.label}
                      </div>
                      <FiChevronRight 
                        className={`w-5 h-5 ${
                          (hasNavigation && isActive && !isHovered) 
                            ? "text-blue-500 opacity-100" 
                            : isHovered
                            ? "text-blue-500 opacity-100"
                            : "opacity-0"
                        }`}
                        style={{ transition: "all 0.2s ease" }}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Sales;

// // import { MiscellaneousServices } from '@mui/icons-material';
// // import React from 'react';
// // import { FiChevronRight, FiShoppingCart, FiFileText, FiDollarSign, FiBriefcase, FiChevronDown } from 'react-icons/fi';
// // import { useNavigate } from 'react-router-dom';

// // const Accounting = () => {
// //       const navigate = useNavigate()
  
// //   const categories = [
// //     {
// //       title: 'Accounting',
// //       icon: <FiShoppingCart className="w-5 h-5 text-blue-600" />,
// //       // color: 'bg-gradient-to-br from-blue-50 to-blue-100',
// //       color: 'bg-gray-100',
// //       borderColor: 'border-blue-200',
// //       items: [
// //         { code: '901', label: 'Overall Revenues' },
// //         { code: '902', label: 'Revenues By Customer' },
// //         { code: '903', label: 'Pending Payments From Customers' },
// //         { code: '904', label: 'Customer Credits' },
// //         { code: '905', label: 'Customer Discounts' },
// //         { code: '906', label: '1099 K' },    
// //         { code: '907', label: 'Account Balance' },
// //         { code: '908', label: 'General Ledger' }
// //       ]
// //     },
// //     {
// //       title: 'Sales Tax',
// //       icon: <FiDollarSign className="w-5 h-5 text-blue-600" />,
// //       // color: 'bg-gradient-to-br from-blue-50 to-blue-100',
// //       color: 'bg-gray-100',
// //       borderColor: 'border-blue-200',
// //       items: [
// //         { code: '1001', label: 'State Sales Tax' },
// //         { code: '1002', label: 'Sales Tax By County' },
// //         { code: '1003', label: 'NY PBT' },
// //         { code: '1004', label: 'PT 101' }
// //       ]
// //     },
// //     // {
// //     //   title: 'Business Tax',
// //     //   icon: <FiBriefcase className="w-5 h-5 text-blue-600" />,
// //     //   // color: 'bg-gradient-to-br from-blue-50 to-blue-100',
// //     //   color: 'bg-gray-100',
// //     //   borderColor: 'border-blue-200',
// //     //   items: [
// //     //     { code: '1101', label: 'accounting books' },
// //     //     { code: '1102', label: 'P&L statement' },
// //     //     { code: '1103', label: 'Bank Reconciliation' },
// //     //     { code: '1104', label: 'Check Reconciliation ' },
// //     //     { code: '1105', label: 'Card reconciliation' },
// //     //     { code: '1106', label: ' Cash reconciliation' },
// //     //     ]
// //     // },
    
// //   ];

// //   return (
// //     <div className="w-full px-6 py-4">
// //       <div className="max-w-6xl mx-auto space-y-8">
// //         {categories.map((category, idx) => (
// //           <div 
// //             key={idx} className="space-y-2"
// //             // className="flex-1 w-[33%] bg-white rounded-lg border border-gray-100 overflow-hidden"
// //           >
// //             <div className={`p-4 custombgColor ${category.color}`}>
// //               <div className="flex items-center space-x-3 mb-4">
// //                 {category.icon}
// //                 <h2 className="text-base font-semibold text-gray-900">{category.title}</h2>
// //               </div>
// //               <div className="grid grid-cols-3 gap-2 mt-1 mx-20">
// //                 {category.items.map((item, itemIdx) => (
// //                   <div 
// //                     key={itemIdx}
// //                     onClick={() => {
// //                       if (item.code === "751") {
// //                         navigate(`/SalesTax`); 
// //                       } 
// //                     }}
// //                     // className="flex items-center bg-white border border-gray-200 rounded-md p-3 hover:bg-gray-100 transition cursor-pointer"

// //                     className="flex items-center bg-white rounded-md p-3 hover:bg-white hover:shadow-sm transition-all duration-200 cursor-pointer group"
// //                   >
// //                     <div className="w-10 text-gray-500 font-mono text-sm font-medium">{item.code}</div>
// //                     <div className="flex-grow text-gray-700 text-sm">{item.label}</div>
// //  <FiChevronRight className="w-4 h-4 text-gray-400 opacity-0 group-hover:opacity-100 transition-opacity" />  {/* 🟢 react-icons */}              
// //                 </div>
// //                 ))}
// //               </div>
// //             </div>
// //           </div>
// //         ))}
// //       </div>
// //     </div>
// //   );
// // };

// // export default Accounting;




// import React, { useState } from 'react';
// import { FiChevronRight, FiShoppingCart, FiDollarSign } from 'react-icons/fi';
// import { useNavigate } from 'react-router-dom';

// const Accounting = () => {
//   const navigate = useNavigate();
//   const [activeItem, setActiveItem] = useState(null);
//   const [hoveredItem, setHoveredItem] = useState(null);
  
//   const categories = [
//     {
//       title: 'Accounting',
//       icon: <FiShoppingCart className="w-6 h-6 text-blue-600" />,
//       color: "border-blue-200",
//       items: [
//         { code: '901', label: 'Overall Revenues' },
//         { code: '902', label: 'Revenues By Customer' },
//         { code: '903', label: 'Pending Payments From Customers' },
//         { code: '904', label: 'Customer Credits' },
//         { code: '905', label: 'Customer Discounts' },
//         { code: '906', label: '1099 K' },    
//         { code: '907', label: 'Account Balance' },
//         { code: '908', label: 'General Ledger' }
//       ]
//     },
//     {
//       title: 'Sales Tax',
//       icon: <FiDollarSign className="w-6 h-6 text-blue-600" />,
//       color: "border-blue-200",
//       items: [
//         { code: '1001', label: 'State Sales Tax' },
//         { code: '1002', label: 'Sales Tax By County' },
//         { code: '1003', label: 'NY PBT' },
//         { code: '1004', label: 'PT 101' }
//       ]
//     }
//   ];

//   const handleNavigation = (item) => {
//     setActiveItem(item.code);
    
//     if (item.code === "1001") {
//       navigate(`/SalesTax`);
//     }
//   };

//   return (
//     <div className="w-full px-6 py-4 mt-5 bg-gray-50">
//       <div className="max-w-6xl mx-auto">
//         <div className="grid grid-cols-4 gap-6">
//           {categories.map((category, idx) => (
//             <div key={idx}>
//               <div className="flex items-center space-x-3 mb-5 pb-2 border-b">
//                 <div className="p-2 bg-blue-50 rounded-full">
//                   {category.icon}
//                 </div>
//                 <h2 className="text-lg font-semibold text-gray-900">
//                   {category.title}
//                 </h2>
//               </div>
//               <div className="space-y-3">
//                 {category.items.map((item, itemIdx) => {
//                   const isActive = activeItem === item.code;
//                   const isHovered = hoveredItem === `${item.code}-${idx}`;
                  
//                   return (
//                     <div
//                     key={itemIdx}
//                     onClick={() => handleNavigation(item)}
//                     onMouseEnter={() => setHoveredItem(`${item.code}-${idx}`)}
//                     onMouseLeave={() => setHoveredItem(null)}
//                     style={{ 
//                       boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
//                       transform: isHovered ? 'translateY(-2px)' : 'none',
//                       transition: 'all 0.3s ease'
//                     }}
//                     className={`
//                       border rounded-md p-3 text-left
//                       flex items-center cursor-pointer
//                       ${isActive 
//                         ? `bg-white border-l-4 border-l-blue-600`
//                         : `bg-white hover:border-l-4 hover:border-l-blue-600 ${isHovered ? 'border-l-4 border-l-blue-600' : ''}`
//                       }
//                     `}
//                       // key={itemIdx}
//                       // onClick={() => handleNavigation(item)}
//                       // onMouseEnter={() => setHoveredItem(`${item.code}-${idx}`)}
//                       // onMouseLeave={() => setHoveredItem(null)}
//                       // style={{ 
//                       //   boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
//                       //   transform: isHovered ? 'translateY(-2px)' : 'none',
//                       //   transition: 'all 0.3s ease'
//                       // }}
//                       // className={`
//                       //   border rounded-md p-3 text-left
//                       //   flex items-center cursor-pointer
//                       //   ${isActive 
//                       //     ? `bg-white hover:border-l-4 hover:border-l-blue-600 ${isHovered ? 'border-l-4 border-l-blue-600' : ''}`
//                       //     : `bg-white hover:border-l-4 hover:border-l-blue-600 ${isHovered ? 'border-l-4 border-l-blue-600' : ''}`
//                       //   }
//                       // `}
//                     >
//                                             <div className={`w-10 font-mono text-sm font-medium ${isHovered || isActive ? 'text-black-500' : 'text-black-700'}`}>
//                         {item.code}
//                       </div>
//                       <div className={`flex-grow text-sm font-medium ${isHovered || isActive ? 'text-gray-500' : 'text-gray-700'}`}>
//                         {item.label}
//                       </div>
//                       {/* <div className={`w-10 font-bold text-sm font-medium ${isHovered ? 'text-black-500' : 'text-black-700'}`}>
//                         {item.code}
//                       </div>
//                       <div className={`flex-grow text-sm font-medium ${isHovered ? 'text-gray-500' : 'text-gray-700'}`}>
//                         {item.label}
//                       </div> */}
//                       {/* <FiChevronRight 
//                         className={`w-5 h-5 ${isActive ? 'text-white' : isHovered ? 'text-blue-500 opacity-100' : 'opacity-0'}`}
//                         style={{ transition: 'all 0.2s ease' }} 
//                       /> */}
//                    <FiChevronRight 
//                                            className={`w-5 h-5 ${isActive ? 'text-blue-500 opacity-100' : isHovered ? 'text-blue-500 opacity-100' : 'opacity-0'}`}
//                                            style={{ transition: 'all 0.2s ease' }} 
//                                          />
//                     </div>
//                   );
//                 })}
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Accounting;



import React, { useState } from 'react';
import { FiChevronRight, FiShoppingCart, FiDollarSign } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

const Accounting = () => {
  const navigate = useNavigate();
  const [activeItem, setActiveItem] = useState(null);
  const [hoveredItem, setHoveredItem] = useState(null);
  const itemsWithNavigation = ["1001"]; // Only item with actual navigation
  
  const categories = [
    {
      title: 'Accounting',
      icon: <FiShoppingCart className="w-6 h-6 text-blue-600" />,
      color: "border-blue-200",
      items: [
        { code: '901', label: 'Overall Revenues' },
        { code: '902', label: 'Revenues By Customer' },
        { code: '903', label: 'Pending Payments From Customers' },
        { code: '904', label: 'Customer Credits' },
        { code: '905', label: 'Customer Discounts' },
        { code: '906', label: '1099 K' },    
        { code: '907', label: 'Account Balance' },
        { code: '908', label: 'General Ledger' }
      ]
    },
    {
      title: 'Sales Tax',
      icon: <FiDollarSign className="w-6 h-6 text-blue-600" />,
      color: "border-blue-200",
      items: [
        { code: '1001', label: 'State Sales Tax' },
        { code: '1002', label: 'Sales Tax By County' },
        { code: '1003', label: 'NY PBT' },
        { code: '1004', label: 'PT 101' }
      ]
    }
  ];

  const handleNavigation = (item) => {
    // Only set active state and navigate for items with navigation
    if (itemsWithNavigation.includes(item.code)) {
      setActiveItem(item.code);
      
      // Navigate based on the item code
      switch (item.code) {
        case "1001":
          navigate(`/SalesTax`);
          break;
        default:
          console.log(`No navigation defined for item code: ${item.code}`);
      }
    } else {
      // For non-navigable items, don't update the active state
      console.log(`Item ${item.code} has no navigation function`);
      // Ensure we don't set an active state for non-navigable items
      setActiveItem(null);
    }
  };

  return (
    <div className="w-full px-6 py-4 mt-5 bg-gray-50">
      <div className="max-w-6xl mx-auto">
        <div className="grid grid-cols-4 gap-6">
          {categories.map((category, idx) => (
            <div key={idx}>
              <div className="flex items-center space-x-3 mb-5 pb-2 border-b">
                <div className="p-2 bg-blue-50 rounded-full">
                  {category.icon}
                </div>
                <h2 className="text-lg font-semibold text-gray-900">
                  {category.title}
                </h2>
              </div>
              <div className="space-y-3">
                {category.items.map((item, itemIdx) => {
                  const isActive = activeItem === item.code;
                  const isHovered = hoveredItem === `${item.code}-${idx}`;
                  const hasNavigation = itemsWithNavigation.includes(item.code);
                  
                  return (
                    <div
                      key={itemIdx}
                      onClick={() => handleNavigation(item)}
                      onMouseEnter={() => setHoveredItem(`${item.code}-${idx}`)}
                      onMouseLeave={() => setHoveredItem(null)}
                      style={{ 
                        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                        transform: isHovered ? 'translateY(-2px)' : 'none',
                        transition: 'all 0.3s ease'
                      }}
                      className={`
                        border rounded-md p-3 text-left
                        flex items-center cursor-pointer
                        ${
                          hasNavigation && isActive
                            ? `bg-white border-l-4 border-l-blue-600`
                            : `bg-white ${
                                isHovered ? "border-l-4 border-l-blue-600" : ""
                              }`
                        }
                      `}
                    >
                      <div className={`w-10 font-mono text-sm font-medium ${
                        isHovered || (hasNavigation && isActive)
                          ? 'text-black-500' 
                          : 'text-black-700'
                      }`}>
                        {item.code}
                      </div>
                      <div className={`flex-grow text-sm font-medium ${
                        isHovered || (hasNavigation && isActive)
                          ? 'text-gray-500' 
                          : 'text-gray-700'
                      }`}>
                        {item.label}
                      </div>
                      <FiChevronRight 
                        className={`w-5 h-5 ${
                          (hasNavigation && isActive && !isHovered) 
                            ? "text-blue-500 opacity-100" 
                            : isHovered
                            ? "text-blue-500 opacity-100"
                            : "opacity-0"
                        }`}
                        style={{ transition: 'all 0.2s ease' }} 
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Accounting;
import React, { useState, useEffect } from "react";
import { BiSolidUser } from "react-icons/bi";
import { FaHome, FaTimes } from "react-icons/fa";
import { useNavigate, useParams, useLocation, useSearchParams } from "react-router-dom";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import { addToast } from "./ToastBox/ToastBoxMessageSlice";
import { useDispatch } from "react-redux";
import config from "../../config/config";
import ToastNotificationBox from "./ToastBox/ToastNotificationBox";
import { Modal, Button } from "flowbite-react";
import FullScreenLoader from './FullScreenLoader';
import CustomNavigationButtons from "../reusable/CustomNavigationWithEditDelete";
import CustomCommonTab from "../reusable/CustomCommonTab";
// import CustomNavigation from "../reusable/CustomNavigationIcon";

const UserViewPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { userId: paramuserid } = useParams();
  const user_id = paramuserid;
 
  const [loading, setLoading] = useState(false);
  const [activeItem, setActiveItem] = useState("View");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [invalidPhoneNumber, setInvalidPhoneNumber] = useState("");
  const [userData, setUserData] = useState(null);
  const [email_id, setEmail_id] = useState(""); // State to store email_id
  const [searchParams] = useSearchParams();
  
  const baseURL = process.env.NODE_ENV === "production" 
    ? config.production.baseURL 
    : config.development.baseURL;

  const phoneRegExp = /^\(\d{3}\) \d{3}-\d{4}$/;
  const emailRegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const formatPhoneNumber = (value) => {
    if (!value) return "";
    const cleaned = ('' + value).replace(/\D/g, '').slice(0, 10);
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    return match ? `(${match[1]}) ${match[2]}-${match[3]}` : cleaned;
  };

  const handlePhoneNumberChange = (e) => {
    const phoneNumber = e.target.value.replace(/[-()]/g, '').slice(0, 10);
    const formattedValue = formatPhoneNumber(phoneNumber);

    if (formattedValue === "") {
      setInvalidPhoneNumber("");
    } else if (!/[1-9]/.test(phoneNumber)) {
      setInvalidPhoneNumber("Invalid number");
    } else if (formattedValue.length < 14) {
      setInvalidPhoneNumber("Phone number must be at least 10 characters");
    } else {
      setInvalidPhoneNumber("");
    }

    formik.setFieldValue("mobileNumber", formattedValue);
  };

  const formik = useFormik({
    initialValues: {
      userName: "",
      email: "",
      mobileNumber: "",
    },
    // validationSchema: Yup.object({
    //   userName: Yup.string().required("User Name is required"),
    //   email: Yup.string()
    //     .matches(emailRegExp, "Invalid email address")
    //     .required("Email is required"),
    //   mobileNumber: Yup.string()
    //     .required("Contact Number is required"),
    // }),

    validationSchema: Yup.object({
      userName: Yup.string()
      .trim()
      .required("User Name is required")
      .test('not-empty-after-trim', 'User Name cannot be empty or contain only spaces', value => 
        value && value.trim().length > 0
      )
        .test('no-empty-spaces', 'Username cannot contain only spaces', value => 
          value && value.trim().length > 0
        )
        .test('no-leading-spaces', 'Username cannot begin with spaces', value => 
          !value || !value.startsWith(' ')
        ),
      email: Yup.string()
        .trim()
        .matches(emailRegExp, "Invalid email address")
        .required("Email is required")
        .test('not-just-spaces', 'Email cannot be just spaces', value => 
          value && value.trim().length > 0
        ),
      mobileNumber: Yup.string()
        .required("Contact Number is required")
        .test('valid-phone', 'Contact Number is required', value => 
          value && value.replace(/\D/g, '').length > 0
        ),
    }),
    onSubmit: async (values) => {
      try {
        const trimmedValues = {
          userName: values.userName.trim(),
          email: values.email.trim(),
          mobileNumber: values.mobileNumber,
        };
        
        // Additional check to ensure values aren't empty after trimming
        // if (!trimmedValues.userName || !trimmedValues.email || !trimmedValues.mobileNumber.replace(/\D/g, '').length !== 10) {
        //   dispatch(addToast({ type: 'danger', message: 'Please fill all required fields properly' }));
        //   return;
        // }
        
        setLoading(true);
        handleEditUser(trimmedValues); // Pass trimmed values to the API
      } catch (error) {
        console.error("Error in formik submit:", error);
        dispatch(addToast({ type: 'danger', message: 'An error occurred during submission' }));
        setLoading(false);
      }
    },
  });

  const hasActualChanges = () => {
    if (!userData) return false;
    
    const originalValues = {
      userName: userData.contact_name || "",
      email: userData.contact_email || "",
      mobileNumber: formatPhoneNumber(userData.contact_no || ""),
    };
    
    // Compare trimmed values to check for actual changes
    return (
      formik.values.userName.trim() !== originalValues.userName.trim() ||
      formik.values.email.trim() !== originalValues.email.trim() ||
      formik.values.mobileNumber !== originalValues.mobileNumber
    ) && (
      formik.values.userName.trim() !== "" &&
      formik.values.email.trim() !== "" &&
      formik.values.mobileNumber.replace(/\D/g, '').length > 0
    );
  };

  const handleMenuClick = (item) => {
    setActiveItem(item);
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      const businessId = localStorage.getItem("business_id");

      const response = await axios.delete(
        `${baseURL}/${btoa("user/deleteuser")}/${btoa(businessId)}/${btoa(user_id)}`
      );

      if (response.data) {
        dispatch(addToast({ type: 'danger', message: 'User deleted successfully' }));
        navigate("/UserDashBoard");
      }
    } catch (error) {
      dispatch(addToast({ type: 'danger', message: 'Failed to delete user' }));
    } finally {
      setLoading(false);
    }
  };

  const fetchUserData = async () => {
    try {
      setLoading(true);
      const businessId = localStorage.getItem("business_id");
      const response = await axios.get(
        `${baseURL}/${btoa("user/viewuser")}/${btoa(businessId)}/${btoa(user_id)}`
      );

      if (response.data && response.data.userData && response.data.userData.length > 0) {
        const userInfo = response.data.userData[0];
        setUserData(userInfo);
        
        // Store email_id for edit functionality
        setEmail_id(userInfo.contact_email || "");
        
        formik.setValues({
          userName: userInfo.contact_name || "",
          email: userInfo.contact_email || "",
          mobileNumber: formatPhoneNumber(userInfo.contact_no || ""),
        });
        console.log('>>>>>>userData', userInfo);
      } else {
        dispatch(addToast({ type: 'warning', message: 'No user data found' }));
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      dispatch(addToast({ type: 'danger', message: 'Failed to fetch user data' }));
    } finally {
      setLoading(false);
    }
  };

  const handleEditUser = async (values) => {
    try {
      const endpoint = `${baseURL}/${btoa("user/update")}`;
      
      // Prepare data according to API requirements - using the email_id from state
      const requestData = {
        edit_userId: user_id,
        email_db: email_id, // Use the email_id from state
        edit_contactPersonName: values.userName,
        edit_contactPersonEmail: values.email,
        edit_contactPersonPhoneNumber: values.mobileNumber
      };
      
      console.log("Sending update request to:", endpoint);
      console.log("With data:", requestData);
      
      // Make the API call
      const response = await axios.post(endpoint, requestData);
      
      console.log("Update response:", response);
      
      if (response.data && response.data.dataUpdatingStatus === true) {
        // Success case
        const successMessage = `${values.userName} details updated successfully`;
        dispatch(addToast({ type: 'success', message: successMessage }));
        setActiveItem("View");
        // Refresh user data
        // fetchUserData();
        navigate('/UserDashBoard')
      } else {
        // API returned but with error
        const errorMsg =  'User update failed';
        dispatch(addToast({ type: 'danger', message: errorMsg }));
        navigate("/UserDashBoard");
      }
    } catch (error) {
      console.error("Error updating user:", error);
      const errorMessage = error.response?.data?.message || 'Failed to update user';
      dispatch(addToast({ type: 'danger', message: errorMessage }));
    } finally {
      setLoading(false);
      navigate('/UserDashBoard')
    }
  };

  useEffect(() => {
    if (user_id) {
      fetchUserData();
    }
  }, [user_id]);

  // const menuItems = [{ name: "View" }];

  const menuItems = [{ name: `${activeItem} User` }];

  return (
    <>
      {loading && <FullScreenLoader />}
      
      {/* <nav aria-label="breadcrumb">
        <ol className="flex space-x-2 text-md font-bold text-gray-700 mt-[20px] font-[15px]">
          <li className="font-[15px]">Users</li>
          <li><span className="text-gray-400 font-[15px]">{">>"}</span></li>
          <li className="font-[15px]">
            {activeItem === "View" ? "View User" : activeItem === "Edit" ? "Edit User" : "User Details"}
          </li>
        </ol>
      </nav> */}
    <div className="flex w-full px-2 ">
  <div className="flex-1 flex justify-start items-center ">
    {/* <nav aria-label="breadcrumb">
      <ol className="flex space-x-2 text-md font-bold text-gray-700 mb-6">
        <li>Users</li>
        <li>
          <span className="text-gray-400">{">>"}</span>
        </li>
        <li >View User</li>
      </ol>
    </nav> */}
  </div>

  {/* Center Aligned Custom Tab Component */}
  <div className="flex-1 flex justify-center">
    <CustomCommonTab items={menuItems} />
  </div>

  {/* Placeholder for Right-Aligned Section */}
  <div className="flex-1 flex justify-end">
    {/* Add any right-aligned content here if needed */}
  </div>
</div>
      <form className="space-y-3" onSubmit={formik.handleSubmit}>
        <div className="flex mb-2 justify-center rounded-md font-bold text-md items-center w-full mx-auto">
          {/* <div
            className={`flex items-center cursor-pointer px-4 py-1 ${
              activeItem === "View" ? "border-b-2 border-green-500 text-green-600" : "text-gray-600"
            } border-b border-gray-300`}
            onClick={() => handleMenuClick("View")}
          >
            <span>View</span>
          </div> */}

          {/* <div
            className={`flex items-center cursor-pointer px-4 py-1 ${
              activeItem === "Edit" ? "border-b-2 border-green-500 text-green-600" : "text-gray-600"
            } border-b border-gray-300`}
            onClick={() => handleMenuClick("Edit")}
          >
            <span>Edit</span>
          </div>

          <div
            className={`flex items-center cursor-pointer px-4 py-1 ${
              activeItem === "Delete" ? "border-b-2 border-green-500 text-green-600" : "text-gray-600"
            } border-b border-gray-300`}
            onClick={() => setIsDeleteModalOpen(true)}
          >
            <span>Delete</span>
          </div> */}
        </div>

        <div className="flex flex-col items-center justify-center ">
          <ToastNotificationBox className="z-58880" />

          <div className="flex justify-between bg-white rounded-lg p-8 w-[880px] mx-auto relative mt-0" 
               style={{ boxShadow: "0 2px 10px rgba(0, 0, 0, 0.3)" }}>
            
            {/* Action buttons in top-right corner of the card, positioned half-in/half-out */}
            <div className="absolute -top-5 -right-1 flex items-center space-x-2 z-10">
  {activeItem === "View" ? (
    // When in View mode, show edit and delete buttons
    <>
      <CustomNavigationButtons type="edit" onClick={() => handleMenuClick("Edit")} />
      <CustomNavigationButtons type="delete" onClick={() => setIsDeleteModalOpen(true)} />
    </>
  ) : (
    // When in Edit mode, show save and cancel edit buttons
    <>
  
    </>
  )}

  {/* Home button - always visible */}
  <div
    className="flex items-center justify-center cursor-pointer rounded-full bg-[#cefafe] hover:bg-[#25caed] w-9 h-9 mb-0 border-[0.5px] border-[#25caed] hover:border-[#25caed] hover:scale-110 shadow-md transition-all duration-200 group"
    onClick={() => {
      navigate("/");
    }}
    style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
  >
    <FaHome className="text-[#25caed] group-hover:text-white transition-colors duration-200" size={16} />
  </div>

  {/* Close/Return button - always visible */}
  <button
    className="bg-red-200 hover:bg-red-500 w-9 h-9 flex items-center justify-center rounded-full cursor-pointer hover:bg-red-700 border-[0.5px] border-red-500 hover:border-red-700 hover:scale-110 shadow-md transition-all duration-200 group"
    // onClick={() => navigate('/UserDashBoard')} 
    onClick={(e) => {
      e.preventDefault();
      if (activeItem === "Edit") {
        // When in Edit mode, return to View mode
        setActiveItem("View");
      } else {
        // When in View mode, navigate to UserDashboard
        navigate('/UserDashBoard');
      }
    }}
  >
    <span className="text-red-500 group-hover:text-white text-xl font-bold mb-1.5 transition-colors duration-200">&times;</span>
  </button>
</div>
            
            <div className="flex flex md:flex gap-8">
              <div className="flex flex-col items-center justify-center space-y-4 w-[300px]">
                <div className="w-16 h-16 bg-emerald-500 rounded-full flex items-center justify-center">
                  <BiSolidUser className="text-green-500 rounded-full p-2" size={80} />
                </div>
                <h2 className="text-lg font-medium text-gray-700">
                  {activeItem === "View" ? "User Information" : "Edit User"}
                </h2>
              </div>

              <div className="space-y-4">
                {activeItem === "View" && userData ? (
                  <>
                    <div className="space-y-1">
                      <label className="block text-sm font-medium text-gray-700 ">
                        User Name
                      </label>
                      <input
                        type="text"
                        className="p-1 border border-gray-300 rounded-md bg-gray-100"
                        value={userData.contact_name || ""}
                        // disabled={true} readOnly
                        readOnly disabled
                      />
                    </div>
                    
                    <div className="space-y-1">
                      <label className="block text-sm font-medium text-gray-700">
                        Contact Email
                      </label>
                      <input
                        type="text"
                        className=" p-1 border border-gray-300 rounded-md bg-gray-100"
                        value={userData.contact_email || ""}
                        // disabled={true} readOnly
                        readOnly disabled
                      />
                    </div>
                    
                    <div className="space-y-1">
                      <label className="block text-sm font-medium text-gray-700">
                        Contact Number
                      </label>
                      <input
                        type="text"
                        className=" p-1 border border-gray-300 rounded-md bg-gray-100"
                        value={formatPhoneNumber(userData.contact_no) || ""}
                        // disabled={true} 
                        readOnly disabled
                      />
                    </div>
                                    
                  </>
                ) : (
                  <>
                    <div className="space-y-1">
                      <label className="block text-sm font-medium text-gray-700">
                        User Name <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="userName"
                        placeholder="Enter User Name"
                        className=" p-1 border border-gray-300 rounded-md"
                        // onChange={formik.handleChange}
                        onChange={(e) => {
                          const inputValue = e.target.value.replace(/^[^a-zA-Z0-9]|(?<=[a-zA-Z0-9])[^a-zA-Z0-9\s]/g, '');
                          // const inputValue = e.target.value.replace(/[^a-zA-Z\s]/g, "");
                          formik.setFieldValue("userName", inputValue);
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.userName}
                        disabled={activeItem === "View"}
                      />
                      {formik.touched.userName && formik.errors.userName && (
                        <p className="text-red-500 text-sm">{formik.errors.userName}</p>
                      )}
                    </div>

                    <div className="space-y-1">
                      <label className="block text-sm font-medium text-gray-700">
                        Contact Email <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="email"
                        name="email"
                        placeholder="Enter Contact Email"
                        className="p-1 border border-gray-300 rounded-md"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                        disabled={activeItem === "View"}
                      />
                      {formik.touched.email && formik.errors.email && (
                        <p className="text-red-500 text-sm">{formik.errors.email}</p>
                      )}
                    </div>

                    <div className="space-y-1">
                      <label className="block text-sm font-medium text-gray-700">
                        Contact Number <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="mobileNumber"
                        placeholder="(XXX) XXX-XXXX"
                        className="p-1 border border-gray-300 rounded-md"
                        onChange={handlePhoneNumberChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.mobileNumber}
                        maxLength={14}
                        disabled={activeItem === "View"}
                      />
                      {formik.touched.mobileNumber && formik.errors.mobileNumber && (
                        <p className="text-red-500 text-sm">{formik.errors.mobileNumber}</p>
                      )}
                      {invalidPhoneNumber && (
                        <p className="text-red-500 text-sm">{invalidPhoneNumber}</p>
                      )}
                    </div>
                  </>
                )}

                {/* {activeItem !== "View" && (
                  <div className="flex justify-end gap-4 mt-10">
                    <button
                      type="submit"
                      disabled={!formik.isValid || !formik.dirty || invalidPhoneNumber !== ""}
                      className={`w-40 px-12 py-1 border-2 border-blue-500 text-blue-500 hover:font-bold rounded-md
                        hover:bg-blue-700 hover:text-white uppercase ${
                          !formik.isValid || !formik.dirty || invalidPhoneNumber !== ""
                            ? 'bg-gray-100 text-gray-500 hover:bg-gray-100 hover:text-gray-500 focus:none focus:outline-none font-bold hover:none'
                            : ''
                        }`}
                    >
                      {loading ? "Saving..." : "SAVE"}
                    </button>
                    <button
                      type="button"
                      onClick={() => navigate('/UserDashBoard')}
                      className="w-40 px-12 py-1 border-2 border-red-500 text-red-500 hover:font-bold hover:text-white rounded-md hover:bg-red-700 uppercase"
                    >
                      CANCEL
                    </button>
                  </div>
                )} */}

{activeItem !== "View" && (
  <div className="flex justify-end gap-4 mt-10">
    <button
      type="submit"
      disabled={!hasActualChanges() || invalidPhoneNumber !== ""}
      className={`w-40 px-12 py-1 border-2 border-blue-500 text-blue-500 hover:font-bold rounded-md
        ${!hasActualChanges() || invalidPhoneNumber !== "" 
          ? 'bg-gray-100 text-gray-500 cursor-not-allowed' 
          : 'hover:bg-blue-700 hover:text-white'} uppercase`}
    >
      {loading ? "Saving..." : "SAVE"}
    </button>
    <button
      type="button"
      onClick={() => navigate('/UserDashBoard')}
      disabled={!hasActualChanges() || invalidPhoneNumber !== ""}
      className={`w-40 px-12 py-1 border-2 border-red-500 text-red-500 hover:font-bold rounded-md
        ${!hasActualChanges() || invalidPhoneNumber !== "" 
          ? 'bg-gray-100 text-gray-500 cursor-not-allowed' 
          : 'hover:bg-red-700 hover:text-white'} uppercase`}
    >
      CANCEL
    </button>
  </div>
)}

                
              </div>
            </div>
          </div>
        </div>
      </form>

      {/* Delete Confirmation Modal */}
      <Modal
        show={isDeleteModalOpen}
        size="md"
        popup
        onClose={() => setIsDeleteModalOpen(false)}
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <h3 className="mb-5 text-lg font-normal text-gray-500">
              Are you sure you want to delete
              <span className="block uppercase p-2 font-semibold text-red-700 underline underline-offset-8">
                "{userData?.contact_name || formik.values.userName}"
              </span>
            </h3>
            <div className="flex justify-center gap-4">
              <Button color="failure" onClick={() => handleDelete()} disabled={loading}>
                {loading ? "Deleting..." : "Yes, I'm sure"}
              </Button>
              <Button color="gray" onClick={() => setIsDeleteModalOpen(false)}>
                No, cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UserViewPage;
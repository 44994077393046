
// // import { MiscellaneousServices } from '@mui/icons-material';
// // import React from 'react';
// // import { FiChevronRight, FiBarChart, FiDollarSign, FiBriefcase, FiChevronDown, FiShoppingCart } from 'react-icons/fi';

// // const Analytics = () => {
// //   const categories = [
// //     // {
// //     //      title: 'Sales',
// //     //      icon: <FiShoppingCart className="w-5 h-5 text-blue-600" />,
// //     //      // color: 'bg-gradient-to-br from-blue-50 to-blue-100',
// //     //      color: 'bg-gray-100',
// //     //      borderColor: 'border-blue-200',
// //     //      items: [
// //     //        { code: '501', label: 'All Sales' },
// //     //        { code: '502', label: 'Sales by Fuel Grades' },
// //     //        { code: '503', label: 'Sales by County' },
// //     //        { code: '504', label: 'Sales by State' },
// //     //        { code: '505', label: 'Sales by Customer' }
// //     //      ]
// //     //    },
// //       //  {
// //       //    title: 'Sales Tax',
// //       //    icon: <FiDollarSign className="w-5 h-5 text-blue-600" />,
// //       //    // color: 'bg-gradient-to-br from-blue-50 to-blue-100',
// //       //    color: 'bg-gray-100',
// //       //    borderColor: 'border-blue-200',
// //       //    items: [
// //       //      { code: '1001', label: 'State Sales Tax' },
// //       //      { code: '1002', label: 'Sales Tax by County' },
// //       //      { code: '1003', label: 'NY PBT' },
// //       //      { code: '1004', label: 'PT 101' }
// //       //    ]
// //       //  },
// //      {
// //           title: 'Business Tax',
// //           icon: <FiBriefcase className="w-5 h-5 text-blue-600" />,
// //           // color: 'bg-gradient-to-br from-blue-50 to-blue-100',
// //           color: 'bg-gray-100',
// //           borderColor: 'border-blue-200',
// //           items: [
// //             { code: '1101', label: 'Accounting books' },
// //             { code: '1102', label: 'P&L Statement' },
// //             { code: '1103', label: 'Bank Reconciliation' },
// //             { code: '1104', label: 'Check Reconciliation ' },
// //             { code: '1105', label: 'Card Reconciliation' },
// //             { code: '1106', label: ' Cash Reconciliation' },
// //             ]
// //         },
// //         {
// //           title: 'Misc',
// //           icon: <MiscellaneousServices className="w-5 h-5 text-blue-600" />,
// //           // color: 'bg-gradient-to-br from-blue-50 to-blue-100',
// //           color: 'bg-gray-100',
// //           borderColor: 'border-blue-200',
// //           items: [
// //             { code: '1201', label: 'Miscellaneous ' },
           
// //             ]
// //         }
// //   ];

// //   return (
// //     <div className="w-full px-6 py-4">
// //       <div className="max-w-6xl mx-auto space-y-8">
// //         {categories.map((category, idx) => (
// //           <div key={idx} className="space-y-2"
// //           // className="flex-1 w-[33%] bg-white rounded-lg border border-gray-100 overflow-hidden"
// //           >
// //             <div className={`p-4 custombgColor ${category.color}`}>
// //             <div className="flex items-center space-x-2 space-y-2 text-gray-900 font-semibold text-lg">
// //                 {category.icon}
// //                 <h2 className="text-base font-semibold text-gray-900">{category.title}</h2>
// //               </div>
// //               <div className="grid grid-cols-3 gap-2 mt-1 mx-20">
// //                 {category.items.map((item, itemIdx) => (
// //                   <div key={itemIdx} 
// //                   // className="flex items-center bg-white border border-gray-200 rounded-md p-3 hover:bg-gray-100 transition cursor-pointer"

// //                   className="flex items-center bg-white rounded-md p-3 hover:bg-white hover:shadow-sm transition-all duration-200 cursor-pointer group"
// //                   >
// //                     <div className="w-10 text-gray-500 font-mono text-sm font-medium">{item.code}</div>
// //                     <div className="flex-grow text-gray-700 text-sm">{item.label}</div>
// //                     <FiChevronRight className="w-4 h-4 text-gray-400 opacity-0 group-hover:opacity-100 transition-opacity" />  {/* 🟢 react-icons */}              
// //                     </div>
// //                 ))}
// //               </div>
// //             </div>
// //           </div>
// //         ))}
// //       </div>
// //     </div>
// //   );
// // };

// // export default Analytics;



// import React, { useState } from 'react';
// import { FiChevronRight, FiBriefcase } from 'react-icons/fi';
// import { MiscellaneousServices } from '@mui/icons-material';
// import { useNavigate } from 'react-router-dom';

// const Analytics = () => {
//   const navigate = useNavigate();
//   const [activeItem, setActiveItem] = useState(null);
//   const [hoveredItem, setHoveredItem] = useState(null);
//   const itemsWithNavigation = ["1101", "1102", "1103", "1104","1105", "1106", "1201",];
//   const categories = [
//     {
//       title: 'Business Tax',
//       icon: <FiBriefcase className="w-6 h-6 text-blue-600" />,
//       color: "border-blue-200",
//       items: [
//         { code: '1101', label: 'Accounting Books' },
//         { code: '1102', label: 'P&L Statement' },
//         { code: '1103', label: 'Bank Reconciliation' },
//         { code: '1104', label: 'Check Reconciliation ' },
//         { code: '1105', label: 'Card Reconciliation' },
//         { code: '1106', label: 'Cash Reconciliation' },
//       ]
//     },
//     {
//       title: 'Misc',
//       icon: <MiscellaneousServices className="w-6 h-6 text-blue-600" />,
//       color: "border-blue-200",
//       items: [
//         { code: '1201', label: 'Miscellaneous ' },
//       ]
//     }
//   ];

//   const handleNavigation = (item) => {
//     // Only set active state for items with navigation
//     if (itemsWithNavigation.includes(item.code)) {
//       setActiveItem(item.code);
      
//       // Navigate based on the item code
//       switch(item.code) {

//         default:
//           console.log(`No navigation defined for item code: ${item.code}`);
//       }
//     } else {
//       console.log(`Item ${item.code} has no navigation function`);
//     }
//   };
  

//   return (
//     <div className="w-full px-6 py-4 mt-5 bg-gray-50">
//       <div className="max-w-6xl mx-auto">
//         <div className="grid grid-cols-4 gap-6">
//           {categories.map((category, idx) => (
//             <div key={idx}>
//               <div className="flex items-center space-x-3 mb-5 pb-2 border-b">
//                 <div className="p-2 bg-blue-50 rounded-full">
//                   {category.icon}
//                 </div>
//                 <h2 className="text-lg font-semibold text-gray-900">
//                   {category.title}
//                 </h2>
//               </div>
//               <div className="space-y-3">
//                 {category.items.map((item, itemIdx) => {
//                   const isActive = activeItem === item.code;
//                   const isHovered = hoveredItem === `${item.code}-${idx}`;
                  
//                   return (
//                     <div
//                       // key={itemIdx}
//                       // onClick={() => handleNavigation(item)}
//                       // onMouseEnter={() => setHoveredItem(`${item.code}-${idx}`)}
//                       // onMouseLeave={() => setHoveredItem(null)}
//                       // style={{ 
//                       //   boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
//                       //   transform: isHovered ? 'translateY(-2px)' : 'none',
//                       //   transition: 'all 0.3s ease'
//                       // }}
//                       // className={`
//                       //   border rounded-md p-3 text-left
//                       //   flex items-center cursor-pointer
//                       //   ${isActive 
//                       //     ? `bg-white hover:border-l-4 hover:border-l-blue-600 ${isHovered ? 'border-l-4 border-l-blue-600' : ''}`
//                       //     : `bg-white hover:border-l-4 hover:border-l-blue-600 ${isHovered ? 'border-l-4 border-l-blue-600' : ''}`
//                       //   }
//                       // `}
//                       key={itemIdx}
//                       onClick={() => handleNavigation(item)}
//                       onMouseEnter={() => setHoveredItem(`${item.code}-${idx}`)}
//                       onMouseLeave={() => setHoveredItem(null)}
//                       style={{ 
//                         boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
//                         transform: isHovered ? 'translateY(-2px)' : 'none',
//                         transition: 'all 0.3s ease'
//                       }}
//                       className={`
//                         border rounded-md p-3 text-left
//                         flex items-center cursor-pointer
//                         ${isActive 
//                           ? `bg-white border-l-4 border-l-blue-600`
//                           : `bg-white hover:border-l-4 hover:border-l-blue-600 ${isHovered ? 'border-l-4 border-l-blue-600' : ''}`
//                         }
//                       `}
//                     >
//                                             <div className={`w-10 font-mono text-sm font-medium ${isHovered || isActive ? 'text-black-500' : 'text-black-700'}`}>
//                         {item.code}
//                       </div>
//                       <div className={`flex-grow text-sm font-medium ${isHovered || isActive ? 'text-gray-500' : 'text-gray-700'}`}>
//                         {item.label}
//                       </div>
//                       {/* <div className={`w-10 font-semibold text-sm font-medium ${isHovered ? 'text-black-500' : 'text-black-700'}`}>
//                         {item.code}
//                       </div>
//                       <div className={`flex-grow text-sm font-medium ${isHovered ? 'text-gray-500' : 'text-gray-700'}`}>
//                         {item.label}
//                       </div> */}
//                       {/* <FiChevronRight 
//                         className={`w-5 h-5 ${isActive ? 'text-white' : isHovered ? 'text-blue-500 opacity-100' : 'opacity-0'}`}
//                         style={{ transition: 'all 0.2s ease' }} 
//                       /> */}
//                    <FiChevronRight 
//                                            className={`w-5 h-5 ${isActive ? 'text-blue-500 opacity-100' : isHovered ? 'text-blue-500 opacity-100' : 'opacity-0'}`}
//                                            style={{ transition: 'all 0.2s ease' }} 
//                                          />
//                     </div>
//                   );
//                 })}
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Analytics;




import React, { useState } from 'react';
import { FiChevronRight, FiBriefcase } from 'react-icons/fi';
import { MiscellaneousServices } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const Analytics = () => {
  const navigate = useNavigate();
  const [activeItem, setActiveItem] = useState(null);
  const [hoveredItem, setHoveredItem] = useState(null);
  // Note: Since none of these items have actual navigation paths defined in the switch case,
  // we're setting an empty array, but you should add codes here once navigation is implemented
  const itemsWithNavigation = []; 

  const categories = [
    {
      title: 'Business Tax',
      icon: <FiBriefcase className="w-6 h-6 text-blue-600" />,
      color: "border-blue-200",
      items: [
        { code: '1101', label: 'Accounting Books' },
        { code: '1102', label: 'P&L Statement' },
        { code: '1103', label: 'Bank Reconciliation' },
        { code: '1104', label: 'Check Reconciliation ' },
        { code: '1105', label: 'Card Reconciliation' },
        { code: '1106', label: 'Cash Reconciliation' },
      ]
    },
    {
      title: 'Misc',
      icon: <MiscellaneousServices className="w-6 h-6 text-blue-600" />,
      color: "border-blue-200",
      items: [
        { code: '1201', label: 'Miscellaneous ' },
      ]
    }
  ];

  const handleNavigation = (item) => {
    // Only set active state and navigate for items with navigation
    if (itemsWithNavigation.includes(item.code)) {
      setActiveItem(item.code);
      
      // Navigate based on the item code
      switch(item.code) {
        // Add cases here as navigation routes are implemented
        default:
          console.log(`No navigation defined for item code: ${item.code}`);
      }
    } else {
      // For non-navigable items, don't update the active state
      console.log(`Item ${item.code} has no navigation function`);
      // Ensure we don't set an active state for non-navigable items
      setActiveItem(null);
    }
  };

  return (
    <div className="w-full px-6 py-4 mt-5 bg-gray-50">
      <div className="max-w-6xl mx-auto">
        <div className="grid grid-cols-4 gap-6">
          {categories.map((category, idx) => (
            <div key={idx}>
              <div className="flex items-center space-x-3 mb-5 pb-2 border-b">
                <div className="p-2 bg-blue-50 rounded-full">
                  {category.icon}
                </div>
                <h2 className="text-lg font-semibold text-gray-900">
                  {category.title}
                </h2>
              </div>
              <div className="space-y-3">
                {category.items.map((item, itemIdx) => {
                  const isActive = activeItem === item.code;
                  const isHovered = hoveredItem === `${item.code}-${idx}`;
                  const hasNavigation = itemsWithNavigation.includes(item.code);
                  
                  return (
                    <div
                      key={itemIdx}
                      onClick={() => handleNavigation(item)}
                      onMouseEnter={() => setHoveredItem(`${item.code}-${idx}`)}
                      onMouseLeave={() => setHoveredItem(null)}
                      style={{ 
                        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                        transform: isHovered ? 'translateY(-2px)' : 'none',
                        transition: 'all 0.3s ease'
                      }}
                      className={`
                        border rounded-md p-3 text-left
                        flex items-center cursor-pointer
                        ${
                          hasNavigation && isActive
                            ? `bg-white border-l-4 border-l-blue-600`
                            : `bg-white ${
                                isHovered ? "border-l-4 border-l-blue-600" : ""
                              }`
                        }
                      `}
                    >
                      <div className={`w-10 font-mono text-sm font-medium ${
                        isHovered || (hasNavigation && isActive)
                          ? 'text-black-500' 
                          : 'text-black-700'
                      }`}>
                        {item.code}
                      </div>
                      <div className={`flex-grow text-sm font-medium ${
                        isHovered || (hasNavigation && isActive)
                          ? 'text-gray-500' 
                          : 'text-gray-700'
                      }`}>
                        {item.label}
                      </div>
                      <FiChevronRight 
                        className={`w-5 h-5 ${
                          (hasNavigation && isActive && !isHovered) 
                            ? "text-blue-500 opacity-100" 
                            : isHovered
                            ? "text-blue-500 opacity-100"
                            : "opacity-0"
                        }`}
                        style={{ transition: 'all 0.2s ease' }} 
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Analytics;
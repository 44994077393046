// // import React, { useState, useRef, useEffect } from 'react';
// // import { Box, Paper } from '@mui/material';
// // import 'react-datepicker/dist/react-datepicker.css';
// // import '../Sales/sales.css'; // Add custom styles if needed
// // import SendIcon from '@mui/icons-material/Send';
// // import Button from '@mui/material/Button';
// // import {  useSelector , useDispatch} from "react-redux";
// // import { Grid, IconButton } from '@mui/material';
// // import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
// // import PrintIcon from '@mui/icons-material/Print';
// // import DescriptionIcon from '@mui/icons-material/Description';
// // import FileDownloadIcon from '@mui/icons-material/FileDownload';

// // import axios from "axios";
// // import { Tooltip } from "react-tippy";

// // import { addCustomer } from "../../../store/customerSlice";
// // import config  from "../../../config/config"
// // import Loader from "./../Loader";
// // import FullScreenLoader from '../FullScreenLoader';
// // // import SalesReportTable from './SalesReportTable';


// // const Sales = () => {
// //   const businessId = localStorage.getItem('business_id')
// //   const managerId =localStorage.getItem('user_id')
// //   const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;
// //   const [periodType, setPeriodType] = useState(""); // Tracks the selected period (Year, Month, Quarterly)
// //   const [tempperiodType, settempPeriodType] = useState(""); // Tracks the selected period (Year, Month, Quarterly)

// //   const [selectedYear, setSelectedYear] = useState(""); // Tracks the selected year
// //   const [tempYear, setTempYear] = useState(""); // Tracks the selected year

// //   const [selectedMonth, setSelectedMonth] = useState(""); // Tracks the selected month
// //   const [tempMonth, setTempMonth] = useState(""); // Tracks the selected year
// //   const [tempFuelGrade, settempFuelGrade] = useState(""); // Tracks the selected year


// //   const [selectedQuarter, setSelectedQuarter] = useState("");
// //   const [tempQuarter, setTempQuarter] = useState(""); // Tracks the selected year

// //   const [customer, setCustomer] = useState('');
// //   const [tempcustomer, setTempCustomer] = useState('');

// //   const [fuelGrade, setFuelGrade] = useState('');

// //   const customersAll = useSelector(state => state.customer.allCustomers);
// //   const dispatch = useDispatch();
// //   const [dateTime, setDateTime] = useState('');
// //   const [salesResponse, setSalesReport] = useState([]);
// //   const [imageSource, setImageSource] = useState();
// //   const [imageResponse, setimageResponse] = useState([]);
// //   const [loading, setLoading] = useState(false); // Add a loading state
// //   const [pdfhovered, setPdfHovered] = useState(false);
// //   const [taxhovered, setTaxHovered] = useState(false);
// //   const [productData, setProductData] = useState([]);


// //   const [RunClicked, setRunClicked] = useState(false); // Tracks the selected quarter
// //   const user_id = localStorage.getItem("user_id");
// //   const user_role = localStorage.getItem("user_role");
// //   const business_id = localStorage.getItem("business_id");
// //   const contentRef = useRef();
// //   const [userRole, setUserRole] = useState("");
// //   const from =  salesResponse.data?.from;
// //   const to =  salesResponse.data?.to;
// // //   const isButtonEnabled = () => {
// // //     if (periodType === 'Yearly' && selectedYear && customer && fuelGrade) return true;
// // //     if (periodType === 'Monthly' && selectedYear && selectedMonth && customer && fuelGrade) return true;
// // //     if (periodType === 'Quarterly' && selectedYear && selectedQuarter && customer && fuelGrade) return true;
// // //     return false;
// // //   };
// // const isButtonEnabled = () => {
// //     if ( customer ) return true;
// //     return false;
// //   };

// //   const formatToTwoDecimalsWithCommas = (num) => {
// //     const parts = num.toString().split('.');
// //     const integerPartWithCommas = parseInt(parts[0], 10).toLocaleString('en-US'); // Adds commas to the integer part
// //     const decimalPart = parts[1] ? parts[1].substring(0, 2) : ''; // Limits to two decimal places without rounding
  
// //     return decimalPart ? `${integerPartWithCommas}.${decimalPart}` : integerPartWithCommas;
// //   };
// //   const generateUSDateTime = () => {
// //     setRunClicked(true);
// //     const currentDate = new Date();
    
// //     // Format date and time in US format
// //     const formattedDateTime = currentDate.toLocaleString('en-US', {
// //       year: 'numeric',
// //       month: 'numeric',
// //       day: 'numeric',
// //       hour: 'numeric',
// //       minute: 'numeric',
// //       second: 'numeric', // Optional: remove if seconds aren't needed
// //       hour12: true, // 12-hour format with AM/PM
// //       timeZone: 'America/New_York' // You can change the timezone if necessary
// //     });

// //     setDateTime(formattedDateTime);
// //   };
// //   function formatNumberwithoutzeros(num) {
// //     return new Intl.NumberFormat('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(num);
// //   }
// //   const handlePrint = () => {
// //     const content = contentRef.current.innerHTML; // Get the HTML content
// //     const newWindow = window.open('', '_blank'); // Open a new window
// //     newWindow.document.write(`
// //       <html>
// //         <head>
// //           <title>Print</title>
// //           <style>
// //             /* Optional: Add styles for printed content */
// //                       @media print {
// //   img {
// //     display: block;
// //     width: 100px; /* Adjust width as needed */
// //     height: auto;
// //   }
// //               @page {
// //                 margin: 0; /* Remove default margins */
// //               }
// //               body {
// //                 margin: 0;
// //                 padding: 0;
// //               }
// //               /* Hide headers and footers */
// //               header, footer {
// //                 display: none; /* Hides header and footer */
// //               }
// //               /* You can also add styles to control the printed content */

// //             body {
// //               font-family: Arial, sans-serif;
// //               margin: 60px;
// //             }
// //           </style>
// //         </head>
// //         <body>
// //           ${content} 
// //         </body>
// //       </html>
// //     `);
// //     newWindow.document.close(); // Close the document to apply styles
// //     newWindow.print(); // Trigger the print dialog
// //   };
// //   function formatNumber(num) {
// //     return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(num);
// //   }
  
// // const Sub_total_amount =() => {
// //   return (
// //    salesResponse.data && salesResponse.data.totalAmount
// //   )
// // }
// // const Due_amount =() => {
// //   return (
// //    salesResponse.data.sales_data.length >0 ? salesResponse.data.sales_data.reduce((accumulator, currentValue) => {
// //        return accumulator + +currentValue.due_amount; // Sum the gallons property
// //      }, 0) : ''
// //   )
// // }
// // const Quantity =() => {
// //   return (
// //    salesResponse.data && salesResponse.data.totalGallons
// //   )
// // }
// //   useEffect(() => {
// //     setUserRole(localStorage.getItem("user_role"));
// //   }, []);

// //   useEffect(() => {
// //     axios
// //       .get(`${baseURL}/${btoa("business/profile")}/${btoa(business_id)}/${btoa(user_id)}/${btoa(user_role)}`)
// //       .then((response) => {
// //         console.log(response);
// //         // console.log("response businesstype", response);
// //         setImageSource(response.data.business_data[0].image_file)
// //         setimageResponse(response.data.business_data[0])
// //       })
// //       .catch((error) => {
// //         console.error("Error fetching business data:", error);

// //       });
// //       console.log(imageResponse);

// //   }, []);

// //   // Years and Months for dropdowns
// //   const years = ["2024", "2023", "2022", "2021"];
// //   const months = [
// //     "January",
// //     "February",
// //     "March",
// //     "April",
// //     "May",
// //     "June",
// //     "July",
// //     "August",
// //     "September",
// //     "October",
// //     "November",
// //     "December",
// //   ];
// //   const quarters = [{bName: "Q1",
// //     fName:"Q1 (Mar-May)"
// //   },{bName: "Q2",
// //     fName:"Q2 (June-Aug)"},{bName: "Q3",
// //         fName: "Q3 (Sep-Nov)"},{bName: "Q4",
// //             fName: "Q4 (Dec-Feb)"}]

// // // console.log('customersAll', customersAll);
// // useEffect(() => {
// //   window.scrollTo(0, 0);
// //   axios
// //     .get(`${baseURL}Y3VzdG9tZXIvdmlldw==/${btoa(businessId)}`)
// //     .then((response) => {
// //       const sortedCustomerData = response.data.customers_data.sort(
// //         (a, b) => b.customer_id - a.customer_id
// //       );
// //       // console.log("sor" , sortedCustomerData);
// //       dispatch(addCustomer(sortedCustomerData))
   
// //     })
// //     .catch((error) => {
// //       console.error("Error fetching customer data:", error);
// //     });
// // }, []);

// //   // Ref for DatePicker to programmatically open it
// //   const datePickerRef = useRef(null);

// //   const handleCustomerChange = (event) => {
// //     setCustomer(event.target.value);
// //     // setSelectedDate(null); // Reset selected date
// //   };
// //   const handleFuelChange = (event) => {
// //     setFuelGrade(event.target.value);
// //     // setSelectedDate(null); // Reset selected date
// //   };

// //   const handlePeriodChange = (event) => {
// //     // setRunClicked(false)
// //     setPeriodType(event.target.value);
// //     // setSelectedDate(null); // Reset selected date
// //   };
// //   useEffect(() => {
// //     window.scrollTo(0, 0);
// //     axios
// //       .get(`${baseURL}/${btoa("product/view")}`)
// //       .then((response) => {
// //           // console.log("prod" , response.data.products_data);
       
// //         setProductData( response.data.products_data);
// //       })
// //       .catch((error) => {
// //         console.error("Error fetching customer data:", error);
// //       });
// //   }, []);
// //   const handleRun = async () => { 
// //     setLoading(true); // Show loader
// //     setTempYear(selectedYear)
// //     setTempMonth(selectedMonth)
// //     setTempQuarter(selectedQuarter)
// //     settempPeriodType(periodType)
// //     settempFuelGrade(fuelGrade)
// //     setTempCustomer(customer)
// //     // Introduce a 3-second delay
// //     // const delay = new Promise(resolve => setTimeout(resolve, 3000));
    
// //     try {
// //         // await Promise.all([
// //             // delay, // Wait for at least 3 seconds
// //             axios.post(`${baseURL}/${btoa("reports/salesreport")}`, {
// //                 reportType: periodType,
// //                 monthName: selectedMonth,
// //                 quarterName: selectedQuarter,
// //                 fuelGradeId: fuelGrade,
// //                 year: selectedYear,
// //                 businessId: localStorage.getItem('business_id'),
// //                 managerId: localStorage.getItem('user_id'),
// //                 customerId: customer
// //             }).then(response => {
// //                 console.log('response.....', response);
// //                 setSalesReport(response);
// //             })
// //         // ]);
// //     } catch (error) {
// //         console.log("Error posting details:", error);
// //     } finally {
// //         setLoading(false); // Hide loader
// //     }
// // };

// //   // const handleRun = async  () => { 
// //   //   setLoading(true); // Set loading to true when fetching data
// //   //   setTempYear(selectedYear)
// //   //   setTempMonth(selectedMonth)
// //   //   setTempQuarter(selectedQuarter)
// //   //   settempPeriodType(periodType)
// //   //   // settempFuelGrade(fuelGrade)
// //   //   // setTempCustomer(customer)
// //   //   try{
// //   //       const response = await axios.post(`${baseURL}/${btoa("reports/salesreport")}` , {
// //   //           reportType:periodType,
// //   //           monthName:selectedMonth,
// //   //           quarterName:selectedQuarter,
// //   //           fuelGradeId:fuelGrade,
// //   //           year:selectedYear,
// //   //           businessId:localStorage.getItem('business_id'),
// //   //           managerId:localStorage.getItem('user_id'),
           
// //   //           customerId: customer
// //   //       })
// //   //       console.log('response.....', response)
// //   //       setSalesReport(response)
// //   //   }
 
// //   //       catch(error){
// //   //           console.log("error posting details" , error);
// //   //         }
// //   //         finally {
// //   //           setLoading(false); // Set loading to false after data is fetched
// //   //         }
// //   // };
// //   // const isFormComplete = () => {
// //   //   return customer !== '' && invoiceType !== '' && durationType !== '' && selectedDate !== null;
// //   // };
// //   const [scrolled, setScrolled] = useState(false);

// //   useEffect(() => {
// //     const handleScroll = () => {
// //       if (window.scrollY > 0) {  // Trigger background when scrolling starts
// //         setScrolled(true);
// //       } else {
// //         setScrolled(false);
// //       }
// //     };

// //     window.addEventListener('scroll', handleScroll);
    
// //     // Clean up the event listener on component unmount
// //     return () => window.removeEventListener('scroll', handleScroll);
// //   }, []);

// //   return (
// //     <div className='ml-3 pageHeight'>
// //       {loading && <FullScreenLoader/>}
// //     <>
// //       <div 
// //   className="flex justify-between items-center w-full  pt-4 mt-4 mb-5 " 
// //   style={{ 
// //     maxWidth: '1200px', 
// //     boxSizing: 'border-box', 
// //     marginLeft: '0px', 
// //     color: '#444444', // Lighter black color
// //   }}
// // >
// // <nav aria-label="breadcrumb">
// //     <ol className="flex space-x-2 text-lg font-bold text-gray-700 ml-6">
// //       <li>
// //         {/* <a  href="/operations" className="text-blue-600 hover:text-blue-800"> */}
// //           TankReading
// //         {/* </a> */}
// //       </li>
// //       {/* <li>
// //         <span className="text-gray-400">{'>>'}</span>
// //       </li>
// //       <li>
// //           Invoice Report
// //       </li> */}
// //     </ol>
// //   </nav></div>



// //       <Box sx={{ padding: 2,paddingTop:0,paddingBottom:3,  maxWidth: '100%', backgroundColor: '#ffff', marginTop:'20px' }}>
// //         {/* <Paper elevation={3}
// //              style={{
// //               padding: '40px',
// //               paddingTop: '20px',
// //               display: 'flex',
// //               justifyContent: 'center',  // Centers horizontally
// //               alignItems: 'center',      // Centers vertically
// //             }}
// //          sx={{ padding: 5,paddingTop:'0px', borderRadius: 4,alignItems:'center', boxShadow: '0 0 20px 0 rgba(0, 0, 0, .2)',
// //  }}> */}
// //           <Grid container spacing={3} >
// //             {/* Customer Dropdown */}
// //             <Grid item md={2.1} >
// //               <div>
          
// //             <select
// //               id="Select Customer"
// //               name="Select Customer"
// //               required
// //               onChange={handleCustomerChange}
// //               value={customer}
// //               className=" border border-gray-300 text-sm font-medium rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full text-gray-500 p-2.5"
// //             >
// //               <option value="">Select Customer</option>
// //               <option value="all">All Customers</option>
         
// //                  {
// //                 customersAll.map((customer)=>{
// //                   return  <option value={customer.customer_id} >{customer.business_name}</option>

// //                 })
// //                }
               
// //             </select>
// //           </div>
// //             </Grid>
        

// // {/*               
// //      { (periodType === 'Monthly') &&
// //                     <Grid item md={2} >
// //           <div>
// //           <label
// //             for="Select Month"
// //             className="block mb-2 text-base font-medium text-gray-900 "
// //           >
// //             Select Month
// //             <span className="text-red-500 text-xl font-extrabold pl-2 pt-3">*</span>
// //           </label>
// //           <select
// //             id="Select Month"
// //             name="Select Month"
// //             required
// //             value={selectedMonth}
// //             onChange={(e) => {setSelectedMonth(e.target.value);}}
// //             label="Select Month"
// //             className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
// //           >
// //             <option value="">--Select Month--</option>
// //             {months.map((month) => (
// //                 <option key={month} value={month} sx={{ margin: 0, padding: '1px 16px' }}>
// //                   {month}
// //                 </option>
// //               ))}         
// //           </select>
// //         </div>
// //         </Grid>
// // }
// //       { (periodType === 'Quarterly') &&
// //                      <Grid item md={2} >
// //           <div>
// //           <label
// //             for="Select Quarter"
// //             className="block mb-2 text-base font-medium text-gray-900 "
// //           >
// //             Select Quarter
// //             <span className="text-red-500 text-xl font-extrabold pl-2 pt-3">*</span>
// //           </label>
// //           <select
// //             id="Select Quarter"
// //             name="Select Quarter"
// //             required
// //             value={selectedQuarter}
// //             onChange={(e) => {setSelectedQuarter(e.target.value);}}  
// //             label="Select Quarter"
// //             className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
// //           >
// //             <option value="">--Select Quarter--</option>
// //             {quarters.map((quarter) => (
// //             <option key={quarter.fName} value={quarter.bName} sx={{ margin: 0, padding: '1px 16px' }}>
// //               {quarter.fName}
// //             </option>
// //           ))}      
// //           </select>
// //         </div> 
// //         </Grid>
// //       }
// //                 {(periodType === 'Yearly' || periodType === 'Monthly' || periodType === "Quarterly") &&
// //                                  <Grid item md={1.8} >
// //           <div>
// //           <label
// //             for="Select Year"
// //             className="block mb-2 text-base font-medium text-gray-900 "
// //           >
// //             Select Year
// //             <span className="text-red-500 text-xl font-extrabold pl-2 pt-3">*</span>
// //           </label>
// //           <select
// //             id="Select Year"
// //             name="Select Year"
// //             required
// //             value={selectedYear}
// //             onChange={(e) => {setSelectedYear(e.target.value);}} 
// //             label="Select Year"
// //             className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
// //           >
// //             <option value="">--Select Year--</option>          
// //             {years.map((year) => (
// //               <option key={year} value={year} sx={{ margin: 0, padding: '1px 16px' }}>
// //                 {year}
// //               </option>
// //             ))}          
// //           </select>
// //         </div>
// //         </Grid> */}
// //             <Grid item >
// //            <Button variant="contained"          
// //             disabled = {!isButtonEnabled()}
// //            sx={{
// //             backgroundColor: 'rgb(231 70 148)',  // Pink color
// //             color: 'white',
// //              height:'43px',
// //             '&:hover': {
// //               backgroundColor: 'rgb(231 70 148)',  // Darker shade of pink on hover
// //             }
// //           }}
// //        endIcon={<SendIcon />}
// //       //  onClick={()=>{generateUSDateTime(); handleRun()}} 
// //        >
// //   Run
// // </Button>
// //            </Grid>
// //           </Grid>


// //         {/* </Paper> */}
// //       </Box>
// //       {
// //       RunClicked ?
// //   <div id="runclicked">
// //    { RunClicked &&
        
// //     <div style={{  borderRadius: "20px",
// //       maxWidth: '100%',
// //       overflowX: 'auto',
// //       margin: '0 auto', 
// //       flexDirection: 'column',
// //                     boxShadow: '0 0 25px 0 rgba(0, 0, 0, 0.2)',
// //                    margin:'55px',
// //                    marginTop:'45px',
// //                    paddingBottom:'80px',
// //                    margin:'45px',
// //                    padding:'20px',
// //                    justifyContent:'center',
// //                    display:'flex',
// //                    flexDirection:'column'
// //           }}>
// //     {salesResponse && salesResponse.data && salesResponse.data.sales_data.length > 0 ?
// //     <Grid container alignItems="end" justifyContent="end" >
// //       {/* PDF Download Icon */}
// //      <Grid item xs={12} md={4}>
// //         <Box p={2} className="font-bold text-md text-gray-400">
// //         {
// //             RunClicked && <p>Report RunTime: {dateTime} </p>

// //         }
// //         </Box>
    
      
// //      </Grid>
// //      <Grid item xs={12} md={8}>
// //      <Grid container alignItems="end" justifyContent="end" >

// //       <Grid item>
// //       <Tooltip title="Pdf" position="top" trigger="mouseenter">

// //         <IconButton aria-label="Download PDF"  
// //              sx={{
// //                 backgroundColor: 'transparent', // No background color initially
// //                 borderRadius: '50%', // Circular shape
// //                 padding: '12px', // Adjust padding for the circular button
// //                 '&:hover': {
// //                   backgroundColor: 'rgba(227, 140, 151, 0.3)', // Light background color on hover
// //                 },
// //                 transition: 'background-color 0.3s ease', // Smooth transition effect
// //               }}
// //               onMouseEnter={() => setPdfHovered(true)}
// //               onMouseLeave={() => setPdfHovered(false)}
// //  >
// //   {
// //     pdfhovered ? <FileDownloadIcon sx={{ fontSize: 20, color: 'red' }} />
// //     :           <PictureAsPdfIcon fontSize="small" color="error" />

// //   }
// //         </IconButton>
// //         </Tooltip>
// //       </Grid>
// //       <Grid item>
// //       <Tooltip title="Print" position="top" trigger="mouseenter">
// //         <IconButton aria-label="Print" onClick={handlePrint}
// //                 sx={{
// //                     backgroundColor: 'transparent', // No background color initially
// //                     borderRadius: '50%', // Circular shape
// //                     padding: '12px', // Adjust padding for the circular button
// //                     '&:hover': {
// //                       backgroundColor: 'rgba(0, 0, 0, 0.1)', // Light background color on hover
// //                     },
// //                     transition: 'background-color 0.3s ease', // Smooth transition effect
// //                   }}
        
// //         >
// //           <PrintIcon fontSize="small" sx={{color:'black'}}/>
// //         </IconButton>
// //         </Tooltip>
// //       </Grid>

// //       {/* Tax File Icon */}
// //       <Grid item>
// //       <Tooltip title="Excel" position="top" trigger="mouseenter">

// //         <IconButton aria-label="Tax File"
// //             sx={{
// //                 backgroundColor: 'transparent', // No background color initially
// //                 borderRadius: '50%', // Circular shape
// //                 padding: '12px', // Adjust padding for the circular button
// //                 '&:hover': {
// //                   backgroundColor: 'rgba(143, 191, 96, 0.3)', // Light background color on hover
// //                 },
// //                 transition: 'background-color 0.3s ease', // Smooth transition effect
// //               }}
// //               onMouseEnter={() => setTaxHovered(true)}
// //               onMouseLeave={() => setTaxHovered(false)}
        
// //         >
// //           {
// //             taxhovered ? <FileDownloadIcon sx={{ fontSize: 20, color: 'red' }} />
// //             :
// //             <DescriptionIcon fontSize="small" style={{ color: '#4CAF50' }} />

// //           }
// //         </IconButton>
// //         </Tooltip>

// //       </Grid>
// //       </Grid>
// //       </Grid>
// //     </Grid>
// //     :''}
// //     {/* <div className="flex justify-center"> */}

// //     <div ref={contentRef} style={{width:'100%',
// //          height:'auto',
// //          justifyContent:'center',
// //           alignSelf:"center",
// //           borderRadius: "10px",
// //           boxShadow: '0 0 20px 0 rgba(0, 0, 0, .1)',
// //           padding:'20px',
// //           marginTop:'20px'
// //           }}>

// // <div style={{  display: 'flex',
// //     alignItems: 'center',
// //     height:'110px',
// //     justifyContent: 'space-between',
// //     marginBottom:'20px',
// //     backgroundColor: 'rgba(180,180,180,0.1)'}}>
// //       {/* Left Image */}
// //       {/* <img
// //         src="https://via.placeholder.com/60"
// //         alt="Left Logo"
// //         style={{ width: '60px', // Set the size of the images
// //             height: '60px'}}
// //       /> */}
// //       {/* <img src="/static/media/logo.0ea4b695a3030ea659a8.png" alt="flownia" className="w-max" ></img> */}
// //               {userRole !== "1" && (
// //           <div className="">
// //             {imageSource ? (
// //               <img
// //                 style={{ width: '140px',
// //                     height: '60px'}}
// //                 src={`${baseURL}uploads/img/business/${imageSource}`} alt="Selected" />
// //             ) : (
// //               <p>No image selected</p>
// //             )}
// //           </div>
// //         )}

// //       {/* Middle: Three Texts Stacked */}
// //       <div style={{ textAlign: 'center', marginLeft:'90px' }}>
// //   <p>
// //     <strong style={{ fontWeight: '600', fontSize: '18px', margin: '5px' }}>
// //       {imageResponse.business_name}
// //     </strong>
// //   </p>
// //   <p>
// //     <strong style={{ fontWeight: '600', fontSize: '18px', margin: '5px' }}>
// //       {imageResponse.business_address}
// //     </strong>
// //   </p>
// //   <p>
// //     <strong style={{ fontWeight: '600', fontSize: '18px', margin: '5px' }}>
// //       {imageResponse.business_city}, {imageResponse.business_state}, {imageResponse.business_zip_code}
// //     </strong>
// //   </p>
// // </div>



// //       {/* Right Image */}
// //       <img src="/static/media/logo.0ea4b695a3030ea659a8.png" alt="invoicefilelogo" className="w-max" style={{ width: '240px', // Set the size of the images
// //             height: '200px'}}></img>
// //     </div>
// //     {RunClicked &&
// //     (
// //     <div style={{ color: '#1976d2', textAlign:'center', fontSize:'20px', fontWeight:'bold', marginTop: '50px'}} className="mb-1" >
// //              {(tempperiodType === 'Yearly' && tempYear && RunClicked)  &&  `${tempYear} - Sales - ${salesResponse?.data?.customerName === "all" ? "All Customers" : salesResponse?.data?.customerName} - ${salesResponse?.data?.fuelGrade === "all" ? "All Fuel Grades" : salesResponse?.data?.fuelGrade}  `}
// //      {(tempperiodType === 'Monthly' && tempYear && tempMonth && RunClicked) &&`${tempMonth} - ${tempYear} - Sales - ${salesResponse?.data?.customerName === "all" ? "All Customers" : salesResponse?.data?.customerName} - ${salesResponse?.data?.fuelGrade === "all" ? "All Fuel Grades" : salesResponse?.data?.fuelGrade}  `}
// //          {(tempperiodType === 'Quarterly' && tempYear && tempQuarter && RunClicked) && ` ${tempQuarter == 'Q1' ? 'Q1 (Mar-May)' :''} ${tempQuarter == 'Q2' ? 'Q2 (June-Aug)':'' } ${tempQuarter == 'Q3' ? 'Q3 (Sep-Nov)' :'' } ${tempQuarter == 'Q4' ? 'Q4 (Dec-Feb)' :'' } - ${tempYear} - Sales - ${salesResponse?.data?.customerName === "all" ? "All Customers" : salesResponse?.data?.customerName} - ${salesResponse?.data?.fuelGrade === "all" ? "All Fuel Grades" : salesResponse?.data?.fuelGrade} `}
// //          <hr className="border-t border-gray-300 mb-1 mt-2" />
// //         </div>
// //    )}


// // {/* <SalesReportTable salesResponse={salesResponse}/> */}

// //     {RunClicked && salesResponse && salesResponse.data && salesResponse.data.sales_data.length > 0 ?

// //       (<div style={{ marginTop: '14px' }}>
// //         <table style={{ width: '45%', maxWidth: '600px', margin: '30px auto', fontWeight: 'bold', borderCollapse: 'collapse' }}>
// //           {/* Header Row */}
// //           <thead style={{ backgroundColor: 'transparent' }}>
// //             <tr style={{ textAlign: 'center', marginBottom: '10px',  color: '#3479E0' }}>
// //               <th style={{ padding: '10px',textAlign: 'left', width:'110px' }}>Fuel Grade</th>
// //               <th style={{ padding: '10px', textAlign: 'right', width:'210px' }}>Gallons</th>
// //               <th style={{ padding: '10px', textAlign: 'right' }}>Amount</th>
// //             </tr>
// //           </thead>
      
// //           {/* Data Rows */}
// //           <tbody>
// //             {salesResponse.data?.sales_data?.length > 0 &&
// //               salesResponse.data.sales_data.map((item) => (
// //                 <tr key={item.product_id || item.product_name} style={{ textAlign: 'center', marginBottom: '10px' }}>
// //                   <td style={{ padding: '10px', fontWeight: 'bold', textAlign: 'left' }}>{item.product_name || ''}</td>
// //                   <td style={{ padding: '10px', fontWeight: 'bold', textAlign: 'right' }}>{`${formatNumberwithoutzeros(item.total_gallons)} gl`}</td>
// //                   <td style={{ padding: '10px', fontWeight: 'bold', textAlign: 'right' }}>$ {formatNumber(item.total_amount) || 0}</td>
// //                 </tr>
// //               ))}
// //           </tbody>
      
// //           {/* Total Summary Row */}
// //           <tfoot>
// //             <tr style={{ textAlign: 'center', padding: '10px', margin: '20px auto', borderRadius: '5px' , borderTop:'1px solid grey'}}>
// //               <td style={{ padding: '10px', fontWeight: 'bold', textAlign: 'left' }}>Total:</td>
// //               <td style={{ padding: '10px', fontWeight: 'bold' , textAlign: 'right'}}>{`${formatNumberwithoutzeros(salesResponse.data?.totalGallons)} gl`}</td>
// //               <td style={{ padding: '10px', fontWeight: 'bold', textAlign: 'right' }}>$ {formatNumber(salesResponse.data?.totalAmount) || 0}</td>
// //             </tr>
// //           </tfoot>
// //         </table>
// //       </div>
// //       ):(
// //   <p style={{ color: 'red', fontSize: '18px', fontWeight: 'bold', textAlign: 'center', paddingTop:'20px' }}>
// //   No Data Available
// // </p>
// //     )}
// //     </div>
   
    
// //     </div>
// // }
// //     <hr/>
// //     </div>
// //     :     <div style={{height: '600px'}}></div>

// //         }
// //     </>
// //     </div>
// //   );
// // };

// // export default Sales;
// // import React, { useState, useEffect } from "react";
// // import {  useSelector , useDispatch} from "react-redux";
// // import axios from "axios";
// // import { addCustomer } from "../../../store/customerSlice";
// // import config  from "../../../config/config"

// // const TankReading = () => {
// //   const [selectedReport, setSelectedReport] = useState(null);
// //     const [customer, setCustomer] = useState('');
// //       const businessId = localStorage.getItem('business_id')
// //   const managerId =localStorage.getItem('user_id')
// //   const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;

// //     const handleCustomerChange = (event) => {
// //     setCustomer(event.target.value);
// //     // setSelectedDate(null); // Reset selected date
// //   };
// //   useEffect(() => {
// //   window.scrollTo(0, 0);
// //   axios
// //     .get(`${baseURL}Y3VzdG9tZXIvdmlldw==/${btoa(businessId)}`)
// //     .then((response) => {
// //       const sortedCustomerData = response.data.customers_data.sort(
// //         (a, b) => b.customer_id - a.customer_id
// //       );
// //       // console.log("sor" , sortedCustomerData);
// //       dispatch(addCustomer(sortedCustomerData))
   
// //     })
// //     .catch((error) => {
// //       console.error("Error fetching customer data:", error);
// //     });
// // }, []);
// //   const dispatch = useDispatch();


// //   const reports = [
// //     "Tank Inventory",
// //     "Tank Delivery Report",
// //     "Liquid Sensor Status Report",
// //     "CSLD Test Results",
// //     "Tank Leak Test History",
// //     "Tank Leak Test Results",
// //     "Priority Alarm History",
// //     "System Status",
// //     "Pressure Line Leak Test Results",
// //     "Pressure Line Leak Test History",
// //     "Set Tank Limits",
// //     "More...",
// //   ];
// //     const customersAll = useSelector(state => state.customer.allCustomers);


// //   return (
// //     <div className="p-12 w-full mx-auto ">
// //       <h2 className="text-xl font-bold mb-4">TankReading</h2>
      
// //       {/* Customer Selection */}
// //       <div className="mb-4">
// //                <select
// //               id="Select Customer"
// //               name="Select Customer"
// //               required
// //               onChange={handleCustomerChange}
// //               value={customer}
// //               className="mb-[50px] w-60 focus:outline-none focus:border-none border border-gray-300 text-sm font-medium rounded-lg focus:ring-primary-600 focus:border-primary-600 block text-gray-500 p-2"
// //             >
// //               <option value="">Select Customer</option>
// //               <option value="all">All Customers</option>
         
// //                  {
// //                 customersAll.map((customer)=>{
// //                   return  <option value={customer.customer_id} >{customer.business_name}</option>

// //                 })
// //                }
               
// //             </select>
// //       </div>

// //       {/* Buttons Grid */}
// //       <div className="grid grid-cols-4 gap-4 mb-6">
// //   {reports.slice(0, 8).map((report, index) => (
// //     <button
// //       key={index}
// //       className={`border rounded p-2 text-left transition-all w-full h-12 truncate flex-grow ${
// //         customer
// //           ? "cursor-pointer hover:shadow-cyan-700/50"
// //           : "cursor-not-allowed opacity-50"
// //       } ${
// //         selectedReport === report
// //           ? "bg-[#25caed] text-white hover:font-bold active:scale-90 " +
// //             (customer ? "hover:shadow-cyan-700/50 hover:shadow-lg hover:border-transparent" : "")
// //           : "border-[#25caed] font-semibold text-black " +
// //             (customer ? "hover:bg-[#25caed] hover:text-white hover:shadow-lg hover:border-transparent" : "")
// //       }`}
// //       onClick={() => customer && setSelectedReport(report)}
// //       disabled={!customer}
// //     >
// //       {report}
// //     </button>
// //   ))}
// // </div>

// // {/* Last row with adjusted widths */}
// // <div className="grid grid-cols-4 gap-4 mb-6">
// //   {reports.slice(8).map((report, index) => (
// //     <button
// //       key={index}
// //       className={`border rounded p-2 text-left transition-all h-12 truncate flex-grow ${
// //         customer
// //           ? "cursor-pointer hover:shadow-cyan-700/50"
// //           : "cursor-not-allowed opacity-50"
// //       } ${
// //         selectedReport === report
// //           ? "bg-[#25caed] text-white hover:font-bold active:scale-90 " +
// //             (customer ? "hover:shadow-cyan-700/50 hover:shadow-lg hover:border-transparent" : "")
// //           : "border-[#25caed] font-semibold text-black " +
// //             (customer ? "hover:bg-[#25caed] hover:text-white hover:shadow-lg hover:border-transparent" : "")
// //       }`}
// //       onClick={() => customer && setSelectedReport(report)}
// //       disabled={!customer}
// //     >
// //       {report}
// //     </button>
// //   ))}
// // </div>


// //       {/* Report Display Frame */}
// //       {selectedReport === "Tank Inventory" && (
// //         <div className="border rounded p-6 text-center mt-6 min-h-[300px] bg-white"
// //          style={{boxShadow: '0 0 20px 0 rgba(0, 0, 0,.3)',}}>
// //           <p className="text-gray-700"> Report:  <strong>{selectedReport} </strong></p>
      
// //       <div className="min-h-screen  flex items-center justify-center p-4">
// //       <div className="bg-white shadow-lg p-6 text-sm mt-[20px] mb-[20px]" style={{width:'320px', boxShadow: '0 0 20px 0 rgba(0, 0, 0,.3)'}}
// //       >
// //         <div className="space-y-1">
// //           {/* Header */}
// //           <div className="mt-2 text-left pl-4 mb-[0px]">
// //             <div>249517 0363-0902</div>
// //             <div>RED CAP SUNOCO</div>
// //             <div>127 ERIE BLVD</div>
// //             <div>SCHENECTADY, NY</div>
// //             <div className="mt-4" >FEB 6, 2025 6:52 AM</div>
// //           </div>

// //           {/* System Status */}
// //           <div className="pt-[30px]">
// //             <div className="custom-dashed-border mb-[3px]">SYSTEM STATUS REPORT</div>
// //             <div className="text-left pl-4 mt-[4px]">T 2: DELIVERY NEEDED</div>
// //           </div>

// //           {/* Inventory Report */}
// //           <div className="pt-[30px]">
// //             <div className="text-left pl-4">INVENTORY REPORT</div>
// //             <div className="mt-4">
// //               {/* Tank 1 */}
// //               <div className="text-left pl-4">T 1: UNLD</div>
// //               <div className="pl-4">
// //                 <div className="flex justify-between">
// //                   <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'left'}}>VOLUME</span>
// //                   <span>= </span>
// //                   <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'right'}}> 10149 GALS</span>
// //                 </div>
// //                 <div className="flex justify-between">
// //                   <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'left'}}>ULLAGE</span>
// //                   <span>= </span>
// //                   <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'right'}}>4827 GALS</span>
// //                 </div>
// //                 <div className="flex justify-between">
// //                   <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'left'}}>90% ULLAGE</span>
// //                   <span>= </span>
// //                   <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'right'}}> 3329 GALS</span>
// //                 </div>
// //                 <div className="flex justify-between">
// //                   <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'left'}}>HEIGHT</span>
// //                   <span>= </span>
// //                   <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'right'}}> 74.55 INCHES</span>
// //                 </div>
// //                 <div className="flex justify-between">
// //                   <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'left'}}>WATER VOL</span>
// //                   <span>= </span>
// //                   <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'right'}}> 0 GALS</span>
// //                 </div>
// //                 <div className="flex justify-between">
// //                   <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'left'}}>WATER</span>
// //                   <span>= </span>
// //                   <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'right'}}>0.00 INCHES</span>
// //                 </div>
// //                 <div className="flex justify-between">
// //                   <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'left'}}>TEMP</span>
// //                   <span>= </span>
// //                   <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'right'}}>34.9 DEG F</span>
// //                 </div>
// //               </div>

// //               {/* Tank 2 */}
// //               <div className="mt-5">
// //                 <div className="text-left pl-4">T 2: ULTRA</div>
// //                 <div className="pl-4">
// //                   <div className="flex justify-between">
// //                     <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'left'}}>VOLUME</span>
// //                     <span>=</span>
// //                     <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'right'}}> 1804 GALS</span>
// //                   </div>
// //                   <div className="flex justify-between">
// //                     <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'left'}}>ULLAGE</span>
// //                     <span>=</span>
// //                     <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'right'}}>13172 GALS</span>
// //                   </div>
// //                   <div className="flex justify-between">
// //                     <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'left'}}>90% ULLAGE</span>
// //                     <span>=</span>
// //                     <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'right'}}> 11674 GALS</span>
// //                   </div>
// //                   <div className="flex justify-between">
// //                     <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'left'}}>HEIGHT</span>
// //                     <span>=</span>
// //                     <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'right'}}> 21.36 INCHES</span>
// //                   </div>
// //                   <div className="flex justify-between">
// //                     <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'left'}}>WATER VOL</span>
// //                     <span>=</span>
// //                     <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'right'}}> 0 GALS</span>
// //                   </div>
// //                   <div className="flex justify-between">
// //                     <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'left'}}>WATER</span>
// //                     <span>=</span>
// //                     <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'right'}}> 0.00 INCHES</span>
// //                   </div>
// //                   <div className="flex justify-between">
// //                     <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'left'}}>TEMP</span> 
// //                       <span>=</span>
// //                     <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'right'}}> 44.5 DEG F</span>
// //                   </div>
// //                 </div>
// //               </div>

// //               {/* Tank 3 */}
// //               <div className="mt-5">
// //                 <div className="text-left pl-4">T 3: DIESEL</div>
// //                 <div className="pl-4">
// //                   <div className="flex justify-between">
// //                     <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'left'}}>VOLUME</span>
// //                     <span>=</span>
// //                     <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'right'}}> 2917 GALS</span>
// //                   </div>
// //                   <div className="flex justify-between">
// //                     <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'left'}}>ULLAGE</span>
// //                     <span>=</span>
// //                     <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'right'}}> 6767 GALS</span>
// //                   </div>
// //                   <div className="flex justify-between">
// //                     <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'left'}}>90% ULLAGE</span>
// //                     <span>=</span>
// //                     <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'right'}}>5798 GALS</span>
// //                   </div>
// //                   <div className="flex justify-between">
// //                     <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'left'}}>HEIGHT</span>
// //                     <span>=</span>
// //                     <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'right'}}> 30.52 INCHES</span>
// //                   </div>
// //                   <div className="flex justify-between">
// //                     <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'left'}}>WATER VOL</span>
// //                     <span>=</span>
// //                     <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'right'}}>15 GALS</span>
// //                   </div>
// //                   <div className="flex justify-between">
// //                     <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'left'}}>WATER</span>
// //                     <span>=</span>
// //                     <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'right'}}> 0.85 INCHES</span>
// //                   </div>
// //                   <div className="flex justify-between">
// //                     <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'left'}}>TEMP</span>
// //                     <span>=</span>
// //                     <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'right'}}> 43.9 DEG F</span>
// //                   </div>
// //                 </div>
// //               </div>
// //             </div>
// //           </div>

// //           {/* Footer */}
// //           <div className="pt-[30px] text-center">* * * * * * * * * * * END * * * * * * * * * * *</div>
// //         </div>
// //       </div>
// //     </div>
 
// //         </div>
// //       )}
// //     </div>
// //   );
// // };

// // export default TankReading;

// // import React from 'react';
// // import { FiChevronRight, FiDatabase, FiTruck, FiAlertCircle, FiBarChart2, FiLayers } from 'react-icons/fi'; 
// // import { useNavigate } from 'react-router-dom';

// // const TankReading = () => {
// //   const navigate = useNavigate();

// //   const categories = [
// //     {
// //       title: 'Overall Status',
// //       icon: <FiBarChart2 className="w-5 h-5 text-blue-600" />, 
// //       // color: 'bg-gradient-to-br from-blue-50 to-blue-100',
// //       color:'bg-gray-100',
// //       borderColor: 'border-blue-200',
// //       items: [
// //         { code: '101', label: 'System Status Report' },
// //         { code: '205', label: 'In-Tank Status Report' }
// //       ]
// //     },
// //     {
// //       title: 'Tank Inventory',
// //       icon: <FiDatabase className="w-5 h-5 text-green-600" />,  
// //       // color: 'bg-gradient-to-br from-green-50 to-green-100',
// //       color:'bg-gray-100',
// //       borderColor: 'border-green-200',
// //       items: [
// //         { code: '201', label: 'In-Tank Inventory Report' },
// //         { code: '21E', label: 'Inventory Snapshot Volume' },
// //         { code: '231', label: 'In-Tank Full Inventory Report' },
// //         { code: '20D', label: 'In-Tank Stick Height Report' }
// //       ]
// //     },
// //     {
// //       title: 'Fuel Delivery',
// //       icon: <FiTruck className="w-5 h-5 text-yellow-600" />,  
// //       // color: 'bg-gradient-to-br from-yellow-50 to-yellow-100',
// //       color:'bg-gray-100',
// //       borderColor: 'border-yellow-200',
// //       items: [
// //         { code: '202', label: 'In-Tank Delivery Report' },
// //         { code: '20C', label: 'In-Tank Most Recent Delivery Report' }
// //       ]
// //     },
// //     {
// //       title: 'Tank Leak',
// //       icon: <FiLayers className="w-5 h-5 text-red-600" />,  
// //       // color: 'bg-gradient-to-br from-red-50 to-red-100',
// //       color:'bg-gray-100',
// //       borderColor: 'border-red-200',
// //       items: [
// //         { code: '203', label: 'In-Tank Leak Detect Report' },
// //         { code: '207', label: 'In-Tank Leak Test History Report' },
// //         { code: '208', label: 'In-Tank Leak Test Results Report' }
// //       ]
// //     },
// //     {
// //       title: 'Alarm',
// //       icon: <FiAlertCircle className="w-5 h-5 text-purple-600" />,  
// //       // color: 'bg-gradient-to-br from-purple-50 to-purple-100',
// //       color:'bg-gray-100',
// //       borderColor: 'border-purple-200',
// //       items: [
// //         { code: '113', label: 'Active Alarm Report' },
// //         { code: '206', label: 'In-Tank Alarm History Report' }
// //       ]
// //     }
// //   ];

// //   return (
// //     <div className="w-full py-4"> 
// //       <div className=" mx-auto">
// //         <div className="flex items-start gap-6 justify-between">
// //           {categories.map((category, idx) => (
// //             <div key={idx} className=" max-w-[20%] bg-white rounded-lg  border border-gray-100 overflow-hidden">
// //               <div className={` ${category.color}`}>
// //                 <div className="flex items-center space-x-3 mb-4">
// //                   {category.icon}  
// //                   <h2 className="text-base font-semibold text-gray-900">{category.title}</h2>
// //                 </div>
// //                 <div className="space-y-1">
// //                   {category.items.map((item, itemIdx) => (
// //                     <div 
// //                       key={itemIdx}
// //                       // onClick={() => navigate(`/report/${item.code}`)}
// //                       className="flex items-center bg-white rounded-md p-2 hover:bg-white hover:shadow-sm transition-all duration-200 cursor-pointer group"
// //                     >
// //                       <div className="w-10 text-gray-500 font-mono text-sm font-medium">{item.code}</div>
// //                       <div className="flex-grow text-gray-700 text-sm">{item.label}</div>
// //                       <FiChevronRight className="w-4 h-4 text-gray-400 opacity-0 group-hover:opacity-100 transition-opacity" />
// //                     </div>
// //                   ))}
// //                 </div>
// //               </div>
// //             </div>
// //           ))}
// //         </div>
// //       </div>
// //     </div>
// //   );
// // };

// // export default TankReading;
// // import React, { useState, useRef, useEffect } from 'react';
// // import { Box, Paper } from '@mui/material';
// // import 'react-datepicker/dist/react-datepicker.css';
// // import '../Sales/sales.css'; // Add custom styles if needed
// // import SendIcon from '@mui/icons-material/Send';
// // import Button from '@mui/material/Button';
// // import {  useSelector , useDispatch} from "react-redux";
// // import { Grid, IconButton } from '@mui/material';
// // import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
// // import PrintIcon from '@mui/icons-material/Print';
// // import DescriptionIcon from '@mui/icons-material/Description';
// // import FileDownloadIcon from '@mui/icons-material/FileDownload';

// // import axios from "axios";
// // import { Tooltip } from "react-tippy";

// // import { addCustomer } from "../../../store/customerSlice";
// // import config  from "../../../config/config"
// // import Loader from "./../Loader";
// // import FullScreenLoader from '../FullScreenLoader';
// // // import SalesReportTable from './SalesReportTable';


// // const Sales = () => {
// //   const businessId = localStorage.getItem('business_id')
// //   const managerId =localStorage.getItem('user_id')
// //   const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;
// //   const [periodType, setPeriodType] = useState(""); // Tracks the selected period (Year, Month, Quarterly)
// //   const [tempperiodType, settempPeriodType] = useState(""); // Tracks the selected period (Year, Month, Quarterly)

// //   const [selectedYear, setSelectedYear] = useState(""); // Tracks the selected year
// //   const [tempYear, setTempYear] = useState(""); // Tracks the selected year

// //   const [selectedMonth, setSelectedMonth] = useState(""); // Tracks the selected month
// //   const [tempMonth, setTempMonth] = useState(""); // Tracks the selected year
// //   const [tempFuelGrade, settempFuelGrade] = useState(""); // Tracks the selected year


// //   const [selectedQuarter, setSelectedQuarter] = useState("");
// //   const [tempQuarter, setTempQuarter] = useState(""); // Tracks the selected year

// //   const [customer, setCustomer] = useState('');
// //   const [tempcustomer, setTempCustomer] = useState('');

// //   const [fuelGrade, setFuelGrade] = useState('');

// //   const customersAll = useSelector(state => state.customer.allCustomers);
// //   const dispatch = useDispatch();
// //   const [dateTime, setDateTime] = useState('');
// //   const [salesResponse, setSalesReport] = useState([]);
// //   const [imageSource, setImageSource] = useState();
// //   const [imageResponse, setimageResponse] = useState([]);
// //   const [loading, setLoading] = useState(false); // Add a loading state
// //   const [pdfhovered, setPdfHovered] = useState(false);
// //   const [taxhovered, setTaxHovered] = useState(false);
// //   const [productData, setProductData] = useState([]);


// //   const [RunClicked, setRunClicked] = useState(false); // Tracks the selected quarter
// //   const user_id = localStorage.getItem("user_id");
// //   const user_role = localStorage.getItem("user_role");
// //   const business_id = localStorage.getItem("business_id");
// //   const contentRef = useRef();
// //   const [userRole, setUserRole] = useState("");
// //   const from =  salesResponse.data?.from;
// //   const to =  salesResponse.data?.to;
// // //   const isButtonEnabled = () => {
// // //     if (periodType === 'Yearly' && selectedYear && customer && fuelGrade) return true;
// // //     if (periodType === 'Monthly' && selectedYear && selectedMonth && customer && fuelGrade) return true;
// // //     if (periodType === 'Quarterly' && selectedYear && selectedQuarter && customer && fuelGrade) return true;
// // //     return false;
// // //   };
// // const isButtonEnabled = () => {
// //     if ( customer ) return true;
// //     return false;
// //   };

// //   const formatToTwoDecimalsWithCommas = (num) => {
// //     const parts = num.toString().split('.');
// //     const integerPartWithCommas = parseInt(parts[0], 10).toLocaleString('en-US'); // Adds commas to the integer part
// //     const decimalPart = parts[1] ? parts[1].substring(0, 2) : ''; // Limits to two decimal places without rounding
  
// //     return decimalPart ? `${integerPartWithCommas}.${decimalPart}` : integerPartWithCommas;
// //   };
// //   const generateUSDateTime = () => {
// //     setRunClicked(true);
// //     const currentDate = new Date();
    
// //     // Format date and time in US format
// //     const formattedDateTime = currentDate.toLocaleString('en-US', {
// //       year: 'numeric',
// //       month: 'numeric',
// //       day: 'numeric',
// //       hour: 'numeric',
// //       minute: 'numeric',
// //       second: 'numeric', // Optional: remove if seconds aren't needed
// //       hour12: true, // 12-hour format with AM/PM
// //       timeZone: 'America/New_York' // You can change the timezone if necessary
// //     });

// //     setDateTime(formattedDateTime);
// //   };
// //   function formatNumberwithoutzeros(num) {
// //     return new Intl.NumberFormat('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(num);
// //   }
// //   const handlePrint = () => {
// //     const content = contentRef.current.innerHTML; // Get the HTML content
// //     const newWindow = window.open('', '_blank'); // Open a new window
// //     newWindow.document.write(`
// //       <html>
// //         <head>
// //           <title>Print</title>
// //           <style>
// //             /* Optional: Add styles for printed content */
// //                       @media print {
// //   img {
// //     display: block;
// //     width: 100px; /* Adjust width as needed */
// //     height: auto;
// //   }
// //               @page {
// //                 margin: 0; /* Remove default margins */
// //               }
// //               body {
// //                 margin: 0;
// //                 padding: 0;
// //               }
// //               /* Hide headers and footers */
// //               header, footer {
// //                 display: none; /* Hides header and footer */
// //               }
// //               /* You can also add styles to control the printed content */

// //             body {
// //               font-family: Arial, sans-serif;
// //               margin: 60px;
// //             }
// //           </style>
// //         </head>
// //         <body>
// //           ${content} 
// //         </body>
// //       </html>
// //     `);
// //     newWindow.document.close(); // Close the document to apply styles
// //     newWindow.print(); // Trigger the print dialog
// //   };
// //   function formatNumber(num) {
// //     return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(num);
// //   }
  
// // const Sub_total_amount =() => {
// //   return (
// //    salesResponse.data && salesResponse.data.totalAmount
// //   )
// // }
// // const Due_amount =() => {
// //   return (
// //    salesResponse.data.sales_data.length >0 ? salesResponse.data.sales_data.reduce((accumulator, currentValue) => {
// //        return accumulator + +currentValue.due_amount; // Sum the gallons property
// //      }, 0) : ''
// //   )
// // }
// // const Quantity =() => {
// //   return (
// //    salesResponse.data && salesResponse.data.totalGallons
// //   )
// // }
// //   useEffect(() => {
// //     setUserRole(localStorage.getItem("user_role"));
// //   }, []);

// //   useEffect(() => {
// //     axios
// //       .get(`${baseURL}/${btoa("business/profile")}/${btoa(business_id)}/${btoa(user_id)}/${btoa(user_role)}`)
// //       .then((response) => {
// //         console.log(response);
// //         // console.log("response businesstype", response);
// //         setImageSource(response.data.business_data[0].image_file)
// //         setimageResponse(response.data.business_data[0])
// //       })
// //       .catch((error) => {
// //         console.error("Error fetching business data:", error);

// //       });
// //       console.log(imageResponse);

// //   }, []);

// //   // Years and Months for dropdowns
// //   const years = ["2024", "2023", "2022", "2021"];
// //   const months = [
// //     "January",
// //     "February",
// //     "March",
// //     "April",
// //     "May",
// //     "June",
// //     "July",
// //     "August",
// //     "September",
// //     "October",
// //     "November",
// //     "December",
// //   ];
// //   const quarters = [{bName: "Q1",
// //     fName:"Q1 (Mar-May)"
// //   },{bName: "Q2",
// //     fName:"Q2 (June-Aug)"},{bName: "Q3",
// //         fName: "Q3 (Sep-Nov)"},{bName: "Q4",
// //             fName: "Q4 (Dec-Feb)"}]

// // // console.log('customersAll', customersAll);
// // useEffect(() => {
// //   window.scrollTo(0, 0);
// //   axios
// //     .get(`${baseURL}Y3VzdG9tZXIvdmlldw==/${btoa(businessId)}`)
// //     .then((response) => {
// //       const sortedCustomerData = response.data.customers_data.sort(
// //         (a, b) => b.customer_id - a.customer_id
// //       );
// //       // console.log("sor" , sortedCustomerData);
// //       dispatch(addCustomer(sortedCustomerData))
   
// //     })
// //     .catch((error) => {
// //       console.error("Error fetching customer data:", error);
// //     });
// // }, []);

// //   // Ref for DatePicker to programmatically open it
// //   const datePickerRef = useRef(null);

// //   const handleCustomerChange = (event) => {
// //     setCustomer(event.target.value);
// //     // setSelectedDate(null); // Reset selected date
// //   };
// //   const handleFuelChange = (event) => {
// //     setFuelGrade(event.target.value);
// //     // setSelectedDate(null); // Reset selected date
// //   };

// //   const handlePeriodChange = (event) => {
// //     // setRunClicked(false)
// //     setPeriodType(event.target.value);
// //     // setSelectedDate(null); // Reset selected date
// //   };
// //   useEffect(() => {
// //     window.scrollTo(0, 0);
// //     axios
// //       .get(`${baseURL}/${btoa("product/view")}`)
// //       .then((response) => {
// //           // console.log("prod" , response.data.products_data);
       
// //         setProductData( response.data.products_data);
// //       })
// //       .catch((error) => {
// //         console.error("Error fetching customer data:", error);
// //       });
// //   }, []);
// //   const handleRun = async () => { 
// //     setLoading(true); // Show loader
// //     setTempYear(selectedYear)
// //     setTempMonth(selectedMonth)
// //     setTempQuarter(selectedQuarter)
// //     settempPeriodType(periodType)
// //     settempFuelGrade(fuelGrade)
// //     setTempCustomer(customer)
// //     // Introduce a 3-second delay
// //     // const delay = new Promise(resolve => setTimeout(resolve, 3000));
    
// //     try {
// //         // await Promise.all([
// //             // delay, // Wait for at least 3 seconds
// //             axios.post(`${baseURL}/${btoa("reports/salesreport")}`, {
// //                 reportType: periodType,
// //                 monthName: selectedMonth,
// //                 quarterName: selectedQuarter,
// //                 fuelGradeId: fuelGrade,
// //                 year: selectedYear,
// //                 businessId: localStorage.getItem('business_id'),
// //                 managerId: localStorage.getItem('user_id'),
// //                 customerId: customer
// //             }).then(response => {
// //                 console.log('response.....', response);
// //                 setSalesReport(response);
// //             })
// //         // ]);
// //     } catch (error) {
// //         console.log("Error posting details:", error);
// //     } finally {
// //         setLoading(false); // Hide loader
// //     }
// // };

// //   // const handleRun = async  () => { 
// //   //   setLoading(true); // Set loading to true when fetching data
// //   //   setTempYear(selectedYear)
// //   //   setTempMonth(selectedMonth)
// //   //   setTempQuarter(selectedQuarter)
// //   //   settempPeriodType(periodType)
// //   //   // settempFuelGrade(fuelGrade)
// //   //   // setTempCustomer(customer)
// //   //   try{
// //   //       const response = await axios.post(`${baseURL}/${btoa("reports/salesreport")}` , {
// //   //           reportType:periodType,
// //   //           monthName:selectedMonth,
// //   //           quarterName:selectedQuarter,
// //   //           fuelGradeId:fuelGrade,
// //   //           year:selectedYear,
// //   //           businessId:localStorage.getItem('business_id'),
// //   //           managerId:localStorage.getItem('user_id'),
           
// //   //           customerId: customer
// //   //       })
// //   //       console.log('response.....', response)
// //   //       setSalesReport(response)
// //   //   }
 
// //   //       catch(error){
// //   //           console.log("error posting details" , error);
// //   //         }
// //   //         finally {
// //   //           setLoading(false); // Set loading to false after data is fetched
// //   //         }
// //   // };
// //   // const isFormComplete = () => {
// //   //   return customer !== '' && invoiceType !== '' && durationType !== '' && selectedDate !== null;
// //   // };
// //   const [scrolled, setScrolled] = useState(false);

// //   useEffect(() => {
// //     const handleScroll = () => {
// //       if (window.scrollY > 0) {  // Trigger background when scrolling starts
// //         setScrolled(true);
// //       } else {
// //         setScrolled(false);
// //       }
// //     };

// //     window.addEventListener('scroll', handleScroll);
    
// //     // Clean up the event listener on component unmount
// //     return () => window.removeEventListener('scroll', handleScroll);
// //   }, []);

// //   return (
// //     <div className='ml-3 pageHeight'>
// //       {loading && <FullScreenLoader/>}
// //     <>
// //       <div 
// //   className="flex justify-between items-center w-full  pt-4 mt-4 mb-5 " 
// //   style={{ 
// //     maxWidth: '1200px', 
// //     boxSizing: 'border-box', 
// //     marginLeft: '0px', 
// //     color: '#444444', // Lighter black color
// //   }}
// // >
// // <nav aria-label="breadcrumb">
// //     <ol className="flex space-x-2 text-lg font-bold text-gray-700 ml-6">
// //       <li>
// //         {/* <a  href="/operations" className="text-blue-600 hover:text-blue-800"> */}
// //           TankReading
// //         {/* </a> */}
// //       </li>
// //       {/* <li>
// //         <span className="text-gray-400">{'>>'}</span>
// //       </li>
// //       <li>
// //           Invoice Report
// //       </li> */}
// //     </ol>
// //   </nav></div>



// //       <Box sx={{ padding: 2,paddingTop:0,paddingBottom:3,  maxWidth: '100%', backgroundColor: '#ffff', marginTop:'20px' }}>
// //         {/* <Paper elevation={3}
// //              style={{
// //               padding: '40px',
// //               paddingTop: '20px',
// //               display: 'flex',
// //               justifyContent: 'center',  // Centers horizontally
// //               alignItems: 'center',      // Centers vertically
// //             }}
// //          sx={{ padding: 5,paddingTop:'0px', borderRadius: 4,alignItems:'center', boxShadow: '0 0 20px 0 rgba(0, 0, 0, .2)',
// //  }}> */}
// //           <Grid container spacing={3} >
// //             {/* Customer Dropdown */}
// //             <Grid item md={2.1} >
// //               <div>
          
// //             <select
// //               id="Select Customer"
// //               name="Select Customer"
// //               required
// //               onChange={handleCustomerChange}
// //               value={customer}
// //               className=" border border-gray-300 text-sm font-medium rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full text-gray-500 p-2.5"
// //             >
// //               <option value="">Select Customer</option>
// //               <option value="all">All Customers</option>
         
// //                  {
// //                 customersAll.map((customer)=>{
// //                   return  <option value={customer.customer_id} >{customer.business_name}</option>

// //                 })
// //                }
               
// //             </select>
// //           </div>
// //             </Grid>
        

// // {/*               
// //      { (periodType === 'Monthly') &&
// //                     <Grid item md={2} >
// //           <div>
// //           <label
// //             for="Select Month"
// //             className="block mb-2 text-base font-medium text-gray-900 "
// //           >
// //             Select Month
// //             <span className="text-red-500 text-xl font-extrabold pl-2 pt-3">*</span>
// //           </label>
// //           <select
// //             id="Select Month"
// //             name="Select Month"
// //             required
// //             value={selectedMonth}
// //             onChange={(e) => {setSelectedMonth(e.target.value);}}
// //             label="Select Month"
// //             className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
// //           >
// //             <option value="">--Select Month--</option>
// //             {months.map((month) => (
// //                 <option key={month} value={month} sx={{ margin: 0, padding: '1px 16px' }}>
// //                   {month}
// //                 </option>
// //               ))}         
// //           </select>
// //         </div>
// //         </Grid>
// // }
// //       { (periodType === 'Quarterly') &&
// //                      <Grid item md={2} >
// //           <div>
// //           <label
// //             for="Select Quarter"
// //             className="block mb-2 text-base font-medium text-gray-900 "
// //           >
// //             Select Quarter
// //             <span className="text-red-500 text-xl font-extrabold pl-2 pt-3">*</span>
// //           </label>
// //           <select
// //             id="Select Quarter"
// //             name="Select Quarter"
// //             required
// //             value={selectedQuarter}
// //             onChange={(e) => {setSelectedQuarter(e.target.value);}}  
// //             label="Select Quarter"
// //             className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
// //           >
// //             <option value="">--Select Quarter--</option>
// //             {quarters.map((quarter) => (
// //             <option key={quarter.fName} value={quarter.bName} sx={{ margin: 0, padding: '1px 16px' }}>
// //               {quarter.fName}
// //             </option>
// //           ))}      
// //           </select>
// //         </div> 
// //         </Grid>
// //       }
// //                 {(periodType === 'Yearly' || periodType === 'Monthly' || periodType === "Quarterly") &&
// //                                  <Grid item md={1.8} >
// //           <div>
// //           <label
// //             for="Select Year"
// //             className="block mb-2 text-base font-medium text-gray-900 "
// //           >
// //             Select Year
// //             <span className="text-red-500 text-xl font-extrabold pl-2 pt-3">*</span>
// //           </label>
// //           <select
// //             id="Select Year"
// //             name="Select Year"
// //             required
// //             value={selectedYear}
// //             onChange={(e) => {setSelectedYear(e.target.value);}} 
// //             label="Select Year"
// //             className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
// //           >
// //             <option value="">--Select Year--</option>          
// //             {years.map((year) => (
// //               <option key={year} value={year} sx={{ margin: 0, padding: '1px 16px' }}>
// //                 {year}
// //               </option>
// //             ))}          
// //           </select>
// //         </div>
// //         </Grid> */}
// //             <Grid item >
// //            <Button variant="contained"          
// //             disabled = {!isButtonEnabled()}
// //            sx={{
// //             backgroundColor: 'rgb(231 70 148)',  // Pink color
// //             color: 'white',
// //              height:'43px',
// //             '&:hover': {
// //               backgroundColor: 'rgb(231 70 148)',  // Darker shade of pink on hover
// //             }
// //           }}
// //        endIcon={<SendIcon />}
// //       //  onClick={()=>{generateUSDateTime(); handleRun()}} 
// //        >
// //   Run
// // </Button>
// //            </Grid>
// //           </Grid>


// //         {/* </Paper> */}
// //       </Box>
// //       {
// //       RunClicked ?
// //   <div id="runclicked">
// //    { RunClicked &&
        
// //     <div style={{  borderRadius: "20px",
// //       maxWidth: '100%',
// //       overflowX: 'auto',
// //       margin: '0 auto', 
// //       flexDirection: 'column',
// //                     boxShadow: '0 0 25px 0 rgba(0, 0, 0, 0.2)',
// //                    margin:'55px',
// //                    marginTop:'45px',
// //                    paddingBottom:'80px',
// //                    margin:'45px',
// //                    padding:'20px',
// //                    justifyContent:'center',
// //                    display:'flex',
// //                    flexDirection:'column'
// //           }}>
// //     {salesResponse && salesResponse.data && salesResponse.data.sales_data.length > 0 ?
// //     <Grid container alignItems="end" justifyContent="end" >
// //       {/* PDF Download Icon */}
// //      <Grid item xs={12} md={4}>
// //         <Box p={2} className="font-bold text-md text-gray-400">
// //         {
// //             RunClicked && <p>Report RunTime: {dateTime} </p>

// //         }
// //         </Box>
    
      
// //      </Grid>
// //      <Grid item xs={12} md={8}>
// //      <Grid container alignItems="end" justifyContent="end" >

// //       <Grid item>
// //       <Tooltip title="Pdf" position="top" trigger="mouseenter">

// //         <IconButton aria-label="Download PDF"  
// //              sx={{
// //                 backgroundColor: 'transparent', // No background color initially
// //                 borderRadius: '50%', // Circular shape
// //                 padding: '12px', // Adjust padding for the circular button
// //                 '&:hover': {
// //                   backgroundColor: 'rgba(227, 140, 151, 0.3)', // Light background color on hover
// //                 },
// //                 transition: 'background-color 0.3s ease', // Smooth transition effect
// //               }}
// //               onMouseEnter={() => setPdfHovered(true)}
// //               onMouseLeave={() => setPdfHovered(false)}
// //  >
// //   {
// //     pdfhovered ? <FileDownloadIcon sx={{ fontSize: 20, color: 'red' }} />
// //     :           <PictureAsPdfIcon fontSize="small" color="error" />

// //   }
// //         </IconButton>
// //         </Tooltip>
// //       </Grid>
// //       <Grid item>
// //       <Tooltip title="Print" position="top" trigger="mouseenter">
// //         <IconButton aria-label="Print" onClick={handlePrint}
// //                 sx={{
// //                     backgroundColor: 'transparent', // No background color initially
// //                     borderRadius: '50%', // Circular shape
// //                     padding: '12px', // Adjust padding for the circular button
// //                     '&:hover': {
// //                       backgroundColor: 'rgba(0, 0, 0, 0.1)', // Light background color on hover
// //                     },
// //                     transition: 'background-color 0.3s ease', // Smooth transition effect
// //                   }}
        
// //         >
// //           <PrintIcon fontSize="small" sx={{color:'black'}}/>
// //         </IconButton>
// //         </Tooltip>
// //       </Grid>

// //       {/* Tax File Icon */}
// //       <Grid item>
// //       <Tooltip title="Excel" position="top" trigger="mouseenter">

// //         <IconButton aria-label="Tax File"
// //             sx={{
// //                 backgroundColor: 'transparent', // No background color initially
// //                 borderRadius: '50%', // Circular shape
// //                 padding: '12px', // Adjust padding for the circular button
// //                 '&:hover': {
// //                   backgroundColor: 'rgba(143, 191, 96, 0.3)', // Light background color on hover
// //                 },
// //                 transition: 'background-color 0.3s ease', // Smooth transition effect
// //               }}
// //               onMouseEnter={() => setTaxHovered(true)}
// //               onMouseLeave={() => setTaxHovered(false)}
        
// //         >
// //           {
// //             taxhovered ? <FileDownloadIcon sx={{ fontSize: 20, color: 'red' }} />
// //             :
// //             <DescriptionIcon fontSize="small" style={{ color: '#4CAF50' }} />

// //           }
// //         </IconButton>
// //         </Tooltip>

// //       </Grid>
// //       </Grid>
// //       </Grid>
// //     </Grid>
// //     :''}
// //     {/* <div className="flex justify-center"> */}

// //     <div ref={contentRef} style={{width:'100%',
// //          height:'auto',
// //          justifyContent:'center',
// //           alignSelf:"center",
// //           borderRadius: "10px",
// //           boxShadow: '0 0 20px 0 rgba(0, 0, 0, .1)',
// //           padding:'20px',
// //           marginTop:'20px'
// //           }}>

// // <div style={{  display: 'flex',
// //     alignItems: 'center',
// //     height:'110px',
// //     justifyContent: 'space-between',
// //     marginBottom:'20px',
// //     backgroundColor: 'rgba(180,180,180,0.1)'}}>
// //       {/* Left Image */}
// //       {/* <img
// //         src="https://via.placeholder.com/60"
// //         alt="Left Logo"
// //         style={{ width: '60px', // Set the size of the images
// //             height: '60px'}}
// //       /> */}
// //       {/* <img src="/static/media/logo.0ea4b695a3030ea659a8.png" alt="flownia" className="w-max" ></img> */}
// //               {userRole !== "1" && (
// //           <div className="">
// //             {imageSource ? (
// //               <img
// //                 style={{ width: '140px',
// //                     height: '60px'}}
// //                 src={`${baseURL}uploads/img/business/${imageSource}`} alt="Selected" />
// //             ) : (
// //               <p>No image selected</p>
// //             )}
// //           </div>
// //         )}

// //       {/* Middle: Three Texts Stacked */}
// //       <div style={{ textAlign: 'center', marginLeft:'90px' }}>
// //   <p>
// //     <strong style={{ fontWeight: '600', fontSize: '18px', margin: '5px' }}>
// //       {imageResponse.business_name}
// //     </strong>
// //   </p>
// //   <p>
// //     <strong style={{ fontWeight: '600', fontSize: '18px', margin: '5px' }}>
// //       {imageResponse.business_address}
// //     </strong>
// //   </p>
// //   <p>
// //     <strong style={{ fontWeight: '600', fontSize: '18px', margin: '5px' }}>
// //       {imageResponse.business_city}, {imageResponse.business_state}, {imageResponse.business_zip_code}
// //     </strong>
// //   </p>
// // </div>



// //       {/* Right Image */}
// //       <img src="/static/media/logo.0ea4b695a3030ea659a8.png" alt="invoicefilelogo" className="w-max" style={{ width: '240px', // Set the size of the images
// //             height: '200px'}}></img>
// //     </div>
// //     {RunClicked &&
// //     (
// //     <div style={{ color: '#1976d2', textAlign:'center', fontSize:'20px', fontWeight:'bold', marginTop: '50px'}} className="mb-1" >
// //              {(tempperiodType === 'Yearly' && tempYear && RunClicked)  &&  `${tempYear} - Sales - ${salesResponse?.data?.customerName === "all" ? "All Customers" : salesResponse?.data?.customerName} - ${salesResponse?.data?.fuelGrade === "all" ? "All Fuel Grades" : salesResponse?.data?.fuelGrade}  `}
// //      {(tempperiodType === 'Monthly' && tempYear && tempMonth && RunClicked) &&`${tempMonth} - ${tempYear} - Sales - ${salesResponse?.data?.customerName === "all" ? "All Customers" : salesResponse?.data?.customerName} - ${salesResponse?.data?.fuelGrade === "all" ? "All Fuel Grades" : salesResponse?.data?.fuelGrade}  `}
// //          {(tempperiodType === 'Quarterly' && tempYear && tempQuarter && RunClicked) && ` ${tempQuarter == 'Q1' ? 'Q1 (Mar-May)' :''} ${tempQuarter == 'Q2' ? 'Q2 (June-Aug)':'' } ${tempQuarter == 'Q3' ? 'Q3 (Sep-Nov)' :'' } ${tempQuarter == 'Q4' ? 'Q4 (Dec-Feb)' :'' } - ${tempYear} - Sales - ${salesResponse?.data?.customerName === "all" ? "All Customers" : salesResponse?.data?.customerName} - ${salesResponse?.data?.fuelGrade === "all" ? "All Fuel Grades" : salesResponse?.data?.fuelGrade} `}
// //          <hr className="border-t border-gray-300 mb-1 mt-2" />
// //         </div>
// //    )}


// // {/* <SalesReportTable salesResponse={salesResponse}/> */}

// //     {RunClicked && salesResponse && salesResponse.data && salesResponse.data.sales_data.length > 0 ?

// //       (<div style={{ marginTop: '14px' }}>
// //         <table style={{ width: '45%', maxWidth: '600px', margin: '30px auto', fontWeight: 'bold', borderCollapse: 'collapse' }}>
// //           {/* Header Row */}
// //           <thead style={{ backgroundColor: 'transparent' }}>
// //             <tr style={{ textAlign: 'center', marginBottom: '10px',  color: '#3479E0' }}>
// //               <th style={{ padding: '10px',textAlign: 'left', width:'110px' }}>Fuel Grade</th>
// //               <th style={{ padding: '10px', textAlign: 'right', width:'210px' }}>Gallons</th>
// //               <th style={{ padding: '10px', textAlign: 'right' }}>Amount</th>
// //             </tr>
// //           </thead>
      
// //           {/* Data Rows */}
// //           <tbody>
// //             {salesResponse.data?.sales_data?.length > 0 &&
// //               salesResponse.data.sales_data.map((item) => (
// //                 <tr key={item.product_id || item.product_name} style={{ textAlign: 'center', marginBottom: '10px' }}>
// //                   <td style={{ padding: '10px', fontWeight: 'bold', textAlign: 'left' }}>{item.product_name || ''}</td>
// //                   <td style={{ padding: '10px', fontWeight: 'bold', textAlign: 'right' }}>{`${formatNumberwithoutzeros(item.total_gallons)} gl`}</td>
// //                   <td style={{ padding: '10px', fontWeight: 'bold', textAlign: 'right' }}>$ {formatNumber(item.total_amount) || 0}</td>
// //                 </tr>
// //               ))}
// //           </tbody>
      
// //           {/* Total Summary Row */}
// //           <tfoot>
// //             <tr style={{ textAlign: 'center', padding: '10px', margin: '20px auto', borderRadius: '5px' , borderTop:'1px solid grey'}}>
// //               <td style={{ padding: '10px', fontWeight: 'bold', textAlign: 'left' }}>Total:</td>
// //               <td style={{ padding: '10px', fontWeight: 'bold' , textAlign: 'right'}}>{`${formatNumberwithoutzeros(salesResponse.data?.totalGallons)} gl`}</td>
// //               <td style={{ padding: '10px', fontWeight: 'bold', textAlign: 'right' }}>$ {formatNumber(salesResponse.data?.totalAmount) || 0}</td>
// //             </tr>
// //           </tfoot>
// //         </table>
// //       </div>
// //       ):(
// //   <p style={{ color: 'red', fontSize: '18px', fontWeight: 'bold', textAlign: 'center', paddingTop:'20px' }}>
// //   No Data Available
// // </p>
// //     )}
// //     </div>
   
    
// //     </div>
// // }
// //     <hr/>
// //     </div>
// //     :     <div style={{height: '600px'}}></div>

// //         }
// //     </>
// //     </div>
// //   );
// // };

// // export default Sales;



// import React, { useState, useEffect } from "react";
// import {  useSelector , useDispatch} from "react-redux";
// import axios from "axios";
// import { addCustomer } from "../../../store/customerSlice";
// import config  from "../../../config/config"
// import FullScreenLoader from './../FullScreenLoader';
// import { FiChevronRight, FiDatabase, FiTruck, FiAlertCircle, FiBarChart2, FiLayers } from 'react-icons/fi'; 
// import AlarmIcon from '@mui/icons-material/Alarm';

// const TankReading = () => {
//   const [selectedReport, setSelectedReport] = useState(null);
//     const [customer, setCustomer] = useState('');
//       const businessId = localStorage.getItem('business_id')
//   const managerId =localStorage.getItem('user_id')
//   const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;
//   const [loading, setLoading] = useState(true); // Add a loading state

//     const handleCustomerChange = (event) => {
//     setCustomer(event.target.value);
//     setSelectedReport('')
//     // setSelectedDate(null); // Reset selected date
//   };
//   const [tankReadingData, setTankReadingData] = useState('');
//     const fetchTankData = async (id) => {
//       setLoading(true); // Stop loader after data fetch

//       try {
//         const response = await axios.get(`${baseURL}${btoa("tankreading/view")}/${btoa(businessId)}/${btoa(customer)}/${(id)}`);
//         setTankReadingData(response.data)
//         if(response){
//           setLoading(false); // Stop loader after data fetch

//         }

//         console.log("tank status", response);

       
//       } catch (error) {
//         console.log("error fetching Deployment status", error);
//         if(error){
//           setLoading(false); // Stop loader after data fetch

//         }
//       } finally {
//       }
//     };

//   useEffect(() => {
//   window.scrollTo(0, 0);
//   axios
//     .get(`${baseURL}Y3VzdG9tZXIvdmlldw==/${btoa(businessId)}`)
//     .then((response) => {
//       const sortedCustomerData = response.data.customers_data.sort(
//         (a, b) => b.customer_id - a.customer_id
//       );
//       // console.log("sor" , sortedCustomerData);
//       dispatch(addCustomer(sortedCustomerData))
   
//     })
//     .catch((error) => {
//       console.error("Error fetching customer data:", error);
//     });
// }, []);
//   const dispatch = useDispatch();
//   const [reportId, setReportId] = useState(null)
//   const reportIdMap = {
//     "System Status Report": "I10100",
//     "Tank Status Report": "I20501",
//     "Tank Inventory Report": "I20100",
//     "Inventory Snapshot Volume": "I21ETT",
//     "Tank Full Inventory Report": "I23100",
//     "Tank Stick Height Report": "I20D01",
//     "Tank Delivery Report": "I20201",
//     "Tank Most Recent Delivery Report": "I20C01",
//     "Tank Leak Detect Report": "I20301",
//     "Tank Leak Test History Report": "I20701",
//     "Tank Leak Test Results Report": "I20801",
//     "Active Alarm Report": "I11300",
//     "Tank Alarm History Report":"I20601"
//   };

//   const reports = [
//     "Tank Inventory",
//     "Tank Delivery Report",
//     "Liquid Sensor Status Report",
//     "CSLD Test Results",
//     "Tank Leak Test History",
//     "Tank Leak Test Results",
//     "Priority Alarm History",
//     "System Status",
//     "Pressure Line Leak Test Results",
//     "Pressure Line Leak Test History",
//     "Set Tank Limits",
//     "More...",
//   ];
//   const categories = [
//     {
//       title: 'Overall Status',
//       icon: <FiBarChart2 className="w-5 h-5 text-blue-600" />, 
//       // color: 'bg-gradient-to-br from-blue-50 to-blue-100',
//       color:'custombgColor border-0',
//       borderColor: 'border-blue-200',
//       items: [
//         { code: '101', label: 'System Status Report' },
//         { code: '205', label: 'Tank Status Report' }
//       ]
//     },
//     {
//       title: 'Tank Inventory',
//       icon: <FiDatabase className="w-5 h-5 text-green-600" />,  
//       // color: 'bg-gradient-to-br from-green-50 to-green-100',
//       color:'custombgColor border-0',
//       borderColor: 'border-green-200',
//       items: [
//         { code: '201', label: 'Tank Inventory Report' },
//         { code: '21E', label: 'Inventory Snapshot Volume' },
//         { code: '231', label: 'Tank Full Inventory Report' },
//         { code: '20D', label: 'Tank Stick Height Report' }
//       ]
//     },
//     {
//       title: 'Fuel Delivery',
//       icon: <FiTruck className="w-5 h-5 text-yellow-600" />,  
//       // color: 'bg-gradient-to-br from-yellow-50 to-yellow-100',
//       color:'custombgColor border-0',
//       borderColor: 'border-yellow-200',
//       items: [
//         { code: '202', label: 'Tank Delivery Report' },
//         { code: '20C', label: 'Tank Most Recent Delivery Report' }
//       ]
//     },
//     {
//       title: 'Tank Leak',
//       icon: <FiLayers className="w-5 h-5 text-red-600" />,  
//       // color: 'bg-gradient-to-br from-red-50 to-red-100',
//       color:'custombgColor border-0',
//       borderColor: 'border-red-200',
//       items: [
//         { code: '203', label: 'Tank Leak Detect Report' },
//         { code: '207', label: 'Tank Leak Test History Report' },
//         { code: '208', label: 'Tank Leak Test Results Report' }
//       ]
//     },
//     {
//       title: 'Alarm',
//       icon: <AlarmIcon className="w-5 h-5 text-purple-600" />,  
//       // color: 'bg-gradient-to-br from-purple-50 to-purple-100',
//       color:'custombgColor border-0',
//       borderColor: 'border-purple-200',
//       items: [
//         { code: '113', label: 'Active Alarm Report' },
//         { code: '206', label: 'Tank Alarm History Report' }
//       ]
//     }
//   ];
//     const customersAll = useSelector(state => state.customer.allCustomers);


//   return (
//     <div className=" w-full mx-auto mt-[20px]">
//       <h2 className="text-xl font-bold mb-4">TankReading</h2>
      
//       {/* Customer Selection */}
//       <div className="bg-white py-[20px] rounded-lg px-[16px] flex justify-start items-center mb-[50px]"
//       style={{ boxShadow: '0 0 8px rgba(0, 0, 0, 0.2)' }}
//       >
//         <div>
//          <label
//               for="Select Customer"
//               className="block mb-2 text-[14px] font-medium text-gray-600 "
//             >
//               Select Customer
//               <span className="text-red-500 text-[23px] font-extrabold pl-2 pt-3">*</span>
//             </label>
//   <select
//     id="Select Customer"
//     name="Select Customer"
//     required
//     onChange={handleCustomerChange}
//     value={customer}
//     className="w-60 focus:outline-none border border-gray-300 text-sm font-medium rounded-lg focus:ring-2 focus:ring-primary-600 focus:ring-offset-2 text-gray-500 p-2"

//     // className=" w-60 h-42 focus:outline-none focus:border-none border border-gray-300 text-sm font-medium rounded-lg focus:ring-primary-600 focus:border-primary-600 block text-gray-500 p-2"
//   >
//     <option value="">Select Customer</option>
//     {/* <option value="all">All Customers</option> */}

//     {customersAll.map((customer) => (
//       <option key={customer.customer_id} value={customer.customer_id}>
//         {customer.business_name}
//       </option>
//     ))}
//   </select>
//   </div>
// </div>


//       {/* Buttons Grid */}
//       {/* <div className="grid grid-cols-4 gap-4 mb-6">
//   {reports.map((report, index) => (
//     <button
//       key={index}
//       className={`border rounded p-2 text-left transition-all w-full h-12 truncate flex-grow ${
//         customer
//           ? "cursor-pointer hover:shadow-cyan-700/50"
//           : "cursor-not-allowed opacity-50"
//       } ${
//         selectedReport === report
//           ? "bg-[#25caed] text-white hover:font-bold active:scale-90 " +
//             (customer ? "hover:shadow-cyan-700/50 hover:shadow-lg hover:border-transparent" : "")
//           : "border-[#25caed] font-semibold text-black " +
//             (customer ? "hover:bg-[#25caed] hover:text-white hover:shadow-lg hover:border-transparent" : "")
//       }`}
//       onClick={() => {
//         if (customer) {
//           setTankReadingData('')
//           const selectedReportId = reportIdMap[report];
//           setSelectedReport(report);
//           setReportId(selectedReportId);
//           fetchTankData(selectedReportId); // Pass reportId directly
//         }
//       }}
      

//       disabled={!customer}
//     >
//       {report}
//     </button>
//   ))}
// </div> */}
//  <div className=" mx-auto">
//         <div className="flex items-start gap-2 justify-between">
//           {categories.map((category, idx) => (
//             <div key={idx} className=" max-w-[20%] bg-white rounded-lg overflow-hidden"
         
//             >
//               <div className={` ${category.color}`}>
//                 <div className="flex items-center space-x-3 mb-4">
//                   {category.icon}  
//                   <h2 className="text-base font-semibold text-gray-900">{category.title}</h2>
//                 </div>
//                 <div className="space-y-1">
//                   {category.items.map((item, itemIdx) => (
//                     <div 
//                       key={itemIdx}
//                       // onClick={() => navigate(`/report/${item.code}`)}
//                       onClick={() => {
//                         if (customer) {
//                           setTankReadingData('')
//                           const selectedReportId = reportIdMap[item.label];
//                           setSelectedReport(item.label);
//                           setReportId(selectedReportId);
//                           fetchTankData(selectedReportId); // Pass reportId directly
//                         }
//                       }}
//           className={`border rounded-md p-2 text-left transition-all  duration-200 w-full  flex items-center ${
//         customer
//           ? "cursor-pointer"
//           : "cursor-not-allowed opacity-50"
//       } ${(selectedReport === item.label) ? "bg-[#25caed] text-white active:scale-90 "  : " bg-white " }`}
//                       // className="flex items-center bg-white rounded-md p-2 hover:bg-white hover:shadow-sm transition-all duration-200 cursor-pointer group"
//                     >
//                       <div className="w-10 ${selectedReport === item.label ? 'text-white' : 'text-gray-500'} font-mono text-sm font-medium">{item.code}</div>
//                       <div className={`flex-grow text-sm ${selectedReport === item.label ? 'text-white' : 'text-gray-700'}`}>{item.label}</div>
//                       <FiChevronRight className="w-4 h-4 text-gray-400 opacity-0 group-hover:opacity-100 transition-opacity" />
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>

// {/* Last row with adjusted widths */}
// {/* <div className="grid grid-cols-4 gap-4 mb-6">
//   {reports.slice(8).map((report, index) => (
//     <button
//       key={index}
//       className={`border rounded p-2 text-left transition-all h-12 truncate flex-grow ${
//         customer
//           ? "cursor-pointer hover:shadow-cyan-700/50"
//           : "cursor-not-allowed opacity-50"
//       } ${
//         selectedReport === report
//           ? "bg-[#25caed] text-white hover:font-bold active:scale-90 " +
//             (customer ? "hover:shadow-cyan-700/50 hover:shadow-lg hover:border-transparent" : "")
//           : "border-[#25caed] font-semibold text-black " +
//             (customer ? "hover:bg-[#25caed] hover:text-white hover:shadow-lg hover:border-transparent" : "")
//       }`}
//       onClick={() => {customer && setSelectedReport(report); }}
//       disabled={!customer}
//     >
//       {report}
//     </button>
//   ))}
// </div> */}


//       {/* Report Display Frame */}
//       {(selectedReport === "System Status Report") && selectedReport && (
//         <div className="border rounded p-6 text-center mt-6 min-h-[300px] bg-white mt-[60px]"
//          style={{boxShadow: '0 0 20px 0 rgba(0, 0, 0,.3)',}}>
//           <p className="text-gray-700"> Report:  <strong>{selectedReport} </strong></p>
      
//       <div className="min-h-screen  flex items-start justify-center p-4 mt-[20px]">
//       <div className="bg-white shadow-lg p-6 text-sm mt-[20px] mb-[20px]" style={{width:'320px', boxShadow: '0 0 20px 0 rgba(0, 0, 0,.3)'}}
//       >
//         <div className="space-y-1">
//           <div className="mt-2 text-left pl-4 mb-[0px]">
//             <div>249517 0363-0902</div>
//             <div>RED CAP SUNOCO</div>
//             <div>127 ERIE BLVD</div>
//             <div>SCHENECTADY, NY</div>
//             <div className="mt-4" >FEB 6, 2025 6:52 AM</div>
//           </div>

//           <div className="pt-[30px]">
//             <div className="custom-dashed-border mb-[3px]">SYSTEM STATUS REPORT</div>
//             <div className="text-left pl-4 mt-[4px]">T 2: DELIVERY NEEDED</div>
//           </div>

//           <div className="pt-[30px]">
//             <div className="text-left pl-4">INVENTORY REPORT</div>
//             <div className="mt-4">
//               <div className="text-left pl-4">T 1: UNLD</div>
//               <div className="pl-4">
//                 <div className="flex justify-between">
//                   <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'left'}}>VOLUME</span>
//                   <span>= </span>
//                   <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'right'}}> 10149 GALS</span>
//                 </div>
//                 <div className="flex justify-between">
//                   <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'left'}}>ULLAGE</span>
//                   <span>= </span>
//                   <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'right'}}>4827 GALS</span>
//                 </div>
//                 <div className="flex justify-between">
//                   <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'left'}}>90% ULLAGE</span>
//                   <span>= </span>
//                   <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'right'}}> 3329 GALS</span>
//                 </div>
//                 <div className="flex justify-between">
//                   <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'left'}}>HEIGHT</span>
//                   <span>= </span>
//                   <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'right'}}> 74.55 INCHES</span>
//                 </div>
//                 <div className="flex justify-between">
//                   <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'left'}}>WATER VOL</span>
//                   <span>= </span>
//                   <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'right'}}> 0 GALS</span>
//                 </div>
//                 <div className="flex justify-between">
//                   <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'left'}}>WATER</span>
//                   <span>= </span>
//                   <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'right'}}>0.00 INCHES</span>
//                 </div>
//                 <div className="flex justify-between">
//                   <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'left'}}>TEMP</span>
//                   <span>= </span>
//                   <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'right'}}>34.9 DEG F</span>
//                 </div>
//               </div>

//               <div className="mt-5">
//                 <div className="text-left pl-4">T 2: ULTRA</div>
//                 <div className="pl-4">
//                   <div className="flex justify-between">
//                     <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'left'}}>VOLUME</span>
//                     <span>=</span>
//                     <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'right'}}> 1804 GALS</span>
//                   </div>
//                   <div className="flex justify-between">
//                     <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'left'}}>ULLAGE</span>
//                     <span>=</span>
//                     <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'right'}}>13172 GALS</span>
//                   </div>
//                   <div className="flex justify-between">
//                     <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'left'}}>90% ULLAGE</span>
//                     <span>=</span>
//                     <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'right'}}> 11674 GALS</span>
//                   </div>
//                   <div className="flex justify-between">
//                     <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'left'}}>HEIGHT</span>
//                     <span>=</span>
//                     <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'right'}}> 21.36 INCHES</span>
//                   </div>
//                   <div className="flex justify-between">
//                     <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'left'}}>WATER VOL</span>
//                     <span>=</span>
//                     <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'right'}}> 0 GALS</span>
//                   </div>
//                   <div className="flex justify-between">
//                     <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'left'}}>WATER</span>
//                     <span>=</span>
//                     <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'right'}}> 0.00 INCHES</span>
//                   </div>
//                   <div className="flex justify-between">
//                     <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'left'}}>TEMP</span> 
//                       <span>=</span>
//                     <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'right'}}> 44.5 DEG F</span>
//                   </div>
//                 </div>
//               </div>

//               <div className="mt-5">
//                 <div className="text-left pl-4">T 3: DIESEL</div>
//                 <div className="pl-4">
//                   <div className="flex justify-between">
//                     <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'left'}}>VOLUME</span>
//                     <span>=</span>
//                     <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'right'}}> 2917 GALS</span>
//                   </div>
//                   <div className="flex justify-between">
//                     <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'left'}}>ULLAGE</span>
//                     <span>=</span>
//                     <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'right'}}> 6767 GALS</span>
//                   </div>
//                   <div className="flex justify-between">
//                     <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'left'}}>90% ULLAGE</span>
//                     <span>=</span>
//                     <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'right'}}>5798 GALS</span>
//                   </div>
//                   <div className="flex justify-between">
//                     <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'left'}}>HEIGHT</span>
//                     <span>=</span>
//                     <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'right'}}> 30.52 INCHES</span>
//                   </div>
//                   <div className="flex justify-between">
//                     <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'left'}}>WATER VOL</span>
//                     <span>=</span>
//                     <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'right'}}>15 GALS</span>
//                   </div>
//                   <div className="flex justify-between">
//                     <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'left'}}>WATER</span>
//                     <span>=</span>
//                     <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'right'}}> 0.85 INCHES</span>
//                   </div>
//                   <div className="flex justify-between">
//                     <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'left'}}>TEMP</span>
//                     <span>=</span>
//                     <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'right'}}> 43.9 DEG F</span>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>

//           <div className="pt-[30px] text-center">* * * * * * * * * * * END * * * * * * * * * * *</div>
//         </div>
//       </div>
//       {/* <pre>
//       {
//       loading && selectedReport ? <FullScreenLoader /> :
      
//       tankReadingData.replace(/\\u0001|\\u0003/g, '') // Remove special control characters
//     .replace(/<br\s*\/>/g, '\n')    // Replace <br /> with newlines
//     .replace(/\\\//g, '/').replace(/[\u0001\u0003]/g, '')         // Fix escaped slashes like \/ to /
//     .trim()}
    

//       </pre> */}
//     </div>
 
//         </div>
//       )}
//     </div>
//   );
// };

// export default TankReading;













import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { addCustomer } from "../../../store/customerSlice";
import config from "../../../config/config";
import FullScreenLoader from './../FullScreenLoader';
import { FiChevronRight, FiDatabase, FiTruck, FiAlertCircle, FiBarChart2, FiLayers } from 'react-icons/fi';
import AlarmIcon from '@mui/icons-material/Alarm';

const TankReading = () => {
  const [selectedReport, setSelectedReport] = useState(null);
  const [customer, setCustomer] = useState('');
  const [hoveredItem, setHoveredItem] = useState(null);
  const businessId = localStorage.getItem('business_id');
  const managerId = localStorage.getItem('user_id');
  const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;
  const [loading, setLoading] = useState(true);
  const [tankReadingData, setTankReadingData] = useState('');
  const [reportId, setReportId] = useState(null);
  const dispatch = useDispatch();
  const customersAll = useSelector(state => state.customer.allCustomers);

  const handleCustomerChange = (event) => {
    setCustomer(event.target.value);
    setSelectedReport('');
  };
  const styles = `
  .custom-dashed-border {
    border-bottom: 1px dashed black;
    padding-bottom: 2px;
  }
`;
  const fetchTankData = async (id) => {
    setLoading(true);
    try {
      const response = await axios.get(`${baseURL}${btoa("tankreading/view")}/${btoa(businessId)}/${btoa(customer)}/${(id)}/10.143.31.84`);
      setTankReadingData(response.data);
      if (response) {
        setLoading(false);
      }
    } catch (error) {
      console.log("error fetching Deployment status", error);
      if (error) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get(`${baseURL}Y3VzdG9tZXIvdmlldw==/${btoa(businessId)}`)
      .then((response) => {
        const sortedCustomerData = response.data.customers_data.sort(
          (a, b) => b.customer_id - a.customer_id
        );
        dispatch(addCustomer(sortedCustomerData));
      })
      .catch((error) => {
        console.error("Error fetching customer data:", error);
      });
  }, []);

  const reportIdMap = {
    "System Status Report": "I10100",
    // "Tank Status Report": "I20501",
    "Tank Status Weekly Report": "I30100",

    "Tank Inventory Report": "I20100",
    "Inventory Snapshot Volume": "I21ETT",
    "Tank Full Inventory Report": "I23100",
    "Tank Stick Height Report": "I20D01",
    "Tank Delivery Report": "I20201",
    "Tank Most Recent Delivery Report": "I20C01",
    "Tank Leak Detect Report": "I20301",
    "Tank Leak Test History Report": "I20701",
    "Tank Leak Test Results Report": "I20801",
    "Active Alarm Report": "I11300",
    "Tank Alarm History Report": "I20601"
  };

  const categories = [
    {
      title: 'Overall Status',
      icon: <FiBarChart2 className="w-6 h-6 text-blue-600" />,
      color: "border-blue-200",
      items: [
        { code: '101', label: 'System Status Report' },
        { code: '205', label: 'Tank Status Weekly Report' }
      ]
    },
    {
      title: 'Tank Inventory',
      icon: <FiDatabase className="w-6 h-6 text-blue-600" />,
      color: "border-blue-200",
      items: [
        { code: '201', label: 'Tank Inventory Report' },
        { code: '21E', label: 'Inventory Snapshot Volume' },
        { code: '231', label: 'Tank Full Inventory Report' },
        { code: '20D', label: 'Tank Stick Height Report' }
      ]
    },
    {
      title: 'Fuel Delivery',
      icon: <FiTruck className="w-6 h-6 text-blue-600" />,
      color: "border-blue-200",
      items: [
        { code: '202', label: 'Tank Delivery Report' },
        { code: '20C', label: 'Tank Most Recent Delivery Report' }
      ]
    },
    {
      title: 'Tank Leak',
      icon: <FiLayers className="w-6 h-6 text-blue-600" />,
      color: "border-blue-200",
      items: [
        { code: '203', label: 'Tank Leak Detect Report' },
        { code: '207', label: 'Tank Leak Test History Report' },
        { code: '208', label: 'Tank Leak Test Results Report' }
      ]
    },
    {
      title: 'Alarm',
      icon: <AlarmIcon className="w-6 h-6 text-blue-600" sx={{ fontSize: 24 }} />,
      color: "border-blue-200",
      items: [
        { code: '113', label: 'Active Alarm Report' },
        { code: '206', label: 'Tank Alarm History Report' }
      ]
    }
  ];

  const handleNavigation = (item) => {
    if (customer) {
      setTankReadingData('');
      const selectedReportId = reportIdMap[item.label];
      setSelectedReport(item.label);
      setReportId(selectedReportId);
      fetchTankData(selectedReportId);
    }
  };
  const formatDate = (dateStr) => {
    if (!dateStr) return "";
    
    // Example from backend: "04/02/25 3:00 AM"
    const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    
    try {
      // Split into date and time parts (accounting for potential AM/PM)
      const mainParts = dateStr.split(' ');
      const dateParts = mainParts[0].split('/');
      
      if (dateParts.length !== 3) return dateStr;
      
      const month = months[parseInt(dateParts[0], 10) - 1];
      const day = parseInt(dateParts[1], 10);
      const year = dateParts[2].length === 2 ? `20${dateParts[2]}` : dateParts[2];
      
      // Handle time part - preserve format from backend including AM/PM
      let timeStr = "";
      if (mainParts.length > 1) {
        timeStr = mainParts.slice(1).join(' '); // Rejoin to handle "3:00 AM" format
      }
      
      return `${month} ${day}, ${year} ${timeStr}`;
    } catch (error) {
      console.log("Date formatting error:", error);
      return dateStr; // Return original if error
    }
  };

  return (
    <div className="w-full px-6 py-4 mt-5">
      <h2 className="text-xl font-bold mb-4">Tank Reading</h2>
      <style>{styles}</style>

      {/* Customer Selection */}
      <div className="bg-white p-4 rounded-lg mb-6 shadow-md">
        <div>
          <label
            htmlFor="selectCustomer"
            className="block mb-2 text-sm font-medium text-gray-600"
          >
            Select Customer
            <span className="text-red-500 text-lg font-bold ml-1">*</span>
          </label>
          <select
            id="selectCustomer"
            name="selectCustomer"
            required
            onChange={handleCustomerChange}
            value={customer}
            className="w-60 focus:outline-none border border-gray-300 text-sm font-medium rounded-lg focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 text-gray-500 p-2"
          >
            <option value="">Select Customer</option>
            {customersAll.map((customer) => (
              <option key={customer.customer_id} value={customer.customer_id}>
                {customer.business_name}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* Report Categories */}
      <div className="w-full bg-gray-50">
        <div className="max-w-7xl">
          <div className="grid grid-cols-5 gap-6">
            {categories.map((category, idx) => (
              <div key={idx}>
                <div className="flex items-center space-x-3 mb-5 pb-2 border-b">
                  <div className="p-2 bg-blue-50 rounded-full">
                    {category.icon}
                  </div>
                  <h2 className="text-lg font-semibold text-gray-900">
                    {category.title}
                  </h2>
                </div>
                <div className="space-y-3">
                  {category.items.map((item, itemIdx) => {
                    const isActive = selectedReport === item.label;
                    const isHovered = hoveredItem === `${item.code}-${idx}`;
                    const isEnabled = customer !== '';
                    return (
                      <div
                        key={itemIdx}
                        onClick={() => isEnabled && handleNavigation(item)}
                        onMouseEnter={() => setHoveredItem(`${item.code}-${idx}`)}
                        onMouseLeave={() => setHoveredItem(null)}
                        style={{
                          boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                          transform: isHovered && isEnabled ? "translateY(-2px)" : "none",
                          transition: "all 0.3s ease",
                          opacity: isEnabled ? 1 : 0.5,
                          cursor: isEnabled ? "pointer" : "not-allowed"
                        }}
                        className={`
                          border rounded-md p-3 text-left
                          flex items-center
                          ${
                            isEnabled && isActive
                              ? `bg-white border-l-4 border-l-blue-600`
                              : `bg-white ${
                                  isEnabled && isHovered ? "border-l-4 border-l-blue-600" : ""
                                }`
                          }
                        `}
                      >
                        <div className={`w-10 font-mono text-sm font-medium ${
                          (isEnabled && isHovered) || (isEnabled && isActive)
                            ? "text-black-500"
                            : "text-black-700"
                        }`}>
                          {item.code}
                        </div>
                        <div className={`flex-grow text-sm font-medium ${
                          (isEnabled && isHovered) || (isEnabled && isActive) 
                            ? 'text-gray-500' 
                            : 'text-gray-700'
                        }`}>
                          {item.label}
                        </div>
                        <FiChevronRight 
                          className={`w-5 h-5 ${
                            (isEnabled && isActive && !isHovered) 
                              ? "text-blue-500 opacity-100" 
                              : isEnabled && isHovered
                              ? "text-blue-500 opacity-100"
                              : "opacity-0"
                          }`}
                          style={{ transition: 'all 0.2s ease' }} 
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Report Display Frame */}
      {selectedReport && (
        <div className="border rounded p-6 mt-6 min-h-[300px] bg-white shadow-lg">
          <p className="text-gray-700 mb-4">Report: <strong>{selectedReport}</strong></p>
          
          {(loading && !tankReadingData) ? <FullScreenLoader /> : 
            (selectedReport === "Tank Inventory Report" ) ? (
              <div className="flex items-start justify-center p-4">
                <div className="bg-white shadow-lg p-6 text-sm" style={{width:'320px'}}>
                  <div className="space-y-1">
                  <div className="mt-2 text-left kkk mb-0">
  <div>{tankReadingData?.brandName || ''}</div>
  <div>{tankReadingData?.Address?.split('.')[0] || ''}</div>
  <div>{tankReadingData?.Address?.split('. ')[1] || ''}</div>
  <div>{tankReadingData?.pbsNumber || ''}</div>

  <div className="mt-4">{formatDate(tankReadingData?.reportRunTime)}</div>
</div>
          
                    <div className="pt-8">
                      <div className="custom-dashed-border mb-1 text-center">SYSTEM STATUS REPORT</div>
                      <div className="text-left kkk mt-1">
                      {tankReadingData && tankReadingData["IN-TANK INVENTORY"] ? 
  Object.keys(tankReadingData["IN-TANK INVENTORY"]).map((tank, index) => (
    tankReadingData["IN-TANK INVENTORY"][tank].VOLUME < 2000 ? 
    <div key={index}>T {tank?.split(' ')?.[1] || ''}: DELIVERY NEEDED</div> : null
  ))
  : null
}
                      </div>
                    </div>
          
                    <div className="pt-8">
                      <div className="text-left kkk">INVENTORY REPORT</div>
                      <div className="mt-4">
                        {Object.keys(tankReadingData["IN-TANK INVENTORY"]).map((tank, index) => {
                          const tankData = tankReadingData["IN-TANK INVENTORY"][tank];
                          return (
                            <div key={index} className={index > 0 ? "mt-5" : ""}>
                              <div className="text-left kkk">T {tank.split(' ')[1]}: {tankData.PRODUCT}</div>
                              <div className="kkk">
                                <div className="flex justify-between">
                                  <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'left'}}>VOLUME</span>
                                  <span>= </span>
                                  <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'right'}}> {tankData.VOLUME} GALS</span>
                                </div>
                                <div className="flex justify-between">
                                  <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'left'}}>ULLAGE</span>
                                  <span>= </span>
                                  <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'right'}}>{tankData.ULLAGE} GALS</span>
                                </div>
                                <div className="flex justify-between">
                                  <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'left'}}>90% ULLAGE</span>
                                  <span>= </span>
                                  {/* <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'right'}}> {Math.round(parseInt(tankData.ULLAGE) * 0.9)} GALS</span> */}
                                  {/* <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'right'}}> XXXX GALS</span> */}
                                  <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'right'}}> {Math.max(0, Math.round((parseFloat(tankData.VOLUME) + parseFloat(tankData.ULLAGE)) * 0.9 - parseFloat(tankData.VOLUME)))} GALS</span>
                                </div>
                                <div className="flex justify-between">
                                  <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'left'}}>HEIGHT</span>
                                  <span>= </span>
                                  <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'right'}}> {tankData.HEIGHT} INCHES</span>
                                </div>
                                <div className="flex justify-between">
                                  <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'left'}}>WATER VOL</span>
                                  <span>= </span>
                                  <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'right'}}> {tankData.WATER === "0.00" ? "0" : "15"} GALS</span>
                                </div>
                                <div className="flex justify-between">
                                  <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'left'}}>WATER</span>
                                  <span>= </span>
                                  <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'right'}}>{tankData.WATER} INCHES</span>
                                </div>
                                <div className="flex justify-between">
                                  <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'left'}}>TEMP</span>
                                  <span>= </span>
                                  <span style={{width:'110px', whiteSpace:'nowrap', textAlign:'right'}}>{tankData.TEMP} DEG F</span>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
          
                    <div className="pt-8 text-center">* * * * * * * * * * * END * * * * * * * * * * *</div>
                  </div>
                </div>
              </div>
            ) : (selectedReport === "Tank Status Weekly Report")? (
              <div className="flex justify-center p-4">
                <div className="bg-white shadow-lg p-6 font-mono text-sm"  style={{width:'320px'}}>
                  <div className="text-left space-y-1">
                    {/* Header */}
                    <div className="mb-4">
  <div>{tankReadingData?.brandName || ''}</div>
  <div>{tankReadingData?.Address?.split('.')[0] || ''}</div>
  <div>{tankReadingData?.Address?.split('. ')[1] || ''}</div>
  <div>{tankReadingData?.pbsNumber || ''}</div>
</div>

          
                    {/* Date */}
                    <div className="py-2">{formatDate(tankReadingData?.reportRunTime)}</div>
          
                    {/* Status header with dashes */}
                    <div className="py-2">
                      <div>LIQUID STATUS</div>
                      <div className="text-xs">- - - - - - - - - - - - - -</div>
                      <div className="py-1">{formatDate(tankReadingData?.reportRunTime)}</div>
                    </div>
          
                    {/* Sensor status list */}
                    <div className="space-y-6 py-2 text-left">
                    {tankReadingData && tankReadingData["SENSOR LOCATION STATUS"] ?
  Object.keys(tankReadingData["SENSOR LOCATION STATUS"]).map((sensorKey, index) => {
    const sensor = tankReadingData["SENSOR LOCATION STATUS"][sensorKey];
    return (
      <div key={index} className="leading-tight">
        <div>{`L ${index+1}:${sensor?.LOCATION || ''}`}</div>
        <div>SENSOR {sensor?.STATUS?.toUpperCase() || ''}</div>
      </div>
    );
  })
  : <p>No sensor data available</p>
}
                    </div>
          
                    {/* Footer */}
                    <div className="pb-2">
                    <div className="pt-8 text-center">* * * * * * * * * * * END * * * * * * * * * * *</div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <pre className="whitespace-pre-wrap overflow-auto">
                {/* {tankReadingData.replace(/\\u0001|\\u0003/g, '')
                  .replace(/<br\s*\/>/g, '\n')
                  .replace(/\\\//g, '/')
                  .replace(/[\u0001\u0003]/g, '')
                  .trim()} */}
              </pre>
            )
          }
        </div>
      )}
    </div>
  );
};

export default TankReading;
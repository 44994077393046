// import React from "react";
// // import niosyslogo from "../../../src/assets/img/niosyslogo.png";
// import {
//   FaFacebook,
//   FaGooglePlus,
//   FaPhoneAlt,
//   FaTwitterSquare,
// } from "react-icons/fa";
// import { HiOutlineMail } from "react-icons/hi";
// import { ImClock } from "react-icons/im";
// import { FaLocationDot } from "react-icons/fa6";

// const Footer = () => {
//   return (
//     <footer className="bg-white mt-auto text-black  translate-y-[-1px]; ">
//       <div className="absolute top-0 left-0 right-0 h-1.5 bg-gradient-to-b from-gray-300 via-transparent to-transparent"></div>
//       {/* <div className="md:flex  md:items-center sm:px-12 px-4  bg-[#ffffff19] pb-56"> */}
//       <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 gap-10 sm:px-8 px-5 py-3 mx-8">
//         <div>
//           {/* <a href="niosys.net">
//             <img src={niosyslogo} alt="Niosys Logo" />
//           </a> */}
//           <p className="mt-6 text-sm">
//             An enterprise IT company Developing apps & software Providing IT
//             services to customers since 2017
//           </p>
//           <div className="italic mt-8 flex gap-3 text-sm font-bold">
//             follow us:
//             <FaFacebook className="hover:text-[#3479E0]" size={20} />
//             <FaTwitterSquare className="hover:text-[#3479E0]" size={20} />
//             <FaGooglePlus className="hover:text-[#3479E0]" size={20} />
//           </div>
//         </div>

//         <div>
//           <h4 className="text-sm font-semibold mb-4 text-center">Contact Us</h4>
//           <hr className="mb-6 border-t-2 border-blue-500" />
//           <div className="space-y-4">
//             <p className="hover:text-blue-500 text-[15px] cursor-pointer">
//               300 Great Oaks Blvd,<span className="block">Albany, New York 12203</span>
//             </p>
//             <p className="hover:text-blue-500 text-[15px] cursor-pointer">
//               Phone: (518) 992 – 2580
//             </p>
//             <p className="hover:text-blue-500 text-[15px] cursor-pointer">
//               Email: sales@niosys.net
//             </p>
//           </div>
//         </div>

//         <div>
//           <h4 className="text-sm font-semibold mb-4 text-center">
//             Customer Support
//           </h4>
//           <hr className="mb-6 border-t-2 border-blue-500" />
//           <div className="space-y-4">
//             <p className="flex items-center gap-5 hover:text-blue-500 font-bold text-[14px] cursor-pointer">
//               <svg
//                 xmlns="http://www.w3.org/2000/svg"
//                 viewBox="0 0 24 24"
//                 fill="currentColor"
//                 className="w-5 h-5"
//               >
//                 <path
//                   fillRule="evenodd"
//                   d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
//                   clipRule="evenodd"
//                 />
//               </svg>
//               (518) 992 – 2580
//             </p>
//             <p className="flex items-center hover:text-blue-500 gap-5 text-[15px] cursor-pointer">
//               <svg
//                 xmlns="http://www.w3.org/2000/svg"
//                 viewBox="0 0 24 24"
//                 fill="currentColor"
//                 className="w-5 h-5"
//               >
//                 <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
//                 <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
//               </svg>
//               sales@niosys.net
//             </p>
//             <p className="flex gap-4 items-center text-[14px] cursor-pointer">
//               <svg
//                 xmlns="http://www.w3.org/2000/svg"
//                 viewBox="0 0 24 24"
//                 fill="currentColor"
//                 className="w-6 h-6"
//               >
//                 <path
//                   fillRule="evenodd"
//                   d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 6a.75.75 0 00-1.5 0v6c0 .414.336.75.75.75h4.5a.75.75 0 000-1.5h-3.75V6z"
//                   clipRule="evenodd"
//                 />
//               </svg>
//               <p>   Mon – Fri 9 am – 6 pm <span className="block">Sat, Sun– Closed</span></p>
           
//             </p>
//           </div>
//         </div>

//         <div>
//           <h4 className="text-sm font-semibold mb-4 text-center">About Us</h4>
//           <hr className="mb-6 border-t-2 border-blue-500" />
//           <p className="hover:text-blue-500 text-[14px] cursor-pointer">
//             About Us
//           </p>
//         </div>
//       </div>

//       {/* <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 text-center pt-2 bg-black text-gray-400 text-sm pb-8">
//     <span>&copy; 2020 Appy. All rights reserved.</span>
//     <span>Terms · Privacy Policy</span> */}

//       <div className="flex justify-between text-[12px] items-center px-6 py-6 bg-gray-100">
//         <div>
//           <h5>&copy; Copyright 2023.  All Rights Reserved</h5>
//         </div>
//         <div>
//           <h5 className="text-[12px]">Powered By NIOSYS.NET</h5>
//         </div>
//       </div>
//     </footer>
//   );
// };

// export default Footer;

import React from "react";
// import niosyslogo from "../../../src/assets/img/niosyslogo.png";
import {
  FaFacebook,
  FaGooglePlus,
  FaPhoneAlt,
  FaTwitterSquare,
} from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { ImClock } from "react-icons/im";
import { FaLocationDot } from "react-icons/fa6";

const Footer = () => {
  return (
    <footer className=" mt-auto text-black sticky bottom-0 left-0 w-full  translate-y-[-1px] shadow-lg">
      {/* <div className="absolute top-0 left-0 right-0 h-1.5 bg-gradient-to-b 
      from-gray-300 via-transparent to-transparent rounded-xl"></div> */}
        {/* <div className="absolute top-0 left-0 right-0 h-1.5 rounded-xl bg-white"></div> */}
      {/* <div className="md:flex  md:items-center sm:px-12 px-4  bg-[#ffffff19] pb-56"> */}
    

      {/* <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 text-center pt-2 bg-black text-gray-400 text-sm pb-8">
    <span>&copy; 2020 Appy. All rights reserved.</span>
    <span>Terms · Privacy Policy</span> */}

      <div className="mx-4 flex justify-between text-[12px] items-center px-6 py-2 bg-white shadow-lg rounded-xl"
      style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
      >
        <div className="w-[200px]"></div>
        <div className="m-auto">
          <h5>&copy; Copyright 2025.  All Rights Reserved</h5>
        </div>
        <div>
          <h5 className="text-[12px]">Powered By NIOSYS.NET</h5>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaHome, FaShoppingCart, FaMoneyBillAlt, FaChartLine, FaTimes } from 'react-icons/fa';

// Import your components from their respective locations
// Update these paths to match your project structure
import PurchasesComponent from '../../components/pages/Purchases';
import ExpensesComponent from '../../components/pages/Operations';

const OperationScreen = () => {
  const [activeItem, setActiveItem] = useState("Purchases");
  const [displayComponent, setDisplayComponent] = useState("Purchases");
  const navigate = useNavigate();

  const handleMenuClick = (item) => {
    setActiveItem(item);
    
    // Only update the displayed component for Purchases and Expenses
    // For Losses, keep displaying whatever was previously showing
    if (item !== "Losses" && item !== "Home") {
      setDisplayComponent(item);
    }
  };

  useEffect(() => {
    // Always set to Purchases when the component mounts
    setActiveItem("Purchases");
    setDisplayComponent("Purchases");
    localStorage.setItem('activeItem', 'Purchases'); // Set default in localStorage
  }, []);
  
  
  // Render the appropriate component based on displayComponent
  const renderComponent = () => {
    switch (displayComponent) {
      case 'Purchases':
        return <PurchasesComponent />;
      case 'Expenses':
        return <ExpensesComponent />;
      default:
        return <PurchasesComponent />;
    }
  };
useEffect(()=> {
const item = localStorage.getItem('activeItem')
setActiveItem(item)
setDisplayComponent(item)
},[])
  return (
    <div>
      {/* Menu Tabs */}
      <div className="flex mb-3 justify-center p-4 rounded-md font-bold text-md items-center w-full mx-auto pt-0 pb-2 custombgColor">
        {/* Purchases */}
        <div
          className={`flex items-center cursor-pointer px-4 py-1 ${
            activeItem === "Purchases"
              ? "border-b-2 border-green-500 text-green-600"
              : "text-gray-600"
          } border-b border-gray-300`}
          onClick={() => {handleMenuClick("Purchases"); localStorage.setItem('activeItem', 'Purchases')}}
        >
          <span>Purchases</span>
        </div>

        {/* Expenses */}
        <div
          className={`flex items-center cursor-pointer px-4 py-1 ${
            activeItem === "Expenses"
              ? "border-b-2 border-green-500 text-green-600"
              : "text-gray-600"
          } border-b border-gray-300`}
          onClick={() => {handleMenuClick("Expenses"); localStorage.setItem('activeItem', 'Expenses')}}
        >
          <span>Expenses</span>
        </div>

        {/* Losses */}
        <div
          className={`flex items-center cursor-pointer px-4 py-1 ${
            activeItem === "Losses"
              ? "border-b-2 border-green-500 text-green-600"
              : "text-gray-600"
          } border-b border-gray-300`}
          onClick={() => handleMenuClick("Losses")}
        >
          <span>Losses</span>
        </div>

        {/* Home */}
        <div
          className={`flex items-center cursor-pointer px-4 py-[7px] ${
            activeItem === "Home"
              ? "border-b-2 border-green-500 text-green-600"
              : "text-gray-600"
          } border-b border-gray-300`}
          onClick={() => {
            handleMenuClick("Home");
            navigate("/");
          }}
        >
          <FaHome className="text-lg" />
        </div>
      </div>

      {/* Content Area - This displays the component */}
      <div>
        {renderComponent()}
      </div>
    </div>
  );
};

export default OperationScreen;
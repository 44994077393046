// import { useNavigate } from "react-router-dom";
// import { FaHome } from "react-icons/fa";

// const CustomNavigation = () => {
//   const navigate = useNavigate();

//   return (
//     <div className="flex items-center justify-end ">
//       <div
//         className={`absolute -top-4 flex items-center cursor-pointer rounded-full bg-[#25caed] w-10 h-10  px-2 py-2 mb-1 mr-4  border-gray-300 mb-1 hover:scale-110 shadow-md`}
//         onClick={() => {
//           navigate("/");
//         }}
//         style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
//       >
//         {" "}
//         <FaHome className="text-white ml-[2px] mb-1 mt-[2px]" size={20} />
//         {/* <span>Home</span> */}
//       </div>

//       {/* Close Icon */}
//       <div
//         className="absolute -top-4 -right-2 bg-red-500 w-10 h-10 flex items-center justify-center rounded-full cursor-pointer hover:bg-red-700 hover:scale-110 shadow-md"
//         onClick={() => navigate(-1)}
//       >
//         <span className="text-white text-2xl font-bold mb-1">&times;</span>
//       </div>
//     </div>
//   );
// };

// export default CustomNavigation;





import { useNavigate } from "react-router-dom";
import { FaHome } from "react-icons/fa";

const CustomNavigation = () => {
  const navigate = useNavigate();

  return (
    <div className="flex items-center justify-end">
      {/* Home button - bordered/unfilled by default, filled blue on hover */}
      <div
        className="absolute -top-5 flex items-center justify-center cursor-pointer rounded-full bg-[#cefafe] hover:bg-[#25caed] w-9 h-9 mb-0 mr-1 border-[0.5px] border-[#25caed] hover:border-[#25caed] hover:scale-110 shadow-md transition-all duration-200 group"
        onClick={() => {
          navigate("/");
        }}
        style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
      >
        <FaHome className="text-[#25caed] group-hover:text-white transition-colors duration-200" size={16} />
      </div>

      {/* Close Icon - bordered/unfilled by default, filled red on hover */}
      <div
        className="absolute -top-5 -right-4 bg-red-200 hover:bg-red-500 w-9 h-9 flex items-center justify-center rounded-full cursor-pointer hover:bg-red-700 border-[0.5px] border-red-500 hover:border-red-700 hover:scale-110 shadow-md transition-all duration-200 group"
        onClick={() => navigate(-1)}
      >
        <span className="text-red-500 group-hover:text-white text-xl font-bold  transition-colors duration-200">&times;</span>
      </div>
    </div>
  );
};

export default CustomNavigation;
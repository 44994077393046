import React, { useState, useRef, useEffect } from 'react';
import { Box, Paper } from '@mui/material';
import 'react-datepicker/dist/react-datepicker.css';
// import '../Sales/sales.css'; // Add custom styles if needed
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import {  useSelector , useDispatch} from "react-redux";
import { Grid, IconButton } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import PrintIcon from '@mui/icons-material/Print';
import DescriptionIcon from '@mui/icons-material/Description';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import axios from "axios";
import { Tooltip } from "react-tippy";

// import { addCustomer } from "../../../store/customerSlice";
import config  from "../../config/config"
import FullScreenLoader from '../pages/FullScreenLoader';
import { FaHome } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
// import FullScreenLoader from './FullScreenLoader';
// import SalesReportTable from './SalesReportTable';


const PurchaseByUnBrandReport = () => {
  const businessId = localStorage.getItem('business_id')
  const managerId =localStorage.getItem('user_id')
  const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;
  const [periodType, setPeriodType] = useState(""); // Tracks the selected period (Year, Month, Quarterly)
  const [tempperiodType, settempPeriodType] = useState(""); // Tracks the selected period (Year, Month, Quarterly)
  const navigate = useNavigate();
  const [selectedYear, setSelectedYear] = useState(""); // Tracks the selected year
  const [tempYear, setTempYear] = useState(""); // Tracks the selected year

  const [selectedMonth, setSelectedMonth] = useState(""); // Tracks the selected month
  const [tempMonth, setTempMonth] = useState(""); // Tracks the selected year
  const [tempFuelGrade, settempFuelGrade] = useState(""); // Tracks the selected year


  const [selectedQuarter, setSelectedQuarter] = useState( "");
  const [tempQuarter, setTempQuarter] = useState(""); // Tracks the selected year

  const [brand, setBrand] = useState('');
  const [tempcustomer, setTempCustomer] = useState('');

  const [fuelGrade, setFuelGrade] = useState('');

  const customersAll = useSelector(state => state.customer.allCustomers);
  const dispatch = useDispatch();
  const [dateTime, setDateTime] = useState('');
  const [salesResponse, setSalesReport] = useState([]);
  const [imageSource, setImageSource] = useState();
  const [imageResponse, setimageResponse] = useState([]);
  const [loading, setLoading] = useState(true); // Add a loading state
  console.log(loading, 'loading1')
  const [pdfhovered, setPdfHovered] = useState(false);
  const [taxhovered, setTaxHovered] = useState(false);
  const [productData, setProductData] = useState([]);


  const [RunClicked, setRunClicked] = useState(false); // Tracks the selected quarter
  const user_id = localStorage.getItem("user_id");
  const user_role = localStorage.getItem("user_role");
  const business_id = localStorage.getItem("business_id");
  const contentRef = useRef();
  const [userRole, setUserRole] = useState("");
  const from =  salesResponse.data?.from;
  const to =  salesResponse.data?.to;
  // const isButtonEnabled = () => {
  //   if (periodType === 'Yearly' && selectedYear && selectedText ) return true;
  //   if (periodType === 'Monthly' && selectedYear && selectedMonth && selectedText ) return true;
  //   if (periodType === 'Quarterly' && selectedYear && selectedQuarter && selectedText ) return true;
  //   return false;
  // };
  const isButtonEnabled = () => {
    // if (periodType === "Yearly" && selectedYear) return true;
    // if (periodType === "Monthly" && selectedYear && selectedMonth) return true;
    // if (periodType === "Quarterly" && selectedYear && selectedQuarter) return true;
    return false;
  };
  const [sellerNames , setSellerNames] = useState([])

  useEffect(()=>{
    const fetchSellersNames = async ()=> {
      try{
        const response = await axios.get(`${baseURL}${btoa("invoice/allsellers")}`)
        console.log('response', response);
        setSellerNames(response.data);
        console.log(sellerNames, 'sellernames')
      }catch(error){
        console.log("error fetching seller names" , error);
      }
    }
    fetchSellersNames()
    console.log('setSellerNames', sellerNames)
  },[])
  const formatToTwoDecimalsWithCommas = (num) => {
    const parts = num.toString().split('.');
    const integerPartWithCommas = parseInt(parts[0], 10).toLocaleString('en-US'); // Adds commas to the integer part
    const decimalPart = parts[1] ? parts[1].substring(0, 2) : ''; // Limits to two decimal places without rounding
  
    return decimalPart ? `${integerPartWithCommas}.${decimalPart}` : integerPartWithCommas;
  };
  const generateUSDateTime = () => {
    setRunClicked(true);
    const currentDate = new Date();
    
    // Format date and time in US format
    const formattedDateTime = currentDate.toLocaleString('en-US', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    
    });

    setDateTime(formattedDateTime);
  };
  function formatNumberwithoutzeros(num) {
    return new Intl.NumberFormat('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(num);
  }
  const handlePdfDownload = async () => {
    const brandNamePdf = selectedText === "All Brands" ? "all" : selectedText
   const selectedMonthpdf = selectedMonth === "" ? null : selectedMonth
   const selectedQuarterpdf = selectedQuarter === "" ? null : selectedQuarter
   const datepdf = new Date().toISOString().slice(0, 19).replace("T", " ")

    // setLoading(true);
console.log(businessId, managerId,selectedBrandName,brandNamePdf,periodType,selectedYear,selectedMonth,selectedQuarter, 'checking.................................')
      // console.log("selected rrr" , selectedRows);

    try {
      const response = await axios.get(
        `${baseURL}/${btoa("purchase/download_purchasedinvoicespdf")}/${btoa(businessId)}/${btoa(managerId)}/${btoa(selectedBrandName)}/${btoa(brandNamePdf)}/${btoa(null)}/${btoa(periodType)}/${btoa(selectedYear)}/${btoa(selectedMonthpdf)}/${btoa(selectedQuarterpdf)}`, 
        { responseType: 'blob' }
    );
    console.log(response, 'purchase pdf response')
      const filename = `${brandNamePdf === "all" ? 'All Brands' : brandNamePdf} ${selectedMonth} ${selectedQuarter} ${selectedYear} Invoices ${datepdf}.pdf`;
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = filename; // Set the filename here
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

     
      // console.log("Invoice downloaded successfully.");
    } catch (error) {
      console.log("Error downloading invoice:", error);
    }finally {
      // Hide loader if applicable
      // setLoading(false);
  }
  };

  const handlePrint = () => {
    const content = contentRef.current.innerHTML; // Get the HTML content
    const newWindow = window.open('', '_blank'); // Open a new window
    newWindow.document.write(`
      <html>
        <head>
          <title>Print</title>
          <style>
            /* Optional: Add styles for printed content */
                      @media print {
  img {
    display: block;
    width: 100px; /* Adjust width as needed */
    height: auto;
  }
              @page {
                margin: 0; /* Remove default margins */
              }
              body {
                margin: 0;
                padding: 0;
              }
              /* Hide headers and footers */
              header, footer {
                display: none; /* Hides header and footer */
              }
              /* You can also add styles to control the printed content */

            body {
              font-family: Arial, sans-serif;
              margin: 30px;
            }
          </style>
        </head>
        <body>
          ${content} 
        </body>
      </html>
    `);
    newWindow.document.close(); // Close the document to apply styles
    newWindow.print(); // Trigger the print dialog
  };
  function formatNumber(num) {
    return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(num);
  }
  
  useEffect(() => {
    setUserRole(localStorage.getItem("user_role"));
  }, []);

  useEffect(() => {
    axios
      .get(`${baseURL}/${btoa("business/profile")}/${btoa(business_id)}/${btoa(user_id)}/${btoa(user_role)}`)
      .then((response) => {
        console.log(response);
        // console.log("response businesstype", response);
        setImageSource(response.data.business_data[0].image_file)
        setimageResponse(response.data.business_data[0])
      })
      .catch((error) => {
        console.error("Error fetching business data:", error);

      });
      console.log(imageResponse);

  }, []);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  // Years and Months for dropdowns
  const years = ["2025", "2024", "2023", "2022", "2021"];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const quarters = [{bName: "Q1",
    fName:"Q1 (Mar-May)"
  },{bName: "Q2",
    fName:"Q2 (June-Aug)"},{bName: "Q3",
        fName: "Q3 (Sep-Nov)"},{bName: "Q4",
            fName: "Q4 (Dec-Feb)"}]

// console.log('customersAll', customersAll);
// useEffect(() => {
//   window.scrollTo(0, 0);
//   axios
//     .get(`${baseURL}Y3VzdG9tZXIvdmlldw==/${btoa(businessId)}`)
//     .then((response) => {
//       const sortedCustomerData = response.data.customers_data.sort(
//         (a, b) => b.customer_id - a.customer_id
//       );
//       // console.log("sor" , sortedCustomerData);
//       dispatch(addCustomer(sortedCustomerData))
   
//     })
//     .catch((error) => {
//       console.error("Error fetching customer data:", error);
//     });
// }, []);

  // Ref for DatePicker to programmatically open it
  const datePickerRef = useRef(null);


  const handleFuelChange = (event) => {
    setFuelGrade(event.target.value);
    // setSelectedDate(null); // Reset selected date
  };


  const handlePeriodChange = (event) => {
    const newPeriodType = event.target.value;
    setPeriodType(newPeriodType);
  
    // Reset fields based on the selected period type
    if (newPeriodType === "Yearly") {
      setSelectedMonth("");
      setSelectedQuarter("");
    } else if (newPeriodType === "Monthly") {
      if (!selectedMonth) setSelectedMonth("");
      setSelectedQuarter("");
    } else if (newPeriodType === "Quarterly") {
      setSelectedMonth("");
      if (!selectedQuarter) setSelectedQuarter("");
    } else if (newPeriodType === "CustomRange") {
      setSelectedYear("");
      setSelectedMonth("");
      setSelectedQuarter("");
      
      // Set dates to null instead of default values
      setStartDate(null);
      setEndDate(null);
    }
  };
  

  // const handlePeriodChange = (event) => {
  //   // setRunClicked(false)
  //   setPeriodType(event.target.value);
  //   // setSelectedDate(null); // Reset selected date
  // };
  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get(`${baseURL}/${btoa("product/view")}`)
      .then((response) => {
          // console.log("prod" , response.data.products_data);
       
        setProductData( response.data.products_data);
      })
      .catch((error) => {
        console.error("Error fetching customer data:", error);
      });
  }, []);
  const [selectedText, setSelectedText] = useState("All Brands");
  const [selectedBrandName , setSelectedBrandName] = useState("all")
  const handleChangeFuelBrands = (e) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    setSelectedBrandName(selectedOption.value);
    setSelectedText(selectedOption.text);
  };
  const handleRun = async () => { 
    setRunClicked(true);
    setLoading(true); // Start loader
    setTempYear(selectedYear);
    setTempMonth(selectedMonth);
    setTempQuarter(selectedQuarter);
    settempPeriodType(periodType);
    settempFuelGrade(fuelGrade);
    setTempCustomer(brand);
  
    try {
      const response = await axios.post(`${baseURL}/${btoa("")}`, {
        reportType: periodType,
        monthName: selectedMonth,
        quarterName: selectedQuarter,
        fuelGradeName: null,
        year: selectedYear,
        businessId: localStorage.getItem('business_id'),
        managerId: localStorage.getItem('user_id'),
        brandId: selectedBrandName,
        brandName: selectedText === "All Brands" ? "all" : selectedText,
        userRole: userRole,
      });
  
      console.log('response.....', response);
      setSalesReport(response);
    } catch (error) {
      console.error("Error posting details:", error);
    } finally {
      setLoading(false); // Stop loader after data fetch
    }
  };
  

  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {  // Trigger background when scrolling starts
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    
    // Clean up the event listener on component unmount
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="ml-5">
    <>
      <div
        className="flex justify-between items-center w-full pt-4 mt-4 mb-5"
        style={{
          maxWidth: "1200px",
          boxSizing: "border-box",
          marginLeft: "4px",
          color: "#444444"
        }}
      >
  {/* <h1 className="font-bold text-xl ">Gas Purchase Report</h1> */}
  <nav aria-label="breadcrumb">
    <ol className="flex space-x-2 text-lg font-bold text-gray-700">
      <li>
        {/* <a  href="/operations" className="text-blue-600 hover:text-blue-800"> */}
          Reports
        {/* </a> */}
      </li>
      <li>
        <span className="text-gray-400">{'>>'}</span>
      </li>
      <li>
        {/* <a href="/purchases" className="text-blue-600 hover:text-blue-800"> */}
          Purchases From Unbranded
        {/* </a> */}
      </li>
    </ol>
  </nav>
</div>


     <div className="relative py-[12px] px-[8px] rounded-xl bg-white" style={{ boxShadow: '0 0 25px 0 rgba(0, 0, 0, 0.2)',}}>
    
     <div className="flex items-center justify-end">
            {/* Home button - bordered/unfilled by default, filled blue on hover */}
            <div
              className="absolute -top-4 flex items-center justify-center cursor-pointer rounded-full bg-[#cefafe] hover:bg-[#25caed] w-9 h-9 mb-0 mr-6 border-[0.5px] border-[#25caed] hover:border-[#25caed] hover:scale-110 shadow-md transition-all duration-200 group"
              onClick={() => {
                navigate("/");
              }}
              style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
            >
              <FaHome
                className="text-[#25caed] group-hover:text-white transition-colors duration-200"
                size={16}
              />
            </div>

            {/* Close Icon - bordered/unfilled by default, filled red on hover */}
            <div
              className="absolute -top-4 -right-2 bg-red-200 hover:bg-red-500 w-9 h-9 flex items-center justify-center rounded-full cursor-pointer hover:bg-red-700 border-[0.5px] border-red-500 hover:border-red-700 hover:scale-110 shadow-md transition-all duration-200 group"
              onClick={() => navigate("/HomeReports")}
            >
              <span className="text-red-500 group-hover:text-white text-xl font-bold mb-1.5 transition-colors duration-200">
                &times;
              </span>
            </div>
          </div>
    
      {/* <Box 
      sx={{ padding: 1,paddingTop:0,paddingBottom:1,  maxWidth: '100%',
       backgroundColor: '#ffff', marginTop:'20px' }}
       
       > */}
        {/* <Paper elevation={3}
             style={{
              padding: '40px',
              paddingTop: '20px',
              display: 'flex',
              justifyContent: 'center',  // Centers horizontally
              alignItems: 'center',      // Centers vertically
            }}
         sx={{ padding: 5,paddingTop:'0px', borderRadius: 4,alignItems:'center', boxShadow: '0 0 20px 0 rgba(0, 0, 0, .2)',
 }}> */}
          <Grid container spacing={3} style={{display:'flex', alignItems:'center'}}  className="pl-[10px] pr-6 "  >
            {/* Customer Dropdown */}
            <Grid item md={2.1} >
            <div className="flex-grow">
            <label
            for="Select Year"
            className="block mb-2 text-[14px] font-medium text-gray-600 inline-flex items-center "
          >
            Select Brand
            <span className="text-red-500 text-xl font-extrabold pl-1">*</span>
          </label>
            <select
              id="Select Brand"
              name="Select Brand"
              required
              onChange={handleChangeFuelBrands}
              className=" border border-gray-300 text-sm  font-medium rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full text-gray-500 py-1 px-2 h-[30px]"
            >
              {/* <option value="">Select Brand</option> */}
              <option value="all">All Brands</option>
         
              {sellerNames.map((seller) => (
                <option
                  key={seller.id}
                  value={seller.id}
                >
                  {/* {seller.seller_name} */}
                  {seller.seller_name.charAt(0).toUpperCase() + seller.seller_name.slice(1).toLowerCase()}

                </option>
              ))}
               
            </select>
              {/* <select
                id="customerId"
                className=" border border-gray-300 text-sm font-medium rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full text-gray-500 p-2.5"
                onChange={(e) => setSelectedCustomer(e.target.value === "All Customers" ? "all" : e.target.value)}
              >
                <option>All Customers</option>
                {productOptions.map((customer) => (
                  <option key={customer.customer_id} value={customer.customer_id}>
                    {customer.business_name}
                  </option>
                ))}
              </select> */}
          </div>

              {/* <div>
            <label
              for="bill_to"
              className="block mb-2 text-[14px] font-medium text-gray-600  "
            >
              Select Brand
              <span className="text-red-500 text-xl font-extrabold pl-2 pt-3">*</span>

            </label>
            <select
              id="Select Brand"
              name="Select Brand"
              required
              onChange={handleChangeFuelBrands}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
            >
              <option value="">--Select Brand--</option>
              <option value="all">All Brands</option>
         
              {sellerNames.map((seller) => (
                <option
                  key={seller.id}
                  value={seller.id}
                >
                  {seller.seller_name}
                </option>
              ))}
               
            </select>
          </div> */}
            </Grid>
            {/* <Grid item md={2.2} >
              <div>
            <label
              for="bill_to"
              className="block mb-2 text-[14px] font-medium text-gray-600  "
            >
              Select Fuel Grade
              <span className="text-red-500 text-xl font-extrabold pl-2 pt-3">*</span>

            </label>
            <select
              id="Select Customer"
              name="Select Customer"
              required
              onChange={handleFuelChange}
              value={fuelGrade}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
            >
              <option value="">--Select Fuel Grade--</option>
              <option value="all">All Fuel Grades</option>
                         {productData?.map ((selectedProduct)=>{
                          return (
                            <option
                              key={selectedProduct.product_id}
                              value={selectedProduct.product_id}
                            >
                              {selectedProduct.product_name}
                            </option>)
                         })}
               
            </select>
          </div>
            </Grid> */}

            {/* Invoice Type Dropdown */}
            <Grid item md={2.1}>
            <div>
            <label
              for="Select Report Type"
              className="block mb-2 text-[14px] font-medium text-gray-600 inline-flex items-center  "
            >
              Select Report Type
              <span className="text-red-500 text-xl font-extrabold pl-1">*</span>

            </label>
            <select
              id="Select Report Type"
              name="Select Report Type"
              required
              onChange={handlePeriodChange}
              value = {periodType}
              className=" border border-gray-300 text-sm font-medium rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full text-gray-500 py-1 px-2 h-[30px]"
            >
              <option value="">Select Report Type</option>
              <option value="Monthly">Monthly</option>
              <option value="Quarterly">Quarterly</option>
              <option value="Yearly">Yearly</option>      
            </select>
          </div>
            </Grid>        
     { (periodType === 'Monthly') &&
                    <Grid item md={2.1} >
          <div>
          <label
            for="Select Month"
            className="block mb-2 text-[14px] font-medium text-gray-600 inline-flex items-center  "
          >
            Select Month
            <span className="text-red-500 text-xl font-extrabold pl-1">*</span>
          </label>
          <select
            id="Select Month"
            name="Select Month"
            required
            value={selectedMonth}
            onChange={(e) => {setSelectedMonth(e.target.value);}}
            label="Select Month"
            // className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
                className=" border border-gray-300 text-sm font-medium rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full text-gray-500 py-1 px-2 h-[30px]"
          >
            <option value="">--Select Month--</option>
            {/* <option value="">Select Month</option> */}

            {months.map((month) => (
                <option key={month} value={month} sx={{ margin: 0, padding: '1px 16px' }}>
                  {month}
                </option>
              ))}         
          </select>
        </div>
        </Grid>
}
      { (periodType === 'Quarterly') &&
                     <Grid item md={2.1} >
          <div>
          <label
            for="Select Quarter"
            className="block mb-2 text-[14px] font-medium text-gray-600 inline-flex items-center  "
          >
            Select Quarter
            <span className="text-red-500 text-xl font-extrabold pl-1">*</span>
          </label>
          <select
            id="Select Quarter"
            name="Select Quarter"
            required
            value={selectedQuarter}
            onChange={(e) => {setSelectedQuarter(e.target.value);}}  
            label="Select Quarter"
       className=" border border-gray-300 text-sm font-medium rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full text-gray-500 py-1 px-2 h-[30px]"
          >
            <option value="">--Select Quarter--</option>
            {/* <option value="">Select Quarter</option> */}

            {quarters.map((quarter) => (
            <option key={quarter.fName} value={quarter.bName} sx={{ margin: 0, padding: '1px 16px' }}>
              {quarter.fName}
            </option>
          ))}      
          </select>
        </div> 
        </Grid>
      }
                {(periodType === 'Yearly' || periodType === 'Monthly' || periodType === "Quarterly") &&
                                 <Grid item md={2.1} >
          <div>
          <label
            for="Select Year"
            className="block mb-2 text-[14px] font-medium text-gray-600 inline-flex items-center  "
          >
            Select Year
            <span className="text-red-500 text-xl font-extrabold pl-1">*</span>
          </label>
          <select
            id="Select Year"
            name="Select Year"
            required
            value={selectedYear}
            onChange={(e) => {setSelectedYear(e.target.value);}} 
            label="Select Year"
          className=" border border-gray-300 text-sm font-medium rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full text-gray-500 py-1 px-2 h-[30px]"
          >
            <option value="">--Select Year--</option>    
     
            {years.map((year) => (
              <option key={year} value={year} sx={{ margin: 0, padding: '1px 16px' }}>
                {year}
              </option>
            ))}          
          </select>
        </div>
        </Grid>
}  
            <Grid item >
           <Button variant="contained"          
           disabled={!isButtonEnabled()}
           sx={{
            backgroundColor: 'rgb(231 70 148)',  // Pink color
            color: 'white',
            marginTop: '34px',
            height:'30px',
            //  paddingTop:'10px',
            '&:hover': {
              backgroundColor: 'rgb(231 70 148)',  // Darker shade of pink on hover
            }
          }}
       endIcon={<SendIcon />}
       onClick={()=>{generateUSDateTime(); handleRun()}}
        >
  Run
</Button>
           </Grid>
          </Grid>


        {/* </Paper> */}
      {/* </Box> */}
      </div>
      {
      RunClicked ?
  <div id="runclicked">
   { RunClicked &&
        
    <div
    className='bg-white'
    style={{  borderRadius: "20px",
      maxWidth: '100%',
      overflowX: 'auto',
      margin: '0 auto', 
      flexDirection: 'column',
                    boxShadow: '0 0 25px 0 rgba(0, 0, 0, 0.2)',
                   margin:'55px',
                   paddingBottom:'80px',
                   margin:'15px 0px 15px 0px',
                   marginTop:'45px',

                   padding:'20px',
                   justifyContent:'center',
                   display:'flex',
                   flexDirection:'column'
          }}>
    {salesResponse.data && salesResponse.data.purchasedInvoicesTotals && salesResponse.data.purchasedInvoicesTotals.length > 0 ?
    <Grid container alignItems="end" justifyContent="end" >
      {/* PDF Download Icon */}
     <Grid item xs={12} md={4}>
        <Box p={2} className="font-bold text-md text-gray-400">
        {
            RunClicked && <p>Report RunTime: {dateTime} </p>

        }
        </Box>
    
      
     </Grid>
     <Grid item xs={12} md={8}>
     <Grid container alignItems="end" justifyContent="end" >

      <Grid item>
      <Tooltip title="Pdf" position="top" trigger="mouseenter">

        <IconButton aria-label="Download PDF"  
             sx={{
                backgroundColor: 'transparent', // No background color initially
                borderRadius: '50%', // Circular shape
                padding: '12px', // Adjust padding for the circular button
                '&:hover': {
                  backgroundColor: 'rgba(227, 140, 151, 0.3)', // Light background color on hover
                },
                transition: 'background-color 0.3s ease', // Smooth transition effect
              }}
              onMouseEnter={() => setPdfHovered(true)}
              onMouseLeave={() => setPdfHovered(false)}
              onClick={handlePdfDownload}
 >
  {
    pdfhovered ? <FileDownloadIcon sx={{ fontSize: 20, color: 'red' }} />
    :           <PictureAsPdfIcon fontSize="small" color="error" />

  }
        </IconButton>
        </Tooltip>
      </Grid>
      <Grid item>
      <Tooltip title="Print" position="top" trigger="mouseenter">
        <IconButton aria-label="Print" onClick={handlePrint}
                sx={{
                    backgroundColor: 'transparent', // No background color initially
                    borderRadius: '50%', // Circular shape
                    padding: '12px', // Adjust padding for the circular button
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.1)', // Light background color on hover
                    },
                    transition: 'background-color 0.3s ease', // Smooth transition effect
                  }}
        
        >
          <PrintIcon fontSize="small" sx={{color:'black'}}/>
        </IconButton>
        </Tooltip>
      </Grid>

      {/* Tax File Icon */}
      <Grid item>
      <Tooltip title="Excel" position="top" trigger="mouseenter">

        <IconButton aria-label="Tax File"
            sx={{
                backgroundColor: 'transparent', // No background color initially
                borderRadius: '50%', // Circular shape
                padding: '12px', // Adjust padding for the circular button
                '&:hover': {
                  backgroundColor: 'rgba(143, 191, 96, 0.3)', // Light background color on hover
                },
                transition: 'background-color 0.3s ease', // Smooth transition effect
              }}
              onMouseEnter={() => setTaxHovered(true)}
              onMouseLeave={() => setTaxHovered(false)}
        
        >
          {
            taxhovered ? <FileDownloadIcon sx={{ fontSize: 20, color: 'red' }} />
            :
            <DescriptionIcon fontSize="small" style={{ color: '#4CAF50' }} />

          }
        </IconButton>
        </Tooltip>

      </Grid>
      </Grid>
      </Grid>
    </Grid>
    :''}
    {/* <div className="flex justify-center"> */}

    <div ref={contentRef} style={{width:'100%',
         height:'auto',
         justifyContent:'center',
          alignSelf:"center",
          borderRadius: "10px",
          boxShadow: '0 0 20px 0 rgba(0, 0, 0, .1)',
          padding:'20px',
          marginTop:'20px'
          }}>

<div style={{  display: 'flex',
    alignItems: 'center',
    height:'110px',
    justifyContent: 'space-between',
    marginBottom:'20px',
    backgroundColor: 'rgba(180,180,180,0.1)'}}>
      {/* Left Image */}
      {/* <img
        src="https://via.placeholder.com/60"
        alt="Left Logo"
        style={{ width: '60px', // Set the size of the images
            height: '60px'}}
      /> */}
      {/* <img src="/static/media/logo.0ea4b695a3030ea659a8.png" alt="flownia" className="w-max" ></img> */}
              {userRole !== "1" && (
          <div className="">
            {imageSource && (
              <img
                style={{ width: '140px',
                    height: '60px'}}
                src={`${baseURL}uploads/img/business/${imageSource}`} alt="Selected" />
            )}
          </div>
        )}

      {/* Middle: Three Texts Stacked */}
      <div style={{ textAlign: 'center', marginLeft:'90px' }}>
  <p>
    <strong style={{ fontWeight: '600', fontSize: '18px', margin: '5px' }}>
      {imageResponse.business_name}
    </strong>
  </p>
  <p>
    <strong style={{ fontWeight: '600', fontSize: '18px', margin: '5px' }}>
      {imageResponse.business_address}
    </strong>
  </p>
  <p>
    <strong style={{ fontWeight: '600', fontSize: '18px', margin: '5px' }}>
      {imageResponse.business_city}, {imageResponse.business_state}, {imageResponse.business_zip_code}
    </strong>
  </p>
</div>



      {/* Right Image */}
      <img src="/static/media/logo.0ea4b695a3030ea659a8.png" alt="invoicefilelogo" className="w-max" style={{ width: '240px', // Set the size of the images
            height: '200px'}}></img>
    </div>
    {RunClicked &&
    (
    <div style={{ color: '#1976d2', textAlign:'center', fontSize:'21px', fontWeight:'bold', marginTop: '50px'}}     className='border-b border-gray-300 mb-1 mt-2'
    >
             {(tempperiodType === 'Yearly' && tempYear && RunClicked)  &&  `${tempYear} - Purchased - ${salesResponse?.data?.brandName === "all" ? "All " : salesResponse?.data?.brandName} Invoices `}
     {(tempperiodType === 'Monthly' && tempYear && tempMonth && RunClicked) &&`${tempMonth} - ${tempYear} - Purchased - ${salesResponse?.data?.brandName === "all" ? "All " : salesResponse?.data?.brandName} Invoices `}
         {(tempperiodType === 'Quarterly' && tempYear && tempQuarter && RunClicked) && ` ${tempQuarter == 'Q1' ? 'Q1 (Mar-May)' :''} ${tempQuarter == 'Q2' ? 'Q2 (June-Aug)':'' } ${tempQuarter == 'Q3' ? 'Q3 (Sep-Nov)' :'' } ${tempQuarter == 'Q4' ? 'Q4 (Dec-Feb)' :'' } - Purchased - ${salesResponse?.data?.brandName === "all" ? "All " : salesResponse?.data?.brandName} Invoices  `}
         {/* <hr className="border-t border-gray-300 mb-1 mt-2" /> */}
        </div>
   )}


{/* <SalesReportTable salesResponse={salesResponse}/> */}
<>
    {RunClicked && loading ? (
      <FullScreenLoader /> // Show loader while fetching data
    ) : RunClicked && salesResponse?.data?.purchasedInvoicesTotals?.length > 0 ? (
      <div style={{ marginTop: '14px' }}>
        <table style={{ width: '60%', maxWidth: '700px', margin: '0px auto', fontWeight: 'normal', borderCollapse: 'collapse' }}>
          {/* Header Row */}
          <thead style={{ backgroundColor: 'transparent' }}>
            <tr style={{ textAlign: 'center', marginBottom: '10px', color: '#3479E0' }}>
              <th style={{ padding: '10px', textAlign: 'left', width: '300px', fontSize: '19px' }}>Fuel Grade</th>
              <th style={{ padding: '10px', textAlign: 'right', width: '80px', fontSize: '19px' }}>Gallons</th>
              <th style={{ padding: '10px', textAlign: 'right', fontSize: '19px' }}>Amount</th>
            </tr>
          </thead>

          {/* Data Rows */}
          <tbody>
            {salesResponse.data.purchasedInvoicesTotals.map((item) => (
              <tr key={item.product_id || item.product_name} style={{ textAlign: 'center', marginBottom: '10px' }}>
                <td style={{ padding: '10px', fontWeight: 'lighter', textAlign: 'left', fontSize: '15px' }}>
                  {item.fuelgrade_name || ''}
                </td>
                <td style={{ padding: '10px', fontWeight: 'lighter', textAlign: 'right', fontSize: '16px' }}>
                  {`${formatNumberwithoutzeros(item.gross_quantity_total)} gl`}
                </td>
                <td style={{ padding: '10px', fontWeight: 'lighter', textAlign: 'right', fontSize: '16px', whiteSpace: 'nowrap'  }}>
                  $ {formatNumber(item.unit_total) || 0}
                </td>
              </tr>
            ))}
          </tbody>

          {/* Total Summary Row */}
          <tfoot>
            <tr style={{ textAlign: 'center', padding: '10px', margin: '20px auto', borderRadius: '5px', borderTop: '1px solid grey', borderBottom: '1px solid grey', backgroundColor: '#F5F5F5' }}>
              <td style={{ padding: '10px', fontWeight: 'lighter', textAlign: 'left', fontSize: '16px' }}>Total:</td>
              <td style={{ padding: '10px', fontWeight: 'lighter', textAlign: 'right', fontSize: '16px', whiteSpace:'nowrap'  }}>
                {`${formatNumberwithoutzeros(salesResponse?.data.grossQuantitySum)} gl`}
              </td>
              <td style={{ padding: '10px', fontWeight: 'lighter', textAlign: 'right', fontSize: '16px' }}>
                $ {formatNumber(salesResponse?.data.unitTotalSum) || 0}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    ) : (
      RunClicked && !loading && (
        <p style={{ color: 'red', fontSize: '18px', fontWeight: 'bold', textAlign: 'center', paddingTop: '20px' }}>
          No Data Available
        </p>
      )
    )}
  </>
    </div>
   
    
    </div>
}
    <hr/>
    </div>
    :     <div style={{height: '300px'}}></div>

        }
    </>
    </div>
  );
};

export default PurchaseByUnBrandReport;

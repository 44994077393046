import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const CustomCommonTab = ({ items }) => {
  const [activeItem, setActiveItem] = useState(items[0].name);
  const navigate = useNavigate();

  const handleMenuClick = (name, path) => {
    setActiveItem(name);
    if (path) navigate(path);
  };

  return (
    <div className="flex mb-6 justify-center p-4 rounded-md font-bold text-md items-center w-full mx-auto py-2">
      {items.map(({ name, path }) => (
        <div
          key={name}
          className={`flex items-center cursor-pointer px-4 py-1 border-b-2 border-green-500 text-green-600 ${
            activeItem === name ? "border-b-2 border-green-500 text-green-600" : "text-gray-600"
          } border-b border-gray-300`}
          onClick={() => handleMenuClick(name, path)}
        >
          <span>{name}</span>
        </div>
      ))}
    </div>
  );
};

export default CustomCommonTab;

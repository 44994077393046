import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addToast } from "./ToastBox/ToastBoxMessageSlice";
import { HiSpeakerWave } from "react-icons/hi2";
import TabMenu from "./TabMenu";
import dayjs from 'dayjs';
import { useSelector } from "react-redux";
import { addCustomer } from "../../store/customerSlice";
import axios from "axios";
import config from "../../config/config";
import CustomNavigation from "../reusable/CustomNavigationIcon";
import { FaHome } from "react-icons/fa";
import { LocationCity } from "@mui/icons-material";
import { clearNavigationState } from '../../components/Navigation/navigationSlice';

const HomeNotifications = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const businessId = localStorage.getItem("business_id");
  
  // Get navigation state from Redux first
  const reduxNavigationState = useSelector((state) => state.navigation.navigationState) || {};
  
// In HomeNotifications.js - Fix the JSON parsing error

const [fromDeliveryPrice, setFromDeliveryPrice] = useState(() => {
  // First try localStorage with proper error handling
  try {
    const savedState = localStorage.getItem('navigationState');
    if (savedState) {
      const parsedState = JSON.parse(savedState);
      return parsedState.fromDeliveryPrice || reduxNavigationState.fromDeliveryPrice || null;
    }
  } catch (error) {
    console.error('Error parsing navigationState:', error);
  }
  // Fall back to Redux state
  return reduxNavigationState.fromDeliveryPrice || null;
});

const [businessNamefromdeliveryprice, setBusinessNameFromDeliveryPrice] = useState(() => {
  // First try localStorage with proper error handling
  try {
    const savedState = localStorage.getItem('navigationState');
    if (savedState) {
      const parsedState = JSON.parse(savedState);
      return parsedState.businessNamefromdeliveryprice || reduxNavigationState.businessNamefromdeliveryprice || null;
    }
  } catch (error) {
    console.error('Error parsing navigationState:', error);
  }
  // Fall back to Redux state
  return reduxNavigationState.businessNamefromdeliveryprice || null;
});

const [fromPriceBook, setFromPriceBook] = useState(() => {
  // First try localStorage with proper error handling
  try {
    const savedState = localStorage.getItem('navigationState');
    if (savedState) {
      const parsedState = JSON.parse(savedState);
      return parsedState.fromPriceBook || reduxNavigationState.fromPriceBook || null;
    }
  } catch (error) {
    console.error('Error parsing navigationState:', error);
  }
  // Fall back to Redux state
  return reduxNavigationState.fromPriceBook || null;
});

// Update localStorage when Redux state changes - with error handling
useEffect(() => {
  if (Object.keys(reduxNavigationState).length > 0) {
    try {
      localStorage.setItem('navigationState', JSON.stringify(reduxNavigationState));
    } catch (error) {
      console.error('Error storing navigationState:', error);
    }
  }
}, [reduxNavigationState]);

  const baseURL =
    process.env.NODE_ENV === "production"
      ? config.production.baseURL
      : config.development.baseURL;
  
  const invoiceNumber = location.state?.invoicenumber || null;
  const business_name = location.state?.business_name || null;
  const bill_from_name = location.state?.bill_from_name || null;
  
  const [selectedNotification, setSelectedNotification] = useState(1);
  const [selectedMode, setSelectedMode] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');
  const [both, setBoth] = useState('');

  const businessName = localStorage.getItem('businessName');
  const [highlightedNotification, setHighlightedNotification] = useState(null);

  useEffect(() => {
    if (location.state?.fromPastDue) {
      setHighlightedNotification("Past due notification");
    } else if (location.state?.fromPartialPay) {
      setHighlightedNotification("Partial Pay notification");
    } 
    else if (fromPriceBook) {
      setHighlightedNotification("Price change notification");
    }
    else if (fromDeliveryPrice) {
      setHighlightedNotification("Delivery Price notification");
    }
    else {
      setHighlightedNotification(null);
    }
  }, [location.state, fromPriceBook, fromDeliveryPrice]);

  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get(`${baseURL}Y3VzdG9tZXIvdmlldw==/${btoa(businessId)}`) //
      .then((response) => {
        const sortedCustomerData = response.data.customers_data.sort(
          (a, b) => b.customer_id - a.customer_id
        );
        dispatch(addCustomer(sortedCustomerData))
     
      })
      .catch((error) => {
        console.error("Error fetching customer data:", error);
      });
  }, []);

  const notifications = [
    "Price change notification",
    "Past due notification",
    "Partial Pay notification",
    "Delivery Price notification",
  ];
  
  const [activeTab, setActiveTab] = useState("View");
  const navigationPath = fromPriceBook ? "/NewPrice" : (fromDeliveryPrice ? '/deliveryPriceDashBoard': "/");

  const tabs = [
    { key: "View", label: "View" },
    { key: "Cancel", label: "Cancel", navigationPath: navigationPath },
  ];
  
  // Clear navigation state when component unmounts
  useEffect(() => {
    return () => {
      dispatch(clearNavigationState());
      localStorage.removeItem('navigationState');
    };
  }, [dispatch]);
  
  const handleModeSelect = (notificationId, mode) => {
    setSelectedNotification(notificationId);
    setSelectedMode(mode);
    setIsEditing(false);
  };
  
  const customersAll = useSelector((state) => state.customer.allCustomers) || [];
  const [customer, setCustomer] = useState('');

  return (
    <div className="relative flex flex-col justify-center py-5 items-center ">
      <div
        className="relative flex flex-col items-start p-4 w-[1080px] bg-white"
        style={{
          boxShadow: "0 0 20px 0 rgba(0, 0, 0,.3)",
          borderRadius:'8px'
        }}
      >
       
        <div className="w-full flex justify-end mb-4">
          <div className="flex items-center justify-end">
            <div
              className="absolute -top-5 mr-4 flex items-center justify-center cursor-pointer rounded-full bg-[#cefafe] hover:bg-[#25caed] w-9 h-9 mb-0 mr-1 border-[0.5px] border-[#25caed] hover:border-[#25caed] hover:scale-110 shadow-md transition-all duration-200 group"
              onClick={() => {
                navigate("/");
              }}
              style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
            >
              <FaHome className="text-[#25caed] group-hover:text-white transition-colors duration-200" size={16} />
            </div>
      
            <div
              className="absolute -top-5 -right-4 bg-red-200 hover:bg-red-500 w-9 h-9 flex items-center justify-center rounded-full cursor-pointer hover:bg-red-700 border-[0.5px] border-red-500 hover:border-red-700 hover:scale-110 shadow-md transition-all duration-200 group"
              onClick={() => navigate(navigationPath)}
            >
              <span className="text-red-500 group-hover:text-white text-xl font-bold  transition-colors duration-200">&times;</span>
            </div>
          </div>
        </div>
        
        {!fromPriceBook && !fromDeliveryPrice &&
          <div className=" flex justify-center items-center m-auto mb-4">
            <div className="flex gap-4">
              <select className="border p-0 px-2 rounded-lg h-[32px] w-[200px] bg-gray-100 cursor-not-allowed" disabled>
                <option value={business_name}>{business_name}</option>
              </select>

              <select className="border p-0 px-2 rounded-lg w-[200px] bg-gray-100 cursor-not-allowed" disabled>
                <option value={invoiceNumber}>{invoiceNumber}</option>
              </select>
            </div>
          </div>
        }
        
        {fromPriceBook && 
          <div className="flex justify-center items-center m-auto mb-4">
            <div className="flex gap-4">
              <select className="border p-0 px-2 rounded-lg h-[32px] w-[200px] ">
                <option value="">-- Select Customer --</option>
                <option value="all">All Customers </option>
                {customersAll.map((customer) => (
                  <option key={customer.customer_id} value={customer.customer_id} onChange={(e) => setCustomer(e.target.value)}>
                    {customer.business_name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        }

        {fromDeliveryPrice && 
          <div className="flex justify-center items-center m-auto mb-4">
            <div className="flex gap-4">
              <select className="border p-0 px-2 rounded-lg h-[32px] w-[200px] ">
                <option value="">-- Select Customer --</option>
                <option value="all">-- All Customers --</option>
                {customersAll.map((customer) => (
                  <option key={customer.customer_id} value={customer.customer_id} onChange={(e) => setCustomer(e.target.value)}>
                    {customer.business_name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        }

        <div className="flex flex-col gap-4 w-full">
          {notifications.map((notification, index) => {
            const isHighlighted = notification === highlightedNotification;
            const todayDate = dayjs().format("MM-DD-YYYY");
            
            return (
              <div key={index} className="w-full">
                <div
                  className={`flex items-center justify-between w-full p-4 rounded-lg ${
                    isHighlighted ? "bg-blue-100 border-l-[6px] border-blue-600" : "bg-white"
                  }`}
                  style={{ boxShadow: "0 0 20px 0 rgba(0, 0, 0,.1)" }}
                >
                  <div className={`flex-1 font-medium ${isHighlighted ? '' : 'text-gray-400'}`}>
                    {index + 1}. {notification}
                  </div>

                  <div className="ml-4 flex gap-2">
                    <button
                      className={`w-40 px-2 py-1 border-2 ${
                        isHighlighted
                          ? "border-blue-600 text-blue-600 hover:bg-blue-600 hover:text-white hover:shadow-blue-600/50 rounded-md"
                          : "border-gray-300 text-gray-400 cursor-not-allowed"
                      } rounded-md uppercase duration-200 hover:shadow-lg active:scale-90`}
                      disabled={!isHighlighted}
                      onClick={() => {
                        handleModeSelect(index, 'sms');
                        setMessage(`Dear ${business_name || customer}, we just sent you a ${location.state?.fromPastDue ? 'past due' : ''}${location.state?.fromPartialPay ? 'partial pay': ''}${fromPriceBook ? 'price change': ''}${fromDeliveryPrice ? 'delivery price': ''} message at ${todayDate}. Thank you, ${bill_from_name || businessName || businessNamefromdeliveryprice}.`);
                      }}
                    >
                      SMS
                    </button>
                    <button
                      className={`w-40 px-2 py-1 border-2 ${
                        isHighlighted
                          ? "border-purple-600 text-purple-600 hover:bg-purple-600 hover:text-white hover:shadow-purple-600/50 rounded-md"
                          : "border-gray-300 text-gray-400 cursor-not-allowed"
                      } rounded-md uppercase duration-200 hover:shadow-lg active:scale-90`}
                      disabled={!isHighlighted}
                      onClick={() => {
                        handleModeSelect(index, 'email');
                        setEmail(`Dear ${business_name || customer}, we just sent you a ${location.state?.fromPastDue ? 'past due' : ''}${location.state?.fromPartialPay ? 'partial pay': ''}${fromPriceBook ? 'price change': ''}${fromDeliveryPrice ? 'delivery price': ''} message at ${todayDate}. Thank you, ${bill_from_name || businessName || businessNamefromdeliveryprice}.`);
                      }}
                    >
                      Email
                    </button>
                    <button
                      className={`w-40 px-2 py-1 border-2 ${
                        isHighlighted
                          ? "border-orange-600 text-orange-600 hover:bg-orange-600 hover:text-white hover:shadow-orange-600/50 rounded-md"
                          : "border-gray-300 text-gray-400 cursor-not-allowed"
                      } rounded-md uppercase duration-200 hover:shadow-lg active:scale-90`}
                      disabled={!isHighlighted}
                      onClick={() => {
                        handleModeSelect(index, 'both');
                        setBoth(`Dear ${business_name || customer}, we just sent you a ${location.state?.fromPastDue ? 'past due' : ''}${location.state?.fromPartialPay ? 'partial pay': ''}${fromPriceBook ? 'price change': ''}${fromDeliveryPrice ? 'delivery price': ''} message at ${todayDate}. Thank you, ${bill_from_name || businessName || businessNamefromdeliveryprice}.`);
                      }}
                    >
                      Both
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      
      {selectedMode === 'sms' && (
        <div className="mt-4 p-4  rounded-lg shadow-md flex flex-col  p-4 w-[1080px] bg-white"
        style={{
          boxShadow: "0 0 20px 0 rgba(0, 0, 0,.3)",
        }}
        >
          <div className="flex justify-between items-center mb-2">
            <span className="font-bold">SMS Message</span>
            <button onClick={() => setIsEditing(!isEditing)} className="text-gray-500">
              ✎
            </button>
          </div>
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            disabled={!isEditing}
            className={`w-full p-2 min-h-[80px] border border-gray-300 ${isEditing ? 'bg-gray-100': 'bg-white'}`}
          />
          <div className="flex justify-end mt-2">
          <button
                  className={`w-40 px-2 py-1 border-2 ${"border-green-600 text-green-600 hover:bg-green-600 hover:text-white hover:shadow-green-600/50 rounded-md"
                  } rounded-md uppercase duration-200 hover:shadow-lg active:scale-90`}
              onClick={()=> {
                dispatch(addToast({ type: 'success', message: `SMS Sent Successfully` }));
              }}
                >
                  SEND
                </button>
          </div>
        </div>
      )}
      
      {selectedMode === 'email' && (
        <div className="mt-4 p-4  rounded-lg shadow-md flex flex-col  p-4 w-[1080px] bg-white"
        style={{
          boxShadow: "0 0 20px 0 rgba(0, 0, 0,.3)",
        }}
        >
          <div className="flex justify-between items-center mb-2">
            <span className="font-bold">Email Message</span>
            <button onClick={() => setIsEditing(!isEditing)} className="text-gray-500">
              ✎
            </button>
          </div>
          <textarea
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={!isEditing}
            className={`w-full p-2 min-h-[80px] border border-gray-300 ${isEditing ? 'bg-gray-100': 'bg-white'}`}
          />
          <div className="flex justify-end mt-2">
          <button
                  className={`w-40 px-2 py-1 border-2 ${"border-green-600 text-green-600 hover:bg-green-600 hover:text-white hover:shadow-green-600/50 rounded-md"
                  } rounded-md uppercase duration-200 hover:shadow-lg active:scale-90`}
              onClick={()=> {
                dispatch(addToast({ type: 'success', message: `Email Sent Successfully` }));
              }}
                >
                  SEND
                </button>
          </div>
        </div>
      )}
      
      {selectedMode === 'both' && (
        <div className="mt-4 p-4  rounded-lg shadow-md flex flex-col  p-4 w-[1080px] bg-white"
        style={{
          boxShadow: "0 0 20px 0 rgba(0, 0, 0,.3)",
        }}
        >
          <div className="flex justify-between items-center mb-2">
            <span className="font-bold">SMS and Email Message</span>
            <button onClick={() => setIsEditing(!isEditing)} className="text-gray-500">
              ✎
            </button>
          </div>
          <textarea
            value={both}
            onChange={(e) => setBoth(e.target.value)}
            disabled={!isEditing}
            className={`w-full p-2 min-h-[80px]  border border-gray-300 ${isEditing ? 'bg-gray-100': 'bg-white'}`}
          />
          <div className="flex justify-end mt-2">
          <button
                  className={`w-40 px-2 py-1 border-2 ${"border-green-600 text-green-600 hover:bg-green-600 hover:text-white hover:shadow-green-600/50 rounded-md"
                  } rounded-md uppercase duration-200 hover:shadow-lg active:scale-90`}
              onClick={()=> {
                dispatch(addToast({ type: 'success', message: `SMS,Email Sent Successfully` }));
              }}
                >
                  SEND
                </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default HomeNotifications;
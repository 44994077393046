// import React, { useState, useEffect } from 'react';
// import { useParams, useNavigate } from 'react-router-dom';
// import DatePicker from 'react-datepicker';
// import { toast } from 'react-toastify';
// import config from "../../config/config";
// import axios from 'axios';
// import dayjs from 'dayjs';
// import EditEndTimePickerViews from './timepickers/EditEndTimePicker';
// import EditStartTimePickerViews from './timepickers/EditStartTimePicker';
// import { FaCalendarAlt } from 'react-icons/fa';
// import { useDispatch } from "react-redux";
// import { addToast } from '../pages/ToastBox/ToastBoxMessageSlice';

// const EditNotification = () => {
//     const dispatch = useDispatch()
  
//   const baseURL = process.env.NODE_ENV === "production"
//     ? config.production.baseURL
//     : config.development.baseURL;
//   const { id } = useParams();
//   const user_id = localStorage.getItem("user_id");
//   const [selectedBusiness, setSelectedBusiness] = useState('');
//   const [startDate, setStartDate] = useState(null);
//   const [endDate, setEndDate] = useState(null);
//   const [scrollingMessage, setScrollingMessage] = useState('');
//   const [businessDetails, setBusinessDetails] = useState([]);
//   const [notificationType, setNotificationType] = useState("");
//   const [isNotificationTypeReadOnly, setIsNotificationTypeReadOnly] = useState(false);
//   const [deploymentStartDate, setDeploymentStartDate] = useState(null);
//   const [startTime, setStartTime] = useState(null);  // Initialize as null
//   const [endTime, setEndTime] = useState(null);  // Initialize as null
//   const navigate = useNavigate();

//   const formatDateForApi = (date) => {
//     if (!date) return '';
//     const year = date.getFullYear();
//     const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
//     const day = String(date.getDate()).padStart(2, '0');
//     return `${year}-${month}-${day}`;
//   };

//   const parseDate = (dateString) => {
//     const [year, month, day] = dateString.split('-');
//     return new Date(year, month - 1, day);
//   };

//   const handleStartTimeChange = (timeString) => {
//     if (dayjs(timeString, 'hh:mm A', true).isValid()) {
//       setStartTime(timeString);
//     } else {
//       console.error("Invalid start time", timeString);
//     }
//   };

//   const handleEndTimeChange = (timeString) => {
//     if (dayjs(timeString, 'hh:mm A', true).isValid()) {
//       setEndTime(timeString);
//     } else {
//       console.error("Invalid end time", timeString);
//     }
//   };

//   useEffect(() => {
//     const fetchBusinessDetails = async () => {
//       try {
//         const response = await axios.get(`${baseURL}/${btoa("business/view")}`);
//         setBusinessDetails(response.data.business_data);
//       } catch (error) {
//         console.error("Error fetching business details", error);
//       }
//     };
//     fetchBusinessDetails();
//   }, [baseURL]);

//   useEffect(() => {
//     const fetchScrollingMessage = async () => {
//       try {
//         const response = await axios.get(`${baseURL}/${btoa("scrollingmsg/scrollingmsg")}/${btoa(id)}`);
//         const data = response.data.scrollingmsg_data[0];
//         console.log("Fetched data:", data);

//         const fromDandt = dayjs(data.from_dandt, 'YYYY-MM-DD HH:mm:ss', true);
//         const toDandt = dayjs(data.to_dandt, 'YYYY-MM-DD HH:mm:ss', true);

//         if (!fromDandt.isValid() || !toDandt.isValid()) {
//           throw new Error("Invalid date format");
//         }

//         setSelectedBusiness(data.business_id);
//         setStartDate(dayjs(data.start_date).toDate());
//         setEndDate(dayjs(data.end_date).toDate());
//         setDeploymentStartDate(fromDandt.toDate());
//         setStartTime(fromDandt.format('hh:mm A'));  // Update state with time value
//         setEndTime(toDandt.format('hh:mm A'));  // Update state with time value
//         setScrollingMessage(data.message);
//         setNotificationType(data.mesg_type);
//       } catch (error) {
//         console.error("Error fetching scrolling message by id", error);
//       }
//     };
//     fetchScrollingMessage();
//   }, [id, baseURL]);

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const data = {
//       businessId: selectedBusiness,
//       userId: user_id,
//       startDate: formatDateForApi(startDate),
//       endDate: formatDateForApi(endDate),
//       message: scrollingMessage,
//       userName: localStorage.getItem("userName"),
//       rowId: id,
//       notificationType,
//       fromDate: formatDateForApi(deploymentStartDate),
//       fromTime: startTime,
//       toTime: endTime
//     };
//     console.log("Updating  data:", data);
//     try {
//       const response = await axios.put(`${baseURL}/${btoa("scrollingmsg/update")}`, data);
//       console.log("update response" , response);
//       // toast.success("Message updated successfully", { autoClose: 2500 });
//         dispatch(addToast({ type: 'success', message: `Message updated successfully` }));       
      
//       navigate("/notifications");
//     } catch (error) {
//       console.error("Error updating scrolling message", error);
//       // toast.error("Error updating message", { autoClose: 2500 });
//       dispatch(addToast({ type: 'danger', message: `Error updating message` }));       

//     }
//   };

//   useEffect(() => {
//     if (selectedBusiness !== "0" && selectedBusiness !== "") {
//       setNotificationType("Info");
//       setIsNotificationTypeReadOnly(true);
//     } else {
//       setNotificationType("");
//       setIsNotificationTypeReadOnly(false);
//     }
//   }, [selectedBusiness]);

//   return (
//     <div className="mt-8 flex justify-center">
//       <form onSubmit={handleSubmit} className="bg-white rounded-lg overflow-hidden p-6">
//         <div className='flex flex-row space-x-4 w-full'>
          
//         <div className="mb-4 w-full">
//             <label htmlFor="notification" className="block text-gray-700 font-semibold mb-2">
//               Notification type
//             </label>
//             <select
//               id="notification"
//               value={notificationType}
//               onChange={(e) => setNotificationType(e.target.value)}
//               className={`w-full px-3 py-2 border-none rounded-md shadow-lg ${isNotificationTypeReadOnly ? 'bg-gray-200' : ''}`}
//               required
//               style={{ boxShadow: '0 0 8px rgba(0, 0, 0, 0.2)' }}
//               disabled={isNotificationTypeReadOnly}
//             >
//               <option value="">--Select--</option>
//               <option value="Info">Info</option>
//               <option value="Release">Release</option>
//             </select>
//           </div>

//           <div className="mb-4 w-full">
//             <label htmlFor="business" className="block text-gray-700 font-semibold mb-2">
//               Select Business*
//             </label>
//             <select
//               id="business"
//               value={selectedBusiness}
//               onChange={(e) => setSelectedBusiness(e.target.value)}
//               className="w-full px-3 py-2 border-none rounded-md shadow-lg"
//               required
//               style={{ boxShadow: '0 0 8px rgba(0, 0, 0, 0.2)' }}
//             >
//               <option value="">--Select--</option>
//               <option value="0">All Business</option>
//               {businessDetails.map((selected, index) => (
//                 <option value={selected.business_id} key={index}>{selected.business_name}</option>
//               ))}
//             </select>
//           </div>

          
//         </div>

//         <div className="flex flex-row w-full space-x-4">
//           <div className="mb-4">
//             <label htmlFor="startDate" className="block text-gray-700 font-semibold mb-2">
//               From
//             </label>
//             <div className="w-full rounded-md" style={{ boxShadow: '0 0 8px rgba(0, 0, 0, 0.2)' }}>
//               <DatePicker
//                 id="startDate"
//                 selected={startDate}
//                 onChange={(date) => setStartDate(date)}
//                 className="w-full px-3 py-2 border-none rounded-md"
//                 placeholderText="Select Start Date"
//                 dateFormat="MM/dd/yyyy"
//               />
//             </div>
//           </div>
//           <div className="mb-4">
//             <label htmlFor="endDate" className="block text-gray-700 font-semibold mb-2">
//               To
//             </label>
//             <div className="w-full rounded-md" style={{ boxShadow: '0 0 8px rgba(0, 0, 0, 0.2)' }}>
//               <DatePicker
//                 id="endDate"
//                 selected={endDate}
//                 onChange={(date) => setEndDate(date)}
//                 className="w-full px-3 py-2 border-none rounded-md"
//                 placeholderText="Select End Date"
//                 dateFormat="MM/dd/yyyy"
//               />
//             </div>
//           </div>
//         </div>



//         <div>
//             {selectedBusiness === "0" && notificationType === "Release" && (
//                         <div>
//                           <h2 className="text-lg font-semibold text-gray-600 mb-1">Select Deployment Date and Time</h2>
//                           <hr className="border-t border-gray-300 mb-1" />
                          
//                             <div className="flex flex-row w-full space-x-4">
//                               <div className="mb-4">
//                                 <label htmlFor="deploymentStartDate" className="block text-gray-700 font-semibold mb-2">
//                                   Select date
//                                 </label>
//                                 <div
//                                   className="w-72 flex items-center rounded-md bg-white shadow-sm relative"
//                                   style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
//                                 >
//                                   <DatePicker
//                                     id="deploymentStartDate"
//                                     selected={deploymentStartDate}
//                                     minDate={new Date()}
//                                     autoComplete='off'
//                                     onChange={(date) => setDeploymentStartDate(date)}
//                                     className="w-full px-3 py-2 border-none rounded-md focus:outline-none focus:ring-0"
//                                     placeholderText="Select Start Date"
//                                     dateFormat="MM/dd/yyyy"
//                                   />
//                                   <FaCalendarAlt className="text-gray-500 absolute right-3 pointer-events-none" />
//                                 </div>
//                               </div>

//                               <EditStartTimePickerViews
//                                 startTime={startTime}
//                                 onStartTimeChange={handleStartTimeChange}
//                               />
//                               <EditEndTimePickerViews
//                                 endTime={endTime}
//                                 onEndTimeChange={handleEndTimeChange}
//                               />
//                             </div>

//                         </div>
//               )}
//           </div>

//         <div className="mb-4">
//           <label htmlFor="scrollingMessage" className="block text-gray-700 font-semibold mb-2">
//             Scrolling Message
//           </label>
//           <textarea
//             id="scrollingMessage"
//             value={scrollingMessage}
//             onChange={(e) => setScrollingMessage(e.target.value)}
//             className="w-full px-3 py-2 border-none rounded-md shadow-lg"
//             rows="4"
//             style={{ boxShadow: '0 0 8px rgba(0, 0, 0, 0.2)' }}
//           />
//         </div>

//         <div className="flex justify-end space-x-4">
//           <button
//             type="submit"
//             className="bg-blue-500 text-white px-4 py-2 rounded-md shadow-lg hover:bg-blue-600"
//             style={{ boxShadow: '0 0 8px rgba(0, 0, 0, 0.2)' }}
//           >
//             Update
//           </button>
//           <button
//             type="button"
//             onClick={() => navigate("/notifications")}
//             className="bg-gray-500 text-white px-4 py-2 rounded-md shadow-lg hover:bg-gray-600"
//             style={{ boxShadow: '0 0 8px rgba(0, 0, 0, 0.2)' }}
//           >
//             Cancel
//           </button>
//         </div>
//       </form>
//     </div>
//   );
// };

// export default EditNotification;



import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import config from "../../config/config";
import axios from 'axios';
import dayjs from 'dayjs';
import EditEndTimePickerViews from './timepickers/EditEndTimePicker';
import EditStartTimePickerViews from './timepickers/EditStartTimePicker';
import { FaCalendarAlt } from 'react-icons/fa';
import { useDispatch } from "react-redux";
import { addToast } from '../pages/ToastBox/ToastBoxMessageSlice';

const EditNotification = () => {
    const dispatch = useDispatch()
  
  const baseURL = process.env.NODE_ENV === "production"
    ? config.production.baseURL
    : config.development.baseURL;
  const { id } = useParams();
  const user_id = localStorage.getItem("user_id");
  const [selectedBusiness, setSelectedBusiness] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [scrollingMessage, setScrollingMessage] = useState('');
  const [businessDetails, setBusinessDetails] = useState([]);
  const [notificationType, setNotificationType] = useState("");
  const [isNotificationTypeReadOnly, setIsNotificationTypeReadOnly] = useState(false);
  const [deploymentStartDate, setDeploymentStartDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const navigate = useNavigate();

  // Validation states
  const [errors, setErrors] = useState({
    notificationType: '',
    selectedBusiness: '',
    startDate: '',
    endDate: '',
    deploymentStartDate: '',
    startTime: '',
    endTime: '',
    scrollingMessage: ''
  });
  const [touched, setTouched] = useState({
    notificationType: false,
    selectedBusiness: false,
    startDate: false,
    endDate: false,
    deploymentStartDate: false,
    startTime: false,
    endTime: false,
    scrollingMessage: false
  });
  const [isFormValid, setIsFormValid] = useState(false);

  const formatDateForApi = (date) => {
    if (!date) return '';
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const parseDate = (dateString) => {
    const [year, month, day] = dateString.split('-');
    return new Date(year, month - 1, day);
  };

  const handleStartTimeChange = (timeString) => {
    if (dayjs(timeString, 'hh:mm A', true).isValid()) {
      setStartTime(timeString);
      validateField('startTime', timeString);
    } else {
      console.error("Invalid start time", timeString);
      setErrors(prev => ({ ...prev, startTime: 'Invalid time format' }));
    }
    setTouched(prev => ({ ...prev, startTime: true }));
  };

  const handleEndTimeChange = (timeString) => {
    if (dayjs(timeString, 'hh:mm A', true).isValid()) {
      setEndTime(timeString);
      validateField('endTime', timeString);
    } else {
      console.error("Invalid end time", timeString);
      setErrors(prev => ({ ...prev, endTime: 'Invalid time format' }));
    }
    setTouched(prev => ({ ...prev, endTime: true }));
  };

  // Handle field blur for validation
  const handleBlur = (field) => {
    setTouched(prev => ({ ...prev, [field]: true }));
    
    // Validate the field on blur
    switch (field) {
      case 'notificationType':
        validateField('notificationType', notificationType);
        break;
      case 'selectedBusiness':
        validateField('selectedBusiness', selectedBusiness);
        break;
      case 'startDate':
        validateField('startDate', startDate);
        break;
      case 'endDate':
        validateField('endDate', endDate);
        break;
      case 'deploymentStartDate':
        validateField('deploymentStartDate', deploymentStartDate);
        break;
      case 'scrollingMessage':
        validateField('scrollingMessage', scrollingMessage);
        break;
      default:
        break;
    }
  };

  // Validate individual field
  const validateField = (field, value) => {
    let newErrors = { ...errors };
    
    switch (field) {
      case 'notificationType':
        newErrors.notificationType = !value ? 'Please select a notification type' : '';
        break;
      case 'selectedBusiness':
        newErrors.selectedBusiness = !value ? 'Please select a business' : '';
        break;
      case 'startDate':
        newErrors.startDate = !value ? 'Please select a start date' : '';
        break;
      case 'endDate':
        if (!value) {
          newErrors.endDate = 'Please select an end date';
        } else if (startDate && value < startDate) {
          newErrors.endDate = 'End date cannot be before start date';
        } else {
          newErrors.endDate = '';
        }
        break;
      case 'deploymentStartDate':
        if (selectedBusiness === "0" && notificationType === "Release" && !value) {
          newErrors.deploymentStartDate = 'Please select a deployment date';
        } else {
          newErrors.deploymentStartDate = '';
        }
        break;
      case 'startTime':
        if (selectedBusiness === "0" && notificationType === "Release" && !value) {
          newErrors.startTime = 'Please select a start time';
        } else {
          newErrors.startTime = '';
        }
        break;
      case 'endTime':
        if (selectedBusiness === "0" && notificationType === "Release" && !value) {
          newErrors.endTime = 'Please select an end time';
        } else if (startTime && value && dayjs(value, 'hh:mm A').isBefore(dayjs(startTime, 'hh:mm A'))) {
          newErrors.endTime = 'End time cannot be before start time';
        } else {
          newErrors.endTime = '';
        }
        break;
      case 'scrollingMessage':
        newErrors.scrollingMessage = !value || value.trim() === '' ? 'Please enter a message' : '';
        break;
      default:
        break;
    }
    
    setErrors(newErrors);
    return !newErrors[field]; // Return true if the field is valid
  };

  // Validate all fields
  const validateForm = () => {
    // Basic required fields
    const isNotificationTypeValid = validateField('notificationType', notificationType);
    const isBusinessValid = validateField('selectedBusiness', selectedBusiness);
    const isStartDateValid = validateField('startDate', startDate);
    const isEndDateValid = validateField('endDate', endDate);
    const isMessageValid = validateField('scrollingMessage', scrollingMessage);
    
    // Conditional validation for deployment fields
    let isDeploymentFieldsValid = true;
    if (selectedBusiness === "0" && notificationType === "Release") {
      const isDeploymentDateValid = validateField('deploymentStartDate', deploymentStartDate);
      const isStartTimeValid = validateField('startTime', startTime);
      const isEndTimeValid = validateField('endTime', endTime);
      isDeploymentFieldsValid = isDeploymentDateValid && isStartTimeValid && isEndTimeValid;
    }
    
    const formValid = isNotificationTypeValid && isBusinessValid && isStartDateValid && 
                      isEndDateValid && isMessageValid && isDeploymentFieldsValid;
    
    setIsFormValid(formValid);
    return formValid;
  };

  useEffect(() => {
    const fetchBusinessDetails = async () => {
      try {
        const response = await axios.get(`${baseURL}/${btoa("business/view")}`);
        setBusinessDetails(response.data.business_data);
      } catch (error) {
        console.error("Error fetching business details", error);
      }
    };
    fetchBusinessDetails();
  }, [baseURL]);

  useEffect(() => {
    const fetchScrollingMessage = async () => {
      try {
        const response = await axios.get(`${baseURL}/${btoa("scrollingmsg/scrollingmsg")}/${btoa(id)}`);
        const data = response.data.scrollingmsg_data[0];
        console.log("Fetched data:", data);

        const fromDandt = dayjs(data.from_dandt, 'YYYY-MM-DD HH:mm:ss', true);
        const toDandt = dayjs(data.to_dandt, 'YYYY-MM-DD HH:mm:ss', true);

        if (!fromDandt.isValid() || !toDandt.isValid()) {
          throw new Error("Invalid date format");
        }

        setSelectedBusiness(data.business_id);
        setStartDate(dayjs(data.start_date).toDate());
        setEndDate(dayjs(data.end_date).toDate());
        setDeploymentStartDate(fromDandt.toDate());
        setStartTime(fromDandt.format('hh:mm A'));
        setEndTime(toDandt.format('hh:mm A'));
        setScrollingMessage(data.message);
        setNotificationType(data.mesg_type);
        
        // Mark fields as touched since they're pre-filled
        // setTouched({
        //   notificationType: true,
        //   selectedBusiness: true,
        //   startDate: true,
        //   endDate: true,
        //   deploymentStartDate: true,
        //   startTime: true,
        //   endTime: true,
        //   scrollingMessage: true
        // });
        
        // Validate pre-filled form
        // setTimeout(() => {
        //   validateForm();
        // }, 0);
      } catch (error) {
        console.error("Error fetching scrolling message by id", error);
      }
    };
    fetchScrollingMessage();
  }, [id, baseURL]);
  const validateInitialForm = () => {
    // Run validation without showing errors to user
    const isFormValid = validateForm();
    return isFormValid;
  };
  useEffect(() => {
    // Only validate if we have essential data loaded
    if (scrollingMessage && startDate && endDate) {
      validateInitialForm();
    }
  }, [scrollingMessage, startDate, endDate]);
  // Revalidate form when dependencies change
  useEffect(() => {
    validateForm();
  }, [notificationType, selectedBusiness, startDate, endDate, deploymentStartDate, startTime, endTime, scrollingMessage]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Set all fields as touched
    const allTouched = Object.keys(touched).reduce((acc, field) => {
      acc[field] = true;
      return acc;
    }, {});
    setTouched(allTouched);
    
    // Validate all fields before submission
    const isValid = validateForm();
    
    if (!isValid) {
      dispatch(addToast({ type: 'error', message: 'Please fill all required fields correctly' }));
      return;
    }
    
    const data = {
      businessId: selectedBusiness,
      userId: user_id,
      startDate: formatDateForApi(startDate),
      endDate: formatDateForApi(endDate),
      message: scrollingMessage,
      userName: localStorage.getItem("userName"),
      rowId: id,
      notificationType,
      fromDate: formatDateForApi(deploymentStartDate),
      fromTime: startTime,
      toTime: endTime
    };
    console.log("Updating data:", data);
    try {
      const response = await axios.put(`${baseURL}/${btoa("scrollingmsg/update")}`, data);
      console.log("update response", response);
      dispatch(addToast({ type: 'success', message: `Message updated successfully` }));
      navigate("/notifications");
    } catch (error) {
      console.error("Error updating scrolling message", error);
      dispatch(addToast({ type: 'danger', message: `Error updating message` }));
    }
  };

  useEffect(() => {
    if (selectedBusiness !== "0" || selectedBusiness !== "") {
      setNotificationType("Info");
      setIsNotificationTypeReadOnly(true);
    } else {
      setNotificationType("");
      setIsNotificationTypeReadOnly(false);
    }
  }, [selectedBusiness]);

  return (
    <div className="mt-8 flex justify-center">
      <form onSubmit={handleSubmit} className="bg-white rounded-lg overflow-hidden p-6">
        <div className='flex flex-row space-x-4 w-full'>
          
        <div className="mb-4 w-full">
            <label htmlFor="notification" className="block text-gray-700 font-semibold mb-2">
              Notification type*
            </label>
            <select
              id="notification"
              value={notificationType}
              onChange={(e) => {
                setNotificationType(e.target.value);
                validateField('notificationType', e.target.value);
              }}
              onBlur={() => handleBlur('notificationType')}
              className={`w-full px-3 py-2 border-none rounded-md shadow-lg ${isNotificationTypeReadOnly ? 'bg-gray-200' : ''} ${
                touched.notificationType && errors.notificationType ? 'border-2 border-red-500' : ''
              }`}
              required
              style={{ boxShadow: '0 0 8px rgba(0, 0, 0, 0.2)' }}
              disabled={isNotificationTypeReadOnly}
            >
              <option value="">--Select--</option>
              <option value="Info">Info</option>
              <option value="Release">Release</option>
            </select>
            {touched.notificationType && errors.notificationType && (
              <p className="text-red-500 text-sm mt-1">{errors.notificationType}</p>
            )}
          </div>

          <div className="mb-4 w-full">
            <label htmlFor="business" className="block text-gray-700 font-semibold mb-2">
              Select Business*
            </label>
            <select
              id="business"
              value={selectedBusiness}
              onChange={(e) => {
                setSelectedBusiness(e.target.value);
                validateField('selectedBusiness', e.target.value);
              }}
              onBlur={() => handleBlur('selectedBusiness')}
              className={`w-full px-3 py-2 border-none rounded-md shadow-lg ${
                touched.selectedBusiness && errors.selectedBusiness ? 'border-2 border-red-500' : ''
              }`}
              required
              style={{ boxShadow: '0 0 8px rgba(0, 0, 0, 0.2)' }}
            >
              <option value="">--Select--</option>
              <option value="0">All Business</option>
              {businessDetails.map((selected, index) => (
                <option value={selected.business_id} key={index}>{selected.business_name}</option>
              ))}
            </select>
            {touched.selectedBusiness && errors.selectedBusiness && (
              <p className="text-red-500 text-sm mt-1">{errors.selectedBusiness}</p>
            )}
          </div>
        </div>

        <div className="flex flex-row w-full space-x-4">
          <div className="mb-4">
            <label htmlFor="startDate" className="block text-gray-700 font-semibold mb-2">
              From*
            </label>
            <div className={`w-full rounded-md ${
              touched.startDate && errors.startDate ? 'border-2 border-red-500' : ''
            }`} style={{ boxShadow: '0 0 8px rgba(0, 0, 0, 0.2)' }}>
              {/* <DatePicker
                id="startDate"
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                  validateField('startDate', date);
                  // Also validate endDate since it depends on startDate
                  validateField('endDate', endDate);
                }}
                onBlur={() => handleBlur('startDate')}
                className="w-full px-3 py-2 border-none rounded-md"
                placeholderText="Select Start Date"
                dateFormat="MM/dd/yyyy"
              /> */}
 <DatePicker
  id="startDate"
  selected={startDate}
  onChange={(date) => {
    setStartDate(date);
    validateField('startDate', date);
    // Also validate endDate since it depends on startDate
    validateField('endDate', endDate);
  }}
  onBlur={() => handleBlur('startDate')}
  className="w-full px-3 py-2 border-none rounded-md"
  placeholderText="Select Start Date"
  dateFormat="MM/dd/yyyy"
  minDate={new Date()} // This prevents selecting past dates
/>
            </div>
            {touched.startDate && errors.startDate && (
              <p className="text-red-500 text-sm mt-1">{errors.startDate}</p>
            )}
          </div>
          <div className="mb-4">
            <label htmlFor="endDate" className="block text-gray-700 font-semibold mb-2">
              To*
            </label>
            <div className={`w-full rounded-md ${
              touched.endDate && errors.endDate ? 'border-2 border-red-500' : ''
            }`} style={{ boxShadow: '0 0 8px rgba(0, 0, 0, 0.2)' }}>
              {/* <DatePicker
                id="endDate"
                selected={endDate}
                onChange={(date) => {
                  setEndDate(date);
                  validateField('endDate', date);
                }}
                onBlur={() => handleBlur('endDate')}
                className="w-full px-3 py-2 border-none rounded-md"
                placeholderText="Select End Date"
                dateFormat="MM/dd/yyyy"
              /> */}

<DatePicker
  id="endDate"
  selected={endDate}
  onChange={(date) => {
    setEndDate(date);
    validateField('endDate', date);
  }}
  onBlur={() => handleBlur('endDate')}
  className="w-full px-3 py-2 border-none rounded-md"
  placeholderText="Select End Date"
  dateFormat="MM/dd/yyyy"
  minDate={startDate || new Date()} // Use start date if available, otherwise today
/>
              
            </div>
            {touched.endDate && errors.endDate && (
              <p className="text-red-500 text-sm mt-1">{errors.endDate}</p>
            )}
          </div>
        </div>

        <div>
            {selectedBusiness === "0" && notificationType === "Release" && (
                <div>
                  <h2 className="text-lg font-semibold text-gray-600 mb-1">Select Deployment Date and Time</h2>
                  <hr className="border-t border-gray-300 mb-1" />
                  
                    <div className="flex flex-row w-full space-x-4">
                      <div className="mb-4">
                        <label htmlFor="deploymentStartDate" className="block text-gray-700 font-semibold mb-2">
                          Select date*
                        </label>
                        <div
                          className={`w-72 flex items-center rounded-md bg-white shadow-sm relative ${
                            touched.deploymentStartDate && errors.deploymentStartDate ? 'border-2 border-red-500' : ''
                          }`}
                          style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
                        >
                          <DatePicker
                            id="deploymentStartDate"
                            selected={deploymentStartDate}
                            minDate={new Date()}
                            autoComplete='off'
                            onChange={(date) => {
                              setDeploymentStartDate(date);
                              validateField('deploymentStartDate', date);
                            }}
                            onBlur={() => handleBlur('deploymentStartDate')}
                            className="w-full px-3 py-2 border-none rounded-md focus:outline-none focus:ring-0"
                            placeholderText="Select Start Date"
                            dateFormat="MM/dd/yyyy"
                          />
                          <FaCalendarAlt className="text-gray-500 absolute right-3 pointer-events-none" />
                        </div>
                        {touched.deploymentStartDate && errors.deploymentStartDate && (
                          <p className="text-red-500 text-sm mt-1">{errors.deploymentStartDate}</p>
                        )}
                      </div>

                      <div className="relative">
                        <EditStartTimePickerViews
                          startTime={startTime}
                          onStartTimeChange={handleStartTimeChange}
                        />
                        {touched.startTime && errors.startTime && (
                          <p className="text-red-500 text-sm mt-1 absolute">{errors.startTime}</p>
                        )}
                      </div>
                      
                      <div className="relative">
                        <EditEndTimePickerViews
                          endTime={endTime}
                          onEndTimeChange={handleEndTimeChange}
                        />
                        {touched.endTime && errors.endTime && (
                          <p className="text-red-500 text-sm mt-1 absolute">{errors.endTime}</p>
                        )}
                      </div>
                    </div>
                </div>
              )}
          </div>

        <div className="mb-4">
          <label htmlFor="scrollingMessage" className="block text-gray-700 font-semibold mb-2">
            Scrolling Message*
          </label>
          <textarea
            id="scrollingMessage"
            value={scrollingMessage}
            onChange={(e) => {
              setScrollingMessage(e.target.value);
              validateField('scrollingMessage', e.target.value);
            }}
            onBlur={() => handleBlur('scrollingMessage')}
            className={`w-full px-3 py-2 border-none rounded-md shadow-lg ${
              touched.scrollingMessage && errors.scrollingMessage ? 'border-2 border-red-500' : ''
            }`}
            rows="4"
            style={{ boxShadow: '0 0 8px rgba(0, 0, 0, 0.2)' }}
          />
          {touched.scrollingMessage && errors.scrollingMessage && (
            <p className="text-red-500 text-sm mt-1">{errors.scrollingMessage}</p>
          )}
        </div>

        <div className="flex justify-end space-x-4">
          <button
            type="submit"
            className={`${
              isFormValid
                ? 'bg-blue-500 hover:bg-blue-600'
                : 'bg-blue-300 cursor-not-allowed'
            } text-white px-4 py-2 rounded-md shadow-lg`}
            style={{ boxShadow: '0 0 8px rgba(0, 0, 0, 0.2)' }}
            disabled={!isFormValid}
          >
            Update
          </button>
          <button
            type="button"
            onClick={() => navigate("/notifications")}
            className="bg-gray-500 text-white px-4 py-2 rounded-md shadow-lg hover:bg-gray-600"
            style={{ boxShadow: '0 0 8px rgba(0, 0, 0, 0.2)' }}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditNotification;
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTabs } from "../pages/TabContext";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
// import * as Yup from "yup";
import {
  MdOutlineLocalShipping,
  MdOutlinePayment,
  MdOutlinePhoneInTalk,
  MdPersonOutline
} from "react-icons/md";
import { TbFileInvoice } from "react-icons/tb";
// import CustomTooltip from "./CustomeTooltip";
import { MdOutlineBusinessCenter } from "react-icons/md";
import OwnerPasswordChangeModal from "../pages/OwnerPasswordChangeModal";
import config from "../../config/config";
import profileImage from "../../../src/assets/images/profilePic.jpg";
import FaxIcon from "../../../src/assets/images/fax image.jpg";
import ContactPhoneOutlinedIcon from "@mui/icons-material/ContactPhoneOutlined";
import { useDispatch } from "react-redux";
import { addToast } from "../pages/ToastBox/ToastBoxMessageSlice";
import FullScreenLoader from "../pages/FullScreenLoader";

function capitalizeFirstLetter(email) {
  return email.charAt(0).toUpperCase() + email.slice(1).toLowerCase();
}

const ViewBusiness = () => {
  const dispatch = useDispatch();
  const { changeTab } = useTabs();
  const handleCancel = () => {
    // Change tab to 'items'
    navigate(`/`); // Navigate to the 'items' route
  };

  const { businessId } = useParams();
  console.log(businessId);

  const [isFormModified, setIsFormModified] = useState(false);
  const baseURL =
    process.env.NODE_ENV === "production"
      ? config.production.baseURL
      : config.development.baseURL;
  const CustomTooltip1 = ({ content, show, marginLeft }) => {
    return (
      <div
        style={{
          display: show ? "block" : "none",
          position: "absolute",
          backgroundColor: "#ffffff",
          color: "#fff",
          padding: "5px",
          borderRadius: "4px",
          border: "1px solid red",
          marginTop: "-35px",
          marginLeft: `${marginLeft}px`, // Use the provided marginLeft prop
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)"
        }}
      >
        <div
          style={{
            position: "absolute",
            bottom: "-7px",
            left: "50%",
            marginLeft: "-10px",
            width: "0",
            height: "0",
            borderLeft: "7px solid transparent",
            borderRight: "7px solid transparent",
            borderTop: "7px solid #f98080"
          }}
        ></div>
        <p className="text-xs text-red-500">{content}</p>
      </div>
    );
  };

  const BusinessAddressTooltip = ({ content, show, marginLeft }) => {
    return (
      <div
        style={{
          display: show ? "block" : "none",
          position: "absolute",
          backgroundColor: "#ffffff",
          color: "#fff",
          padding: "5px",
          borderRadius: "4px",
          border: "1px solid red",
          marginTop: "-35px",
          marginLeft: `${marginLeft}px`,
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)"
        }}
      >
        <div
          style={{
            position: "absolute",
            bottom: "-7px",
            left: "50%",
            marginLeft: "-10px",
            width: "0",
            height: "0",
            borderLeft: "7px solid transparent",
            borderRight: "7px solid transparent",
            borderTop: "7px solid #f98080"
          }}
        ></div>
        <p className="text-xs text-red-500">{content}</p>
      </div>
    );
  };

  const navigate = useNavigate();
  const [customerDetails, setCustomerDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [customerEditMode, setCustomerEditMode] = useState(false);
  const [billingEditMode, setBillingEditMode] = useState(false);
  const [shippingEditMode, setShippingEditMode] = useState(false);
  const [paymentEditMode, setPaymentEditMode] = useState(false);

  const [billingZipCodeError, setBillingZipCodeError] = useState("");
  const [shippingZipCodeError, setShippingZipCodeError] = useState("");
  const [cardZipCodeError, setCardZipCodeError] = useState("");
  const [bankAccountError, setBankAccountError] = useState("");
  const [businessAccountError, setBusinessAccountError] = useState("");
  const [inputPhoneError, setInputPhoneError] = useState("");
  const [bankRoutingError, setBankRoutingError] = useState("");

  const [emailError, setEmailError] = useState("");

  const [isBillingInfoModified, setIsBillingInfoModified] = useState(false);
  const [isShippingInfoModified, setIsShippingInfoModified] = useState(false);
  const [isPaymentInfoModified, setIsPaymentInfoModified] = useState(false);

  const [busNameError, setbusNameError] = useState("");
  const [busAddNameError, setBusAddNameError] = useState("");
  const [busCityNameError, setbusCityNameError] = useState("");
  const [zipCodeError, setZipCodeError] = useState("");

  const [contactNameError, setContactNameError] = useState("");
  const [editMode, setEditMode] = useState(false);

  const [isBusinessInfoModified, setIsBusinessInfoModified] = useState(false);
  const [isContactInfoModified, setIsContactInfoModified] = useState(false);

  const [businessInfoEditMode, setBusinessInfoEditMode] = useState(false);
  const [contactInfoEditMode, setContactInfoEditMode] = useState(false);

  const [managersData, setManagersData] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [ownerId, setOwnerId] = useState();
  const [emailuserToggle, setIsEmailuserToggle] = useState(false);
  const [phoneuserToggle, setIsuserPhoneToggle] = useState(false);

  const [OwnerPhoneToggle, setIsOwnerPhoneToggle] = useState(false);
  const [OwnerEmailToggle, setIsOwnerEmailToggle] = useState(false);

  const [OwnerNameToggle, setIsOwnerNameToggle] = useState(false);

  const handleOpenModal = () => {
    console.log("Button clicked'");
    setIsModalOpen(true);
    document.body.style.overflow = "hidden";
  };

  // const handleCloseModal = () => {
  //   console.log('Modal closed');
  //   setIsModalOpen(false);
  //   document.body.style.overflow = '';
  // };

  const handleUserPhoneToggle = () => {
    setIsuserPhoneToggle((prev) => !prev);
  };
  const handleUserEmailToggle = () => {
    setIsEmailuserToggle((prev) => !prev);
  };

  const handleOwnerPhoneToggle = () => {
    setIsOwnerPhoneToggle((prev) => !prev);
  };
  const handleOwnerEmailToggle = () => {
    setIsOwnerEmailToggle((prev) => !prev);
  };

  const handleOwnerNameToggle = () => {
    setIsOwnerNameToggle((prev) => !prev);
  };
  const handlebusNameChange = (e) => {
    const { name, value } = e.target;

    // Validate item name to contain only alphabets and spaces
    let inputValue = value.replace(/[^a-zA-Z\s]/g, ""); // Remove non-alphabetic characters except spaces

    setFormData({
      ...formData,
      [name]: capitalizeWords(inputValue)
    });
    console.log(formData);

    // Set error message if item name is not empty and less than 3 characters
    if (inputValue.replace(/\s/g, "").length > 0 && inputValue.length < 3) {
      setbusNameError("Name must be at least 3 letters");
    } else {
      setbusNameError("");
    }
    const isModified = value !== customerDetails[name];
    if (businessInfoFields.includes(name)) {
      setIsBusinessInfoModified(isModified);
    }
  };
  const handlebusAddChange = (e) => {
    const { name, value } = e.target;

    // Validate item name to contain only alphabets and spaces
    let inputValue = value.replace(/[^a-zA-Z\s]/g, ""); // Remove non-alphabetic characters except spaces

    setFormData({
      ...formData,
      [name]: capitalizeWords(inputValue)
    });

    // Set error message if item name is not empty and less than 3 characters
    if (inputValue.replace(/\s/g, "").length > 0 && inputValue.length < 3) {
      setBusAddNameError("Address must be at least 3 letters");
    } else {
      setBusAddNameError("");
    }
    const isModified = value !== customerDetails[name];
    if (businessInfoFields.includes(name)) {
      setIsBusinessInfoModified(isModified);
    }
  };
  const handlebusCityChange = (e) => {
    const { name, value } = e.target;

    // Validate item name to contain only alphabets and spaces
    let inputValue = value.replace(/[^a-zA-Z\s]/g, ""); // Remove non-alphabetic characters except spaces

    setFormData({
      ...formData,
      [name]: capitalizeWords(inputValue)
    });

    // Set error message if item name is not empty and less than 3 characters
    if (inputValue.replace(/\s/g, "").length > 0 && inputValue.length < 3) {
      setbusCityNameError(" City Name must be at least 3 letters");
    } else {
      setbusCityNameError("");
    }
    const isModified = value !== customerDetails[name];
    if (businessInfoFields.includes(name)) {
      setIsBusinessInfoModified(isModified);
    }
  };

  const [ownerData, setOwnerData] = useState({
    edit_ownerId: ownerId, // OWNER DATA SETTING FROM API
    contact_name: "",
    contact_email: "",
    contact_no: ""
  });

  const handleContactNameChange = (e) => {
    const { name, value } = e.target;

    let inputValue = value.replace(/[^a-zA-Z\s]/g, ""); // Remove non-alphabetic characters except spaces

    setOwnerData({
      ...ownerData,
      [name]: capitalizeWords(inputValue)
    });

    // Set error message if item name is not empty and less than 3 characters
    if (inputValue.replace(/\s/g, "").length > 0 && inputValue.length < 3) {
      setContactNameError(" Name must be at least 3 letters");
    } else {
      setContactNameError("");
    }
    const isContactModified = value !== customerDetails[name];
    if (contactInfoFields.includes(name)) {
      setIsContactInfoModified(isContactModified);
    }
  };

  const [formData, setFormData] = useState({
    businessName: "",
    businessAddress: "",
    businessCity: "",
    businessState: "",
    businessZipCode: "",
    contactPersonName: "",
    contactPersonEmail: "",
    contactPersonPhoneNumber: ""
  });

  console.log(formData);

  const [isCardSelected, setIsCardSelected] = useState(false);
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  const validateEmail = (email) => {
    return emailPattern.test(email);
  };

  const handleEmailChange = (e) => {
    const { name, value } = e.target;

    // Validate email
    const isValidEmail = validateEmail(value);

    setOwnerData({
      ...ownerData,
      [name]: value
    });

    // Set error message if email is not in the expected format
    if (value.length > 0 && !isValidEmail) {
      setEmailError("Invalid email address");
    } else {
      setEmailError("");
    }
    const isContactModified = value !== customerDetails[name];
    if (contactInfoFields.includes(name)) {
      setIsContactInfoModified(isContactModified);
    }
  };

  const showToast = (message, type, businessName, filledFrames) => {
    let toastColorClass;
    let iconComponent;

    if (type === "success") {
      toastColorClass = "bg-blue-50 text-blue-500";
      iconComponent = (
        <FaCheckCircle className="mr-6 text-[#1D72F3] bg-blue-50" size={24} />
      );
    } else {
      toastColorClass = "bg-red-50 text-red-500";
      iconComponent = <FaTimesCircle className="mr-6 text-red-500" size={24} />;
    }

    toast.error(
      <div className={`flex justify-between ${toastColorClass}`}>
        <div className="rounded-lg" style={{ whiteSpace: "nowrap" }}>
          {message}
        </div>
        <div className="rounded-lg  ">{iconComponent}</div>
      </div>,
      {
        position: "top-left",
        hideProgressBar: true,
        autoClose: 4000,
        icon: false,
        className: " w-[843px] ml-[240px] mt-[60px]", // Full width
        style: {
          borderLeft:
            type === "success" ? "5px solid #1D72F3" : "5px solid red",
          background: type === "success" ? "#ebf5ff" : "#fdf2f2",
          minHeight: "10px"
        }
      }
    );
  };

  const business_id = localStorage.getItem("business_id");
  const user_id = localStorage.getItem("user_id");
  const user_role = localStorage.getItem("user_role");
  // console.log("user role is" , user_role);
  // console.log("bus id is " , business_id);
  // console.log("user id is " , user_id);

  useEffect(() => {
    axios
      .get(
        `${baseURL}${btoa("business/profile")}/${btoa(business_id)}/${btoa(
          user_id
        )}/${btoa(user_role)}`
      )
      .then((response) => {
        console.log(response);
        const businessData = response.data.business_data[0];
        console.log(businessData);
        // setCustomerDetails(businessData);
        setFormData({
          businessId: businessData.business_id,
          businessName: businessData.business_name,
          businessAddress: businessData.business_address,
          businessCity: businessData.business_city,
          businessState: businessData.business_state,
          businessZipCode: businessData.business_zip_code,
          contactPersonName: businessData.contact_name,
          contactPersonEmail: businessData.contact_email,
          contactPersonPhoneNumber: businessData.contact_no,
          business_contact_no: businessData.business_contact_no,
          fax_number: businessData.fax_number
        });

        const ownerData = response.data.owner_data[0];
        setOwnerData({
          contact_name: ownerData.contact_name,
          contact_email: ownerData.contact_email,
          contact_no: ownerData.contact_no
        });

        setManagersData(response.data.managers_list);

        // console.log("owners data " , ownerData);
        // console.log("managers data" , managersData);
        setOwnerId(response.data.business_data[0].user_id);

        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching customer data:", error);
        setLoading(false);
      });
  }, [businessId]);

  console.log("managers data", managersData);

  const handleInputPhoneChange = (e) => {
    const { name, value } = e.target;
    let inputValue = value.replace(/\D/g, "");
    inputValue = inputValue.slice(0, 10);

    if (inputValue.length === 10) {
      inputValue = `(${inputValue.slice(0, 3)})${inputValue.slice(
        3,
        6
      )}-${inputValue.slice(6, 10)}`;
    }

    setOwnerData({
      ...ownerData,
      [name]: inputValue
    });

    if (inputValue.length > 0 && !/^\(\d{3}\)\d{3}-\d{4}$/.test(inputValue)) {
      setInputPhoneError("Please enter a valid number");
    } else {
      setInputPhoneError("");
    }
    const isContactModified = value !== customerDetails[name];
    if (contactInfoFields.includes(name)) {
      setIsContactInfoModified(isContactModified);
    }
    const isShippingModified = value !== customerDetails[name];
    if (contactInfoFields.includes(name)) {
      setIsShippingInfoModified(isShippingModified);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    const transformedValue = [
      "businessState",
      "billingState",
      "shippingState",
      "bankCurrency",
      "cardPaymentCurrency"
    ].includes(name)
      ? value
      : capitalizeWords(value);

    setFormData({
      ...formData,
      [name]: transformedValue
    });

    const isModified = value !== customerDetails[name];
    if (businessInfoFields.includes(name)) {
      setIsBusinessInfoModified(isModified);
    }
    const isContactModified = value !== customerDetails[name];
    if (contactInfoFields.includes(name)) {
      setIsContactInfoModified(isContactModified);
    }
  };

  const processBusinessAccountInput = (value) => {
    // Remove any characters that are not letters, numbers, or spaces
    const inputValue = value.replace(/[^a-zA-Z0-9\s]/g, "");

    // Capitalize the first letter of each word
    const processedValue = inputValue
      .toLowerCase()
      .replace(/(?:^|\s)\S/g, (char) => char.toUpperCase());

    return processedValue;
  };

  const handleInputZipChange = (e) => {
    const { name, value } = e.target;

    // Validate zip code to be at most 5 digits
    let inputValue = value.replace(/\D/g, ""); // Remove non-numeric characters
    inputValue = inputValue.slice(0, 5); // Limit to 5 digits

    setFormData({
      ...formData,
      [name]: inputValue
    });

    // Set error message if zip code is not empty and exceeds 5 digits
    if (inputValue.length > 0 && inputValue.length < 5) {
      setZipCodeError("Zip code must be at most 5 digits");
    } else {
      setZipCodeError("");
    }
    const isModified = value !== customerDetails[name];
    if (businessInfoFields.includes(name)) {
      setIsBusinessInfoModified(isModified);
    }
  };

  const handleEditClick = async (e) => {
    if (
      zipCodeError ||
      billingZipCodeError ||
      shippingZipCodeError ||
      cardZipCodeError ||
      bankAccountError ||
      businessAccountError ||
      inputPhoneError
    ) {
      // Display an error message or take appropriate action
      return;
    }
    const updatedData = {
      edit_businessName: formData.businessName,
      edit_businessId: formData.businessId,
      edit_businessAddress: formData.businessAddress,
      edit_businessCity: formData.businessCity,
      edit_businessState: formData.businessState,
      edit_businessZipCode: formData.businessZipCode,
      edit_contactPersonName: formData.contactPersonName,
      edit_contactPersonEmail: formData.contactPersonEmail,
      edit_contactPersonPhoneNumber: formData.contactPersonPhoneNumber
    };
    // ... (Rest of your code)
    console.log("updated data :", updatedData);

    try {
      const response = await axios.put(
        `${baseURL}/${btoa("business/update/")}`,
        updatedData
      );
      console.log("updated Data : ", updatedData);

      console.log("Update Response:", response); // Add this log to check the response

      if (response.status === 200) {
        const responseData = await response.data;

        if (responseData.dataUpdatingStatus === true) {
          const filledFrames = [];

          // Check each frame and add its name to the array
          if (formData.businessName) filledFrames.push("Info");
          if (formData.contactPersonName) filledFrames.push("Contact Info");
          if (formData.billingAddress) filledFrames.push("Billing Info");
          if (formData.shippingTo) filledFrames.push("Shipping Info");
          if (formData.bankCurrency) filledFrames.push("Payment Info");

          let successMessage = `${formData.businessName}`;

          if (filledFrames.length > 0) {
            successMessage += ` updated successfully`;
          }

          // showToast(
          //   successMessage,
          //   "success",
          //   formData.businessName,
          //   filledFrames
          // );
          dispatch(addToast({ type: "success", message: `${successMessage}` }));

          // Clear form data after a successful submission
          // You can call a function to reset form values here if needed
        } else {
          // showToast(
          //   `Unable to update ${formData.businessName} customer data`,
          //   "error"
          // );
          dispatch(
            addToast({
              type: "danger",
              message: `Unable to update ${formData.businessName} customer data`
            })
          );
        }
      } else {
        console.log("Request failed with status: " + response.status);
        response.data.then((data) => {
          console.log(data);
        });
        // showToast("Unable to update customer, please try again", "error");
        dispatch(
          addToast({
            type: "danger",
            message: `Unable to update customer, please try again`
          })
        );
      }
    } catch (error) {
      console.error("Update Error:", error);
      // showToast("Unable to update customer, please try again", "error");
      dispatch(
        addToast({
          type: "danger",
          message: `Unable to update customer, please try again`
        })
      );
    }

    window.scrollTo(0, 0);
    navigate(`/`);
  };

  console.log("Form Data:", formData);

  // Owner data sending

  console.log(ownerId);

  const ownerUpdatedData = {
    edit_ownerId: user_id,
    email_db: localStorage.getItem("userEmail"),
    edit_contactPersonName: ownerData.contact_name,
    edit_contactPersonEmail: ownerData.contact_email,
    edit_contactPersonPhoneNumber: ownerData.contact_no
  };

  const handleOwnerEditClick = async () => {
    try {
      const response = await axios.put(
        `${baseURL}/${btoa("owner/update/")}`,
        ownerUpdatedData
      );
      console.log("owner data is", ownerUpdatedData);
      console.log("owner data posting ", response);
      // navigate("/")
      setContactInfoEditMode(!contactInfoEditMode);
      let successMessage = `${ownerData.contact_name}, details updated successfully`;
      //   showToast(
      //   successMessage,
      //   "success",
      // );
      dispatch(addToast({ type: "success", message: `${successMessage}` }));
    } catch (error) {
      console.log("error posting owner details", error);
    }
  };

  const handleToggle = () => {
    setIsCardSelected(!isCardSelected);
  };

  const capitalizeWords = (input) => {
    return input
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  // Usage example
  const businessInfoFields = [
    "businessName",
    "businessAddress",
    "businessCity",
    "businessState",
    "businessZipCode"
  ];
  const contactInfoFields = [
    "contactPersonName",
    "contactPersonEmail",
    "contactPersonPhoneNumber"
  ];

  const filteredManagersData = managersData.filter(
    (manager) => manager.contact_email !== ownerData.contact_email
  );
  const formatEmail = (email) => {
    if (!email) {
      return "";
    }
    const [localPart, domain] = email.split("@");
    const maskedLocalPart = localPart.slice(0, 2) + "*****";
    const domainTLD = domain.slice(domain.lastIndexOf("."));
    return `${maskedLocalPart}${domainTLD}`;
  };

  return (
    <div>
      {loading ? (
        <p>
          <FullScreenLoader />
        </p>
      ) : (
        <div className="w-full mx-auto mt-[20px]">
          <h1 className="text-xl font-bold ml-10">Owner Contact Info</h1>
          <div className="flex p-4">
            <div className=" p-4 w-1/3 h-50">
              <div
                className="bg-white rounded-box rounded-xl"
                style={{ height: "98%" }}
              >
                <div className="flex items-center">
                  <MdOutlineBusinessCenter className="text-gray-700 w-6 h-6 mr-2 mb-10" />
                  <h2 className="mb-10 text-xl font-bold text-gray-900 underline underline-offset-8 decoration-gray-300">
                    User Profile
                  </h2>
                </div>

                <div className="w-32 h-32 rounded-full bg-gray-200 flex items-center justify-center mb-4 mx-auto">
                  <img
                    src={profileImage} // Replace with actual profile image URL
                    alt="Profile"
                    className="w-full h-full object-cover rounded-full"
                  />
                </div>

                {/* Display user details */}
                {/* <div className="w-full text-center">
                  <h2 className="text-xl font-bold text-gray-900 mb-2">
                    {formData.businessName}
                  </h2>
                  {/* <p className="text-md text-gray-700 mb-2"><strong>Business Name:</strong> {businessData.business_name}</p> */}
                  {/* <p className="text-md text-gray-700 mb-2"><strong>Business Address:</strong> {businessData.business_address}</p> */}
                  {/* <p className="text-md text-gray-700 mb-2">
                    <strong></strong> {formData.businessAddress}
                  </p>

                  <p className="text-md text-gray-700 mb-2">
                    <strong></strong> {formData.businessCity},
                    {formData.businessState}, {formData.businessZipCode}
                  </p>
                  {formData.fax_number !== null ||
                  formData.fax_number !== "null" ||
                  formData.fax_number !== undefined ||
                  formData.fax_number !== "undefined" ? (
                    <p className="text-md text-gray-700 mb-2 flex items-center justify-center">
                      <img
                        src={FaxIcon || ""}
                        alt="Fax Icon"
                        className="w-6 h-6 mr-2"
                      />
                      {formData.fax_number || ""}
                    </p>
                  ) : (
                    ""
                  )}
                  {formData.business_contact_no !== null ||
                  formData.business_contact_no !== "null" ||
                  formData.business_contact_no !== undefined ||
                  formData.business_contact_no !== "undefined" ? (
                    <p className="text-md text-gray-700 mb-2">
                      <strong>
                        {" "}
                        <ContactPhoneOutlinedIcon />
                      </strong>{" "}
                      {formData.business_contact_no || ""}
                    </p>
                  ) : (
                    ""
                  )} */}
                  {/* <p className="text-md text-gray-700 mb-2"><strong>State:</strong> {businessData.business_state}</p> */}
                  {/* <p className="text-md text-gray-700 mb-2"><strong>Zip Code:</strong> {businessData.business_zip_code}</p> */}
                {/* </div> */} 


                <div className="w-full text-center">
  <h2 className="text-xl font-bold text-gray-900 mb-2">
    {formData.businessName}
  </h2>
  <p className="text-md text-gray-700 mb-2">
    <strong></strong> {formData.businessAddress}
  </p>

  <p className="text-md text-gray-700 mb-2">
    <strong></strong> {formData.businessCity},
    {formData.businessState}, {formData.businessZipCode}
  </p>
  
  {formData.fax_number && formData.fax_number !== "null" && formData.fax_number !== "undefined" ? (
    <p className="text-md text-gray-700 mb-2 flex items-center justify-center">
      <img
        src={FaxIcon || ""}
        alt="Fax Icon"
        className="w-5 h-5 mr-2"
      />
      {formData.fax_number}
    </p>
  ) : ""}
  
  {formData.business_contact_no && formData.business_contact_no !== "null" && formData.business_contact_no !== "undefined" ? (
    <p className="text-md text-gray-700 mb-2">
      <strong>
        {" "}
        <ContactPhoneOutlinedIcon className="w-5 h-5 mr-2" />
      </strong>{" "}
      {formData.business_contact_no}
    </p>
  ) : ""}
</div>


              </div>
            </div>
            <div className=" p-0 w-2/3">
              <div className="w-auto bg-white rounded-box  mx-8 rounded-xl mt-4">
                <div className="flex items-center">
                  <MdOutlinePhoneInTalk className="text-gray-700 w-6 h-6 mr-2 mb-10" />
                  <h2 className="mb-10 text-xl font-bold text-gray-900 underline underline-offset-8 decoration-gray-300">
                    Business Owner Contact Info
                  </h2>
                </div>

                <div className="grid gap-4 sm:grid-cols-1 sm:gap-6">
                  <div className="w-full">
                    <label
                      htmlFor="contactPersonName"
                      className="block mb-2 text-sm font-medium text-gray-900 "
                    >
                      Contact Person Name
                      {/* <span className="text-red-500 text-xl font-extrabold  ml-1">*</span> */}
                    </label>
                    <CustomTooltip1
                      content={contactNameError}
                      show={!!contactNameError}
                      marginLeft={120}
                    />
                    <div className="flex">
                      <input
                        className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${
                          contactInfoEditMode ? "bg-white" : "bg-gray-200"
                        }`}
                        placeholder="Enter Name"
                        type="text"
                        id="contact_name"
                        name="contact_name"
                        value={ownerData.contact_name}
                        onChange={handleContactNameChange}
                        required
                        readOnly={!contactInfoEditMode}
                      />
                      <label
                        htmlFor="OwnerNameToggle"
                        className="switch-toggle flex items-center ml-4 mt-2 mb-0"
                      >
                        <input
                          type="checkbox"
                          onChange={handleOwnerNameToggle}
                          id="OwnerNameToggle"
                          name="OwnerNameToggle"
                          className="hidden"
                          aria-label="OwnerNameToggle"
                        />
                        <span className="slider-toggle round"></span>
                      </label>
                    </div>
                  </div>

                  <div className="w-full">
                    <label
                      htmlFor="contactPersonEmail"
                      className="block mb-2 text-sm font-medium text-gray-900 "
                    >
                      Email
                      {/* <span className="text-red-500 text-xl font-extrabold  ml-1">*</span> */}
                    </label>
                    <CustomTooltip1
                      content={emailError}
                      show={!!emailError}
                      marginLeft={206}
                    />
                    <div className="flex">
                      <input
                        className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${
                          contactInfoEditMode ? "bg-white" : "bg-gray-200"
                        }`}
                        placeholder="Enter Email"
                        type="email"
                        id="contact_email"
                        name="contact_email"
                        value={capitalizeFirstLetter(
                          formatEmail(ownerData.contact_email)
                        )}
                        onChange={handleEmailChange}
                        required
                        readOnly={!contactInfoEditMode}
                      />
                      <label
                        htmlFor="OwnerEmailToggle"
                        className="switch-toggle flex items-center ml-4 mt-2 mb-0"
                      >
                        <input
                          type="checkbox"
                          onChange={handleOwnerEmailToggle}
                          id="OwnerEmailToggle"
                          name="OwnerEmailToggle"
                          className="hidden"
                          aria-label="OwnerEmailToggle"
                        />
                        <span className="slider-toggle round"></span>
                      </label>
                    </div>
                    {/* {emailError && (
                  <div className="text-red-500 text-sm mt-1">{emailError}</div>
                )} */}
                  </div>

                  <div className="w-full">
                    <label
                      htmlFor="contactPersonPhoneNumber"
                      className="block mb-2 text-sm font-medium text-gray-900  "
                    >
                      Phone Number
                      {/* <span className="text-red-500 text-xl font-extrabold ml-1">*</span> */}
                    </label>
                    <CustomTooltip1
                      content={inputPhoneError}
                      show={!!inputPhoneError}
                      marginLeft={163}
                    />
                    <div className="flex">
                      <input
                        className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${
                          contactInfoEditMode ? "bg-white" : "bg-gray-200"
                        }`}
                        placeholder="Enter Phone Number"
                        type="text"
                        id="contact_no"
                        name="contact_no"
                        value={ownerData.contact_no}
                        onChange={handleInputPhoneChange}
                        required
                        readOnly={!contactInfoEditMode}
                      />
                      <label
                        htmlFor="OwnerPhoneToggle"
                        className="switch-toggle flex items-center ml-4 mt-2 mb-0"
                      >
                        <input
                          type="checkbox"
                          onChange={handleOwnerPhoneToggle}
                          id="OwnerPhoneToggle"
                          name="OwnerPhoneToggle"
                          className="hidden"
                          aria-label="OwnerPhoneToggle"
                        />
                        <span className="slider-toggle round"></span>
                      </label>
                    </div>
                    {/* {inputPhoneError && (
                  <div className="text-red-500 text-sm mt-1">
                    {inputPhoneError}
                  </div>
                )} */}
                  </div>
                </div>

                <div className="flex justify-end space-x-3">
                  <>
                    {/* <button
                    onClick={handleCancel}
                    type="submit"
                    className="hover:bg-red-500 border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-sm font-semibold hover:border-transparent hover:shadow-lg hover:shadow-red-500/60 text-center hover:text-white  rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800"
                  >
                    Cancel
                  </button> */}

                    {/* <button
                      onClick={() => {
                        if (contactInfoEditMode) {
                          // Perform save logic here
                          handleOwnerEditClick();
                        } else {
                          // Toggle edit mode
                          setContactInfoEditMode(!contactInfoEditMode);
                        }
                      }}
                    type="submit"
                    disabled={
                      ownerData.contact_name.length < 3 || 
                      ownerData.contact_email.length < 3 ||
                      !ownerData.contact_email.includes('@') ||
                      !ownerData.contact_email.includes('.') || 
                      ownerData.contact_no.length < 13 ||
                      emailError
                    // Add additional conditions for other business info fields if needed
                  }
                    className="hover:bg-[#0044AB] border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-sm font-semibold hover:border-transparent hover:shadow-lg hover:shadow-[#0044AB]/50 text-center hover:text-white  rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800"
                  >
                    {contactInfoEditMode ? "Save" : "Edit"}
                  </button> */}

                    {/* <button
                    type="submit"
                    onClick={handleOpenModal}
                     className="hover:bg-[#0044AB] border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-sm font-semibold hover:border-transparent hover:shadow-lg hover:shadow-[#0044AB]/50 text-center hover:text-white rounded-lg focus:ring-4 focus:ring-primary-200 hover:bg-primary-800"
                  >
                    change password
                  </button>
                  {isModalOpen && (
                    <OwnerPasswordChangeModal onClose={handleCloseModal} />
                  )} */}
                  </>
                </div>
              </div>

              <div>
                <>
                  <OwnerPasswordChangeModal />
                </>
                {/* {filteredManagersData.map((manager , index) => (
          <div key={manager.id} className="w-auto bg-white rounded-box mx-8 rounded-xl mt-8">
              
              <div className="flex items-center">
              <MdOutlinePhoneInTalk className="text-gray-700 w-6 h-6 mr-2 mb-10" />
              <h2 className="mb-10 text-xl font-bold text-gray-900 underline underline-offset-8 decoration-gray-300">
                Business User Contact Info
              </h2>
             </div>

          <div className="grid gap-4 sm:grid-cols-1 sm:gap-6">
              <div className="w-full">
                <label
                  htmlFor="contactPersonName"
                  className="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Contact Person Name
                </label>
              

                <input
                  className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${
                  "bg-gray-200"
                  }`}
                  placeholder="Enter Name"
                  type="text"
                  id={`contactPersonName-${index}`}
                  name={`contactPersonName-${index}`}
                  value={manager.contact_name}
                  onChange={handleContactNameChange}
                  required
                  readOnly
                />
              </div>



            <div className="w-full">
                <label
                  htmlFor="contactPersonEmail"
                  className="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Email
                </label>
<div className="flex">
                <input
                  className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${
                    "bg-gray-200"
                  }`}
                  placeholder="Enter Email"
                  type="email"
                  id={`contactPersonEmail-${index}`}
                  name={`contactPersonEmail-${index}`}
                  value={capitalizeFirstLetter(formatEmail(manager.contact_email))}
                  onChange={handleEmailChange}
                  required
                  readOnly
                />
      
  </div>
               
              </div>

              <div className="w-full">
                <label
                  htmlFor="contactPersonPhoneNumber"
                  className="block mb-2 text-sm font-medium text-gray-900  "
                >
                  Phone Number
                </label>
             
<div className="flex">
                <input
                  className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${
                  "bg-gray-200"
                  }`}
                  placeholder="Enter Phone Number"
                  type="text"
                  id={`contactPersonPhoneNumber-${index}`}
                  name={`contactPersonPhoneNumber-${index}`}
                  value={manager.contact_no}
                  onChange={handleInputPhoneChange}
                  required
                  readOnly
                />
          
  </div>
              
              </div>
            </div>

            <div className="flex justify-end space-x-3">
              
          </div>
        </div>
      ))} */}
              </div>
              {/* <>
         <OwnerPasswordChangeModal />
         </> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewBusiness;

import React from 'react';
import { FaEnvelope, FaLock, FaSignInAlt } from 'react-icons/fa';
// import img1 from "../../assets/images/Login.jpg"
import img2  from  "../../assets/images/Loginold.jpg"
import { IoPerson } from "react-icons/io5";
import { useNavigate ,Link } from 'react-router-dom';
import { MdBusinessCenter } from "react-icons/md";
import { MdLockReset } from "react-icons/md";
import { GiCancel } from "react-icons/gi";
import axios from 'axios';
import { useState } from 'react';
import { toast } from 'react-toastify';
import config  from "../../config/config"
import PersonIcon from "@mui/icons-material/Person";
import InvoiceFileLogo from '../../assets/images/InvoiceFileLogo.png'
import { useDispatch, useSelector } from "react-redux";
import { addToast } from './ToastBox/ToastBoxMessageSlice';
import { MdAttachEmail } from "react-icons/md";  

const ForgotPassword = ({handleForgotCancelClick}) => {

  const dispatch = useDispatch();
  const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;
    const divStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        height: '100vh',
        width: "100vw",
        display: 'flex',
        backgroundImage: `url(${img2})`,
        backgroundSize: '100% 100%',
        backgroundPosition: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 999,
      };
      const [showMaskedEmail, setShowMaskedEmail] = useState(false);
      const formatEmail = (email) => {
        if (!email || typeof email !== 'string') {
          return '';
        }
        
        if (!email.includes('@')) {
          return email;
        }
        
        const [localPart, domain] = email.split('@');
        
        if (!domain) {
          return email;
        }
        
        const maskedLocalPart = localPart.slice(0, 2) + "*****";
        
        const lastDotIndex = domain.lastIndexOf('.');
        if (lastDotIndex === -1) {
          return `${maskedLocalPart}@${"*".repeat(domain.length)}`;
        }
        
        const domainName = domain.substring(0, lastDotIndex);
        const tld = domain.substring(lastDotIndex);
        const domainTLD = domain.slice(domain.lastIndexOf('.'));
        const maskedDomain = domainName.charAt(0) + "*".repeat(Math.max(3, domainName.length - 1));
        
        return `${maskedLocalPart}${domainTLD}`;
      };
      let navigate = useNavigate()
  const [emailStyles, setEmailStyles] = useState('')
  const [domainStyles, setdomainStyles] = useState('')
    const [invalidemailError, setinvalidEmailError] = useState(false)
      const [invalidDomainError, setinvalidDomainError] = useState(false)

      const [formData, setFormData] = useState({
        email: "",
        domainName: ""
      });

      const handleEmailChange = (e) => {
        setShowMaskedEmail(false); // Reset masking when typing
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };

      const handleDomainNameChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name] : value,
        }));
      };


      const EmailTooltip = ({ content, styles }) => {
        return (
          <div
          style={{
            display: content ? "block" : "none",
            position: "absolute",
            backgroundColor: "#ffffff",
            color: "#fff",
            padding: "5px",
            borderRadius: "4px",
            border: `1px solid ${styles.borderColor || "red"}`,
            // left : "309px", 
            right : "80px",
            marginTop: "44px",
            right: "10px", // Adjust this value as needed
            zIndex: 1,
            width : "125px",
            ...styles,
          }}
          >
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "-7px",
                marginTop: "-7px",
                width: "0",
                height: "0",
                borderTop: "7px solid transparent",
                borderBottom: "7px solid transparent",
                borderRight: `7px solid ${styles.arrowStyle?.borderTopColor || "red"}`,
                }}
            ></div>
            <p className="text-xs text-red-500">{content}</p>
          </div>
        );
      };
      const DomainNameTooltip = ({ content, styles }) => {
        return (
          <div
            style={{
              display: content ? "block" : "none",
              position: "absolute",
              backgroundColor: "#ffffff",
              color: "#fff",
              padding: "5px",
              borderRadius: "4px",
              border: `1px solid ${styles.borderColor || "red"}`, // Adjust border color as needed
              // left: "309px", // Adjust this value as needed
              // marginTop: "-3px",
              // right: "20px", // Adjust this value as needed
              right : "80px", 
              marginTop: "44px",
                right: "10px",
              zIndex: 1,
              width: "225px",
              ...styles,
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "-7px",
                marginTop: "-7px",
                width: "0",
                height: "0",
                borderTop: "7px solid transparent",
                borderBottom: "7px solid transparent",
                borderRight: `7px solid ${
                  styles.arrowStyle?.borderTopColor || "red" // Adjust arrow color as needed
                }`,
              }}
            ></div>
            <p className="text-xs text-red-500">{content}</p>
          </div>
        );
      };

      const handleChangePassword= async ()=>{
        
        console.log("reset password data" , formData);
        
        try{
          const response = await axios.post(`${baseURL}/${btoa("login/forgotpassword")}` ,{
            email : formData.email,
            domainName : formData.domainName
          })
          console.log(response);
          if(response.data.updateStatus === "Password updated successfully"){
            // toast.success("New Password has been sent to you registered mobile number" , {autoClose : 2000}).
           dispatch(addToast({ type: 'success', message: `New Password has been sent to you registered mobile number` }));        
            
          }
          handleForgotCancelClick()

        }catch(error){
            console.log("error resetting password");
            // toast.error("Invalid email or domain name" ,  {autoClose : 2000})
            dispatch(addToast({ type: 'error', message: `Invalid email or domain name` }));        

        }
       
      }


    
  return (
    <>
  
 <div className="flex h-screen w-screen relative overflow-hidden">
 <div className="absolute top-[-12.5%] left-[55%] w-[1040px] h-[1040px] bg-[#25caed] rotate-[-13deg] shadow-lg rounded-[4%] -z-9999"></div>
 <div className="absolute top-[-10%] left-[58%] w-[1050px] h-[1050px] bg-white rotate-[-13deg] shadow-lg rounded-[4%] -z-9999"></div>
 {/* Left Section (3/4 width) */}
 <div className="w-3/5 bg-gradient-to-br from-[#25caed] to-[#0044AB] flex flex-col justify-center items-center  pr-[40px]">
   <div className="text-center text-white max-w-[500px] px-8">
   <h1 className="text-5xl font-bold m-auto ml-[50px]">
        <img
           style={{ width: '300px',
            height: '80px'}}
            src={ InvoiceFileLogo}
            alt="InvoiceFile"
            className={`${ "w-max"}`}
          />

        </h1>
     <p className="text-xl font-normal mb-14 text-white-300">Smart Invoice Processing</p>
     <h2 className="text-[28px] font-bold mb-4">
       Create Professional Invoices to Send to Your Customers
     </h2>
     <p className="text-base leading-relaxed ">
          Streamline your billing process with 
        </p>
        <p className="text-md leading-relaxed ">
        professional, easy-to-use
        templates.
        </p>
   </div>
 </div>

 {/* Right Section (1/4 width) */}
 <div className="w-2/5  flex justify-center items-center" style={{zIndex:999}}>
   <div className="w-full max-w-sm px-8 py-12">
     <div className="text-center mb-8">
       <div className="w-24 h-24 bg-gray-200 rounded-full m-auto flex items-center justify-center">
   <PersonIcon style={{ fontSize: 50, color: "gray" }} />

       </div>
       <h2 className="text-2xl text-gray-600 font-bold mt-4">Forgot Password?</h2>
     </div>
     <div className="space-y-4" >
       <div>
         <label htmlFor="email" className="block text-sm font-medium text-gray-700">
           Email
         </label>
         {/* {invalidemailError && (
                      <EmailTooltip content="Invalid email address" styles={showTooltip()} />
                    )} */}
         <div className="relative">
                  <MdAttachEmail
                    className="text-gray-500 absolute top-1/2 transform -translate-y-1/2 left-3"
                    style={{ fontSize: "18px" }}
                  />
        <input
  type="text"
  name="email"
  id="email"
  autoComplete='off'
  value={showMaskedEmail && formData.email ? formatEmail(formData.email) : formData.email}
  onChange={handleEmailChange}
  onFocus={() => {
    setShowMaskedEmail(false);
    setinvalidEmailError(false);
  }}
  onBlur={() => {
    setinvalidEmailError(true);
    if (formData.email.trim()) {
      if (formData.email.includes('@')) {
        setShowMaskedEmail(true);
      }
      setEmailStyles("bg-blue-100 text-blue-600 border-blue-600");
    } else {
      setEmailStyles("");
    }
  }}
  required
  placeholder="Enter your email address"
  className={`${emailStyles} pl-10 w-full px-3 py-2 border rounded-lg focus:ring-blue-500 focus:border-blue-500 `}
/>
       </div>
       {/* {invalidDomainError && (
  <DomainNameTooltip content={showDomainNameTooltip} styles={{}} />
)} */}
       <div>
         <label htmlFor="email" className="block text-sm font-medium text-gray-700 mt-2">
           Domain 
         </label>
         <div className="relative  mt-3">
                  <MdBusinessCenter
                    className="text-gray-500 absolute top-1/2 transform -translate-y-1/2 left-3"
                    style={{  fontSize: "20px" }}
                  />
         <input
           id="domainName"
           name="domainName"
           type="text"
           autoComplete='off'

           required
           onChange={handleDomainNameChange}
           onFocus={()=> setinvalidDomainError(false)}

           placeholder="Enter your domain name"
           onBlur={() => {
            setinvalidDomainError(true)
            if (formData.domainName.trim()) { // Ensuring the email is not just spaces
              setdomainStyles("bg-blue-100 text-blue-600 border-blue-600");
            } else {
              setdomainStyles("");
            }
          }}
           className={`${domainStyles} pl-10 w-full px-3 py-2 border rounded-lg focus:ring-blue-500 focus:border-blue-500`}
         />
         </div>
         </div>
       </div>
       <button
        //  type="submit"
         className={`transition transform active:scale-95 w-full ${formData.email.includes('@') && formData.email.includes('.') && formData.domainName.length>=3? 'bg-[#25caed] cursor-pointer': 'bg-[#93c5fd] cursor-not-allowed'}  text-white font-bold py-2 rounded-lg transition`}
         onClick={handleChangePassword}
        //  disabled={
        //   !(formData.email.includes('@') && 
        //     formData.email.includes('.') && 
        //     formData.domainName.length >= 3)
        // }
       >
         Submit
       </button>
       
       <div className="text-left " onClick={handleForgotCancelClick}>
              <a href="#" className="text-blue-500 text-sm hover:underline">
              Cancel
              </a>
            </div>
     </div>

     <div className="flex items-center mt-3">
       {/* <input
         type="checkbox"
         id="remember"
         className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
       />
       <label htmlFor="remember" className="ml-2 block text-sm text-gray-700">
         Remember me
       </label> */}
     </div>

     <div className="mt-[169px] text-center pt-[12px] ">
          <div className="flex items-center text-[#26a9e1] text-sm mb-1">
      <div className="flex-grow border-t border-[#26a9e1]"></div>
      <span className="px-2 font-bold">Follow Us</span>
      <div className="flex-grow border-t border-[#26a9e1]"></div>
    </div>
          
    <div className="flex justify-center space-x-1 mt-[4px]">
  <a
    href="#"
    className="text-[#26a9e1] hover:bg-blue-100 w-10 h-10 flex items-center justify-center rounded-full"
  >
    <i className="fab fa-facebook-f  text-md"></i>
  </a>
  <a
    href="#"
    className="text-[#26a9e1] hover:bg-blue-100 w-10 h-10 flex items-center justify-center rounded-full"
  >
    <i className="fab fa-instagram  text-md"></i>
  </a>
  <a
    href="#"
    className="text-[#26a9e1] hover:bg-blue-100 w-10 h-10 flex items-center justify-center rounded-full"
  >
    <i className="fab fa-twitter  text-md"></i>
  </a>
  <a
    href="#"
    className="text-[#26a9e1] hover:bg-blue-100 w-10 h-10 flex items-center justify-center rounded-full"
  >
    <i className="fab fa-linkedin-in  text-md"></i>
  </a>
</div>
        </div>

   </div>
 </div>
</div>
</>            
  )
};

export default ForgotPassword;


import React from "react";

import { useState, useEffect } from "react";
import axios from "axios";
import { addInvoice } from "../../actions/invoiceActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DatePicker, Space } from "antd";
import dayjs from "dayjs";
import config from "../../config/config";
import FullScreenLoader from "../pages/FullScreenLoader";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import { useMemo } from "react";
import CustomCommonTab from "../reusable/CustomCommonTab";
import CustomNavigation from "../reusable/CustomNavigation";
import { FaHome } from "react-icons/fa";
import { NotebookIcon, NotebookPenIcon } from "lucide-react";
import {
  EditNoteSharp,
  NoteAdd,
  NoteAddRounded,
  NoteOutlined,
  NotesSharp
} from "@mui/icons-material";
import NoteOutlinedIcon from "@mui/icons-material/NoteOutlined";

const InvoiceFormTwo = () => {
  const dispatch = useDispatch();
  const [isTermsExpanded, setTermsExpanded] = useState(false);
  const [expandedTaxRow, setExpandedTaxRow] = useState(null);
  const toggleTaxRow = (productId) => {
    // If the same row is clicked again, close it
    if (expandedTaxRow === productId) {
      setExpandedTaxRow(null);
    } else {
      setExpandedTaxRow(productId);
    }
  };
  // const handlePOChange = (e) => {
  //   const selectedPO = e.target.value;
  //   setBrandInvoiceNumber(selectedPO);
  //   setMinLengthError("");
  //   setQuantityErrors({});
    
  //   // Reset all financial values when PO is deselected or changed
  //   if (selectedPO === "" || selectedPO === "--Select PO--") {
  //     setInvoiceFuelData([]);
  //     setTaxDataForInvoice([]); 
  //     setProductSubTotal(0);
  //     setfreightCharges(0);
  //     setfreightsurcharge(0);
  //     setDiscount(0);
  //     setRebates(0);
  //     setwaterBill(0);
      
  //     // Reset InvoiceCustomerData quantities to zero
  //     const resetData = InvoiceCustomerData.map(item => ({
  //       ...item,
  //       quantity: 0,
  //       total: 0
  //     }));
  //     setInvoiceCustomerData(resetData);
  //   }
  // };
  
  const handlePOChange = (e) => {
    const selectedPO = e.target.value;
    setBrandInvoiceNumber(selectedPO);
    setMinLengthError("");
    setQuantityErrors({});
    
    // Reset all financial values when PO is deselected or changed
    setInvoiceFuelData([]);
    setTaxDataForInvoice([]);
    setProductSubTotal(0);
    setfreightCharges(0);
    setfreightsurcharge(0);
    setDiscount(0);
    setRebates(0);
    setwaterBill(0);
    
    // Reset InvoiceCustomerData quantities to zero (this is the key fix)
    const resetData = InvoiceCustomerData.map(item => ({
      ...item,
      quantity: 0,
      total: 0
    }));
    setInvoiceCustomerData(resetData);
    setCreatedOnDate(null);
    setInvoiceDate(null);
    setDueDate(null);
    setSelectedTerm("");
  };


  const disableAllDates = (current) => {
    return (
      current &&
      (current < dayjs().startOf("day") || current > dayjs().endOf("day"))
    );
  };
  const disablePastDates = (current) => {
    return current && current < dayjs().startOf("day"); // Disable past dates
  };

  const baseURL =
    process.env.NODE_ENV === "production"
      ? config.production.baseURL
      : config.development.baseURL;
  const [showDescription, setShowDescription] = useState(false);
  const [quantityErrors, setQuantityErrors] = React.useState({});

  const [discount, setDiscount] = useState(0);
  const [waterBill, setwaterBill] = useState(0);

  const [Rebates, setRebates] = useState(0);
  const [footer, setFooter] = useState("");

  const [freightsurcharge, setfreightsurcharge] = useState(0);
  const [freightCharges, setfreightCharges] = useState(0);
  const [brandInvoiceNumber, setBrandInvoiceNumber] = useState("");
  const [invoiceFuelData, setInvoiceFuelData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const toggleInput = () => {
    setIsOpen((prev) => !prev);
  };
  const [invoicePurchaseDate, setInvoicePurchaseDate] = useState("");

  console.log(invoiceFuelData, "invoicefueldata");
  const [InvoiceCustomerData, setInvoiceCustomerData] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(""); // Selected product
  const [selectedDiscountType, setSelectedDiscountType] = useState(""); // Type of discount (amount, %, gallons)

  const [productSubTotal, setProductSubTotal] = useState(0);

  const [loading, setLoading] = useState(true);

  const [DiscountRebateError, setDiscountRebateError] = useState("");

  const [latestPurchaseInvoices, setLatestPurchaseInvoices] = useState(null);

  const [customerIdInvoice, setCustomerIdInvoice] = useState("");

  const today = new Date().toISOString().split("T")[0];
  const [sellerNames, setSellerNames] = useState([]);

  const [tax, setTax] = useState("");
  const [feesOrDiscounts, setFeesOrDiscounts] = useState("");

  const [termsAndConditions, setTermsAndConditions] = useState("");

  const [latestInvoiceNumber, setLatestInvoiceNumber] = useState("");

  // const [dueDate, setDueDate] = useState("");

  // const [createdDate, setCreatedOnDate] = useState("");
  // const [invoiceDate, setInvoiceDate] = useState(dayjs());

  const businessId = localStorage.getItem("business_id");

  const [customerOptions, setCustomerOptions] = useState([]);

  const navigate = useNavigate();
  const [imageResponse, setimageResponse] = useState([]);
  useEffect(() => {
    axios
      .get(
        `${baseURL}/${btoa("business/profile")}/${btoa(business_id)}/${btoa(
          user_id
        )}/${btoa(user_role)}`
      )
      .then((response) => {
        console.log(response);
        // console.log("response businesstype", response);
        setimageResponse(response.data.business_data[0]);
      })
      .catch((error) => {
        console.error("Error fetching business data:", error);
      });
    console.log(imageResponse);
  }, []);
  // console.log("new total is ", newTotal);

  const [products, setProducts] = useState([
    {
      product: "",
      productCode: "",
      quantity: "",
      unitPrice: "",
      total: 0,
      editableDescription: false
    }
  ]);

  // console.log("products", products);
  const [isOpen1, setIsOpen1] = useState(false);
  const toggleInput1 = () => {
    setIsOpen1((prev) => !prev);
  };
  const [termsAndConditions1, setTermsAndConditions1] = useState("");
  const [formField, setFormField] = useState({
    invoice_number: "",
    bill_from: "",
    bill_to: "",
    vehicleNumber: "",
    sellersId: "",
    originTerminal: "",
    destinationTerminal: "",
    created_on: today,
    due_on: today,
    invoiceDate: today
  });

  const isFormComplete = () => {
    // Check if customer information is filled
    const isCustomerInfoComplete =
      formField.bill_to && createdDate && dueDate && invoiceDate;

    const areProductsComplete =
      InvoiceCustomerData.length > 0 &&
      InvoiceCustomerData.some(
        (product) =>
          !product.isCustomRow && // Exclude custom rows
          product.quantity > 0
      );

    const isPOSelected = Boolean(brandInvoiceNumber);

    return isCustomerInfoComplete && areProductsComplete && isPOSelected;
  };
  const existingRows = InvoiceCustomerData.filter((row) => !row.isAddedRow);
  const totalQuantity = existingRows.reduce((acc, product) => {
    if (product.product_name !== "" && product.quantity !== 0) {
      console.log(product, products, "item checking");
      return acc + parseFloat(product.quantity || 0);
    }
    return acc; // If the condition is not met, return the current accumulator
  }, 0); // Initial accumulator value
  console.log(totalQuantity, InvoiceCustomerData, "total quantity");
  const subTotal = InvoiceCustomerData.filter((product) => !product.isCustomRow) // Include only rows where iscustom is false
    .reduce((acc, product) => acc + parseFloat(product.total || 0), 0);
  //  console.log(subTotal, 'subtotal checking')
  const grandTotal = parseFloat(productSubTotal);
  const [customerIndividualData, setCustomerIndividualData] =
    useState(grandTotal);
  console.log("customerIndividualData", customerIndividualData);
  const handleChange = (event) => {
    setfreightCharges(0);
    setfreightsurcharge(0);
    setDiscount(0);
    setProductSubTotal(0);

    setLatestPurchaseInvoices(null);
    setInvoiceFuelData([]); // Reset immediately
    setQuantityErrors({}); // Reset quantity errors
    setExpandedTaxRow(null); // Reset expanded rows
    setBrandInvoiceNumber("");
    setRemainingProducts(availableProducts);
    setCustomerIdInvoice(event.target.value);
    setInvoiceCustomerData([]);

    console.log(event.target.value);
    if (event instanceof Date) {
      // setCreatedOnDate(event);
    } else {
      setInvoiceFuelData([]);

      const { name, value } = event.target;
      setFormField({
        ...formField,
        [name]: value
      });
    }
  };

  useEffect(() => {
    const fetchLatestInvoiceNumber = async () => {
      try {
        const response = await axios.get(
          `${baseURL}${btoa("invoice/latest_invoice_number")}/${btoa(
            businessId
          )}`
        );
        const latestInvoiceNumber = response.data;
        // console.log("latestInvoiceNumber", latestInvoiceNumber);
        setLatestInvoiceNumber(latestInvoiceNumber);
        // setFormField((prev) => ({ ...prev, invoice_number: latestInvoiceNumber }));
      } catch (error) {
        console.error("Error fetching latest invoice number:", error);
      }
    };
    fetchLatestInvoiceNumber();
  }, []);
  const organizeTaxesByFuelType = () => {
    const availableFuelTypes = [];

    invoiceFuelData.forEach((fuelItem) => {
      const fuelType = fuelItem.fuelgrade_name.toLowerCase();
      const fuelQuantity =
        InvoiceCustomerData.find(
          (row) => row.product_id === fuelItem.fuelgrade_id
        )?.quantity || 0;

      if (fuelQuantity > 0) {
        availableFuelTypes.push(fuelType);
      }
    });

    const fuelTypeIndices = {};
    availableFuelTypes.forEach((fuelType, index) => {
      fuelTypeIndices[fuelType] = index;
    });

    // Initialize objects for each fuel type with its assigned index
    const fuelTypeTaxes = {};
    availableFuelTypes.forEach((fuelType) => {
      fuelTypeTaxes[fuelType] = { index: fuelTypeIndices[fuelType] };
    });

    // Process taxes for each fuel type
    invoiceFuelData.forEach((fuelItem) => {
      const fuelType = fuelItem.fuelgrade_name.toLowerCase();
      const fuelQuantity =
        InvoiceCustomerData.find(
          (row) => row.product_id === fuelItem.fuelgrade_id
        )?.quantity || 0;

      if (fuelQuantity > 0) {
        // Filter taxes applicable to this fuel type
        const fuelTaxes = TaxDataForInvoice.filter((tax) => {
          const taxName = tax.po_customertax_name.toLowerCase();

          // Match diesel taxes with diesel fuel
          if (taxName.includes("diesel") && fuelType === "diesel") {
            return true;
          }

          // Match gasoline taxes with non-diesel fuels
          if (
            (taxName.includes("gasoline") || !taxName.includes("diesel")) &&
            fuelType !== "diesel"
          ) {
            return true;
          }

          return false;
        });

        // Process taxes for this fuel type
        fuelTaxes.forEach((tax) => {
          const taxKey = tax.po_customertax_name;
          const taxAmount = tax[tax.po_customertax_name] * fuelQuantity;

          // Add to the corresponding fuel type object
          fuelTypeTaxes[fuelType][taxKey] = taxAmount;
        });
      }
    });

    return { fuelTypeTaxes, fuelTypeIndices };
  };
  const [freightdata, setfreightdata] = useState([]);
  const calculateSalesTaxAmount = (
    invoiceFuelData,
    invoiceCustomerData,
    taxDataForInvoice
  ) => {
    let totalTaxAmount = 0;

    // Process each fuel type for taxes
    invoiceFuelData.forEach((fuelItem) => {
      const fuelType = fuelItem.fuelgrade_name.toLowerCase();
      const fuelQuantity =
        invoiceCustomerData.find(
          (row) => row.product_id === fuelItem.fuelgrade_id
        )?.quantity || 0;

      if (fuelQuantity > 0) {
        // Get applicable taxes for this fuel type
        const fuelTaxes = taxDataForInvoice.filter((tax) => {
          const taxName = tax.po_customertax_name.toLowerCase();

          if (taxName.includes("diesel") && fuelType === "diesel") {
            return true;
          }

          if (
            (taxName.includes("gasoline") || !taxName.includes("diesel")) &&
            fuelType !== "diesel"
          ) {
            return true;
          }

          return false;
        });

        // Add this fuel's tax total to the grand total
        fuelTaxes.forEach((tax) => {
          totalTaxAmount += tax[tax.po_customertax_name] * fuelQuantity;
        });
      }
    });

    return totalTaxAmount;
  };
  const calculateProductTotalWithTaxes = (productId, quantity) => {
    if (!quantity || quantity <= 0) return 0;

    // Find the product row data
    const productRow = InvoiceCustomerData.find(
      (row) => row.product_id === productId
    );
    if (!productRow) return 0;

    // Get base product total
    const baseTotal = parseFloat(productRow.total) || 0;

    // Find the fuel item for this product
    const fuelItem = invoiceFuelData.find(
      (fuel) => fuel.fuelgrade_id === productId
    );
    if (!fuelItem) return baseTotal; // If no fuel item found, return just the base total

    const fuelType = fuelItem.fuelgrade_name.toLowerCase();

    // Calculate applicable taxes for this product
    let taxTotal = 0;
    TaxDataForInvoice.forEach((tax) => {
      if (!tax || !tax.po_customertax_name) return;

      const taxName = tax.po_customertax_name.toLowerCase();
      let isApplicable = false;

      // Check if tax applies to this fuel type
      if (taxName.includes("diesel") && fuelType === "diesel") {
        isApplicable = true;
      } else if (
        (taxName.includes("gasoline") || !taxName.includes("diesel")) &&
        fuelType !== "diesel"
      ) {
        isApplicable = true;
      }

      if (isApplicable) {
        const taxRate = parseFloat(tax[tax.po_customertax_name]) || 0;
        taxTotal += taxRate * quantity;
      }
    });

    // Add freight charges
    let freightChargeTotal = 0;
    if (freightdata && freightdata.freight_charge) {
      freightChargeTotal += freightdata.freight_charge * quantity;
    }

    // Add freight surcharge
    let freightSurchargeTotal = 0;
    if (freightdata && freightdata.freight_surcharge) {
      freightSurchargeTotal += freightdata.freight_surcharge * quantity;
    }

    // Return the total with taxes and freight
    return baseTotal + taxTotal + freightChargeTotal + freightSurchargeTotal;
  };
  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const formData = new FormData();

      // Get taxes organized by fuel type
      const { fuelTypeTaxes, fuelTypeIndices } = organizeTaxesByFuelType();
      console.log("Fuel Type Indices:", fuelTypeIndices);
      console.log("Fuel Type Taxes:", fuelTypeTaxes);

      // Define all possible tax keys
      const allTaxKeys = [
        "federal_excise_tax",
        "federal_oil_spil",
        "nys_prepaid",
        "nys_excise",
        "nys_petroleum_bus_rcvry",
        "nys_petroleum_testing_fee",
        "nys_spill_recovery",
        "nys_prepaid_gasoline",
        "nys_prepaid_diesel",
        "ny_pbt_gasoline",
        "ny_pbt_diesel",
        "ny_oilspill_surcharge",
        "ny_motor_fuel_testing_fee",
        "ny_motor_fuel_excise_tax",
        "ny_diesel_fuel_tax",
        "federal_oil_spill_tax_cost_recovery_eten",
        "federal_oil_spill_tax_cost_recovery_diesel",
        "federal_gasoline_tax",
        "federal_diesel_tax",
        "fedhaz_subsup_recoveryfee_eten",
        "fedhaz_subsup_recoveryfee_diesel"
      ];

      // Add each tax value to formData with dynamic indices
      allTaxKeys.forEach((taxKey) => {
        // For each fuel type, add the tax if it exists, otherwise add null
        Object.entries(fuelTypeTaxes).forEach(([fuelType, taxData]) => {
          const index = taxData.index; // Get the dynamically assigned index
          const taxValue = taxData[taxKey];

          if (taxValue !== undefined) {
            formData.append(`${taxKey}[${index}]`, taxValue.toFixed(5));
          } else {
            formData.append(`${taxKey}[${index}]`, "null");
          }
        });
      });

      const invoiceAmount = calculateInvoiceAmount();
      formData.append("finalInvoiceAmount", invoiceAmount.toFixed(2));

      formData.append("businessId", localStorage.getItem("business_id"));
      formData.append("managerId", localStorage.getItem("user_id"));
      formData.append("userRole", user_role);

      formData.append("managerId", localStorage.getItem("user_id"));

      formData.append("billFromName", formField.bill_from);
      formData.append("customerId", formField.bill_to);
      formData.append("deliveryDate", dayjs(createdDate).format("YYYY-MM-DD"));
      formData.append("invoiceDate", dayjs(invoiceDate).format("YYYY-MM-DD"));

      formData.append("dueDate", dayjs(dueDate).format("YYYY-MM-DD"));

      formData.append("invoiceNumber", latestInvoiceNumber);
      formData.append("subTotalAmount", productSubTotal);
      formData.append("finalTotalAmount", productSubTotal + taxTotal1);
      formData.append("discountType", selectedDiscountType);

      formData.append("discountsAmount", discount);

      let freightIndex = 0;
      InvoiceCustomerData.forEach((row, index) => {
        if (parseFloat(row.quantity) > 0) {
          const freightChargeValue =
            parseFloat(freightdata.freight_charge) || 0;
          const productFreightCharge =
            freightChargeValue * parseFloat(row.quantity);
          formData.append(
            `freightCharge[${freightIndex}]`,
            productFreightCharge.toFixed(2)
          );
          formData.append(
            `freightChargePerc[${freightIndex}]`,
            freightChargeValue.toFixed(5)
          );
          freightIndex++;
        }
      });

      let freightIndex1 = 0;
      InvoiceCustomerData.forEach((row, index) => {
        if (parseFloat(row.quantity) > 0) {
          const freightSurchargeValue =
            parseFloat(freightdata.freight_surcharge) || 0;
          const productFreightCharge =
            freightSurchargeValue * parseFloat(row.quantity);
          formData.append(
            `freightSurcharge[${freightIndex1}]`,
            productFreightCharge.toFixed(2)
          );
          formData.append(
            `freightSurchargePerc[${freightIndex1}]`,
            freightSurchargeValue.toFixed(5)
          );
          freightIndex1++;
        }
      });

      const totalFreightCharge = InvoiceCustomerData.filter(
        (row) => !row.isCustomRow && parseFloat(row.quantity) > 0
      ).reduce(
        (acc, row) =>
          acc + customerIndividualData.freigt_charge * parseFloat(row.quantity),
        0
      );
      formData.append("totalFreightCharge", totalFreightCharge.toFixed(2));

      formData.append(
        "salesTaxAmount",
        parseFloat(
          calculateSalesTaxAmount(
            invoiceFuelData,
            InvoiceCustomerData,
            TaxDataForInvoice
          )
        ).toLocaleString(undefined, {
          minimumFractionDigits: 5,
          maximumFractionDigits: 5
        })
      );
      formData.append("termsCondition", termsAndConditions);
      formData.append("footer", footer);

      formData.append("purchasedInvoicePO", brandInvoiceNumber);

      //  let validIndex = 0;
      const addedRows = InvoiceCustomerData.filter((row) => row.isAddedRow);
      const existingRows = InvoiceCustomerData.filter((row) => !row.isAddedRow);

      // Log or send added rows and other rows separately
      console.log("Added Rows:", addedRows);
      console.log("Existing Rows:", existingRows);

      addedRows.forEach((row) => {
        console.log(row, "backend checking");
        if (row.isCustomRow && selectedDiscountType === "gallons") {
          formData.append("discountsGallons", row.quantity);
          formData.append("discountsPrice", row.unitPrice);
        }
        if (row.product_name === "Freight Charges") {
          formData.append(`freightDescription`, row.description);
        } else if (row.product_name === "Freight Surcharge") {
          formData.append(`freightSurchargeDescription`, row.description);
        } else if (row.product_name === "Water Bill") {
          formData.append(`waterBillDescription`, row.description);
        } else if (row.product_name === "Rebates") {
          formData.append(`rebatesCreditsDescription`, row.description);
        }
      });

      let validIndex = 0;

      existingRows.forEach((row, index) => {
        if (row.total > 0) {
          if (row.isCustomRow && selectedDiscountType === "gallons") {
            formData.append("discountsGallons", row.quantity);
            formData.append("discountsPrice", row.unitPrice);
          } else {
            formData.append(`productId[${validIndex}]`, row.product_id);
            formData.append(`quantities[${validIndex}]`, row.quantity);
            formData.append(`unitPrices[${validIndex}]`, row.unitPrice);
            formData.append(`unitTotals[${validIndex}]`, row.total);

            const totalWithTaxes = calculateProductTotalWithTaxes(
              row.product_id,
              parseFloat(row.quantity)
            );
            formData.append(
              `unitTotalsWithTaxCharges[${validIndex}]`,
              totalWithTaxes.toFixed(2)
            );
            validIndex++;
          }
        }
      });

      console.log("form data: ", formData);

      dispatch(addInvoice(formData));

      navigate("/invoice"); // Use navigate function to redirect
    } catch (error) {
      console.log(error, "invoice creation error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    async function fetchCustomerOptions() {
      try {
        const response = await axios.get(
          `${baseURL}/${btoa("customer/view")}/${btoa(businessId)}`
        );
        setCustomerOptions(response.data.customers_data);
        console.log("customer options", response.data.customers_data);
      } catch (error) {
        console.error("Error fetching customer options:", error);
      }
    }
    fetchCustomerOptions();
  }, []);

  useEffect(() => {}, []);

  const business_id = localStorage.getItem("business_id");
  const user_id = localStorage.getItem("user_id");
  const user_role = localStorage.getItem("user_role");

  useEffect(() => {
    const fetchCustomerTermsAndConditions = async () => {
      try {
        const response = await axios.get(
          `${baseURL}${btoa("invoice/fetchtermsconditions")}/${btoa(
            formField.bill_to
          )}/${btoa(businessId)}`
        );
        // console.log("tandc",response , );
        if (response.data.termsconditions_data?.length === 0) {
          setTermsAndConditions("");
        }
        setTermsAndConditions(
          response.data.termsconditions_data[0].terms_conditions
        );
      } catch (error) {
        console.error(
          "Error fetching customer terms and conditions in create invoice",
          error
        );
      }
    };
    fetchCustomerTermsAndConditions();
  }, [formField.bill_to]);

  useEffect(() => {
    axios
      .get(
        `${baseURL}${btoa("business/profile")}/${btoa(business_id)}/${btoa(
          user_id
        )}/${btoa(user_role)}`
      )
      .then((response) => {
        // console.log(response);
        const businessData = response.data.business_data[0];
        // console.log(businessData);
        // setCustomerDetails(businessData);
        setFormField({
          ...formField,
          bill_from: businessData.business_name
          // Other fields as needed
        });
        // setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching business data:", error);
        // setLoading(false);
      });
  }, []);

  const handleProductChange = (index, field, value) => {
    setMinLengthError("");
    const updatedData = [...InvoiceCustomerData];

    updatedData[index][field] = value;
    const quantityInput = document.querySelector(`#quantity-input-${index}`);

    if (quantityInput && quantityInput.disabled) {
      setInvoiceCustomerData(updatedData);
      return;
    }

    if (field === "description") {
      setInvoiceCustomerData(updatedData);
      return;
    }

    const quantity = isNaN(parseFloat(updatedData[index]?.quantity))
      ? 0
      : parseFloat(updatedData[index]?.quantity);
    const unitPrice =
      isNaN(parseFloat(updatedData[index]?.unitPrice)) ||
      parseFloat(updatedData[index]?.unitPrice) === ""
        ? 0.0
        : parseFloat(updatedData[index]?.unitPrice);

    const total = isNaN(parseFloat(quantity * unitPrice))
      ? 0
      : parseFloat(quantity * unitPrice);

    updatedData[index].total = total;

    // Calculate the product sub-total (exclude invalid values)
    const productSubTotal = updatedData
      .filter((item) => !item.isCustomRow)
      .reduce(
        (acc, item) => acc + (isNaN(item.total) ? 0 : Number(item.total)),
        0
      );

    setProductSubTotal(Number(productSubTotal));
    // Recalculate the newSubtotal with validation
    const newSubtotal = updatedData.reduce(
      (acc, item) => acc + (isNaN(item.total) ? 0 : item.total),
      0
    );

    updatedData.forEach((item) => {
      if (item.product_name === "Discount") {
        if (selectedDiscountType === "amount") {
          item.total = item.unitPrice; // Fixed amount
          setDiscount(Number(item.total));
        } else if (selectedDiscountType === "percentage") {
          const discountAmount = item.unitPrice; // Fixed amount entered
          const percentage = productSubTotal
            ? (discountAmount / productSubTotal) * 100
            : 0;
          const formattedPercentage =
            percentage % 1 === 0
              ? percentage.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })
              : percentage.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                }); // Apply toFixed(2) only if it's a decimal

          setDiscountedPercentage(formattedPercentage); // Set the percentage valsetue
          item.total = discountAmount; // Update total for discount
          setDiscount(Number(item.total));
        } else if (selectedDiscountType === "gallons") {
          item.total = item.quantity * item.unitPrice; // Gallons
          setDiscount(Number(item.total));
        }
      }
    });

    if (updatedData[index]?.product_name === "Freight Charges") {
      let freightcharges = updatedData[index]?.unitPrice;
      setfreightCharges(Number(freightcharges));
      updatedData[index].total = freightcharges;
    }

    if (updatedData[index]?.product_name === "Freight Surcharge") {
      let freightsurcharge = updatedData[index]?.unitPrice;
      setfreightsurcharge(Number(freightsurcharge));
      updatedData[index].total = freightsurcharge;
    }

    if (updatedData[index]?.product_name === "Water Bill") {
      let waterbill = updatedData[index]?.unitPrice;
      setwaterBill(Number(waterbill));
      updatedData[index].total = waterbill;
    }
    for (let i = 1; i <= 14; i++) {
      if (updatedData[index]?.product_name === `credit${i}`) {
        let creditValue = updatedData[index]?.unitPrice;
        eval(`setCredit${i}`)(creditValue); // Using eval to dynamically set state
        updatedData[index].total = creditValue;
      }
    }

    if (updatedData[index]?.product_name === "Rebates") {
      let Rebates = updatedData[index]?.unitPrice;
      setRebates(Rebates);
      updatedData[index].total = Rebates;
    }

    // Update the state
    setInvoiceCustomerData(updatedData);

    // Debugging log
    console.log("Updated Row:", {
      productName: updatedData[index]?.product_name || "N/A",
      quantity,
      unitPrice,
      total
    });

    const newTotal = newSubtotal + newSubtotal * (tax / 100) + feesOrDiscounts;

    setProducts(updatedData);
  };
  const customerId = customerIdInvoice;
  useEffect(() => {
    const encodedId = btoa(customerId);
    const businessId = localStorage.getItem("business_id");

    axios
      .get(`${baseURL}Y3VzdG9tZXIvZWRpdA==/${encodedId}/${btoa(businessId)}`)

      .then((response) => {
        const fuelGradedata = response.data.customersFuelsData;
        const customerIndividualData = response.data.customers_data[0];
        const freightdata = response.data.customerChargesData[0];
        setfreightdata(freightdata);
        setCustomerIndividualData(customerIndividualData);

        setLatestPurchaseInvoices(response.data.purchasedInvoiceNumbers);
        // setTaxNamesForInvoice(response.data.customersTaxes)
        console.log(fuelGradedata, "");
        setInvoiceCustomerData(fuelGradedata);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching customer data:", error);
        setLoading(false);
      });
  }, [customerId, brandInvoiceNumber]);
  useEffect(() => {
    const fetchLatestPurchaseInvoices = async () => {
      try {
        const response = await axios.get(
          `${baseURL}${btoa(
            "purchasedinvoice/fetchPurchasedInvoiceNumber"
          )}/${btoa(businessId)}`
        );
        console.log(response, "response new ......");
      } catch (error) {
        console.error("Error fetching latest purchase invoices:", error);
      }
    };
    fetchLatestPurchaseInvoices();
  }, [brandInvoiceNumber]);
  const [TaxDataForInvoice, setTaxDataForInvoice] = useState([]);

  const [DiscountedPercentage, setDiscountedPercentage] = useState("");
  useEffect(() => {
    setDiscountedPercentage("");
  }, []);

  useEffect(() => {
    const fetchLatestPurchaseInvoicedata = async () => {
      if (!brandInvoiceNumber || brandInvoiceNumber === "--Select PO--") {
        setInvoiceFuelData([]);
        setInvoicePurchaseDate("");
        setTaxDataForInvoice([]);
        return;
      }
      try {
        const response = await axios.get(
          `${baseURL}${btoa("purchasedinvoice/fetchpurchasedinvoice")}/${btoa(
            brandInvoiceNumber
          )}/${btoa(businessId)}/${btoa(customerId)}`
        );
        console.log(response, "response 3 boxes ......");
        setInvoiceFuelData(response.data.purchasedInvoiceData[0].fuelgrades);

        setInvoicePurchaseDate(
          response.data.purchasedInvoiceData[0].brand_invoice_date
        );

        setTaxDataForInvoice(response.data.customersTaxesAndValues);
      } catch (error) {
        console.error("Error fetching latest purchase invoices:", error);
      }
    };
    fetchLatestPurchaseInvoicedata();
  }, [brandInvoiceNumber, businessId, customerId, baseURL]);

  //   for business logo fetching

  const [businessData, setBusinessData] = useState([]);
  console.log("TaxNamesForInvoice", TaxDataForInvoice);
  const taxTotal1 = TaxDataForInvoice.reduce((acc, item) => {
    // Access the tax value using the dynamic key from po_customertax_name
    const taxValue =
      parseFloat(item[item.po_customertax_name] * totalQuantity) || 0;
    return acc + taxValue; // Accumulate the tax value
  }, 0);

  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get(
        `${baseURL}${btoa("business/profile")}/${btoa(business_id)}/${btoa(
          user_id
        )}/${btoa(user_role)}`
      )
      .then((response) => {
        setBusinessData(response.data.business_data[0].image_file);
      })
      .catch((error) => {
        console.error("Error fetching business data:", error);
      });
  }, []);
  const [MinLengthError, setMinLengthError] = useState("");
  const handleDelete = (productId, e) => {
    console.log("InvoiceCustomerData", InvoiceCustomerData, productId);
    setSelectedProduct("");

    e.preventDefault();

    // Ensure that we don't delete the last item
    if (InvoiceCustomerData.length === 1) {
      setMinLengthError("You cannot delete the last product.");
      return; // Exit the function to prevent deletion
    }

    // Find the product to be deleted
    const deletedProduct = InvoiceCustomerData.find(
      (row) => row.product_name === productId
    );

    // If the product to delete exists, proceed
    if (deletedProduct) {
      const updatedData = InvoiceCustomerData.filter(
        (row) => row.product_name !== productId
      );

      // Set the updated data back to the state
      setInvoiceCustomerData(updatedData);

      // Recalculate product subTotal after deletion

      const productSubTotal = updatedData
        .filter((row) => !row.isCustomRow) // Exclude "Discount" from the subtotal
        .reduce(
          (acc, row) => acc + (isNaN(row.total) ? 0 : Number(row.total)),
          0
        );
      setProductSubTotal(productSubTotal);

      if (deletedProduct?.isCustomRow) {
        setRemainingProducts((prev) => {
          let updatedProducts = [...prev];

          if (
            deletedProduct.product_name.startsWith("credit") &&
            !prev.some((product) => product === "Credits")
          ) {
            updatedProducts.push("Credits");
          } else if (
            !deletedProduct.product_name.toLowerCase().startsWith("credit")
          ) {
            updatedProducts.push(deletedProduct.product_name);
          }

          // Sort the dropdown based on the availableProducts order
          return updatedProducts.sort(
            (a, b) =>
              availableProducts.indexOf(a) - availableProducts.indexOf(b)
          );
        });
      }
      const hasCustomRows = updatedData.some((row) => row.isCustomRow);
      setShowDescription(hasCustomRows);

      if (deletedProduct?.product_name === "Discount") {
        setDiscount(0);
      }
      if (deletedProduct?.product_name === "Freight Charges") {
        setfreightCharges(0);
      }
      if (deletedProduct?.product_name === "Freight Surcharge") {
        setfreightsurcharge(0);
      }
      if (deletedProduct?.product_name === "Water Bill") {
        setwaterBill(0);
      }
      if (deletedProduct?.product_name === "Rebates") {
        setRebates(0);
      }
      for (let i = 1; i <= 14; i++) {
        if (deletedProduct?.product_name === `credit${i}`) {
          eval(`setCredit${i}`)(0);
        }
      }
    }
  };

  const [memoNote, setMemoNote] = useState("");
  const [isMemoExpanded, setMemoExpanded] = useState(false);

  const formatNumberWithCommas = (value) => {
    if (!value || isNaN(value)) return ""; // Handle invalid or empty values
    return parseFloat(value).toLocaleString("en-US");
  };

  const disablefutureDates = (current) => {
    return current && current > dayjs().startOf("day");
  };

  const availableProducts = [
    "Freight Charges",
    "Freight Surcharge",
    "Water Bill",
    "Discount",
    "Rebates",
    "Miscellaneous",
    "Credits"
  ];

  const [remainingProducts, setRemainingProducts] = useState(availableProducts);
  useEffect(() => {
    setRemainingProducts(availableProducts);
  }, []);
  const date = new Date(invoicePurchaseDate);
  const formattedDate = `${String(date.getMonth() + 1).padStart(
    2,
    "0"
  )}/${String(date.getDate()).padStart(2, "0")}/${date.getFullYear()}`;

  const [activeItem, setActiveItem] = useState("View");

  const handleMenuClick = (item) => {
    setActiveItem(item);
  };

  console.log(InvoiceCustomerData, "memoizedInvoiceData");

  const [selectedTerm, setSelectedTerm] = useState("");

  const handlePaymentTermChange = (e) => {
    const term = e.target.value;
    setSelectedTerm(term);
    calculateDueDate(invoiceDate, term);
  };
  const calculateDueDate = (invoiceDate, term) => {
    if (!invoiceDate) {
      setDueDate(null);
      return;
    }

    let daysToAdd = 0;
    if (term === "Net 7") daysToAdd = 7;
    else if (term === "Net 10") daysToAdd = 10;
    else if (term === "Net 30") daysToAdd = 30;

    setDueDate(dayjs(invoiceDate).add(daysToAdd, "day"));
  };

  const menuItems = [{ name: "Create Invoice " }];
  // Calculate freight charges for all products
  const calculateFreightCharges = () => {
    if (!Array.isArray(InvoiceCustomerData) || !freightdata) {
      return 0;
    }

    let totalFreightAmount = 0;

    InvoiceCustomerData.filter((item) => !item.isCustomRow).forEach(
      (product) => {
        const quantity = parseFloat(product.quantity) || 0;

        if (quantity > 0 && freightdata.freight_charge) {
          totalFreightAmount += freightdata.freight_charge * quantity;
        }
      }
    );

    return formatCurrency(totalFreightAmount);
  };

  // Calculate freight surcharge for all products
  const calculateFreightSurcharge = () => {
    if (!Array.isArray(InvoiceCustomerData) || !freightdata) {
      return 0;
    }

    let totalSurchargeAmount = 0;

    InvoiceCustomerData.filter((item) => !item.isCustomRow).forEach(
      (product) => {
        const quantity = parseFloat(product.quantity) || 0;

        if (quantity > 0 && freightdata.freight_surcharge) {
          totalSurchargeAmount += freightdata.freight_surcharge * quantity;
        }
      }
    );

    return formatCurrency(totalSurchargeAmount);
  };

  // Calculate total taxes for the invoice
  const calculateTaxTotal = () => {
    if (
      !Array.isArray(InvoiceCustomerData) ||
      !Array.isArray(invoiceFuelData) ||
      !Array.isArray(TaxDataForInvoice)
    ) {
      return 0;
    }

    let totalTaxAmount = 0;

    // Process each fuel type
    invoiceFuelData.forEach((fuelItem) => {
      const fuelType = fuelItem.fuelgrade_name.toLowerCase();
      const product = InvoiceCustomerData.find(
        (p) => p.product_id === fuelItem.fuelgrade_id
      );
      const fuelQuantity = product ? parseFloat(product.quantity) || 0 : 0;

      if (fuelQuantity > 0) {
        // Get applicable taxes for this fuel type
        const fuelTaxes = TaxDataForInvoice.filter((tax) => {
          if (!tax || !tax.po_customertax_name) return false;
          const taxName = tax.po_customertax_name.toLowerCase();

          if (taxName.includes("diesel") && fuelType === "diesel") {
            return true;
          }

          if (
            (taxName.includes("gasoline") || !taxName.includes("diesel")) &&
            fuelType !== "diesel"
          ) {
            return true;
          }

          return false;
        });

        // Add this fuel's tax total to the grand total
        fuelTaxes.forEach((tax) => {
          if (tax && tax.po_customertax_name) {
            totalTaxAmount +=
              (parseFloat(tax[tax.po_customertax_name]) || 0) * fuelQuantity;
          }
        });
      }
    });

    return formatCurrency(totalTaxAmount);
  };

  // Format currency values for display
  const formatCurrency = (value) => {
    const numValue = parseFloat(value) || 0;
    return numValue.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  };

  const calculateInvoiceAmount = () => {
    if (!brandInvoiceNumber || brandInvoiceNumber === "--Select PO--" || 
      !invoiceFuelData || invoiceFuelData.length === 0) {
    return 0;
  }
    // Calculate base fuel total
    const fuelTotal = parseFloat(productSubTotal) || 0;

    // Calculate freight charges
    const freightChargeTotal = InvoiceCustomerData.filter(
      (item) => !item.isCustomRow
    ).reduce((total, product) => {
      const quantity = parseFloat(product.quantity) || 0;
      if (quantity > 0 && freightdata && freightdata.freight_charge) {
        return total + freightdata.freight_charge * quantity;
      }
      return total;
    }, 0);

    // Calculate freight surcharge
    const freightSurchargeTotal = InvoiceCustomerData.filter(
      (item) => !item.isCustomRow
    ).reduce((total, product) => {
      const quantity = parseFloat(product.quantity) || 0;
      if (quantity > 0 && freightdata && freightdata.freight_surcharge) {
        return total + freightdata.freight_surcharge * quantity;
      }
      return total;
    }, 0);

    // Calculate tax total
    let taxTotal = 0;
    invoiceFuelData.forEach((fuelItem) => {
      const fuelType = fuelItem.fuelgrade_name?.toLowerCase();
      const product = InvoiceCustomerData.find(
        (p) => p.product_id === fuelItem.fuelgrade_id
      );
      const fuelQuantity = product ? parseFloat(product.quantity) || 0 : 0;

      if (fuelQuantity > 0 && Array.isArray(TaxDataForInvoice)) {
        // Get applicable taxes for this fuel type
        const fuelTaxes = TaxDataForInvoice.filter((tax) => {
          if (!tax || !tax.po_customertax_name) return false;
          const taxName = tax.po_customertax_name.toLowerCase();

          if (taxName.includes("diesel") && fuelType === "diesel") {
            return true;
          }

          if (
            (taxName.includes("gasoline") || !taxName.includes("diesel")) &&
            fuelType !== "diesel"
          ) {
            return true;
          }

          return false;
        });

        // Add this fuel's tax total to the grand total
        fuelTaxes.forEach((tax) => {
          if (tax && tax.po_customertax_name) {
            taxTotal +=
              (parseFloat(tax[tax.po_customertax_name]) || 0) * fuelQuantity;
          }
        });
      }
    });

    // Calculate other values
    const waterBillAmount = parseFloat(waterBill) || 0;
    const discountAmount = parseFloat(discount) || 0;
    const rebateAmount = parseFloat(Rebates) || 0;

    // Calculate final invoice amount
    const invoiceAmount =
      fuelTotal +
      freightChargeTotal +
      freightSurchargeTotal +
      taxTotal +
      waterBillAmount -
      discountAmount -
      rebateAmount;

    // Return the numeric value
    return invoiceAmount;
  };

  const displayInvoiceAmount = () => {
    if (!brandInvoiceNumber || brandInvoiceNumber === "--Select PO--" || invoiceFuelData.length === 0) {
      return `$ 0.00`;
    }
    const amount = calculateInvoiceAmount();
    return `$ ${formatCurrency(amount)}`;
  };
  useEffect(() => {
    setQuantityErrors({});
    setInvoiceFuelData([]);
    setBrandInvoiceNumber("");
  }, [customerId]);

  // const validateDeliveryDate = (current) => {
  //   // Get PO date from invoicePurchaseDate
  //   const poDate = invoicePurchaseDate
  //     ? dayjs(invoicePurchaseDate).startOf("day")
  //     : null;
  //   const today = dayjs().startOf("day");

  //   // If we have a PO date, ensure delivery date is >= PO date and <= today
  //   if (poDate && current) {
  //     return current < poDate || current > today;
  //   }

  //   // If no PO date yet, just ensure delivery date <= today
  //   return current > today;
  // };

  // const validateInvoiceDate = (current) => {
  //   // Get delivery date
  //   const deliveryDate = createdDate ? dayjs(createdDate).startOf("day") : null;
  //   const today = dayjs().startOf("day");

  //   // If we have a delivery date, ensure invoice date is >= delivery date and <= today
  //   if (deliveryDate && current) {
  //     return current < deliveryDate || current > today;
  //   }

  //   // If no delivery date yet, just ensure invoice date <= today
  //   return current > today;
  // };

  // const validateDueDate = (current) => {
  //   // Get invoice date
  //   const invDate = invoiceDate ? dayjs(invoiceDate).startOf("day") : null;

  //   // If we have an invoice date, ensure due date >= invoice date
  //   if (invDate && current) {
  //     return current < invDate;
  //   }

  //   // If no invoice date yet, allow all future dates
  //   return current < dayjs().startOf("day");
  // };

  const validateDeliveryDate = (current) => {
    if (!current) return false;

    // If PO date exists, delivery date should be >= PO date and <= today
    const poDate = invoicePurchaseDate
      ? dayjs(invoicePurchaseDate).startOf("day")
      : null;
    const today = dayjs().startOf("day");
    const oct2024 = dayjs("2024-10-01").startOf("day"); // Oct 2024 constraint

    // Disable dates that are:
    // 1. Before October 2024
    // 2. Before PO date (if PO exists)
    // 3. After today
    const minDate = poDate && poDate.isAfter(oct2024) ? poDate : oct2024;

    // Return true to disable the date
    return current.isBefore(minDate) || current.isAfter(today);
  };

  const validateInvoiceDate = (current) => {
    if (!current) return false;

    // Delivery date is the minimum date for invoice
    const deliveryDate = createdDate ? dayjs(createdDate).startOf("day") : null;
    const today = dayjs().startOf("day");

    if (deliveryDate) {
      // Return true to disable the date
      return current.isBefore(deliveryDate) || current.isAfter(today);
    }

    // If no delivery date selected yet, just prevent future dates
    return current.isAfter(today);
  };

  // Updated validateDueDate function: Only allow dates between invoice date and current date
  // const validateDueDate = (current) => {
  //   if (!current) return false;

  //   const today = dayjs().startOf("day");
  //   const invDate = invoiceDate ? dayjs(invoiceDate).startOf("day") : null;

  //   if (invDate) {
  //     // Disable dates that are:
  //     // 1. Before the invoice date
  //     // 2. After today
  //     return current.isBefore(invDate) || current.isAfter(today);
  //   }

  //   // If no invoice date yet, disable all past dates and future dates
  //   return current.isBefore(dayjs().startOf("day")) || current.isAfter(today);
  // };
  const validateDueDate = (current) => {
    if (!current) return false;

    // Invoice date is the minimum for due date
    const invDate = invoiceDate ? dayjs(invoiceDate).startOf("day") : null;

    if (invDate) {
      // Only disable dates before invoice date
      // Dates after current date are allowed
      return current.isBefore(invDate);
    }

    // If no invoice date yet, disable all past dates but allow future dates
    return current.isBefore(dayjs().startOf("day"));
  };
  // const validateDueDate = (current) => {
  //   if (!current) return false;

  //   // Invoice date is the minimum for due date
  //   const invDate = invoiceDate ? dayjs(invoiceDate).startOf("day") : null;

  //   if (invDate) {
  //     // Return true to disable the date (disable if before invoice date)
  //     return current.isBefore(invDate);
  //   }

  //   // If no invoice date yet, disable all past dates
  //   return current.isBefore(dayjs().startOf("day"));
  // };

  // Modify the state initialization to ensure no default dates are pre-selected
  // Replace these in your component's useState initialization:
  const [createdDate, setCreatedOnDate] = useState(null); // Was using today's date
  const [invoiceDate, setInvoiceDate] = useState(null); // Was using dayjs()
  const [dueDate, setDueDate] = useState(null); // Was using today's date

  // Update the date picker handlers to not automatically calculate other dates
  const handleDeliveryDateChange = (date) => {
    console.log(date, "date....");
    setCreatedOnDate(date);
    setFormField({
      ...formField,
      created_on: date
    });
    // Don't automatically set other dates
  };

  const handleInvoiceDateChange = (date) => {
    setInvoiceDate(date);
    setFormField({
      ...formField,
      invoiceDate: date
    });
    // Only calculate due date if user explicitly selected a payment term
    if (selectedTerm) {
      calculateDueDate(date, selectedTerm);
    }
  };

  const handleDueDateChange = (date) => {
    setDueDate(date);
    setFormField({
      ...formField,
      due_on: date
    });
    // Clear the selected term when user manually sets due date
    setSelectedTerm("");
  };

  const canSelectPO = () => formField.bill_to !== "";
const canSelectDeliveryDate = () => canSelectPO() && brandInvoiceNumber !== "";
const canSelectInvoiceDate = () => canSelectDeliveryDate() && createdDate !== null;
const canSelectPaymentTerms = () => canSelectInvoiceDate() && invoiceDate !== null;
const canSelectDueDate = () => canSelectPaymentTerms();


  return (
    <>
      {loading && <FullScreenLoader />}

      <div className="flex w-full px-2 ">
        <div className="flex w-full px-2 ">
          <div className="flex-1 flex justify-start items-center ">
            {/* <nav aria-label="breadcrumb">
      <ol className="flex space-x-2 text-md font-bold text-gray-700 mb-6">
        <li>Sales</li>
        <li>
          <span className="text-gray-400">{">>"}</span>
        </li>
        <li>Create Invoice</li>
      </ol>
    </nav> */}
          </div>

          <div className="flex-1 flex justify-center">
            <CustomCommonTab items={menuItems} />
          </div>

          <div className="flex-1 flex justify-end"></div>
        </div>
      </div>
      <form>
        <div className="flex justify-center items-start">
          <div className="flex flex-col">
            <div
              className="relative w-[60vw] bg-white rounded-lg space-y-0  mt-2 mb-[20px]"
              style={{ boxShadow: "0 2px 10px rgba(0, 0, 0, 0.3)" }}
            >
              <div className="flex items-center justify-end">
                {/* Home button - bordered/unfilled by default, filled blue on hover */}
                <div
                  className="absolute -top-3 flex items-center justify-center cursor-pointer rounded-full bg-[#cefafe] hover:bg-[#25caed] w-9 h-9 mb-0 mr-9 border-[0.5px] border-[#25caed] hover:border-[#25caed] hover:scale-110 shadow-md transition-all duration-200 group"
                  onClick={() => {
                    navigate("/");
                  }}
                  style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
                >
                  <FaHome
                    className="text-[#25caed] group-hover:text-white transition-colors duration-200"
                    size={16}
                  />
                </div>

                {/* Close Icon - bordered/unfilled by default, filled red on hover */}
                <div
                  className="absolute -top-3 -right-2 bg-red-200 hover:bg-red-500 w-9 h-9 flex items-center justify-center rounded-full cursor-pointer hover:bg-red-700 border-[0.5px] border-red-500 hover:border-red-700 hover:scale-110 shadow-md transition-all duration-200 group"
                  onClick={() => navigate("/invoice")}
                >
                  <span className="text-red-500 group-hover:text-white text-xl font-bold mb-1.5 transition-colors duration-200">
                    &times;
                  </span>
                </div>
              </div>
              {/* <CustomNavigation onCloseClick={() => navigate("/invoice")} /> */}
              <div className="flex mb-1 justify-center p-4 rounded-md font-bold text-md items-center w-full mx-auto py-2 bg-white mt-3 "></div>
              <div className="flex justify-evenly items-start w-[450px] mx-auto py-2 border-b">
                {/* Customer Name Section */}
                <div className="flex flex-col items-start w-[180px] gap-2">
                  <div></div>
                  <select
                    className="ml-2 w-full p-1 border rounded-md h-[32px]"
                    id="bill_to"
                    name="bill_to"
                    required
                    value={formField.bill_to}
                    onChange={handleChange}
                  >
                    <option value="">--Select Customer--</option>
                    {customerOptions.map((customer) => (
                      <option
                        key={customer.customer_id}
                        value={customer.customer_id}
                      >
                        {customer.business_name}
                      </option>
                    ))}
                  </select>
                </div>

                {/* PO Section */}
                <div className="flex flex-col items-end w-[160px]">
                  {/* PO Number Label */}
                  <div className="flex items-center justify-end"></div>

                  <select
  className="mt-2 w-full p-1 border rounded-md shadow-sm h-[32px]"
  onChange={handlePOChange}
  disabled={!canSelectPO()}
>
  <option>--Select PO--</option>
  {latestPurchaseInvoices?.map((po) => (
    <option
      key={po.brand_invoice_number}
      value={po.brand_invoice_number}
    >
      {po.brand_invoice_number}
    </option>
  ))}
</select>
                </div>
              </div>

              <div className="flex justify-between items-center mb-8 ml-[40px] mr-[40px] py-10 ">
                <div className="text-left">
                  <h2 className=" font-bold text-4xl mb-2 text-left">
                    INVOICE
                  </h2>
                  <p className="  text-2xl font-bold text-left">
                    {latestInvoiceNumber}
                  </p>
                </div>
                <div className="text-right">
                  <h2 className="   text-orange-600 font-bold text-4xl mb-2 text-left">
                    AMT DUE
                  </h2>
                  <p className="  text-orange-600 text-2xl font-bold text-right m-auto">
                    {displayInvoiceAmount()}
                  </p>
                </div>
              </div>
              <div>
                <div className="h-[80px] flex items-center justify-between">
                  <div>
                    <div
                      className="flex gap-4 justify-between items-end mr-[40px] pb-[30px] w-[60vw] "
                      style={{ paddingRight: "40px" }}
                    >
                      {customerIndividualData.brand_logo &&
                        brandInvoiceNumber && (
                          <div className="flex items-center justify-center pl-12 my-auto">
                            <img
                              src={`${baseURL}/img/${customerIndividualData.brand_logo}`}
                              alt="Business Logo"
                              className="w-20 h-15 rounded-full object-contain"
                            />
                          </div>
                        )}

                      {isFormComplete() && (
                        <button
                          onClick={handleSubmit}
                          type="submit"
                          className='w-24 px-2 px-12 py-1 border-2 
                  border-[#000080] hover:font-bold rounded-md hover:bg-blue-700 
                  hover:shadow-blue-700/50 uppercase duration-200 hover:text-white 
                  hover:shadow-lg hover:border-transparent active:scale-90 "'
                        >
                          CREATE
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex justify-between p-4 pb-0 px-[40px] my-4">
                {/* Bill To Section */}
                <div className="flex-1 p-2 rounded-md ">
                  <p className="font-bold text-xl uppercase  ">
                    {customerIndividualData ? "Bill To" : ""}
                  </p>
                  <p className=" ">{customerIndividualData.business_name}</p>
                  <p className="">{customerIndividualData.billing_address} </p>
                  <p className="">
                    {customerIndividualData.billing_city}{" "}
                    {customerIndividualData.billing_state}{" "}
                    {customerIndividualData.billing_zip_code}
                  </p>
                  <p className=" ">{customerIndividualData.contact_name}</p>
                  <p className=" ">{customerIndividualData.contact_email}</p>
                  <p className=" ">{customerIndividualData.contact_no}</p>
                  {/* <div className="flex items-center justify-start mt-4 h-55">
                    {customerIndividualData ? (
                      <span
                        className=" text-right text-blue-500 font-bold mr-2 mt-2"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Account #
                      </span>
                    ) : (
                      ""
                    )}
                    {customerIndividualData ? (
                      <p
                        className="w-auto min-w-[125px] text-blue-500 font-bold mt-2"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        {customerIndividualData.business_account
                          ? customerIndividualData.business_account
                          : ""}
                      </p>
                    ) : (
                      ""
                    )}
                  </div> */}
                </div>

                {/* Ship To Section */}
                <div className="flex-1 p-2 rounded-md  pl-[40px]">
                  <p className="font-bold text-xl uppercase ">
                    {customerIndividualData ? "Ship To" : ""}
                  </p>
                  {/* <p className="">{customerIndividualData.shipping_to}</p> */}
                  <p>{customerIndividualData.business_name}</p>
                  <p className="">{customerIndividualData.shipping_address} </p>

                  <p className="">
                    {customerIndividualData.shipping_city}{" "}
                    {customerIndividualData.shipping_state}{" "}
                    {customerIndividualData.shipping_zip_code}
                  </p>
                </div>

                {/* Brand Logo Section */}
                {/* {customerIndividualData.brand_logo && ( */}
                {/* {customerIndividualData.brand_logo && brandInvoiceNumber && (
                  <div className="flex items-center justify-center pr-12 my-auto">
                    <img
                      src={`${baseURL}/img/${customerIndividualData.brand_logo}`}
                      alt="Business Logo"
                      className="w-20 h-20 rounded-full object-contain"
                    />
                  </div>
                )} */}

                {/* Invoice Details Section */}
                <div className="w-1/3 space-y-3">
                  <div className="flex items-center justify-evenly mt-2 h-55">
                    {customerIndividualData ? (
                      <span
                        className=" text-left text-blue-500 font-bold ml-8 mt-0"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        ACCOUNT #
                      </span>
                    ) : (
                      ""
                    )}
                    {customerIndividualData ? (
                      <p
                        className="w-auto min-w-[100px] text-blue-500 font-bold mt-0"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        {customerIndividualData.business_account
                          ? customerIndividualData.business_account
                          : ""}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="flex items-center gap-2 justify-end">
                    <span
                      className="w-3/5 text-right font-semibold mr-2 "
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Delivery Date
                    </span>
                    <Space direction="vertical" className="w-[160px]">
                      {/* <DatePicker
                        id="invoiceDate"
                        disabledDate={validateInvoiceDate}
                        // disabledDate={disableAllDates}
                        format="MM-DD-YYYY"
                        className="w-[135px] h-[32px] rounded-md border border-gray-300"
                        value={invoiceDate}
                        onChange={handleInvoiceDateChange}
                        placeholder="MM-DD-YYYY"
                      /> */}

                      <DatePicker
                        id="deliveryDate"
                        format="MM-DD-YYYY"
                        className="w-[135px] h-[32px] rounded-md border border-gray-300"
                        value={createdDate}
                        disabledDate={validateDeliveryDate}
                        onChange={handleDeliveryDateChange}
                        placeholder="MM-DD-YYYY"
                        disabled={!canSelectDeliveryDate()}
                      />
                    </Space>
                  </div>

                  <div className="flex items-center gap-2 justify-end">
                    <span
                      className="w-3/5 text-right font-semibold mr-2  "
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Invoice Date
                    </span>
                    <Space direction="vertical" className="w-[160px]">
                      {/* <DatePicker
                        id="deliveryDate"
                        format="MM-DD-YYYY"
                        className="w-[135px]  h-[32px] rounded-md border border-gray-300"
                        value={createdDate}
                        disabledDate={validateDeliveryDate}
                        // disabledDate={disablefutureDates}
                        onChange={handleDeliveryDateChange}
                        placeholder="MM-DD-YYYY"
                      /> */}
                      <DatePicker
                        id="invoiceDate"
                        disabledDate={validateInvoiceDate}
                        format="MM-DD-YYYY"
                        className="w-[135px] h-[32px] rounded-md border border-gray-300"
                        value={invoiceDate}
                        onChange={handleInvoiceDateChange}
                        placeholder="MM-DD-YYYY"
                        disabled={!canSelectInvoiceDate()}
                      />
                    </Space>
                  </div>
                  <div className="flex items-center gap-2 justify-end">
                    <span
                      className="w-3/5 text-right font-semibold mr-2"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Payment Terms
                    </span>
                    <select
                      className="w-[160px] h-[30px] rounded-md border border-gray-300 px-2 py-1"
                      value={selectedTerm}
                      onChange={handlePaymentTermChange}
                      disabled={!canSelectPaymentTerms()}
                    >
                      <option value="">--Select--</option>
                      <option value="Net 7">Net 7</option>
                      <option value="Net 10">Net 10</option>
                      <option value="Net 30">Net 30</option>
                    </select>
                  </div>

                  <div className="flex items-center gap-2 justify-end">
                    <span
                      className="w-3/5 text-right font-semibold mr-2 "
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Due Date
                    </span>
                    <Space direction="vertical" className="w-[160px]">
                      {/* <DatePicker
                        id="dueDate"
                        format="MM-DD-YYYY"
                        className="w-[135px]  h-[32px] rounded-md border border-gray-300"
                        value={dueDate}
                        disabledDate={validateDueDate}
                        // disabledDate={disablePastDates} // Disable past dates
                        onChange={handleDueDateChange}
                        placeholder="MM-DD-YYYY"
                      /> */}
                      <DatePicker
                        id="dueDate"
                        format="MM-DD-YYYY"
                        className="w-[135px] h-[32px] rounded-md border border-gray-300"
                        value={dueDate}
                        disabledDate={validateDueDate}
                        onChange={handleDueDateChange}
                        placeholder="MM-DD-YYYY"
                        disabled={!canSelectDueDate()}
                      />
                    </Space>
                  </div>

                  <div className="flex justify-start gap-6 ml-[35px] "></div>
                </div>
              </div>

              <div>
                <div>
                  <div className="relative flex items-start">
                    <div className="flex-grow">
                      <table className="w-[90%]  border-collapse border mt-1 px-[40px] ">
                        <thead>
                          <tr>
                            <th
                              colSpan="100%"
                              className="border-b-2 border-black bg-white h-0 p-0 m-0"
                            ></th>
                          </tr>
                          {/* <tr className="bg-gray-100">
                            <th
                              className="px-2 py-2 text-center"
                              style={{ width: "60px" }}
                            >
                              #
                            </th>
                            <th
                              className=" py-2 text-left"
                              style={{ width: "700px" }}
                            >
                              Product
                            </th>

                            <th
                              className=" py-2 text-left"
                              style={{ width: "450px" }}
                            >
                              Description
                            </th>

                            <th
                              className=" py-2 text-center"
                              style={{ width: "1000px" }}
                            >
                              Quantity/gl
                            </th>
                            <th
                              className=" py-2 text-right"
                              style={{ whiteSpace: "nowrap", width: "350px" }}
                            >
                              Unit Price
                            </th>
                            <th
                              className=" text-right px-2"
                              style={{ whiteSpace: "nowrap", width: "450px" }}
                            >
                              Line Total
                            </th>
                          </tr> */}

                          <tr className="bg-white border-b-[2px] border-black">
                            <th className="px-2 py-2 text-center w-[5%]">#</th>
                            <th className="px-2 py-2 pl-10 text-left uppercase w-[35%]">
                              Product
                            </th>
                            {showDescription ? (
                              <th className="py-2 text-left uppercase w-[15%]">
                                Description
                              </th>
                            ) : (
                              <th className="py-2 text-left uppercase w-[15%]">
                                Description
                              </th>
                            )}
                            <th className="text-right uppercase w-[17%]">
                              Quantity
                            </th>
                            <th
                              className="text-right uppercase w-[10%]"
                              style={{ whiteSpace: "nowrap" }}
                            >
                              Unit Price
                            </th>
                            <th className="px-4 text-right uppercase w-[15%]">
                              Amount
                            </th>
                          </tr>

                          <tr>
                            <th
                              colSpan="100%"
                              className="border-t-2 border-black bg-white h-0 p-0 m-0"
                            ></th>
                          </tr>
                        </thead>
                        {formField.bill_to !== "" &&
                        freightdata &&
                        brandInvoiceNumber &&
                        TaxDataForInvoice.length > 0 &&
                        InvoiceCustomerData.filter(
                          (row) => !row.isCustomRow
                        ).some((row) => row.unitPrice > 0) ? (
                          <tbody style={{ fontFamily: "Sans-Serif" }}>
                            {InvoiceCustomerData.map((row, index) => {
                              const filteredItems = invoiceFuelData.filter(
                                (item) => row.product_id === item.fuelgrade_id
                              );
                              console.log(filteredItems, "filtereditems");
                              const grossQuantity =
                                filteredItems.length > 0
                                  ? filteredItems[0].gross_quantity
                                  : 0;

                              // Only show tax rows for non-custom rows with quantities
                              const showTaxes =
                                !row.isCustomRow &&
                                parseFloat(row.quantity) > 0 &&
                                expandedTaxRow === row.product_id;

                              // Get applicable taxes for this product
                              const productTaxes = TaxDataForInvoice.filter(
                                (tax) => {
                                  const taxName =
                                    tax.po_customertax_name.toLowerCase();
                                  const fuelType =
                                    filteredItems[0]?.fuelgrade_name.toLowerCase() ||
                                    "";

                                  if (
                                    taxName.includes("diesel") &&
                                    fuelType === "diesel"
                                  ) {
                                    return true;
                                  }

                                  if (
                                    (taxName.includes("gasoline") ||
                                      !taxName.includes("diesel")) &&
                                    fuelType !== "diesel"
                                  ) {
                                    return true;
                                  }

                                  return false;
                                }
                              );

                              return (
                                <React.Fragment key={row.product_id}>
                                  <tr
                                    key={row.product_id}
                                    className="border-t font-normal"
                                  >
                                    <td className="px-2 py-2">{index + 1}</td>
                                    <td
                                      className="text-left py-2"
                                      style={{
                                        whiteSpace: "nowrap",
                                        width: "700px"
                                      }}
                                    >
                                      <div className="flex items-center">
                                        {/* Add expand/collapse icon for rows with taxes */}
                                        <div className="w-[20px] mr-2">
                                          {!row.isCustomRow &&
                                            parseFloat(row.quantity) > 0 &&
                                            productTaxes.length > 0 && (
                                              <button
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  e.stopPropagation();
                                                  toggleTaxRow(row.product_id);
                                                }}
                                                className="mr-2 text-blue-500 focus:outline-none w-[20px] "
                                              >
                                                {expandedTaxRow ===
                                                row.product_id
                                                  ? "▼"
                                                  : "►"}
                                              </button>
                                            )}
                                        </div>
                                        {/* Product Name */}
                                        <span>{row.product_name}</span>

                                        {/* Discount Badge */}
                                        {discount &&
                                        DiscountedPercentage > 0 &&
                                        row.product_name.toLowerCase() ===
                                          "discount" &&
                                        selectedDiscountType ===
                                          "percentage" ? (
                                          <span className="bg-red-500 text-white ml-3 px-4 py-1 rounded-sm">
                                            ({DiscountedPercentage})%
                                          </span>
                                        ) : null}

                                        {/* Delete Button */}
                                        <button
                                          onClick={(e) =>
                                            handleDelete(row.product_name, e)
                                          }
                                          className="text-gray-500 hover:text-red-600 ml-2"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            fill="currentColor"
                                            className="w-4 h-4"
                                          >
                                            <path d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z" />
                                          </svg>
                                        </button>
                                      </div>
                                    </td>

                                    <td
                                      className=" py-2 text-left"
                                      style={{ width: "350px" }}
                                      colSpan={
                                        row.isFreight ||
                                        row.product_name.toLowerCase() ===
                                          "water bill" ||
                                        row.product_name.toLowerCase() ===
                                          "rebates" ||
                                        (row.isDiscount &&
                                          selectedDiscountType !== "gallons") ||
                                        row.product_name
                                          .toLowerCase()
                                          .includes("credit")
                                          ? 2
                                          : 1
                                      }
                                    >
                                      {row.isFreight && row.isCustomRow ? (
                                        // Freight Charges/surcharge: Use row.description
                                        <input
                                          type="text"
                                          placeholder="Enter description"
                                          maxLength={50}
                                          value={row.description || ""}
                                          onChange={(e) =>
                                            handleProductChange(
                                              index,
                                              "description",
                                              e.target.value
                                            )
                                          }
                                          className="w-full p-2 border rounded h-[30px] text-left"
                                        />
                                      ) : row.isDiscount && row.isCustomRow ? (
                                        // Discount: Show dropdown for Amount, %, Gallons
                                        <select
                                          className="w-full min-w-[200px] p-1 border rounded h-[30px] text-[16px]"
                                          // onChange={(e) =>
                                          //   handleDiscountTypeChange(e, index)
                                          // }
                                          value={row.description || ""}
                                        >
                                          <option value="">
                                            --Select Discount Type--
                                          </option>
                                          <option value="amount">Amount</option>
                                          <option value="percentage">%</option>
                                          <option value="gallons">
                                            Gallons
                                          </option>
                                        </select>
                                      ) : row.isCustomRow ? (
                                        // Custom row: Use row.description
                                        <input
                                          type="text"
                                          placeholder="Enter description"
                                          maxLength={50}
                                          value={row.description || ""}
                                          onChange={(e) =>
                                            handleProductChange(
                                              index,
                                              "description",
                                              e.target.value
                                            )
                                          }
                                          className="w-full p-2 border rounded h-[30px] text-left"
                                        />
                                      ) : (
                                        // Non-custom row: Use prod_description for each filtered item
                                        filteredItems.map(
                                          (filteredRow, filteredIndex) => (
                                            <div
                                              style={{
                                                whiteSpace: "nowrap",
                                                textTransform: "capitalize"
                                              }}
                                              className="text-left"
                                            >
                                              {filteredRow.prod_decsription ||
                                                ""}
                                            </div>
                                          )
                                        )
                                      )}
                                    </td>

                                    {!row.isFreight &&
                                      (!row.isDiscount ||
                                        selectedDiscountType === "gallons") &&
                                      row.product_name.toLowerCase() !==
                                        "water bill" &&
                                      row.product_name.toLowerCase() !==
                                        "rebates" &&
                                      ![...Array(14)]
                                        .map((_, i) => `credit${i + 1}`)
                                        .includes(
                                          row.product_name.toLowerCase()
                                        ) && (
                                        <td
                                          className="px-2 py-2 relative"
                                          style={{
                                            width: "1000px",
                                            whiteSpace: "nowrap"
                                          }}
                                        >
                                          <div className="flex items-center border-2 border-gray-400 rounded h-[32px] bg-white overflow-hidden">
                                            {/* Available Gallons (Read-only) */}
                                            <div className="w-3/5 text-right pr-2 text-gray-700 bg-gray-100 border-r bg-gray-200">
                                              {filteredItems.length > 0 ? (
                                                filteredItems.map(
                                                  (filteredItem, idx) => (
                                                    <div
                                                      key={idx}
                                                      className="flex justify-start "
                                                    >
                                                      <label
                                                        htmlFor={`fuel-${index}`}
                                                        className="text-left font-normal flex justify-center items-center bg-gray-200 mb-0 py-1"
                                                      >
                                                        {formatNumberWithCommas(
                                                          filteredItem.gross_quantity ||
                                                            0
                                                        )}
                                                      </label>
                                                    </div>
                                                  )
                                                )
                                              ) : (
                                                <div className="flex justify-start space-x-2 px-2">
                                                  <label className="text-left font-normal flex justify-center items-center bg-gray-200 mb-0 py-1">
                                                    0
                                                  </label>
                                                </div>
                                              )}
                                            </div>
                                            {/* Editable Input Field */}
                                            <input
                                              type="text"
                                              disabled={
                                                (grossQuantity <= 300 ||
                                                  grossQuantity <= 0) &&
                                                !row.isCustomRow &&
                                                !row.product_name
                                                  .toLowerCase()
                                                  .includes("credit")
                                              }
                                              value={
                                                row.quantity
                                                  ? formatNumberWithCommas(
                                                      row.quantity
                                                    )
                                                  : ""
                                              }
                                              id={`quantity-input-${index}`}
                                              onChange={(e) => {
                                                const newQuantity = Number(
                                                  e.target.value.replace(
                                                    /[^0-9.]/g,
                                                    ""
                                                  )
                                                );

                                                if (
                                                  !row.product_name
                                                    .toLowerCase()
                                                    .includes("credit")
                                                ) {
                                                  if (
                                                    newQuantity >
                                                      grossQuantity &&
                                                    selectedDiscountType !==
                                                      "gallons"
                                                  ) {
                                                    setQuantityErrors(
                                                      (prev) => ({
                                                        ...prev,
                                                        [row.product_id]: `Available gallons: ${formatNumberWithCommas(
                                                          grossQuantity
                                                        )}`
                                                      })
                                                    );
                                                    return;
                                                  }
                                                }

                                                setQuantityErrors((prev) => {
                                                  const updatedErrors = {
                                                    ...prev
                                                  };
                                                  delete updatedErrors[
                                                    row.product_id
                                                  ];
                                                  return updatedErrors;
                                                });
                                                handleProductChange(
                                                  index,
                                                  "quantity",
                                                  newQuantity
                                                );
                                              }}
                                              onBlur={() => {
                                                setQuantityErrors((prev) => {
                                                  if (!row.product_id) {
                                                    console.warn(
                                                      "Missing product_id for row:",
                                                      row
                                                    );
                                                    return prev;
                                                  }
                                                  const updatedErrors = {
                                                    ...prev
                                                  };
                                                  delete updatedErrors[
                                                    row.product_id
                                                  ];
                                                  return updatedErrors;
                                                });
                                              }}
                                              placeholder="0"
                                              className={`text-right w-3/5 p-2 px-1 border-none rounded-r outline-none focus:none focus:outline-none ${
                                                quantityErrors[row.product_id]
                                                  ? "border-red-500"
                                                  : ""
                                              } ${
                                                (grossQuantity <= 300 ||
                                                  grossQuantity <= 0) &&
                                                !row.isCustomRow &&
                                                !row.product_name
                                                  .toLowerCase()
                                                  .includes("credit")
                                                  ? "bg-gray-200 cursor-not-allowed"
                                                  : "bg-white"
                                              }`}
                                            />
                                          </div>

                                          {/* Error Tooltip */}
                                          {quantityErrors[row.product_id] && (
                                            <div className="absolute left-0 bottom-[110%] px-2 py-1 bg-red-500 text-white text-xs rounded-md shadow-lg">
                                              {quantityErrors[row.product_id]}
                                              <div className="absolute left-1/2 bottom-[-5px] transform -translate-x-1/2 w-0 h-0 border-x-[5px] border-x-transparent border-t-[5px] border-t-red-500"></div>
                                            </div>
                                          )}
                                        </td>
                                      )}

                                    {/* Unit Price */}
                                    <td
                                      className=" py-2"
                                      style={{ width: "350px" }}
                                    >
                                      {row.product_name.toLowerCase() ===
                                        "freight charges" ||
                                      row.product_name.toLowerCase() ===
                                        "freight surcharge" ||
                                      row.product_name
                                        .toLowerCase()
                                        .includes("credit") ? (
                                        // Input for "Freight Charges" or "Freight Surcharge"
                                        <div className="w-full text-right flex items-end justify-end h-[30px]">
                                          {/* <input
        type="number"
        value={row.unitPrice || ""}
      
        min={0}
        onChange={(e) =>
          handleProductChange(index, "unitPrice", Number((e.target.value)), row.product_name)
        }
        placeholder=""
        className="w-full text-center p-2 border rounded h-[30px]"
      /> */}
                                          <input
                                            type="text" // Use text to allow comma formatting
                                            value={
                                              row.unitPrice !== undefined &&
                                              row.unitPrice !== null
                                                ? formatNumberWithCommas(
                                                    row.unitPrice
                                                  ) // Ensure 2 decimal places
                                                : ""
                                            }
                                            onChange={(e) => {
                                              let inputValue =
                                                e.target.value.replace(
                                                  /[^0-9.]/g,
                                                  ""
                                                ); // Remove non-numeric characters except "."

                                              // Prevent multiple decimal points
                                              if (
                                                (inputValue.match(/\./g) || [])
                                                  .length > 1
                                              ) {
                                                return;
                                              }

                                              let newValue =
                                                parseFloat(inputValue);
                                              if (isNaN(newValue)) newValue = 0; // Default to 0 if NaN

                                              // **Explicitly round to two decimal places**
                                              newValue =
                                                Math.round(newValue * 100) /
                                                100;

                                              handleProductChange(
                                                index,
                                                "unitPrice",
                                                newValue,
                                                row.product_name
                                              );
                                            }}
                                            placeholder="0.00"
                                            className="w-full text-right py-2 border rounded h-[30px]"
                                          />
                                        </div>
                                      ) : row.product_name.toLowerCase() ===
                                          "water bill" ||
                                        row.product_name.toLowerCase() ===
                                          "rebates" ||
                                        row.isDiscount ? (
                                        // Handle Discounts and Specific Conditions
                                        selectedDiscountType === "" &&
                                        row.isDiscount ? (
                                          <div className="w-full py-2 text-right flex items-end justify-end h-[30px]">
                                            {"0.00"}{" "}
                                            {/* Reset to 0 if Discount Type is empty */}
                                          </div>
                                        ) : (
                                          //   <input
                                          //   type="number"
                                          //   value={row.unitPrice || ""}

                                          //   min={0}
                                          //   onChange={(e) =>
                                          //     handleProductChange(index, "unitPrice", Number((e.target.value)), row.product_name)
                                          //   }
                                          //   placeholder=""
                                          //   className="w-full p-2 text-center border rounded h-[30px]"
                                          // />
                                          <input
                                            type="text" // Use text to allow comma formatting
                                            value={
                                              row.unitPrice !== undefined &&
                                              row.unitPrice !== null
                                                ? formatNumberWithCommas(
                                                    row.unitPrice
                                                  ) // Ensure 2 decimal places
                                                : ""
                                            }
                                            onChange={(e) => {
                                              let inputValue =
                                                e.target.value.replace(
                                                  /[^0-9.]/g,
                                                  ""
                                                ); // Remove non-numeric characters except "."

                                              // Prevent multiple decimal points
                                              if (
                                                (inputValue.match(/\./g) || [])
                                                  .length > 1
                                              ) {
                                                return;
                                              }

                                              let newValue =
                                                parseFloat(inputValue);
                                              if (isNaN(newValue)) newValue = 0; // Default to 0 if NaN

                                              // **Explicitly round to two decimal places**
                                              newValue =
                                                Math.round(newValue * 100) /
                                                100;

                                              handleProductChange(
                                                index,
                                                "unitPrice",
                                                newValue,
                                                row.product_name
                                              );
                                            }}
                                            placeholder="0.00"
                                            className="w-full text-right py-2 border rounded h-[30px]"
                                          />
                                        )
                                      ) : (
                                        // Default Case for Other Products
                                        // <div className="w-full p-2 text-center flex items-center justify-center h-[30px]">
                                        //   $ {row.unitPrice ? Number(row.unitPrice).toFixed(2): '0' || ""} {/* Default Unit Price */}
                                        // </div>
                                        <div className="relative w-full py-2  text-right flex items-end justify-end h-[30px]">
                                          <span
                                            className={`${
                                              row.unitPrice <= 0
                                                ? "text-red-500 font-bold"
                                                : ""
                                            }`}
                                            style={{ whiteSpace: "nowrap" }}
                                          >
                                            ${" "}
                                            {row.unitPrice
                                              ? Number(
                                                  row.unitPrice
                                                ).toLocaleString(undefined, {
                                                  minimumFractionDigits: 5,
                                                  maximumFractionDigits: 5
                                                })
                                              : "0"}
                                          </span>

                                          {/* {row.unitPrice <= 0 && (
    <div className="w-[110px] absolute left-0 bottom-[110%] px-2 py-1 bg-red-500 text-white text-xs rounded-md arrow-tooltip">
      Update Pricebook
      <div className="absolute left-1/2 bottom-[-5px] transform -translate-x-1/2 w-0 h-0 border-x-[5px] border-x-transparent border-t-[5px] border-t-red-500"></div>
    </div>
  )} */}
                                        </div>
                                      )}
                                    </td>

                                    {/* Total */}

                                    {/* <td className="px-2 py-2">{row.total || "0.00"}</td> */}
                                    <td
                                      className="pr-1 py-2 pl-0"
                                      style={{
                                        whiteSpace: "nowrap",
                                        width: "400px"
                                      }}
                                    >
                                      {row.product_name.toLowerCase() ===
                                      "freight charges" ? (
                                        <div className="w-full py-2 pr-1 text-right flex items-end justify-end h-[30px]">
                                          ${" "}
                                          {Number(
                                            freightCharges
                                          ).toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                          })}
                                        </div>
                                      ) : row.product_name.toLowerCase() ===
                                        "freight surcharge" ? (
                                        <div className="w-full py-2 pr-1 text-right flex items-end justify-end h-[30px]">
                                          ${" "}
                                          {Number(
                                            freightsurcharge
                                          ).toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                          })}
                                        </div>
                                      ) : row.product_name.toLowerCase() ===
                                          "discount" &&
                                        selectedDiscountType === "" ? (
                                        <div className="w-full py-2 pr-1 text-right flex items-end justify-end h-[30px]">
                                          ${" "}
                                          {Number(0).toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                          })}{" "}
                                          {/* Set total to 0 for Discount */}
                                        </div>
                                      ) : row.product_name
                                          .toLowerCase()
                                          .includes("credit") ? (
                                        <div className="w-full py-2 pr-1 text-right flex items-end justify-end h-[30px] text-red-500">
                                          -${" "}
                                          {Number(
                                            Math.abs(row.total || 0)
                                          ).toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                          })}
                                        </div>
                                      ) : (
                                        <div className="w-full py-2 pr-1 text-right flex items-end justify-end h-[30px]">
                                          ${" "}
                                          {Number(
                                            row.total || 0
                                          ).toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                          })}
                                        </div>
                                      )}
                                    </td>

                                    {/* Action: Delete button */}
                                    {/* <td className="pr-4 py-2">
          <button
            onClick={(e) => handleDelete(row.product_name, e)}
            className="text-gray-500 hover:text-red-600"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-5 h-5"
            >
              <path d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z" />
            </svg>
          </button>
        </td> */}
                                  </tr>
                                  {/* Tax rows - only shown when expanded */}
                                  {showTaxes && (
                                    <>
                                      {/* First show the tax rows as before */}
                                      {productTaxes.map((tax, taxIndex) => {
                                        const taxRate =
                                          tax[tax.po_customertax_name];
                                        const quantity = parseFloat(
                                          row.quantity
                                        );
                                        const taxAmount = taxRate * quantity;

                                        return (
                                          <tr
                                            key={`tax-${row.product_id}-${taxIndex}`}
                                            className="bg-gray-50 border-t border-gray-200"
                                          >
                                            <td className="px-4 py-1"></td>
                                            <td
                                              className="py-1 pl-10 text-gray-600 text-md text-left"
                                              style={{ whiteSpace: "nowrap" }}
                                            >
                                              {tax.customer_taxes}
                                            </td>
                                            <td
                                              className="py-1"
                                              colSpan={1}
                                            ></td>
                                            <td
                                              className="py-1 text-center text-gray-600 text-md text-right"
                                              style={{ whiteSpace: "nowrap" }}
                                            >
                                              {Number(
                                                quantity
                                              ).toLocaleString()}{" "}
                                              gl
                                            </td>
                                            <td
                                              className="py-1 text-center text-gray-600 text-md text-right"
                                              style={{ whiteSpace: "nowrap" }}
                                            >
                                              ${" "}
                                              {Number(taxRate).toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 5,
                                                  maximumFractionDigits: 5
                                                }
                                              )}
                                            </td>
                                            <td
                                              className="pr-1 py-1 text-right text-gray-600 text-md"
                                              style={{ whiteSpace: "nowrap" }}
                                            >
                                              ${" "}
                                              {Number(taxAmount).toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 5,
                                                  maximumFractionDigits: 5
                                                }
                                              )}
                                            </td>
                                          </tr>
                                        );
                                      })}

                                      {/* Display freight charge as the last item in the expanded section */}
                                      {freightdata.freight_charge > 0 && (
                                        <tr className="bg-gray-50 border-t border-gray-200">
                                          <td className="px-4 py-1"></td>
                                          <td
                                            className="py-1 pl-10 text-gray-600 text-md text-left"
                                            style={{ whiteSpace: "nowrap" }}
                                          >
                                            Freight Charges
                                          </td>
                                          <td className="py-1" colSpan={1}></td>
                                          <td
                                            className="py-1 text-center text-gray-600 text-md text-right"
                                            style={{ whiteSpace: "nowrap" }}
                                          >
                                            {Number(
                                              row.quantity
                                            ).toLocaleString()}{" "}
                                            gl
                                          </td>
                                          <td
                                            className="py-1 text-center text-gray-600 text-md text-right"
                                            style={{ whiteSpace: "nowrap" }}
                                          >
                                            ${" "}
                                            {Number(
                                              freightdata.freight_charge
                                            ).toLocaleString(undefined, {
                                              minimumFractionDigits: 5,
                                              maximumFractionDigits: 5
                                            })}
                                          </td>
                                          <td
                                            className="pr-1 py-1 text-right text-gray-600 text-md"
                                            style={{ whiteSpace: "nowrap" }}
                                          >
                                            ${" "}
                                            {Number(
                                              freightdata.freight_charge *
                                                row.quantity
                                            ).toLocaleString(undefined, {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2
                                            })}
                                          </td>
                                        </tr>
                                      )}
                                      {freightdata.freight_surcharge > 0 && (
                                        <tr className="bg-gray-50 border-t border-gray-200">
                                          <td className="px-4 py-1"></td>
                                          <td
                                            className="py-1 pl-10 text-gray-600 text-md text-left"
                                            style={{ whiteSpace: "nowrap" }}
                                          >
                                            Freight Surcharge
                                          </td>
                                          <td className="py-1" colSpan={1}></td>
                                          <td
                                            className="py-1 text-center text-gray-600 text-md text-right"
                                            style={{ whiteSpace: "nowrap" }}
                                          >
                                            {Number(
                                              row.quantity
                                            ).toLocaleString()}{" "}
                                            gl
                                          </td>
                                          <td
                                            className="py-1 text-center text-gray-600 text-md text-right"
                                            style={{ whiteSpace: "nowrap" }}
                                          >
                                            ${" "}
                                            {Number(
                                              freightdata.freight_surcharge
                                            ).toLocaleString(undefined, {
                                              minimumFractionDigits: 5,
                                              maximumFractionDigits: 5
                                            })}
                                          </td>
                                          <td
                                            className="pr-1 py-1 text-right text-gray-600 text-md"
                                            style={{ whiteSpace: "nowrap" }}
                                          >
                                            ${" "}
                                            {Number(
                                              freightdata.freight_surcharge *
                                                row.quantity
                                            ).toLocaleString(undefined, {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2
                                            })}
                                          </td>
                                        </tr>
                                      )}
                                    </>
                                  )}
                                </React.Fragment>
                              );
                            })}
                          </tbody>
                        ) : formField.bill_to !== "" ? (
                          <tr>
                            <td
                              colSpan={6}
                              className="h-[120px] text-center align-middle"
                            >
                              {Array.isArray(InvoiceCustomerData) &&
                                InvoiceCustomerData.length > 0 &&
                                InvoiceCustomerData.filter(
                                  (row) => row.isCustomRow !== true
                                ).every((row) => (row.unitPrice || 0) <= 0) && ( // Default `unitPrice` to 0 if missing
                                  <h1 className="text-red-500 font-semibold whitespace-nowrap text-2xl">
                                    Please update the Fuel prices from Price
                                    book
                                  </h1>
                                )}
                              {!freightdata && (
                                <h1 className="text-red-500 font-semibold whitespace-nowrap text-2xl">
                                  Please update the Delivery prices from
                                  Delivery Price
                                </h1>
                              )}
                              {brandInvoiceNumber &&
                                TaxDataForInvoice.length <= 0 && (
                                  <h1 className="text-red-500 font-semibold whitespace-nowrap text-2xl">
                                    Please update the Taxes from Sales Tax
                                  </h1>
                                )}
                            </td>
                          </tr>
                        ) : null}
                      </table>
                    </div>
                    {/* {brandInvoiceNumber ?
  <div
  className={`mt-8 mx-auto border-2 border-blue-500 rounded-lg bg-white shadow-md mt-2 text-[12px] absolute top-[0px] right-[-32%]`}
  style={{
    width: '30%',
  }}
>
<div className="flex flex-col gap-1 justify-center border-b border-gray-200 pb-2 px-4 pt-2 mb-2">
  <div className="flex justify-between">
    <span className="font-bold text-gray-800">INVOICE #</span>
    <span className="font-bold text-gray-800">{brandInvoiceNumber}</span>
  </div>
  <div className="flex justify-between">
    <span className="font-bold text-gray-800">INVOICE Date:</span>
    <span className="font-bold text-gray-800">{formattedDate}</span>
  </div>
</div>


  <div className="space-y-2 px-2 py-2">
  {invoiceFuelData.map((row, index) =>{
    const enteredQuantity = InvoiceCustomerData[index]?.quantity || 0;
    const remainingQuantity = row.gross_quantity - enteredQuantity;
    return (
   
      <div
      key={row.fuelgrade_name}
      className={`flex flex-wrap justify-between items-center rounded-lg ${colors[index % colors.length]} p-2 gap-2`}
    >
      <span className={`font-bold text-[14px] ${texts[index % texts.length]}`}>
        {row.fuelgrade_name}
      </span>
  
      <span className={`${texts[index % texts.length]} font-bold text-[14px]`}>
        {formatNumberWithCommas(remainingQuantity || 0)} gl
      </span>
  
      <span className={`${texts[index % texts.length]} font-bold text-[14px]`} style={{whiteSpace:'nowrap'}}>
        $ {formatNumberWithCommas(row.unit_price || 0)}
      </span>
    </div>
    )
  } 
 
)}



  </div>
</div>
:
<div
className={`mt-8 mx-auto border border-blue-500 rounded-lg bg-white shadow-md mt-2 text-[12px] absolute top-[0px] right-[-28%]`}
style={{
  width: '30%',
  visibility:'hidden'
}}
>
<div className="flex justify-between border-b border-gray-200 pb-2 px-4 pt-2 mb-2">
  <span className="font-bold text-gray-800">INVOICE {brandInvoiceNumber}</span>
  <span
    className="text-gray-500   "
    style={{ whiteSpace: 'nowrap' }}
  >
    {formattedDate}
  </span>
</div>

<div className="space-y-2 px-2 py-2">
  {InvoiceCustomerData.map((row, index) => {
    const filteredItems = invoiceFuelData.filter(
      (item) => row.product_name === item.fuelgrade_name
    );

    return (
      <div
        key={row.product_name}
        className={`flex flex-wrap justify-between items-center rounded-lg ${colors[index % colors.length]} p-2 gap-2`}
      >
        <span className={`font-bold ${texts[index % texts.length]}`}>
          {row.product_name}
        </span>

        {filteredItems.map((filteredRow, filteredIndex) => (
          <span
            key={`quantity-${filteredIndex}`}
            className={`${texts[index % texts.length]} font-bold`}
          >
            {formatNumberWithCommas(filteredRow.gross_quantity || 0)} gl
          </span>
        ))}

        {filteredItems.map((filteredRow, filteredIndex) => (
          <span
            key={`price-${filteredIndex}`}
            className={`${texts[index % texts.length]} font-bold`}
          >
            {formatNumberWithCommas(filteredRow.unit_price || 0)} Price
          </span>
        ))}
      </div>
    );
  })}
</div>
</div>
} */}
                  </div>

                  {brandInvoiceNumber && formField.bill_to !== "" && (
                    <div className="flex justify-start items-center pl-4 gap-4 ">
                      {/* {remainingProducts.length > 0 && formField.bill_to ? (
  <select
    className="px-4 py-1 text-left w-[220px] border rounded-md  my-2 h-[35px] mx-[30px]"
    value={selectedProduct}
    onChange={(e) => {
      const selected = e.target.value;
      setSelectedProduct(selected); // Update state
      addRow(selected); // Add row and filter dropdown
    }}
  >
    <option value="">--Add Product/Service--</option>
    {remainingProducts.map((product, index) => (
      <option key={product} value={product}>
        {product}
      </option>
    ))}
  </select>
) : (
  <p className="text-green-500 ml-4"></p>
)} */}
                      <div className="text-red-500">{DiscountRebateError} </div>

                      <div className="text-red-500 ml-[20px]">
                        {MinLengthError}{" "}
                      </div>
                    </div>
                  )}

                  <div className="flex flex-col lg:flex-row p-6 pt-0 gap-4 mx-[0px]">
                    {true ? (
                      <div
                        className="lg:w-3/5 mt-4 "
                        style={{ visibility: "hidden" }}
                      >
                        <h2 className="text-lg font-semibold mb-2 pl-3 mt-2">
                          Terms & Conditions
                        </h2>
                        <textarea
                          className="w-full h-20 p-2 rounded-md focus:outline-none focus:border-none bg-gray-100 border-0"
                          placeholder="Enter terms and conditions here..."
                          value={
                            termsAndConditions === null ||
                            termsAndConditions === "null"
                              ? ""
                              : termsAndConditions
                          }
                          onChange={(e) =>
                            setTermsAndConditions(e.target.value)
                          }
                          maxLength={600}
                        ></textarea>
                      </div>
                    ) : (
                      ""
                    )}
                    {/* Right Half: Subtotal and Taxes */}
                    {subTotal && formField.bill_to !== "" ? (
                      <div className="lg:w-2/5  rounded-md p-5 px-7 pt-0 mb-[20px]">
                        {/* Subtotal Section */}
                        <div className="flex justify-between items-center mb-2">
                          <div className="flex items-center gap-2">
                            {/* <button
                              onClick={toggleSubtotal}
                              className="text-blue-500"
                            > */}
                            {/* {isSubtotalExpanded ? "▼" : "►"} */}
                            {/* </button> */}
                            {/* <span className="text-gray-800 font-medium">
                              Subtotal
                            </span> */}
                          </div>
                          {/* <span className="text-gray-800 font-medium">
                            ${" "}
                            {(
                              parseFloat(productSubTotal) +
                              parseFloat(freightCharges) +
                              parseFloat(freightsurcharge) +
                              parseFloat(waterBill) -
                              parseFloat(discount) -
                              parseFloat(Rebates) -
                              [...Array(14)].reduce(
                                (acc, _, i) =>
                                  acc + parseFloat(eval(`credit${i + 1}`) || 0),
                                0
                              )
                            ).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </span> */}
                        </div>

                        <div className="ml-4 mb-5">
                          {freightsurcharge ? (
                            <div className="flex justify-between">
                              <span>Freight Surcharges:</span>
                              <span>
                                +${" "}
                                {freightsurcharge
                                  ? freightsurcharge.toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2
                                    })
                                  : Number(0.0).toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2
                                    })}
                              </span>
                            </div>
                          ) : (
                            ""
                          )}
                          {waterBill ? (
                            <div className="flex justify-between">
                              <span>Water Bill:</span>
                              <span>
                                +${" "}
                                {waterBill
                                  ? waterBill.toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2
                                    })
                                  : Number(0.0).toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2
                                    })}
                              </span>
                            </div>
                          ) : (
                            ""
                          )}

                          {discount ? (
                            <div className="flex justify-between">
                              <span>
                                Discounts:
                                {discount &&
                                DiscountedPercentage &&
                                DiscountedPercentage > 0 &&
                                selectedDiscountType === "percentage" &&
                                selectedDiscountType !== "" ? (
                                  <span className="text-red-500 font-semibold ml-1">
                                    {discount &&
                                    DiscountedPercentage &&
                                    DiscountedPercentage > 0 &&
                                    selectedDiscountType === "percentage"
                                      ? `(${Number(
                                          DiscountedPercentage
                                        ).toLocaleString(undefined, {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2
                                        })})%`
                                      : ""}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </span>
                              <span className="text-red-500 ">
                                -${" "}
                                {discount
                                  ? discount.toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2
                                    })
                                  : Number(0.0).toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2
                                    })}
                              </span>
                            </div>
                          ) : (
                            ""
                          )}
                          {Rebates ? (
                            <div className="flex justify-between">
                              <span>Rebates:</span>
                              <span className="text-red-500">
                                -${" "}
                                {Rebates
                                  ? Rebates.toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2
                                    })
                                  : Number(0.0).toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2
                                    })}
                              </span>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="ml-6 mt-[20px]">
                          <div className="flex justify-between">
                            <span className=" font-bold font-medium">
                              Fuel Amount:
                            </span>
                            {/* <span>{totalQuantity}gl</span> */}
                            <span className="text-gray-800 font-medium">
                              ${" "}
                              {productSubTotal
                                ? Number(productSubTotal).toLocaleString(
                                    undefined,
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2
                                    }
                                  )
                                : Number(0.0).toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                  })}
                            </span>
                          </div>
                          {customerIndividualData.freigt_charge ||
                          TaxDataForInvoice.length > 0 ? (
                            <>
                              <div className="mt-1  pt-2 flex justify-between items-center font-semibold">
                                <span>Fuel Tax</span>
                                <span>
                                  ${" "}
                                  {calculateSalesTaxAmount(
                                    invoiceFuelData,
                                    InvoiceCustomerData,
                                    TaxDataForInvoice
                                  ).toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                  })}
                                </span>
                              </div>
                              <div className="mt-1  pt-2 flex justify-between items-center font-semibold">
                                <span>Freight Charges</span>
                                <span>$ {calculateFreightCharges()}</span>
                              </div>
                              <div className="mt-1 border-b border-gray-300 pt-2 flex justify-between items-center font-semibold">
                                <span className={{ whiteSpace: "nowrap" }}>
                                  Freight Surcharge
                                </span>
                                <span>$ {calculateFreightSurcharge()}</span>
                              </div>
                              <div className="mt-2 flex justify-between items-center bg-gray-100 py-1">
                                <div className="flex items-center gap-2">
                                  <span className="text-gray-800 font-bold font-[20px] ml-[1px]">
                                    Invoice Amount
                                  </span>
                                </div>
                                <span className="text-gray-800 font-bold font-medium">
                                  {/* ${" "}
                            {(() => {
                              // Calculate base fuel total
                              // Example of more robust parsing
                              const fuelTotal =
                                parseFloat(productSubTotal) || 0;
                              const additionalCharges =
                                customerIndividualData.freigt_charge
                                  ? (customerIndividualData.freigt_charge) *
                                  totalQuantity
                                  : 0;

                              // Calculate deductions
                              const deductions = 0;

                              // Calculate total taxes from all fuel types
                              let totalTaxAmount = 0;

                              // Process each fuel type to get their specific taxes
                              invoiceFuelData.forEach((fuelItem) => {
                                const fuelType =
                                  fuelItem.fuelgrade_name.toLowerCase();
                                const fuelQuantity =
                                  InvoiceCustomerData.find(
                                    (row) =>
                                      row.product_id === fuelItem.fuelgrade_id
                                  )?.quantity || 0;

                                if (fuelQuantity > 0) {
                                  // Get applicable taxes for this fuel type
                                  const fuelTaxes = TaxDataForInvoice.filter(
                                    (tax) => {
                                      const taxName =
                                        tax.po_customertax_name.toLowerCase();

                                      if (
                                        taxName.includes("diesel") &&
                                        fuelType === "diesel"
                                      ) {
                                        return true;
                                      }

                                      if (
                                        (taxName.includes("gasoline") ||
                                          !taxName.includes("diesel")) &&
                                        fuelType !== "diesel"
                                      ) {
                                        return true;
                                      }

                                      return false;
                                    }
                                  );

                                  // Add this fuel's tax total to the grand total
                                  fuelTaxes.forEach((tax) => {
                                    totalTaxAmount +=
                                      tax[tax.po_customertax_name] *
                                      fuelQuantity;
                                  });
                                }
                              });

                              // Calculate final invoice amount: Fuel Total + Additional Charges - Deductions + Taxes
                              const invoiceAmount =
                                fuelTotal +
                                additionalCharges -
                                deductions +
                                totalTaxAmount;

                              // Then format only when displaying:
                              return invoiceAmount.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              });
                            })()} */}
                                  {displayInvoiceAmount()}
                                </span>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className=" rounded-lg mb-4"></div>
                </div>
              </div>
              <div
                //  style={{   boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",}}
                className="mt-2"
              >
                {/* Left Half: Terms and Conditions */}
              </div>

              <div
                className="mx-[40px] mb-2"
                style={{ marginBottom: "50px", marginTop: "50px" }}
              >
                {/* <div className="relative">
  <h2 className="absolute left-3 top-1 text-lg font-semibold mb-2 pl-3 mt-2">Terms & Conditions</h2>

    <LightbulbOutlinedIcon 
      // fontSize="large" 
      className="absolute left-3 top-2 text-black bg-white" 
      style={{borderRadius:'50%', fontSize:'25px'}}
    />
    <textarea
      className="w-full h-12 p-2 pl-[44px] rounded-md focus:outline-none focus:border-none bg-gray-100 border-0"
      placeholder="Enter terms and conditions here..."
      value={termsAndConditions === null || termsAndConditions === "null" ? '' :termsAndConditions}
      onChange={(e) => setTermsAndConditions(e.target.value)}
      maxLength={600}
    />
  </div> */}
                {/* <div className="relative bg-gray-100 px-2 rounded-md">
                  <div className="flex items-center">
                    <LightbulbOutlinedIcon
                      className="text-black bg-white absolute left-2 top-1/2 transform -translate-y-1/2"
                      style={{ borderRadius: "50%", fontSize: "28px" }}
                    />
                    <div className="pl-8 pt-1 text-md font-semibold">
                      Terms & Conditions
                    </div>
                  </div>

                  <textarea
                    className="w-full pl-8 pt-0 pb-0 rounded-md focus:outline-none focus:ring-0 focus:border-none bg-gray-100 border-0 resize-none"
                    placeholder="Enter terms and conditions here..."
                    rows={1}
                    value={
                      termsAndConditions === null ||
                      termsAndConditions === "null"
                        ? ""
                        : termsAndConditions
                    }
                    onChange={(e) => setTermsAndConditions(e.target.value)}
                    maxLength={600}
                  />
                </div> */}
                {/* {!isOpen ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      cursor: "pointer",
                      // border: "1px solid #ccc",
                      padding: "10px"
                      // boxShadow: "0 2px 10px rgba(0, 0, 0, 0.3)"
                    }}
                    className="w-full mb-2 bg-gray-100"
                    onClick={toggleInput}
                  >
                    <span style={{ fontWeight: "bold" }}>
                      Terms & Conditions
                    </span>

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      style={{
                        transform: isOpen ? "rotate(90deg)" : "rotate(0deg)",
                        transition: "transform 0.2s ease"
                      }}
                    >
                      <polyline points="9 18 15 12 9 6"></polyline>
                    </svg>
                  </div>
                ) : (
                  <div className="relative bg-gray-100 px-2 rounded-md mb-2">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        <LightbulbOutlinedIcon
                          className="text-black bg-white absolute left-2 top-1/2 transform -translate-y-1/2"
                          style={{ borderRadius: "50%", fontSize: "28px" }}
                        />
                        <div className="pl-8 pt-1 text-md font-semibold">
                          Terms & Conditions
                        </div>
                      </div>

                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        onClick={toggleInput}
                        style={{
                          transform: isOpen ? "rotate(90deg)" : "rotate(0deg)",
                          transition: "transform 0.2s ease",
                          cursor: "pointer"
                        }}
                      >
                        <polyline points="9 18 15 12 9 6"></polyline>
                      </svg>
                    </div>

                    <textarea
                      className="w-full pl-8 pt-0 pb-0 rounded-md focus:outline-none focus:ring-0 focus:border-none bg-gray-100 border-0 resize-none"
                      placeholder="Enter terms and conditions here..."
                      rows={1}
                      value={
                        termsAndConditions === null ||
                        termsAndConditions === "null"
                          ? ""
                          : termsAndConditions
                      }
                      onChange={(e) => setTermsAndConditions(e.target.value)}
                      maxLength={600}
                    />
                  </div>
                )} */}
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-center items-center ">
          {/* footer start */}
          {/* <div style={{ border: "1px solid #ccc", padding: "10px",boxShadow: "0 2px 10px rgba(0, 0, 0, 0.3)",}}
 className=' w-[56vw] mb-[10px] '>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={toggleInput}
      >
        <span style={{ fontWeight: "bold", color: "#25caed" }}>Footer</span>
        <span
          style={{
            transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
            transition: "transform 0.2s ease",
          }}
        >
          ▼
        </span>
      </div>
      {isOpen && (
        <div style={{ marginTop: "10px" }}>
          <input
            type="text"
            placeholder="Enter text..."
            className='bg-gray-200 ml-[20px]'
            value={footer}
            onChange={(e)=> {setFooter(e.target.value)}}
            style={{
              width: "96%",
              padding: "5px",
              border: "none",
              borderRadius: "4px",
            }}
          />
        </div>
      )}
    </div> */}
          {/* footer ends */}
        </div>
        <div className="flex justify-center items-center ">
          {/* { isFormComplete() && (
              <div className="flex justify-end mr-[0px] pb-[30px] mt-[20px] w-[58vw] " >
                <button
                 onClick={handleSubmit}
                  type="submit"
                  // className=" mb-4 mr-[80px] w-[140px] h-[35px] mt-4 mr-2hover:bg-yellow-100 bg-yellow-100 border-1 border-yellow-300 border-2 text-black px-6 py-1.5 text-normal font-bold  hover:shadow-lg hover:shadow-[#0044AB]/50 text-center  focus:ring-4 focus:ring-primary-200 "
                  // disabled={!isFormValid}
                  className='w-40 px-2 px-12 py-1 border-2 
                  border-[#000080] hover:font-bold rounded-md hover:bg-blue-700 
                  hover:shadow-blue-700/50 uppercase duration-200 hover:text-white 
                  hover:shadow-lg hover:border-transparent active:scale-90 "
'
                >
                  SUBMIT
                </button>
              </div>
            )}     */}

          <div
            className="relative w-[60vw] bg-white rounded-lg space-y-0  mt-2 mb-[20px] p-5"
            style={{ boxShadow: "0 2px 10px rgba(0, 0, 0, 0.3)" }}
          >
            {!isOpen ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  cursor: "pointer",
                  // border: "1px solid #ccc",
                  padding: "10px"
                  // boxShadow: "0 2px 10px rgba(0, 0, 0, 0.3)"
                }}
                className="w-full bg-gray-100"
                onClick={toggleInput}
              >
                <span style={{ fontWeight: "bold" }}>Terms & Conditions</span>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  style={{
                    transform: isOpen ? "rotate(90deg)" : "rotate(0deg)",
                    transition: "transform 0.2s ease"
                  }}
                >
                  <polyline points="9 18 15 12 9 6"></polyline>
                </svg>
              </div>
            ) : (
              <div className="relative bg-gray-100 px-2 rounded-md mb-2">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <LightbulbOutlinedIcon
                      className="text-black bg-white absolute left-2 top-1/2 transform -translate-y-1/2"
                      style={{ borderRadius: "50%", fontSize: "28px" }}
                    />
                    <div className="pl-8 pt-1 text-md font-semibold">
                      Terms & Conditions
                    </div>
                  </div>

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    onClick={toggleInput}
                    style={{
                      transform: isOpen ? "rotate(90deg)" : "rotate(0deg)",
                      transition: "transform 0.2s ease",
                      cursor: "pointer"
                    }}
                  >
                    <polyline points="9 18 15 12 9 6"></polyline>
                  </svg>
                </div>

                <textarea
                  className="w-full pl-8 pt-0 pb-0 rounded-md focus:outline-none focus:ring-0 focus:border-none bg-gray-100 border-0 resize-none"
                  placeholder="Enter terms and conditions here..."
                  rows={1}
                  value={
                    termsAndConditions === null || termsAndConditions === "null"
                      ? ""
                      : termsAndConditions
                  }
                  onChange={(e) => setTermsAndConditions(e.target.value)}
                  maxLength={600}
                />
              </div>
            )}
          </div>
        </div>
        {/* <div className="flex justify-center items-center "> */}
        <div className="flex justify-center items-center ">
          <div
            className="relative w-[60vw] bg-white rounded-lg space-y-0  mt-2 mb-[20px] p-5"
            style={{ boxShadow: "0 2px 10px rgba(0, 0, 0, 0.3)" }}
          >
            <div className="mx-0">
              {!isOpen1 ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    cursor: "pointer",
                    // border: "1px solid #ccc",
                    padding: "10px"
                    // boxShadow: "0 2px 10px rgba(0, 0, 0, 0.3)"
                  }}
                  className="w-full mb-0 bg-gray-100"
                  onClick={toggleInput1}
                >
                  <span style={{ fontWeight: "bold" }}>Memo & Notes</span>

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    style={{
                      transform: isOpen1 ? "rotate(90deg)" : "rotate(0deg)",
                      transition: "transform 0.2s ease"
                    }}
                  >
                    <polyline points="9 18 15 12 9 6"></polyline>
                  </svg>
                </div>
              ) : (
                <div className="relative bg-gray-100 px-2 rounded-md mb-2">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <NoteOutlinedIcon
                        className="text-black bg-white absolute left-2 top-1/2 transform -translate-y-1/2"
                        style={{ borderRadius: "50%", fontSize: "16px" }}
                      />
                      <div className="pl-8 pt-1 text-md font-semibold">
                        Memo & Notes
                      </div>
                    </div>

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      onClick={toggleInput1}
                      style={{
                        transform: isOpen1 ? "rotate(90deg)" : "rotate(0deg)",
                        transition: "transform 0.2s ease",
                        cursor: "pointer"
                      }}
                    >
                      <polyline points="9 18 15 12 9 6"></polyline>
                    </svg>
                  </div>

                  <textarea
                    className="w-full pl-8 pt-0 pb-0 rounded-md focus:outline-none focus:ring-0 focus:border-none bg-gray-100 border-0 resize-none"
                    placeholder="Enter Memo and Notes here..."
                    rows={1}
                    value={
                      termsAndConditions1 === null ||
                      termsAndConditions1 === "null"
                        ? ""
                        : termsAndConditions1
                    }
                    onChange={(e) => setTermsAndConditions1(e.target.value)}
                    maxLength={600}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        {/* </div> */}
      </form>
    </>
  );
};

export default InvoiceFormTwo;

import React, { useState, useEffect } from 'react';
import { FaRegComment, FaCamera, FaCloudUploadAlt, FaChevronLeft, FaChevronRight, FaPlus } from 'react-icons/fa';
import { DatePicker, Space } from "antd";
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import CustomCommonTab from "../reusable/CustomCommonTab";
import { FaHome } from "react-icons/fa";
import axios from 'axios';
import config from "../../config/config";
const ExpenseForm = () => {
  const navigate = useNavigate();
  const handleSubmitSuccess = (validRows) => {
    const dateKey = date.toISOString().split('T')[0];
    const updatedExpenses = {
      ...savedExpenses,
      [dateKey]: validRows
    };
   
    // Set flag to prevent adding more rows in this session
    setHasSubmittedToday(true);
    
    // Set flag to indicate we now have data for this date
    setHasExistingData(true);
    
    // Update both the savedExpenses and the rows state with valid rows
    setSavedExpenses(updatedExpenses);
    setRows(validRows);
    
    // Optionally store in localStorage if needed
    // localStorage.setItem('savedExpenses', JSON.stringify(updatedExpenses));
  };
  const [date, setDate] = useState(new Date());
  const [rows, setRows] = useState([{ 
    expense: '', 
    paymentType: '2', // Set default payment type to Check
    amount: '',
    rawAmount: '',
    checkNumber: '',
    invoiceNumber: '',
    comment: '',
    showComment: false,
    hasAttachment: false
  }]);

  // Flag to track if user has submitted in the current session
  const [hasSubmittedToday, setHasSubmittedToday] = useState(false);

  // Flag to track if a row was recently added via the + button
  const [recentlyAddedRow, setRecentlyAddedRow] = useState(false);

  // const [savedExpenses, setSavedExpenses] = useState(() => {
  //   // Load saved expenses from localStorage on component mount
  //   const savedData = localStorage.getItem('savedExpenses');
  //   return savedData ? JSON.parse(savedData) : {};
  // });
  const [savedExpenses, setSavedExpenses] = useState( {});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);
  const [files, setFiles] = useState({});
  // const expenseOptions = [
  //   'Meals', 'Travel', 'Office Supplies', 'Utilities', 'Rent', 'Other'
  // ];
  const baseURL = process.env.NODE_ENV === "production"
    ? config.production.baseURL
    : config.development.baseURL;

  // State for API data
  const [expenseOptions, setExpenseOptions] = useState([]);
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // const paymentOptions = [
  //   'Cash', 'Check', 'Card', 'CTX/Bank EFT', 'Write Off', 'By Phone'
  // ];

  // Define which payment types are considered cash and non-cash
  const cashPaymentTypes = ['Cash', 'Write Off'];
  const nonCashPaymentTypes = ['Check', 'Card', 'CTX/Bank EFT', 'By Phone'];

  // API functions
  const api = {
    submitExpenses: async (expenseData) => {
      // Simulating API call with a promise
      return new Promise((resolve, reject) => {
        console.log('Submitting to API:', expenseData);
        
        // Simulate network delay
        setTimeout(() => {
          // Simulate 90% success rate
          if (Math.random() > 0.1) {
            resolve({
              success: true,
              message: 'Expenses submitted successfully',
              transactionId: 'TXN-' + Math.floor(Math.random() * 1000000),
              timestamp: new Date().toISOString(),
              data: {
                processedExpenses: expenseData.expenses.length,
                totalAmount: expenseData.totalAmount
              }
            });
          } else {
            reject({
              success: false,
              message: 'Failed to submit expenses',
              errorCode: 'ERR-' + Math.floor(Math.random() * 1000)
            });
          }
        }, 1500); // 1.5 second delay to simulate network
      });
    }
  };
  const businessDate = localStorage.getItem('business_date')
  const today = dayjs(); // Get today's date
  const disabledDate = (current) => {
    // Convert businessDate to dayjs object if it's not already
    const businessDateObj = typeof businessDate === 'string' 
      ? dayjs(businessDate) 
      : dayjs(businessDate);
    
    // Disable dates before businessDate or after today
    return (
      current.isBefore(businessDateObj, 'day') || 
      current.isAfter(today, 'day')
    );
  };
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        // Fetch expense options
        const expensesResponse = await axios.get(`${baseURL}${"ZXhwZW5zZXMvY3VzdG9tZXJfZXhwZW5zZXNsaXN0"}`);
        if (expensesResponse.data && expensesResponse.data.expenses_list) {
          setExpenseOptions(expensesResponse.data.expenses_list);
        }

        // Fetch payment types
        const paymentTypesResponse = await axios.get(`${baseURL}${"ZXhwZW5zZXMvZXhwZW5zZXNfcGF5bWVudHR5cGVz"}`);
        if (paymentTypesResponse.data && paymentTypesResponse.data.payment_types) {
          setPaymentOptions(paymentTypesResponse.data.payment_types);
          
          // Update default payment type in initial row
          setRows(currentRows => {
            const updatedRows = [...currentRows];
            const defaultPaymentType = paymentTypesResponse.data.payment_types.find(type => type.id === '2');
            if (defaultPaymentType && updatedRows[0]) {
              updatedRows[0].paymentType = defaultPaymentType.id;
              updatedRows[0].paymentTypeLabel = defaultPaymentType.payment_type;
            }
            return updatedRows;
          });
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setSubmitStatus({
          type: 'error',
          message: 'Failed to load expense options. Please refresh the page.'
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [baseURL]);
  // Reset the submission flag when date changes
  useEffect(() => {
    setHasSubmittedToday(false);
    setRecentlyAddedRow(false); // Reset the recently added row flag when date changes
  }, [date]);

  // Check if we have saved expenses for the current date
  useEffect(() => {
    const dateKey = date.toISOString().split('T')[0];
    
    const savedDataFromStorage = localStorage.getItem('savedExpenses');
    const parsedSavedData = savedDataFromStorage ? JSON.parse(savedDataFromStorage) : {};
    
    // If we have data for this specific date
    if (parsedSavedData[dateKey] && parsedSavedData[dateKey].length > 0) {
      // Load saved data for this date from localStorage
      setRows(parsedSavedData[dateKey]);
      // Flag that we have existing data for this date
      setHasExistingData(true);
      console.log(`Loaded ${parsedSavedData[dateKey].length} rows for ${dateKey}`);
    } else {
      // If no saved expenses for this date, start with one empty row with Check as default payment type
      setRows([{ 
        expense: '', 
        paymentType: '2', // Change to ID '2' for Check (instead of string 'Check')
        amount: '',
        rawAmount: '',
        checkNumber: '',
        invoiceNumber: '',
        comment: '',
        showComment: false,
        hasAttachment: false
      }]);
      // Flag that we don't have existing data (use auto-add on focus)
      setHasExistingData(false);
      console.log(`No saved data for ${dateKey}, starting with empty row`);
    }
  }, [date]);

  const handleDateChange = (newDate) => {
    if (newDate) {
      setDate(newDate.toDate());
    }
  };

  const navigateDate = (direction) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + (direction === 'prev' ? -1 : 1));
    setDate(newDate);
  };

  // Format a number as currency with 2 decimal places
  const formatCurrency = (value) => {
    if (value === '') return '';
    
    // Convert to number and ensure it's valid
    const num = parseFloat(value);
    if (isNaN(num)) return '';
    
    // Format with 2 decimal places
    return num.toFixed(2);
  };

  // Handle amount change with proper validation and real-time formatting
  const handleAmountChange = (index, value) => {
    // Remove all commas first to get a clean starting point
    let rawValue = value.replace(/,/g, '');
    
    // Remove any non-numeric characters except decimal point
    let cleanValue = rawValue.replace(/[^0-9.]/g, '');
    
    // Handle decimal points - only allow one
    const parts = cleanValue.split('.');
    if (parts.length > 2) {
      cleanValue = parts[0] + '.' + parts.slice(1).join('');
    }
    
    // Prevent leading zeros except for decimal values less than 1
    if (cleanValue.length > 1 && cleanValue[0] === '0' && cleanValue[1] !== '.') {
      cleanValue = cleanValue.substring(1);
    }
    
    // Limit to 2 decimal places
    if (parts.length > 1 && parts[1].length > 2) {
      cleanValue = parts[0] + '.' + parts[1].substring(0, 2);
    }
    
    // Format with commas for display
    let formattedValue = cleanValue;
    if (cleanValue && !isNaN(parseFloat(cleanValue))) {
      // Only format the whole number part with commas
      if (parts.length > 1) {
        // Has decimal part
        const wholeNumber = parseInt(parts[0]).toLocaleString('en-US');
        formattedValue = wholeNumber + '.' + parts[1];
      } else {
        // No decimal part
        formattedValue = parseInt(cleanValue) ? parseInt(cleanValue).toLocaleString('en-US') : cleanValue;
      }
    }
    
    // Update the row with formatted value
    updateRow(index, 'amount', formattedValue);
  };

  // Store both displayed and raw values for amount
  const updateRow = (index, field, value) => {
    const newRows = [...rows];
    
    if (field === 'amount') {
      // For amount field, store the raw number separately for calculations
      const rawValue = value.replace(/,/g, '');
      newRows[index] = { 
        ...newRows[index], 
        amount: value,
        rawAmount: rawValue 
      };
    } else {
      newRows[index] = { ...newRows[index], [field]: value };
    }
    
    setRows(newRows);
    
    // Filter out empty rows that don't have both expense type and amount
    const validRows = newRows.filter(row => {
      return row.expense && row.rawAmount && parseFloat(row.rawAmount) > 0;
    });

    const dateKey = date.toISOString().split('T')[0];
    // const currentStorage = localStorage.getItem('savedExpenses');
    // const parsedStorage = currentStorage ? JSON.parse(currentStorage) : {};

    // Only update valid rows in localStorage
    // localStorage.setItem('savedExpenses', JSON.stringify({
    //   ...parsedStorage,
    //   [dateKey]: validRows.length > 0 ? newRows : []
    // }));
  };

  // Track if we have existing data for the current date
  const [hasExistingData, setHasExistingData] = useState(false);

  // Handle focus on checkNumber or invoiceNumber
  const handleFieldFocus = (index, field) => {
    // If we just added a row via the + button, don't auto-add another row
    if (recentlyAddedRow) {
      return;
    }
    
    // Only use auto-row addition if:
    // 1. There's no existing data for this date
    // 2. This is the last row
    // 3. We're under the 5 row limit for first-time entries
    // 4. User hasn't submitted in this session
    // 5. It's a focus on checkNumber or invoiceNumber field
    if (!hasExistingData && index === rows.length - 1 && rows.length < 5 && !hasSubmittedToday) {
      const currentRow = rows[index];
      
      // Only add a new row if the current row has expense type, payment method, and amount filled in
      if (currentRow.expense && currentRow.paymentType && currentRow.amount) {
        console.log('Auto-adding new row on focus (no existing data scenario)');
        addNewRow();
      }
    }
  };

  const addNewRow = () => {
    // Only allow adding rows if:
    // 1. If no existing data, limit to 5 rows 
    // 2. If existing data, no row limit applies
    // 3. User hasn't submitted in this session OR date has submitted data
    // 4. We haven't recently added a row via + button
    const rowLimit = !hasExistingData ? 5 : Number.MAX_SAFE_INTEGER;
    
    if ((rows.length < rowLimit || hasSubmittedToday) && !recentlyAddedRow) {
      const newRows = [...rows];
      newRows.push({ 
        expense: '', 
        paymentType: '2', // Change to ID '2' for Check (instead of string 'Check')
        amount: '',
        rawAmount: '',
        checkNumber: '',
        invoiceNumber: '',
        comment: '',
        showComment: false,
        hasAttachment: false
      });
      setRows(newRows);
      setRecentlyAddedRow(true);
    }
  };
  

  const toggleComment = (index) => {
    const newRows = [...rows];
    // Only toggle comment visibility (don't open comment automatically)
    newRows[index].showComment = !newRows[index].showComment;
    setRows(newRows);
  };

  const toggleAttachment = (index) => {
    const newRows = [...rows];
    newRows[index].hasAttachment = !newRows[index].hasAttachment;
    setRows(newRows);
  };

  const handleFileChange = (index, event) => {
    if (event.target.files && event.target.files[0]) {
      setFiles({
        ...files,
        [index]: event.target.files[0]
      });
      
      // Also mark that this row has an attachment
      const newRows = [...rows];
      newRows[index].hasAttachment = true;
      setRows(newRows);
    }
  };

  const isRowValid = (row) => {
    // Use rawAmount for validation to avoid issues with commas
    const amountNum = parseFloat(row.rawAmount || '0');
    const validAmount = !isNaN(amountNum) && amountNum > 0;
    
    return row.expense && row.paymentType && validAmount;
  };

  // Format amount for display in the UI
  const displayAmount = (amount) => {
    if (!amount) return '';
    
    const num = parseFloat(amount);
    if (isNaN(num)) return '';
    
    return num.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  };


const getTotals = () => {
  let cashTotal = 0;
  let nonCashTotal = 0;
  
  // Define cash payment type IDs - update these with actual IDs from your API
  const cashPaymentTypeIds = ['1', '4']; // Example: '1' for Cash, '4' for Write Off
  
  rows.forEach(row => {
    if (row.expense && row.paymentType && row.rawAmount) {
      const amount = parseFloat(row.rawAmount) || 0;
      
      // Check if the payment type ID is in the cash payment types array
      if (cashPaymentTypeIds.includes(row.paymentType)) {
        cashTotal += amount;
      } else {
        nonCashTotal += amount;
      }
    }
  });
  
  const total = cashTotal + nonCashTotal;
  
  return {
    cashTotal: cashTotal.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }),
    nonCashTotal: nonCashTotal.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }),
    total: total.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  };
};
  // Handle user input on any field
  const handleInputChange = (index, field, value) => {
    updateRow(index, field, value);
    
    // If user enters something in the newly added row, allow adding another row
    if (recentlyAddedRow && index === rows.length - 1) {
      setRecentlyAddedRow(false);
    }
  };


  const handleSubmit = async () => {
    // Filter out empty rows
    const validRows = rows.filter(row => isRowValid(row));
    
    if (validRows.length === 0) {
      setSubmitStatus({
        type: 'error',
        message: 'Please add at least one valid expense entry'
      });
      return;
    }
    
    // Show submitting state
    setIsSubmitting(true);
    setSubmitStatus({ type: 'info', message: 'Submitting expenses...' });
    
    try {
      // Prepare form data for API
      const formData = new FormData();
      
      // Add business and user info
      formData.append('businessId', localStorage.getItem('business_id')); // Replace with actual business ID from context/props
      formData.append('userId', localStorage.getItem('user_id')); // Replace with actual user ID from auth context
      formData.append('userRole', localStorage.getItem('user_role')); // Replace with actual user role from auth context
      formData.append('select_date', date.toISOString().split('T')[0]);
      
      // Add expense data
      validRows.forEach((row, index) => {
        formData.append(`expenses_name[${index}]`, row.expense);
        formData.append(`payment_type[${index}]`, row.paymentType);
        formData.append(`expenses_amount[${index}]`, row.rawAmount);
        formData.append(`cheque_number[${index}]`, row.checkNumber || '');
        formData.append(`invoice_number[${index}]`, row.invoiceNumber || '');
        formData.append(`comment[${index}]`, row.comment || '');
        
        // Add attachment if exists
        if (row.hasAttachment && files[index]) {
          formData.append(`attachment[${index}]`, files[index]);
        }
      });
      
      // Call the API
      const response = await axios.post(
        `${baseURL}${"ZXhwZW5zZXMvY3JlYXRlc3RhdGVtZW50"}`, 
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );
      
      // Handle success
      if (response.data && response.data.success) {
        setSubmitStatus({ 
          type: 'success', 
          message: `Successfully submitted ${validRows.length} expense entries!` 
        });
        
        // Update saved expenses
        handleSubmitSuccess(validRows);
        
        // Reset form after delay
        setTimeout(() => {
          setSubmitStatus(null);
          navigate('/OperationsScreen');
        }, 2000);
      } else {
        throw new Error(response.data.message || 'Failed to submit expenses');
      }
      
    } catch (error) {
      console.error('Error submitting expenses:', error);
      
      // Handle error
      setSubmitStatus({ 
        type: 'error', 
        message: error.message || 'Failed to submit expenses. Please try again.' 
      });
    } finally {
      setIsSubmitting(false);
    }
    navigate('/OperationsScreen')
  };


  const handleCancel = () => {
    // Reset form to initial state
    setRows([{ 
      expense: '', 
      paymentType: '2', // Default payment type ID is Check
      paymentTypeLabel: 'Check', // For display
      amount: '',
      rawAmount: '',
      checkNumber: '',
      invoiceNumber: '',
      comment: '',
      showComment: false,
      hasAttachment: false
    }]);
    setFiles({});
    setDate(new Date());
    navigate('/OperationsScreen');
  };

  const [activeItem, setActiveItem] = useState("View");
  const handleMenuClick = (item) => {
    setActiveItem(item);
  };

  const menuItems = [{ name: "New Expenses" }];

  // Check if a row is from existing data (should be read-only/greyed out)
  const isExistingRow = (index) => {
    return hasExistingData && index < rows.length - 1;
  };

  // Determine if plus button should be shown
  const shouldShowPlusButton = (index) => {
    // Show plus button if:
    // 1. This is the last row
    // 2. We haven't recently added a row
    // 3. If no existing data, limit to 5 rows ONLY for first-time entries
    // 4. If date has submitted data, no row limit applies
    
    const isLastRow = index === rows.length - 1;
    
    if (!isLastRow) return false;
    if (recentlyAddedRow) return false;
    
    // If this date has submitted data, always show plus button on last row
    if (hasSubmittedToday) return true;
    
    // If no existing data, apply 5 row limit
    if (!hasExistingData && rows.length >= 5) return false;
    
    return true;
  };

  return (
    <>
     <CustomCommonTab items={menuItems} />
    <div className="p-6 max-w-6xl mx-auto bg-white shadow-lg rounded-lg border border-gray-100 relative">
      {/* Navigation Tabs */}
         <div className="flex items-center justify-end">
                     {/* Home button - bordered/unfilled by default, filled blue on hover */}
                     <div
                       className="absolute -top-4 flex items-center justify-center cursor-pointer rounded-full bg-[#cefafe] hover:bg-[#25caed] w-9 h-9 mb-0 mr-3 border-[0.5px] border-[#25caed] hover:border-[#25caed] hover:scale-110 shadow-md transition-all duration-200 group"
                       onClick={() => {
                         navigate("/");
                       }}
                       style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
                     >
                       <FaHome className="text-[#25caed] group-hover:text-white transition-colors duration-200" size={16}  />
                     </div>
               
                     {/* Close Icon - bordered/unfilled by default, filled red on hover */}
                     <div
                       className="absolute -top-4 -right-2 bg-red-200 hover:bg-red-500 w-9 h-9 flex items-center justify-center rounded-full cursor-pointer hover:bg-red-700 border-[0.5px] border-red-500 hover:border-red-700 hover:scale-110 shadow-md transition-all duration-200 group"
                       onClick={() => navigate('/OperationsScreen')}
                     >
                       <span className="text-red-500 group-hover:text-white text-xl font-bold mb-1.5 transition-colors duration-200">&times;</span>
                     </div>
                   </div>

      {/* Date Picker with Navigation and Totals */}
      <div className="flex items-center justify-between p-4 rounded-lg shadow-sm">
        {/* Left side totals */}
        <div className="flex gap-6 mr-4 items-end">
          <div className="text-sm font-medium flex flex-col justify-start">
            <span className="text-green-600">Cash :</span> 
            <span className="">$ {getTotals().cashTotal}</span>
          </div>
          <div className="text-sm font-medium flex flex-col justify-start">
            <span className="text-blue-600">Non-Cash :</span> 
            <span className="">$ {getTotals().nonCashTotal}</span>
          </div>
        </div>

        {/* Date Navigation */}
        <div className="flex items-center">        
          <Space direction="vertical" style={{ height: "100%" }} className="mx-4">
            <DatePicker
              format="MM-DD-YYYY"
              views={["year", "month", "day"]}
              value={dayjs(date)}
              onChange={handleDateChange}
              className="w-40 h-10 border-blue-200 focus:border-blue-500"
              placeholder="Select Date"
              disabledDate={disabledDate}

            />
          </Space>
        </div>

        {/* Right side total */}
        <div className="flex flex-col ml-4 items-start">
          <div className="text-sm font-medium flex flex-col justify-start">
            <span className="text-gray-700">Totals:</span> 
            <span className=" font-bold">$ {getTotals().total}</span>
          </div>
        </div>
      </div>

      {/* Expense List Header */}
      <div className="grid grid-cols-12 gap-4 mb-2 px-4 font-semibold py-3 rounded-t-lg">
        <div className="col-span-3">Expenses Name</div>
        <div className="col-span-2">Payment Type</div>
        <div className="col-span-2">Amount</div>
        <div className="col-span-2">Check #</div>
        <div className="col-span-3">Invoice # </div>
      </div>

      {/* Expense Rows */}
      <div className="mb-4 border border-gray-200 rounded-b-lg">
        {rows.map((row, index) => (
          <>
                   { console.log(row, 'row')}

          <div key={index} className={`px-4 py-3 border-b last:border-b-0 ${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'} ${isExistingRow(index) ? 'bg-gray-100' : ''} transition-colors`}>
            <div className="grid grid-cols-12 gap-4 items-center">
              {/* Expense Type Dropdown */}
              <div className="col-span-3">
              <select
                  value={row.expense}
                  onChange={(e) => {
                    handleInputChange(index, 'expense', e.target.value);
                  }}
                  className={`w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-300 focus:border-blue-300 transition-all ${isExistingRow(index) ? 'bg-gray-100 cursor-not-allowed' : ''}`}
                  disabled={isExistingRow(index)}
                >
                  <option value="">Select Type</option>
                  {expenseOptions.map(option => (
                    <option key={option.id} value={option.expenses_name}>{option.expenses_name}</option>
                  ))}
                </select>
              </div>

              <div className="col-span-2">
              <select
                  value={row.paymentType}
                  onChange={(e) => {
                    handleInputChange(index, 'paymentType', e.target.value);
                  }}
                  disabled={!row.expense || isExistingRow(index)}
                  className={`w-full p-2 border border-gray-300 rounded-md disabled:bg-gray-100 focus:ring-2 focus:ring-blue-300 focus:border-blue-300 transition-all ${isExistingRow(index) ? 'cursor-not-allowed' : ''}`}
                >
                  {paymentOptions.map(option => (
                    <option key={option.id} value={option.id}>{option.payment_type}</option>
                  ))}
                </select>
              </div>

              {/* Amount Input */}
              <div className="col-span-2">
                <div className="relative">
                  <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500">$</span>
                  <input
                    type="text"
                    placeholder="0.00"
                    value={row.amount}
                    onChange={(e) => {
                      handleAmountChange(index, e.target.value);
                      // If user enters something in the newly added row, allow adding another row
                      if (recentlyAddedRow && index === rows.length - 1) {
                        setRecentlyAddedRow(false);
                      }
                    }}
                    onBlur={() => {
                      // Format with 2 decimal places and commas on blur
                      if (row.rawAmount) {
                        const num = parseFloat(row.rawAmount);
                        if (!isNaN(num)) {
                          // Format with both commas and 2 decimal places
                          const formatted = num.toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          });
                          updateRow(index, 'amount', formatted);
                        }
                      }
                    }}
                    disabled={!row.expense || !row.paymentType || isExistingRow(index)}
                    className={`w-full p-2 pl-8 border border-gray-300 rounded-md disabled:bg-gray-100 focus:ring-2 focus:ring-blue-300 focus:border-blue-300 transition-all ${isExistingRow(index) ? 'cursor-not-allowed' : ''}`}
                  />
                </div>
              </div>

              {/* Check Number */}
              <div className="col-span-2">
              <input
    type="text"
    placeholder="Check #"
    value={row.checkNumber}
    onChange={(e) => {
      const newValue = e.target.value.replace(/[^0-9]/g, '');
      handleInputChange(index, 'checkNumber', newValue);
    }}
    onFocus={() => handleFieldFocus(index, 'checkNumber')}
    disabled={
      !row.expense || 
      !row.paymentType || 
      !row.amount || 
      // Use explicit comparison to disable for specific payment types
      row.paymentType === '1' || // Cash
      row.paymentType === '4' || // Write Off
      row.paymentType === '3' || // Card
      row.paymentType === '6' || // By Phone
      isExistingRow(index)
    }
    className={`w-full p-2 border border-gray-300 rounded-md disabled:bg-gray-100 focus:ring-2 focus:ring-blue-300 focus:border-blue-300 transition-all ${isExistingRow(index) ? 'cursor-not-allowed' : ''}`}
  />
              </div>

              {/* Invoice Number with Action Buttons */}
              <div className="col-span-3">
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Invoice #"
                    value={row.invoiceNumber}
                    onChange={(e) => {
                      const newValue = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
                      handleInputChange(index, 'invoiceNumber', newValue);
                    }}
                    onFocus={() => handleFieldFocus(index, 'invoiceNumber')}
                    disabled={!row.expense || !row.paymentType || !row.amount || isExistingRow(index)}
                    className={`w-full p-2 border border-gray-300 rounded-md disabled:bg-gray-100 pr-28 focus:ring-2 focus:ring-blue-300 focus:border-blue-300 transition-all ${isExistingRow(index) ? 'cursor-not-allowed' : ''}`}
                  />
                  <div className="absolute right-2 top-1/2 transform -translate-y-1/2 flex space-x-2">
                    <button 
                      onClick={() => toggleComment(index)}
                      className={`p-1 rounded-full ${row.showComment ? 'text-blue-500 bg-blue-100' : 'text-gray-500 hover:text-blue-500 '} transition-colors`}
                      title="Add Comment"
                      disabled={!row.expense || !row.paymentType || !row.amount || isExistingRow(index)}
                    >
                      <FaRegComment size={16} />
                    </button>
                    {row.paymentType === "1" && ( // Change from "Cash" to "1"
  <button 
    // onClick={() => toggleAttachment(index)}
    className={`p-1 rounded-full ${row.hasAttachment ? 'text-blue-500 bg-blue-100' : 'text-gray-500 hover:text-blue-500 '} transition-colors`}
    title="Take Photo"
    disabled={!row.expense || !row.paymentType || !row.amount || isExistingRow(index)}
  >
    <FaCamera size={16} />
  </button>
)}
                    
                    {shouldShowPlusButton(index) && hasExistingData && (
                      <button 
                        onClick={addNewRow}
                        className="p-1 rounded-full text-green-500 hover:bg-green-50 transition-colors"
                        title="Add New Row"
                      >
                        <FaPlus size={16} />
                      </button>
                    )}
                  </div>
              </div>
            </div>
          </div>

          {/* Comment Row - Updated UI style */}
          {row.showComment && (
            <div className="mt-2 border-t border-gray-200 pt-2">
              <div className="flex items-center">
                <span className="font-medium text-gray-700 mr-2">Comments:</span>
                <input
  type="text"
  placeholder="Add a comment"
  value={row.comment}
  onChange={(e) => updateRow(index, 'comment', e.target.value)}
  disabled={isExistingRow(index)}
  className={`flex-1 p-2 border-b border-t-0 border-l-0 border-r-0 border-gray-300 
    focus:outline-none focus:ring-0 focus:border-gray-300 
    transition-colors ${isExistingRow(index) ? 'bg-gray-100 cursor-not-allowed' : ''}`}
/>
              </div>
            </div>
          )}

          {/* Attachment Preview */}
          {row.hasAttachment && (
            <div className="mt-2 pl-2 ml-0 mr-0 border-l-4 border-blue-300 py-2">
              <div className="bg-gray-100 p-2 rounded-md flex items-center">
                <div className="w-10 h-10 bg-gray-300 rounded-md flex items-center justify-center">
                  <FaCamera size={16} className="text-gray-600" />
                </div>
                <span className="ml-2 text-sm text-gray-600">
                  {files[index] ? files[index].name : 'Attachment ready to upload'}
                </span>
              </div>
            </div>
          )}
        </div>
        </>

      ))}
    </div>

    {/* Submit and Cancel Buttons */}
    <div className="flex justify-end space-x-4 mt-6">
      <button 
        className="px-6 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 transition-colors focus:outline-none focus:ring-2 focus:ring-gray-300"
        onClick={handleCancel}
        disabled={isSubmitting}
      >
        Cancel
      </button>
      <button 
        className="px-6 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-300 disabled:opacity-50 disabled:cursor-not-allowed flex items-center"
        onClick={handleSubmit}
        disabled={!rows.some(isRowValid) || isSubmitting}
      >
        {isSubmitting ? (
          <>
            <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            Processing...
          </>
        ) : (
          'Submit'
        )}
      </button>
    </div>
  </div>
  </>
);
};

export default ExpenseForm;
import React, { useState, useEffect } from 'react';
import { LineChart, Line, BarChart, Bar, PieChart, Pie, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Brush } from 'recharts';
import { ChevronDown, ChevronUp, DollarSign, BarChart2, BarChart4, TrendingUp, Activity, ShoppingCart, Users, ArrowUp, ArrowDown } from 'lucide-react';
import axios from 'axios'; // Uncomment when API integration is ready
import SuperAdminHome from "../Admin/SuperAdminHome";
import ScrollingMessage from "./ScrollingMessage";
import CustomerPortalHome from '../CustomerPortal/CustomerPortalHome'
import { DatePicker, Space } from "antd";
import { useNavigate } from "react-router-dom";
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import PieChartIcon from '@mui/icons-material/PieChart';
import BarChartIcon from '@mui/icons-material/BarChart';
import { Droplets, Calendar } from 'lucide-react';
import config from "../../config/config"
import { MdFilterList } from "react-icons/md";
import FullScreenLoader from './FullScreenLoader';
import {
  FaChevronLeft,
  FaChevronRight,

} from "react-icons/fa";
import TextField from "@mui/material/TextField"; // Make sure this import is at the top
import moment from 'moment';
import dayjs from "dayjs";
import useScrollToTop from '../../Utility/Scroll';
const Home = () => {
  // States
  const [activeTab, setActiveTab] = useState('Purchases');
  const [showFilter, setShowFilter] = useState(false);
  const [brandId, setBrandId] = useState('');
  const [periodType, setPeriodType] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedQuarter, setSelectedQuarter] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dateRange, setDateRange] = useState('Last 30 days');
  const [chartType, setChartType] = useState('area');
  const [showPriceTooltip, setShowPriceTooltip] = useState(false);
  const [visibleActivities, setVisibleActivities] = useState(5);
  const [hoveredCard, setHoveredCard] = useState(null);
  const [pieSegmentCount, setPieSegmentCount] = useState(4);
  const [userRole, setUserRole] = useState("");

  const navigate = useNavigate();
  const BUSINESS_START_DATE = dayjs(localStorage.getItem('business_date'));
  
  const [selectedDate, setSelectedDate] = useState(dayjs());
  
  const isAtBusinessMonth = selectedDate.isSame(BUSINESS_START_DATE, 'month');
  const isAtCurrentMonth = selectedDate.isSame(dayjs(), 'month');
  const [isLoading, setIsLoading] = useState(false);

  const handlePrevMonth = () => {
    if (!isAtBusinessMonth) {
      setSelectedDate(selectedDate.clone().subtract(1, 'month'));
    }
  };
  
  const handleNextMonth = () => {
    if (!isAtCurrentMonth) {
      setSelectedDate(selectedDate.clone().add(1, 'month'));
    }
  };
  
  const disabledDate = (current) => {
    if (!current) return false;
    
    const tooEarly = current.isBefore(BUSINESS_START_DATE, 'month');
    
    const tooLate = current.isAfter(dayjs(), 'month');
    
    return tooEarly || tooLate;
  };
  
  // Handle date change from the DatePicker
  const handleDateChange = (date) => {
    if (date) {
      setSelectedDate(date);
      // You might want to fetch data here based on the new date
      // fetchDashboardData();
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    console.log('Selected date:', selectedDate.format('MMMM YYYY'));
    console.log('Is at business month:', isAtBusinessMonth);
    console.log('Is at current month:', isAtCurrentMonth);
    // useScrollToTop()
  }, [selectedDate, isAtBusinessMonth, isAtCurrentMonth]);
  const brands = [
    { id: 1, seller_name: 'Shell' },
    { id: 2, seller_name: 'BP' },
    { id: 3, seller_name: 'Exxon' },
    { id: 4, seller_name: 'Chevron' }
  ];
  
  const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;
  const [recentActivities, setRecentActivities] = useState([]);
  console.log(recentActivities, 'recentActivities')

  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  const quarters = [
    { fName: 'Q1 (Jan-Mar)', bName: 'Q1' },
    { fName: 'Q2 (Apr-Jun)', bName: 'Q2' },
    { fName: 'Q3 (Jul-Sep)', bName: 'Q3' },
    { fName: 'Q4 (Oct-Dec)', bName: 'Q4' }
  ];
  const getSummaryTitle = () => {
    let fuelGradeText = "All Fuel Grades";
    
    if (brandId === "1") {
      fuelGradeText = "Regular";
    } else if (brandId === "5") {
      fuelGradeText = "Premium";
    } else if (brandId === "10") {
      fuelGradeText = "Diesel";
    } else if (brandId === "14") {
      fuelGradeText = "E85";
    }
    
    return `${activeTab} Summary: ${fuelGradeText} (gl)`;
  };
  const years = ['2022', '2023', '2024', '2025'];

  const isBrandSelected = brandId !== '';

  const handleBrandChange = (e) => {
    setBrandId(e.target.value);
    // Fetch updated data when the fuel grade changes
    // fetchDashboardData();
  };

  const handlePeriodChange = (e) => {
    setPeriodType(e.target.value);
  };

  const handleKeyPress = (e) => {
    // Handle key press if needed
  };

  // Function to load more activities
  const loadMoreActivities = () => {
    setVisibleActivities(prev => Math.min(prev + 5, recentActivities.length));
  };
  
  // Data states
  const [data, setData] = useState({});
  
  // Initialize with all chart data types
  const [chartData, setChartData] = useState({
    Purchases: [],
    Sales: [],
    Revenue: [],
    Billing: []
  });
  
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [paymentAlerts, setPaymentAlerts] = useState([]);
  const [priceAlerts, setPriceAlerts] = useState([]);
  const [balanceData, setBalanceData] = useState({
    activeBalance: 9470,
    income: 1699.0,
    expenses: -799.0,
    taxes: -199.0
  });

  // Fetch data on component mount and when date range changes
  useEffect(() => {
    // Fetch all necessary data
    fetchDashboardData();
  }, [dateRange, selectedDate]);
  
  useEffect(() => {
    setUserRole(localStorage.getItem("user_role"));
  }, []);
  
  useEffect(() => {

    fetchDashboardData()
  }, [selectedMonth, brandId]);
  useEffect(() => {
    // Create a style element
    const style = document.createElement('style');
    // Add your CSS rule with updated color to match the dropdown
    style.innerHTML = `
    .home-date-picker.ant-picker {
      height: 28px !important;
    }
  
    .home-date-picker .ant-picker-input > input {
      color: #6B7280 !important; 
      font-size: 0.875rem;
      font-weight: 500;
    }
  
    .home-date-picker .ant-picker-suffix {
      color: #6b7280;
    }
  
    .home-date-picker .ant-picker-dropdown {
      font-size: 0.875rem;
    }
    `;
    // Append to head
    document.head.appendChild(style);
    
    // Clean up
    return () => {
      document.head.removeChild(style);
    };
  }, []);
  const [purchasesData, setpurchasesData] = useState([])
  const [salesData, setsalesData] = useState([])
  const [billingData, setbillingData] = useState([])
  const [revenueData, setrevenueData] = useState([])
  // Function to fetch all dashboard data
  const convertPurchasesData = (purchasesSummery) => {
    return purchasesSummery.map(item => {
      // Use date-fns or moment.js to properly format the date
      // Or use this vanilla JS approach that preserves the date regardless of timezone:
      const dateParts = item.added_on.split('T')[0].split('-');
      const year = parseInt(dateParts[0]);
      const month = parseInt(dateParts[1]);
      const day = parseInt(dateParts[2]);
      
      // Format as MM/DD without timezone conversion
      const formattedDate = `${month}/${day}`;
      
      return {
        name: formattedDate,
        value: parseInt(item.gallons, 10) // Convert gallons to integer for the chart
      };
    });
  };
  const convertSalesData = (salesSummery) => {
    // Create an object to aggregate data by date
    const aggregatedData = {};
    
    salesSummery.forEach(item => {
      // Parse date from string without timezone conversion
      const dateParts = item.added_on.split('T')[0].split('-');
      const month = parseInt(dateParts[1]);
      const day = parseInt(dateParts[2]);
      const formattedDate = `${month}/${day}`;
      
      // If this date already exists, add to its value
      if (aggregatedData[formattedDate]) {
        aggregatedData[formattedDate] += parseInt(item.gallons, 10);
      } else {
        // Otherwise create a new entry
        aggregatedData[formattedDate] = parseInt(item.gallons, 10);
      }
    });
    
    // Convert the aggregated object back to array format
    return Object.entries(aggregatedData).map(([date, gallons]) => ({
      name: date,
      value: gallons
    }));
  };

  const convertBillingData = (billingSummery) => {
    // Create an object to aggregate data by date
    const aggregatedData = {};
    
    billingSummery.forEach(item => {
      // Parse date from string without timezone conversion
      const dateParts = item.added_on.split('T')[0].split('-');
      const month = parseInt(dateParts[1]);
      const day = parseInt(dateParts[2]);
      const formattedDate = `${month}/${day}`;
      
      // If this date already exists, add to its value
      if (aggregatedData[formattedDate]) {
        aggregatedData[formattedDate] += parseInt(item.gallons, 10);
      } else {
        aggregatedData[formattedDate] = parseInt(item.gallons, 10);
      }
    });
    
    return Object.entries(aggregatedData).map(([date, gallons]) => ({
      name: date,
      value: gallons
    }));
  };
  const convertPiePurchasesData = (piePurchases) => {
    // Define a set of colors for the pie chart
    const colorPalette = [
      '#FF6347', '#FF8C69', '#FFA07A', '#FFDAB9', 
      '#FFE4B5', '#FFEFD5', '#FFE4E1', '#FFDEAD'
    ];
    
    return piePurchases.map((item, index) => {
      return {
        name: item.seller_name.charAt(0).toUpperCase() + item.seller_name.slice(1), // Capitalize brand name
        value: parseInt(item.total_gallons, 10),
        fill: colorPalette[index % colorPalette.length] // Cycle through colors if more brands than colors
      };
    });
  };
  const convertPieSalesData = (pieSales) => {
    const colorPalette = ['#4169E1', '#6495ED', '#87CEFA', '#B0E0E6', '#ADD8E6', '#B0C4DE'];
    
    // Extract data from each status and convert to the required format
    const result = [];
    
    // Process draft data
    if (pieSales.draft[0].gallons !== null) {
      result.push({
        name: 'Draft',
        value: parseInt(pieSales.draft[0].gallons, 10),
        fill: colorPalette[0]
      });
    }
    
    // Process approved data
    if (pieSales.approved[0].gallons !== null) {
      result.push({
        name: 'Approved',
        value: parseInt(pieSales.approved[0].gallons, 10),
        fill: colorPalette[1]
      });
    }
    
    // Process sent data
    if (pieSales.sent[0].gallons !== null) {
      result.push({
        name: 'Sent',
        value: parseInt(pieSales.sent[0].gallons, 10),
        fill: colorPalette[2]
      });
    }
    
    // If no data available, return an empty array or a placeholder
    return result.length > 0 ? result : [{ name: 'No Data', value: 100, fill: '#CCCCCC' }];
  };
  
  // Function to convert the billing pie chart API data
  const convertPieBillingData = (pieBilling) => {
    const colorPalette = ['#2E8B57', '#3CB371', '#90EE90', '#98FB98'];
    
    const result = [];
    
    // Process past due data
    if (pieBilling.pastData[0].gallons !== null) {
      result.push({
        name: 'Past Due',
        value: parseInt(pieBilling.pastData[0].gallons, 10),
        fill: colorPalette[0]
      });
    }
    
    // Process partial payment data
    if (pieBilling.partialData[0].gallons !== null) {
      result.push({
        name: 'Partial Pay',
        value: parseInt(pieBilling.partialData[0].gallons, 10),
        fill: colorPalette[1]
      });
    }
    
    // Process fully paid data
    if (pieBilling.fullpaidData[0].gallons !== null) {
      result.push({
        name: 'Fully Paid',
        value: parseInt(pieBilling.fullpaidData[0].gallons, 10),
        fill: colorPalette[2]
      });
    }
    
    // If no data available, return an empty array or a placeholder
    return result.length > 0 ? result : [{ name: 'No Data', value: 100, fill: '#CCCCCC' }];
  };
  
  const pieChartData1 = {
    Purchases: [
      { name: 'Shell', value: 400, fill: '#FF6347' },
      { name: 'BP', value: 300, fill: '#FF8C69' },
      { name: 'Exxon', value: 200, fill: '#FFA07A' },
      { name: 'Chevron', value: 100, fill: '#FFDAB9' },
      { name: 'Gulf', value: 150, fill: '#FFE4B5' },
      { name: 'Citgo', value: 120, fill: '#FFEFD5' },
      { name: 'Texaco', value: 90, fill: '#FFE4E1' },
      { name: 'Marathon', value: 80, fill: '#FFDEAD' }
    ],
    Sales: [
      { name: 'Regular', value: 350, fill: '#4169E1' },
      { name: 'Premium', value: 270, fill: '#6495ED' },
      { name: 'Diesel', value: 180, fill: '#87CEFA' },
      { name: 'E85', value: 90, fill: '#B0E0E6' },
      { name: 'Propane', value: 60, fill: '#ADD8E6' },
      { name: 'CNG', value: 40, fill: '#B0C4DE' }
    ],
    Billing: [
      { name: 'On Time', value: 450, fill: '#2E8B57' },
      { name: 'Late', value: 330, fill: '#3CB371' },
      { name: 'Overdue', value: 220, fill: '#90EE90' },
      { name: 'Pending', value: 130, fill: '#98FB98' }
    ],
    Revenue: [
      // { name: 'Retail', value: 480, fill: '#9370DB' },
      // { name: 'Wholesale', value: 360, fill: '#8A2BE2' },
      // { name: 'Fleet', value: 240, fill: '#9932CC' },
      // { name: 'Loyalty', value: 150, fill: '#BA55D3' },
      // { name: 'Car Wash', value: 100, fill: '#D8BFD8' },
      // { name: 'Convenience', value: 200, fill: '#DDA0DD' }
    ]
  };
  const [pieChartData, setPieChartData] = useState(pieChartData1)
  const transformPriceDataToAlerts = (data) => {
    if (!data) return [];
    
    const alerts = [];
    
    // Process Sunoco data
    if (data.sunoco) {
      // Check regular
      if (data.sunoco.regular && data.sunoco.regular.length >= 2) {
        const latest = data.sunoco.regular[0];
        const previous = data.sunoco.regular[1];
        
        if (latest && previous) {
          const latestPrice = parseFloat(latest.unit_price || 0);
          const previousPrice = parseFloat(previous.unit_price || 0);
          const change = latestPrice > previousPrice ? 'up' : 'down';
          
          alerts.push({
            name: "Sunoco ",
            brand: 'Regular',
            change,
            previousPrice,
            currentPrice: latestPrice
          });
        }
      } 
      // else if (data.sunoco.regular && data.sunoco.regular.length === 1) {
      //   // Handle case where there's only latest price without previous
      //   const latest = data.sunoco.regular[0];
      //   if (latest) {
      //     alerts.push({
      //       name: " ",
      //       brand: '',
      //       change: '', // Default to up when there's no comparison
      //       previousPrice: '',
      //       currentPrice: ''
      //     });
      //   }
      // }
      
      // Check diesel
      if (data.sunoco.diesel && data.sunoco.diesel.length >= 2) {
        const latest = data.sunoco.diesel[0];
        const previous = data.sunoco.diesel[1];
        
        if (latest && previous) {
          const latestPrice = parseFloat(latest.unit_price || 0);
          const previousPrice = parseFloat(previous.unit_price || 0);
          const change = latestPrice > previousPrice ? 'up' : 'down';
          
          alerts.push({
            name: "Sunoco ",
            brand: 'Diesel',
            change,
            previousPrice,
            currentPrice: latestPrice
          });
        }
      }
      
      // Check premium
      if (data.sunoco.premium && data.sunoco.premium.length >= 2) {
        const latest = data.sunoco.premium[0];
        const previous = data.sunoco.premium[1];
        
        if (latest && previous) {
          const latestPrice = parseFloat(latest.unit_price || 0);
          const previousPrice = parseFloat(previous.unit_price || 0);
          const change = latestPrice > previousPrice ? 'up' : 'down';
          
          alerts.push({
            name: "Sunoco ",
            brand: 'Premium',
            change,
            previousPrice,
            currentPrice: latestPrice
          });
        }
      }

      // Check ultra
      if (data.sunoco.ultra && data.sunoco.ultra.length >= 2) {
        const latest = data.sunoco.ultra[0];
        const previous = data.sunoco.ultra[1];
        
        if (latest && previous) {
          const latestPrice = parseFloat(latest.unit_price || 0);
          const previousPrice = parseFloat(previous.unit_price || 0);
          const change = latestPrice > previousPrice ? 'up' : 'down';
          
          alerts.push({
            name: "Sunoco ",
            brand: 'Ultra',

            change,
            previousPrice,
            currentPrice: latestPrice
          });
        }
      }
    }
    
    // Process Gulf data
    if (data.gulf) {
      // Check regular
      if (data.gulf.regular && data.gulf.regular.length >= 2) {
        const latest = data.gulf.regular[0];
        const previous = data.gulf.regular[1];
        
        if (latest && previous) {
          const latestPrice = parseFloat(latest.unit_price || 0);
          const previousPrice = parseFloat(previous.unit_price || 0);
          const change = latestPrice > previousPrice ? 'up' : 'down';
          
          alerts.push({
            name: "Gulf ",
            brand: 'Regular',
            change,
            previousPrice,
            currentPrice: latestPrice
          });
        }
      }
      
      // Check diesel
      if (data.gulf.diesel && data.gulf.diesel.length >= 2) {
        const latest = data.gulf.diesel[0];
        const previous = data.gulf.diesel[1];
        
        if (latest && previous) {
          const latestPrice = parseFloat(latest.unit_price || 0);
          const previousPrice = parseFloat(previous.unit_price || 0);
          const change = latestPrice > previousPrice ? 'up' : 'down';
          
          alerts.push({
            name: "Gulf ",
            brand: 'Diesel',
            change,
            previousPrice,
            currentPrice: latestPrice
          });
        }
      }

      // Check premium
      if (data.gulf.premium && data.gulf.premium.length >= 2) {
        const latest = data.gulf.premium[0];
        const previous = data.gulf.premium[1];
        
        if (latest && previous) {
          const latestPrice = parseFloat(latest.unit_price || 0);
          const previousPrice = parseFloat(previous.unit_price || 0);
          const change = latestPrice > previousPrice ? 'up' : 'down';
          
          alerts.push({
            name: "Gulf ",
            brand: 'Premium',
            change,
            previousPrice,
            currentPrice: latestPrice
          });
        }
      }

      // Check ultra
      if (data.gulf.ultra && data.gulf.ultra.length >= 2) {
        const latest = data.gulf.ultra[0];
        const previous = data.gulf.ultra[1];
        
        if (latest && previous) {
          const latestPrice = parseFloat(latest.unit_price || 0);
          const previousPrice = parseFloat(previous.unit_price || 0);
          const change = latestPrice > previousPrice ? 'up' : 'down';
          
          alerts.push({
            name: "Gulf ",
            brand: 'Ultra',
            change,
            previousPrice,
            currentPrice: latestPrice
          });
        }
      }
    }
    
    return alerts;
  };

  const processPaymentAlerts = (data) => {
    const alerts = [];
      // Process overdue alerts (if any)
      if (data.overduePayAlert && data.overduePayAlert.length > 0) {
        data.overduePayAlert.forEach(alert => {
          alerts.push({
            id: alert.invoice_number,
            paymentStatus: "Overdue",
            amount: alert.final_invoice_amount,
            customer: alert.business_name
          });
        });
      }

    
    // Process partially paid alerts
    if (data.partialpaidPayAlert && data.partialpaidPayAlert.length > 0) {
      data.partialpaidPayAlert.forEach(alert => {
        alerts.push({
          id: alert.invoice_number,
          paymentStatus: "Partial Pay",
          amount: alert.final_invoice_amount,
          dueAmount: alert.due_amount,
          customer: alert.business_name
        });
      });
    }
        // Process fully paid alerts
        if (data.fullylpaidPayAlert && data.fullylpaidPayAlert.length > 0) {
          data.fullylpaidPayAlert.forEach(alert => {
            alerts.push({
              id: alert.invoice_number,
              paymentStatus: "Full Pay",
              amount: alert.final_invoice_amount,
              customer: alert.business_name
            });
          });
        }
  
    
    return alerts;
  };


  const fetchDashboardData = async () => {
    // Set loading state
    setLoading(true);
    setError(null);
  
    try {
      const business_id = localStorage.getItem("business_id");
      const selectedYear = selectedDate.format('YYYY');
      const selectedMonthName = selectedDate.format('MMMM');
      // Make the API request with the selected fuel grade
      const response = await axios.post(`${baseURL}${btoa("invoice/fetchhomedata")}`, {
        businessId: business_id,
        userRole: localStorage.getItem('user_role'),
        userId: localStorage.getItem("user_id"),
        fuelGradeId: brandId ? brandId : 'all',
        reportType:'Monthly',
        monthName:selectedMonthName,
        year:selectedYear
      });
      
      const apiData = response.data;
  
      // Process all the data as before...
      const purchasesData = convertPurchasesData(apiData.purchasesSummery);
      const salesData = convertSalesData(apiData.salesSummery);
      const billingData = convertBillingData(apiData.billingSummery);
      const purchasesPieData = convertPiePurchasesData(apiData.piePurchases);
      const purchasesSalesData = convertPieSalesData(apiData.pieSales);
      const purchasesBillingData = convertPieBillingData(apiData.pieBilling);
      const transformedAlerts = transformPriceDataToAlerts(apiData);
      
      // Process the payment alerts
      const processedPaymentAlerts = processPaymentAlerts(apiData);
      
      // Update all the state variables
      setChartData({
        Purchases: purchasesData,
        Sales: salesData,
        Revenue: revenueData, // This is still using dummy data
        Billing: billingData
      });
      
      setPieChartData(prevPieChartData => ({
        ...prevPieChartData,
        Purchases: purchasesPieData,
        Sales: purchasesSalesData,
        Billing: purchasesBillingData,
      }));
      
      // Set the payment alerts
      setPaymentAlerts(processedPaymentAlerts);
      
      // Set other dashboard data
      setRecentActivities(apiData.recentActivities);
      setPriceAlerts(transformedAlerts);
      setData(apiData);
      
    } catch (err) {
      console.error('Error fetching dashboard data:', err);
      setError('Failed to load dashboard data. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const dummyPaymentAlerts = [
    { id: "INV-39812", paymentStatus: "Partial Pay" },
    { id: "INV-58293", paymentStatus: "Full Pay" },
    { id: "INV-77541", paymentStatus: "Sent " },
    { id: "INV-77541", paymentStatus: "Sent " },
    { id: "INV-77541", paymentStatus: "Sent " }
  ];

  // const dummyPriceAlerts = [
  //   { name: "Gulf Diesel", change: "up", previousPrice: 20, currentPrice: 40 },
  //   { name: "Sunoco Diesel", change: "down", previousPrice: 22, currentPrice: 32 },
  //   { name: "Sunoco Regular", change: "up", previousPrice: 18, currentPrice: 20 }
  // ];

  const dummyBalanceData = {
    activeBalance: 9470,
    income: 1699.0,
    expenses: -799.0,
    taxes: -199.0
  };

  // Load more payment alerts
  const loadMorePaymentAlerts = async () => {
    console.log('Load more alerts clicked');
  };

  // Get chart color based on active tab
  const getChartColor = () => {
    switch(activeTab) {
      case 'Purchases':
        return '#FF4500'; 
      case 'Sales':
        return '#4169E1'; 
      case 'Billing':
        return '#2E8B57'; 
      case 'Revenue':
        return '#9370DB'; 
      default:
        return '#FF7F50'; 
    }
  };

  const formatNumber = (number, decimals = 2) => {
    return Number(number).toLocaleString('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: decimals
    });
  };
  useEffect(() => {
    if (brandId) {
      setIsLoading(true);
      const timer = setTimeout(() => {
        setIsLoading(false);
      }, 800);
      return () => clearTimeout(timer);
    }
  }, [brandId]);
  const getCardInfo = (cardType, index) => {
    // Handle the TBD card type
    if (cardType === 'TBD') {
      return {
        icon: <Activity size={20} />,
        borderColor: colorScheme[index],
        gallons: "0",
        amount: "0"
      };
    }
    
    // Check if data exists and has required properties
    const hasData = data && 
                   data.purchasesTotals && 
                   data.purchasesTotals[0] &&
                   data.salesTotals && 
                   data.salesTotals[0] &&
                   data.billingsTotals && 
                   data.billingsTotals[0];
  
    // If data is not available yet or loading, return a loading state
    if (!hasData ) {
      return {
        icon: cardType === 'Purchases' ? <ShoppingCart size={20} /> :
              cardType === 'Sales' ? <BarChart2 size={20} /> :
              cardType === 'Billing' ? <DollarSign size={20} /> :
              <BarChart4 size={20} />,
        borderColor: colorScheme[index],
        gallons: "Loading...",
        amount: "Loading..."
      };
    }
  
    // Now we can safely access the data
    switch(cardType) {
      case 'Purchases':
        return {
          icon: <ShoppingCart size={20} />,
          borderColor: colorScheme[0],
          gallons: `${formatNumber(data.purchasesTotals[0].total_gallons, 0)} gl`,
          amount: `$${formatNumber(data.purchasesTotals[0].total_amount)}`
        };
      case 'Sales':
        return {
          icon: <BarChart2 size={20} />,
          borderColor: colorScheme[1],
          gallons: `${formatNumber(data.salesTotals[0].total_quantity, 0)} gl`,
          amount: `$${formatNumber(data.salesTotals[0].total_final_invoice_amount)}`
        };
      case 'Billing':
        return {
          icon: <DollarSign size={20} />,
          borderColor: colorScheme[2],
          gallons: `${formatNumber(data.billingsTotals[0].total_quantity, 0)} gl`,
          amount: `$${formatNumber(data.billingsTotals[0].total_final_invoice_amount)}`
        };
      case 'Revenue':
        // For Revenue, we use safe access with optional chaining
        return {
          icon: <BarChart4 size={20} />,
          borderColor: colorScheme[3],
          gallons: `${formatNumber(data.revenueTotals?.[0]?.total_revenue || "0.00")} gl`,
          amount: `$${formatNumber(data.revenueTotals?.[0]?.profit_margin || "0.00")}`
        };
      default:
        return {
          icon: <ShoppingCart size={20} />,
          borderColor: colorScheme[0],
          gallons: "0 gl",
          amount: "$0.00"
        };
    }
  };
  const LoadingPlaceholder = ({ color }) => (
    <div className="animate-pulse flex flex-col items-center">
      <div className="w-8 h-8 rounded-full bg-gray-200 mb-2"></div>
      <div className="h-4 w-16 bg-gray-200 rounded mb-1"></div>
      <div className="h-3 w-12 bg-gray-200 rounded"></div>
    </div>
  );
  

  // const cardTypes = ['Purchases', 'Sales', 'Billing', 'Revenue'];
  // const allCardTypes = [...cardTypes, 'TBD', 'TBD'];
  const allCardTypes = ['Purchases', 'Sales', 'Billing', 'Revenue', 'TBD', 'TBD'];
  const colorScheme = [
    '#41afaa', // Teal
    '#466eb4', // Blue
    '#00a0e1', // Light Blue
    '#e6a532', // Orange
    '#d7642c', // Dark Orange
    '#af4b91'  // Purple
  ];
  const CustomDot = (props) => {
    const { cx, cy, value, payload, index, data } = props;
    
    // Get the value directly from the payload if available, otherwise use the value prop
    const dataValue = payload && payload.value !== undefined ? payload.value : value;
    
    // Check if value is valid before formatting
    let displayValue = '';
    if (dataValue !== undefined && dataValue !== null && !isNaN(dataValue)) {
      // Format the value properly - ensure we're using the correct thousands separator
      displayValue = parseInt(dataValue, 10).toLocaleString('en-US');
    }
    
    // Determine if this is the last point in the dataset
    const isLastPoint = data && index === data.length - 1;
    
    // Adjust positioning based on whether it's the last point
    let xOffset = 25; // Default offset for regular points (to the right)
    let textAnchorValue = "middle"; // Default text anchor
    
    // If it's the last point, position the label to the left of the dot
    if (isLastPoint) {
      xOffset = -25; // Negative offset to place label to the left of the point
      textAnchorValue = "end"; // Align text to the end (right-aligned)
    }
    
    return (
      <g>
        {/* The original circle dot */}
        <circle 
          cx={cx} 
          cy={cy} 
          r={5} 
          fill="#FF4500" 
          stroke="none"
          style={{ opacity: 1 }}
        />
        
        {/* Only show text label if we have a valid value */}
        {displayValue && (
          <text 
            x={isLastPoint ? cx - 10 : cx + xOffset} 
            y={cy - 10} 
            textAnchor={textAnchorValue} 
            fill="#333" 
            fontSize="12"
            fontWeight="bold"
            // Make sure the label is always visible
            style={{ 
              zIndex: isLastPoint ? 1000 : 1,
              fontWeight: isLastPoint ? "bolder" : "bold" 
            }}
          >
            {displayValue}gl
          </text>
        )}
      </g>
    );
  };
  
  const renderChart = () => {
    // Get data for the active tab
    const activeData = chartData[activeTab] || [];
    const chartColor = getChartColor();
    const activePieData = pieChartData[activeTab] || [];
    if (loading) {
      return (
        <div className="flex items-center justify-center h-[280px]">
          <div className="flex flex-col items-center">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
            <p className="mt-2 text-gray-500">Loading data...</p>
          </div>
        </div>
      );
    }
    const getLighterColor = (color) => {
      // For hex colors like #FF4500, convert to RGB and lighten
      if (color.startsWith('#')) {
        // Convert hex to rgb
        let r = parseInt(color.slice(1, 3), 16);
        let g = parseInt(color.slice(3, 5), 16);
        let b = parseInt(color.slice(5, 7), 16);
        
        // Lighten by mixing with white (255,255,255)
        r = Math.floor(r + (255 - r) * 0.7);
        g = Math.floor(g + (255 - g) * 0.7);
        b = Math.floor(b + (255 - b) * 0.7);
        
        return `rgba(${r}, ${g}, ${b}, 0.7)`;
      }
      
      // For named colors like 'red', 'blue', etc.
      switch(color) {
        case '#FF4500': return 'rgba(255, 69, 0, 0.3)'; // Purchases
        case '#4169E1': return 'rgba(65, 105, 225, 0.3)'; // Sales
        case '#2E8B57': return 'rgba(46, 139, 87, 0.3)'; // Billing
        case '#9370DB': return 'rgba(147, 112, 219, 0.3)'; // Revenue
        default: return 'rgba(200, 200, 200, 0.3)';
      }
    };
    
    // Custom shape for bar with thick outline and lighter fill
    const CustomBar = (props) => {
      const { x, y, width, height, fill } = props;
      const lightFill = getLighterColor(fill);
      
      return (
        <g>
          {/* Main bar with lighter fill */}
          <rect x={x} y={y} width={width} height={height} fill={lightFill} />
          
          {/* Outline with original color and thicker stroke */}
          <rect 
            x={x} 
            y={y} 
            width={width} 
            height={height} 
            fill="none" 
            stroke={fill} 
            strokeWidth={1} 
          />
        </g>
      );
    };
    switch(chartType) {
      case 'bar':
        return (
          <ResponsiveContainer width="100%" height={ 280} paddingBottom ="30px">
            <BarChart data={activeData}   margin={{ bottom: 20 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis 
  dataKey="name" 
  label={{ 
    value: 'Date', 
    position: 'insideBottomCenter', 
    offset: 0,
    dy: 15 
  }}
  // Remove this formatter or simplify it
  tickFormatter={(value) => value}
/>
              <YAxis 
              width={100}  
  label={{ value: 'Gallons', angle: -90, position: 'insideLeft', marginRight:'5px' }}
/>
    
              <Tooltip  cursor={{ fill: 'rgba(0, 0, 0, 0)' }}/>
              <Bar dataKey="value" fill={chartColor}  barSize={40}   shape={<CustomBar />}/>
              {/* <Brush 
                dataKey="name" 
                height={30} 
                stroke={chartColor} 
                fill="#f5f5f5"
                tickFormatter={(value) => value}
                startIndex={0}
                endIndex={Math.min(11, activeData.length - 1)}
              /> */}
            </BarChart>
          </ResponsiveContainer>
        );
      case 'pie':
        // Filter pieData to show only the top N segments based on value
        const sortedPieData = [...activePieData].sort((a, b) => b.value - a.value);
        const visiblePieData = sortedPieData.slice(0, pieSegmentCount);
        
        // Calculate "Other" segment if needed
        let otherSegment = null;
        if (pieSegmentCount < sortedPieData.length) {
          const otherValue = sortedPieData
            .slice(pieSegmentCount)
            .reduce((sum, item) => sum + item.value, 0);
            
          if (otherValue > 0) {
            otherSegment = {
              name: 'Other',
              value: otherValue,
              fill: '#CCCCCC'
            };
          }
        }
        
        // Final data to display
        const displayPieData = otherSegment 
          ? [...visiblePieData, otherSegment]
          : visiblePieData;
        
        return (
          <div>
            <ResponsiveContainer width="100%" height={ 280}>
              <PieChart>
                <Pie
                  data={displayPieData}
                  cx="50%"
                  cy="50%"
                  outerRadius={70}
                  dataKey="value"
                  label={({name, percent}) => `${name}: ${(percent * 100).toFixed(0)}%`}
                />
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
            
            {/* Simple segment control for pie charts */}
            {activePieData.length > 4 && (
              <div className="flex justify-center items-center mt-2">
                <span className="text-sm mr-2">Show segments:</span>
                <select 
                  className="text-sm border rounded px-2 py-1" 
                  value={pieSegmentCount}
                  onChange={(e) => setPieSegmentCount(parseInt(e.target.value))}
                >
                  <option value={4}>Top 4</option>
                  <option value={6}>Top 6</option>
                  <option value={8}>Top 8</option>
                  <option value={activePieData.length}>All ({activePieData.length})</option>
                </select>
              </div>
            )}
          </div>
        );
        case 'area':
          return (
            <ResponsiveContainer width="100%" height={280}>
              <AreaChart 
                data={activeData} 
                margin={{ top: 20, right: 50, bottom: 10, left: 30 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis 
  dataKey="name" 
  label={{ 
    value: 'Date', 
    position: 'insideBottomCenter', 
    offset: 0,
    dy: 15,
  }}
  // This formatter should be removed or simplified since 'name' is already formatted correctly
  // If you still need a formatter:
  tickFormatter={(value) => value} // Just use the pre-formatted value
/>
                <YAxis 
                  width={45}  
                  label={{ value: 'Gallons', angle: -90, position: 'insideLeft', dx: -25 }}
                />
                <Tooltip 
                  formatter={(value) => [Number(value).toLocaleString('en-US') + ' gl', 'Gallons']}
                />
                <Area 
                  type="monotone" 
                  dataKey="value" 
                  stroke="#FF4500" 
                  fill="#FF4500" 
                  fillOpacity={0.1}
                  // Use the custom dot component
                  dot={<CustomDot />}
                  activeDot={<CustomDot />}
                  isAnimationActive={true}
                />
              </AreaChart>
            </ResponsiveContainer>
          );
      case 'line':
      default:
        return (
          <ResponsiveContainer width="100%" height={ 280}>
            <LineChart data={activeData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Line type="monotone" dataKey="value" stroke={chartColor} dot={{ r: 2 }} activeDot={{ r: 5 }} />
              {/* <Brush 
                dataKey="name" 
                height={30} 
                stroke={chartColor} 
                fill="#f5f5f5"
                tickFormatter={(value) => value}
                startIndex={0}
                endIndex={Math.min(11, activeData.length - 1)}
              /> */}
            </LineChart>
          </ResponsiveContainer>
        );
    }
  };

  // if (loading) {
  //   return <div className="flex items-center justify-center h-screen"><FullScreenLoader/></div>;
  // }

  // if (error) {
  //   return <div className="flex items-center justify-center h-screen text-red-500">{error}</div>;
  // }

  return (
    <>
      {userRole === '1' ? (
        <SuperAdminHome />
      ) : userRole === '5' ? (
        <CustomerPortalHome />
      ) : (
        <>
          <div className="w-full flex justify-center items-center">
            <ScrollingMessage />
          </div>
          <div className="min-h-screen p-4 px-0 py-2">
            <div className="px-[0px] py-[0px] mx-auto">
              <div className="flex justify-end items-start gap-3 mb-[45px] mt-[10px]">
              <button
  className="w-[120px] text-[15px] px-2 px-12 py-1 border-2 bg-white text-black
  border-[#25caed] hover:font-bold hover:bg-[#25caed] 
  uppercase duration-200 hover:text-white hover:border-transparent active:scale-90"
  style={{ borderRadius: '12px' }}
  onMouseEnter={(e) => {
    e.target.style.boxShadow = '0 0 10px 5px rgba(37, 202, 237, 0.5)'; // Shadow on hover
  }}
  onMouseLeave={(e) => {
    e.target.style.boxShadow = 'none'; // No shadow by default
  }}
  onClick={() => { navigate(`/Pricing`); }}
>
  Price Book
</button>



              
                <button 
                  className="w-[120px] text-[15px] px-2 px-12 py-1 border-2 
                  border-[#25caed] hover:font-bold hover:bg-[#25caed] bg-white text-black
                  hover:shadow-cyan-700/50 uppercase duration-200 hover:text-white 
                  hover:shadow-lg hover:border-transparent active:scale-90"
                  style={{ borderRadius: '12px' }}
                  onMouseEnter={(e) => {
                    e.target.style.boxShadow = '0 0 10px 5px rgba(37, 202, 237, 0.5)'; // Shadow on hover
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.boxShadow = 'none'; // No shadow by default
                  }}
                  onClick={() => navigate('/invoice')}
                >
                  Sales
                </button>
                
                <button 
                  className="w-[120px] text-[15px] px-2 px-12 py-1 border-2 
                  border-[#25caed] hover:font-bold hover:bg-[#25caed] bg-white text-black
                  hover:shadow-cyan-700/50 uppercase duration-200 hover:text-white 
                  hover:shadow-lg hover:border-transparent active:scale-90"
                  style={{ borderRadius: '12px' }}
                  onMouseEnter={(e) => {
                    e.target.style.boxShadow = '0 0 10px 5px rgba(37, 202, 237, 0.5)'; // Shadow on hover
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.boxShadow = 'none'; // No shadow by default
                  }}
                  onClick={() => navigate('/statements')}
                >
                  Statements
                </button>
              </div>
              
              {/* Main Content */}
              <div className="flex flex-wrap gap-4 items-start gap-8">
                {/* Left Column - Summary Sales and Recent Activities */}
                <div className="flex-1 min-w-0">
              {/* Top naviagation     */}
              <div className="flex justify-between w-full gap-4 ">
      {allCardTypes.map((cardType, index) => {
        const { icon, borderColor, gallons, amount } = getCardInfo(cardType, index);
        
        // Determine if this is the TBD card or Revenue
        const isTBD = cardType === 'TBD';
        const isRevenue = cardType === 'Revenue';
        
        return (
          <div 
            key={cardType}
            className="cursor-pointer flex-shrink-0"
            style={{ 
              width: `calc(${100 / allCardTypes.length}% - 20px)`
            }}
            onClick={() => {
              if (isTBD || isRevenue) {
                console.log('TBD card clicked - feature coming soon!');
              } else {
                setActiveTab(cardType);
                setBrandId('');
                setSelectedDate(dayjs())
                setTimeout(() => {
                  fetchDashboardData();
                }, 100);
              }
            }}
          >
            <div 
              className={`
                flex flex-col items-center bg-white p-3 rounded-lg shadow-lg
                transition-all duration-300
                border-b-0 hover:border-b-4 ${cardType === activeTab ? 'border-b-4' : ''}
                h-full whitespace-nowrap
                ${isLoading ? 'opacity-70' : ''}
              `}
              style={{
                borderBottomWidth: cardType === activeTab ? '4px' : '0px',
                borderBottomColor: cardType === activeTab ? borderColor : 'transparent',
                boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" 
              }}
              onMouseEnter={(e) => {
                if (cardType !== activeTab) {
                  e.currentTarget.style.borderBottomWidth = '4px';
                  e.currentTarget.style.borderBottomColor = borderColor;
                }
              }}
              onMouseLeave={(e) => {
                if (cardType !== activeTab) {
                  e.currentTarget.style.borderBottomWidth = '0px';
                  e.currentTarget.style.borderBottomColor = 'transparent';
                }
              }}
            >
              {/* {isLoading ? (
                <LoadingPlaceholder color={borderColor} />
              ) : (
                <>
                  <div 
                    className="w-12 h-12 flex items-center justify-center rounded-full text-white transition-colors duration-300"
                    style={{ backgroundColor: borderColor }}
                  >
                    {icon}
                  </div>
                  <div className="mt-2 text-center w-full overflow-hidden text-ellipsis">
                    <div className="font-semibold text-sm truncate">{cardType}</div>
                    <div className="text-sm font-semibold truncate mt-1" style={{ color: borderColor }}>{gallons}</div>
                  </div>
                </>
              )} */}
                <>
                  <div 
                    className="w-12 h-12 flex items-center justify-center rounded-full text-white transition-colors duration-300"
                    style={{ backgroundColor: borderColor }}
                  >
                    {icon}
                  </div>
                  <div className="mt-2 text-center w-full overflow-hidden text-ellipsis">
                    <div className="font-semibold text-sm truncate">{cardType}</div>
                    <div className="text-sm font-semibold truncate mt-1" style={{ color: borderColor }}>{gallons}</div>
                  </div>
                </>
            </div>
          </div>
        );
      })}
    </div>
                  {/* <div className="flex flex-wrap justify-start gap-8 flex-1 mb-8 perspective-1000">
                  {cardTypes.map(cardType => {
                    const { icon, color, hoverColor, frontValue, backValue } = getCardInfo(cardType);
                    return (
                      <div 
                        key={cardType}
                        className={`cursor-pointer ${cardType === activeTab ? 'z-10' : ''}`}
                        onClick={() => setActiveTab(cardType)}
                        onMouseEnter={() => setHoveredCard(cardType)}
                        onMouseLeave={() => setHoveredCard(null)}
                        style={{ perspective: '1000px' }}
                      >
                        <div 
                          className={`relative transition-transform duration-700 ${hoveredCard === cardType ? 'rotateY-180' : ''}`}
                          style={{ 
                            transformStyle: 'preserve-3d',
                            transform: hoveredCard === cardType ? 'rotateY(180deg)' : 'rotateY(0deg)'
                          }}
                        > */}
                          {/* Front Face */}
                          {/* <div 
                            className="flex flex-col items-center bg-white p-4 rounded-lg shadow-lg px-8 backface-hidden"
                            style={{ 
                              backfaceVisibility: 'hidden',
                              position: 'relative',
                              width: '140px',
                              height: '160px',
                              zIndex: 2
                            }}
                          >
                            <div className={`w-16 h-16 flex items-center justify-center rounded-full text-white ${color} transition-colors duration-300`}>
                              {icon}
                            </div>
                            <div className="mt-4 text-center">
                              <div className="font-semibold">{cardType}</div>
                              <div className="text-sm text-gray-700 mt-2" style={{whiteSpace:'nowrap'}}>
                                {frontValue}
                              </div>
                            </div>
                          </div> */}
                          
                          {/* Back Face */}
                          {/* <div 
                            className="flex flex-col items-center justify-center bg-white p-4 rounded-lg shadow-lg px-8 backface-hidden absolute top-0 left-0 w-full h-full"
                            style={{ 
                              backfaceVisibility: 'hidden',
                              transform: 'rotateY(180deg)',
                              zIndex: 1
                            }}
                          >
                            <div className={`w-16 h-16 flex items-center justify-center rounded-full text-white ${color} transition-colors duration-300`}>
                              {icon}
                            </div>
                            <div className="mt-4 text-center">
                              <div className="font-semibold">{cardType}</div>
                              <div className="text-sm text-gray-700 mt-2" style={{whiteSpace:'nowrap'}}>
                                {backValue}
                              </div>
                            </div>
                          </div> */}
                        {/* </div>
                      </div>
                    );
                  })}
                </div> */}
            
                  {/* Filter Button Above Summary Box */}
                  {/* <div className="w-full mb-2 mt-4">
                    <div className="flex flex-wrap items-center w-full gap-4">
                      {showFilter && (              
                        <div className="flex flex-wrap flex-grow gap-4">
                          <select
                            id="brandId"
                            value={brandId}
                            onChange={handleBrandChange}
                            onKeyPress={handleKeyPress}
                            className="border border-gray-300 text-sm font-medium rounded-lg focus:ring-primary-600 focus:border-primary-600 flex-grow basis-0 min-w-[130px] text-gray-500 py-1 px-2 h-[30px]"
                          >
                            <option value="all">All Fuel Grades</option>
                        
                             <option value="1">Regular</option>
                             <option value="5">Premium</option>
                             <option value="10">Diesel</option>
                             <option value="14">E85</option>
                          </select>
                          
                    
                        </div>
                      )}
                      {showFilter && (   
                          <div className="flex items-center justify-center">
                          <button 
                            className="flex items-center mr-2"
                            onClick={handlePrevMonth}
                            disabled={isAtBusinessMonth}
                          >
                            <FaChevronLeft 
                              className={isAtBusinessMonth ? "text-gray-300" : "text-gray-600 cursor-pointer"} 
                            />
                          </button>
                          
                          <div>
                            <Space direction="vertical" style={{ height: "100%" }}>
                              <DatePicker
                                value={selectedDate}
                                onChange={handleDateChange}
                                disabledDate={disabledDate}
                                picker="month"
                                format="MMMM-YYYY"
                                popupStyle={{ width: 'auto' }}
                                style={{ width: '150px', height: '28px' }}
                                className="home-date-picker"
                                allowClear={false}
                              />
                            </Space>
                          </div>
                          
                          <button 
                            className="flex items-center ml-2"
                            onClick={handleNextMonth}
                            disabled={isAtCurrentMonth}
                          >
                            <FaChevronRight 
                              className={isAtCurrentMonth ? "text-gray-300" : "text-gray-600 cursor-pointer"} 
                            />
                          </button>
                        </div>
)}
                      <button 
                        className="py-1 px-3 border rounded-md flex items-center gap-1 bg-white h-[30px] ml-auto"
                        style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
                        onClick={() => setShowFilter(!showFilter)}
                      >
                        Filter
                        <MdFilterList className="w-5 h-[16px] mr-2" />
                      </button>
                    </div>
                  </div> */}
                  
                  {/* Summary Sales Box */}
                  <div className="bg-white rounded-lg shadow p-4 mb-4 mt-6" style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}>
                  <div className="flex justify-between items-center mb-4">
      {/* Left: Purchase Summary */}
      <div className="w-2/4">
  <p className="font-semibold text-sm truncate" style={{whiteSpace:'nowrap'}}>{getSummaryTitle()}</p>
</div>
      
      {/* Middle: Dropdowns */}
      <div className="flex items-center justify-center gap-4 w-2/4">
        <select
          id="brandId"
          value={brandId}
          onChange={handleBrandChange}
          onKeyPress={handleKeyPress}
          className="border border-gray-300 text-sm font-medium rounded-lg focus:ring-primary-600 focus:border-primary-600 min-w-[130px] text-gray-500 py-1 px-2 h-[30px]"
        >
          <option value="all">All Fuel Grades</option>
          <option value="1">Regular</option>
          <option value="5">Premium</option>
          <option value="10">Diesel</option>
          <option value="14">E85</option>
        </select>
        
        <Space direction="vertical" style={{ height: "100%" }}>
          <DatePicker
            value={selectedDate}
            onChange={handleDateChange}
            disabledDate={disabledDate}
            picker="month"
            format="MMMM-YYYY"
            popupStyle={{ width: 'auto' }}
            style={{ width: '150px', height: '28px' }}
            className="home-date-picker"
            allowClear={false}
          />
        </Space>
      </div>
      
      {/* Right: Chart Icons */}
      <div className="flex gap-2 justify-end w-1/4">
        <div 
          onClick={() => setChartType('area')}
          className={`w-8 h-8 flex items-center justify-center rounded cursor-pointer ${chartType === 'area' ? 'bg-blue-100' : 'bg-gray-100'}`}
        >
          <TrendingUp size={16} className={chartType === 'area' ? 'text-blue-500' : 'text-red-500'} />
        </div>
        <div 
          onClick={() => setChartType('bar')}
          className={`w-8 h-8 flex items-center justify-center rounded cursor-pointer ${chartType === 'bar' ? 'bg-blue-100' : 'bg-gray-100'}`}
        >
          <BarChartIcon fontSize="small" className={chartType === 'bar' ? 'text-blue-500' : 'text-green-500'} />
        </div>
        <div 
          onClick={() => setChartType('pie')}
          className={`w-8 h-8 flex items-center justify-center rounded cursor-pointer ${chartType === 'pie' ? 'bg-blue-100' : 'bg-gray-100'}`}
        >
          <PieChartIcon fontSize="small" className={chartType === 'pie' ? 'text-blue-500' : 'text-purple-500'} />
        </div>
        <div 
          onClick={() => setChartType('donut')}
          className={`w-8 h-8 flex items-center justify-center rounded cursor-pointer ${chartType === 'donut' ? 'bg-blue-100' : 'bg-gray-100'}`}
        >
          <DonutLargeIcon fontSize="small" className={chartType === 'donut' ? 'text-blue-500' : 'text-orange-500'} />
        </div>
      </div>
    </div>
                    
                    {renderChart()}
                  </div>
                  
                  {/* Recent Activities Section */}
                  <div className="bg-white rounded-lg shadow p-4" style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}>
                    <div className="flex justify-center items-center mb-4 bg-blue-900 text-white rounded-lg p-1">
                      <h2 className=" font-semibold">Recent Activities</h2>
                    </div>
                    
                    <div className="overflow-x-auto">
                      <div>
                        {recentActivities.slice(0, visibleActivities).map((purchase, index) => (
                          <div 
                            key={index} 
                            className="py-2 px-3 rounded border hover:bg-blue-50 transition-colors duration-200"
                          >
                            <div className="flex items-center justify-between">
                              <div className="flex-1">
                                <span className="font-semibold text-gray-700">{purchase.message}</span>
                                {/* <span className="text-gray-500 text-sm"> from {purchase.company}</span> */}
                              </div>
                              
                               
                              
                              <div className="flex items-center gap-1 text-gray-700">
                                {/* <Droplets size={14} className="text-blue-500" /> */}
                                {/* <span className="text-sm">{purchase.product}</span> */}
                              </div>
                              
                              {/* <div className="flex items-center gap-1 w-24 justify-end">
                                <DollarSign size={14} className="text-green-500" />
                                <span className="font-semibold">{purchase.price}</span>
                              </div> */}
                              
                              {/* <div className="flex items-center gap-1 w-28 justify-end">
                                <Calendar size={14} className="text-gray-400" />
                                <span className="text-xs text-gray-500">{purchase.date}</span>
                              </div> */}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    
                    {/* Show More button - only visible when there are more activities to show */}
                    {visibleActivities < recentActivities.length && (
                      <div className="mt-3 flex justify-end">
                        <button 
                          className="text-blue-500 bg-blue-100 text-sm p-1 px-2 rounded-lg "
                          onClick={loadMoreActivities}
                        >
                          More...
                          {/* <ChevronDown size={14} /> */}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                
                {/* Right Column */}
                <div className="w-72">
                  {/* Price Alerts */}
                  <div className="bg-orange-500 rounded-lg shadow p-4 mb-4 flex flex-col justify-between" 
  style={{ boxShadow: "0px 4px 16px rgba(239, 89, 25, 0.3)", height:'252px' }}
>
  {/* Header - Price Alert button */}
  <div className="w-full">
    <button 
      className="w-full mb-4 bg-white rounded-md p-1 text-blue-500 flex items-center justify-center"
    >
      <span className="text-orange-500 font-bold flex justify-center items-center">Price Alert</span>
    </button>
  
    {/* Content - This can be empty if no alerts */}
    <div className="flex-grow overflow-hidden">
      {Array.isArray(priceAlerts) && priceAlerts.length > 0 && (
        <table className="w-full border-collapse">
          <tbody>
            {priceAlerts.slice(0, 4).map((alert, index) => (
              <tr 
                key={index} 
                className="pb-2"
                onMouseEnter={() => setShowPriceTooltip(true)}
                onMouseLeave={() => setShowPriceTooltip(false)}
              >
                <td className="px-2 py-1 text-left">
                  <span className="text-white font-bold">{alert.name}</span>
                </td>
                <td className="px-2 py-1 text-left">
                  <span className="text-white font-bold">{alert.brand}</span>
                </td>
                <td className="px-2 py-1 text-left">
                  <span className="text-white font-bold">{alert.currentPrice}</span>
                </td>
                <td className="px-2 py-1 text-center">
                  <div className="flex justify-center cursor-pointer">
                    {alert.change === 'up' ? (
                      <ArrowUp size={20} className="w-8 text-red-500 bg-white p-1 rounded-lg" strokeWidth={4} />
                    ) : (
                      <ArrowDown size={20} className="w-8 bg-white p-1 text-blue-500 rounded-lg" strokeWidth={4} />
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  </div>
  
  {/* Footer - More button at bottom left */}
  <div className="mt-auto self-start">
    <button 
      className="text-sm bg-orange-200 p-1 px-2 text-orange-600 rounded-lg"
      // onClick={loadMorePaymentAlerts}
    >
      More...
    </button>
  </div>
</div>
                  
                  {/* Payment Alerts */}
                  <div className="bg-gray-50 rounded-lg shadow p-4 mb-5 flex flex-col justify-between" 
  style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)", height:'278px' }}>
  
  {/* Header and content area */}
  <div>
    <h2 className="font-semibold mb-4 bg-gray-200 px-3 flex justify-center items-center">Payment Alerts</h2>
    
    <div className="space-y-3 py-1">
      {paymentAlerts.length > 0 ? (
        paymentAlerts.slice(0, 3).map((payment, index) => (
          <div key={index} className="flex justify-between items-center">
            <div className="flex items-center gap-2">
              <div className={`w-3 h-3 rounded-full ${
                payment.paymentStatus === "Full Pay" ? "bg-green-500" : 
                payment.paymentStatus === "Partial Pay" ? "bg-orange-500" : 
                "bg-red-500"
              }`}></div>
              <span>{payment.id}</span>
            </div>
            <span className={`${
              payment.paymentStatus === "Full Pay" ? "text-green-500" : 
              payment.paymentStatus === "Partial Pay" ? "text-orange-500" : 
              "text-red-500"
            }`}>{payment.paymentStatus}</span>
          </div>
        ))
      ) : (
        <div className="flex justify-center items-center py-4 text-gray-500">
          No payment alerts found
        </div>
      )}
    </div>
  </div>
  
  {/* Footer with More button at bottom left */}
  <div className="mt-auto self-start">
    <button 
      className="text-blue-500 bg-blue-100 text-sm p-1 px-2 rounded-lg"
      onClick={loadMorePaymentAlerts}
    >
      More...
    </button>
  </div>
</div>
                  
                  {/* Expenses Status */}
                  <div className="bg-white rounded-lg shadow p-4" 
                  style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" , height:'205px'}}>
                    <div className="flex justify-between items-center mb-4">
                      <h2 className=" font-semibold">Expenses Status</h2>
                      <div className="bg-green-100 text-green-500 px-2 py-1 rounded text-xs">On Track</div>
                    </div>
                    
                    <div className="h-32">
                      <ResponsiveContainer width="100%" height="100%">
                        <LineChart data={chartData[activeTab].slice(0, 6)}>
                          <Line type="monotone" dataKey="value" stroke="#FF7F50" strokeWidth={2} dot={false} />
                        </LineChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Home;
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import config from "../../config/config";
import CloudUpload from "@mui/icons-material/CloudUpload";
import dayjs from "dayjs";
import { useSelector, useDispatch } from "react-redux";
import { addPurchases, deleteAllPurchases } from "../../store/purchasesSlice";
import axiosInstance from "../../authentication/axiosInstance";
import { addToast } from "./ToastBox/ToastBoxMessageSlice";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { FaHome } from "react-icons/fa";
import { Check, X } from "lucide-react";
import CustomCommonTab from "../reusable/CustomCommonTab";
import CustomNavigation from "../reusable/CustomNavigationIcon";

const FileUploadModal = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [cardsData, setCardsData] = useState(null);
  
  useEffect(() => {
    const storedData = localStorage.getItem('cardsData');
    if (storedData) {
      setCardsData(JSON.parse(storedData));
      // Optionally clear after use
      // localStorage.removeItem('cardsData');
    }
  }, []);
  // const cardsData = location.state?.cardsData || []; // Ensure fallback value
  console.log(cardsData, "cardsDatacardsDatacardsData");
  const [selectedDateFrom, setSelectedDateFrom] = useState(dayjs());

  const formattedDatefrom = selectedDateFrom ? selectedDateFrom : "";
  const [selectedInvoiceNumber, setSelectedInvoiceNumber] = useState("");
  const fileInputRef = useRef(null);
  const [openDeleteModalId, setOpenDeleteModalId] = useState(null);

  const [rightcardata, setrightcardata] = useState([]);
  const [monthName, setMonthaname] = useState("");
  const [yearName, setYear] = useState("");
  const [uploadCount, setUploadCount] = useState(0);

  const formattedMonthName = () => {
    let [month] = formattedDatefrom.format("MMMM-YYYY").split("-");
    return month;
  };
  const formattedYear = () => {
    let [, year] = formattedDatefrom.format("MMMM-YYYY").split("-");
    return year;
  };
  const userRole = localStorage.getItem("user_role");
  const dispatch = useDispatch();

  const businessId = localStorage.getItem("business_id");
  const managerId = localStorage.getItem("user_id");
  const [createdDate, setCreatedOnDate] = useState(new Date());
  const [sunocoGallons, setSunocoGallons] = useState("");
  const [GulfGallons, setGulfGallons] = useState("");
  const [loading, setloading] = useState(false);
  const [selectedBrandName, setSelectedBrandName] = useState("all");

  const formatDateForApi = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const handleChangeFuelBrands = (e) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    setSelectedBrandName(selectedOption.value);
    setSelectedText(selectedOption.text);
  };

  const [selectedText, setSelectedText] = useState("");
  const [isClicked, setIsClicked] = useState(false);

  const baseURL =
    process.env.NODE_ENV === "production"
      ? config.production.baseURL
      : config.development.baseURL;
  const [sellerNames, setSellerNames] = useState([]);
  const [localFuelBrand, setLocalFuelBrand] = useState("");
  const [file, setFile] = useState(null);

  const modalRef = useRef(null);
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      // Get the file type
      const fileType = selectedFile.type;

      // Check if the file type is PDF only
      if (fileType === "application/pdf") {
        setFileName(selectedFile.name);
        setFile(selectedFile);
        setErrorMessage("");
      } else {
        setErrorMessage("The file to upload must be in .pdf format.");
        setFileName("");
        setFile(null);
      }
    } else {
      setFileName("");
      setFile(null);
      setErrorMessage("");
    }
  };
  const [fileName, setFileName] = useState(null);
  const [selectedFuelBrand, setSelectedFuelBrand] = useState("");
  // const onFuelBrandChange = (fuelBrand) => {
  //   setSelectedFuelBrand(fuelBrand.value);
  //   setSelectedText(fuelBrand.text)
  // };

  const handleUploadImage = async (e) => {
    if (isClicked) return;
    setSelectedDateFrom(dayjs());

    console.log("upload image", file);
    // console.log("date date" , formatDateForApi(createdDate));
    e.preventDefault();
    if (!file) {
      alert("Please select a file first!");
      return;
    }
    const formData = new FormData();
    formData.append("receipt", file);
    formData.append("businessId", localStorage.getItem("business_id"));
    formData.append("brandId", selectedFuelBrand);

    formData.append("managerId", localStorage.getItem("user_id"));
    formData.append("userRole", localStorage.getItem("user_role"));
    formData.append("selectedDate", formatDateForApi(createdDate));
    formData.append("businessName", "Hp Gas");

    setloading(true);
    setIsClicked(true);

    try {
      const response = await axiosInstance.post(
        `${baseURL}${btoa("purchase/create")}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            // 'Authorization': `${token}`,
          },
        }
      );
      console.log(response, "response");
      if (response.data.success === true) {
        setFile(null);
        setFileName("");
        fileInputRef.current.value = "";
        // toast.success(`${response.data.message}` , {autoClose : 2500})
        dispatch(
          addToast({ type: "success", message: `${response.data.message}` })
        );

        // dispatch(addToast({ type: 'success', message: `${response.data.message}` }));
        setUploadCount((prevCount) => prevCount + 1);
        setCreatedOnDate(new Date());
        // setSelectedDateFrom(dayjs())
      }
      // if(response.data.success === false){
      //   setFile(null);
      //   setFileName('');
      //   toast.error(`Image uploading failed` , {autoClose : 2500})
      // }

      // console.log("upload image response", response);,
    } catch (error) {
      if (error.response.data.success === false) {
        setFile(null);
        setFileName("");
        // toast.error(`${error.response.data.message}` , {autoClose : 2500})
        dispatch(
          addToast({
            type: "danger",
            message: `${error.response.data.message}`,
          })
        );
      }
      console.log("upload image failed", error);
    } finally {
      // setUploadModal(false)
      setloading(false);
      setIsClicked(false);
      navigate("/OperationsScreen");
    }
  };
  const [errorMessage, setErrorMessage] = useState("");

  // const handleSelectChange = (e) => {
  //   const selectedOption = e.target.options[e.target.selectedIndex];
  //   setLocalFuelBrand(selectedOption.value);
  //   onFuelBrandChange(selectedOption);
  // };
  const onFuelBrandChange = (fuelBrand) => {
    setSelectedFuelBrand(fuelBrand.id); // Update selected fuel brand ID
    setSelectedText(fuelBrand.seller_name); // Update the displayed seller name
  };
  // const handleSelectChange = (fuelBrandId) => {
  //   const selectedFuelBrand = sellerNames.find((seller) => seller.id === fuelBrandId);
  //   if (selectedFuelBrand) {
  //     setLocalFuelBrand(fuelBrandId); // Update the local state
  //     onFuelBrandChange(selectedFuelBrand); // Pass the selected brand object to the next handler
  //   }
  // };

  const handleSelectChange = (fuelBrandId) => {
    const selectedFuelBrand = sellerNames.find(
      (seller) => seller.id === fuelBrandId
    );
    if (selectedFuelBrand) {
      setLocalFuelBrand((prevSelected) =>
        prevSelected === fuelBrandId ? null : fuelBrandId
      );
      onFuelBrandChange(selectedFuelBrand); // Pass the selected brand object to the next handler
    }
  };

  useEffect(() => {
    const fetchSellersNames = async () => {
      try {
        const response = await axios.get(
          `${baseURL}${btoa("invoice/allsellers")}`
        );
        setSellerNames(response.data);
      } catch (error) {
        console.error("Error fetching seller names", error);
      }
    };

    fetchSellersNames();
  }, []);

  useEffect(() => {
    setLocalFuelBrand("");
    setFileName("");
  }, []);

  // Close modal when clicking outside the modal content
  const handleOutsideClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      // onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  const [activeItem, setActiveItem] = useState("View");

  const handleMenuClick = (item) => {
    setActiveItem(item);
  };

  const menuItems = [{ name: "New PO" }];

  return (
    <>
      <CustomCommonTab items={menuItems} />
      <div
        ref={modalRef}
        className="bg-white rounded-lg w-[50vw] m-auto p-6 relative mt-[20px] mb-[20px]"
        style={{
          boxShadow: "0 0 20px 0 rgba(0, 0, 0,.3)",
        }}
      >
       {/* <CustomNavigation /> */}
       {/* <CustomNavigation onCloseClick={() =>  navigate("/purchases")}  /> */}
       <div className="flex items-center justify-end">
               {/* Home button - bordered/unfilled by default, filled blue on hover */}
               <div
                 className="absolute -top-4 flex items-center justify-center cursor-pointer rounded-full bg-[#cefafe] hover:bg-[#25caed] w-9 h-9 mb-0 mr-3 border-[0.5px] border-[#25caed] hover:border-[#25caed] hover:scale-110 shadow-md transition-all duration-200 group"
                 onClick={() => {
                   navigate("/");
                 }}
                 style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
               >
                 <FaHome className="text-[#25caed] group-hover:text-white transition-colors duration-200" size={16}  />
               </div>
         
               {/* Close Icon - bordered/unfilled by default, filled red on hover */}
               <div
                 className="absolute -top-4 -right-2 bg-red-200 hover:bg-red-500 w-9 h-9 flex items-center justify-center rounded-full cursor-pointer hover:bg-red-700 border-[0.5px] border-red-500 hover:border-red-700 hover:scale-110 shadow-md transition-all duration-200 group"
                 onClick={() => navigate('/OperationsScreen')}
               >
                 <span className="text-red-500 group-hover:text-white text-xl font-bold mb-1.5 transition-colors duration-200">&times;</span>
               </div>
             </div>
        <div className="flex justify-center rounded-md font-bold text-md items-center w-full mx-auto bg-white">
          {/* View */}

          {/* {
          <div
            className={`flex items-center cursor-pointer px-4 py-1 ${activeItem === "Edit" ? "border-b-2 border-green-500 text-green-600" : "text-gray-600"
              } border-b border-gray-300 `}
            onClick={() => { handleMenuClick("Edit"); navigate('/') }}

          >
            <span>Home</span>
          </div>

        } */}
        </div>

        <h2 className="text-xl font-bold text-gray-800 mb-4 text-center">
          PO Upload
        </h2>

        <div className="flex justify-center items-center p-4 space-x-4">
          {cardsData && cardsData.length > 0 ? (
            cardsData.map((seller) => (
              <label
                key={seller.id}
                className={`relative flex flex-col items-center justify-center p-4 border-2 rounded-lg cursor-pointer transition-all duration-300 group ${localFuelBrand === seller.id
                    ? "border-blue-500 shadow-lg"
                    : "border-gray-300"
                  }`}
                style={{ height: "100px", width: "120px" }}
              >
                {/* Hidden Radio Button */}
                <input
                  type="radio"
                  name="brand"
                  value={seller.id}
                  checked={localFuelBrand === seller.id}
                  onChange={() => handleSelectChange(seller.id)}
                  className="absolute top-0 left-0 w-full h-full opacity-0 peer"
                />

                {/* Content */}
                <div className="z-10 text-center ml-1 mr-3">
                  {/* Logo */}
                  <img
                    src={`${baseURL}/img/${seller.logo}`}
                    alt={seller.brandName}
                    style={{ width: "80px", height: "50px" }}
                    className="object-contain mb-2"
                  />
                  {/* Brand Name */}
                  <span className="block text-lg font-semibold text-gray-700">
                    {seller.brandName.charAt(0).toUpperCase() +
                      seller.brandName.slice(1).toLowerCase()}
                  </span>
                </div>

                {/* Tick Mark (Appears when checked) */}
                <div className="absolute bottom-2 right-2 w-6 h-6 bg-blue-500 rounded-full flex items-center justify-center opacity-0 peer-checked:opacity-100 transition-opacity duration-300">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    className="w-4 h-4 text-white"
                  >
                    <path
                      d="M20 6L9 17l-5-5"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </label>
            ))
          ) : (
            <p className="text-gray-500 text-center">No sellers available</p>
          )}
        </div>

        {/* File Upload */}
        {/* <h4 className='text-black font-bold ml-4'>Upload Document</h4> */}
        <div
          style={{
            border: "2px solid #25caed",
            margin: "16px",
            borderRadius: "20px",
            padding: "20px",
          }}
        >
          <div className="flex flex-col items-center justify-center w-full relative m-auto">
            {!fileName && (
              <>
                <div className="flex items-center justify-center w-16 h-16 bg-[#ecfeff] rounded-full pb-2">
                  <svg
                    width="50"
                    height="50"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 16V8M12 8L8 12M12 8L16 12"
                      stroke="#25caed"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M4 16V19C4 19.5523 4.44772 20 5 20H19C19.5523 20 20 19.5523 20 19V16"
                      stroke="#25caed"
                      stroke-width="2"
                      stroke-linecap="round"
                    />
                  </svg>
                </div>

                <p
                  className="mt-2 text-gray-500"
                  style={{ color: "#25caed", fontWeight: 600 }}
                >
                  Drag and drop the file here
                </p>
                <p
                  className="mt-2 text-gray-500"
                  style={{ color: "#25caed", fontWeight: 600 }}
                >
                  Or
                </p>
                <p
                  className="p-2"
                  style={{ color: "#25caed", fontWeight: 600 }}
                >
                  {/* to upload */}
                  Click on file Choose file button below
                </p>
              </>
            )}
            {fileName && (
              <>
                <div className="flex items-center justify-center w-16 h-16 bg-[#ecfeff] rounded-full pb-2">
                  <svg
                    width="50"
                    height="50"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 16V8M12 8L8 12M12 8L16 12"
                      stroke="#25caed"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M4 16V19C4 19.5523 4.44772 20 5 20H19C19.5523 20 20 19.5523 20 19V16"
                      stroke="#25caed"
                      stroke-width="2"
                      stroke-linecap="round"
                    />
                  </svg>
                </div>
                {/* <CloudUpload style={{ color: 'gray', fontSize: 80, marginRight: '8px' }} /> */}
                <p className="mt-2 text-gray-500">
                  Please click on Done to Upload
                </p>
              </>
            )}
            <input
              type="file"
              id="fileInput"
              className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
              onChange={handleFileChange}
              ref={fileInputRef}
              accept=".pdf,application/pdf"
              disabled={!localFuelBrand}
            />
            <div className="flex flex-col w-full mt-2">
              {fileName && <span className="m-auto">{fileName}</span>}
              {
                <label
                  htmlFor="fileInput"
                  className={`bg-blue-500 text-[#25caed] py-2 px-4 rounded cursor-pointer mb-0 m-auto mt-[5px]
                  ${!localFuelBrand
                      ? "bg-white cursor-not-allowed"
                      : "bg-white text-[#25caed]"
                    }
                `}
                  style={{
                    border: "2px solid #25caed",
                    borderRadius: "10px",
                    color: "#25caed",
                    fontWeight: '600'
                  }}
                >
                  Choose File
                </label>
              }
            </div>
          </div>
        </div>

        {/* Error message - only show when there's an error */}
        {errorMessage && (
          <div className="flex justify-start items-center gap-2 font-normal ml-4">
            <span>
              <ErrorOutlineIcon style={{ color: "red", fontSize: 25 }} />
            </span>
            <span>
              <h5 className="text-red-500">{errorMessage}</h5>
            </span>
          </div>
        )}

        {fileName && (
          <div className="flex justify-end items-center gap-4 mt-10 mx-5">
            {/* <button
            onClick={() => { navigate('/purchases') }}
            className=" px-2 py-2 rounded-lg border border-gray-300 bg-white text-gray-500 font-medium hover:bg-gray-100 active:scale-95 transition duration-200 flex justify-between items-center gap-1"
          >
            <span className='mx-1'><X size={18} /></span>  Cancel
          </button> */}
            <button
              onClick={handleUploadImage}
              disabled={!localFuelBrand || !fileName}
              className={`px-2 py-2 rounded-lg text-white font-medium flex items-center justify-center text-center
            ${!localFuelBrand || !fileName
                  ? "bg-blue-300 opacity-50 cursor-not-allowed"
                  : "bg-[#25caed] hover:bg-[#25caed] active:scale-95 transition duration-200"
                }`}
              style={{ backgroundColor: "#25caed" }}
            >
              <span className="my-auto text-center"></span> Upload
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default FileUploadModal;

import React, { useState, useMemo, useEffect, useRef } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { FaChevronLeft, FaChevronRight , FaDownload} from 'react-icons/fa';
import { FaSearch, FaCalendarAlt } from 'react-icons/fa';
import { createColumnHelper ,
  useReactTable, 
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel, } from "@tanstack/react-table";
  import { useSelector , useDispatch } from 'react-redux'; 
  import { FaArrowDownLong , FaArrowUpLong} from "react-icons/fa6";
  import { MdNavigateNext } from "react-icons/md";
  import { MdNavigateBefore } from "react-icons/md";
  import { FaEye, FaTrashAlt } from 'react-icons/fa';
  import config from "../../../config/config";
import axios from 'axios';
import { toast } from 'react-toastify';
import { addDraftAgreements, deleteAllDraftAgreements } from './DraftAgreementsSlice';
import DraftImagesViewModel from './DraftImagesViewModel';
import PurchaseDeleteModel from '../../../components/models/PurchasesDeleteModel';
import FullScreenLoader from '../FullScreenLoader';
import { addToast } from '../ToastBox/ToastBoxMessageSlice';

  const DraftAgreements = () => {
    const baseURL =
    process.env.NODE_ENV === "production"
      ? config.production.baseURL
      : config.development.baseURL;
  const columnHelper = createColumnHelper();
  const [createdDate, setCreatedOnDate] = useState(new Date());
  const [fileName, setFileName] = useState(null);
  const [file, setFile] = useState(null);
  const [rowSelection, setRowSelection] = useState({});
  const [sorting , setSorting] = React.useState([])
  const [filtering , setFiltering] = useState("")
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploadCount, setUploadCount] = useState(0);
  const fileInputRef = useRef(null);
  const [selectedImagePath , setSelectedImagePath] = useState("")
  const [imagesByDate , setImagesByDate] = useState([])
  const [openDeleteModalId, setOpenDeleteModalId] = useState(null);
  const [columnFilters, setColumnFilters] = useState("")
  const businessId = localStorage.getItem("business_id")
  const managerId = localStorage.getItem("user_id")

  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch()

  const allDraftAgreements = useSelector(state => state.DraftAgreements.allDraftAgreements)
  console.log("all purchases" ,  useSelector(state => state));

  useEffect(() => {
    async function fetchDraftAgreements() {
      try {
        const response = await axios.get(
          `${baseURL}${btoa("draftagreement/viewdraftagreement")}/${btoa(businessId)}/${btoa(managerId)}`
        );
        console.log(response, 'image_data')
        dispatch(deleteAllDraftAgreements())
        dispatch(addDraftAgreements(response.data.draftagreements))
        console.log("fetch images" , response);
      } catch (error) {
        console.error("Error fetching receipt images :", error);
      }
    }
    fetchDraftAgreements();
  }, [uploadCount]);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const fileType = selectedFile.type;
  
      if (fileType === 'image/jpeg' || fileType === 'image/jpg' || fileType === 'application/pdf' ) {
        setFileName(selectedFile.name);
        setFile(selectedFile);
        setErrorMessage('');
      } else {
        setErrorMessage('Only JPEG, JPG and Pdf formats are allowed.');
        setFileName('');
        setFile(null);
      }
    } else {
      setFileName('');
      setFile(null);
      setErrorMessage('');
    }
  };
  
  useEffect(() => {
    const currentDate = new Date();
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    const currentMonth = monthNames[currentDate.getMonth()];
    const currentYear = currentDate.getFullYear();
    // setCurrentMonthYear(`${currentMonth} ${currentYear}`);
  }, []); 


  const handleDateChange = (date) => {
    setCreatedOnDate(date);
  };

  const handlePrevDay = () => {
    setCreatedOnDate(new Date(createdDate.setDate(createdDate.getDate() - 1)));
  };

  const handleNextDay = () => {
    const today = new Date();
    const isToday =
      createdDate.getDate() === today.getDate() &&
      createdDate.getMonth() === today.getMonth() &&
      createdDate.getFullYear() === today.getFullYear();
    if (isToday) {
      return;
    }
    setCreatedOnDate(new Date(createdDate.setDate(createdDate.getDate() + 1)));
  };

  const formatDate = (dateStr) => {
    const [year, month, day] = dateStr.split("-");
    return `${month}/${day}/${year}`;
  };
  const formatDateForApi = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const capitalizeFirstLetterWordWords = (str) => {
    return str
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };
  const lowercaseAllWords = (str) => {
    return str
      .toLowerCase()
  };
  const [loading, setLoading] = useState(false); // Add a loading state

  const handleUploadImage = async (e) => {
    setLoading(true)
     console.log("upload image draft" , file);
    e.preventDefault();
    if (!file) {
      alert('Please select a file first!');
      return;
    }
    const formData = new FormData();
    formData.append('receipt', file);
    formData.append('businessId', localStorage.getItem("business_id"));
    formData.append('managerId', localStorage.getItem("user_id"));
    formData.append('selectedDate', formatDateForApi(createdDate));
    formData.append('businessName', "Hp Gas");
  
    try {
      const response = await axios.post(`${baseURL}${btoa("draftagreement/create")}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log(response, 'draftagreementresoponse')
      if(response.data.dataUpdatingStatus === true){
        setFile(null); 
        setFileName(''); 
        fileInputRef.current.value = ''; 
        // toast.success(`Image uploaded successfully` , {autoClose : 2500})
        dispatch(addToast({ type: 'success', message: `Image uploaded successfully` }));
    
        setUploadCount(prevCount => prevCount + 1);
        setCreatedOnDate(new Date())
      }
      if(response.data.dataUpdatingStatus === false){
        setFile(null); 
        setFileName(''); 
        // toast.error(`Image uploading failed` , {autoClose : 2500})
        dispatch(addToast({ type: 'danger', message: `Image uploading failed` }));

      }

      // console.log("upload image response", response);, 
    } catch (error) {
      console.log("upload image failed", error);
    } finally{
      setLoading(false)
    }
  };

  const data = useMemo(() => {
    return allDraftAgreements.map((draftagreement, index) => ({
      ...draftagreement,
      sl_no: index + 1,
      added_on: formatDate(draftagreement ? draftagreement.added_on : ''),
      image_name: capitalizeFirstLetterWordWords(draftagreement ? draftagreement.image_name : ''),
      // contact_name : capitalizeFirstLetterWordWords(vehicle.contact_name),
      // contact_email : lowercaseAllWords(vehicle.contact_email)
    }));
  }, [allDraftAgreements]);

  useEffect(() => {
    // alert(formatDateForApi(createdDate))
    async function fetchImageByDate(){
      try {
        const response = await axios.get(`${baseURL}${btoa("purchase/fetchimagebydate")}/${btoa(formatDateForApi(createdDate))}/${btoa(businessId)}}`);
        setImagesByDate(response.data.image_data)
        // console.log("images by date" , response.data.image_data);
      }catch (error) {
        console.error("error fetching images by date", error) ;
    }
    }
    fetchImageByDate()
  }, [createdDate, uploadCount]);

  const handleView = (row) => {
    console.log('View action for row:', row);
    setSelectedImagePath(row)
    setIsModalOpen(true)
   
  };

  const handleDownload = (fileName) => { 
    const downloadUrl = `${baseURL}uploads/docs/draft_agreements/${fileName}`;
    window.open(downloadUrl, '_blank');
  };
  

  const columns = [
    columnHelper.accessor("sl_no", {
      header: <div className="text-center">Sl no</div>,
    }),
    columnHelper.accessor("uploaded_date", {
      header: "Invoice Date",
      cell: (props) => {
        const { uploaded_date } = props.row.original;
  
        if (!uploaded_date) {
          return <div className="text-center text-gray-500"></div>; // Display "N/A" for missing dates
        }
  
        try {
          const date = new Date(uploaded_date);
          if (isNaN(date.getTime())) {
            throw new Error("Invalid date");
          }
  
          const options = { month: "short", day: "2-digit", year: "numeric" };
          const formattedDate = `${date.toLocaleDateString("en-US", {
            month: "short",
          })}-${date.toLocaleDateString("en-US", {
            day: "2-digit",
          })}-${date.toLocaleDateString("en-US", { year: "numeric" })}`;
          return <div className="text-center">{formattedDate}</div>;
        } catch (error) {
          console.error("Error formatting date:", error);
          return <div className="text-center text-red-500">Invalid Date</div>; // Fallback for invalid date formats
        }
      },
    }),
    columnHelper.accessor("image_name", {
      header: <div className="text-center">Image Name</div>,
      cell: () => (
        <div className="text-center">Fuel Purchased Image</div> // Static cell value
      ),
    }),
    columnHelper.accessor("contact_email", {
      header: <div className="text-center">Action</div>,
      cell: ({ row }) => {
        const { img_file, id } = row.original;
        const isPdf = img_file?.endsWith(".pdf");
  
        return (
          <div className="flex justify-center space-x-3">
            {isPdf ? (
              <button
                onClick={() => handleDownload(img_file)}
                className="text-gray-500 hover:text-blue-700 hover:bg-blue-200 p-2 rounded-full cursor-pointer"
              >
                <FaDownload size={20} />
              </button>
            ) : (
              <button
                onClick={() => handleView(img_file)}
                className="text-gray-500 hover:text-blue-700 hover:bg-blue-200 p-2 rounded-full cursor-pointer"
              >
                <FaEye size={20} />
              </button>
            )}
            <button
              className="text-gray-500 hover:text-red-700 hover:bg-red-200 p-2 rounded-full cursor-pointer"
            >
              <FaTrashAlt size={18} />
            </button>
          </div>
        );
      },
      enableColumnFilter: false,
      enableSorting: false,
    }),
  ];
  

  const handleSortingChange = (columnId) => {
    setSorting((old) => {
      if (old.length && old[0].id === columnId) {
        // Toggle sorting direction
        return [{
          id: columnId,
          desc: !old[0].desc,
        }];
      }
      // Default sorting to descending initially
      return [{ id: columnId, desc: true }];
    });
  };
  
  const table = useReactTable({
    data: data || [],
    columns: columns,
    state: {
      rowSelection: rowSelection,
      globalFilter: filtering,
      sorting: sorting,
    },
    onSortingChange: setSorting,
    onGlobalFilterChange: setFiltering,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onRowSelectionChange: setRowSelection,
    onColumnFiltersChange: setColumnFilters,
    enableRowSelection: true,
    enableColumnFilter: true,
  });
  const currentPage = table.options.state.pagination.pageIndex;

  return (
    <div className='pb-10 pageHeightinvoice'>
            {loading && <FullScreenLoader/>}

          <div>
      <h1 className="font-bold text-xl mt-6"   style={{ 
    maxWidth: '1200px', 
    boxSizing: 'border-box', 
    marginLeft: '42px', 
    color: '#444444' // Lighter black color
  }}>Draft Agreements</h1>
  </div>
        
        <div className="w-full ml-10">
        {/* <ScrollingMessage /> */}
        </div>
      <nav className="bg-gray-50 px-4 py-0 pt-3  flex items-center justify-between">
      
        <div className="flex-grow flex justify-center w-full mt-4 pb-4 space-x-4">
          <button onClick={handlePrevDay} className="">
            <FaChevronLeft size={24} className="text-gray-700" />
            </button>
          <div 
            className="h-11 text-gray-700 font-normal w-48 border border-gray-300 border-b-4 border-b-blue-400 rounded-t-md rounded-b-md flex items-center shadow"
          >
           <div 
              className='flex justify-between items-center cursor-pointer'
              onClick={() => document.getElementById('created_on').focus()}
            >
              <DatePicker
                id="created_on"
                name="created_on"
                selected={createdDate}
                required
                maxDate={new Date()}
                onChange={handleDateChange}
                dateFormat="MMMM dd, yyyy"
                placeholderText="From"
                className="w-full outline-none border-none rounded-r-lg cursor-pointer focus:outline-none focus:ring-0 bg-transparent"
                onKeyDown={(e) => { e.preventDefault(); }}
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-5 h-5 mr-2"
              >
                <path d="M12.75 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM7.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM8.25 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM9.75 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM10.5 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM12.75 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM14.25 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 13.5a.75.75 0 100-1.5.75.75 0 000 1.5z" />
                <path
                  fillRule="evenodd"
                  d="M6.75 2.25A.75.75 0 017.5 3v1.5h9V3A.75.75 0 0118 3v1.5h.75a3 3 0 013 3v11.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V7.5a3 3 0 013-3H6V3a.75.75 0 01.75-.75zm13.5 9a1.5 1.5 0 00-1.5-1.5H5.25a1.5 1.5 0 00-1.5 1.5v7.5a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5v-7.5z"
                  clipRule="evenodd"
                />
              </svg>
            </div>

          </div>
          <button onClick={handleNextDay} className="ml-2">
            <FaChevronRight size={24} className="text-gray-700" />
          </button>
        </div>
      </nav>

      <div className="p-6">
      <div className="flex flex-col items-start w-80 max-w-md mx-auto my-5">
      <label className="mb-2 text-lg font-bold" htmlFor="fileInput">
        Upload Invoice*
      </label>

      <div className="flex items-center w-full relative">
        <input
          type="file"
          id="fileInput"
          className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
          onChange={handleFileChange}
          ref={fileInputRef}
          accept="image/pdf/*" // Allow all image file types
        />
        <div className="flex w-full">
          <input
            type="text"
            placeholder="Choose file"
            className="w-full p-2 border border-gray-300 rounded-l-lg cursor-pointer"
            value={fileName}
            readOnly
          />
          <label
            htmlFor="fileInput"
            className="bg-blue-500 text-white py-2 px-4 rounded-r-lg cursor-pointer mb-0"
          >
            Browse
          </label>
        </div>
      </div>
      {errorMessage && (
        <p className="text-red-500 font-medium text-sm">{errorMessage}</p>
      )}
      <button 
        onClick={handleUploadImage}
        className="w-full mt-4 bg-white border-2 font-semibold border-blue-500 text-blue-500 py-2 cursor-pointer rounded-lg hover:bg-blue-500 hover:text-white">
        Submit
      </button>
    </div>

    </div>

          <div className='ml-6 mr-6 mt-10'>
             <table className="w-full table-auto bg-transparent rounded-xl">
                  <thead className="bg-gray-500/20 text-gray-500">
                    {table.getHeaderGroups().map((headerGroup) => (
                      <tr key={headerGroup.id} className="text-center text-xs">
                        {headerGroup.headers.map((header, index) => {
                          const isFirstHeader = index === 0;
                          const isLastHeader = index === headerGroup.headers.length - 1;
                          const headerClasses = [
                            'p-3 font-bold uppercase',
                            isFirstHeader ? 'rounded-l-xl' : '',
                            isLastHeader ? 'rounded-r-xl' : '',
                          ];

                          const isSorted = header.column.getIsSorted();
                          const showSortingIcons = header.column.columnDef.enableSorting !== false;
                          const ArrowUpIcon = (
                            <FaArrowUpLong
                              className={`${isSorted === 'asc' ? 'text-black' : 'text-gray-400'}`}
                              size={12}
                            />
                          );
                          const ArrowDownIcon = (
                            <FaArrowDownLong
                              className={`${isSorted === 'desc' ? 'text-black' : 'text-gray-400'}`}
                              size={12}
                            />
                          );

                          return (
                            <th
                              key={header.id}
                              className={headerClasses.join(' ')}
                              colSpan={header.colSpan}
                              onClick={() => showSortingIcons && handleSortingChange(header.column.id)}
                            >
                              <div className="flex items-center justify-center">
                                {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                                {showSortingIcons && (
                                  <div className="flex items-center ml-2">
                                    {ArrowDownIcon}
                                    {ArrowUpIcon}
                                  </div>
                                )}
                              </div>
                            </th>
                          );
                        })}
                      </tr>
                    ))}
                  </thead>
                  <tbody>
                    {table.getRowModel().rows.map((row) => (
                      <tr key={row.id} className="cursor-pointer text-center hover:bg-gray-500/10">
                        {row.getVisibleCells().map((cell) => (
                          <td key={cell.id} className="p-4 font-semibold text-[#081159] text-[14px]">
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
              </table>    
            </div>

                <div className="flex items-center mr-7 mt-6 gap-2 justify-end">
                  <button
                    className={`px-4 py-2 rounded-l-lg focus:outline-none flex items-center ${
                      table.getCanPreviousPage()
                        ? " hover:bg-[#114FFF] hover:text-white cursor-pointer"
                        : " cursor-not-allowed"
                    }`}
                    onClick={() => table.previousPage()}
                    disabled={!table.getCanPreviousPage()}
                  >
                    <MdNavigateBefore className="w-6 h-6" />
                    Prev
                  </button>
                  {[...Array(table.getPageCount()).keys()].map((page) => (
                    <button
                      key={page}
                      onClick={() => table.setPageIndex(Number(page))}
                      className={`px-4 py-2  focus:outline-none ${
                        currentPage === page
                          ? " rounded-full bg-[#3479E0] text-white "
                          : " rounded-full bg-white dark:text-black hover:bg-[#3479E0]/50 hover:text-white"
                      }`}
                    >
                      {page + 1}
                    </button>
                  ))}
                  <button
                    className={`px-4 py-2 rounded-r-lg  focus:outline-none flex items-center justify-center${
                      table.getCanNextPage()
                        ? " hover:bg-[#114FFF] hover:text-white cursor-pointer"
                        : "bg-gray-300 cursor-not-allowed"
                    }`}
                    onClick={() => table.nextPage()}
                    disabled={!table.getCanNextPage()}
                  >
                    Next
                    <MdNavigateNext className="w-6 h-6" />
                  </button>
                </div>


                <DraftImagesViewModel isModalOpen={isModalOpen} closeModal={closeModal} selectedImagePath={selectedImagePath} />
                {openDeleteModalId && (
                  <PurchaseDeleteModel
                    onClose={() => setOpenDeleteModalId(null)}
                    id={openDeleteModalId}
                    imageName={"Fuel Purchased Image"}
                    resetRowSelection={table.resetRowSelection}
                  />
                )}


    </div>
  )
}

export default DraftAgreements;

// import React, { useState , useMemo , useEffect } from 'react';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
// import Tabs from '@mui/material/Tabs';
// import Tab from '@mui/material/Tab';
// import Box from '@mui/material/Box';
// import config from "../../config/config";
// import { useSelector } from 'react-redux';
// import axios from 'axios';
// import { FaCalendarAlt } from 'react-icons/fa';
// import { toast } from 'react-toastify';
// import { createColumnHelper ,
//     useReactTable, 
//     flexRender,
//     getCoreRowModel,
//     getFilteredRowModel,
//     getPaginationRowModel,
//     getSortedRowModel, } from "@tanstack/react-table";
// import { FaArrowDownLong , FaArrowUpLong} from "react-icons/fa6";
// import { FaEye, FaTrashAlt } from 'react-icons/fa';
// import { addNotification , deleteAllNotification , deleteNotification } from '../../store/adminNotificationSlice';
// import PopUpModalForDeleteNotification from '../models/NotificationDeleteModel';
// import { useNavigate } from 'react-router-dom';
// import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { TimePicker } from '@mui/x-date-pickers/TimePicker';
// import StartTimePickerViews from "./timepickers/StartTimePicker"
// import EndTimePickerViews from "./timepickers/EndTimePicker"
// import { useDispatch } from "react-redux";
// import { addToast } from '../pages/ToastBox/ToastBoxMessageSlice';
// const Notification = () => {
  
//   const [selectedBusiness, setSelectedBusiness] = useState('');
//   const [startDate, setStartDate] = useState(null);
//   const [endDate, setEndDate] = useState(null);
//   const [scrollingMessage, setScrollingMessage] = useState('');
//   const [sorting , setSorting] = React.useState([])
//   const [rowSelection, setRowSelection] = useState({});
//   const [filtering , setFiltering] = useState("")
//   const [columnFilters, setColumnFilters] = useState("")
//   const [businessDetails , setBusinessDetails] = useState([])
//   const [messages , setMessages] = useState([])
//   const [openDeleteModalId, setOpenDeleteModalId] = useState(null);
//   const [businessNameForDeleteModel , setBusinessNameForDeleteModel] = useState("")
//   const [notificationType , setNotificationType] = useState("")
//   const [count , setCount]  = useState(0)
//   const [isNotificationTypeReadOnly, setIsNotificationTypeReadOnly] = useState(false);
//   const [deploymentStartDate, setDeploymentStartDate] = useState(null);
//   const [deploymentEndDate, setDeploymentEndDate] = useState(null);
//   const [startTime, setStartTime] = useState("");
//   const [endTime, setEndTime] = useState("");
  
//   const allPurchases = useSelector(state => state.purchase.allPurchases)
//   const business_id = localStorage.getItem("business_id")
//   const user_id = localStorage.getItem("user_id")

//   const navigate = useNavigate();

//   const [value, setValue] = React.useState('scrollingmessages');

//   const dispatch = useDispatch()
//   const allNotifications = useSelector((state => state.notification.allNotifications))
//   // console.log("all notifications" , allNotifications);

//   const handleChange = (event, newValue) => {
//     setValue(newValue);
//   };

//   const baseURL =
//   process.env.NODE_ENV === "production"
//     ? config.production.baseURL
//     : config.development.baseURL;

// const columnHelper = createColumnHelper();


// // const handleStatusToggle = async (id, currentStatus) => {
// //     const newStatus = currentStatus === '1' ? '0' : '1';
// //     try {
// //       await axios.post(`${baseURL}${btoa("")}`, { id, newStatus });
// //       const response = await axios.post(`${baseURL}${btoa(`scrollingmsg/activatescrollingmessage`)}` ,{
// //         businessId : selectedBusiness,
// //         userId : user_id,
// //         userName: "admin",
// //         rowId: 2

// //       })
// //       console.log(response)

// //       // setMessages(messages.map(message => message.id === id ? { ...message, activation_status: newStatus } : message));
// //       toast.success("Status updated successfully", { autoClose: 2500 });
// //     } catch (error) {
// //       console.error("Error updating status", error);
// //       toast.error("Error updating status", { autoClose: 2500 });
// //     }
// //   };

//   const columns = [
//     columnHelper.accessor("sl_no", {
//       header: <div className="text-center">Sl no</div>,
//     }),
//     columnHelper.accessor("business_name", {
//       header: <div className="text-center">Business</div>,
//     }),
//     columnHelper.accessor("start_date", {
//       header: <div className="text-center">Start Date</div>,
//       cell: (props) => {
//         const date = new Date(props.row.original.end_date);
//         const options = { month: 'short', day: '2-digit', year: 'numeric' };
//         const formattedDate = `${date.toLocaleDateString("en-US", { month: 'short' })}-${date.toLocaleDateString("en-US", { day: '2-digit' })}-${date.toLocaleDateString("en-US", { year: 'numeric' })}`;
//         return (
//           <div>{formattedDate}</div>
//         );
//       }
//     }),
//     columnHelper.accessor("end_date", {
//       header: <div className="text-center">End Date</div>,
//       cell: (props) => {
//         const date = new Date(props.row.original.end_date);
//         const options = { month: 'short', day: '2-digit', year: 'numeric' };
//         const formattedDate = `${date.toLocaleDateString("en-US", { month: 'short' })}-${date.toLocaleDateString("en-US", { day: '2-digit' })}-${date.toLocaleDateString("en-US", { year: 'numeric' })}`;
//         return (
//           <div>{formattedDate}</div>
//         );
//       }
//     }),
//     columnHelper.accessor("mesg_type", {
//       header: <div className="text-center">Message type</div>,
//     }),
    
//     columnHelper.accessor("message", {
//       header: <div className="text-left">Scrolling Messages</div>,
//       cell: (props) => (
//         <div className="text-left w-96">{capitalizeFirstLetterWordWords(props.row.original.message)}</div>
//       ),
//     }),
//     columnHelper.accessor("activation_status", {
//       header: <div className="text-center">Status</div>,
//       cell: ({ row }) => (
//         <button
//           className={`p-2 rounded-2xl h-9 w-16 ${row.original.activation_status === '1' ? 'bg-green-200 text-green-700' : 'bg-red-200 text-red-700'}`}
//           // onClick={() => handleStatusToggle(row.original.id, row.original.activation_status)}
//         >
//           {row.original.activation_status === '1' ? 'Active' : 'Inactive'}
//         </button>
//       ),
//     }),
//     columnHelper.accessor('""', {
//       header: <div className="text-center">Action</div>,
//       cell: ({ row }) => (
//         <div className="flex justify-center space-x-1">
//           <button className="text-gray-500 hover:text-blue-700 hover:bg-blue-200 p-2 rounded-full cursor-pointer" 
//             onClick={() => navigate(`/editnotifications/${row.original.id}`)}>
//                  <svg
//                     xmlns="http://www.w3.org/2000/svg"
//                     viewBox="0 0 24 24"
//                     fill="currentColor"
//                     className="w-5 h-5"
//                   >
//                     <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32l8.4-8.4z" />
//                     <path d="M5.25 5.25a3 3 0 00-3 3v10.5a3 3 0 003 3h10.5a3 3 0 003-3V13.5a.75.75 0 00-1.5 0v5.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5V8.25a1.5 1.5 0 011.5-1.5h5.25a.75.75 0 000-1.5H5.25z" />
//                   </svg>
//           </button>
//           <button 
//               className="text-gray-500 hover:text-red-700 hover:bg-red-200 p-2 rounded-full cursor-pointer"
//               onClick={() => {
//                 setOpenDeleteModalId(row.original.id);
//                 setBusinessNameForDeleteModel(row.original.business_name);
//               }}
              
//               // onClick={() => console.log(row.original)}
//               >
//             <FaTrashAlt size={18} />
//           </button>
//         </div>
//       ),
//       enableColumnFilter: false,
//       enableSorting: false,
//     }),
//   ];


//   const formatDate = (dateStr) => {
//     const [year, month, day] = dateStr.split("-");
//     return `${month}/${day}/${year}`;
//   };
//   const formatDateForApi = (date) => {
//     if (!date) return null; // Handle null date
//     const year = date.getFullYear();
//     const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
//     const day = String(date.getDate()).padStart(2, '0');
//     return `${year}-${month}-${day}`;
//   };
//   const formattedFromDate = (startDate instanceof Date && !isNaN(startDate.getTime()))
//   ? startDate.toISOString()
//   : new Date().toISOString();

//   const formattedToDate = (endDate instanceof Date && !isNaN(endDate.getTime()))
//   ? endDate.toISOString()
//   : new Date().toISOString();



//   const capitalizeFirstLetterWordWords = (str) => {
//     return str
//       .split(' ')
//       .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
//       .join(' ');
//   };
//   const data = useMemo(() => {
//     return (allNotifications || []).map((message, index) => ({
//       ...message,
//       sl_no: index + 1,
//       business_name : capitalizeFirstLetterWordWords(message.business_name),
//       start_date: formatDate(message.start_date),
//       end_date: formatDate(message.end_date),
//       message: capitalizeFirstLetterWordWords(message.message)
//     }));
//   }, [allNotifications]);

//   const handleSortingChange = (columnId) => {
//     setSorting((old) => {
//       if (old.length && old[0].id === columnId) {
//         return [{
//           id: columnId,
//           desc: !old[0].desc,
//         }];
//       }
//       return [{ id: columnId, desc: true }];
//     });
//   };
  
//   const table = useReactTable({
//     data: data || [],
//     columns: columns,
//     state: {
//       rowSelection: rowSelection,
//       globalFilter: filtering,
//       sorting: sorting,
//     },
//     onSortingChange: setSorting,
//     onGlobalFilterChange: setFiltering,
//     getCoreRowModel: getCoreRowModel(),
//     getSortedRowModel: getSortedRowModel(),
//     getFilteredRowModel: getFilteredRowModel(),
//     onRowSelectionChange: setRowSelection,
//     onColumnFiltersChange: setColumnFilters,
//     enableRowSelection: true,
//     enableColumnFilter: true,
//   });
//   const currentPage = table.options.state.pagination.pageIndex;

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(
//           `${baseURL}/${btoa("business/view")}`
//         );
//         // console.log("business fetched details is ", response);
//         setBusinessDetails(response.data.business_data);
//       } catch (error) {
//         console.error("Error fetching owner details", error);
//       }
//     };
//     fetchData();
//   }, []);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(
//           `${baseURL}/${btoa("scrollingmsg/view")}`
//         );
//         console.log("fetched messages ", response);
//         dispatch(deleteAllNotification())
//         dispatch(addNotification(response.data.scrollingmsgs))
//         setMessages(response.data.scrollingmsgs);
//       } catch (error) {
//         console.error("Error fetching messages", error);
//       }
//     };
//     fetchData();
//   }, [count]);

  

//   const handleSubmit =  async (e) => {
//     e.preventDefault();
//     // Handle form submission
//     const data = {
//         businessId : selectedBusiness,
//         userId : user_id,
//         startDate: formatDateForApi(startDate),
//         endDate: formatDateForApi(endDate),
//         message : scrollingMessage,
//         userName : localStorage.getItem("userName"),
//         notificationType : notificationType,
//         fromDate: formatDateForApi(deploymentStartDate),
//         fromTime: startTime,
//         // toDate: formatDateForApi(deploymentEndDate),
//         toTime: endTime
//     };
    
//     console.log("data" , data);

//     try {
//         const response  = await axios.post(`${baseURL}${btoa("scrollingmsg/create")}` , data)
//         console.log("response" , response); 
//         if(response.data){
//           // toast.success("Message posted successfully" , {autoClose : 2500})
//      dispatch(addToast({ type: 'success', message:"Message posted successfully" }));       
          
//           setCount(count => count + 1)
//           setSelectedBusiness("")
//           setStartDate(null)
//           setEndDate(null)
//           setScrollingMessage("")
//           setNotificationType("")
//         }
       
//     } 
//     catch (error) {
//         console.log("error posting scrolling messages" , error)
//         // toast.error("error posting messages" , {autoClose : 2500})
//         dispatch(addToast({ type: 'danger', message:"error posting messages" }));       

//     }
//   };

//   useEffect(() => {
//     if (notificationType === "Release") {
//         setSelectedBusiness("0")     
//         setIsNotificationTypeReadOnly(true);
//     } else {
//       setIsNotificationTypeReadOnly(false);
//     }
//   }, [notificationType]);


//   const handleStartTimeChange = (timeString) => {
//     setStartTime(timeString);
//   };

//   const handleEndTimeChange = (timeString) => {
//     setEndTime(timeString);
//   };

//   return (
   
//     <div className="">
//         <Box sx={{ width: '100%', fontFamily: 'sans-serif' }}>
//             <Tabs
//                 value={value}
//                 onChange={handleChange}
//                 textColor="primary"
//                 indicatorColor="primary"
//                 aria-label="secondary tabs example"
//                 sx={{
//                     '& .MuiTab-root': {
//                         fontFamily: 'sans-serif',
//                         height: '70px',
//                         '&:hover': {
//                             color: 'blue',
//                             borderBottom: '2px solid blue',
//                         },
//                     },
//                     '& .Mui-selected': {
//                         color: 'blue',
//                     },
//                     '& .MuiTabs-indicator': {
//                         backgroundColor: 'blue',
//                     },
//                 }}
//             >
//                 <Tab value="scrollingmessages" label="SCROLLING MESSAGES" />
//                 <Tab value="smsnotifications" label="SMS NOTIFICATIONS" />
//             </Tabs>
//         </Box>

//         <div className=" flex justify-center">
//             <form onSubmit={handleSubmit} className="bg-white rounded-lg overflow-hidden">
//                 <div className="shadow-lg p-6">

//                   <div className='flex flex-row space-x-4'>

//                   <div className="mb-4 w-full">
//                         <label htmlFor="facility" className="block text-gray-700 font-semibold mb-2">
//                             Notification type
//                         </label>
//                         <select
//                               id="notification"
//                               value={notificationType}
//                               onChange={(e) => setNotificationType(e.target.value)}
//                                className="w-full px-3 py-2 border-none rounded-md shadow-lg"
//                               required
//                               style={{ boxShadow: '0 0 8px rgba(0, 0, 0, 0.2)' }}
//                               // disabled={isNotificationTypeReadOnly}
//                             >

//                             <option value="">--Select--</option>
//                             <option value="Info">Info</option>
//                             <option value="Release">Release</option>
//                         </select>
//                     </div>

//                     <div className="mb-4 w-full">
//                         <label htmlFor="facility" className="block text-gray-700 font-semibold mb-2">
//                             Select Business*
//                         </label>
//                         <select
//                             id="business"
//                             value={selectedBusiness}
//                             onChange={(e) => setSelectedBusiness(e.target.value)}
//                             className={`w-full px-3 py-2 border-none rounded-md shadow-lg ${isNotificationTypeReadOnly ? 'bg-gray-200' : ''}`}
//                             required
//                             style={{ boxShadow: '0 0 8px rgba(0, 0, 0, 0.2)' }}
//                             disabled={isNotificationTypeReadOnly}
//                         >

//                             <option value="">--Select--</option>
//                             <option value="0">All Business</option>
//                             {businessDetails.map((selected , index)=>  (
//                                  <option value={selected.business_id} key={index}>{selected.business_name}</option>
//                              ))}

//                         </select>
//                     </div>

                    

//                     </div>

//                     <div className="flex flex-row w-full space-x-4 mb-3">
//                       <div className="mb-4">
//                         <label htmlFor="startDate" className="block text-gray-700 font-semibold mb-2">
//                           Notification start date
//                         </label>
//                         <div
//                           className="w-72 flex items-center rounded-md bg-white shadow-sm relative"
//                           onClick={() => document.getElementById('startDate').focus()}
//                           style={{ boxShadow: '0 0 8px rgba(0, 0, 0, 0.2)' }}
//                         >
//                           <DatePicker
//                             id="startDate"
//                             selected={startDate}
//                             minDate={new Date()}
//                             autoComplete="off"
//                             onChange={(date) => setStartDate(date)}
//                             className="w-full px-3 py-2 border-none rounded-md focus:outline-none focus:ring-0"
//                             placeholderText="Select Start Date"
//                             dateFormat="MM/dd/yyyy"
//                           />
//                           <FaCalendarAlt className="text-gray-500 absolute right-3 pointer-events-none" />
//                         </div>
//                       </div>

//                       <div className="mb-4">
//                         <label htmlFor="endDate" className="block text-gray-700 font-semibold mb-2">
//                         Notification end date
//                         </label>
//                         <div
//                           className="w-72 flex items-center rounded-md bg-white shadow-sm relative"
//                           onClick={() => document.getElementById('endDate').focus()}
//                           style={{ boxShadow: '0 0 8px rgba(0, 0, 0, 0.2)' }}
//                         >
//                           <DatePicker
//                             id="endDate"
//                             selected={endDate}
//                             minDate={new Date()}
//                             autoComplete="off"
//                             onChange={(date) => setEndDate(date)}
//                             className="w-full px-3 py-2 border-none rounded-md focus:outline-none focus:ring-0"
//                             placeholderText="Select End Date"
//                             dateFormat="MM/dd/yyyy"
//                           />
//                           <FaCalendarAlt className="text-gray-500 absolute right-3 pointer-events-none" />
//                         </div>
//                       </div>
//                     </div>
                      
//                     <div>
//                       {selectedBusiness === "0" && notificationType === "Release" && (
//                         <div>
//                           {/* <h2 className="text-lg font-semibold text-gray-600 mb-1">Select Deployment Date and Time</h2> */}
//                           <hr className="border-t border-gray-300" />
                          
//                             <div className="flex flex-row w-full space-x-4 mt-2">
//                               <div className="mb-4">
//                                 <label htmlFor="deploymentStartDate" className="block text-gray-700 font-semibold mb-2">
//                                   Select deployment date
//                                 </label>
//                                 <div
//                                   className="w-72 flex items-center rounded-md bg-white shadow-sm relative"
//                                   style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
//                                 >
//                                   <DatePicker
//                                     id="deploymentStartDate"
//                                     selected={deploymentStartDate}
//                                     minDate={new Date()}
//                                     autoComplete='off'
//                                     onChange={(date) => setDeploymentStartDate(date)}
//                                     className="w-full px-3 py-2 border-none rounded-md focus:outline-none focus:ring-0"
//                                     placeholderText="Select Start Date"
//                                     dateFormat="MM/dd/yyyy"
//                                   />
//                                   <FaCalendarAlt className="text-gray-500 absolute right-3 pointer-events-none" />
//                                 </div>
//                               </div>

//                               <div className="mb-3 flex space-x-3">
//                                 <StartTimePickerViews onStartTimeChange={handleStartTimeChange} />
//                                 <EndTimePickerViews onEndTimeChange={handleEndTimeChange} />
//                               </div>

//                             </div>

//                         </div>
//                       )}
//                     </div>


                  

//                     <div className="mb-4">
//                         <label htmlFor="scrollingMessage" className="block text-gray-700 font-semibold mb-2">
//                             Scrolling Message
//                         </label>
//                         <textarea
//                             id="scrollingMessage"
//                             value={scrollingMessage}
//                             onChange={(e) => setScrollingMessage(e.target.value)}
//                             className="w-[593px] px-3 py-2 border-none rounded-md shadow-sm"
//                             placeholder="Enter Scrolling Message"
//                             rows="4"
//                             style={{ boxShadow: '0 0 8px rgba(0, 0, 0, 0.2)' }}
//                         ></textarea>
//                     </div>
                    
//                     <button 
//                         // onClick={handleUploadImage}
//                         className="w-80 ml-36 mt-2 bg-white border-2 font-semibold border-blue-500 text-blue-500 py-2 cursor-pointer rounded-lg hover:bg-blue-500 hover:text-white shadow-md">
//                         Submit
//                     </button>
//                 </div>
//             </form>
//         </div>
        
//         <div className='ml-6 mr-6 mt-10'>
//              <table className="w-full table-auto bg-transparent rounded-xl">
//                   <thead className="bg-gray-500/20 text-gray-500">
//                     {table.getHeaderGroups().map((headerGroup) => (
//                       <tr key={headerGroup.id} className="text-center text-xs">
//                         {headerGroup.headers.map((header, index) => {
//                           const isFirstHeader = index === 0;
//                           const isLastHeader = index === headerGroup.headers.length - 1;
//                           const headerClasses = [
//                             'p-3 font-bold uppercase',
//                             isFirstHeader ? 'rounded-l-xl' : '',
//                             isLastHeader ? 'rounded-r-xl' : '',
//                           ];

//                           const isSorted = header.column.getIsSorted();
//                           const showSortingIcons = header.column.columnDef.enableSorting !== false;
//                           const ArrowUpIcon = (
//                             <FaArrowUpLong
//                               className={`${isSorted === 'asc' ? 'text-black' : 'text-gray-400'}`}
//                               size={12}
//                             />
//                           );
//                           const ArrowDownIcon = (
//                             <FaArrowDownLong
//                               className={`${isSorted === 'desc' ? 'text-black' : 'text-gray-400'}`}
//                               size={12}
//                             />
//                           );

//                           return (
//                             <th
//                               key={header.id}
//                               className={headerClasses.join(' ')}
//                               colSpan={header.colSpan}
//                               onClick={() => showSortingIcons && handleSortingChange(header.column.id)}
//                             >
//                               <div className="flex items-center justify-center">
//                                 {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
//                                 {showSortingIcons && (
//                                   <div className="flex items-center ml-2">
//                                     {ArrowDownIcon}
//                                     {ArrowUpIcon}
//                                   </div>
//                                 )}
//                               </div>
//                             </th>
//                           );
//                         })}
//                       </tr>
//                     ))}
//                   </thead>
//                   <tbody>
//                     {table.getRowModel().rows.map((row) => (
//                       <tr key={row.id} className="cursor-pointer text-center hover:bg-gray-500/10">
//                         {row.getVisibleCells().map((cell) => (
//                           <td key={cell.id} className="p-4 font-semibold text-[#081159] text-[14px]">
//                             {flexRender(cell.column.columnDef.cell, cell.getContext())}
//                           </td>
//                         ))}
//                       </tr>
//                     ))}
//                   </tbody>
//               </table>    
//          </div>

          
        
//                 {openDeleteModalId && (
//                   <PopUpModalForDeleteNotification
//                     onClose={() => setOpenDeleteModalId(null)}
//                     id={openDeleteModalId}
//                     businessNameForDeleteModel={businessNameForDeleteModel}
//                     resetRowSelection={table.resetRowSelection}

//                   />
//                 )}

//     </div>


//   );
// };

// export default Notification;




import React, { useState, useMemo, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import config from "../../config/config";
import { useSelector } from 'react-redux';
import axios from 'axios';
import { FaCalendarAlt } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { createColumnHelper,
    useReactTable, 
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel, } from "@tanstack/react-table";
import { FaArrowDownLong, FaArrowUpLong } from "react-icons/fa6";
import { FaEye, FaTrashAlt } from 'react-icons/fa';
import { addNotification, deleteAllNotification, deleteNotification } from '../../store/adminNotificationSlice';
import PopUpModalForDeleteNotification from '../models/NotificationDeleteModel';
import { useNavigate } from 'react-router-dom';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import StartTimePickerViews from "./timepickers/StartTimePicker"
import EndTimePickerViews from "./timepickers/EndTimePicker"
import { useDispatch } from "react-redux";
import { addToast } from '../pages/ToastBox/ToastBoxMessageSlice';

const Notification = () => {
  
  const [selectedBusiness, setSelectedBusiness] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [scrollingMessage, setScrollingMessage] = useState('');
  const [sorting, setSorting] = React.useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [filtering, setFiltering] = useState("");
  const [columnFilters, setColumnFilters] = useState("");
  const [businessDetails, setBusinessDetails] = useState([]);
  const [messages, setMessages] = useState([]);
  const [openDeleteModalId, setOpenDeleteModalId] = useState(null);
  const [businessNameForDeleteModel, setBusinessNameForDeleteModel] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const [count, setCount] = useState(0);
  const [isNotificationTypeReadOnly, setIsNotificationTypeReadOnly] = useState(false);
  const [deploymentStartDate, setDeploymentStartDate] = useState(null);
  const [deploymentEndDate, setDeploymentEndDate] = useState(null);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formErrors, setFormErrors] = useState({
    notificationType: "",
    selectedBusiness: "",
    startDate: "",
    endDate: "",
    scrollingMessage: "",
    deploymentStartDate: "",
    startTime: "",
    endTime: ""
  });
  
  const allPurchases = useSelector(state => state.purchase.allPurchases);
  const business_id = localStorage.getItem("business_id");
  const user_id = localStorage.getItem("user_id");

  const navigate = useNavigate();

  const [value, setValue] = React.useState('scrollingmessages');

  const dispatch = useDispatch();
  const allNotifications = useSelector((state => state.notification.allNotifications));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const baseURL =
    process.env.NODE_ENV === "production"
      ? config.production.baseURL
      : config.development.baseURL;

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor("sl_no", {
      header: <div className="text-center">Sl no</div>,
    }),
    columnHelper.accessor("business_name", {
      header: <div className="text-center">Business</div>,
    }),
    columnHelper.accessor("start_date", {
      header: <div className="text-center">Start Date</div>,
      cell: (props) => {
        const date = new Date(props.row.original.start_date);
        const formattedDate = `${date.toLocaleDateString("en-US", { month: 'short' })}-${date.toLocaleDateString("en-US", { day: '2-digit' })}-${date.toLocaleDateString("en-US", { year: 'numeric' })}`;
        return (
          <div>{formattedDate}</div>
        );
      }
    }),
    columnHelper.accessor("end_date", {
      header: <div className="text-center">End Date</div>,
      cell: (props) => {
        const date = new Date(props.row.original.end_date);
        const formattedDate = `${date.toLocaleDateString("en-US", { month: 'short' })}-${date.toLocaleDateString("en-US", { day: '2-digit' })}-${date.toLocaleDateString("en-US", { year: 'numeric' })}`;
        return (
          <div>{formattedDate}</div>
        );
      }
    }),
    columnHelper.accessor("mesg_type", {
      header: <div className="text-center">Message type</div>,
    }),
    
    columnHelper.accessor("message", {
      header: <div className="text-left">Scrolling Messages</div>,
      cell: (props) => (
        <div className="text-left w-96">{capitalizeFirstLetterWordWords(props.row.original.message)}</div>
      ),
    }),
    columnHelper.accessor("activation_status", {
      header: <div className="text-center">Status</div>,
      cell: ({ row }) => (
        <button
          className={`p-2 rounded-2xl h-9 w-16 ${row.original.activation_status === '1' ? 'bg-green-200 text-green-700' : 'bg-red-200 text-red-700'}`}
        >
          {row.original.activation_status === '1' ? 'Active' : 'Inactive'}
        </button>
      ),
    }),
    columnHelper.accessor('""', {
      header: <div className="text-center">Action</div>,
      cell: ({ row }) => (
        <div className="flex justify-center space-x-1">
          <button className="text-gray-500 hover:text-blue-700 hover:bg-blue-200 p-2 rounded-full cursor-pointer" 
            onClick={() => navigate(`/editnotifications/${row.original.id}`)}>
                 <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-5 h-5"
                  >
                    <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32l8.4-8.4z" />
                    <path d="M5.25 5.25a3 3 0 00-3 3v10.5a3 3 0 003 3h10.5a3 3 0 003-3V13.5a.75.75 0 00-1.5 0v5.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5V8.25a1.5 1.5 0 011.5-1.5h5.25a.75.75 0 000-1.5H5.25z" />
                  </svg>
          </button>
          <button 
              className="text-gray-500 hover:text-red-700 hover:bg-red-200 p-2 rounded-full cursor-pointer"
              onClick={() => {
                setOpenDeleteModalId(row.original.id);
                setBusinessNameForDeleteModel(row.original.business_name);
              }}
              >
            <FaTrashAlt size={18} />
          </button>
        </div>
      ),
      enableColumnFilter: false,
      enableSorting: false,
    }),
  ];

  const formatDate = (dateStr) => {
    if (!dateStr) return '';
    const dateParts = dateStr.split("-");
    if (dateParts.length !== 3) return dateStr;
    
    const [year, month, day] = dateParts;
    return `${month}/${day}/${year}`;
  };

  const formatDateForApi = (date) => {
    if (!date) return null; // Handle null date
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const capitalizeFirstLetterWordWords = (str) => {
    if (!str) return '';
    return str
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  const data = useMemo(() => {
    return (allNotifications || []).map((message, index) => ({
      ...message,
      sl_no: index + 1,
      business_name: capitalizeFirstLetterWordWords(message.business_name),
      message: capitalizeFirstLetterWordWords(message.message)
    }));
  }, [allNotifications]);

  const handleSortingChange = (columnId) => {
    setSorting((old) => {
      if (old.length && old[0].id === columnId) {
        return [{
          id: columnId,
          desc: !old[0].desc,
        }];
      }
      return [{ id: columnId, desc: true }];
    });
  };
  
  const table = useReactTable({
    data: data || [],
    columns: columns,
    state: {
      rowSelection: rowSelection,
      globalFilter: filtering,
      sorting: sorting,
    },
    onSortingChange: setSorting,
    onGlobalFilterChange: setFiltering,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onRowSelectionChange: setRowSelection,
    onColumnFiltersChange: setColumnFilters,
    enableRowSelection: true,
    enableColumnFilter: true,
  });

  const currentPage = table.options.state.pagination?.pageIndex || 0;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${baseURL}/${btoa("business/view")}`
        );
        setBusinessDetails(response.data.business_data);
      } catch (error) {
        console.error("Error fetching owner details", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${baseURL}/${btoa("scrollingmsg/view")}`
        );
        console.log("fetched messages ", response);
        dispatch(deleteAllNotification());
        dispatch(addNotification(response.data.scrollingmsgs));
        setMessages(response.data.scrollingmsgs);
      } catch (error) {
        console.error("Error fetching messages", error);
      }
    };
    fetchData();
  }, [count, dispatch]);

  // Validation function
  const validateForm = () => {
    let valid = true;
    const errors = {
      notificationType: "",
      selectedBusiness: "",
      startDate: "",
      endDate: "",
      scrollingMessage: "",
      deploymentStartDate: "",
      startTime: "",
      endTime: ""
    };

    if (!notificationType) {
      errors.notificationType = "Notification type is required";
      valid = false;
    }

    if (!selectedBusiness) {
      errors.selectedBusiness = "Business selection is required";
      valid = false;
    }

    if (!startDate) {
      errors.startDate = "Start date is required";
      valid = false;
    }

    if (!endDate) {
      errors.endDate = "End date is required";
      valid = false;
    }

    if (endDate && startDate && endDate < startDate) {
      errors.endDate = "End date must be after start date";
      valid = false;
    }

    if (!scrollingMessage.trim()) {
      errors.scrollingMessage = "Scrolling message is required";
      valid = false;
    } else if (scrollingMessage.length > 500) {
      errors.scrollingMessage = "Message must be less than 500 characters";
      valid = false;
    }

    // Validate deployment info for Release notification type
    if (notificationType === "Release" && selectedBusiness === "0") {
      if (!deploymentStartDate) {
        errors.deploymentStartDate = "Deployment date is required";
        valid = false;
      }
      
      if (!startTime) {
        errors.startTime = "Start time is required";
        valid = false;
      }

      if (!endTime) {
        errors.endTime = "End time is required";
        valid = false;
      }
    }

    setFormErrors(errors);
    return valid;
  };

  // Check if form is valid for enabling submit button
  const isFormValid = () => {
    // Basic form validation
    if (!notificationType || !selectedBusiness || !startDate || !endDate || !scrollingMessage.trim()) {
      return false;
    }

    // Special validation for Release notification
    if (notificationType === "Release" && selectedBusiness === "0") {
      if (!deploymentStartDate || !startTime || !endTime) {
        return false;
      }
    }

    // Check if end date is after start date
    if (endDate && startDate && endDate < startDate) {
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;
    setIsSubmitting(true);

    if (!validateForm()) {
      dispatch(addToast({ type: 'warning', message: "Please fill all required fields correctly" }));
      return;
    }

    // Handle form submission
    const data = {
        businessId: selectedBusiness,
        userId: user_id,
        startDate: formatDateForApi(startDate),
        endDate: formatDateForApi(endDate),
        message: scrollingMessage,
        userName: localStorage.getItem("userName"),
        notificationType: notificationType,
        fromDate: formatDateForApi(deploymentStartDate),
        fromTime: startTime,
        toTime: endTime
    };
    
    console.log("data", data);

    try {
        const response = await axios.post(`${baseURL}${btoa("scrollingmsg/create")}`, data);
        console.log("response", response); 
        if(response.data){
          dispatch(addToast({ type: 'success', message: "Message posted successfully" }));       
          
          setCount(count => count + 1);
          setSelectedBusiness("");
          setStartDate(null);
          setEndDate(null);
          setScrollingMessage("");
          setNotificationType("");
          setDeploymentStartDate(null);
          setStartTime("");
          setEndTime("");
          setIsNotificationTypeReadOnly(false);
        }
    } 
    catch (error) {
        console.log("error posting scrolling messages", error);
        dispatch(addToast({ type: 'danger', message: "Error posting messages" }));
    }

    finally {
      setIsSubmitting(false);
    }

  };

  useEffect(() => {
    if (notificationType === "Release") {
        setSelectedBusiness("0");     
        setIsNotificationTypeReadOnly(true);
    } else {
      setIsNotificationTypeReadOnly(false);
    }
  }, [notificationType]);

  const handleStartTimeChange = (timeString) => {
    setStartTime(timeString);
  };

  const handleEndTimeChange = (timeString) => {
    setEndTime(timeString);
  };

  return (
    <div className="">
        <Box sx={{ width: '100%', fontFamily: 'sans-serif' }}>
            <Tabs
                value={value}
                onChange={handleChange}
                textColor="primary"
                indicatorColor="primary"
                aria-label="secondary tabs example"
                sx={{
                    '& .MuiTab-root': {
                        fontFamily: 'sans-serif',
                        height: '70px',
                        '&:hover': {
                            color: 'blue',
                            borderBottom: '2px solid blue',
                        },
                    },
                    '& .Mui-selected': {
                        color: 'blue',
                    },
                    '& .MuiTabs-indicator': {
                        backgroundColor: 'blue',
                    },
                }}
            >
                <Tab value="scrollingmessages" label="SCROLLING MESSAGES" />
                <Tab value="smsnotifications" label="SMS NOTIFICATIONS" />
            </Tabs>
        </Box>

        <div className="flex justify-center">
            <form onSubmit={handleSubmit} className="bg-white rounded-lg overflow-hidden">
                <div className="shadow-lg p-6">

                  <div className='flex flex-row space-x-4'>

                    <div className="mb-4 w-full">
                        <label htmlFor="facility" className="block text-gray-700 font-semibold mb-2">
                            Notification type<span className="text-red-500">*</span>
                        </label>
                        <select
                              id="notification"
                              value={notificationType}
                              onChange={(e) => setNotificationType(e.target.value)}
                              className={`w-full px-3 py-2 border-none rounded-md shadow-lg ${formErrors.notificationType ? 'border-red-500 ring-1 ring-red-500' : ''}`}
                              required
                              style={{ boxShadow: '0 0 8px rgba(0, 0, 0, 0.2)' }}
                            >

                            <option value="">--Select--</option>
                            <option value="Info">Info</option>
                            <option value="Release">Release</option>
                        </select>
                        {formErrors.notificationType && <p className="text-red-500 text-xs mt-1">{formErrors.notificationType}</p>}
                    </div>

                    <div className="mb-4 w-full">
                        <label htmlFor="facility" className="block text-gray-700 font-semibold mb-2">
                            Select Business<span className="text-red-500">*</span>
                        </label>
                        <select
                            id="business"
                            value={selectedBusiness}
                            onChange={(e) => setSelectedBusiness(e.target.value)}
                            className={`w-full px-3 py-2 border-none rounded-md shadow-lg ${isNotificationTypeReadOnly ? 'bg-gray-200' : ''} ${formErrors.selectedBusiness ? 'border-red-500 ring-1 ring-red-500' : ''}`}
                            required
                            style={{ boxShadow: '0 0 8px rgba(0, 0, 0, 0.2)' }}
                            disabled={isNotificationTypeReadOnly}
                        >

                            <option value="">--Select--</option>
                            <option value="0">All Business</option>
                            {businessDetails.map((selected, index) => (
                                 <option value={selected.business_id} key={index}>{selected.business_name}</option>
                             ))}

                        </select>
                        {formErrors.selectedBusiness && <p className="text-red-500 text-xs mt-1">{formErrors.selectedBusiness}</p>}
                    </div>
                  </div>

                  <div className="flex flex-row w-full space-x-4 mb-3">
                    <div className="mb-4">
                      <label htmlFor="startDate" className="block text-gray-700 font-semibold mb-2">
                        Notification start date<span className="text-red-500">*</span>
                      </label>
                      <div
                        className={`w-72 flex items-center rounded-md bg-white shadow-sm relative ${formErrors.startDate ? 'border border-red-500 ring-1 ring-red-500' : ''}`}
                        onClick={() => document.getElementById('startDate').focus()}
                        style={{ boxShadow: '0 0 8px rgba(0, 0, 0, 0.2)' }}
                      >
                        <DatePicker
                          id="startDate"
                          selected={startDate}
                          minDate={new Date()}
                          autoComplete="off"
                          onChange={(date) => setStartDate(date)}
                          className="w-full px-3 py-2 border-none rounded-md focus:outline-none focus:ring-0"
                          placeholderText="Select Start Date"
                          dateFormat="MM/dd/yyyy"
                        />
                        <FaCalendarAlt className="text-gray-500 absolute right-3 pointer-events-none" />
                      </div>
                      {formErrors.startDate && <p className="text-red-500 text-xs mt-1">{formErrors.startDate}</p>}
                    </div>

                    <div className="mb-4">
                      <label htmlFor="endDate" className="block text-gray-700 font-semibold mb-2">
                        Notification end date<span className="text-red-500">*</span>
                      </label>
                      <div
                        className={`w-72 flex items-center rounded-md bg-white shadow-sm relative ${formErrors.endDate ? 'border border-red-500 ring-1 ring-red-500' : ''}`}
                        onClick={() => document.getElementById('endDate').focus()}
                        style={{ boxShadow: '0 0 8px rgba(0, 0, 0, 0.2)' }}
                      >
                        <DatePicker
                          id="endDate"
                          selected={endDate}
                          minDate={startDate || new Date()}
                          autoComplete="off"
                          onChange={(date) => setEndDate(date)}
                          className="w-full px-3 py-2 border-none rounded-md focus:outline-none focus:ring-0"
                          placeholderText="Select End Date"
                          dateFormat="MM/dd/yyyy"
                        />
                        <FaCalendarAlt className="text-gray-500 absolute right-3 pointer-events-none" />
                      </div>
                      {formErrors.endDate && <p className="text-red-500 text-xs mt-1">{formErrors.endDate}</p>}
                    </div>
                  </div>
                      
                  <div>
                    {selectedBusiness === "0" && notificationType === "Release" && (
                      <div>
                        <hr className="border-t border-gray-300" />
                        
                          <div className="flex flex-row w-full space-x-4 mt-2">
                            <div className="mb-4">
                              <label htmlFor="deploymentStartDate" className="block text-gray-700 font-semibold mb-2">
                                Select deployment date<span className="text-red-500">*</span>
                              </label>
                              <div
                                className={`w-72 flex items-center rounded-md bg-white shadow-sm relative ${formErrors.deploymentStartDate ? 'border border-red-500 ring-1 ring-red-500' : ''}`}
                                style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
                              >
                                <DatePicker
                                  id="deploymentStartDate"
                                  selected={deploymentStartDate}
                                  minDate={new Date()}
                                  autoComplete='off'
                                  onChange={(date) => setDeploymentStartDate(date)}
                                  className="w-full px-3 py-2 border-none rounded-md focus:outline-none focus:ring-0"
                                  placeholderText="Select Deployment Date"
                                  dateFormat="MM/dd/yyyy"
                                />
                                <FaCalendarAlt className="text-gray-500 absolute right-3 pointer-events-none" />
                              </div>
                              {formErrors.deploymentStartDate && <p className="text-red-500 text-xs mt-1">{formErrors.deploymentStartDate}</p>}
                            </div>

                            <div className="mb-3 flex space-x-3">
                              <div>
                                <label className="block text-gray-700 font-semibold mb-2">
                                  Start time<span className="text-red-500">*</span>
                                </label>
                                <div className={formErrors.startTime ? 'border border-red-500 ring-1 ring-red-500 rounded-md' : ''}>
                                  <StartTimePickerViews onStartTimeChange={handleStartTimeChange} />
                                </div>
                                {formErrors.startTime && <p className="text-red-500 text-xs mt-1">{formErrors.startTime}</p>}
                              </div>
                              
                              <div>
                                <label className="block text-gray-700 font-semibold mb-2">
                                  End time<span className="text-red-500">*</span>
                                </label>
                                <div className={formErrors.endTime ? 'border border-red-500 ring-1 ring-red-500 rounded-md' : ''}>
                                  <EndTimePickerViews onEndTimeChange={handleEndTimeChange} />
                                </div>
                                {formErrors.endTime && <p className="text-red-500 text-xs mt-1">{formErrors.endTime}</p>}
                              </div>
                            </div>

                          </div>

                      </div>
                    )}
                  </div>

                  <div className="mb-4">
                      <label htmlFor="scrollingMessage" className="block text-gray-700 font-semibold mb-2">
                          Scrolling Message<span className="text-red-500">*</span>
                      </label>
                      <textarea
                          id="scrollingMessage"
                          value={scrollingMessage}
                          onChange={(e) => setScrollingMessage(e.target.value)}
                          className={`w-[593px] px-3 py-2 border-none rounded-md shadow-sm ${formErrors.scrollingMessage ? 'border border-red-500 ring-1 ring-red-500' : ''}`}
                          placeholder="Enter Scrolling Message"
                          rows="4"
                          style={{ boxShadow: '0 0 8px rgba(0, 0, 0, 0.2)' }}
                      ></textarea>
                      {formErrors.scrollingMessage && <p className="text-red-500 text-xs mt-1">{formErrors.scrollingMessage}</p>}
                      <p className="text-gray-500 text-xs mt-1">{scrollingMessage.length}/500 characters</p>
                  </div>
                  
                  <button 
                      type="submit"
                      disabled={!isFormValid() || isSubmitting}
                      className={`w-80 ml-36 mt-2 border-2 font-semibold py-2 cursor-pointer rounded-lg shadow-md ${isFormValid() 
                        ? 'bg-white border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white' 
                        : 'bg-gray-200 border-gray-400 text-gray-500 cursor-not-allowed'}`}>
                       {isSubmitting ? 'Submitting...' : 'Submit'}
                  </button>
                </div>
            </form>
        </div>
        
        <div className='ml-6 mr-6 mt-10'>
             <table className="w-full table-auto bg-transparent rounded-xl">
                  <thead className="bg-gray-500/20 text-gray-500">
                    {table.getHeaderGroups().map((headerGroup) => (
                      <tr key={headerGroup.id} className="text-center text-xs">
                        {headerGroup.headers.map((header, index) => {
                          const isFirstHeader = index === 0;
                          const isLastHeader = index === headerGroup.headers.length - 1;
                          const headerClasses = [
                            'p-3 font-bold uppercase',
                            isFirstHeader ? 'rounded-l-xl' : '',
                            isLastHeader ? 'rounded-r-xl' : '',
                          ];

                          const isSorted = header.column.getIsSorted();
                          const showSortingIcons = header.column.columnDef.enableSorting !== false;
                          const ArrowUpIcon = (
                            <FaArrowUpLong
                              className={`${isSorted === 'asc' ? 'text-black' : 'text-gray-400'}`}
                              size={12}
                            />
                          );
                          const ArrowDownIcon = (
                            <FaArrowDownLong
                              className={`${isSorted === 'desc' ? 'text-black' : 'text-gray-400'}`}
                              size={12}
                            />
                          );

                          return (
                            <th
                              key={header.id}
                              className={headerClasses.join(' ')}
                              colSpan={header.colSpan}
                              onClick={() => showSortingIcons && handleSortingChange(header.column.id)}
                            >
                              <div className="flex items-center justify-center">
                                {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                                {showSortingIcons && (
                                  <div className="flex items-center ml-2">
                                    {ArrowDownIcon}
                                    {ArrowUpIcon}
                                  </div>
                                )}
                              </div>
                            </th>
                          );
                        })}
                        </tr>
                      ))}
                    </thead>
                    <tbody>
                      {table.getRowModel().rows.map((row) => (
                        <tr key={row.id} className="cursor-pointer text-center hover:bg-gray-500/10">
                          {row.getVisibleCells().map((cell) => (
                            <td key={cell.id} className="p-4 font-semibold text-[#081159] text-[14px]">
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                </table>    
                
                {table.getRowModel().rows.length === 0 && (
                  <div className="text-center p-4 text-gray-500">No notifications found</div>
                )}
           </div>
  
           {openDeleteModalId && (
             <PopUpModalForDeleteNotification
               onClose={() => setOpenDeleteModalId(null)}
               id={openDeleteModalId}
               businessNameForDeleteModel={businessNameForDeleteModel}
               resetRowSelection={table.resetRowSelection}
             />
           )}
  
      </div>
    );
  };
  
  export default Notification;
import React, { useEffect, useState, useRef } from "react";
import Revenue from "./Revenue";
import ScrollToTop from "./ScrollTop";
import {
  createColumnHelper,
  useReactTable,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel
} from "@tanstack/react-table";
import { useSortBy } from "react-table";
import TableMenu from "./TableMenu";
import axios from "axios";
import dayjs from "dayjs";
import { FaEye, FaTrashAlt, FaDownload, FaEdit } from "react-icons/fa";

import { Navigate, json, useNavigate } from "react-router-dom";
import TableInvo from "./TableInvo";
import { Tooltip } from "react-tippy";
import DatePicker2 from "./DatePicker2";
import DatePicker1 from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DatePicker, Space } from "antd";

import IndeterminateCheckbox from "./IndeterminateCheckbox";
import { FiDownload } from "react-icons/fi";
import { AiFillMessage } from "react-icons/ai";
import { AiFillMail } from "react-icons/ai";

import { logDOM } from "@testing-library/react";
import InvoiceViewModal from "./InvoiceViewModal";
import config from "../../config/config";
import { useDispatch, useSelector } from "react-redux";
import {
  addInvoice,
  addDraftInvoice,
  deleteAllInvoices
} from "../../store/invoiceSlice";
import PopUpModal from "./InvoiceDeleteModal";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { FaArrowDownLong, FaArrowUpLong } from "react-icons/fa6";
import { MdFilterList } from "react-icons/md";
import Badge from "@mui/material/Badge";
import PopUpModalForSendInvoice from "./invoiceSendModel";
import ScrollingMessage from "./ScrollingMessage";
import { showToastForUpdateInvoice } from "../toasts/toastForInvoice";
import ToastNotificationBox from "./ToastBox/ToastNotificationBox";
import InvoicePayModal from "./InvoicePayModal";

import TextField from "@mui/material/TextField"; // Make sure this import is at the top
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { FaHome } from "react-icons/fa";
import { colors } from "@mui/material";
function DeliveryPriceDashBoard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [filtering, setFiltering] = useState("");
  const [isfilterOpen, setIsfilterOpen] = useState(false);

  const [rightcardata, setrightcardata] = useState([]);
  const [isModalOpencard, setIsModalOpencard] = useState(false);

  const [isCostToolTip, setIsCostToolTip] = useState(false);
  //   const cardsData =
  // sellerNames.length > 0 ?
  // sellerNames.map((sellerName, index) => ({
  //   id: sellerName.brand_id,
  //   brandName: sellerName.seller_name, // Assuming brand_name is a property
  //   logo: sellerName.brand_logo,
  //   gallons: sellerName.total_gallons, // Adjust gallons data as needed
  //   borderColor: colors[index % colors.length],
  //   total_amount:sellerName.total_amount
  // }))
  // :[];
  const [activeCard, setActiveCard] = useState('freight_charge');

  // 2. Use useEffect to set the initial state
  useEffect(() => {
    setActiveCard('freight_charge');
  }, []);
  const cardsData = [
    {
      id: 1,
      brandName: "Sunoco",
      logo: "sunoco.png", // Assuming 'sunoco.png' is a string representing the path to the logo
      gallons: 1524, // Adjust gallons data as needed
      borderColor: "green", // 'green' should be a string
      total_amount: 5478
    },
    {
      id: 2, // Changed the ID to 2 to avoid duplicate IDs
      brandName: "Shell",
      logo: "shell.png", // Assuming 'shell.png' is another logo path
      gallons: 1400, // Adjust gallons data as needed
      borderColor: "blue", // Changed the borderColor to differentiate
      total_amount: 4800
    }
  ];

  const columnHelper = createColumnHelper();
  const [productOptions, setProductOptions] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [openDeleteModalId, setOpenDeleteModalId] = useState(null);
  const [openSendInvoiceModalId, setOpenSendInvoiceModalId] = useState(null);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [clicked, setClicked] = useState(null);
  const [enterinvoiceinputstyles, setenterinputinvoicestyles] = useState("");

  const [invoiceTypeStyles, setinvoicetypestyles] = useState("");
  const [customerstyles, setcustomerStyles] = useState("");

  const isEditActive = selectedRows.length === 1;
  const isDeleteActive = selectedRows.length > 0;
  const [invoiceData, setInvoiceData] = useState([]);
  const [openModalId, setOpenModalId] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [originalItemData, setOriginalItemData] = useState([]);
  const [businessType, setBusinessType] = useState();

  const [rowSelection, setRowSelection] = React.useState({});
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [sorting, setSorting] = React.useState([]);
  const [columnFilters, setColumnFilters] = useState("");

  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [filteredStatus, setFilteredStatus] = useState("all");
  const [selectedDateFrom, setSelectedDateFrom] = useState("");
  const [selectedDateTo, setSelectedDateTo] = useState("");
  const [selectedInvoiceNumber, setSelectedInvoiceNumber] = useState("");

  const user_id = localStorage.getItem("user_id");
  const userRole = localStorage.getItem("user_role");
  const business_id = localStorage.getItem("business_id");
  const managerId = localStorage.getItem("user_id");
  const [triggerFetch, setTriggerFetch] = useState(false);
  // const [customerFiltering , setCustomerFiltering] = useState("")
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [status, setStatus] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customerEmail, setCustomerEmail] = useState("");
  const [payModalInvoice, setpayModalInvoice] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  // Function to handle scroll event
  const handleScroll = () => {
    if (window.scrollY > 220) {
      setIsScrolled(true); // Add background when scrolling
    } else {
      setIsScrolled(false); // Remove background when at the top
    }
  };

  const formatNumberWithCommas = (number) => {
    return new Intl.NumberFormat("en-US").format(number);
  };
  // Adding the scroll event listener
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    setClicked("draft");
  }, []);
  const invoicesAll = useSelector((state) => state.invoice?.allInvoices[0]);
  // const fetchStatus = useSelector(state => state.invoice?.status);
  console.log(invoicesAll, "fetchststus");

  const [tableData, setTableData] = useState([]);
  // console.log('tabledata', tableData);
  // useEffect(() => {
  //   if (invoicesAll) {
  //     const formattedData = invoicesAll.map(invoice => ({
  //       ...invoice,
  //       invoice_number: (invoice.invoice_number),
  //       final_invoice_amount: Number(invoice.final_invoice_amount),
  //       final_total_amount : Number(invoice.final_total_amount)
  //     }));
  //     // setTableData(formattedData);
  //   }
  // }, [invoicesAll]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const requestData = {
          businessId: localStorage.getItem("business_id"),
          monthName: null,
          year: null
        };

        const response = await axios.post(
          `${baseURL}${btoa("deliverycharges/datatableview")}`,
          requestData,
          { headers: { "Content-Type": "application/json" } }
        );
        const processedData = [];
console.log(response, 'response')
Object.entries(response.data.deliveryChargesDataTable).forEach(
  ([date, customers]) => {
    console.log(date, customers, 'checking');
    Object.entries(customers).forEach(
      ([customerName, customerData]) => {
        console.log(customerName, 'customername');
        // Access deliverycharges from customerData, not from customers
        const charges = customerData.deliverycharges[0];
        console.log(charges, 'chargeschargescharges');
        
        // Add row data
        processedData.push({
          added_on: date,
          customer: customerName,
          customer_id: customerData.customer_id,
          freight_charge: charges.freight_charge || "—",
          freight_surcharge: charges.freight_surcharge || "—",
          business_id: customerData.business_id,
          business_name: customerData.business_name
        });
      }
    );
  }
);

        setTableData(processedData);
        console.log("Success response:", response.data);
      } catch (error) {
        console.error("Error fetching fuel prices:", error);
      }
    };

    fetchData();
  }, []);
  // const scrollingMessages = useSelector((state) => state.scrollingMessage?.message[0]);
  // console.log("invoicess all" , invoicesAll);
  // console.log("message from store " , scrollingMessages);

  const [value, setValue] = React.useState("draft");
  const handleChange = (newValue) => {
    // if(newValue === "overdue") return;
    setClicked(newValue);
    setValue(newValue);
    setFilteredStatus(newValue);
  };

  const handleFilterClick = () => {
    setIsFilterVisible(!isFilterVisible);
  };
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const baseURL =
    process.env.NODE_ENV === "production"
      ? config.production.baseURL
      : config.development.baseURL;
  const businessId = localStorage.getItem("business_id");
  const datePickerFromRef = useRef(null);
  const datePickerToRef = useRef(null);

  const handleIconClick = (ref) => {
    if (ref.current) {
      ref.current.setFocus();
    }
  };

  const handleDateChangeFrom = (from) => {
    console.log(from, "from date.....");
    if (!from || !dayjs(from).isValid()) {
      // If the date is invalid or null, clear the state
      setSelectedDateFrom(null);
      return;
    }
    setSelectedDateFrom(from);
  };

  const handleDateChangeTo = (to) => {
    if (!to || !dayjs(to).isValid()) {
      // If the date is invalid or null, clear the state
      setSelectedDateTo(null);
      return;
    }
    setSelectedDateTo(to);
  };

  const handleDeleteInvoices = async () => {
    if (selectedRows.length > 0) {
    }
  };
  const handlePayModalInvoice = () => {
    setpayModalInvoice(true);
  };

  const handleDeleteModalInvoice = (invoiceId) => {
    // alert(selectedRows.invoice_number)
    if (selectedRows) {
      // console.log("sel" , selectedRows);
      setOpenDeleteModalId(
        table.getSelectedRowModel().flatRows[0]?.original.invoice_number
      );
    }
  };

  // useEffect(() => {
  //   async function fetchCustomerOptions() {
  //     try {
  //       const response = await axios.get(
  //         `${baseURL}/${btoa("customer/view")}/${btoa(businessId)}`
  //       );
  //       setProductOptions(response.data.customers_data);
  //     } catch (error) {
  //       console.error("Error fetching customer options:", error);
  //     }
  //   }
  //   fetchCustomerOptions();
  // }, []);
  const [businessDate, setBusinessDate] = useState("");
  const [businessname, setBusinessname] = useState("");

  useEffect(() => {
    axios
      .get(
        `${baseURL}/${btoa("business/profile")}/${btoa(business_id)}/${btoa(
          user_id
        )}/${btoa(userRole)}`
      )
      .then((response) => {
        // console.log("response businesstype" , response);
        const Type = response.data.business_data[0].business_type;
        setBusinessType(Type);
        setBusinessDate(response.data.business_data[0].added_on);
        setBusinessname(response.data.business_data[0].business_name);
      })
      .catch((error) => {
        console.error("Error fetching business data:", error);
      });
  }, []);




  function formatDatefordatepicker(date) {
    // const day = date.getDate();
    // const month = date.getMonth() + 1; // Months are zero-based
    // const year = date.getFullYear().toString(); // Extract last two digits of year
    // return `${month}-${day}-${year}`;
    const year = date.$y;
    const month = String(date.$M + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(date.$D).padStart(2, "0");
    return `${month}-${day}-${year}`;
  }

  // Define a ref to track the initial render
  const initialRender = useRef(true);
  let debounceTimer;

  useEffect(() => {
    // Define a debounced version of fetchData
    const debouncedFetchData = () => {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => {
        // fetchDataByInvoice(selectedInvoiceNumber);
      }, 300);
    };

    // Check if selectedInvoiceNumber is truthy before executing the debounced fetch
    if (selectedInvoiceNumber) {
      debouncedFetchData();
    }

    // Clean up function to clear the timeout on unmount or when selectedInvoiceNumber changes
    return () => clearTimeout(debounceTimer);
  }, [selectedInvoiceNumber]);

  // useEffect(()=>{
  //     if(selectedRows)
  //   fetchDataByInvoice()
  // },[])
  const [isSubtotalExpanded, setSubtotalExpanded] = useState(false);
  const [isTaxesExpanded, setTaxesExpanded] = useState(false);

  const toggleSubtotal = (e) => {
    e.preventDefault();
    setSubtotalExpanded(!isSubtotalExpanded); // Toggle Subtotal
    setTaxesExpanded(false); // Ensure Taxes is closed
  };

  const toggleTaxes = (e) => {
    e.preventDefault();
    setTaxesExpanded(!isTaxesExpanded); // Toggle Taxes
    setSubtotalExpanded(false); // Ensure Subtotal is closed
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const [year, month, day] = dateString.split('-');
    return `${month}-${day}-${year}`;
  };
  // const handleStatusChange = (selectedStatus) => {
  //   if (selectedStatus === "all") {
  //     // alert("all");
  //     setFilteredStatus(selectedStatus);
  //   } else {
  //     // alert(selectedStatus);
  //     setFilteredStatus(selectedStatus);
  //     // setSelectedRows([]);

  //   }
  // };

  const capitalizeFirstLetterWordWords = (str) => {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  const handleSortingChange = (columnId) => {
    setSorting((old) => {
      if (old.length && old[0].id === columnId) {
        // If it is, toggle the sorting direction
        return [
          {
            id: columnId,
            desc: !old[0].desc
          }
        ];
      }

      // If the columnId is not in the sorting state, set it as descending by default
      return [{ id: columnId, desc: true }];
    });
  };
  const [expandedRow, setExpandedRow] = useState(null);




  const columns = [
    columnHelper.accessor("added_on", {
      header: "Date",
      cell: (props) => {
        const dateString = props.row.original.added_on;
        if (!dateString) {
          return <div>{""}</div>;
        }

        const formattedDate = formatDate(dateString);
        return <div>{formattedDate}</div>;
      },
      enableColumnFilter: false,
      enableSorting: true
    }),

    columnHelper.accessor("customer", {
      header: <div className="text-center w-30">Customer</div>,
      cell: (props) => (
        <div className="text-center w-30">{props.row.original.customer}</div>
      ),
      enableColumnFilter: false,
      enableSorting: true
    }),

    columnHelper.accessor("freight_charge", {
      header: <div className="text-center w-30">Freight Charge</div>,
      cell: (props) => (
        
        <div className="text-center w-30">${" "}{props.row.original.freight_charge}</div>
      ),
      enableColumnFilter: false,
      enableSorting: true
    }),

    columnHelper.accessor("freight_surcharge", {
      header: <div className="text-center w-30">Freight SurCharge</div>,
      cell: (props) => (
        <div className="text-center w-30">${" "}{props.row.original.freight_surcharge}</div>
      ),
      enableColumnFilter: false,
      enableSorting: true
    }),

 
  ];
  const formatDate1 = (dateString) => {
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();

    return `${month}-${day}-${year}`;
  };

  const table = useReactTable({
    data: tableData || [],
    columns: columns,
    state: {
      rowSelection: rowSelection,
      globalFilter: filtering,
      sorting: sorting
    },
    onSortingChange: setSorting,
    onGlobalFilterChange: setFiltering,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onRowSelectionChange: setRowSelection,
    onColumnFiltersChange: setColumnFilters,
    enableRowSelection: true,
    enableColumnFilter: true
  });

  // useEffect(()=>{
  //   const fetchDataByInvoiceForCustomerEmail = async () => {
  //     const invoiceNumbers = table.getSelectedRowModel().flatRows[0]?.original.invoice_number;
  //     try {
  //       const apiUrl = `${baseURL}${btoa("invoice/fetchinvoicebyinvooiocenumber")}/${btoa(invoiceNumbers)}/${btoa(businessId)}`;
  //       const response = await axios.get(apiUrl);
  //       // console.log("invoice for customer email: " , response);
  //       setCustomerEmail(response.data.customerData[0].contact_email)
  //     } catch (error) {
  //       console.error("Error fetching invoice for email", error);
  //       if (error.response.data === "") {
  //           setInvoiceData([])
  //       }
  //     }
  //   };
  //   fetchDataByInvoiceForCustomerEmail()
  // },[table.getSelectedRowModel()])

  useEffect(() => {
    const selected = table
      .getSelectedRowModel()
      .flatRows.map((row) => row.original);
    // if(selected){
    //   fetchDataByInvoice()
    // }
    // console.log("selected rows", selected);
    setSelectedRows(selected);
  }, [rowSelection]);



  // Effect to reset row selection if necessary
  useEffect(() => {
    if (filteredStatus) {
      // console.log("Filtered status changed", filteredStatus);
      table.resetRowSelection();
    }
  }, [filteredStatus]);

  // Reset row selection function
  const resetRowSelection = () => {
    table.resetRowSelection();
  };
  const [activeItem, setActiveItem] = useState("View");
  const [deliveryChargesChange, setDeliveryPriceChange] = useState({});
  useEffect(() => {
    async function fetchFuelGradePrices() {
      try {
        const response = await axios.post(
          `${baseURL}/${btoa(
            "businessdeliverycharges/deliverychargesdashboardcards"
          )}`,
          { businessId: businessId } 
        );
        console.log(response, 'responseresponse')
        setDeliveryPriceChange(response.data.deliverychargesChange || {});
      } catch (error) {
        console.error("Error fetching fuel grade prices:", error);
      }
    }
    fetchFuelGradePrices();
  }, [baseURL, businessId]);
 const deliveryPriceChange1 = 
     {
        " Charge": [
            {
                "business_id": "29",
                "product_id": "1",
                "old_unit_price": null,
                "old_date": null,
                "new_unit_price": "8.00000",
                "added_on": "2025-03-10",
                "product_name": "Regular"
            }
        ],
        " Surcharge": [
            {
                "business_id": "29",
                "product_id": "5",
                "old_unit_price": null,
                "old_date": null,
                "new_unit_price": "4.00000",
                "added_on": "2025-03-10",
                "product_name": "Premium"
            }
        ],
      
    }


    const getPriceChange = (oldValue, newValue) => {
      const oldPrice = parseFloat(oldValue);
      const newPrice = parseFloat(newValue);
      return newPrice - oldPrice;
    };
  
    const colors = ["text-purple-500", "text-green-500", "text-blue-500", "text-red-500", "text-yellow-500"];
  return (
    <div className="flex justify-center items-center flex-col w-full pb-5 ">
      <ScrollingMessage />

      <div className="w-full">
        {/* <h1 className="font-bold text-xl"  style={{ 
    maxWidth: '1200px', 
    boxSizing: 'border-box', 
    marginLeft: '8px', 
    color: '#444444' // Lighter black color
  }}>Invoice Dashboard</h1> */}
        <nav aria-label="breadcrumb">
          <ol className="flex space-x-2 text-md font-bold text-gray-700 ml-[10px] mt-[20px] ] font-[15px]">
            <li className="font-[15px]">
              {/* <a  href="/operations" className="text-blue-600 hover:text-blue-800"> */}
              Setup
              {/* </a> */}
            </li>
            <li>
              <span className="text-gray-400 font-[15px]">{">>"}</span>
            </li>
            <li className="font-[15px]">
              {/* <a href="/purchases" className="text-blue-600 hover:text-blue-800"> */}
              Delivery Price DashBoard
              {/* </a> */}
            </li>
          </ol>
        </nav>

        <div>
          {/* <div className="bg-gray-100 flex mb-4 justify-center p-4 rounded-md font-bold text-md items-center w-full mx-auto pb-2 pt-0 ">
         <div
           className={`flex items-center cursor-pointer px-4 py-1 ${
             activeItem === "View" ? "border-b-2 border-green-500 text-green-600" : "text-gray-600"
           } border-b border-gray-300  `}
           onClick={() => handleMenuClick("View")}
         >
           <span>Pricing</span>
         </div>
         <div
             className={`flex items-center cursor-pointer px-4 py-[7px] ${
               activeItem === "Home" ? "border-b-2 border-green-500 text-green-600" : "text-gray-600"
             } border-b border-gray-300`}
             onClick={() => {handleMenuClick("Home"); navigate('/')}}
           >
             <FaHome className="text-lg" />
           </div>
   
       </div> */}
            <div className="flex justify-start space-x-4 p-1 mb-2 mt-4">
      {/* Freight Charge Card - Comparing old_freightcharge with freight_charge */}
      <div
  className={`flex flex-col items-center justify-between w-64 bg-white rounded-lg px-4 py-2 relative hover:scale-105 hover:border-l-4 hover:border-purple-500 transition-all duration-300 ${
    activeCard === 'freight_charge' ? 'border-l-4 border-purple-500' : ''
  }`}
  style={{ boxShadow: "0 0 20px 0 rgba(0, 0, 0,.2)", height: "90px" }}
  onClick={() => setActiveCard('freight_charge')}
>
        <div className="flex justify-between w-full">
          {/* Top Left - Charge Type */}
          <span className="text-[16px] text-gray-500 font-bold">
            Freight Charges
          </span>

          {/* Top Right - Latest Charge */}
          <span className="text-[16px] font-bold text-purple-500">
            $ {parseFloat(deliveryChargesChange[0]?.freight_charge || 0).toFixed(2)}
          </span>
        </div>

        <div className="flex justify-between w-full mt-1">
          {/* Bottom Left - Date */}
          <span className="text-[16px] font-bold text-purple-500">
            {formatDate(deliveryChargesChange[0]?.added_on)}
          </span>

          {/* Bottom Right - Change Icon - Compare with old_freightcharge */}
          <div
            className={`flex items-center justify-center ${
              getPriceChange(deliveryChargesChange[0]?.old_freightcharge, deliveryChargesChange[0]?.freight_charge) >= 0
                ? "text-blue-500"
                : "text-red-500"
            }`}
          >
            {getPriceChange(deliveryChargesChange[0]?.old_freightcharge, deliveryChargesChange[0]?.freight_charge) >= 0 ? (
              <ArrowUpwardIcon className="text-xl font-bold" />
            ) : (
              <ArrowDownwardIcon className="text-xl font-bold" />
            )}
          </div>
        </div>
      </div>

      {/* Freight Surcharge Card - Comparing old_freightcharge with freight_surcharge */}
      <div
  className={`flex flex-col items-center justify-between w-64 bg-white rounded-lg px-4 py-2 relative hover:scale-105 hover:border-l-4 hover:border-green-500 transition-all duration-300 ${
    activeCard === 'freight_surcharge' ? 'border-l-4 border-green-500' : ''
  }`}
  style={{ boxShadow: "0 0 20px 0 rgba(0, 0, 0,.2)", height: "90px" }}
  onClick={() => setActiveCard('freight_surcharge')}
>
        <div className="flex justify-between w-full">
          {/* Top Left - Charge Type */}
          <span className="text-[16px] text-gray-500 font-bold">
            Freight Surcharge
          </span>

          {/* Top Right - Latest Charge */}
          <span className="text-[16px] font-bold text-green-500">
            $ {parseFloat(deliveryChargesChange[0]?.freight_surcharge || 0).toFixed(2)}
          </span>
        </div>

        <div className="flex justify-between w-full mt-1">
          {/* Bottom Left - Date */}
          <span className="text-[16px] font-bold text-green-500">
            {formatDate(deliveryChargesChange[0]?.added_on)}
          </span>

          {/* Bottom Right - Change Icon - Compare with old_freightcharge */}
          <div
            className={`flex items-center justify-center ${
              getPriceChange(deliveryChargesChange[0]?.old_freightcharge, deliveryChargesChange[0]?.freight_surcharge) >= 0
                ? "text-blue-500"
                : "text-red-500"
            }`}
          >
            {getPriceChange(deliveryChargesChange[0]?.old_freightcharge, deliveryChargesChange[0]?.freight_surcharge) >= 0 ? (
              <ArrowUpwardIcon className="text-xl font-bold" />
            ) : (
              <ArrowDownwardIcon className="text-xl font-bold" />
            )}
          </div>
        </div>
      </div>

    </div>

          {/* 
<div className="flex justify-end items-end mt-[60px]">
          <button
            className="flex items-end justify-end font-bold mr-3 w-55 border-transparent border-2 text-white bg-[#25caed] hover:scale-105 transition-transform duration-300 ease-in-out focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-base px-4 py-1.5 focus:outline-none"
            type="button"
            onClick={handleViewInvoice}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={3}
              stroke="currentColor"
              className="w-5 h-5 mr-3"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 6v12m6-6H6"
              />
            </svg>
            New Invoice
          </button>
          </div> */}
        </div>
      </div>

      {/* {isFilterVisible && ( */}
      <div className="relative flex justify-between gap-2 items-center w-full mt-[185px] py-[8px] px-[15px] rounded-t-lg" style={{height: '58px'}} >
        {/* Fuel Brand Dropdown */}
        <div className="flex-grow" style={{ width: "250px" }}>
          {isfilterOpen && (
            <select
              id="customerId"
              className="border border-gray-400 text-sm font-medium rounded-lg block w-full h-[35px] px-2"
              // onChange={(e) => {
              //   handleChangeFuelBrands(e);
              //   setSelectedText(e.target.value !== "");
              // }}
            >
              <option value="">--Select Fuel Brand--</option>
              {/* <option value="all">All Customer</option> */}
              {/* {sellerNames.map((seller) => (
              <option key={seller.id} value={seller.id}>
                {seller.seller_name}
              </option>
            ))} */}
            </select>
          )}
        </div>

        {/* Invoice Search */}
        <div className="flex-grow" style={{ width: "250px" }}>
          {isfilterOpen && (
            <select
              id="customerId"
              className="border border-gray-400  text-sm font-medium rounded-lg block w-full h-[35px] px-2"
              // onChange={(e) => {
              //   handleChangeFuelBrands(e);
              //   setSelectedText(e.target.value !== "");
              // }}
            >
              <option value="">--Select Fuel Grade--</option>
              <option value="all">Old Price</option>
              <option value="all">New Price</option>

              {/* {sellerNames.map((seller) => (
              <option key={seller.id} value={seller.id}>
                {seller.seller_name}
              </option>
            ))} */}
            </select>
          )}
        </div>
        {/* Date Picker */}
        {isfilterOpen && (
          <Space direction="vertical" 
          // style={{ height: "100%" }}
          >
            <DatePicker
              label="date"
              picker="month"
              format="MMMM-YYYY"
              views={["year", "month"]}
              // value={formattedDatefrom}
              // onChange={handleDateChangeFrom}
              placeholder="Date"
              renderInput={(params) => (
                <TextField
                  {...params}
                  // value={formattedDatefrom ? formattedDatefrom.format("MMMM-YYYY") : ""}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      // height: "32px",
                      display: "flex",
                      alignItems: "center",
                      fontSize: "1rem",
                      // backgroundColor: formattedDatefrom ? "#d7f3f8" : "transparent",
                      // border: `1px solid ${formattedDatefrom ? "#25caed" : "#ccc"}`,
                      borderRadius: "6px",
                      "&:hover": {
                        // borderColor: formattedDatefrom ? "#25caed" : "#aaa",
                      },
                      "&.Mui-focused": {
                        borderColor: "blue",
                        boxShadow: "0 0 8px rgba(0, 0, 255, 0.5)"
                      }
                    }
                  }}
                />
              )}
            />
          </Space>
        )}

        {/* Filters Button */}
        <div
          className="flex items-center justify-center bg-white p-2 h-[30px] rounded-lg shadow-lg cursor-pointer"
          // onClick={() => setIsfilterOpen(!isfilterOpen)}
          style={{ boxShadow: "0 2px 10px rgba(0, 0, 0, 0.3)" }}
        >
          <MdFilterList className="w-5 h-[16px]  mr-2" />
          {!isfilterOpen && <span className="text-sm">Filters</span>}
        </div>

        {/* Upload PO Button */}
        <div className="flex justify-end">
          <button
            className="text-white bg-[#25caed] flex items-center font-bold w-55 h-[35px] hover:scale-105 transition-transform duration-300 ease-in-out focus:ring-4 focus:ring-primary-300 rounded-lg text-base px-4"
            type="button"
            // onClick={() => navigate('/NewPrice')}
            onClick={() =>
              navigate("/deliveryPrice", { state: { businessDate: businessDate , businessname: businessname} })
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={3}
              stroke="currentColor"
              className="w-5 h-5 mr-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 6v12m6-6H6"
              />
            </svg>
            New Delivery Price
          </button>
        </div>
      </div>
      {/* )} */}

      <div
        className={`flex justify-end items-end w-full pl-9 pr-9 ml-6 ${
          isFilterVisible ? "mt-[0px]" : ""
        }`}
      >
        {/* <div >
            <Box sx={{ width: '100%' }}>
              <Tabs
                value={filteredStatus}
                // onChange={handleChange}
                indicatorColor="primary" // Default color for indicators
                sx={{
                  borderBottom: 3,
                  borderColor: 'divider', // Default border color
                  '& .MuiTabs-indicator': {
                    backgroundColor: indicatorColor(filteredStatus),
                  },
                }}
              >
                <Tab
                  value="draft"
                  onClick={()=> setFilteredStatus("draft")}
                  style={{
                    color: filteredStatus === 'draft' ? 'gray' : 'gray', // Gray color if filteredStatus is 'draft'
                    borderBottom: filteredStatus === 'draft' ? '1px solid gray' : 'none', // Gray border bottom if filteredStatus is 'draft'
                  }}
                  label={
                    <Badge
                      badgeContent={status.total_draftInvoices}
                      color=""
                      sx={{
                        '& .MuiBadge-badge': {
                          backgroundColor: filteredStatus === 'draft' ? 'gray' : 'gray', // Gray or green background based on filteredStatus
                          color: 'white',
                          top: '-3px',
                          right: '-10px',
                          fontSize: '0.75rem',
                          height: '18px',
                          minWidth: '18px',
                          padding: '0 6px',
                          borderRadius: '50%'
                        }
                      }}
                    >
                      Draft
                    </Badge>
                  }
                />
                <Tab
                  value="approved"
                  onClick={()=> setFilteredStatus("approved")}
                  sx={{ width: "122px" }}
                  style={{
                    color: filteredStatus === 'approved' ? 'blue' : 'gray', // Blue color if filteredStatus is 'approved'
                    borderBottom: filteredStatus === 'approved' ? '1px solid blue' : 'none', // Blue border bottom if filteredStatus is 'approved'
                  }}
                  label={
                    <Badge
                      badgeContent={status.total_approvedInvoices}
                      color="primary"
                      sx={{
                        '& .MuiBadge-badge': {
                          backgroundColor: filteredStatus === 'approved' ? 'blue' : 'gray',
                          color: 'white',
                          top: '-3px',
                          right: '-10px',
                          fontSize: '0.75rem',
                          height: '18px',
                          minWidth: '18px',
                          padding: '0 6px',
                          borderRadius: '50%'
                        }
                      }}
                    >
                      Approved
                    </Badge>
                  }
                />
                <Tab
                  value="sent"
                  onClick={()=> setFilteredStatus("sent")}
                  style={{
                    color: filteredStatus === 'sent' ? 'green' : 'gray', // Green color if filteredStatus is 'sent'
                    borderBottom: filteredStatus === 'sent' ? '1px solid green' : 'none', // Green border bottom if filteredStatus is 'sent'
                  }}
                  label={
                    <Badge
                      badgeContent={status.total_sentInvoices}
                      color="primary"
                      sx={{
                        '& .MuiBadge-badge': {
                          backgroundColor: filteredStatus === 'sent' ? 'green' : 'gray',
                          color: 'white',
                          top: '-3px',
                          right: '-10px',
                          fontSize: '0.75rem',
                          height: '18px',
                          minWidth: '18px',
                          padding: '0 6px',
                          borderRadius: '50%'
                        }
                      }}
                    >
                      Sent
                    </Badge>
                  }
                />
                   <Tab
                  value="overDue"
                  onClick={()=> setFilteredStatus("overDue")}
                  sx={{ width: "110px" }}
                  style={{
                    color: filteredStatus === 'overDue' ? 'red' : 'gray', // Red color if filteredStatus is 'overdue'
                    borderBottom: filteredStatus === 'overDue' ? '1px solid red' : 'none', // Red border bottom if filteredStatus is 'overdue'
                  }}
                  label={
                    <Badge
                      badgeContent={status.total_overdueinvoices} // Default to "0" if undefined
                      color="primary"
                      sx={{
                        '& .MuiBadge-badge': {
                          backgroundColor: filteredStatus === 'overDue' ? 'red' : 'gray',
                          color: 'white',
                          top: '-3px',
                          right: '-10px',
                          fontSize: '0.75rem',
                          height: '18px',
                          minWidth: '18px',
                          padding: '0 6px',
                          borderRadius: '50%'
                        }
                      }}
                    >
                      Past Due
                    </Badge>
                  }
                />
                <Tab
                  value="partialpaid"
                  onClick={()=> setFilteredStatus("partialpaid")}
                  sx={{ width: "126px" }}
                  style={{
                    color: filteredStatus === 'partialpaid' ? 'orange' : 'gray', // Orange color if filteredStatus is 'partialpaid'
                    borderBottom: filteredStatus === 'partialpaid' ? '1px solid orange' : 'none', // Orange border bottom if filteredStatus is 'partialpaid'
                  }}
                  label={
                    <Badge
                      badgeContent={status.total_partialpaidInvoices}
                      color="primary"
                      sx={{
                        '& .MuiBadge-badge': {
                          backgroundColor: filteredStatus === 'partialpaid' ? 'orange' : 'gray',
                          color: 'white',
                          top: '-3px',
                          right: '-10px',
                          fontSize: '0.75rem',
                          height: '18px',
                          minWidth: '18px',
                          padding: '0 6px',
                          borderRadius: '50%'
                        }
                      }}
                    >
                      Partial Pay
                    </Badge>
                  }
                />
                <Tab
                  value="fullpaid"
                  onClick={()=> setFilteredStatus("fullpaid")}
                  sx={{ width: "110px" }}
                  style={{
                    color: filteredStatus === 'fullpaid' ? 'green' : 'gray', // Green color if filteredStatus is 'fullpaid'
                    borderBottom: filteredStatus === 'fullpaid' ? '1px solid green' : 'none', // Green border bottom if filteredStatus is 'fullpaid'
                  }}
                  label={
                    <Badge
                      badgeContent={status.total_fullpaidInvoices}
                      color="primary"
                      sx={{
                        '& .MuiBadge-badge': {
                          backgroundColor: filteredStatus === 'fullpaid' ? 'green' : 'gray',
                          color: 'white',
                          top: '-3px',
                          right: '-10px',
                          fontSize: '0.75rem',
                          height: '18px',
                          minWidth: '18px',
                          padding: '0 6px',
                          borderRadius: '50%'
                        }
                      }}
                    >
                      Full Paid
                    </Badge>
                  }
                />
             
              </Tabs>
            </Box>
          </div> */}

        {/* <div className="flex items-center gap-3 mr-6 " style={{borderBottom: '3px solid #DCDCDC'}}>  */}

        <div
          className={`flex items-end gap-3 mr-6 transition-all ease-in-out `}
          style={
            {
              // borderBottom: '3px solid #DCDCDC',
            }
          }
        >
          {/* <Tooltip title="Filter" position="top" trigger="mouseenter">
                                          <button
                                              className="items-end mt-[-5px] text-sm font-medium text-center focus:outline-none text-gray-500 hover:text-blue-800 rounded-lg"
                                              type="button"
                                              onClick={handleFilterClick} 
                                          >
                                              <div className="rounded-full p-2">
                                                  <MdFilterList  size={24}     
 />
                                              </div>
                                          </button>
                                          
                              </Tooltip>                  */}
        </div>
      </div>

      {/* <hr className="border border-gray-300 w-[93%] mb-1 mt-1.5 mb-2"></hr> */}

      {/* <hr className=" border border-gray-300 w-[93%]"/> */}
      <div
        style={{
          position: isScrolled && selectedRows.length === 1 ? "fixed" : "fixed",
          left: isScrolled && selectedRows.length === 1 ? "8px" : "8px",
          bottom: "3%",
          width: "500px"
        }}
      >
        <ToastNotificationBox />
      </div>
      {/* <ToastNotificationBox/> */}

      <div className=" w-[100%] ">
        {" "}
        {/*Table starts from here */}
        <div className="w-full rounded-b-xl pt-3 px-3 bg-white pb-4 rounded-tl-[12px] rounded-tr-[12px] shadow-[4px_4px_8px_rgba(0,0,0,0.1),-4px_4px_8px_rgba(0,0,0,0.1),4px_-4px_8px_rgba(0,0,0,0.1),-4px_-4px_8px_rgba(0,0,0,0.1)]
">
          <table className="w-full table-auto bg-transparent rounded-b-xl mr-5 bg-white">
            <thead className="bg-blue-900 text-white">
              {/* <thead className="bg-green-100 text-green-600 overflow-hidden rounded-lg"> */}

              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id} className="text-center text-xs">
                  {headerGroup.headers.map((header, index) => {
                    const isFirstHeader = index === 0;
                    const isLastHeader =
                      index === headerGroup.headers.length - 1;
                    const headerClasses = [
                      "p-3 font-bold uppercase",
                      isFirstHeader ? "rounded-l-xl" : "",
                      isLastHeader ? "rounded-r-xl" : ""
                    ];

                    const isSorted = header.column.getIsSorted();
                    const showSortingIcons =
                      header.column.columnDef.enableSorting !== false;
                    const ArrowUpIcon = (
                      <FaArrowUpLong
                        className={`${
                          isSorted === "asc"  ? 'text-[#06b6d4]' : 'text-white'
                        }`}
                        size={12}
                      />
                    );
                    const ArrowDownIcon = (
                      <FaArrowDownLong
                        className={`${
                          isSorted === "desc"  ? 'text-[#06b6d4]' : 'text-white'
                        }`}
                        size={12}
                      />
                    );

                    return (
                      <th
                        key={header.id}
                        className={headerClasses.join(" ")}
                        colSpan={header.colSpan}
                        onClick={() =>
                          showSortingIcons &&
                          handleSortingChange(header.column.id)
                        }
                      >
                        <div className="flex items-center justify-center">
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                          {showSortingIcons && (
                            <div className="flex items-center ml-2">
                              {ArrowDownIcon}
                              {ArrowUpIcon}
                            </div>
                          )}
                        </div>
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody className="bg-white">
            {table.getRowModel().rows.length > 0 ? (

           table.getRowModel().rows.map((row) => (
                <React.Fragment key={row.id}>
                  {console.log(row, "row..........")}

                  {/* Main Row */}
                  <tr
                    className="cursor-pointer text-center  hover:shadow-[0_4px_8px_rgba(0,0,0,0.1)] transition-shadow "
                  style={{ borderBottom: "0.3px solid #e0e0e0" }}
                    // onClick={() => toggleRow(row.id)}
                    // onClick={()=>navigate('/pricingview', { state: { businessDate: businessDate } })}
                    // onClick={() =>
                    //   navigate("/NewPrice", { state: { businessDate: businessDate } })
                    // }
                    // onClick={() =>
                    //   navigate("/PricingTablePage", {
                    //     state: {
                    //       invoiceDate: row.original.added_on,
                    //       customer: row.original.customer,
                    //       businessDate: businessDate,
                    //       customer_id: row.original.customer_id
                    //     }
                    //   })
                    // }
                  >
                    {row.getVisibleCells().map((cell) => (
                      <td
                        key={cell.id}
                        className="p-3 font-semibold text-[#081159] text-[14px] text-center "
                        onClick={() =>
                          navigate("/deliveryPrice", { state: { businessDate: businessDate } })
                        }
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>

                  {/* Expanded Row */}
                  {expandedRow === row.id && (
                    <tr>
                      <td colSpan={row.getVisibleCells().length}>
                        <div
                          className="flex flex-col items-center gap-3 mb-0"
                          style={{
                            position: "fixed", // Changed from fixed to relative for better alignment within the table
                            right: "10px",
                            bottom: "8%"
                          }}
                        >
                          {row?.original && (
                            <>
                              {/* SMS Button */}
                              <Tooltip
                                title="Send SMS"
                                position="top"
                                trigger="mouseenter"
                              >
                                <button
                                  className="items-center text-sm font-medium text-center focus:outline-none border rounded-full"
                                  type="button"
                                  aria-label="Send SMS"
                                  style={{
                                    color: "#25caed", // Icon color
                                    borderColor: "#25caed", // Border color
                                    backgroundColor: "#d5f8fc" // Lighter background
                                  }}
                                >
                                  <div className="rounded-full p-3">
                                    <AiFillMessage size={20} />
                                  </div>
                                </button>
                              </Tooltip>

                              {/* Email Button */}
                              <Tooltip
                                title="Send Email"
                                position="top"
                                trigger="mouseenter"
                              >
                                <button
                                  className="items-center text-sm font-medium text-center focus:outline-none border rounded-full"
                                  type="button"
                                  aria-label="Send Email"
                                  style={{
                                    color: "#800080", // Icon color
                                    borderColor: "#800080", // Border color
                                    backgroundColor: "#f2e6f9" // Lighter background
                                  }}
                                >
                                  <div className="rounded-full p-3">
                                    <AiFillMail size={20} />
                                  </div>
                                </button>
                              </Tooltip>
                            </>
                          )}
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
             ))
            ) : (
              // Show empty state if no rows
              <tr>
                <td colSpan={columns.length} className="text-center py-12">
                  <div className="text-gray-500 text-lg">
                    No data available
                  </div>
                </td>
              </tr>
            )}
            </tbody>
          </table>

          <InvoiceViewModal
            isOpen={isModalOpen}
            closeModal={closeModal}
            invoiceNumber={
              table.getSelectedRowModel().flatRows[0]?.original.invoice_number
            }
          />

          {!invoiceData ||
            (!invoiceData.length === 0 && (
              <div className="w-full h-[200px] flex items-center justify-center ">
                There is not to show here
              </div>
            ))}
        </div>
      </div>

      {openDeleteModalId && (
        <PopUpModal
          onClose={() => setOpenDeleteModalId(null)}
          invoiceNumber={openDeleteModalId}
          invoiceDelete={handleDeleteInvoices}
          invoiceName={
            table.getSelectedRowModel().flatRows[0]?.original.business_name
          }
          resetRowSelection={table.resetRowSelection}
          setTriggerFetch={setTriggerFetch}
          triggerFetch={triggerFetch}
        />
      )}

      {openSendInvoiceModalId && (
        <PopUpModalForSendInvoice
          onClose={() => setOpenSendInvoiceModalId(null)}
          invoiceNumber={openSendInvoiceModalId}
          invoiceName={
            table.getSelectedRowModel().flatRows[0]?.original.business_name
          }
          resetRowSelection={resetRowSelection}
          customerEmail={customerEmail}
          finalInvoiceAmount={
            table.getSelectedRowModel().flatRows[0]?.original
              .final_invoice_amount
          }
          dueDate={table.getSelectedRowModel().flatRows[0]?.original.due_date}
          setTriggerFetch={setTriggerFetch}
          triggerFetch={triggerFetch}
        />
      )}

      {payModalInvoice && (
        <InvoicePayModal
          open={payModalInvoice}
          handleClose={() => setpayModalInvoice(false)}
          customerName={
            table.getSelectedRowModel().flatRows[0]?.original.business_name
          }
          invoiceNumber={
            table.getSelectedRowModel().flatRows[0]?.original.invoice_number
          }
          finalInvoiceAmount={
            table.getSelectedRowModel().flatRows[0]?.original
              .final_invoice_amount
          }
          setTriggerFetch={setTriggerFetch}
          triggerFetch={triggerFetch}
          resetRowSelection={table.resetRowSelection}
        />
      )}

      <ScrollToTop />
    </div>
  );
}

export default DeliveryPriceDashBoard;

// import { useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';

// const InactivityHandler = () => {
//   const navigate = useNavigate();
//   let inactivityTimeout;

 
// const user_role = localStorage.getItem("user_role");

//   const handleSignOut = ()=>{ 
//     if (user_role === "1") {
//       navigate("/adminlogin");
//     } else if (user_role === '5'){
//       navigate('/customerportal')
//     } else {
//       navigate("/");
//     }
//     localStorage.clear();
//     localStorage.setItem('signOutToken', Date.now().toString());

//   }
//   const resetTimer = () => {
//     clearTimeout(inactivityTimeout);
//     inactivityTimeout = setTimeout(() => {
//         handleSignOut();
//     }, 60 * 60 * 1000); // 60 minutes
//   };

//   useEffect(() => {
//     window.addEventListener('mousemove', resetTimer);
//     window.addEventListener('keydown', resetTimer);
//     window.addEventListener('click', resetTimer);
    
//     resetTimer();

//     return () => {
//       window.removeEventListener('mousemove', resetTimer);
//       window.removeEventListener('keydown', resetTimer);
//       window.removeEventListener('click', resetTimer);
//       clearTimeout(inactivityTimeout);
//     };
//   }, []);

//   return null;
// };

// export default InactivityHandler;
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const InactivityHandler = () => {
  const navigate = useNavigate();
  const user_role = localStorage.getItem("user_role");
  const INACTIVITY_TIMEOUT = 60 * 60 * 1000; // 60 minutes in milliseconds
  const STORAGE_KEY = 'lastActivityTimestamp';

  const handleSignOut = () => { 
    if (user_role === "1") {
      navigate("/adminlogin");
    } else if (user_role === '5'){
      navigate('/customerportal')
    } else {
      navigate("/");
    }
    localStorage.clear();
    localStorage.setItem('signOutToken', Date.now().toString());
  };

  const checkInactivity = () => {
    const lastActivity = parseInt(localStorage.getItem(STORAGE_KEY) || '0');
    const currentTime = Date.now();
    
    if (currentTime - lastActivity > INACTIVITY_TIMEOUT) {
      handleSignOut();
    }
  };

  const updateActivity = () => {
    localStorage.setItem(STORAGE_KEY, Date.now().toString());
  };

  useEffect(() => {
    // Set initial timestamp when component mounts
    updateActivity();
    
    // Set up event listeners
    window.addEventListener('mousemove', updateActivity);
    window.addEventListener('keydown', updateActivity);
    window.addEventListener('click', updateActivity);
    
    // Check for inactivity periodically (every minute)
    const intervalId = setInterval(checkInactivity, 60 * 1000);
    
    return () => {
      window.removeEventListener('mousemove', updateActivity);
      window.removeEventListener('keydown', updateActivity);
      window.removeEventListener('click', updateActivity);
      clearInterval(intervalId);
    };
  }, []);

  return null;
};

export default InactivityHandler;
import React from 'react';
import CustomCommonTab from '../reusable/CustomCommonTab';
import CustomNavigation from '../reusable/CustomNavigationIcon';
import { useNavigate } from 'react-router-dom';

const NewDocument = () => {
const navigate = useNavigate();
  const menuItems = [{ name: "New Documents" }];

  return (
    <>
     <CustomCommonTab items={menuItems} />
    <div
      style={{ 
        paddingTop: '36px',
        position: 'relative',
        width: '880px',
        margin: '40px auto',
        textAlign: 'center',
        padding: '23px',
        border: '1px solid #ddd',
        borderRadius: '8px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        backgroundColor:'white'
      }}
    >    <CustomNavigation onCloseClick={() =>  navigate("/MyDocs")}  />
      {/* Dropdowns */}
      <div style={{ marginBottom: '20px' }} className='w-50'>
        {/* <label htmlFor="customer" style={{ display: 'block', marginBottom: '8px' }}>
          Select Customer
        </label> */}
        <select
          id="customer"
          style={{
            width: '100%',
            padding: '10px',
            border: '1px solid #ccc',
            borderRadius: '4px',
          }}
        >
          <option value="" disabled selected>
            -- Select Customer --
          </option>
          <option value="customer1">Customer 1</option>
          <option value="customer2">Customer 2</option>
          <option value="customer3">Customer 3</option>
        </select>
      </div>

      <div style={{ marginBottom: '20px' }} className='w-50'>
        {/* <label htmlFor="document" style={{ display: 'block', marginBottom: '8px' }}>
          Select Document
        </label> */}
        <select
          id="document"
          style={{
            width: '100%',
            padding: '10px',
            border: '1px solid #ccc',
            borderRadius: '4px',
          }}
        >
          <option value="" disabled selected>
            -- Select Document --
          </option>
          <option value="contracts">Customer Contracts / Agreements</option>
          <option value="licenses">Licenses</option>
          <option value="miscellaneous">Miscellaneous</option>
        </select>
      </div>

      <div style={{ marginBottom: '20px' }} className='w-50'>
        {/* <label htmlFor="documentType" style={{ display: 'block', marginBottom: '8px' }}>
          Select Document Type
        </label> */}
        <select
          id="documentType"
          style={{
            width: '100%',
            padding: '10px',
            border: '1px solid #ccc',
            borderRadius: '4px',
          }}
        >
          <option value="" disabled selected>
            -- Select Document Type --
          </option>
          <option value="pdf">PDF</option>
          <option value="word">Word</option>
          <option value="images">PNG, JPG</option>
        </select>
      </div>

      {/* Submit Button */}
      <button
        className="w-40 px-2 py-1 border-2 border-blue-600
        hover:font-bold rounded-md hover:bg-blue-600 hover:shadow-blue-600/50
        uppercase duration-200 hover:text-white hover:shadow-lg hover:border-transparent active:scale-90"
      >
        Submit
      </button>
    </div>
    </>
   
  );
};

export default NewDocument;

import axios from "axios";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import config from "../../config/config";
import FaxIcon from "../../assets/images/fax image.jpg";
import ContactPhoneOutlinedIcon from "@mui/icons-material/ContactPhoneOutlined";
import FullScreenLoader from "../pages/FullScreenLoader";
import { useNavigate } from "react-router-dom";
import { FaHome, FaEye, FaPencilAlt, FaTrash, FaTimes } from "react-icons/fa";
import PopUpModal from "../pages/InvoiceDeleteModal";
import { Tooltip, DatePicker, Select } from "antd";
import { useReactTable } from "@tanstack/react-table";
import { Button, Modal } from "flowbite-react";
import { useSearchParams, useParams } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { addToast } from '../pages/ToastBox/ToastBoxMessageSlice';
import { use } from "react";
import CustomNavigationButtons from "../reusable/CustomNavigationWithEditDelete";
import CustomCommonTab from "../reusable/CustomCommonTab";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import { useRef } from "react";
import PopUpModalForSendStatement from "../../components/pages/StatementSendModel";

import NotesIcon from "@mui/icons-material/Notes";
const StatementViewFile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { Option } = Select;
    const [isOpen, setIsOpen] = useState(false);
    const textareaRef = useRef(null);
    const [triggerFetch, setTriggerFetch] = useState(false);

    const [termsAndConditions, setTermsAndConditions] = useState("");
  const [openSendInvoiceModalId, setOpenSendInvoiceModalId] = useState(null);
  console.log(openSendInvoiceModalId, 'openSendInvoiceModalId')

    useEffect(() => {
      if (isOpen && textareaRef.current) {
        const length = textareaRef.current.value.length;
        textareaRef.current.focus();
        textareaRef.current.setSelectionRange(length, length);
      }
    }, [isOpen, termsAndConditions]); // Add termsAndConditions to dependencies
    
    const toggleInput = useCallback(() => {
      setIsOpen((prev) => !prev);
    }, []);
    function getMonthlyStatementTitle(dateStr) {
      
      const date = new Date(dateStr);
      const monthName = date.toLocaleString('en-US', { month: 'long' });
      const year = date.getFullYear();
      return `${monthName.slice(0,3).toUpperCase()} ${year}`;
    }
    function getMonthNameFromDate(dateStr) {
      if (!dateStr) return '';
      const date = new Date(dateStr);
      const monthName = date.toLocaleString('en-US', { month: 'long' });
      return monthName.slice(0, 3).toUpperCase();
    }
    
    function getYearFromDate(dateStr) {
      if (!dateStr) return '';
      const date = new Date(dateStr);
      return date.getFullYear();
    }
  const formatExactNumber = (value) => {
    if (!value) return '';
    
    // Split number into integer and decimal parts
    const [integerPart, decimalPart] = value.toString().split('.');
    
    const formattedInteger = parseInt(integerPart || '0').toLocaleString();
    
    // Return with decimal part if it exists
    if (decimalPart !== undefined) {
      return `${formattedInteger}.${decimalPart}`;
    }
    
    return formattedInteger;
  };
  const getAmountColor = (type, amount) => {
    const lowerType = type.toLowerCase();
    
    // Types that should show positive amounts in green
    if (['invoice', 'water bill','rent','pos fee',"Miscellaneous"].includes(lowerType)) {
      return '';
    }
    
    // Types that should show negative amounts in red
    if ([ 'credit card','loyalty gallons', 'loyalty discount', 'loyalty credits'].includes(lowerType)) {
      return 'text-red-600';
    }
    
    // Default color if type doesn't match any condition
    return '';
  };
  
  const {statementNumber : paramStatementNumber} = useParams();
  const statementNumber = paramStatementNumber;
  const { state } = location;
  // const customer_id = state?.customer_id ? state?.customer_id : null
  // const status = state?.status ? state?.status : null
  const customer_id = localStorage.getItem('stmt_customer_id') ? localStorage.getItem('stmt_customer_id') : null
  const status = localStorage.getItem('stmt_status') ? localStorage.getItem('stmt_status') : null

  const baseURL =
    process.env.NODE_ENV === "production"
      ? config.production.baseURL
      : config.development.baseURL;

  const [businessData, setBusinessData] = useState([]);
  const business_id = localStorage.getItem("business_id");
  const user_id = localStorage.getItem("user_id");
  const user_role = localStorage.getItem("user_role");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeItem, setActiveItem] = useState("View");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteRowConfirmModal, setDeleteRowConfirmModal] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [editableTransactions, setEditableTransactions] = useState([]);
  const [rowToDelete, setRowToDelete] = useState(null);
  const [datePickerOpen, setDatePickerOpen] = useState(null);
  const [businessName, setBusinessName] = useState("");



  const dispatch = useDispatch();
  
  // Available product types that can be added
  const allProductTypes = [
    "Water Bill",
    "Rent",
    "POS Fee",
    "Loyalty Discount",
    "Loyalty Credits",
    "Loyalty Gallons"
  ];
  
  // State for available products dropdown
  const [availableProducts, setAvailableProducts] = useState([]);
  
  // State for dropped items (deleted from table but can be added back)
  const [droppedItems, setDroppedItems] = useState([]);

  // Add balance calculation function
  const calculateBalanceAmount = (transactions) => {
    let balance = 0;
    
    transactions.forEach((item) => {
      // Parse the amount - handle empty strings or undefined
      let amount = parseFloat(item.statement_amt || 0);
      if (isNaN(amount)) amount = 0;
      
      const type = item.statement_type.toLowerCase();
      
      // Types that should be positive (add to balance)
      if (['invoice', 'water bill', 'rent', 'pos fee', "miscellaneous"].includes(type)) {
        balance += amount;
      }
      // Types that should be negative (subtract from balance)
      else if (['credit card', 'loyalty gallons', 'loyalty discount', 'loyalty credits'].includes(type)) {
        balance -= amount;
      }
      // Default to adding the amount
      else {
        balance += amount;
      }
    });
    
    return balance;
  };
  
  useEffect(() => {
    axios
      .get(
        `${baseURL}${btoa("business/profile")}/${btoa(business_id)}/${btoa(
          user_id
        )}/${btoa(user_role)}`
      )
      .then((response) => {
        console.log("API Response:", response);
        const data = response.data.business_data[0];
        setBusinessData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching business data:", error);
        setError("Failed to load business data");
        setLoading(false);
      });
  }, [baseURL, business_id, user_id, user_role]);

  const handledeleteStatement = async (statementNumber, customerId, businessId, loggedInUserId, userRole) => {
    try {
      const url = ` ${baseURL}/${('statement/delete')}/${(statementNumber)}/${(customerId)}/${(businessId)}/${(loggedInUserId)}/${(userRole)}`;
        
      const response = await axios.delete(url);
        
      console.log('Delete successful:', response.data);
      dispatch(addToast({ type: 'danger', message: `Statement deleted successfully!` }));
      navigate('/statements'); // Redirect to statements list
        
    } catch (error) {
      console.error('Error deleting statement:', error.response ? error.response.data : error.message);
    }
  };
  
  function formatWebDate(isoString) {
    if (!isoString) return null; // Return null if the date is not set
    return dayjs(isoString).format("MM-DD-YYYY"); // Use dayjs for formatting
  } 
  const [CustomerAddress, setCustomerAddress] = useState([])
  console.log(CustomerAddress, 'CustomerAddress')
  useEffect(() => {
    const fetchCustomerStatement = async () => {
      try {
        const payload = {
          businessId: localStorage.getItem("business_id"),
          customerId: customer_id,
          statementNumber: statementNumber,
        };

        const response = await axios.post(`${baseURL}/${("statement/customerview")}`,
          payload
        );

        if (response.data) {
          console.log("Customer statement data:", response.data);
          setTransactions(response.data.customer_statement);
          setCustomerAddress(response.data.customers_data[0])
          setTermsAndConditions(response.data.customer_statement[0].terms_condition);
          setBusinessName(response.data.customer_statement[0].business_name);
          // Create a deep copy for editing
          setEditableTransactions(JSON.parse(JSON.stringify(response.data.customer_statement)));
          
          // Update available products based on what's already in the statement
          updateAvailableProducts(response.data.customer_statement);
        }
        console.log('statement business name', businessName);
      } catch (error) {
        console.error("Error fetching customer statement:", error);
      }
    };

    fetchCustomerStatement();
  }, [customer_id, statementNumber]);
  
  // Update available products based on what's already in the statement
  const updateAvailableProducts = (transactions) => {
    // Get types that are already in the statement
    const existingTypes = transactions.map(item => item.statement_type);
    
    // Get unique types (in case there are duplicates)
    const uniqueExistingTypes = [...new Set(existingTypes)];
    
    console.log("Existing product types:", uniqueExistingTypes);
    
    // Filter out the types that are already in use
    const available = allProductTypes.filter(type => !uniqueExistingTypes.includes(type));
    
    console.log("Available product types:", available);
    
    // Set available products for the dropdown
    setAvailableProducts([...available, ...droppedItems]);
  };

  // Handle deleting a row
  const handleDeleteRow = (index) => {
    setRowToDelete(index);
    setDeleteRowConfirmModal(true);
  };
  
  // Confirm delete row
  const confirmDeleteRow = () => {
    if (rowToDelete !== null) {
      const deletedItem = editableTransactions[rowToDelete];
      
      // Only add to available products if it's one of our editable types
      if (isItemTypeEditable(deletedItem.statement_type)) {
        // Add the removed item type to available products
        setDroppedItems([...droppedItems, deletedItem.statement_type]);
        setAvailableProducts([...availableProducts, deletedItem.statement_type]);
      }
      
      // Remove the item from editable transactions
      const newTransactions = [...editableTransactions];
      newTransactions.splice(rowToDelete, 1);
      setEditableTransactions(newTransactions);
    }
    
    // Close the modal
    setDeleteRowConfirmModal(false);
    setRowToDelete(null);
  };
  
  // Add a new product row
  const handleAddProduct = (selectedProduct) => {
    if (selectedProduct) {
      // Create a new empty row for the selected product
      const newRow = {
        statement_type: selectedProduct,
        invoice_num: "",
        statement_date: dayjs().format('YYYY-MM-DD'), // Set to today's date as default
        product_name: "",
        product_qty: "",
        statement_amt: "0.00", // Initialize as string to ensure it works with input fields
        // Copy other necessary fields from existing records
        statement_month: editableTransactions[0]?.statement_month,
        balance_amt: editableTransactions[0]?.balance_amt,
        // Add any other required fields
        business_id: business_id,
        customer_id: customer_id,
        statement_number: statementNumber,
        id: `new-${Date.now()}` // Add a temporary ID
      };
      
      // Find the correct position to insert the new row
      // Determine the order of product types
      const productOrder = [
        "Invoice", 
        "Credit Card", 
        "Water Bill", 
        "Rent", 
        "POS Fee", 
        "Loyalty Discount", 
        "Loyalty Credits", 
        "Loyalty Gallons",
        "Miscellaneous"
      ];
      
      // Find the position to insert the new row based on product order
      let insertIndex = editableTransactions.length; // Default to end
      const selectedProductIndex = productOrder.indexOf(selectedProduct);
      
      // Find the first item in the table that should come after our new item
      for (let i = 0; i < editableTransactions.length; i++) {
        const currentTypeIndex = productOrder.indexOf(editableTransactions[i].statement_type);
        if (currentTypeIndex > selectedProductIndex) {
          insertIndex = i;
          break;
        }
      }
      
      // Insert the new row at the correct position
      const updatedTransactions = [...editableTransactions];
      updatedTransactions.splice(insertIndex, 0, newRow);
      setEditableTransactions(updatedTransactions);
      
      // Remove the product from available products
      setAvailableProducts(availableProducts.filter(p => p !== selectedProduct));
      setDroppedItems(droppedItems.filter(p => p !== selectedProduct));
    }
  };

  // Handle updating a field in the editable transaction
  const handleFieldChange = (index, field, value) => {
    const updatedTransactions = [...editableTransactions];
    updatedTransactions[index][field] = value;
    setEditableTransactions(updatedTransactions);
  };

  const handleDateChange = (index, date) => {
    if (date) {
      // Valid date selected
      const formattedDate = date.format('YYYY-MM-DD');
      handleFieldChange(index, 'statement_date', formattedDate);
    } else {
      // Handle case where user clears the date
      // Set to empty string to trigger validation
      handleFieldChange(index, 'statement_date', '');
      
      // Show immediate feedback
      dispatch(addToast({ 
        type: 'warning', 
        message: `Date is required for ${editableTransactions[index].statement_type}` 
      }));
    }
  };

  // Handle amount change
  const handleAmountChange = (index, e) => {
    let value = e.target.value;
    // Allow empty field for user input
    if (value === '') {
      handleFieldChange(index, 'statement_amt', '0');
      return;
    }
    
    // Remove non-numeric characters except decimal point
    value = value.replace(/[^\d.]/g, '');
    
    // Ensure only one decimal point
    const parts = value.split('.');
    if (parts.length > 2) {
      value = parts[0] + '.' + parts.slice(1).join('');
    }
    
    // Limit to 2 decimal places
    if (parts.length > 1 && parts[1].length > 2) {
      value = parts[0] + '.' + parts[1].substring(0, 2);
    }
    
    handleFieldChange(index, 'statement_amt', value);
  };
  
  const debugStateInfo = () => {
    console.log({
      activeItem,
      statementNumber,
      customer_id,
      business_id,
      transactions: transactions.length,
      editableTransactions: editableTransactions.length,
      availableProducts,
      droppedItems
    });
  };
  
  const saveAllEdits = async () => {
    try {
      // Basic payload with what the API needs
      const payload = {
        statementNumber: statementNumber,
        waterbill_date: null,
        waterbill_amt: null,
        rent_date: null,
        rent_amt: null,
        posfee_date: null,
        posfee_amt: null,
        loyaltydis_date: null,
        loyaltydis_amt: null,
        loyaltycredit_date: null,
        loyaltycredit_amt: null,
        loyaltygallons_date: null,
        loyaltygallons_amt: null
      };
      
      // Process items in the most direct way possible
      for (const item of editableTransactions) {
        if (item.statement_type === 'Water Bill') {
          payload.waterbill_date = item.statement_date;
          payload.waterbill_amt = parseFloat(item.statement_amt);
        } 
        else if (item.statement_type === 'Rent') {
          payload.rent_date = item.statement_date;
          payload.rent_amt = parseFloat(item.statement_amt);
        }
        else if (item.statement_type === 'POS Fee') {
          payload.posfee_date = item.statement_date;
          payload.posfee_amt = parseFloat(item.statement_amt);
        }
        else if (item.statement_type === 'Loyalty Discount') {
          payload.loyaltydis_date = item.statement_date;
          payload.loyaltydis_amt = parseFloat(item.statement_amt);
        }
        else if (item.statement_type === 'Loyalty Credits') {
          payload.loyaltycredit_date = item.statement_date;
          payload.loyaltycredit_amt = parseFloat(item.statement_amt);
        }
        else if (item.statement_type === 'Loyalty Gallons') {
          payload.loyaltygallons_date = item.statement_date;
          payload.loyaltygallons_amt = parseFloat(item.statement_amt);
        }
      }
      
      // Make simple direct API call
      const response = await axios.post(`${baseURL}/${("statement/update")}`, payload);
      
      if (response.data) {
        // Just update transactions and show success
        setTransactions([...editableTransactions]);
        dispatch(addToast({ type: 'success', message: 'Statement updated successfully!' }));
        navigate('/statements');
      } else {
        dispatch(addToast({ type: 'danger', message: 'Update failed' }));
      }
    } catch (error) {
      console.error("Error:", error);
      dispatch(addToast({ type: 'danger', message: 'Update failed' }));
    }
  };
  
  // const cancelAllEdits = () => {
  //   setEditableTransactions(JSON.parse(JSON.stringify(transactions)));
  //   setDroppedItems([]);
  //   updateAvailableProducts(transactions);
  // };

  const cancelAllEdits = () => {
    setEditableTransactions(JSON.parse(JSON.stringify(transactions)));
    setDroppedItems([]);
    updateAvailableProducts(transactions);
    setActiveItem("View"); // Add this line to return to View mode
  };

  
  const isItemTypeEditable = (type) => {
    return ['Water Bill', 'Rent', 'POS Fee', 'Loyalty Discount', 'Loyalty Credits', 'Loyalty Gallons'].includes(type);
  };
  const handleApproveStatement = async (e) => {
    e.preventDefault();
  
    try {

      const response = await axios.post(`${baseURL}${("statement/approved")}`,
      {
        statementNumber: statementNumber,
        businessId:localStorage.getItem("business_id"),
        userId:localStorage.getItem("user_id"),
        userRole :localStorage.getItem("user_role")
      }
    );
      if (response.data)
        dispatch(
          addToast({
            type: "success",
            message: `${statementNumber} Approved successfully`,
          })
        );

      navigate("/statements");
    } catch (error) {
      console.log("error details ", error);
    }
  };
  const handleSentStatement = async (e) => {
    e.preventDefault();
  
    try {

      const response = await axios.post(`${baseURL}${("statement/sent")}`,
      {
        statementNumber: statementNumber,
        businessId:localStorage.getItem("business_id"),
        userId:localStorage.getItem("user_id"),
        userRole :localStorage.getItem("user_role"),
        customerId:customer_id
      }
    );
      if (response.data)
        dispatch(
          addToast({
            type: "success",
            message: `${statementNumber} Sent successfully`,
          })
        );

      navigate("/statements");
    } catch (error) {
      console.log("error details ", error);
    }
  };
  const TermsAndConditionsEditor = React.memo(({ termsAndConditions, setTermsAndConditions, isOpen, toggleInput }) => {
    const textareaRef = useRef(null);
  
    useEffect(() => {
      if (isOpen && textareaRef.current) {
        const length = textareaRef.current.value.length;
        textareaRef.current.focus();
        textareaRef.current.setSelectionRange(length, length);
      }
    }, [isOpen]);

   
    return (
      <div className="pt-4">
       <TermsAndConditionsEditor 
  termsAndConditions={termsAndConditions}
  setTermsAndConditions={setTermsAndConditions}
  isOpen={isOpen}
  toggleInput={toggleInput}
/>
      </div>
    );
  });

  const [isMemoOpen, setIsMemoOpen] = useState(false);
  const [currentMemo, setCurrentMemo] = useState("");
  const [memos, setMemos] = useState([]);
  const memoTextareaRef = useRef(null);
  const toggleMemoInput = useCallback(() => {
    setIsMemoOpen((prev) => !prev);
  }, []);
  useEffect(() => {
    if (isMemoOpen && memoTextareaRef.current) {
      memoTextareaRef.current.focus();
      memoTextareaRef.current.setSelectionRange(
        memoTextareaRef.current.value.length, 
        memoTextareaRef.current.value.length
      );
    }
  }, [isMemoOpen]);
  const formatMemoDate = (dateString) => {
    return dayjs(dateString).format("MM/DD/YYYY hh:mm A");
  };
    
  // Loads dummy memo data when the component mounts
  useEffect(() => {
    // This would be replaced with an API call in production
    setMemos([
      {
        id: 1,
        text: "Customer requested extension on payment due to cash flow issues. Extended due date by 14 days.",
        created_by: "John Smith",
        created_on: "2025-03-20T10:30:00",
        edited_on: null
      },
      {
        id: 2,
        text: "Customer made partial payment of $1,250.00. Balance remaining: $3,750.00",
        created_by: "Jane Doe",
        created_on: "2025-03-22T14:15:00",
        edited_on: null
      },
      {
        id: 3,
        text: "Discussed remaining balance with customer. They will pay the remaining amount by the end of next week.",
        created_by: "John Smith",
        created_on: "2025-03-24T09:45:00",
        edited_on: "2025-03-24T11:20:00"
      }
    ]);
  }, []);
  const handleSaveMemo = () => {
    if (currentMemo.trim() === "") return;
  
    // Create a new memo object
    const newMemo = {
      id: memos.length + 1, // In real app, this would be generated by the server
      text: currentMemo,
      created_by: localStorage.getItem("user_id") || "Current User",
      created_on: new Date().toISOString(),
      edited_on: null
    };
  
    // Add the new memo to the beginning of the array (latest first)
    setMemos([newMemo, ...memos]);
    setCurrentMemo(""); // Clear the input after saving
  
    // In a real application, you would save to the server here
    /* API call would go here */
  };
// let status = "1"
  const ViewComponent = () => (
    <>
 <div className="flex flex-col w-full ">
  {/* Top section with sender and recipient information */}
  <div className="flex justify-between items-center w-full pb-3 ">
    {/* Sender section (left) */}
    <div className="flex items-start gap-4">
      <img
        src={`${baseURL}/uploads/img/business/${businessData.image_file}`}
        alt="Business Logo"
        className="w-16 h-16 object-contain"
      />
      <div className="text-sm pt-1">
        <p className="font-bold text-gray-800 mb-1">From</p>
        <p >{businessData.business_name}</p>
        <p>{businessData.business_address}</p>
        <p>
          {businessData.business_city}, {businessData.business_state} {businessData.business_zip_code}
        </p>
        {businessData.contact_no && businessData.contact_no !== "null" && (
          <p className="mt-1">Ph: {businessData.contact_no}</p>
        )}
      </div>
    </div>

    {/* Statement title (center) with stylized underline */}
    <div className="text-left -mt-2 relative">
      {/* <h4 className="text-2xl font-bold uppercase tracking-wider text-gray-800">
        STATEMENT
      </h4>
      <div className="relative">
        <p className="text-lg font-medium mt-1 mb-3 pb-1">
          {getMonthlyStatementTitle(transactions[0]?.statement_month)}
        </p>
      </div> */}
            <p className="font-bold text-gray-800 mb-1">To</p>
      <p>{CustomerAddress.business_name}</p>
      <p>{CustomerAddress.business_address}</p>
      <p>
        {CustomerAddress.business_city}, {CustomerAddress.business_state} {CustomerAddress.business_zip_code}
      </p>
    </div>

    {/* Recipient section (right) */}
    <div className="text-sm text-right pt-1 ">
      <p className=" mb-2 flex justify-between gap-6"><span>Statement #: </span><span>{statementNumber}</span></p>
      <p className="mb-2 flex justify-between gap-6"><span>Statement Month: </span>
       <span>{getMonthNameFromDate(transactions[0]?.statement_month)}</span></p>
      <p className=" mb-2  flex justify-between gap-6"><span>Statement Year:</span>
       <span>{getYearFromDate(transactions[0]?.statement_month)}</span></p>
      <p className=" mb-2  flex justify-between gap-6"><span>Statement Date:</span>
       <span>{formatWebDate(transactions[0]?.added_on)}</span></p>

    </div>
  </div>
  
  {/* <div className="relative w-full h-3 mb-4">
    <div className="absolute top-0 left-0 right-0 h-px bg-gray-200"></div>
  </div> */}
</div>

   
      <div className="absolute top-[300px] left-1/2 -translate-x-1/2 -translate-y-1/2 z-0">
          <p className="text-red-400 opacity-20 font-bold text-[5rem] rotate-[-45deg] select-none">
            {(status === "1" ) && "DRAFT"}
            {(status === "2" ) && "APPROVED"}
            {(status === "3" ) && "SENT TO CUSTOMER"}
            {/* {(status  === "7" || statuspay === "7") && "FULLPAID"} */}
            {(status === "8") && "PAST DUE"}
            {(status === "4") && "PARTIALPAY"}
          </p>
        </div>
      <table className="w-full border-collapse border border-gray-300">
        <thead>
        {/* <tr>   */}
                  {/* <th
                    colSpan="100%"
                    className="border-b-2 border-black bg-white h-0 p-0 m-0"
                  ></th> */}
                {/* </tr> */}
          <tr className="bg-gray-200 text-left">
            <th className="border text-left p-2 pl-[20px] w-[135px]">Type</th>
            <th className="border p-2 w-[142px]">Invoice #</th>
            <th className="border p-2 w-[175px]">Date</th>
            <th className="border text-center p-2 w-[145px]">Product Name</th>
            <th className="border p-2 text-center w-[115px]">Quantity</th>
            <th className="border p-2 text-right">Amount ($)</th>
          </tr>
          {/* <tr> */}
                  {/* <th
                    colSpan="100%"
                    className="border-t-2 border-black bg-white h-0 p-0 m-0"
                  ></th> */}
                {/* </tr> */}
        </thead>
        <tbody style={{ fontSize: "16px", fontFamily: "Sans-Serif"}}>
          {transactions.map((item, index) => (
            <tr
             key={item.id ||`${item.statement_date}- ${item.statement_type}-${index}`} 
             className="border">
              <td className="border text-left p-2 pl-[20px] w-[135px]" style={{whiteSpace:'nowrap'}}>{item.statement_type}</td>
              <td className="border p-2">{item.invoice_num || "-"}</td>
              <td className="border p-2">{formatWebDate(item.statement_date)}</td>
              <td className="border text-center p-2">{item.product_name || "-"}</td>
              <td className={`border p-2 ${item.product_qty ? 'text-center' : 'text-center'}`}>{item.product_qty || "-"}</td>
              <td className={`border p-2 text-right ${getAmountColor(item.statement_type, item.statement_amt)}`}>
                {/* {getAmountColor(item.statement_type).includes('red') ? '-' : ''} */}
                {parseFloat(item.statement_type === "credit card" ? -(item.statement_amt): (item.statement_amt)).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="mt-4 text-right font-bold text-lg pr-1 pt-[20px]">
        Balance Amount: $ {parseFloat(transactions[0]?.balance_amt || 0).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}
      </div>
      <div className="pt-4">
                {!isOpen ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      cursor: "pointer",
                      padding: "10px",
                      
                      marginBottom: "30px"
                    }}
                    className='bg-gray-100'
                    onClick={toggleInput}
                  >
                    <span style={{ fontWeight: "bold" }}>Terms & Conditions</span>

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      style={{
                        transform: isOpen ? "rotate(90deg)" : "rotate(0deg)",
                        transition: "transform 0.2s ease",
                      }}
                    >
                      <polyline points="9 18 15 12 9 6"></polyline>
                    </svg>
                  </div>
                ) : (
                  <div className="relative bg-gray-100 px-2 rounded-md mb-2" style={{ marginBottom: "30px" }}>
                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        <LightbulbOutlinedIcon
                          className="text-black bg-white absolute left-2 top-1/2 transform -translate-y-1/2"
                          style={{ borderRadius: "50%", fontSize: "28px" }}
                        />
                        <div className="pl-8 pt-1 text-md font-semibold">
                          Terms & Conditions
                        </div>
                      </div>

                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        onClick={toggleInput}
                        style={{
                          transform: isOpen ? "rotate(90deg)" : "rotate(0deg)",
                          transition: "transform 0.2s ease",
                          cursor: "pointer"
                        }}
                      >
                        <polyline points="9 18 15 12 9 6"></polyline>
                      </svg>
                    </div>

                    <textarea
                      className="w-full pl-8 pt-0 pb-0 rounded-md focus:outline-none focus:ring-0 focus:border-none bg-gray-100 border-0 resize-none"
                      placeholder="Enter terms and conditions here..."
                      rows={1}
                      value={
                        transactions[0].terms_condition === null ||
                        transactions[0].terms_condition === "null"
                          ? ""
                          : transactions[0].terms_condition
                      }
                      disabled readOnly
                      // onChange={(e) => setTermsAndConditions(e.target.value)}
                      maxLength={600}
                    />
                  </div>
                )}
                  </div>

                                  {/* Memo & Notes Section - Only show in View Component if partially paid */}
{/* { (
  <div className="my-4 bg-white rounded-md shadow-md overflow-hidden" >
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "pointer",
        padding: "10px",
        backgroundColor: "#f3f4f6",
      }}
      onClick={toggleMemoInput}
    >
      <div className="flex items-center">
        <NotesIcon className="mr-2" />
        <span style={{ fontWeight: "bold" }}>Memos & Notes</span>
        {/* <span className="ml-2 text-xs text-red-600">(Required for partial payments)</span> */}
      {/* </div>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        style={{
          transform: isMemoOpen ? "rotate(90deg)" : "rotate(0deg)",
          transition: "transform 0.2s ease",
        }}
      >
        <polyline points="9 18 15 12 9 6"></polyline>
      </svg>
    </div>

    {isMemoOpen && (
      <div className="p-4"> */}
        {/* List of previous memos (maximum 5) - Read-only in View mode */}
        {/* {memos.length > 0 && (
          <div className="border border-gray-200 rounded-md max-h-60 overflow-y-auto">
            {memos.map((memo, index) => (
              <div
                key={memo.id}
                className={`p-3 ${
                  index < memos.length - 1 ? "border-b border-gray-200" : ""
                }`}
              >
                <div className="text-sm mb-1">{memo.text}</div>
                <div className="flex justify-between text-xs text-gray-500 mt-1"> */}
                  {/* <span>By: {memo.created_by}</span>
                  <span>
                    {memo.edited_on
                      ? `Edited: ${formatMemoDate(memo.edited_on)}`
                      : `Created: ${formatMemoDate(memo.created_on)}`}
                  </span> */}
                {/* </div>
              </div>
            ))}
          </div>
        )} */}

        {/* {memos.length === 0 && (
          <div className="text-center text-gray-500 py-4">
            No memos available for this statement.
          </div>
        )}
      </div>
    )}
  </div>
)} */} 


<div className="pt-4">
  {!isMemoOpen ? (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "pointer",
        padding: "10px",
        marginBottom: "30px"
      }}
      className='bg-gray-100'
      onClick={toggleMemoInput}
    >
      <span style={{ fontWeight: "bold" }}>Memos & Notes</span>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        style={{
          transform: isMemoOpen ? "rotate(90deg)" : "rotate(0deg)",
          transition: "transform 0.2s ease",
        }}
      >
        <polyline points="9 18 15 12 9 6"></polyline>
      </svg>
    </div>
  ) : (
    <div className="relative bg-gray-100 px-2 rounded-md mb-2" style={{ marginBottom: "30px" }}>
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <NotesIcon
            className="text-black bg-white absolute left-2 top-1/2 transform -translate-y-1/2"
            style={{ borderRadius: "50%", fontSize: "28px" }}
          />
          <div className="pl-8 pt-1 text-md font-semibold">
            Memos & Notes
          </div>
        </div>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          onClick={toggleMemoInput}
          style={{
            transform: isMemoOpen ? "rotate(90deg)" : "rotate(0deg)",
            transition: "transform 0.2s ease",
            cursor: "pointer"
          }}
        >
          <polyline points="9 18 15 12 9 6"></polyline>
        </svg>
      </div>

      <textarea
        className="w-full pl-8 pt-0 pb-0 rounded-md focus:outline-none focus:ring-0 focus:border-none bg-gray-100 border-0 resize-none"
        placeholder="Enter memo or notes here..."
        rows={1}
        value={
          transactions[0].memo === null ||
          transactions[0].memo === "null"
            ? ""
            : transactions[0].memo
        }
        disabled readOnly
        // onChange={(e) => setMemo(e.target.value)}
        maxLength={600}
      />
    </div>
  )}
</div>




      {(status === "1") && (
                <div className="flex justify-end items-end mb-4 pt-[20px]">
                  <button
                    className="w-[150px] px-2 px-12 py-1 border-2 border-blue-600 text-blue-600 hover:font-bold rounded-md hover:bg-blue-600 hover:shadow-blue-700/50 uppercase duration-200 hover:text-white hover:shadow-lg hover:border-transparent active:scale-90 "
                    // className="mb-4 px-6 py-2 text-green-600 font-bold text-lg border-2 border-green-600 bg-green-100 hover:bg-green-200 rounded-md"
                    onClick={(e) => handleApproveStatement(e)}
                  >
                    APPROVE
                  </button>
                </div>
              )}
              {(status === "2" ) && (
                <div className="flex justify-end items-end mb-4  pt-[20px]">
                  <button
                    className="w-[150px] px-2 px-12 py-1 border-2 border-green-600 text-green-600 hover:font-bold rounded-md hover:bg-green-600 hover:shadow-green-700/50 uppercase duration-200 hover:text-white hover:shadow-lg hover:border-transparent active:scale-90 "
                    // className="mb-4 px-6 py-2 text-green-600 font-bold text-lg border-2 border-green-600 bg-green-100 hover:bg-green-200 rounded-md"
                    onClick={(e) => {
                      e.preventDefault();
                      // handleSentStatement(e)
                      setOpenSendInvoiceModalId(statementNumber);
                    }}
                  >
                    SEND
                  </button>
                </div>
              )}
              {(status === "3") &&
                location.pathname.includes("invoiceview") && (
                  <div className="flex justify-end items-end mb-4 pt-[20px]">
                    <button
                      className="w-[150px] px-2 px-12 py-1 border-2 border-purple-600 text-purple-600  hover:font-bold rounded-md hover:bg-purple-600 hover:shadow-purple-700/50 uppercase duration-200 hover:text-white hover:shadow-lg hover:border-transparent active:scale-90 "
                      // className="mb-4 px-6 py-2 text-green-600 font-bold text-lg border-2 border-green-600 bg-green-100 hover:bg-green-200 rounded-md"
                      onClick={(e) => {
                        e.preventDefault();
                        //   handlePayModalInvoice()
                        // navigate(`/PayInvoice/${invoicenumber}`, {
                        //   state: {
                        //     // setTriggerFetch:setTriggerFetch,
                        //   },
                        // });
                      }}
                    >
                      PAY
                    </button>
                  </div>
                )}


            
    </>
  );

  // Edit Component
  const EditComponent = () => {
    const [isMemoOpen, setIsMemoOpen] = useState(false);
    const [currentMemo, setCurrentMemo] = useState("");
    const [memos, setMemos] = useState([]);
    const memoTextareaRef = useRef(null);
    const toggleMemoInput = useCallback(() => {
      setIsMemoOpen((prev) => !prev);
    }, []);
    useEffect(() => {
      if (isMemoOpen && memoTextareaRef.current) {
        memoTextareaRef.current.focus();
        memoTextareaRef.current.setSelectionRange(
          memoTextareaRef.current.value.length, 
          memoTextareaRef.current.value.length
        );
      }
    }, [isMemoOpen]);
    const formatMemoDate = (dateString) => {
      return dayjs(dateString).format("MM/DD/YYYY hh:mm A");
    };
      
    // Loads dummy memo data when the component mounts
    useEffect(() => {
      // This would be replaced with an API call in production
      setMemos([
        {
          id: 1,
          text: "Customer requested extension on payment due to cash flow issues. Extended due date by 14 days.",
          created_by: "John Smith",
          created_on: "2025-03-20T10:30:00",
          edited_on: null
        },
        {
          id: 2,
          text: "Customer made partial payment of $1,250.00. Balance remaining: $3,750.00",
          created_by: "Jane Doe",
          created_on: "2025-03-22T14:15:00",
          edited_on: null
        },
        {
          id: 3,
          text: "Discussed remaining balance with customer. They will pay the remaining amount by the end of next week.",
          created_by: "John Smith",
          created_on: "2025-03-24T09:45:00",
          edited_on: "2025-03-24T11:20:00"
        }
      ]);
    }, []);
    const handleSaveMemo = () => {
      if (currentMemo.trim() === "") return;
    
      // Create a new memo object
      const newMemo = {
        id: memos.length + 1, // In real app, this would be generated by the server
        text: currentMemo,
        created_by: localStorage.getItem("user_id") || "Current User",
        created_on: new Date().toISOString(),
        edited_on: null
      };
    
      // Add the new memo to the beginning of the array (latest first)
      setMemos([newMemo, ...memos]);
      setCurrentMemo(""); // Clear the input after saving
    
      // In a real application, you would save to the server here
      /* API call would go here */
    };
    // We'll track a separate state for input values
    const [inputAmounts, setInputAmounts] = useState({});
    // State for real-time calculated balance
    const [calculatedBalance, setCalculatedBalance] = useState(0);
    
    // When component mounts, initialize input values from editableTransactions
    // useEffect(() => {
    //   const initialValues = {};
    //   editableTransactions.forEach((item, index) => {
    //     initialValues[index] = parseFloat(item.statement_amt).toFixed(2);
    //   });
    //   setInputAmounts(initialValues);
      
    //   // Initialize calculated balance
    //   const balance = calculateBalanceAmount(editableTransactions, initialValues);
    //   setCalculatedBalance(balance);
    // }, [editableTransactions]);
    useEffect(() => {
      const initialValues = {};
      editableTransactions.forEach((item, index) => {
        initialValues[index] = item.statement_amt;
      });
      setInputAmounts(initialValues);
      
      // Initialize calculated balance
      const balance = calculateBalanceAmount(editableTransactions);
      setCalculatedBalance(balance);
    }, [editableTransactions]);
    
    // Update calculated balance when inputAmounts change
    // const calculatedBalance = useMemo(() => {
    //   return calculateBalanceAmount(editableTransactions, inputAmounts);
    // }, [editableTransactions, inputAmounts]); 


    const validateFormBeforeSave = () => {
      // Check if all editable items have valid dates
      const missingDates = editableTransactions.filter(item => {
        // Only check editable types
        if (isItemTypeEditable(item.statement_type)) {
          // Check if date is missing or empty string
          return !item.statement_date || item.statement_date === '';
        }
        return false;
      });
    
      if (missingDates.length > 0) {
        // Create error message with list of items missing dates
        const itemList = missingDates.map(item => item.statement_type).join(', ');
        dispatch(addToast({ 
          type: 'danger', 
          message: `Please select dates for: ${itemList}` 
        }));
        return false;
      }
      
      return true;
    };
    
    // Save function that uses inputAmounts instead of editableTransactions
    const handleSaveWithInputValues = async () => {
      if (!validateFormBeforeSave()) {
        return; 
      }
      try {
        // First update editableTransactions with current input values
        const updatedTransactions = [...editableTransactions];
        Object.entries(inputAmounts).forEach(([index, value]) => {
          const idx = parseInt(index);
          if (updatedTransactions[idx]) {
            updatedTransactions[idx].statement_amt = value;
          }
        });
        
        // Update the balance amount in all transactions
        updatedTransactions.forEach(transaction => {
          transaction.balance_amt = calculatedBalance.toString();
        });
        
        // Basic payload with what the API needs
        const payload = {
          statementNumber: statementNumber,
          balanceAmt:calculatedBalance.toString(),
          waterbill_date: null,
          waterbill_amt: null,
          rent_date: null,
          rent_amt: null,
          posfee_date: null,
          posfee_amt: null,
          loyaltydis_date: null,
          loyaltydis_amt: null,
          loyaltycredit_date: null,
          loyaltycredit_amt: null,
          loyaltygallons_date: null,
          loyaltygallons_amt: null,
          termsCondition:termsAndConditions,
          businessId:localStorage.getItem("business_id"),
          userId:localStorage.getItem("user_id"),
          userRole :localStorage.getItem("user_role")
        };
        
        // Process items using the updated transactions
        for (const item of updatedTransactions) {
          if (item.statement_type === 'Water Bill') {
            payload.waterbill_date = item.statement_date;
            payload.waterbill_amt = parseFloat(item.statement_amt);
          } 
          else if (item.statement_type === 'Rent') {
            payload.rent_date = item.statement_date;
            payload.rent_amt = parseFloat(item.statement_amt);
          }
          else if (item.statement_type === 'POS Fee') {
            payload.posfee_date = item.statement_date;
            payload.posfee_amt = parseFloat(item.statement_amt);
          }
          else if (item.statement_type === 'Loyalty Discount') {
            payload.loyaltydis_date = item.statement_date;
            payload.loyaltydis_amt = parseFloat(item.statement_amt);
          }
          else if (item.statement_type === 'Loyalty Credits') {
            payload.loyaltycredit_date = item.statement_date;
            payload.loyaltycredit_amt = parseFloat(item.statement_amt);
          }
          else if (item.statement_type === 'Loyalty Gallons') {
            payload.loyaltygallons_date = item.statement_date;
            payload.loyaltygallons_amt = parseFloat(item.statement_amt);
          }
        }
        
        // Make simple direct API call
        const response = await axios.post(`${baseURL}/${("statement/update")}`, payload);
        
        if (response.data) {
          // Update both state variables
          setEditableTransactions(updatedTransactions);
          setTransactions(updatedTransactions);
          
          dispatch(addToast({ type: 'success', message: 'Statement updated successfully!' }));
          navigate('/statements');
        } else {
          dispatch(addToast({ type: 'danger', message: 'Update failed' }));
        }
      } catch (error) {
        console.error("Error:", error);
        dispatch(addToast({ type: 'danger', message: 'Update failed' }));
      }
    };

    
    
    return (
      <>
        <style>{`
    :global(.ant-select-black-text .ant-select-selection-item),
    :global(.ant-select-black-text .ant-select-selection-placeholder) {
      color: black !important;
    }
    :global(.ant-select-black-text .ant-select-selector) {
      border-color: black !important;
    }
  `}</style>

<div className="flex flex-col w-full ">
  {/* Top section with sender and recipient information */}
  <div className="flex justify-between items-center w-full pb-3 ">
    {/* Sender section (left) */}
    <div className="flex items-start gap-4">
      <img
        src={`${baseURL}/uploads/img/business/${businessData.image_file}`}
        alt="Business Logo"
        className="w-16 h-16 object-contain"
      />
      <div className="text-sm pt-1">
        <p className="font-bold text-gray-800 mb-1">From</p>
        <p >{businessData.business_name}</p>
        <p>{businessData.business_address}</p>
        <p>
          {businessData.business_city}, {businessData.business_state} {businessData.business_zip_code}
        </p>
        {businessData.contact_no && businessData.contact_no !== "null" && (
          <p className="mt-1">Ph: {businessData.contact_no}</p>
        )}
      </div>
    </div>

    {/* Statement title (center) with stylized underline */}
    <div className="text-left -mt-2 relative">
      {/* <h4 className="text-2xl font-bold uppercase tracking-wider text-gray-800">
        STATEMENT
      </h4>
      <div className="relative">
        <p className="text-lg font-medium mt-1 mb-3 pb-1">
          {getMonthlyStatementTitle(transactions[0]?.statement_month)}
        </p>
      </div> */}
            <p className="font-bold text-gray-800 mb-1">To</p>
      <p>{CustomerAddress.business_name}</p>
      <p>{CustomerAddress.business_address}</p>
      <p>
        {CustomerAddress.business_city}, {CustomerAddress.business_state} {CustomerAddress.business_zip_code}
      </p>
    </div>

    {/* Recipient section (right) */}
    <div className="text-sm text-right pt-1 ">
      <p className=" mb-2 flex justify-between gap-6"><span>Statement #: </span><span>{statementNumber}</span></p>
      <p className="mb-2 flex justify-between gap-6"><span>Statement Month: </span>
       <span>{getMonthNameFromDate(transactions[0]?.statement_month)}</span></p>
      <p className=" mb-2  flex justify-between gap-6"><span>Statement Year:</span>
       <span>{getYearFromDate(transactions[0]?.statement_month)}</span></p>
      <p className=" mb-2  flex justify-between gap-6"><span>Statement Date:</span>
       <span>{formatWebDate(transactions[0]?.added_on)}</span></p>

    </div>
  </div>
  
  {/* <div className="relative w-full h-3 mb-4">
    <div className="absolute top-0 left-0 right-0 h-px bg-gray-200"></div>
  </div> */}
</div>

        
        {/* Product Dropdown for adding new products */}
        {/* {availableProducts.length > 0 && (
          <div className="pb-4 flex items-center justify-end">
            <label className="mr-2 font-medium">Add Product:</label>
    
            <Select 
             style={{ 
              width: 200,
           
            }}
              placeholder="Select a product" 
              onChange={handleAddProduct}
              className="ant-select-black-text"
              value={null}
            >
              {availableProducts.map(product => (
                <Option key={product} value={product}>{product}</Option>
              ))}
            </Select>
          </div>
        )} */}

        <table className="w-full border-collapse border border-gray-300">
          <thead>
            <tr className="bg-gray-200 text-left">
              <th className="border text-left p-2 pl-[20px] w-[135px]">Type</th>
              <th className="border p-2 w-[142px]">Invoice #</th>
              <th className="border p-2 w-[175px]">Date</th>
              <th className="border text-center p-2 w-[145px]">Product Name</th>
              <th className="border p-2 text-center w-[115px]">Quantity</th>
              <th className="border p-2 text-right">Amount ($)</th>
            </tr>
          </thead>
          <tbody style={{ fontFamily: "Sans-Serif" }}>
            {editableTransactions.map((item, index) => {
              const isEditable = isItemTypeEditable(item.statement_type);
              
              return (
                <tr key={index} className="border">
                  <td className="text-left p-2 pl-[20px] flex items-center ">
                    <span style={{whiteSpace:'nowrap'}}>{item.statement_type}</span>
                    {isEditable && (
                      <button
                        onClick={() => handleDeleteRow(index)}
                        className="ml-2 text-red-600 hover:text-red-800"
                        type="button"
                      >
                        {/* <FaTrash className="h-4 w-4" /> */}
                      </button>
                    )}
                  </td>
                  <td className="border p-2">{item.invoice_num || "-"}</td>
                  <td className="border p-2">
            

                  {isEditable ? (
  <div className="relative">
    <DatePicker 
      className={`border rounded h-[32px] w-[130px] ${
        !item.statement_date ? 'border-red-500' : 'border-gray-300'
      }`}
      value={item.statement_date ? dayjs(item.statement_date) : null}
      format="MM-DD-YYYY"
      onChange={(date) => handleDateChange(index, date)}
      open={datePickerOpen === index}
      onOpenChange={(open) => setDatePickerOpen(open ? index : null)}
      placeholder="Select date *"
    />
    {/* {!item.statement_date && (
      <span className="text-red-500 text-xs absolute -bottom-4 left-1">
        Required
      </span>
    )} */}
  </div>
) : (
  formatWebDate(item.statement_date)
)}
                  </td>
                  <td className="border text-center p-2">{item.product_name || "-"}</td>
                  <td className="border p-2 text-center">{item.product_qty || "-"}</td>
                  <td className={`border p-2 text-right`}>
  {isEditable ? (
    <div className="relative w-[120px] ml-auto">
      {/* Overlay for displaying formatted values while typing */}
      {inputAmounts[index] ? (
        <div className="absolute inset-0 pointer-events-none flex justify-end items-center pr-2">
          <span className={getAmountColor(item.statement_type, inputAmounts[index])}>
            {getAmountColor(item.statement_type).includes('red') ? '-' : ''}
            {formatExactNumber(inputAmounts[index])}
          </span>
        </div>
      ) : null}
      
      {/* The actual input field */}
      <input
  type="text" 
  className={`w-[120px] text-right border rounded p-1 ${
    inputAmounts[index] ? 'text-transparent' : '' 
  }`}
   maxLength={8} 
  value={inputAmounts[index] || ''}
  onChange={(e) => {
    const value = e.target.value;
    // Only allow valid number input
    if (value === '' || /^[0-9]*\.?[0-9]*$/.test(value)) {
      // Update input amount state
      const newInputAmounts = {...inputAmounts, [index]: value};
      setInputAmounts(newInputAmounts);
      
      // Create a temporary copy of transactions for balance calculation
      const tempTransactions = editableTransactions.map((item, idx) => {
        if (idx === index) {
          return {...item, statement_amt: value || '0'};
        }
        // For other items, use their current inputAmount values
        return {...item, statement_amt: inputAmounts[idx] || item.statement_amt};
      });
      
      // Calculate and update balance
      const newBalance = calculateBalanceAmount(tempTransactions);
      setCalculatedBalance(newBalance);
    }
  }}
  onBlur={(e) => {
    // Format on blur
    const value = e.target.value;
    if (value === '') {
      // Set to zero if empty
      const newInputAmounts = {...inputAmounts, [index]: '0'};
      setInputAmounts(newInputAmounts);
      
      // Update editable transaction for this row
      const updatedTransactions = [...editableTransactions];
      updatedTransactions[index].statement_amt = '0';
      setEditableTransactions(updatedTransactions);
      
      // Recalculate balance
      const newBalance = calculateBalanceAmount(updatedTransactions);
      setCalculatedBalance(newBalance);
    } else {
      // Format to 2 decimal places for display
      const formattedValue = parseFloat(value).toFixed(2);
      const newInputAmounts = {...inputAmounts, [index]: formattedValue};
      setInputAmounts(newInputAmounts);
      
      // Update editable transaction for this row
      const updatedTransactions = [...editableTransactions];
      updatedTransactions[index].statement_amt = formattedValue;
      setEditableTransactions(updatedTransactions);
      
      // Recalculate balance
      const newBalance = calculateBalanceAmount(updatedTransactions);
      setCalculatedBalance(newBalance);
    }
  }}
/>
    </div>
  ) : (
    <span className={getAmountColor(item.statement_type, item.statement_amt)}>
      {getAmountColor(item.statement_type).includes('red') ? '-' : ''}
      {parseFloat(item.statement_amt).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })}
    </span>
  )}
</td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <div className="pt-4">
                {!isOpen ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      cursor: "pointer",
                      padding: "10px",
                      
                      marginBottom: "30px"
                    }}
                    className='bg-gray-100'
                    onClick={toggleInput}
                  >
                    <span style={{ fontWeight: "bold" }}>Terms & Conditions</span>

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      style={{
                        transform: isOpen ? "rotate(90deg)" : "rotate(0deg)",
                        transition: "transform 0.2s ease",
                      }}
                    >
                      <polyline points="9 18 15 12 9 6"></polyline>
                    </svg>
                  </div>
                ) : (
                  <div className="relative bg-gray-100 px-2 rounded-md mb-2" style={{ marginBottom: "30px" }}>
                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        <LightbulbOutlinedIcon
                          className="text-black bg-white absolute left-2 top-1/2 transform -translate-y-1/2"
                          style={{ borderRadius: "50%", fontSize: "28px" }}
                        />
                        <div className="pl-8 pt-1 text-md font-semibold">
                          Terms & Conditions
                        </div>
                      </div>

                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        onClick={toggleInput}
                        style={{
                          transform: isOpen ? "rotate(90deg)" : "rotate(0deg)",
                          transition: "transform 0.2s ease",
                          cursor: "pointer"
                        }}
                      >
                        <polyline points="9 18 15 12 9 6"></polyline>
                      </svg>
                    </div>

                    {/* <textarea
                      className="w-full pl-8 pt-0 pb-0 rounded-md focus:outline-none focus:ring-0 focus:border-none bg-gray-100 border-0 resize-none"
                      placeholder="Enter terms and conditions here..."
                      rows={1}
                      value={termsAndConditions}
                      onChange={(e) => setTermsAndConditions(e.target.value)}
                      maxLength={600}
                      ref={textareaRef} // Attach ref 
                    /> */}
                    <textarea
  ref={textareaRef}
  className="w-full pl-8 pt-0 pb-0 rounded-md focus:outline-none focus:ring-0 focus:border-none bg-gray-100 border-0 resize-none"
  placeholder="Enter terms and conditions here..."
  rows={1}
  value={termsAndConditions}
  onChange={(e) => setTermsAndConditions(e.target.value)}
  maxLength={600}
  onFocus={(e) => e.target.setSelectionRange(e.target.value.length, e.target.value.length)} // Keep cursor at end
/>

                  </div>
                )}
                  </div>
  
      
  
  
        {/* Memo & Notes Section - Always show in Edit mode, but with special requirements if partially paid */}
<div className="relative my-4 bg-white rounded-md shadow-md overflow-hidden"  >
  <div
    style={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      cursor: "pointer",
      padding: "10px",
      backgroundColor: "#f3f4f6",
    }}
    onClick={toggleMemoInput}
  >
     <div className="flex items-center">
                        {/* <NotesIcon
                          className="text-black bg-white absolute left-2 top-1/2 transform -translate-y-1/2"
                          style={{ borderRadius: "50%", fontSize: "28px" }}
                        /> */}
                        <div className="text-md font-semibold">
                          Memos & Notes
                        </div>
                      </div>

    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      style={{
        transform: isMemoOpen ? "rotate(90deg)" : "rotate(0deg)",
        transition: "transform 0.2s ease",
      }}
    >
      <polyline points="9 18 15 12 9 6"></polyline>
    </svg>
  </div>

  {isMemoOpen && (
    <>
      <div className="mb-4">
      <textarea
        ref={memoTextareaRef}
        className="w-full pl-8 pt-0 pb-0 rounded-md focus:outline-none focus:ring-0 focus:border-none bg-gray-100 border-0 resize-none"
        placeholder={ "Enter memo or note here..."}
        rows={1}
        value={currentMemo}
        onChange={(e) => setCurrentMemo(e.target.value)}
        maxLength={500}
      />
      <div className="flex justify-between mt-2 mx-2">
        <small className="text-gray-500">{currentMemo.length}/500 characters</small>
        {/* <button
          onClick={handleSaveMemo}
          className="px-4 py-1 text-sm text-white bg-blue-600 rounded-md hover:bg-blue-700 disabled:opacity-50"
          disabled={currentMemo.trim() === ""}
        >
          Save Memo
        </button> */}
      </div>
    </div>
    <div className="p-4 pt-0">
      {/* Input area for new memo */}
    

      {/* List of previous memos (maximum 5) */}
      {memos.length > 0 && (
        <div className="border border-gray-200 rounded-md max-h-60 overflow-y-auto">
          {memos.map((memo, index) => (
            <div
              key={memo.id}
              className={`p-3 ${
                index < memos.length - 1 ? "border-b border-gray-200" : ""
              }`}
            >
              <div className="text-sm mb-1">{memo.text}</div>
              <div className="flex justify-between text-xs text-gray-500 mt-1">
                {/* <span>By: {memo.created_by}</span>
                <span>
                  {memo.edited_on
                    ? `Edited: ${formatMemoDate(memo.edited_on)}`
                    : `Created: ${formatMemoDate(memo.created_on)}`}
                </span> */}
              </div>
            </div>
          ))}
        </div>
      )}

      {memos.length === 0 && (
        <div className="text-center text-gray-500 py-4">
          No memos available for this statement.
        </div>
      )}
    </div>
    </>
  )}
</div>

<div className="mt-4 text-right font-bold text-lg pr-1 pt-[20px]">
          Balance Amount: ${parseFloat(calculatedBalance).toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}
        </div>
        <div className="mt-4 flex justify-end gap-2 pt-6"> 
          <button 
            onClick={handleSaveWithInputValues}
            className="w-24 px-2 py-1 border-2 border-green-600 mb-4
            hover:font-bold rounded-md hover:bg-green-600  
            hover:text-white hover:shadow-lg
            uppercase duration-200" 
            type="button"
          > 
            Save 
          </button>
  
          {/* <button 
            onClick={cancelAllEdits} 
            className="w-24 px-2 py-1 border-2 border-gray-600 mb-4
            hover:font-bold rounded-md hover:bg-gray-600  
            hover:text-white hover:shadow-lg
            uppercase duration-200" 
            type="button"
          > 
            Cancel 
          </button>  */}
        </div>

      </>
    );
  };
  // const menuItems = [{ name: "View Statement" }];

  const menuItems = [{ name: `${activeItem} Statement` }];



  
  // const handleDownloadStatement = async () => {
  //   const statementNumber = statementNumber;
  //   // console.log("selected rrr" , selectedRows);
  //   try {
  //     const response = await axios.get(
  //       `${baseURL}/c3RhdGVtZW50L2Rvd25sb2Fkc3RhdGVtZW50`,
  //       {
  //         responseType: {userRole: user_role,

  //         },
  //       }
  //     );
  //     const filename = `${businessName}_${statementNumber}_${formatDate(
  //       new Date()
  //     )}.pdf`;
  //     const blob = new Blob([response.data], { type: "application/pdf" });
  //     const url = window.URL.createObjectURL(blob);
  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.download = filename; // Set the filename here
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //     // table.resetRowSelection();
  //     window.URL.revokeObjectURL(url);

  //     dispatch(
  //       addToast({
  //         type: "success",
  //         message: `Invoice for ${businessName} downloaded successfully`,
  //       })
  //     );

  //     // console.log("Invoice downloaded successfully.");
  //   } catch (error) {
  //     console.log("Error downloading invoice:", error);
  //   }
  // };


  function formatDate(date) {
    const day = date.getDate() + 1;
    const month = date.getMonth() + 1; // Months are zero-based
    const year = date.getFullYear().toString(); // Extract last two digits of year
    return `${month}-${day}-${year}`;
  }
  const userRole = localStorage.getItem("user_role");
  const userId = localStorage.getItem("user_id");



  const handleDownloadStatement = async () => {
    try {
      // First make a request to get the PDF file
      const response = await axios({
        method: 'post',
        url: `${baseURL}/c3RhdGVtZW50L2Rvd25sb2Fkc3RhdGVtZW50`,
        data: {
          customerId: customer_id,
          businessId: business_id,
          statementNumber: statementNumber,
          loggedInUserId: userId,
          userRole: userRole
        },
        responseType: 'blob' // Important: This tells axios to handle the response as binary data
      });
      
      // Create a filename with businessName and statement number
      const filename = `${CustomerAddress.business_name}_${statementNumber}_${formatDate(new Date())}.pdf`;
      
      // Create a Blob from the response data
      const blob = new Blob([response.data], { type: 'application/pdf' });
      
      // Create a link to download the file
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      
      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
      
      dispatch(
        addToast({
          type: "success",
          message: `Statement for ${CustomerAddress.business_name} downloaded successfully`,
        })
      );
    } catch (error) {
      console.error("Error downloading statement:", error);
      
      // Check if we can get more details about the error
      let errorMessage = "Failed to download statement";
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        errorMessage = `Error ${error.response.status}: ${error.response.statusText}`;
        
        // If the error response is a blob, we need to read it as text
        if (error.response.data instanceof Blob) {
          const reader = new FileReader();
          reader.onload = () => {
            try {
              const errorData = JSON.parse(reader.result);
              dispatch(
                addToast({
                  type: "error",
                  message: errorData.message || errorMessage,
                })
              );
            } catch (e) {
              dispatch(
                addToast({
                  type: "error",
                  message: errorMessage,
                })
              );
            }
          };
          reader.readAsText(error.response.data);
          return; // Early return to avoid showing the error toast twice
        }
      } else if (error.request) {
        // The request was made but no response was received
        errorMessage = "No response received from server";
      } else {
        // Something happened in setting up the request that triggered an Error
        errorMessage = error.message;
      }
      
      dispatch(
        addToast({
          type: "error",
          message: errorMessage,
        })
      );
    }
  };

  return (
    <>
    
      {loading && <FullScreenLoader />}
      <div className="flex w-full px-2 ">
  <div className="flex-1 flex justify-start items-center ">
    {/* <nav aria-label="breadcrumb">
      <ol className="flex space-x-2 text-md font-bold text-gray-700 mb-6">
        <li>Statements</li>
        <li>
          <span className="text-gray-400">{">>"}</span>
        </li>
        <li>View Statement</li>
      </ol>
    </nav> */}
  </div>

  {/* Center Aligned Custom Tab Component */}
  <div className="flex-1 flex justify-center">
    <CustomCommonTab items={menuItems}  />
  </div>

  {/* Placeholder for Right-Aligned Section */}
  <div className="flex-1 flex justify-end">
    {/* Add any right-aligned content here if needed */}
  </div>
</div>
      <div
        className="relative w-[60vw] bg-white rounded-lg mx-auto my-10 p-8 space-y-0 mt-2"
        style={{ boxShadow: "rgba(0, 0, 0, 0.3) 0px 2px 10px" }}
      > 
<div className="absolute -top-5 -right-1 flex items-center space-x-2 z-10">
  {activeItem === "View" ? (
    // When in View mode, show download, edit, delete icons based on status
    <>
      <CustomNavigationButtons type="download" onClick={handleDownloadStatement} />
      {status === "1" && (
        <CustomNavigationButtons type="edit" onClick={() => setActiveItem("Edit")} />
      )}
      {(status === "1" || status === "2") && (
        <CustomNavigationButtons type="delete" onClick={() => setShowDeleteModal(true)} />
      )}
    </>
  ) : (
    <>
 

    </>
  )}

  {/* Home button - always visible */}
  <div
    className="flex items-center justify-center cursor-pointer rounded-full bg-[#cefafe] hover:bg-[#25caed] w-9 h-9 mb-0 border-[0.5px] border-[#25caed] hover:border-[#25caed] hover:scale-110 shadow-md transition-all duration-200 group"
    onClick={() => {
      navigate("/");
    }}
    style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
  >
    <FaHome className="text-[#25caed] group-hover:text-white transition-colors duration-200" size={16} />
  </div>

  {/* Close/Return button - always visible */}
  <button
    className="bg-red-200 hover:bg-red-500 w-9 h-9 flex items-center justify-center rounded-full cursor-pointer hover:bg-red-700 border-[0.5px] border-red-500 hover:border-red-700 hover:scale-110 shadow-md transition-all duration-200 group"
    // onClick={() => navigate('/statements')}
    onClick={(e) => {
      e.preventDefault();
      if (activeItem === "Edit") {
        // When in Edit mode, return to View mode
        setActiveItem("View");
      } else {
        // When in View mode, navigate to UserDashboard
        navigate('/statements');
      }
    }}
  >
    <span className="text-red-500 group-hover:text-white text-2xl font-bold mb-1 transition-colors duration-200">&times;</span>
  </button>
</div>
        
        {/* Conditionally render either View or Edit component based on activeItem state */}
        {activeItem === "View" ? <ViewComponent /> : <EditComponent />}
      
        {/* <div className="mt-6 pt-4 text-sm text-black-600">
          <div className="mt-6 border-t pt-4 text-sm text-black-600 ">
            <div className="flex items-center justify-between mt-2 flex text-sm pt-4">
              <div className="flex items-center gap-4">
                <div>
                  <img
                    src={`${baseURL}/uploads/img/business/${businessData.image_file}`}
                    alt="Business Logo"
                    className="w-20 h-20 object-contain mb-[20px] mt-"
                  />
                </div>
                <div className="font-bold text-sm uppercase text-gray-600 text-sm capitalize">
                  <p className="font-bold text-sm uppercase">
                    {businessData.business_name}
                  </p>
                  <p className="font-bold text-sm uppercase">
                    {businessData.business_address}
                  </p>
                  <p className="font-bold text-sm uppercase">
                    {businessData.business_city}
                  </p>
                  <p className="font-bold text-sm uppercase">
                    {businessData.business_state},{" "}
                    {businessData.business_zip_code}
                  </p>
                </div>
              </div>

              <div className="font-bold text-sm uppercase text-gray-600 text-sm capitalize">
                <p className="font-bold text-sm uppercase">
                  PH: {businessData.contact_no}
                </p>
                <p className="font-bold text-sm uppercase">
                  FX: {businessData.fax_number}
                </p>
               
              </div>
            </div>
          </div>
        </div> */}
        
        {/* Statement Delete Modal */}
        {showDeleteModal && (
          <Modal show={showDeleteModal} size="md" popup onClose={() => setShowDeleteModal(false)}>
            <Modal.Header />
            <Modal.Body>
              <div className="text-center">
                <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400 mt-3">
                  Are you sure you want to delete this statement?
                </h3>
                <div className="flex justify-center gap-4">
                  <Button 
                    color="failure"  
                    onClick={() => handledeleteStatement(statementNumber, customer_id, business_id, user_id, user_role)}
                  >
                    Yes, I'm sure
                  </Button>
                  <Button 
                    color="gray" 
                    onClick={() => setShowDeleteModal(false)}
                  >
                    No, cancel
                  </Button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        )}
        
        {/* Row Delete Confirmation Modal */}
        {deleteRowConfirmModal && (
          <Modal show={deleteRowConfirmModal} size="md" popup onClose={() => setDeleteRowConfirmModal(false)}>
            <Modal.Header />
            <Modal.Body>
              <div className="text-center">
                <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400 mt-3">
                  Are you sure you want to remove this item?
                </h3>
                <div className="flex justify-center gap-4">
                  <Button 
                    color="failure"  
                    onClick={confirmDeleteRow}
                  >
                    Yes, remove it
                  </Button>
                  <Button 
                    color="gray" 
                    onClick={() => setDeleteRowConfirmModal(false)}
                  >
                    No, cancel
                  </Button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        )}

{openSendInvoiceModalId && (
  <PopUpModalForSendStatement
    onClose={() => setOpenSendInvoiceModalId(null)}
    invoiceNumber={openSendInvoiceModalId}
    invoiceDelete={false}
    invoiceName={CustomerAddress.business_name}
    resetRowSelection={() => {/* No row selection to reset */}}
    customerEmail={CustomerAddress.contact_email || ''} 
    finalInvoiceAmount={transactions[0]?.balance_amt || '0'}
    dueDate={transactions[0]?.statement_date || ''}
    setTriggerFetch={() => navigate('/statements')}
    triggerFetch={false}
    handleSentStatement={handleSentStatement} 
  />
)}
      </div>
    </>
  );
};

export default StatementViewFile; 

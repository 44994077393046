import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import { FaTimes, FaDownload } from 'react-icons/fa';
import config from '../../config/config';
Modal.setAppElement('#root');

const PurchaseImagesViewModel = ({ isModalOpen, closeModal, selectedImagePath }) => {
  const baseURL =
    process.env.NODE_ENV === 'production' ? config.production.baseURL : config.development.baseURL;
 const businessName = localStorage.getItem('business_name').replace(/\s+/g, '_')
  const pdfUrl = `${baseURL}uploads/docs/receipts/${businessName}/${selectedImagePath}.pdf`;
  console.log(pdfUrl, 'pdfuurl')
  const [zoomScale, setZoomScale] = useState(1);
  const containerRef = useRef(null);

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isModalOpen]);

  const handleZoom = (direction) => {
    setZoomScale((prev) => Math.max(1, direction === 'in' ? prev + 0.1 : prev - 0.1));
  };

  if (!isModalOpen) return null;

  return (
    <div style={overlayStyles}>
      <div style={headerStyles}>
        <button onClick={closeModal} style={closeButtonStyles}>
          <FaTimes />
        </button>
      </div>
      <div style={containerStyles} ref={containerRef}>
        {selectedImagePath ? (
          <iframe
            src={pdfUrl}
            title="PDF Viewer"
            style={{ ...iframeStyles, transform: `scale(${zoomScale})` }}
          ></iframe>
        ) : (
          <div style={errorStyles}>No Image for this purchase</div>
        )}
      </div>
  
    </div>
  );
};

// Styling
const overlayStyles = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 9999,
};

const headerStyles = {
  position: 'absolute',
  top: '10px',
  right: '10px',
  display: 'flex',
  gap: '10px',
};

const closeButtonStyles = {
  background: 'none',
  border: 'none',
  fontSize: '24px',
  cursor: 'pointer',
  color: 'white',
};

const downloadButtonStyles = {
  background: 'none',
  border: 'none',
  fontSize: '24px',
  cursor: 'pointer',
  color: 'white',
  textDecoration: 'none',
};

const containerStyles = {
  width: '90%',
  height: '80%',
  backgroundColor: 'white',
  borderRadius: '8px',
  overflow: 'hidden',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const iframeStyles = {
  width: '100%',
  height: '100%',
  border: 'none',
  transition: 'transform 0.2s ease-in-out',
};

const errorStyles = {
  fontWeight: 'bold',
  color: 'red',
  backgroundColor: '#ffe5e5',
  padding: '10px',
  borderRadius: '5px',
};

const zoomControlsStyles = {
  position: 'absolute',
  bottom: '20px',
  display: 'flex',
  gap: '10px',
};

const zoomButtonStyles = {
  padding: '10px 20px',
  borderRadius: '5px',
  backgroundColor: '#3479E0',
  color: 'white',
  border: 'none',
  cursor: 'pointer',
};

export default PurchaseImagesViewModel;

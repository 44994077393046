// // // import { useNavigate } from "react-router-dom";
// // // import { FaHome, FaEdit, FaTrash } from "react-icons/fa";

// // // const CustomNavigationButtons = ({ type, onClick }) => {
// // //   const navigate = useNavigate();

// // //   const buttonStyles = {
// // //     home: "bg-[#25caed] hover:scale-110",
// // //     edit: "bg-yellow-500 hover:bg-yellow-600 hover:scale-110",
// // //     delete: "bg-red-500 hover:bg-red-700 hover:scale-110",
// // //   };

// // //   const icons = {
// // //     home: <FaHome className="text-white" size={20} />, 
// // //     edit: <FaEdit className="text-white" size={20} />, 
// // //     delete: <FaTrash className="text-white" size={20} />,
// // //   };

// // //   return (
// // //     <div
// // //       className={`absolute -top-4 flex items-center justify-center cursor-pointer rounded-full w-9 h-9 shadow-md ${buttonStyles[type]}`}
// // //       style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
// // //       onClick={onClick}
// // //     >
// // //       {icons[type]}
// // //     </div>
// // //   );
// // // };
// // //  export default CustomNavigationButtons




// // import {  FaEdit, FaTrash } from "react-icons/fa";

// // const CustomNavigationButtons = ({ type, onClick }) => {
// //   const buttonStyles = {
// //     // home: "bg-[#25caed] hover:scale-110",
// //     edit: "bg-velvet-500 hover:bg-velvet-600 hover:scale-110",
// //     delete: "bg-red-500 hover:bg-red-700 hover:scale-110",
// //   };
  
// //   const icons = {
// //     // home: <FaHome className="text-white" size={20} />,
// //     edit: <FaEdit className="text-white" size={20} />,
// //     delete: <FaTrash className="text-white" size={20} />,
// //   };
  
// //   return (
// //     <div
// //       className={`flex items-center justify-center cursor-pointer rounded-full w-9 h-9 shadow-md ${buttonStyles[type]}`}
// //       style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
// //       onClick={onClick}
// //     >
// //       {icons[type]}
// //     </div>
// //   );
// // };

// // export default CustomNavigationButtons;




// import { FaEdit, FaTrash } from "react-icons/fa";

// const CustomNavigationButtons = ({ type, onClick }) => {
//   const buttonStyles = {
//     edit: "bg-green-500 hover:bg-green-600 hover:scale-110",
//     delete: "bg-red-500 hover:bg-red-700 hover:scale-110",
//   };
  
//   const icons = {
//     edit: <FaEdit className="text-white" size={20} />,
//     delete: <FaTrash className="text-white " size={20} />,
//   };
  
//   return (
//     <div
//       className={`flex items-center justify-center cursor-pointer rounded-full w-9 h-9  px-2 py-2 shadow-md ${buttonStyles[type]}`}
//       style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
//       onClick={onClick}
//     >
//       {icons[type]}
//     </div>
//   );
// };

// export default CustomNavigationButtons;




import { DownloadForOfflineRounded, DownloadOutlined } from "@mui/icons-material";
import { Download, DownloadIcon } from "lucide-react";
import { FaDownload, FaEdit, FaTrash } from "react-icons/fa";

const CustomNavigationButtons = ({ type, onClick }) => {
  const buttonStyles = {
    edit: {
      container: "bg-green-100 hover:bg-green-500 border-[0.5px] border-green-500 hover:border-green-500 hover:scale-110 group",
      icon: "text-green-500 group-hover:text-white"
    },
    delete: {
      container: "bg-gray-100 hover:bg-gray-500 border-[0.5px] border-gray-500 hover:border-gray-500 hover:scale-110 group",
      icon: "text-gray-500 group-hover:text-white"
    },
    download: {
      container: "bg-blue-100 hover:bg-blue-500 border-[0.5px] border-blue-500 hover:border-blue-500 hover:scale-110 group",
      icon: "text-blue-500 group-hover:text-white"
    },
  };
  
  const icons = {
    edit: <FaEdit className={buttonStyles[type].icon + " transition-colors duration-200"} size={15} />,
    delete: <FaTrash className={buttonStyles[type].icon + " transition-colors duration-200"} size={12} />,
    download: <FaDownload className={buttonStyles[type].icon + " transition-colors duration-200"} size={12} />,
  };
  
  return (
    <div
      className={`flex items-center justify-center  cursor-pointer rounded-full w-9 h-9 px-2 py-2 shadow-md transition-all duration-200 ${buttonStyles[type].container}`}
      style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
      onClick={onClick}
    >
      {icons[type]}
    </div>
  );
};

export default CustomNavigationButtons;

import React, { useState, useMemo, useEffect, useRef } from "react";
// import DatePicker from 'react-datepicker';
// import "react-datepicker/dist/react-datepicker.css";
import "../../components/pages/Css/viewbusinessusers.css";

// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DatePicker, Space } from "antd";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FaChevronLeft, FaChevronRight, FaDownload } from "react-icons/fa";
import { Tooltip } from "react-tippy";
import { AiFillEye } from "react-icons/ai";
import { FiDownload } from "react-icons/fi";
import dayjs from "dayjs";
import { FaArrowDown } from "react-icons/fa"; // Import the down arrow icon
import { MdFilterList } from "react-icons/md";

import TextField from "@mui/material/TextField"; // Make sure this import is at the top
// import { FaSearch, FaCalendarAlt } from "react-icons/fa";
import {
  createColumnHelper,
  useReactTable,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel
} from "@tanstack/react-table";
import { useSelector, useDispatch } from "react-redux";
// import { FaArrowDownLong, FaArrowUpLong } from "react-icons/fa6";
import IndeterminateCheckbox from "./IndeterminateCheckbox";
import { MdNavigateNext } from "react-icons/md";
import { MdNavigateBefore } from "react-icons/md";
// import { FaEye, FaTrashAlt } from "react-icons/fa";
import config from "../../config/config";
import axios from "axios";
import axiosInstance from "../../authentication/axiosInstance";
import { BorderColor, Receipt } from "@mui/icons-material";
import { toast } from "react-toastify";
import { addPurchases, deleteAllPurchases } from "../../store/purchasesSlice";
import PurchaseImagesViewModel from "../models/PurchaseImagesViewModel";
import PopUpModalForDeletePurchases from "../models/PurchasesDeleteModel";
import ScrollingMessage from "./ScrollingMessage";
import FileUploadModal from "./FileUploadModal";
import { useNavigate, Link } from "react-router-dom";
import FullScreenLoader from "./FullScreenLoader";
import outlined from "@material-tailwind/react/theme/components/timeline/timelineIconColors/outlined";
import { number } from "yup";
import { addToast } from "./ToastBox/ToastBoxMessageSlice";
import Operation from "antd/es/transfer/operation";
import { FaSearch, FaEye, FaTrashAlt, FaEdit } from "react-icons/fa";
import { FaArrowUpLong, FaArrowDownLong } from "react-icons/fa6";
const Operations = () => {
  const navigate = useNavigate();
  const baseURL =
    process.env.NODE_ENV === "production"
      ? config.production.baseURL
      : config.development.baseURL;
      const businessDate = localStorage.getItem('business_date')
  const today = dayjs(); // Get today's date

  const columnHelper = createColumnHelper();
  const [isModalOpencard, setIsModalOpencard] = useState(false);
  const [activeItem, setActiveItem] = useState("View");
  const [expenses, setExpenses] = useState([]);
  const businessId = localStorage.getItem("business_id");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchExpenses();
  }, []);

  const fetchExpenses = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${baseURL}${btoa("expenses/viewallexpenses")}`,
        { businessId: businessId }
      );
      setExpenses(response.data);
    } catch (error) {
      console.error("Error fetching expenses data:", error);
    } finally {
      setLoading(false);
    }
  };
  const formatDate = (dateString) => {
    // Handle the timezone offset to display the correct date
    const date = new Date(dateString);
    const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    return utcDate.toLocaleDateString("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric"
    });
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
    }).format(amount);
  };

  const getPaymentTypeName = (paymentType) => {
    const paymentTypes = {
      "1": "Cash",
      "2": "Check",
      "3": "Card",
      "4": "Ctx/Bank Eft",
      "10": "By Phone",
      "8": "Write Off"
    };
    return paymentTypes[paymentType] || "Unknown";
  };

  const columns = useMemo(() => [
    {
      accessorFn: (row, i) => (row ? i + 1 : ""),
      header: "#"
    },
    {
      accessorKey: "added_on",
      header: "Date",
      cell: (props) => <div>{formatDate(props.getValue())}</div>
    },
    {
      accessorKey: "expenses_name",
      header: "Expense Name",
      cell: (props) => <div>{props.getValue()}</div>
    },
    {
      accessorKey: "payment_type",
      header: "Payment Method",
      cell: (props) => <div>{getPaymentTypeName(props.getValue())}</div>
    },
 
    // {
    //   accessorKey: "entry_date",
    //   header: "Entry Date",
    //   cell: (props) => <div>{formatDate(props.getValue())}</div>
    // },
     
    {
      accessorKey: "total_amt",
      header: "Amount",
      cell: (props) => <div>{formatCurrency(props.getValue())}</div>
    },
    {
      accessorKey: "cheque_num",
      header: "Check #",
      cell: (props) => <div>{props.getValue() || "_"}</div>
    },
    {
      accessorKey: "invoice_number",
      header: "Invoice #",
      cell: (props) => <div>{props.getValue()}</div>
    },


    // {
    //   accessorKey: "comment",
    //   header: "Comment",
    //   cell: (props) => (
    //     <div className="truncate max-w-xs" title={props.getValue()}>
    //       {props.getValue() || "N/A"}
    //     </div>
    //   )
    // },

    // {
    //   id: "actions",
    //   header: "Actions",
    //   cell: (props) => (
    //     <div className="flex items-center justify-center space-x-2">
    //       <Tooltip title="View" position="top" trigger="mouseenter">
    //         <button className="text-blue-600 p-1 hover:text-blue-800">
    //           <FaEye size={18} />
    //         </button>
    //       </Tooltip>
    //       <Tooltip title="Edit" position="top" trigger="mouseenter">
    //         <button className="text-green-600 p-1 hover:text-green-800">
    //           <FaEdit size={18} />
    //         </button>
    //       </Tooltip>
    //       <Tooltip title="Delete" position="top" trigger="mouseenter">
    //         <button className="text-red-600 p-1 hover:text-red-800">
    //           <FaTrashAlt size={18} />
    //         </button>
    //       </Tooltip>
    //     </div>
    //   )
    // }
  ], []);

  const handleMenuClick = (item) => {
    setActiveItem(item);
  };

  
  const disabledDate = (current) => {
    // Convert businessDate to dayjs object if it's not already
    const businessDateObj = typeof businessDate === 'string' 
      ? dayjs(businessDate) 
      : dayjs(businessDate);
    
    // Disable dates before businessDate or after today
    return (
      current.isBefore(businessDateObj, 'day') || 
      current.isAfter(today, 'day')
    );
  };
  const [isSubtotalExpanded, setSubtotalExpanded] = useState(false);
  const [isTaxesExpanded, setTaxesExpanded] = useState(false);

  const [productSubTotal, setProductSubTotal] = useState(0);
  const toggleSubtotal = (e) => {
    e.preventDefault();
    setSubtotalExpanded(!isSubtotalExpanded); // Toggle Subtotal
    setTaxesExpanded(false); // Ensure Taxes is closed
  };

  const [isfilterOpen, setIsfilterOpen] = useState(false);

  // Data for the modal
  const gallonDetails = [
    { name: "Sunoco", value: 5 },
    { name: "Gulf", value: 7 },
    { name: "Citgo", value: 8 }
  ];
  const [selectedFuelBrand, setSelectedFuelBrand] = useState("");
  const [selectedBrandName, setSelectedBrandName] = useState("all");
  const [productOptions, setProductOptions] = useState([]);
  const [filteredStatus, setFilteredStatus] = useState("all");
  const [selectedInvoiceNumber, setSelectedInvoiceNumber] = useState("");
  const [clicked, setClicked] = useState(null);

  const [selectedInvoiceNumberforModel, setSelectedInvoiceNumberforModel] =
    useState("");

  const [value, setValue] = React.useState("draft");
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);

  const handleChange = (newValue) => {
    // if(newValue === "overdue") return;
    setValue(newValue);
    setFilteredStatus(newValue);
  };
  const [selectedDateFrom, setSelectedDateFrom] = useState(dayjs());
  const [error, setError] = useState(false);
  const handleDateChangeFrom = (from) => {
    if (!from || !dayjs(from).isValid()) {
      // If the date is invalid or null, clear the state
      setSelectedDateFrom(null);
      return;
    }
    setSelectedDateFrom(from);
    setError(false);
  };
  const handleIconClick = (ref) => {
    if (ref.current) {
      ref.current.setFocus();
    }
  };
  const [selectedDateTo, setSelectedDateTo] = useState(null);

  const handleDateChangeTo = (to) => {
    if (!to || !dayjs(to).isValid()) {
      // If the date is invalid or null, clear the state
      setSelectedDateTo(null);
      return;
    }
    console.log(to, "selectedDateTo.......");

    setSelectedDateTo(to);
  };
  console.log(selectedDateTo, "selectedDateTo");
  const datePickerFromRef = useRef(null);
  const datePickerToRef = useRef(null);

  // This function will be passed to the child to handle the selected fuel brand
  const handleFuelBrandChange = (fuelBrand) => {
    setSelectedFuelBrand(fuelBrand.value);
    setSelectedText(fuelBrand.text);
  };
  const [createdDate, setCreatedOnDate] = useState(new Date());
  const [uploadModal, setUploadModal] = useState(false);

  const [currentMonthYear, setCurrentMonthYear] = useState("");
  const [fileName, setFileName] = useState(null);
  const [file, setFile] = useState(null);
  const [rowSelection, setRowSelection] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [sorting, setSorting] = React.useState([]);
  const [filtering, setFiltering] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploadCount, setUploadCount] = useState(0);
  const fileInputRef = useRef(null);
  const [selectedImagePath, setSelectedImagePath] = useState("");
  const [imagesByDate, setImagesByDate] = useState([]);
  const [openDeleteModalId, setOpenDeleteModalId] = useState(null);
  const [columnFilters, setColumnFilters] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const managerId = localStorage.getItem("user_id");
  const userRole = localStorage.getItem("user_role");
  const dispatch = useDispatch();

  const allPurchases = useSelector((state) => state.purchase.allPurchases);
  console.log("all purchases", allPurchases);
  const handleChangeFuelBrands = (e) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    setSelectedBrandName(selectedOption.value);
    setSelectedText(selectedOption.text);
  };



  const expandedRowRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      const expandedRowElement = document.querySelector(
        '[data-expanded="true"]'
      );
      if (expandedRowElement && !expandedRowElement.contains(event.target)) {
        setExpandedRow(null); // Close the expanded row
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  const closeModal = () => {
    setIsModalOpen(false);
  };



  useEffect(() => {
    const currentDate = new Date();
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];
    const currentMonth = monthNames[currentDate.getMonth()];
    const currentYear = currentDate.getFullYear();
    setCurrentMonthYear(`${currentMonth} ${currentYear}`);
  }, []);

 
  const tableRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (tableRef.current && !tableRef.current.contains(event.target)) {
        setExpandedRow(null); // Close all expanded rows
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const formatDateForApi = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const capitalizeFirstLetterWordWords = (str) => {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };
  const lowercaseAllWords = (str) => {
    return str.toLowerCase();
  };

  const handleUploadImage = async (e) => {
    if (isClicked) return;
    setSelectedDateFrom(dayjs());

    console.log("upload image", file);
    // console.log("date date" , formatDateForApi(createdDate));
    e.preventDefault();
    if (!file) {
      alert("Please select a file first!");
      return;
    }
    const formData = new FormData();
    formData.append("receipt", file);
    formData.append("businessId", localStorage.getItem("business_id"));
    formData.append("brandId", selectedFuelBrand);

    formData.append("managerId", localStorage.getItem("user_id"));
    formData.append("userRole", localStorage.getItem("user_role"));
    formData.append("selectedDate", formatDateForApi(createdDate));
    formData.append("businessName", "Hp Gas");

    setLoading(true);
    setIsClicked(true);

    try {
      const response = await axiosInstance.post(
        `${baseURL}${btoa("purchase/create")}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
            // 'Authorization': `${token}`,
          }
        }
      );
      console.log(response, "response");
      if (response.data.success === true) {
        setFile(null);
        setFileName("");
        fileInputRef.current.value = "";
        // toast.success(`${response.data.message}` , {autoClose : 2500})
        dispatch(
          addToast({ type: "success", message: `${response.data.message}` })
        );

        // dispatch(addToast({ type: 'success', message: `${response.data.message}` }));
        setUploadCount((prevCount) => prevCount + 1);
        setCreatedOnDate(new Date());
        // setSelectedDateFrom(dayjs())
      }

    } catch (error) {
      if (error.response.data.success === false) {
        setFile(null);
        setFileName("");
        // toast.error(`${error.response.data.message}` , {autoClose : 2500})
        dispatch(
          addToast({
            type: "danger",
            message: `${error.response.data.message}`
          })
        );
      }
      console.log("upload image failed", error);
    } finally {
      setUploadModal(false);
      setLoading(false);
      setIsClicked(false);
    }
  };

  const data = useMemo(() => {
    return allPurchases.map((purchase, index) => ({
      ...purchase
   
    }));
  }, [allPurchases]);
  console.log(data, "dataforpurchase table");


  const handleView = (row) => {
    // console.log('View action for row:', row);
    // console.log('Constructed Image Path:', imagePath);
    setSelectedInvoiceNumberforModel(row);
    setSelectedImagePath(row);
    setIsModalOpen(true);

    // Add your view logic here
  };
  const [deletedRow, selectedDeleteRow] = useState("");
  const handleDelete = (row, row1) => {
    setOpenDeleteModalId(row);
    selectedDeleteRow(row1);
    // console.log('Delete action for row:', row);
  };

  const handleDownload = (fileName) => {
    const businessName = localStorage.getItem('business_name').replace(/\s+/g, '_')

    const downloadUrl = `${baseURL}uploads/docs/receipts/${businessName}/${fileName}.pdf`;
    window.open(downloadUrl, "_blank");
  };

  const isRowCheckboxDisabled = (row) => {
    if (rowSelection[row.id]) {
      return false;
    } else {
      // If the row is not selected, check if any other rows are selected
      return Object.values(rowSelection).some((selected) => selected);
    }
  };
  const formatNumberWithCommas = (number) => {
    return new Intl.NumberFormat("en-US").format(number);
  };
 

  const handleSortingChange = (columnId) => {
    setSorting((old) => {
      if (old.length && old[0].id === columnId) {
        // Toggle sorting direction
        return [
          {
            id: columnId,
            desc: !old[0].desc
          }
        ];
      }
      // Default sorting to descending initially
      return [{ id: columnId, desc: true }];
    });
  };
  // const [sellerNames , setSellerNames] = useState([])

  const [rightcardata, setrightcardata] = useState([]);

  const [selectedText, setSelectedText] = useState("");
  const [sunocoGallons, setSunocoGallons] = useState("");
  const [GulfGallons, setGulfGallons] = useState("");
  const [monthName, setMonthaname] = useState("");
  const [yearName, setYear] = useState("");

  const [isClicked, setIsClicked] = useState(false);
  const formattedDateTo = selectedDateTo ? selectedDateTo : "";
  const formattedDatefrom = selectedDateFrom ? selectedDateFrom : "";
  // const [year, month, day] = value.split('-');
  const formattedMonthName = () => {
    let [month] = formattedDatefrom.format("MMMM-YYYY").split("-");
    return month;
  };
  const formattedYear = () => {
    let [, year] = formattedDatefrom.format("MMMM-YYYY").split("-");
    return year;
  };

  const colors = [
    "red-500",
    "green-500",
    "yellow-500",
    "purple-500",
    "green-500",
    "[#25caed]"
  ];
  const sellerNames = [
    {
      brand_id: "5",
      seller_name: "sunoco",
      brand_logo: "sunoco.png",
      total_gallons: "0",
      total_amount: "0",
      card: "Expenses"
    },
    {
      brand_id: "2",
      seller_name: "gulf",
      brand_logo: "gulf.png",
      total_gallons: "0",
      total_amount: "0",
      card: "Expenses"
    }
  ];
  const cardsData =
    sellerNames.length > 0
      ? sellerNames.map((sellerName, index) => ({
        id: sellerName.brand_id,
        brandName: sellerName.seller_name, // Assuming brand_name is a property
        logo: sellerName.brand_logo,
        gallons: sellerName.total_gallons, // Adjust gallons data as needed
        borderColor: colors[index % colors.length],
        total_amount: sellerName.total_amount,
        card: sellerName.card
      }))
      : [];
  console.log(sellerNames, "sellerNames");

  const finalData = React.useMemo(() => {
    return allPurchases.length > 0 ? allPurchases : [];
  }, [allPurchases]);
  const [expandedRow, setExpandedRow] = useState(null);

  const toggleRow = (id) => {
    setExpandedRow((prevRow) => (prevRow === id ? null : id));
  };

 
  const table = useReactTable({
    data: expenses,
    columns,
    state: {
      sorting,
      globalFilter: filtering,
    },
    onSortingChange: setSorting,
    onGlobalFilterChange: setFiltering,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });
  const currentPage = table.options.state.pagination.pageIndex;

  return (
    <div className="pb-10 ">
      {loading && <FullScreenLoader />}

      <div
        className=" p-4 rounded-lg ml-4 mt-[8px]"
        style={{ display: "none" }}
      >
     
      </div>
      <div className="flex justify-start space-x-4 mt-[20px] ">
        {cardsData.map((card, index) => (
          <div
            key={card.id}
            className={`flex flex-col items-start justify-between w-1/6 bg-white rounded-lg p-4 relative 
      hover:scale-105 hover:border-l-[6px] hover:border-${card.borderColor
              } transition-all duration-300 
      ${clicked === `card${index + 1}`
                ? `border-l-[6px] border-${card.borderColor}`
                : ""
              }`}
            style={{
              boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.2)",
              height: "90px"
            }}
      
          >
            <div className="flex justify-start w-full">
              <span className="text-sm text-gray-500 font-bold">
                {card.card}
              </span>
            </div>
            <div className="flex items-center justify-center relative space-x-2">
              {" "}
              {/* Added flex layout here */}
              <h2 className={`text-2xl font-bold text-${card.borderColor}`}>
                {formatNumberWithCommas(card.gallons)}
              </h2>
              {/* Down Arrow Icon */}
              <FaArrowDown
                className={`text-${card.borderColor} text-xl mt-1`}
              />
            </div>
            <div className="absolute top-0 right-0 rounded-tl-full flex items-center justify-center">
              <img
                src={`${baseURL}/img/${card.logo}`}
                alt={card.brandName}
                className={`${card.id === "1" && "w-14 h-12"} 
        ${card.id === "2" && "w-12 h-14"} 
        ${card.id === "3" && "w-[80px] h-16 mt-[-8px]"} 
        ${card.id === "4" && "w-14 h-18 "} 
        ${card.id === "5" && "w-14 h-18 mt-2"} 
        ${card.id === "6" && "w-14 h-18"} 
        ${card.id === "7" && "w-14 h-18"} 
        ${card.id === "8" && "w-14 h-18"}
        ${card.id === "9" && "w-14 h-18"}
        object-contain mb-2 mr-3`}
              />
            </div>
          </div>
        ))}

      
      </div>
      <div className="relative flex justify-between  mt-[11rem]  gap-2 items-center w-full h-[35px]">
        {/* Fuel Brand Dropdown */}
        <div className="flex-grow" style={{ width: "250px" }}>
          {isfilterOpen && (
            <select
              id="customerId"
              className="border border-gray-400 text-sm font-medium rounded-lg block
         w-full h-[38px] px-2 text-gray-500 placeholder:text-gray-100"
              onChange={(e) => {
                handleChangeFuelBrands(e);
                setSelectedText(e.target.value !== "");
              }}
            >
              <option value="" className="text-gray-100">
                --Select expenses name--
              </option>
              <option value="all">All names</option>
              {sellerNames.map((seller) => (
                <option key={seller.id} value={seller.id}>
                  {seller.seller_name.charAt(0).toUpperCase() +
                    seller.seller_name.slice(1).toLowerCase()}
                </option>
              ))}
            </select>
          )}
        </div>
        {isfilterOpen && (
          <Space direction="vertical" style={{ height: "100%" }}>
            <DatePicker
              label="Select Month"
              id="purchase"
              picker="month"
              format="MMMM-YYYY"
              views={["year", "month"]}
              value={formattedDatefrom}
              disabledDate={disabledDate}

              onChange={handleDateChangeFrom}
              placeholder="Select Month"
              allowClear={false} // Prevents clearing manually
              inputReadOnly={true} // Prevents typing manually
              renderInput={(params) => (
                <TextField
                  {...params}
                  value={
                    formattedDatefrom
                      ? formattedDatefrom.format("MMMM-YYYY")
                      : ""
                  }
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      height: "32px",
                      display: "flex",
                      alignItems: "center",
                      fontSize: "1rem",
                      backgroundColor: formattedDatefrom
                        ? "#d7f3f8"
                        : "transparent",
                      border: `1px solid ${formattedDatefrom ? "#25caed" : "#ccc"
                        }`,
                      borderRadius: "6px",
                      "&:hover": {
                        borderColor: formattedDatefrom ? "#25caed" : "#aaa"
                      },
                      "&.Mui-focused": {
                        borderColor: "blue",
                        boxShadow: "0 0 8px rgba(0, 0, 255, 0.5)"
                      }
                    }
                  }}
                />
              )}
            />
          </Space>
        )}

        <div className="flex justify-end">
          <button
            className="text-white bg-[#25caed] flex items-center font-bold w-55 h-[35px] hover:scale-105 transition-transform duration-300 ease-in-out focus:ring-4 focus:ring-primary-300 rounded-lg text-base px-4"
            type="button"
            // onClick={() => setUploadModal(true)}
            // onClick={() => navigate('/Fileupload')}
            onClick={() =>
              navigate(
                "/ExpenseForm"

                // { state: { cardsData: cardsData } }
              )
            }
            style={{ whiteSpace: "nowrap" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={3}
              stroke="currentColor"
              className="w-5 h-5 mr-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 6v12m6-6H6"
              />
            </svg>
            New Expenses
          </button>
        </div>
      </div>

  
      {uploadModal && (
        <FileUploadModal
          onClose={() => setUploadModal(false)}
          // handleFileChange={handleFileChange}
          fileInputRef={fileInputRef}
          fileName={fileName}
          setFileName={setFileName}
          selectedFuelBrand={selectedFuelBrand}
          onFuelBrandChange={handleFuelBrandChange}
          handleUploadImage={handleUploadImage}
          errorMessage={errorMessage}
        />
      )}
      <div className="p-0">  
      </div>
     <div
           className="px-3 pt-3 mt-2 rounded-b-xl pb-5 bg-white rounded-tl-[12px] rounded-tr-[12px] shadow-[4px_4px_8px_rgba(0,0,0,0.1),-4px_4px_8px_rgba(0,0,0,0.1),4px_-4px_8px_rgba(0,0,0,0.1),-4px_-4px_8px_rgba(0,0,0,0.1)]
   "
         >
           <table ref={tableRef} className="w-full table-auto bg-transparent ">
             <thead className="bg-blue-900 text-white mx-3">
               {table.getHeaderGroups().map((headerGroup) => (
                 <tr key={headerGroup.id} className="text-center text-xs">
                   {headerGroup.headers.map((header, index) => {
                     const isFirstHeader = index === 0;
                     const isLastHeader = index === headerGroup.headers.length - 1;
                     const headerClasses = [
                       "p-3 font-bold uppercase",
                       isFirstHeader ? "rounded-l-xl" : "",
                       isLastHeader ? "rounded-r-xl" : ""
                     ];
   
                     const isSorted = header.column.getIsSorted();
                     const showSortingIcons =
                       header.column.columnDef.enableSorting !== false;
   
                     return (
                       <th
                         key={header.id}
                         className={headerClasses.join(" ")}
                         colSpan={header.colSpan}
                         onClick={() =>
                           showSortingIcons &&
                           handleSortingChange(header.column.id)
                         }
                       >
                         <div className="flex items-center justify-center gap-2">
                           {header.isPlaceholder
                             ? null
                             : flexRender(
                                 header.column.columnDef.header,
                                 header.getContext()
                               )}
                           {showSortingIcons && (
                          <div className="flex items-center">
                            <FaArrowUpLong
                              className={`${
                                isSorted === "asc"
                                  ? 'text-[#06b6d4]' : 'text-white'
                              }`}
                              size={12}
                            />
                            <FaArrowDownLong
                              className={`${
                                isSorted === "desc"
                                  ? 'text-[#06b6d4]' : 'text-white'
                              }`}
                              size={12}
                            />
                          </div>
                        )}
                         </div>
                       </th>
                     );
                   })}
                 </tr>
               ))}
             </thead>
             <tbody className="bg-white">
               {table.getRowModel().rows.length > 0 ? (
                 table.getRowModel().rows.map((row) => (
                   <>
                     <tr
                       key={row.id}
                       className="cursor-pointer text-center  hover:shadow-[0_4px_8px_rgba(0,0,0,0.1)] transition-shadow "
                       style={{ borderBottom: "0.3px solid #e0e0e0" }}
                      //  onClick={() => navigate(`/Expensesview/${row.id}`)}
                     >
                       {row.getVisibleCells().map((cell) => (
                         <td
                           key={cell.id}
                           className="p-3 font-semibold text-[#081159] text-[14px]"
                         >
                           {flexRender(
                             cell.column.columnDef.cell,
                             cell.getContext()
                           )}
                         </td>
                       ))}
                     </tr>
   
                     {expandedRow === row.id && (
                       <tr className="bg-gray-100 border-t-[2px] border-[#25caed]">
                         {/* First column for spacing/alignment */}
                         <td></td>
   
                         {/* Fuel grades aligned dynamically from 2nd column */}
                         {row.original.fuelgrades?.length > 0 ? (
                           row.original.fuelgrades.map((fuelgrade, fuelIndex) => (
                             <td
                               key={fuelIndex}
                               className="p-3 py-1 text-center font-[8px]"
                             >
                               <div
                                 style={{
                                   whiteSpace: "nowrap",
                                   overflow: "hidden",
                                   textOverflow: "ellipsis",
                                   fontSize: "14px"
                                 }}
                               >
                                 {fuelgrade.fuelgrade_name}
                               </div>
                               <div className="text-gray-600 mt-1 text-[14px]">
                                 {formatNumberWithCommas(
                                   isNaN(fuelgrade.purchased_gallons)
                                     ? 0
                                     : fuelgrade.purchased_gallons
                                 )}{" "}
                                 gl / $
                                 {Number(fuelgrade.unit_total).toLocaleString(
                                   undefined,
                                   {
                                     minimumFractionDigits: 2,
                                     maximumFractionDigits: 2
                                   }
                                 )}
                               </div>
                             </td>
                           ))
                         ) : (
                           <td
                             colSpan={
                               table.getHeaderGroups()[0].headers.length - 2
                             }
                             className="text-gray-500 text-center"
                           >
                             No fuel grades available.
                           </td>
                         )}
   
                         {/* Empty TDs to maintain structure up to the last column */}
                         {[
                           ...Array(
                             table.getHeaderGroups()[0].headers.length -
                               row.original.fuelgrades.length -
                               2
                           )
                         ].map((_, index) => (
                           <td key={index}></td>
                         ))}
   
                         {/* Last column: Icons (View, Download, Delete) */}
                         <td className="p-3 flex items-center justify-evenly">
                           <Tooltip
                             title="View"
                             position="top"
                             trigger="mouseenter"
                           >
                             <button
                               className="text-sm text-black p-1"
                               onClick={() => handleView(row.original.img_file)}
                             >
                               <AiFillEye size={20} />
                             </button>
                           </Tooltip>
                           <Tooltip
                             title="Download"
                             position="top"
                             trigger="mouseenter"
                           >
                             <button
                               className="text-sm text-blue-800 p-1"
                               onClick={() =>
                                 handleDownload(row.original.img_file)
                               }
                             >
                               <FiDownload size={20} />
                             </button>
                           </Tooltip>
                          
                           <Tooltip
                             title={
                              "Delete"
                             }
                             position="top"
                             trigger="mouseenter"
                           >
                             <button
                               className={`text-sm  p-1`}
                               onClick={(e) => {
                                handleDelete(row.original.id, row);
                               }}
                             >
                               <svg
                                 xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 24 24"
                                 fill="currentColor"
                                 className="w-5 h-5"
                               >
                                 <path
                                   fillRule="evenodd"
                                   d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z"
                                   clipRule="evenodd"
                                 />
                               </svg>
                             </button>
                           </Tooltip>
                         </td>
                       </tr>
                     )}
                   </>
                 ))
               ) : (
                 // Show empty state if no rows
                 <tr>
                   <td colSpan={columns.length} className="text-center py-12">
                     <div className="text-gray-500 text-lg">No data available</div>
                   </td>
                 </tr>
               )}
             </tbody>
           </table>
         </div>

      <PurchaseImagesViewModel
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        selectedImagePath={selectedImagePath}
        selectedInvoiceNumberforModel={selectedInvoiceNumberforModel}
      />
      {openDeleteModalId && (
        <PopUpModalForDeletePurchases
          onClose={() => setOpenDeleteModalId(null)}
          id={openDeleteModalId}
          imageName={"Fuel Purchased Image"}
          resetRowSelection={table.resetRowSelection}
          brandInvoiceNumber={deletedRow?.original?.brand_invoice_number}
        />
      )}
    </div>
  );
};

export default Operations;

import SalesTax from "../pages/SalesTax";

const routes = {
  HOME: "/",
  OPERATIONS: '/Operations',
  INVOICE: "/invoice",  // also called Sales in sidebar
  PAYMENTS:'/payments',  // payments in side bar
  SETUP:'/setup',
  PURCHASES : '/purchases',  // purchases in side bar
  EXPENSES : '/expenses' ,   // expenses in side bar
  LOSSES:'/Losses',
  PAYINS: '/payIns',
  PAYOUTS:'/payouts',
  EXPENSES:'expenses',
  DEPOSITS : '/deposits',   // deposits in side bar
  DOCUMENTS : '/documents',   // documents in side bar
  PURCHASES_DETAILS : '/purchases/:id',
  CUSTOMERS: "/customers",
  PRODUCTS: "/products",
  ITEMS:'/items',
  ITEMS_DASHBOARD : '/itemsdashboard', 
  VENDORS : '/vendors',    // vendors in setting icon
  INVENTORY: "/inventory",
  REPORTS:'/reports',
  CUSTOMERREPORTS:'/customerreport',
  SALESTAX: '/SalesTax',
  CORPORATIONTAX:'/CorporationTax',
  SALESREPORT:'/SalesReport',
   MARGINS:'Margins',
   PROFITANDLOSS:'ProfitandLoss',
   PRICELOG:'PriceLog',
  INVOICEREPORT: 'invoiceReport',
  ACCOUNTING:'/Accounting',
  KPI:'/kpi',
  INVOICE_FORM: "/invoiceform",
  CUSTOMER_FORM: "/customerform",
  VIEW_CUSTOMER:'/viewcustomer',
  ANALYTICS:"/analytics",
  BUSINESS:"/business", 
  BUSINESS_DASHBOARD : "/businessdashboard",
  VIEW_BUSINESS : "/viewbusiness",
  // LOGIN_PAGE : "/loginpage",
  ADMIN_LOGIN : "/adminlogin",
  CUSTOMERPORTALPAGE : "/customerportal",
  // ALLSTATEMENTSREPORTS : "/allStatementReports",

  ALLSTATEMENTSREPORTS: "/allstatementsreports",
  ALLINVOICESREPORTS : "/allInvoiceReports",
  USER_LOGIN_PAGE : "/userloginpage",
  FORGOT_PASSWORD : "/forgotpassword",
  SIGNUP_PAGE : "/signup",
  OTP_PAGE : "/otp",
  VIEW_BUSINESS_USERS : "/ViewBusinessUsers",
  CREATE_OWNER : "/createowner",
  CREATE_OWNER_MAIN : "/createownermain",
  CREATE_BUSINESS : "/createbusiness",
  USERS_DASHBOARD : "/usersdashboard",
  ADMIN_BUSINESS_DASHBOARD : "/adminbusinessdashboard",
  ITEM_PRICE : "/itemprice",
  INVOICE_FORM_TWO : "/invoiceformtwo",
  VIEW_INVOICE_FORM : "/viewinvoiceform",
  VIEW_INVOICE_FORM_TWO : "/viewinvoiceformtwo",
  CREATE_VENDOR : '/createvendor',
  VIEW_VENDOR : '/viewvendor',
  VENDOR_VIEW: '/VendorView',
  NOTIFICATIONS : '/notifications',
  EDIT_NOTIFICATIONS: '/editnotifications/:id',
  EDIT_ADMIN_BUSINESS : "/editadminbusiness/:id",
  EDIT_ADMIN_OWNER : "/editadminowner/:id",
  OPERATIONS:'/Operations',
  MESSAGES:'/Messages',
  MYDOCS: '/MyDocs',
  SALESHOME: '/Sales',
  SALESREPORT:'SalesReport',
  AUDITLOG:'AuditLog',
  REPORTSADMIN:'/reportsAdmin',
  ACCOUNTINGADMIN:'/accountingAdmin',
  ANALYTICSADMIN:'/analyticsAdmin',
  DRAFTAGREEMENTS:'/draftAgreemnets',
  APPROVEDCONTRACTS:'/approvedContracts',
  LICENSES:'/Licenses',
  TANKREADING:'/TankReading',
  TERMSANDCONDITIONS:'Termsandconditions',
  PURCHASEREPORT:'PurchaseReport',
  PURCHASEBYUNBRANDREPORT:'/purchaseUnBrandedReport',
  PURCHASEBYFUELGRADEREPORT:'/purchaseByFuelGradeReport',
  PURCHASEFROMTERMINALREPORT:'/purchaseFromTerminalReport',
  PRICELOG:'PriceLog',
  BILLING:'Billing',
  SMS:'/Sms',
  EMAIL:'/Email',
  NOTIFICATIONS:'/Notifications',
  DRAFTAGREEMENTS:'/DraftAgreements',
  APPROVEDCONTRACTS:'/ApprovedContracts',
  LICENSES:'/Licenses',
  KPIS:'/KPIs',
  TRENDING:'/Trending',
  INVOICEVIEW:'/invoiceview',
  SALESTAXCUSTOMERS:'/salesTaxCustomers',
  SALESTAXVIEWEDIT:'/salesTaxViewEdit',
  CUSTOMERTAX:'/customerTax',
  PRICING:'/Pricing',
  NEWPRICE:'/NewPrice',
  EDITINVOICE:'/EditInvoice',
  USERS:'/Users',
  USERSVIEWPAGE:'/Usersviewpage',
  OWNERNOTIFICATIONS:'/OwnerNotification',
  NEWNOTIFICATION:'/NewNotification',
  NEWDOCUMENT:'/NewDocument',
  USERDASHBOARD:'/UserDashBoard',
  PAYINVOICE:'/PayInvoice',
  FILEUPLOAD:'/Fileupload',
  HOMEREPORTS:'/HomeReports',
  PRICINGVIEW:'/PricingView',
  PRICINGTABLEPAGE:'/PricingTablePage',
  PAYMENTPAGE:'/PaymentPage',
  HOMESETUP:'HomeSetup',
  VENDORSCREEN:'/VendorScreen',
  CUSTOMERSCREEN:'/CustomerScreen',
  OPERATIONSSCREEN:'OperationsScreen',
  EXPENSEFORM:'/ExpenseForm',
  HOMENOTIFICATIONS:'/HomeNotifications',
  STATEMENTS:'/statements',
  NEW_STATEMENT_FORM : "/newstatementform",
  STATEMENT_VIEW_FILE : "/statementviewfile",
SEARCHPOREPORT:'/searchPoReport',
MYPOREPORT:'/myPoReport',
POBYBRANDREPORT:'/poByBrandReport',
POBYUNBRANDREPORT:'/poByUnBrandReport',
POBYSTATEREPORT:'/poByStateReport',
ALLSTATEMENTS:'/allStatements',
STATEMENTBYCUSTOMER:'/statementByCustomer',
DELIVERYPRICE:'/deliveryPrice',
DELIVERYPRICEDASHBOARD:'/deliveryPriceDashBoard',
POCHARTS:'/PoCharts',
EXPENSESVIEW:'/Expensesview'
};

export { routes };

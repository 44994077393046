import React, { useState } from "react";
import { 
  LineChart, Line, BarChart, Bar, PieChart, Pie, 
  XAxis, YAxis, CartesianGrid, Tooltip, Legend, 
  ResponsiveContainer, Cell, AreaChart, Area
} from "recharts";

// Icons for chart type selection buttons
const TableIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path d="M3 3h18v18H3zM3 9h18M3 15h18M9 3v18M15 3v18"/>
  </svg>
);

const LineChartIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path d="M3 3v18h18"/>
    <path d="M3 12h18"/>
    <path d="M7 8l5 5 5-5"/>
    <path d="M7 16l5-5 5 5"/>
  </svg>
);

const BarChartIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path d="M6 16V4M12 16V8M18 16V12"/>
  </svg>
);

const PieChartIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path d="M12 12v-8a8 8 0 0 1 8 8h-8z"/>
    <path d="M12 12h8a8 8 0 0 1-8 8v-8z"/>
    <path d="M12 12h-8a8 8 0 0 0 8 8v-8z"/>
    <path d="M12 12v-8a8 8 0 0 0-8 8h8z"/>
  </svg>
);

const AreaChartIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path d="M3 3v18h18"/>
    <path d="M3 18L7 12 12 9 17 15 21 6"/>
    <path d="M3 18L7 12 12 9 17 15 21 6 21 18 3 18z" fill="currentColor" fillOpacity="0.2"/>
  </svg>
);

const POVisualization = ({realData}) => {
  const [chartType, setChartType] = useState('line');
  const [dataType, setDataType] = useState('amount'); // 'amount' or 'gallons'
  
  const processDataByDate = () => {
    const groupedByDate = {};
    
    realData.PO_data.forEach(po => {
      const date = po.brand_invoice_date;
      if (!groupedByDate[date]) {
        groupedByDate[date] = {
          date,
          totalAmount: 0,
          totalGallons: 0,
          count: 0
        };
      }
      
      groupedByDate[date].totalAmount += parseFloat(po.total_amount || 0);
      groupedByDate[date].totalGallons += parseFloat(po.total_gallons || 0);
      groupedByDate[date].count += 1;
    });
    
    // Convert to array and sort by date
    return Object.values(groupedByDate).sort((a, b) => 
      new Date(a.date) - new Date(b.date)
    );
  };
  
  const processDataByBrand = () => {
    const groupedByBrand = {};
    
    realData.PO_data.forEach(po => {
      const brand = po.seller_name || 'Unknown';
      if (!groupedByBrand[brand]) {
        groupedByBrand[brand] = {
          brand,
          totalAmount: 0,
          totalGallons: 0,
          count: 0
        };
      }
      
      groupedByBrand[brand].totalAmount += parseFloat(po.total_amount || 0);
      groupedByBrand[brand].totalGallons += parseFloat(po.total_gallons || 0);
      groupedByBrand[brand].count += 1;
    });
    
    return Object.values(groupedByBrand).sort((a, b) => 
      b.totalAmount - a.totalAmount
    );
  };

  const dateData = processDataByDate();
  const brandData = processDataByBrand();
  
  const formatCurrency = (value) => {
    return `$${Number(value).toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })}`;
  };
  
  const formatDate = (dateString) => {
    if (!dateString) return "";
    const parts = dateString.split("-");
    if (parts.length !== 3) return dateString;
    return `${parts[1]}/${parts[2]}/${parts[0]}`;
  };
  
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8', '#E57373', '#BA68C8', '#4DB6AC'];
  
  const toggleDataType = () => {
    setDataType(dataType === 'amount' ? 'gallons' : 'amount');
  };
  
  const renderChart = () => {
    switch (chartType) {
      case 'line':
        return (
          <div className="flex justify-center w-full">
            <ResponsiveContainer width="80%" height={400}>
              <LineChart
                data={dateData}
                margin={{ top: 20, right: 30, left: 20, bottom: 50 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis 
                  dataKey="date" 
                  tickFormatter={formatDate}
                  angle={-45}
                  textAnchor="end"
                  height={70}
                />
                <YAxis 
                 width={100}
                  tickFormatter={value => dataType === 'amount' ? formatCurrency(value) : `${value} gl`}
                />
                <Tooltip 
                  formatter={(value) => dataType === 'amount' ? formatCurrency(value) : `${value} gallons`}
                  labelFormatter={formatDate}
                />
                <Legend />
                <Line 
                  type="monotone" 
                  dataKey={dataType === 'amount' ? 'totalAmount' : 'totalGallons'} 
                  name={dataType === 'amount' ? 'Total Amount' : 'Total Gallons'} 
                  stroke={themeColor} 
                  activeDot={{ r: 8 }} 
                  strokeWidth={2}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        );
      
      case 'bar':
        return (
            <div className="flex justify-center w-full">
            <ResponsiveContainer width="80%" height={400}>
              <BarChart
                data={brandData}
                margin={{ top: 20, right: 30, left: 50, bottom: 50 }} // Increased left margin
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis 
                  dataKey="brand" 
                  angle={-45}
                  textAnchor="end"
                  height={70}
                />
                <YAxis 
                  width={100} // Increased width to prevent cutting
                  tickFormatter={value => dataType === 'amount' 
                    ? `$${Number(value).toLocaleString()}` 
                    : `${Number(value).toLocaleString()} gl`
                  } 
                />
                <Tooltip 
                //   content={<CustomTooltip />}
                  cursor={{ fill: 'rgba(0, 0, 0, 0)' }} // Light subtle cursor highlight
                />
                <Legend />
                <Bar 
                  dataKey={dataType === 'amount' ? 'totalAmount' : 'totalGallons'} 
                  name={dataType === 'amount' ? 'Total Amount' : 'Total Gallons'} 
                  fill={themeColor}
                  barSize={40}
                //   isAnimationActive={false}
                >
                  {brandData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        );
      
      case 'pie':
        return (
          <div className="flex justify-center w-full">
            <ResponsiveContainer width="80%" height={400}>
              <PieChart>
                <Pie
                  data={brandData}
                  cx="50%"
                  cy="50%"
                  labelLine={true}
                  outerRadius={150}
                  fill="#8884d8"
                  dataKey={dataType === 'amount' ? 'totalAmount' : 'totalGallons'}
                  nameKey="brand"
                  label={({ brand, percent }) => `${brand}: ${(percent * 100).toFixed(0)}%`}
                //   isAnimationActive={false}
                >
                  {brandData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip 
                  formatter={(value) => dataType === 'amount' ? formatCurrency(value) : `${value} gallons`}
                />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </div>
        );
        
      case 'area':
        return (
          <div className="flex justify-center w-full">
            <ResponsiveContainer width="80%" height={400}>
              <AreaChart
                data={dateData}
                margin={{ top: 20, right: 30, left: 20, bottom: 50 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis 
                  dataKey="date" 
                  tickFormatter={formatDate}
                  angle={-45}
                  textAnchor="end"
                  height={70}
                />
                <YAxis 
                 width={100}
                  tickFormatter={value => dataType === 'amount' ? formatCurrency(value) : `${value} gl`}
                />
                <Tooltip 
                  formatter={(value) => dataType === 'amount' ? formatCurrency(value) : `${value} gallons`}
                  labelFormatter={formatDate}
                />
                <Legend />
                <Area 
                  type="monotone" 
                  dataKey={dataType === 'amount' ? 'totalAmount' : 'totalGallons'} 
                  name={dataType === 'amount' ? 'Total Amount' : 'Total Gallons'} 
                  stroke={themeColor}
                  fill="rgba(25, 118, 210, 0.3) " 
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        );
      
      default:
        return null;
    }
  };
  
  // The themeColor should match your application's theme
  const themeColor = "rgb(25, 118, 210)"
  
  return (
    <div className="mb-8 w-full">
      <div className="flex justify-between items-center mb-4 my-[40px]">
        <h3 className="text-xl font-semibold" style={{ color: themeColor }}>
          {/* Purchase Orders Data Visualization */}
        </h3>
        
        {/* Chart Type Selector */}
        <div className="flex items-center space-x-2">
          <button 
            onClick={() => setChartType('line')} 
            className="p-2 rounded hover:bg-gray-100 focus:outline-none"
            title="Line Chart"
            style={{ color: chartType === 'line' ? themeColor : '#444' }}
          >
            <LineChartIcon />
          </button>
          
          <button 
            onClick={() => setChartType('bar')} 
            className="p-2 rounded hover:bg-gray-100 focus:outline-none"
            title="Bar Chart"
            style={{ color: chartType === 'bar' ? themeColor : '#444' }}
          >
            <BarChartIcon />
          </button>
          
          <button 
            onClick={() => setChartType('pie')} 
            className="p-2 rounded hover:bg-gray-100 focus:outline-none"
            title="Pie Chart"
            style={{ color: chartType === 'pie' ? themeColor : '#444' }}
          >
            <PieChartIcon />
          </button>
          
          <button 
            onClick={() => setChartType('area')} 
            className="p-2 rounded hover:bg-gray-100 focus:outline-none"
            title="Area Chart"
            style={{ color: chartType === 'area' ? themeColor : '#444' }}
          >
            <AreaChartIcon />
          </button>
          
          <button 
            onClick={toggleDataType}
            className="ml-4 px-3 py-1 rounded text-sm font-medium"
            style={{ 
              backgroundColor: themeColor,
              color: 'white',
            }}
          >
            Show {dataType === 'amount' ? 'Gallons' : 'Amount'}
          </button>
        </div>
      </div>
      
      <div className="flex justify-center w-full">
        <div style={{ 
          width: '100%',
          padding: '16px', 
          backgroundColor: 'white', 
          borderRadius: '8px', 
          boxShadow: '0 0 10px rgba(0,0,0,0.1)' 
        }}>
          {renderChart()}
        </div>
      </div>
      
      {chartType === 'table' && (
        <div className="overflow-x-auto mt-4">
          <table className="min-w-full divide-y divide-gray-200" style={{ border: '1px solid #eee' }}>
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Date
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  PO Number
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Brand
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Amount
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Gallons
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {realData.PO_data.map((po) => (
                <tr key={po.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {formatDate(po.brand_invoice_date)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-blue-600">
                    {po.brand_invoice_number}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 capitalize">
                    {po.seller_name}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                    {formatCurrency(po.total_amount)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                    {Number(po.total_gallons).toLocaleString()} gl
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default POVisualization;
const menuList = {
  HOME: "Home",
  SALES:'Sales',
  PURCHASES : 'Purchases',
  EXPENSES : 'Expenses',
  DEPOSITS : 'Deposits',
  DOCUMENTS : 'Documents',
  BILLING: "Billing",
  PAYMENTS:'Payments',
  SETUP:'Setup',
  // CUSTOMERS: "Customers",
  CUSTOMERS: "Business",
  PRODUCTS: "Products",
  ITEMS:'Items',
  INVENTORY: "Inventory",
  REPORTS:'Reports',
  ACCOUNTING:'Accounting', 
  KPI:'KPI',
  INVOICE_FORM: "Invoiceform",
  CUSTOMER_FORM: "Customerform",
  VIEW_CUSTOMER:'Viewcustomer',
  ANALYTICS:'Analytics',
  NOTIFICATIONS : 'Notifications',
  OPERATIONS: 'Operations',
  MESSAGES:'Messages',
  MYDOCS: 'MyDocs',
  TANKREADING:'TankReadings',
  LOSSES:'Losses',
  INVOICE:'Invoices',
  PAYMENTS:'Payments',
  PAYINS:'PayIns',
  PAYOUTS:'PayOuts',
  DEPOSITS:'Deposits',
  SALESREPORT:'Sales',
  INVOICEREPORT:'Invoice',
  PRICELOG:'PriceLog',
  SALESTAX:'SalesTax',
  CORPORATIONTAX:'Corporation Tax',
  MARGINS:'Margins',
  PROFITANDLOSS:'Profit & Loss',
  PRICELOG:'PriceLog',
  SMS:'Sms',
  EMAIL:'Email',
  NOTIFICATIONS:'Notifications',
  DRAFTAGREEMENTS:' Agreements',
  APPROVEDCONTRACTS:'Contracts',
  LICENSES:'Licenses',
  KPIS:'KPIs',
  TRENDING:'Trending',
  SALESTAXCUSTOMERS:'salesTaxCustomers',
   PRICING:'Pricing',
   STATEMENTS:'Statements'


}

export { menuList };



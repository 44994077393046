
import React, { useState } from "react";
import { BiSolidUser } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import { addToast } from "./ToastBox/ToastBoxMessageSlice";
import { useDispatch } from "react-redux";
import config from "../../config/config";
import ToastNotificationBox from "../../../src/components/pages/ToastBox/ToastNotificationBox";
import CustomCommonTab from "../reusable/CustomCommonTab";
// import CustomNavigation from "../reusable/CustomNavigationIcon";
import { FaHome } from "react-icons/fa";
import CustomNavigation from "../reusable/CustomNavigation";
// import CustomNavigation from "../reusable/CustomNavigationIcon";

const UserForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const baseURL =
    process.env.NODE_ENV === "production"
      ? config.production.baseURL
      : config.development.baseURL;
  const phoneRegExp = /^\(\d{3}\) \d{3}-\d{4}$/;
  const emailRegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const [invalidPhoneNumber, setInvalidPhoneNumber] = useState("");

  const formatPhoneNumber = (value) => {
    const cleaned = ("" + value).replace(/\D/g, "").slice(0, 10);
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    return match ? `(${match[1]}) ${match[2]}-${match[3]}` : cleaned;
  };

  // const handlePhoneNumberChange = (e) => {
  //   const phoneNumber = e.target.value.replace(/[-()]/g, "").slice(0, 10);
  //   const formattedValue = formatPhoneNumber(phoneNumber);

  //   if (formattedValue === "") {
  //     setInvalidPhoneNumber(""); // Clear validation when empty
  //   } else if (!/[1-9]/.test(phoneNumber)) {
  //     setInvalidPhoneNumber("Invalid number"); // No non-zero digits
  //   } else if (formattedValue.length < 14) {
  //     setInvalidPhoneNumber("Phone number must be at least 10 characters");
  //   } else {
  //     setInvalidPhoneNumber(""); // Valid number
  //   }

  //   formik.setFieldValue("mobileNumber", formattedValue);
  // };

  const handlePhoneNumberChange = (e) => {
    let phoneNumber = e.target.value.replace(/\D/g, "").slice(0, 10); // Remove all non-digits
  
    const formattedValue = formatPhoneNumber(phoneNumber);
  
    if (formattedValue === "") {
      setInvalidPhoneNumber(""); // Clear validation when empty
    } else if (!/[1-9]/.test(phoneNumber)) {
      setInvalidPhoneNumber("Invalid number"); // No non-zero digits
    } else if (phoneNumber.length < 10) {
      setInvalidPhoneNumber("Phone number must be 10 digits");
    } else {
      setInvalidPhoneNumber(""); // Valid number
    }
  
    formik.setFieldValue("mobileNumber", formattedValue);
  };
  

  const formik = useFormik({
    initialValues: {
      userName: "",
      email: "",
      mobileNumber: "",
    },
    // validationSchema: Yup.object({
    //   userName: Yup.string()
    //     .required("User Name is required")
    //     .min(3, "Username must be at least 3 characters")
    //     .max(50, "Username cannot exceed 50 characters")
    //     .test('no-empty-spaces', 'Username cannot contain only spaces', value => 
    //       value && value.trim().length > 0
    //     )
    //     .test('no-consecutive-spaces', 'Username cannot contain consecutive spaces', value => 
    //       !value || !/\s\s/.test(value)
    //     )
    //     .test('no-leading-trailing-spaces', 'Username cannot have leading or trailing spaces', value => 
    //       !value || (value.trim() === value)
    //     ),
    //   // Rest of your validation remains the same
    //   email: Yup.string()
    //     .matches(emailRegExp, "Invalid email address")
    //     .required("Email is required"),
    //   mobileNumber: Yup.string()
    //     .required("Contact Number is required"),
    // })
    validationSchema: Yup.object({
      userName: Yup.string()
        .required("User Name is required")
        .min(3, "Username must be at least 3 characters")
        .max(50, "Username cannot exceed 50 characters")
        .test('no-empty-spaces', 'Username cannot contain only spaces', value => 
          value && value.trim().length > 0
        )
        .test('no-leading-spaces', 'Username cannot begin with spaces', value => 
          !value || !value.startsWith(' ')
        )
        // .test('no-consecutive-spaces', 'Username cannot contain consecutive spaces', value => 
        //   !value || !/\s\s/.test(value)
        // )
        ,
      email: Yup.string()
        .matches(emailRegExp, "Invalid email address")
        .required("Email is required"),
      mobileNumber: Yup.string()
        .required("Contact Number is required"),
    }),
    onSubmit: (values) => {
      axios
        .post(`${baseURL}/${btoa("login/managersignup")}`, {
          businessId: localStorage.getItem("business_id"),
          userName: values.userName,
          userEmail: values.email,
          contactNumber: values.mobileNumber,
        })
        .then((response) => {
          if (response.data.dataSavingStatus && response.data.otpSentStatus) {
            dispatch(
              addToast({
                type: "success",
                message: `${values.userName} has been registered successfully`,
              })
            );
          }
          navigate("/UserDashBoard");
        })
        .catch((error) => {
          if (error.response?.data?.userStatus === "Email already exist") {
            dispatch(
              addToast({
                type: "danger",
                message: "Contact Email already exist",
              })
            );
          }
          if (
            error.response?.data?.userStatus === "Contact number already exist"
          ) {
            dispatch(
              addToast({
                type: "danger",
                message: "Contact number already exist",
              })
            );
          }
        });
    },
  });

  const [activeItem, setActiveItem] = useState("View");

  const handleMenuClick = (item) => {
    setActiveItem(item);
  };
  const menuItems = [{ name: "Create User" }];
  return (
    <form className="space-y-3" onSubmit={formik.handleSubmit}>
      <>
        {/* <div className="flex mb-6 justify-center p-4 rounded-md font-bold text-md items-center w-full mx-auto py-2 mt-3 "> */}
        <CustomCommonTab items={menuItems} />
          {/* <div
            className={`flex items-center cursor-pointer px-4 py-1 ${
              activeItem === "View"
                ? "border-b-2 border-green-500 text-green-600"
                : "text-gray-600"
            } border-b border-gray-300  `}
            onClick={() => handleMenuClick("View")}
          >
            <FaEye className="mr-2" />
            <span>View</span>
          </div> */}

          {/* <div
        className={`flex items-center cursor-pointer px-4 py-1 ${
          activeItem === "Download" ? "border-b-2 border-green-500 text-green-600" : "text-gray-600"
        } border-b border-gray-300 `}
        onClick={() =>{handleMenuClick("Download");}}
      >
        <span>Edit</span>
      </div>
      {
      <div
        className={`flex items-center cursor-pointer px-4 py-1 ${
          activeItem === "Edit" ? "border-b-2 border-green-500 text-green-600" : "text-gray-600"
        } border-b border-gray-300 `}
      onClick={() => {handleMenuClick("Edit");}}

      >
        <span>Delete</span>
      </div> */}
          {/* <div
            className={`flex items-center cursor-pointer px-4 py-1 ${
              activeItem === "Download"
                ? "border-b-2 border-green-500 text-green-600"
                : "text-gray-600"
            } border-b border-gray-300`}
            onClick={() => {
              //   handleMenuClick("Download");
              navigate("/UserDashBoard");
            }}
          >
            <span>Cancel</span>
          </div> */}
        {/* </div> */}
        <div className="flex flex-col items-center justify-center mt-5">
          <ToastNotificationBox className="z-58880" />

          <div
            className="relative flex justify-between bg-white rounded-lg p-8 w-[880px] mx-auto"
            style={{ boxShadow: "0 2px 10px rgba(0, 0, 0, 0.3)" }}
          >  <div className="absolute top-[-10px] right-[-12px]"> 
          {/* <CustomNavigation /> */}
          <div className="flex items-center justify-end ">
          {/* <div
            className={`absolute -top-4 flex items-center cursor-pointer rounded-full bg-[#25caed] w-10 h-10  px-2 py-2 mb-1 mr-4 ${
              activeItem === "Home"
                ? "border-b-2 border-green-500 text-green-600"
                : "text-gray-600"
            }  border-gray-300 mb-1 `}
            onClick={() => {
              handleMenuClick("Home");
              navigate("/");
            }}
            style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
          >
            {" "}
            <FaHome className="text-white ml-[2px] mb-1 mt-[2px]" size={20} />
          </div> */}
          {/* <CustomNavigation/> */}
          {/* <CustomNavigation onCloseClick={() =>  navigate("/UserDashBoard")}  /> */}
           <div className="flex items-center justify-end">
               {/* Home button - bordered/unfilled by default, filled blue on hover */}
               <div
                 className="absolute -top-3 flex items-center justify-center cursor-pointer rounded-full bg-[#cefafe] hover:bg-[#25caed] w-9 h-9 mb-0 mr-9 border-[0.5px] border-[#25caed] hover:border-[#25caed] hover:scale-110 shadow-md transition-all duration-200 group"
                 onClick={() => {
                   navigate("/");
                 }}
                 style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
               >
                 <FaHome className="text-[#25caed] group-hover:text-white transition-colors duration-200" size={16}  />
               </div>
         
               {/* Close Icon - bordered/unfilled by default, filled red on hover */}
               <div
                 className="absolute -top-3 -right-2 bg-red-200 hover:bg-red-500 w-9 h-9 flex items-center justify-center rounded-full cursor-pointer hover:bg-red-700 border-[0.5px] border-red-500 hover:border-red-700 hover:scale-110 shadow-md transition-all duration-200 group"
                 onClick={() => navigate('/UserDashBoard')}
               >
                 <span className="text-red-500 group-hover:text-white text-xl font-bold mb-1.5 transition-colors duration-200">&times;</span>
               </div>
             </div>
          {/* <div
            className="absolute -top-4 -right-2 bg-red-500 w-10 h-10 flex items-center justify-center rounded-full cursor-pointer hover:bg-red-700"
            onClick={() => navigate(-1)}
          >
            <span className="text-white text-2xl font-bold mb-1">&times;</span>
          </div> */}
        </div>

          </div>
           
            <div className="flex flex md:flex gap-8">
              <div className="flex flex-col items-center justify-center space-y-4 w-[300px] h-[300px] ">
                <div className="w-16 h-16 bg-emerald-500 rounded-full flex items-center justify-center">
                  <BiSolidUser
                    className="text-green-500 rounded-full p-2"
                    size={80}
                  />
                </div>
                <h2 className="text-lg font-medium text-gray-700">
                  New User Info
                </h2>
              </div>
              <div className="space-y-4">
                <div className="space-y-1">
                  <label className="block text-sm font-medium text-gray-700">
                    User Name <span className="text-red-500">*</span>
                  </label>
                  {/* <input
                    type="text"
                    name="userName"
                    placeholder="Enter User Name"
                    className="w-full p-1 border border-gray-300 rounded-md"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.userName}
                  /> */}
                  <input
  type="text"
  name="userName"
  placeholder="Enter User Name"
  className="w-full p-1 border border-gray-300 rounded-md"
  onChange={(e) => {
    const inputValue = e.target.value.replace(/^[^a-zA-Z0-9]|(?<=[a-zA-Z0-9])[^a-zA-Z0-9\s]/g, '');
    formik.setFieldValue("userName", inputValue);
  }}
  onBlur={formik.handleBlur}
  value={formik.values.userName}
/>

                  {formik.touched.userName && formik.errors.userName ? (
                    <p className="text-red-500 text-sm">
                      {formik.errors.userName}
                    </p>
                  ) : null}
                </div>
                <div className="space-y-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Contact Email <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="email"
                    name="email"
                    placeholder="Enter Contact Email"
                    className="w-full p-1 border border-gray-300 rounded-md"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <p className="text-red-500 text-sm">
                      {formik.errors.email}
                    </p>
                  ) : null}
                </div>
                <div className="space-y-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Contact Number <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="mobileNumber"
                    placeholder="(XXX) XXX-XXXX"
                    className="w-full p-1 border border-gray-300 rounded-md"
                    onChange={handlePhoneNumberChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.mobileNumber}
                    maxLength={14}
                  />
                  {formik.touched.mobileNumber && formik.errors.mobileNumber ? (
                    <p className="text-red-500 text-sm">
                      {formik.errors.mobileNumber}
                    </p>
                  ) : null}
                  {invalidPhoneNumber && invalidPhoneNumber !== "valid" ? (
                    <p className="text-red-500 text-sm">{invalidPhoneNumber}</p>
                  ) : null}
                </div>
                <div className="flex justify-end gap-4 mt-10">
                  <button
                    type="submit"
                    disabled={
                      !formik.isValid ||
                      !formik.dirty ||
                      invalidPhoneNumber !== ""
                    }
                    className={`w-40 px-12 
                                py-1 border-2 border-blue-500 text-blue-500 hover:font-bold rounded-md
                                 hover:bg-blue-700 hover:text-white uppercase ${
                                   !formik.isValid ||
                                   !formik.dirty ||
                                   invalidPhoneNumber !== ""
                                     ? "bg-gray-100 text-gray-500 hover:bg-gray-100 hover:text-gray-500 focus:none focus:outline-none font-bold hover:none"
                                     : ""
                                 }`}
                  >
                    SUBMIT
                  </button>
                  <button
                    onClick={() => navigate("/UserDashBoard")}
                    className="w-40 px-12 py-1 border-2 border-red-500 text-red-500
                                 hover:font-bold  hover:text-white rounded-md hover:bg-red-700 uppercase"
                  >
                    CANCEL
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </form>
  );
};

export default UserForm;

import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { MdOutlineBusinessCenter } from "react-icons/md";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import config from "../../config/config";
import CreateOwner from "./CreateOwner";
import { LocalGasStation } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { addToast } from "../pages/ToastBox/ToastBoxMessageSlice";
const CreateBusiness = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [emailError, setEmailError] = useState("");

  const [contactNumberError, setContactNumberError] = useState("");

  const [businessNameExist, setBusinessNameExist] = useState("");

  const [domainNameExist, setDomainNameExist] = useState("");

  const [sellerNames, setSellerNames] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  console.log(selectedValues, "selectedValues");

  useEffect(() => {
    const fetchSellersNames = async () => {
      try {
        const response = await axios.get(
          `${baseURL}${btoa("invoice/allsellers")}`
        );
        console.log("response", response);
        setSellerNames(response.data);
        console.log(sellerNames, "sellernames");
      } catch (error) {
        console.log("error fetching seller names", error);
      }
    };
    fetchSellersNames();
    console.log("setSellerNames", sellerNames);
  }, []);

  const [busNameError, setbusNameError] = useState("");
  const [busAddNameError, setBusAddNameError] = useState("");
  const [busCityNameError, setbusCityNameError] = useState("");
  const [zipCodeError, setZipCodeError] = useState("");

  const [DomainError, setDomainError] = useState("");

  const [ownerDetails, setOwnerDetails] = useState([]);
  const [selectedOwner, setSelectedOwner] = useState("");
  const [invalidBusinessZipCode, setInvalidBusinessZipCode] = useState("");
  const [fileName, setFileName] = useState(null);
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);

  const baseURL =
    process.env.NODE_ENV === "production"
      ? config.production.baseURL
      : config.development.baseURL;

  const [formData, setFormData] = useState({
    ownerId: "",
    businessType: "",
    ownerName: "",
    businessName: "",
    businessAddress: "",
    businessCity: "",
    businessState: "",
    businessZipCode: "",
    businessLogo: "",
    contactPersonName: "",
    contactPersonEmail: "",
    contactPersonPhoneNumber: "",
    businessPhoneNumber: "",
    domainName: "",
  });

  const BusinessNameExistTooltip = ({ content, show, marginLeft }) => {
    return (
      <div
        style={{
          display: show ? "block" : "none",
          position: "absolute",
          backgroundColor: "#ffffff",
          color: "#fff",
          padding: "5px",
          borderRadius: "4px",
          border: "1px solid red",
          marginTop: "-35px",
          marginLeft: `${marginLeft}px`, // Use the provided marginLeft prop
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div
          style={{
            position: "absolute",
            bottom: "-7px",
            left: "50%",
            marginLeft: "-10px",
            width: "0",
            height: "0",
            borderLeft: "7px solid transparent",
            borderRight: "7px solid transparent",
            borderTop: "7px solid #f98080",
          }}
        ></div>
        <p className="text-xs text-red-500">{content}</p>
      </div>
    );
  };
  const DomainNameExistTooltip = ({ content, show, marginLeft }) => {
    return (
      <div
        style={{
          display: show ? "block" : "none",
          position: "absolute",
          backgroundColor: "#ffffff",
          color: "#fff",
          padding: "5px",
          borderRadius: "4px",
          border: "1px solid red",
          marginTop: "-35px",
          marginLeft: `${marginLeft}px`, // Use the provided marginLeft prop
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div
          style={{
            position: "absolute",
            bottom: "-7px",
            left: "50%",
            marginLeft: "-10px",
            width: "0",
            height: "0",
            borderLeft: "7px solid transparent",
            borderRight: "7px solid transparent",
            borderTop: "7px solid #f98080",
          }}
        ></div>
        <p className="text-xs text-red-500">{content}</p>
      </div>
    );
  };

  const CustomTooltip1 = ({ content, show, marginLeft }) => {
    return (
      <div
        style={{
          display: show ? "block" : "none",
          position: "absolute",
          backgroundColor: "#ffffff",
          color: "#fff",
          padding: "5px",
          borderRadius: "4px",
          border: "1px solid red",
          marginTop: "-35px",
          marginLeft: `${marginLeft}px`, // Use the provided marginLeft prop
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div
          style={{
            position: "absolute",
            bottom: "-7px",
            left: "50%",
            marginLeft: "-10px",
            width: "0",
            height: "0",
            borderLeft: "7px solid transparent",
            borderRight: "7px solid transparent",
            borderTop: "7px solid #f98080",
          }}
        ></div>
        <p className="text-xs text-red-500">{content}</p>
      </div>
    );
  };

  const BusinessAddressTooltip = ({ content, show, marginLeft }) => {
    return (
      <div
        style={{
          display: show ? "block" : "none",
          position: "absolute",
          backgroundColor: "#ffffff",
          color: "#fff",
          padding: "5px",
          borderRadius: "4px",
          border: "1px solid red",
          marginTop: "-35px",
          marginLeft: `${marginLeft}px`,
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div
          style={{
            position: "absolute",
            bottom: "-7px",
            left: "50%",
            marginLeft: "-10px",
            width: "0",
            height: "0",
            borderLeft: "7px solid transparent",
            borderRight: "7px solid transparent",
            borderTop: "7px solid #f98080",
          }}
        ></div>
        <p className="text-xs text-red-500">{content}</p>
      </div>
    );
  };

  const handleBusinessTypeChange = (e) => {
    setFormData({
      ...formData,
      businessType: e.target.value,
    });
  };

  const handleTextInputClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const CustomTooltip = ({ content, show, marginLeft }) => {
    return (
      <div
        style={{
          display: show ? "block" : "none",
          position: "absolute",
          backgroundColor: "#ffffff",
          color: "#fff",
          padding: "5px",
          borderRadius: "4px",
          border: "1px solid red",
          marginTop: "-35px",
          marginLeft: `${marginLeft}px`,
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
          zIndex: 10,
        }}
      >
        <div
          style={{
            position: "absolute",
            bottom: "-7px",
            left: "50%",
            marginLeft: "-10px",
            width: "0",
            height: "0",
            borderLeft: "7px solid transparent",
            borderRight: "7px solid transparent",
            borderTop: "7px solid #f98080",
          }}
        ></div>
        <p className="text-xs text-red-500">{content}</p>
      </div>
    );
  };
  const capitalizeWords = (input) => {
    return input.charAt(0).toUpperCase() + input.slice(1);
    // .toLowerCase()
    // .split(" ")
    // .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    // .join(" ");
  };

  const handlebusNameChange = (e) => {
    setBusinessNameExist("");
    const { name, value } = e.target;
    let inputValue = value.replace(/[^a-zA-Z0-9\s]/g, "");
    setFormData({
      ...formData,
      [name]: capitalizeWords(inputValue),
    });
    console.log(formData);

    // Set error message if item name is not empty and less than 3 characters
    if (inputValue.replace(/\s/g, "").length > 0 && inputValue.length < 3) {
      setbusNameError("Name must be at least 3 letters");
    } else {
      setbusNameError("");
    }
  };

  const handlebusCityChange = (e) => {
    const { name, value } = e.target;
    let inputValue = value.replace(/[^a-zA-Z0-9\s]/g, "");

    setFormData({
      ...formData,
      [name]: capitalizeWords(inputValue),
    });

    if (inputValue.replace(/\s/g, "").length > 0 && inputValue.length < 3) {
      setbusCityNameError(" City Name must be at least 3 letters");
    } else {
      setbusCityNameError("");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    cleaned = cleaned.slice(0, 10);
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return cleaned;
  }

  const handleFaxNumber = (e) => {
    const { name, value } = e.target;
    let inputValue = formatPhoneNumber(value);

    setFormData({
      ...formData,
      [name]: inputValue,
    });
  };
  const handleDomainName = (e) => {
    setDomainNameExist("");
    const { name, value } = e.target;
    let inputValue = value;
    if (e.target.value === "" || e.target.value.length < 3) {
      setDomainError("Enter minimum 3 characters");
    } else {
      setDomainError("");
    }
    setFormData({
      ...formData,
      [name]: inputValue,
    });
  };

  const handleBusinessPhoneChange = (e) => {
    const { name, value } = e.target;
    console.log("Before formatting:", value);

    let inputValue = formatPhoneNumber(value);
    console.log("after formatting:", inputValue);

    setFormData({
      ...formData,
      [name]: inputValue,
    });
  };
  const handleInputZipChange = (e) => {
    const { name, value } = e.target;
    let inputValue = value.replace(/\D/g, "");
    inputValue = inputValue.slice(0, 5);

    const containsNonZero = /[1-9]/.test(e.target.value);
    if (containsNonZero) {
      setInvalidBusinessZipCode("valid");
    } else {
      setContactNumberError("");
      setInvalidBusinessZipCode("Invalid number");
    }

    if (e.target.value === "") {
      setInvalidBusinessZipCode("");
    }

    setFormData({
      ...formData,
      [name]: inputValue,
    });
    if (inputValue.length > 0 && inputValue.length < 5) {
      setZipCodeError("Zip code must be at most 5 digits");
    } else {
      setZipCodeError("");
    }
  };

  const handleBusinessLogoUpload = (event) => {
    const selectedFile = event.target.files[0];
    // console.log("selected logo" , selectedFile);
    // setFormData({
    //   ...formData,
    //   businessLogo: selectedFile,
    // });
    if (selectedFile) {
      setFileName(selectedFile.name);
      setFile(selectedFile);
    } else {
      setFileName("");
      setFile(null);
    }
  };

  useEffect(() => {
    const fetchOwnerDetails = async () => {
      try {
        const response = await axios.get(`${baseURL}/${btoa("owner/view")}`);
        // Assuming the response contains the owner details in the 'owners_list' property
        setOwnerDetails(response.data.owners_list);
        console.log("Owner details:", response.data.owners_list);
      } catch (error) {
        console.error("Error fetching owner details", error);
      }
    };

    fetchOwnerDetails();
  }, []); // Emp
  const [selectedIds, setSelectedIds] = useState([]);

  const handleChange = (event, id) => {
    const { value, checked } = event.target;

    if (checked) {
      setSelectedValues((prevValues) => [...prevValues, value]); // Add selected value to the array
    } else {
      setSelectedValues((prevValues) =>
        prevValues.filter((val) => val !== value)
      ); // Remove from array if unchecked
    }

    // Update formData with the selected values
    setFormData((prevData) => ({
      ...prevData,
      sellerNames: [selectedValues],
    }));
    setSelectedIds((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  const handleBusinessDetails = async () => {
    // console.log("business details" ,formData);
    // console.log("logo" ,file);
    const businessData = new FormData();
    businessData.append("adminId", localStorage.getItem("id"));
    businessData.append("businessType", formData.businessType);
    businessData.append("businessName", formData.businessName);
    businessData.append("businessAddress", formData.businessAddress);
    businessData.append("businessCity", formData.businessCity);
    businessData.append("businessState", formData.businessState);
    businessData.append("businessZipCode", formData.businessZipCode);
    businessData.append("uploadLogo", file);
    businessData.append("contactPersonName", formData.contactPersonName);
    businessData.append("contactPersonEmail", formData.contactPersonEmail);
    businessData.append(
      "contactPersonPhoneNumber",
      formData.contactPersonPhoneNumber
    );
    businessData.append("businessPhoneNumber", formData.businessPhoneNumber);
    businessData.append("faxNumber", formData.faxNumber);
    businessData.append("domainName", formData.domainName);

    let validIndex = 0;
    // businessData.append("sellerNames", selectedValues); // Use '[]' to indicate an array
    selectedValues.forEach((value, validIndex) => {
      businessData.append(`brandIds[${validIndex}]`, value); // Use '[]' to indicate an array
      validIndex++;
    });

    // businessData.append("business_contact_no" , formData.BusinessPhoneNumber )

    for (let [key, value] of businessData.entries()) {
      console.log(`${key}: ${value}`);
    }
    //  console.log("logo" ,file);
    try {
      const response = await axios.post(
        `${baseURL}${btoa("business/create")}`,
        businessData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("response business upload", response);
      // toast.success((`${formData.businessName} business has been created`),{
      //   autoClose : 2000
      // })
      dispatch(
        addToast({
          type: "success",
          message: `${formData.businessName} business has been created`,
        })
      );

      navigate("/adminbusinessdashboard");
    } catch (error) {
      console.log("error creating business details", error);
      if (
        error.response?.data.businessStatus === "Business name already exist"
      ) {
        setBusinessNameExist("Business name already exist");
      }

      if (error.response?.data.domainStatus === "Domain name already exist") {
        setDomainNameExist("Domain name already exist");
      }
      if (error.response?.data.userStatus === "Email already exist") {
        setEmailError("Email already exist");
      }
      if (error.response?.data.userStatus === "Contact number already exist") {
        setContactNumberError("Contact number already exist");
      }
    }
  };

// Create a single reusable tooltip component
const ErrorTooltip = ({ content, show, marginLeft = 140 }) => {
  if (!show) return null;
  
  return (
    <div
      style={{
        position: "absolute",
        backgroundColor: "#ffffff",
        padding: "5px",
        borderRadius: "4px",
        border: "1px solid red",
        marginTop: "-35px",
        marginLeft: `${marginLeft}px`,
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
        zIndex: 10,
      }}
    >
      <div
        style={{
          position: "absolute",
          bottom: "-7px",
          left: "50%",
          marginLeft: "-10px",
          width: "0",
          height: "0",
          borderLeft: "7px solid transparent",
          borderRight: "7px solid transparent",
          borderTop: "7px solid #f98080",
        }}
      ></div>
      <p className="text-xs text-red-500">{content}</p>
    </div>
  );
};

  return (
    <div className="pt-20 pb-20">
      <h1 className="mx-8 font-bold mb-24 text-gray-400 text-xl mt-[-34px]">
        Create New Business
      </h1>

      <div className="w-auto  bg-white rounded-box  mx-8 rounded-xl">
        <div className="flex items-center">
          <MdOutlineBusinessCenter className="text-gray-700 w-6 h-6 mr-2 mb-10" />
          <h2 className="mb-10 text-xl font-bold text-gray-900 underline underline-offset-8 decoration-gray-300">
            Business Info
          </h2>
        </div>
        <div className="grid gap-4 sm:grid-cols-3 sm:gap-6">
          <div className="w-full ">
            <label
              htmlFor="ownerSelect"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Business type
              <span className="text-red-500 text-xl font-extrabold ml-1">
                *
              </span>
            </label>
            <select
              id="selectBusinessType"
              name="selectBusinessType"
              // value={selectedOwner}
              onChange={handleBusinessTypeChange}
              className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-white"
            >
              <option value="">Select Business type</option>

              <option key={"Retail"} value={"1"}>
                Retail distribution
              </option>
              <option key={"Gas"} value={"2"}>
                Gas distribution
              </option>
            </select>
          </div>

          <div className="w-full">
            <label
              htmlFor="businessName"
              className="block mb-2 text-sm font-medium text-gray-900 "
            >
              Business Name{" "}
              <span className="text-red-500 text-xl font-extrabold">*</span>
            </label>

            <BusinessNameExistTooltip
              content={businessNameExist}
              show={!!businessNameExist}
              marginLeft={140}
            />
            <CustomTooltip1
              content={busNameError}
              show={!!busNameError}
              marginLeft={140}
            />

            <input
              type="text"
              id="businessName"
              name="businessName"
              value={formData.businessName}
              onChange={handlebusNameChange}
              required
              className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${"bg-white"}`}
              placeholder="Enter Business Name"
            />
          </div>

          <div className="w-full">
            <label
              htmlFor="businessAddress"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Business Address{" "}
              <span className="text-red-500 text-xl font-extrabold">*</span>
            </label>
            <BusinessAddressTooltip
              content="Business Address should be at least three char."
              show={
                formData.businessAddress?.length > 0 &&
                formData.businessAddress?.length < 3
              }
              marginLeft={140}
            />

            <input
              type="text"
              id="businessAddress"
              name="businessAddress"
              value={formData.businessAddress}
              onChange={(e) => {
                const inputValue = e.target.value;
                const sanitizedValue = inputValue.replace(
                  /[^a-zA-Z0-9, \-/#]/g,
                  ""
                );
                setFormData((prevData) => ({
                  ...prevData,
                  businessAddress: sanitizedValue,
                }));
              }}
              required
              className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${"bg-white"}`}
              placeholder="Enter Business Address"
            />
          </div>
          <div className="w-full">
            <label
              htmlFor="businessCity"
              className="block mb-2 text-sm font-medium text-gray-900 "
            >
              City{" "}
              <span className="text-red-500 text-xl font-extrabold">*</span>
            </label>
            <CustomTooltip1
              content={busCityNameError}
              show={!!busCityNameError}
              marginLeft={155}
            />

            <input
              className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${"bg-white"}`}
              placeholder="Enter City"
              type="text"
              id="businessCity"
              name="businessCity"
              value={formData.businessCity}
              onChange={handlebusCityChange}
              required
            />
          </div>
          <div className="w-full">
            <label
              htmlFor="businessState"
              className="block mb-2 text-sm font-medium text-gray-900 "
            >
              State{" "}
              <span className="text-red-500 text-xl font-extrabold">*</span>
            </label>
            <select
              id="businessState"
              name="businessState"
              className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${"bg-white"}`}
              value={formData.businessState}
              onChange={handleInputChange}
              // onChange={handleChange}
              required
            >
              <option value="">Select State</option>
              <option value="AL">Alabama (AL)</option>
              <option value="AK">Alaska (AK)</option>
              <option value="AZ">Arizona (AZ)</option>
              <option value="AR">Arkansas (AR)</option>
              <option value="CA">California (CA)</option>
              <option value="CO">Colorado (CO)</option>
              <option value="CT">Connecticut (CT)</option>
              <option value="DE">Delaware (DE)</option>
              <option value="FL">Florida (FL)</option>
              <option value="GA">Georgia (GA)</option>
              <option value="HI">Hawaii (HI)</option>
              <option value="ID">Idaho (ID)</option>
              <option value="IL">Illinois (IL)</option>
              <option value="IN">Indiana (IN)</option>
              <option value="IA">Iowa (IA)</option>
              <option value="KS">Kansas (KS)</option>
              <option value="KY">Kentucky (KY)</option>
              <option value="LA">Louisiana (LA)</option>
              <option value="ME">Maine (ME)</option>
              <option value="MD">Maryland (MD)</option>
              <option value="MA">Massachusetts (MA)</option>
              <option value="MI">Michigan (MI)</option>
              <option value="MN">Minnesota (MN)</option>
              <option value="MS">Mississippi (MS)</option>
              <option value="MO">Missouri (MO)</option>
              <option value="MT">Montana (MT)</option>
              <option value="NE">Nebraska (NE)</option>
              <option value="NV">Nevada (NV)</option>
              <option value="NH">New Hampshire (NH)</option>
              <option value="NJ">New Jersey (NJ)</option>
              <option value="NM">New Mexico (NM)</option>
              <option value="NY">New York (NY)</option>
              <option value="NC">North Carolina (NC)</option>
              <option value="ND">North Dakota (ND)</option>
              <option value="OH">Ohio (OH)</option>
              <option value="OK">Oklahoma (OK)</option>
              <option value="OR">Oregon (OR)</option>
              <option value="PA">Pennsylvania (PA)</option>
              <option value="RI">Rhode Island (RI)</option>
              <option value="SC">South Carolina (SC)</option>
              <option value="SD">South Dakota (SD)</option>
              <option value="TN">Tennessee (TN)</option>
              <option value="TX">Texas (TX)</option>
              <option value="UT">Utah (UT)</option>
              <option value="VT">Vermont (VT)</option>
              <option value="VA">Virginia (VA)</option>
              <option value="WA">Washington (WA)</option>
              <option value="WV">West Virginia (WV)</option>
              <option value="WI">Wisconsin (WI)</option>
              <option value="WY">Wyoming (WY)</option>
            </select>
          </div>

          <div className="w-full">
            <label
              htmlFor="businessZipCode"
              className="block mb-2 text-sm font-medium text-gray-900 "
            >
              Zip Code{" "}
              <span className="text-red-500 text-xl font-extrabold">*</span>
            </label>
            <CustomTooltip1
              content={zipCodeError}
              show={!!zipCodeError}
              marginLeft={167}
            />
            {invalidBusinessZipCode === "Invalid number" && !zipCodeError && (
              <CustomTooltip1
                content={"Invalid ZipCode"}
                show={"Invalid ZipCode"}
                marginLeft={163}
              />
            )}
            <input
              className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${"bg-white"}`}
              placeholder="Enter Zipcode"
              type="text"
              id="businessZipCode"
              name="businessZipCode"
              value={formData.businessZipCode}
              onChange={handleInputZipChange}
              required
            />
            {/* {zipCodeError && (
                  <div className="text-red-500 text-sm mt-1">
                    {zipCodeError}
                  </div>
                )} */}
          </div>

          <div className="w-full">
            <label
              htmlFor="businessLogo"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Business Logo
            </label>
            <div className="flex w-full relative">
              <input
                type="file"
                id="fileInput"
                className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                onChange={handleBusinessLogoUpload}
                ref={fileInputRef}
              />
              <input
                type="text"
                placeholder="Choose file"
                className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-white"
                value={fileName}
                readOnly
                onClick={handleTextInputClick}
              />
            </div>
          </div>
          <div className="w-full">
            <label
              htmlFor="businessPhoneNumber"
              className="block mb-2 text-sm font-medium text-gray-900 "
            >
              Business Phone Number{" "}
            </label>
            <ErrorTooltip
    content={contactNumberError}
    show={!!contactNumberError}
    marginLeft={140}
  />
            {/* <CustomTooltip1 content={zipCodeError} show={!!zipCodeError}      marginLeft={167}/>
                {invalidBusinessZipCode === "Invalid number" && !zipCodeError &&  (
                <CustomTooltip1
                  content={"Invalid ZipCode"}
                  show={"Invalid ZipCode"}
                  marginLeft={163}
                />
                )}  */}
            <input
              className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${"bg-white"}`}
              placeholder="Enter Business Phone number"
              type="text"
              id="businessPhoneNumber"
              name="businessPhoneNumber"
              value={formData.businessPhoneNumber}
              onChange={handleBusinessPhoneChange}
              // required
            />
            {/* {zipCodeError && (
                  <div className="text-red-500 text-sm mt-1">
                    {zipCodeError}
                  </div>
                )} */}
          </div>
          <div className="w-full">
            <label
              htmlFor="faxNumber"
              className="block mb-2 text-sm font-medium text-gray-900 "
            >
             Business Fax Number{" "}
            </label>
            <ErrorTooltip
    content="Invalid fax number format"
    show={formData.faxNumber && formData.faxNumber.length > 0 && formData.faxNumber.length < 10}
    marginLeft={167}
  />
            {/* <CustomTooltip1 content={zipCodeError} show={!!zipCodeError}      marginLeft={167}/>
                {invalidBusinessZipCode === "Invalid number" && !zipCodeError &&  (
                <CustomTooltip1
                  content={"Invalid ZipCode"}
                  show={"Invalid ZipCode"}
                  marginLeft={163}
                />
                )}  */}
            <input
              className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${"bg-white"}`}
              placeholder="Enter Business Fax Number"
              type="text"
              id="faxNumber"
              name="faxNumber"
              value={formData.faxNumber}
              onChange={handleFaxNumber}
              // required
            />
            {/* {zipCodeError && (
                  <div className="text-red-500 text-sm mt-1">
                    {zipCodeError}
                  </div>
                )} */}
          </div>
          <div className="w-full">
            <label
              htmlFor="domainName"
              className="block mb-2 text-sm font-medium text-gray-900 "
              required
            >
              Domain Name{" "}
              <span className="text-red-500 text-xl font-extrabold">*</span>
            </label>
            
            <ErrorTooltip message={domainNameExist || DomainError} />
            <DomainNameExistTooltip
              content={domainNameExist}
              show={!!domainNameExist}
              marginLeft={140}
            />

            <CustomTooltip1
              content={DomainError}
              show={!!DomainError}
              marginLeft={167}
            />

            <input
              className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${"bg-white"}`}
              placeholder="Enter DomainName"
              type="text"
              id="domainName"
              name="domainName"
              value={formData.domainName}
              onChange={handleDomainName}
              required
              minLength={3}
            />
          </div>
        </div>
        {/* <div className="flex justify-end space-x-3">
            
                <>
                  <button
                    onClick={()=> navigate("/")}
                    type="submit"
                    className="hover:bg-red-500 border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-sm font-semibold hover:border-transparent hover:shadow-lg hover:shadow-red-500/60 text-center hover:text-white  rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleBusinessDetails}
                    type="submit"
                    className="hover:bg-[#0044AB] border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-sm font-semibold hover:border-transparent hover:shadow-lg hover:shadow-[#0044AB]/50 text-center hover:text-white  rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800"
                    disabled={
                      formData.businessType === "" ||
                      formData.businessName?.length < 3 ||
                      formData.businessAddress?.length < 3 ||
                      formData.businessCity?.length < 3 ||
                      formData.businessState === '' || 
                      formData.businessZipCode?.length < 5 || invalidBusinessZipCode === "Invalid number"
                    }
                  >
                    Save
                  </button>
                </>
           
            </div> */}
      </div>
      <div
        className="rounded-lg w-auto bg-white shadow-xl m-8 rounded-xl"
        style={{
          boxShadow: "0 4px 15px rgba(0, 0, 0, 0.1)", // Refined shadow for a cleaner look
          backgroundColor: "#ffffff", // Ensure the background is pure white
          transition: "all 0.3s ease-in-out", // Smooth transition on hover
        }}
      >
        <div className="flex items-center m-6 pt-4">
          <LocalGasStation className="text-gray-700 w-6 h-6 mr-2 mb-0" />
          <h2 className="mb-0 text-xl font-bold text-gray-900 underline  decoration-gray-300">
            Fuel Brands
          </h2>
        </div>
        <div className="grid grid-cols-9 gap-4 p-6 ml-4 pt-0">
          {sellerNames.map((sellerName) => (
            <label
              key={sellerName.id}
              className="relative flex flex-col items-center justify-between p-3 border-2 border-[#DCDCDC] rounded-lg cursor-pointer transition-all duration-300 group"
              style={{ height: "150px", width: "120px" }}
              title="Click on the logo to select" // Native Tooltip
            >
              {/* Hidden Checkbox */}
              <input
                type="checkbox"
                value={sellerName.id}
                onChange={(e) => handleChange(e, sellerName.id)}
                className="absolute top-0 left-0 w-full h-full opacity-0 peer"
                name="sellerNames"
              />

              {/* Content */}
              <div className="z-10 mt-6">
                {/* Logo */}
                <img
                  src={`${baseURL}/img/${sellerName.brand_logo}`}
                  alt={sellerName.seller_name}
                  style={{ width: "80px", height: "50px" }}
                  className="object-contain"
                />
                <span className="block mt-2 text-md font-bold font-medium text-center text-gray-700">
                  {sellerName.seller_name}
                </span>
              </div>

              {/* Custom Tooltip */}
              <div className="absolute bottom-[150px] left-1/2 transform -translate-x-1/2 bg-black text-white text-xs px-2 py-1 rounded-md opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none">
                {selectedIds.includes(sellerName.id)
                  ? "Click again to unselect"
                  : "Click on the logo to select"}
              </div>

              {/* Tick Mark (Appears when checked) */}
              <div className="absolute bottom-2 right-2 w-6 h-6 bg-blue-500 rounded-full flex items-center justify-center opacity-0 group peer-checked:opacity-100 transition-opacity duration-300">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  className="w-4 h-4 text-white"
                >
                  <path
                    d="M20 6L9 17l-5-5"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </label>
          ))}
        </div>
      </div>

      <CreateOwner
        setFormData={setFormData}
        formData={formData}
        handleBusinessDetails={handleBusinessDetails}
        emailError1={emailError}
        setEmailError={setEmailError}
        contactNumberError={contactNumberError}
        setContactNumberError={setContactNumberError}
      />
    </div>
  );
};

export default CreateBusiness;

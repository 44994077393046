import { Button, Modal } from "flowbite-react";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import config from "../../config/config";
import { showToastForAddInvoice, showToastForDeleteInvoice } from "../toasts/toastForInvoice";
import { addToast } from '../pages/ToastBox/ToastBoxMessageSlice';

import { useNavigate} from 'react-router-dom';

export default function PopUpModalForSendStatement({
  onClose,
  invoiceNumber,
  invoiceDelete,
  invoiceName,
  resetRowSelection,
  customerEmail,
  finalInvoiceAmount,
  dueDate,
  setTriggerFetch,
  triggerFetch,
  handleSentStatement
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const businessId = localStorage.getItem("business_id");
  const managerId = localStorage.getItem("user_id"); // Assuming managerId is stored in localStorage
  const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;
  const [openModal, setOpenModal] = useState(true);

  // Log customerEmail to ensure it's received correctly
  console.log("customerEmail prop:", customerEmail, invoiceName);

  const [emailFields, setEmailFields] = useState([{ id: 1, value: customerEmail }]);

  useEffect(() => {
    if (customerEmail) {
      setEmailFields([{ id: 1, value: customerEmail }]);
    }
  }, [customerEmail]);

  const handleChange = (id, value) => {
    setEmailFields((prev) =>
      prev.map((field) => (field.id === id ? { ...field, value } : field))
    );
  };

  const handleAddField = () => {
    setEmailFields((prev) => [...prev, { id: prev.length + 1, value: '' }]);
  };
 
  const handleRemoveField = (id) => {
    setEmailFields((prev) => prev.filter((field) => field.id !== id));
  };

  const userRole = localStorage.getItem("user_role");
  const handleSend = async () => {
    const emailArray = emailFields.map((field) => field.value);

    const data = {
      name: invoiceName,
      businessId : localStorage.getItem("business_id"),
      managerId : localStorage.getItem("user_id"),
      invoiceNumber : invoiceNumber,
      finalInvoiceAmount : finalInvoiceAmount,
      dueDate : dueDate,
      emails: emailArray,
      userRole:userRole
    };

    // console.log("data to send:", data);

    try {
      const response = await axios.post(`${baseURL}${btoa("invoice/sendmails")}`, data);
      console.log("response from API:", response);
      if (response.data.dataSavingStatus === true) {
        // showToastForAddInvoice("Invoice sent successfully");
        dispatch(addToast({ type: 'info', message: `Invoice sent successfully` }));        
        onClose();
        resetRowSelection();
        setTriggerFetch(!triggerFetch);
      }
    } catch (error) {
      console.log("error sending invoice:", error);
      // showToastForDeleteInvoice("Error occurred while sending invoice");
      // dispatch(addToast({ type: 'danger', message: `${invoiceName} deleted successfully` }));

    }
    navigate('/invoice')

  };

  return (
    <Modal show={openModal} size="md" popup onClose={onClose}>
    <Modal.Header />
    <Modal.Body>
      <div className="text-center">
        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400 mt-3">
          Send Statement #
          <span className="block uppercase p-2 font-semibold text-red-700 underline underline-offset-8">"{invoiceNumber}"</span>
          <span className="mt-2 text-lg font-normal text-gray-500 dark:text-gray-400"><span className="capitalize">To</span> {invoiceName} </span>
        </h3>
        {emailFields.map((field, index) => (
          <div className="mb-4 flex items-center" key={field.id}>
            <input
              type="email"
              value={field.value}
              onChange={(e) => handleChange(field.id, e.target.value)}
              placeholder="Enter email address"
              className={`p-2 border border-gray-300 rounded w-full ${index === 0 ? 'bg-gray-200' : ''}`}
              readOnly={index === 0}
            />
            {index === emailFields.length - 1 ? (
              <button
                onClick={handleAddField}
                className="ml-2 w-10 h-10 bg-blue-500 text-white rounded flex justify-center items-center"
              >
                +
              </button>
            ) : (
              <button
                onClick={() => handleRemoveField(field.id)}
                className="ml-2 w-10 h-10 bg-red-500 text-white rounded flex justify-center items-center"
              >
                x
              </button>
            )}
          </div>
        ))}
        <div className="flex justify-center gap-5 ml-4">
          <button
            onClick={handleSentStatement} 
            className="bg-green-500 hover:bg-green-500 text-white font-bold py-2 px-4 rounded w-[23%]"
          >
            Send
          </button>
          <button
            onClick={onClose}
            className="bg-gray-500 hover:bg-gray-500 text-white font-bold py-2 px-4 rounded w-[23%]"
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal.Body>
  </Modal>
  );
}

import React, { useState , useEffect} from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import {MdOutlinePhoneInTalk} from "react-icons/md";
import axios from 'axios';
import { toast } from 'react-toastify';
import config  from "../../config/config"
import smsIcon from '../../../src/assets/images/smsIcon.png'
import { Tooltip } from "react-tippy";
import { useDispatch } from "react-redux";
import { addToast } from '../pages/ToastBox/ToastBoxMessageSlice';
const CreateOwner = ({setFormData , formData , handleBusinessDetails,
   emailError1,setEmailError,contactNumberError,setContactNumberError}) => {
  const dispatch = useDispatch()

    const navigate = useNavigate()
    const [contactNameError, setContactNameError] = useState("");
    // const [emailError, setEmailError] = useState("");
    const [inputPhoneError, setInputPhoneError] = useState("");

    const [ownerDetails , setOwnerDetails] = useState([])
    const [businessDetails , setBusinessDetails] = useState([])
    const [invalidPhoneNumber , setInvalidPhoneNumber] = useState("");
    
    const [selectedBusiness, setSelectedBusiness] = useState('');
    const [invalidBusinessZipCode , setInvalidBusinessZipCode] = useState("")
    
    const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;

    useEffect(() => {
      if (typeof setFormData === 'function') {
        setFormData(prevFormData => ({
          ...prevFormData,
          contactPersonName: ownerDetails.contactPersonName,
          contactPersonEmail: ownerDetails.contactPersonEmail,
          contactPersonPhoneNumber: ownerDetails.contactPersonPhoneNumber,
        }));
      }
    }, [ownerDetails, setFormData]);
    
   

    const capitalizeWords = (input) => {
      return input.charAt(0).toUpperCase() + input.slice(1);
        // return input
        //   .toLowerCase()
        //   .split(" ")
        //   .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        //   .join(" ");
      };
    
    const handleContactNameChange = (e) => {
        const { name, value } = e.target;
        let inputValue = value.replace(/[^a-zA-Z\s]/g, "");
        setOwnerDetails({
            ...ownerDetails,
            [name]: capitalizeWords(inputValue),
          });
          if (inputValue.replace(/\s/g, "").length > 0 && inputValue.length < 3) {
            setContactNameError(" Name must be at least 3 letters");
          } else {
            setContactNameError("");
          }
      };
      
      const validateEmail = (email) => {
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailPattern.test(email);
      };
    
      const handleEmailChange = (e) => {
        setEmailError('')
        const { name, value } = e.target;
        const isValidEmail = validateEmail(value);
    
        setOwnerDetails({
          ...ownerDetails,
          [name]: value,
        });
        if (value.length > 0 && !isValidEmail) {
          setEmailError("Invalid email address");
        } else {
          setEmailError("");
        }
    
      };

      function formatPhoneNumber(phoneNumberString) {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        cleaned = cleaned.slice(0, 10);
        var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          var intlCode = (match[1] ? '+1 ' : '');
          return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
        }
        return cleaned;
      }
      
      const handleInputPhoneChange = (e) => {
        setContactNumberError('')
        const { name, value } = e.target;
        let inputValue = formatPhoneNumber(value);
      
        // Check if inputValue has at least one non-zero digit
        const containsNonZero = /[1-9]/.test(inputValue);
      
        // Check if inputValue is all zeros
        const allZeros = /^0+$/.test(inputValue);
      
        // Check if inputValue length is between 1 and 10
        if (inputValue.length > 0 && inputValue.length < 10) {
          setInvalidPhoneNumber("Invalid number");
        } else if (!containsNonZero || allZeros) {
          setInvalidPhoneNumber("Invalid number");
        } else {
          setInvalidPhoneNumber("valid");
        }
      
        setOwnerDetails({
          ...ownerDetails,
          [name]: inputValue,
        });
      };
      
    const handleOwnerDetails = async ()=>{
        // console.log("owner details :" , ownerDetails);
        // console.log("selected business :" , selectedBusiness);
        // console.log("adminId " , localStorage.getItem("id"));
        try{
                const response = await axios.post(`${baseURL}/${btoa("owner/create")}`,{
                businessId :selectedBusiness,
                adminId : localStorage.getItem("id"),
                contactPersonName: ownerDetails.contactPersonName,
                contactPersonEmail: ownerDetails.contactPersonEmail,
                contactPersonPhoneNumber: ownerDetails.contactPersonPhoneNumber
          })

            // console.log(ownerDetails);
            navigate("/")
          
          if(response.data.dataSavingStatus === true && response.data.sendPasswordStatus === true){
            // toast.success(("User has been created, Password has been sent to registered mobile number"),{
            //   autoClose : 2000
            // })
  dispatch(addToast({ type: 'success', message: `User has been created, Password has been sent to registered mobile number` }));
            
          }else if(response.data.dataSavingStatus === true && response.data.sendPasswordStatus === false){
            // toast.success(("User has been created but password could not be send"),{
            //   autoClose : 2000
            // })
    dispatch(addToast({ type: 'success', message: `User has been created but password could not be send` }));
            
          }
            
        }catch(error){
           if(error.response.data.userStatus === 'Contact number already exist' && error.response.data.dataSavingStatus === false){
            // toast.error(("Contact number is already registered with another user"),{autoClose : 2000})
            dispatch(addToast({ type: 'danger', message: `Contact number is already registered with another user` }));
 
            }
            else if(error.response.data.userStatus === 'Email already exist' && error.response.data.dataSavingStatus === false){
                // toast.error(("Email is already registered with another user"),{autoClose : 2000})
                dispatch(addToast({ type: 'danger', message: `Email is already registered with another user` }));

            }
             else if(error.response.data.dataSavingStatus === false && error.response.data.sendPasswordStatus === false){
              // toast.error(("Something went wrong while creating user"),{autoClose : 2000})
              dispatch(addToast({ type: 'danger', message: `Something went wrong while creating user` }));

            }
            console.log("error posting owner details :" , error);
        }
    }

    const CustomTooltip1 = ({ content, show, marginLeft }) => {
        return (
          <div
            style={{
              display: show ? "block" : "none",
              position: "absolute",
              backgroundColor: "#ffffff",
              color: "#fff",
              padding: "5px",
              borderRadius: "4px",
              border: "1px solid red",
              marginTop: "-35px",
              marginLeft: `${marginLeft}px`, // Use the provided marginLeft prop
              boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
            }}
          >
            <div
              style={{
                position: "absolute",
                bottom: "-7px",
                left: "50%",
                marginLeft: "-10px",
                width: "0",
                height: "0",
                borderLeft: "7px solid transparent",
                borderRight: "7px solid transparent",
                borderTop: "7px solid #f98080",
              }}
            ></div>
            <p className="text-xs text-red-500">{content}</p>
          </div>
        );
      };

      useEffect(() => {
        const fetchBusinessDetails = async () => {
          try {
            const response = await axios.get(`${baseURL}/${btoa("business/view")}`);
            console.log("business details", response);
            setBusinessDetails(response.data.business_data)
           
          } catch (error) {
            console.error('Error fetching business details', error);
          }
        };
    
        fetchBusinessDetails();
      }, []); // Emp


      const handleBusinessChange = (e) => {
        const selectedBusinessId = e.target.value;
        setSelectedBusiness(selectedBusinessId);
      }
    
      const isDisabled =
      contactNameError ||
      emailError1 ||
      inputPhoneError ||
      invalidPhoneNumber === 'Invalid number' ||
      !formData?.businessType ||
      (formData?.businessName?.length < 3 || formData?.businessAddress?.length < 3 || formData?.businessCity?.length < 3) ||
      !formData?.businessState ||
      formData?.businessZipCode?.length < 5 ||
      formData?.domainName?.length < 3;
  
  return (
    <div>

        <div className="w-auto bg-white rounded-box  mx-8 rounded-xl mt-16 mb-6">
            <div className="flex items-center">
              <MdOutlinePhoneInTalk className="text-gray-700 w-6 h-6 mr-2 mb-10" />
              <h2 className="mb-10 text-xl font-bold text-gray-900 underline underline-offset-8 decoration-gray-300">
                Create Owner
              </h2>
            </div>

            <div className="grid gap-4 sm:grid-cols-3 sm:gap-6">

              <div className="w-full">
                <label
                  htmlFor="contactPersonName"
                  className="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Contact Person  Name
                  <span className="text-red-500 text-xl font-extrabold ml-1">*</span>
                </label>
                <CustomTooltip1
                  content={contactNameError}
                  show={!!contactNameError}
                  marginLeft={120}
                />

                <input
                  className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${
                  "bg-white" 
                  }`}
                  placeholder="Contact Person Name"
                  type="text"
                  id="contactPersonName"
                  name="contactPersonName"
                  value={ownerDetails.contactPersonName}
                  onChange={handleContactNameChange}
                  required
                
                />
              </div>
              {/* <div className="w-full">
                <label
                  htmlFor="contactPersonLastName"
                  className="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Contact Person Last Name
                  <span className="text-red-500 text-xl font-extrabold ml-1">*</span>
                </label>
                <CustomTooltip1
                  content={contactNameError}
                  show={!!contactNameError}
                  marginLeft={120}
                />

                <input
                  className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${
                  "bg-white" 
                  }`}
                  placeholder="contact Person LastName"
                  type="text"
                  id="contactPersonLastName"
                  name="contactPersonLastName"
                  value={ownerDetails.contactPersonName}
                  onChange={handleContactNameChange}
                  required
                
                />
              </div> */}

              <div className="w-full">
                <label
                  htmlFor="contactPersonEmail"
                  className="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Email
                  <span className="text-red-500 text-xl font-extrabold ml-1">*</span>
                </label>
                <CustomTooltip1 content={emailError1} show={!!emailError1}   marginLeft={206}/>

                <input
                  className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${
                     "bg-white"
                  }`}
                  placeholder="Enter Email"
                  type="email"
                  id="contactPersonEmail"
                  name="contactPersonEmail"
                  value={ownerDetails.contactPersonEmail}
                  onChange={handleEmailChange}
                  required
                
                />
              </div>

              <div className="w-full">
                <label
                  htmlFor="contactPersonPhoneNumber"
                  className="block mb-2 text-sm font-medium text-gray-900  "
                >
                   <div className="flex items-center">
      <span>Phone Number</span>
      <span className="text-red-500 text-xl font-extrabold ml-1">*</span>
      <Tooltip title="SMS will be sent" position="top" trigger="mouseenter">

      <img src={smsIcon} alt="SMS Icon" style={{ width: '30px', height: '20px', marginLeft: '8px' }} />
      </Tooltip>
    </div>
               

                </label>
                <CustomTooltip1
                  content={contactNumberError}
                  show={!!contactNumberError}
                  marginLeft={163}
                />
                 {invalidPhoneNumber === "Invalid number" && !inputPhoneError &&  (
                  <CustomTooltip1
                    content={"Invalid Number"}
                    show={"Invalid Number"}
                    marginLeft={163}
                    />
                  )}

                <input
                  className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${
                     "bg-white" }`
                }
                  placeholder="Enter Phone Number"
                  type="text"
                  id="contactPersonPhoneNumber"
                  name="contactPersonPhoneNumber"
                  value={ownerDetails.contactPersonPhoneNumber}
                  onChange={handleInputPhoneChange}
                  required
                 
                />
              </div>
            </div>

            <div className="flex justify-end space-x-3">
            
                <>
                  <button
                    onClick={()=> navigate("/adminbusinessdashboard")}
                    type="submit"
                    className="hover:bg-red-500 border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-sm font-semibold hover:border-transparent hover:shadow-lg hover:shadow-red-500/60 text-center hover:text-white  rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800"
                  >
                    Cancel
                  </button>

                  {/* <button
                    onClick={() => handleBusinessDetails()}
                    type="submit"
                    disabled={
                      contactNameError ||
                      emailError1 ||
                      inputPhoneError ||
                      invalidPhoneNumber === "Invalid number" ||
                      formData?.businessType === "" ||
                      formData?.businessName?.length < 3 ||
                      formData?.businessAddress?.length < 3 ||
                      formData?.businessCity?.length < 3 ||
                      formData?.businessState === '' ||
                      formData?.businessZipCode?.length < 5 ||
                      formData?.domainName?.length < 3
                    }
                    // className="hover:bg-[#0044AB] border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-sm font-semibold hover:border-transparent hover:shadow-lg hover:shadow-[#0044AB]/50 text-center hover:text-white rounded-lg focus:ring-4 focus:ring-primary-200 hover:bg-primary-800"
                    className={`px-6 py-1.5 mt-4 sm:mt-6 text-sm font-semibold text-center rounded-lg focus:ring-4 focus:ring-primary-200 
                      ${disabled ? 'bg-gray-300 text-gray-600 border-gray-400 cursor-not-allowed' : 'hover:bg-[#0044AB] border-[#3479E0] border-2 text-black hover:border-transparent hover:shadow-lg hover:shadow-[#0044AB]/50 hover:text-white hover:bg-primary-800'}`}
                      disabled={disabled}
                
                >
                    Save
                  </button> */}
 <button
          onClick={handleBusinessDetails}
          disabled={isDisabled}
          className={`hover:bg-[#0044AB] border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-sm font-semibold hover:border-transparent hover:shadow-lg hover:shadow-[#0044AB]/50 text-center hover:text-white rounded-lg focus:ring-4 focus:ring-primary-200 hover:bg-primary-800 ${
            isDisabled ? 'hover:bg-gray-300 bg-gray-300 text-gray-600 border-gray-400 cursor-not-allowed' : 'hover:bg-[#0044AB] border-[#3479E0] border-2 hover:text-white hover:shadow-lg hover:shadow-[#0044AB]/50'
          }`}
        >
          Save
        </button>
                    </>
           
            </div>
        </div>

    </div>
  )
}

export default CreateOwner
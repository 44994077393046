// navigationSlice.js
import { createSlice } from '@reduxjs/toolkit';

const navigationSlice = createSlice({
  name: 'navigation',
  initialState: {
    navigationState: null,
  },
  reducers: {
    setNavigationState: (state, action) => {
      state.navigationState = action.payload;
    },
    clearNavigationState: (state) => {
      state.navigationState = null;
    },
  },
});

export const { setNavigationState, clearNavigationState } = navigationSlice.actions;

export default navigationSlice.reducer;
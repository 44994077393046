import React, {useState} from 'react'
import { useNavigate, Link } from "react-router-dom";
import PurchaseReport from './PurchaseReport';
import Sales from './Sales/Sales';
import InvoiceReport from './Invoice/InvoiceReport';
import PricingView from './PricingView';
import PricingEdit from './PricingEdit';
import { useLocation , useNavigation} from "react-router-dom";

function PricingTablePage() {
      const [activeItem, setActiveItem] = useState("View");
const location = useLocation();
const navigate = useNavigate()
const invoiceDate = location.state?.invoiceDate || "";
const customer = location.state?.customer || "";

const customer_id = location.state?.customer_id ;

const businessDate = location.state?.businessDate || "";

      const renderPage = () => {
        switch (activeItem) {
          case "View":
            return <PricingView 
            invoiceDate={invoiceDate} 
           
            customerName={customer} 
            customerId={customer}
            businessDate={businessDate}
            customer_id={customer_id}
            />;
          case "Edit":
            return <PricingEdit   
            invoiceDate={invoiceDate} 
           
            customerName={customer} 
            customerId={customer}
            businessDate={businessDate}
            customer_id={customer_id}
            
            />;
            return <div/>
            case "Back":
                navigate("/pricing");
                return null; // Prevent rendering anything after navigation
              case "Home":
                navigate("/");
                return null;

          default:
            return <PricingView/>;
        }
      };
    
          const handleMenuClick = (item) => {
            setActiveItem(item);
          };

  return (
    <div 
    style={{
      boxShadow: '0 0 20px 0 rgba(0, 0, 0,.2)',
    }}
    className='w-[50vw] m-auto bg-white'
    >
       <div className="flex justify-center p-4 rounded-md font-bold text-md items-center w-full mx-auto py-2 bg-white mt-3 ">
      {/* View */}
      <div
        className={`flex items-center cursor-pointer px-4 py-1 ${
          activeItem === "View" ? "border-b-2 border-green-500 text-green-600" : "text-gray-600"
        } border-b border-gray-300  `}
        onClick={() => {handleMenuClick("View"); }}
      >
        {/* <FaEye className="mr-2" /> */}
        <span>View</span>
      </div>

      <div
        className={`flex items-center cursor-pointer px-4 py-1 ${
          activeItem === "Edit" ? "border-b-2 border-green-500 text-green-600" : "text-gray-600"
        } border-b border-gray-300 `}
        onClick={() =>{handleMenuClick("Edit"); }}
      >
        {/* <FaDownload className="mr-2" /> */}
        <span>Edit</span>
      </div>
      <div
        className={`flex items-center cursor-pointer px-4 py-1 ${
          activeItem === "Back" ? "border-b-2 border-green-500 text-green-600" : "text-gray-600"
        } border-b border-gray-300 `}
        onClick={() =>{handleMenuClick("Back");}}
      >
        {/* <FaDownload className="mr-2" /> */}
        <span>Back</span>
      </div>
      <div
        className={`flex items-center cursor-pointer px-4 py-1 ${
          activeItem === "Home" ? "border-b-2 border-green-500 text-green-600" : "text-gray-600"
        } border-b border-gray-300 `}
        onClick={() =>{handleMenuClick("Home");}}
      >
        {/* <FaDownload className="mr-2" /> */}
        <span>Home</span>
      </div>

    </div>

      {/* Main Content */}
      <div className="w-full p-4">
        {renderPage()}
      </div>
    </div>
  )
}

export default PricingTablePage

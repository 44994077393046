import React, { useState, useRef, useEffect } from "react";
import { Box, Paper } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import { useSelector, useDispatch } from "react-redux";
import { Grid, IconButton } from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PrintIcon from "@mui/icons-material/Print";
import DescriptionIcon from "@mui/icons-material/Description";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import axios from "axios";
import { Tooltip } from "react-tippy";
import config from "../../config/config";
import FullScreenLoader from "./FullScreenLoader";
import { addToast } from "./ToastBox/ToastBoxMessageSlice";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { FaHome } from "react-icons/fa";

const PurchaseReport = () => {
  const businessId = localStorage.getItem("business_id");
  const managerId = localStorage.getItem("user_id");
   const today = dayjs(); // Get today's date
    const businessDate = localStorage.getItem('business_date')
    const [selectedDate, setSelectedDate] = useState(today); 
  const baseURL =
    process.env.NODE_ENV === "production"
      ? config.production.baseURL
      : config.development.baseURL;
  const [periodType, setPeriodType] = useState("");
  const [tempperiodType, settempPeriodType] = useState(""); 
  const [selectedYear, setSelectedYear] = useState("");
  const [tempYear, setTempYear] = useState(""); 
  const [selectedMonth, setSelectedMonth] = useState(""); 
  const [tempMonth, setTempMonth] = useState(""); 
  const [tempFuelGrade, settempFuelGrade] = useState(""); 
  const [brandId, setBrandId] = useState("");
  const navigate = useNavigate();
  const [selectedQuarter, setSelectedQuarter] = useState("");
  const [tempQuarter, setTempQuarter] = useState("");
  const [brand, setBrand] = useState("");
  const [tempcustomer, setTempCustomer] = useState("");
  const [fuelGrade, setFuelGrade] = useState("");
  const [brandName, setBrandName] = useState("");
  const [brands, setBrands] = useState([]);
  const [isBrandSelected, setIsBrandSelected] = useState(false);
  const customersAll = useSelector((state) => state.customer.allCustomers);
  const dispatch = useDispatch();
  const [dateTime, setDateTime] = useState("");
  const [salesResponse, setSalesReport] = useState([]);
  const [imageSource, setImageSource] = useState();
  const [imageResponse, setimageResponse] = useState([]);
  const [loading, setLoading] = useState(true); // Add a loading state
  console.log(loading, "loading1");
  const [pdfhovered, setPdfHovered] = useState(false);
  const [taxhovered, setTaxHovered] = useState(false);
  const [productData, setProductData] = useState([]);
  const [notFound, setNotFound] = useState(false);
  const [tempSelectedBrandText, setTempSelectedBrandText] = useState("All Brands");
  const [RunClicked, setRunClicked] = useState(false); 
  const user_id = localStorage.getItem("user_id");
  const user_role = localStorage.getItem("user_role");
  const business_id = localStorage.getItem("business_id");
  const contentRef = useRef();
  const [userRole, setUserRole] = useState("");
  const from = salesResponse.data?.from;
  const to = salesResponse.data?.to;
   const [sellerNames, setSellerNames] = useState([]);

  useEffect(() => {
    const fetchSellersNames = async () => {
      try {
        const response = await axios.get(
          `${baseURL}${btoa("invoice/allsellers")}`
        );
        console.log("response", response);
        setSellerNames(response.data);
        console.log(sellerNames, "sellernames");
      } catch (error) {
        console.log("error fetching seller names", error);
      }
    };
    fetchSellersNames();
    console.log("setSellerNames", sellerNames);
  }, []);
  const formatToTwoDecimalsWithCommas = (num) => {
    const parts = num.toString().split(".");
    const integerPartWithCommas = parseInt(parts[0], 10).toLocaleString(
      "en-US"
    ); // Adds commas to the integer part
    const decimalPart = parts[1] ? parts[1].substring(0, 2) : ""; // Limits to two decimal places without rounding

    return decimalPart
      ? `${integerPartWithCommas}.${decimalPart}`
      : integerPartWithCommas;
  };
  const generateUSDateTime = () => {
    setRunClicked(true);
    const currentDate = new Date();
   
    const formattedDateTime = currentDate.toLocaleString("en-US", {
      year: "numeric",
      month: "numeric",
      day: "numeric"
    });
    setDateTime(formattedDateTime);
  };
  function formatNumberwithoutzeros(num) {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(num);
  }
   const handlePdfDownload = async () => {
    const brandNamePdf = selectedText === "All Brands" ? "all" : selectedText;
    const selectedMonthPdf = selectedMonth === "" ? null : selectedMonth;
    const selectedQuarterPdf = selectedQuarter === "" ? null : selectedQuarter;

       let filename = "";

    if (periodType === "Monthly") {
      filename = `${selectedMonth} ${selectedYear} - Purchases - ${selectedText}`;
    } else if (periodType === "Quarterly") {
      const quarterDesc = quarters
        .find((q) => q.bName === selectedQuarter)
        ?.fName.replace("Q", "Q")
        .replace(" ", " (")
        .replace(")", ")");
      filename = `${quarterDesc} ${selectedYear} - Purchases - ${selectedText}`;
    } else if (periodType === "Yearly") {
      filename = `${selectedYear} Year - Purchases - ${selectedText}`;
    }
    filename = `${filename}.pdf`;

    try {
      const response = await axios.get(
        `${baseURL}/${btoa("purchase/download_purchasedinvoicespdf")}/${btoa(
          businessId
        )}/${btoa(managerId)}/${btoa(selectedBrandName)}/${btoa(
          brandNamePdf
        )}/${btoa(null)}/${btoa(periodType)}/${btoa(selectedYear)}/${btoa(
          selectedMonthPdf
        )}/${btoa(selectedQuarterPdf)}`,
        { responseType: "blob" }
      );

      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.log("Error downloading invoice:", error);
    }
  };

  const handleExcelDownload = async () => {
    const brandNameExcel = selectedText === "All Brands" ? "all" : selectedText;
    const selectedMonthExcel = selectedMonth === "" ? null : selectedMonth;
    const selectedQuarterExcel =
      selectedQuarter === "" ? null : selectedQuarter;

    // Construct filename with the same format as PDF
    let filename = "";

    if (periodType === "Monthly") {
      filename = `${selectedMonth} ${selectedYear} - Purchases - ${selectedText}`;
    } else if (periodType === "Quarterly") {
      // Find the quarter description
      const quarterDesc = quarters
        .find((q) => q.bName === selectedQuarter)
        ?.fName.replace("Q", "Q")
        .replace(" ", " (")
        .replace(")", ")");
      filename = `${quarterDesc} ${selectedYear} - Purchases - ${selectedText}`;
    } else if (periodType === "Yearly") {
      filename = `${selectedYear} Year - Purchases - ${selectedText}`;
    }

    // Add Excel extension
    filename = `${filename}.xlsx`;

    try {
      const response = await axios.get(
        `${baseURL}/${btoa("purchase/download_purchasedinvoicesexcel")}/${btoa(
          businessId
        )}/${btoa(managerId)}/${btoa(selectedBrandName)}/${btoa(
          brandNameExcel
        )}/${btoa(null)}/${btoa(periodType)}/${btoa(selectedYear)}/${btoa(
          selectedMonthExcel
        )}/${btoa(selectedQuarterExcel)}`,
        { responseType: "blob" }
      );

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.log("Error downloading Excel:", error);
    }
  };

  const handlePrint = () => {
    const content = contentRef.current.innerHTML; // Get the HTML content
    const newWindow = window.open("", "_blank"); // Open a new window
    newWindow.document.write(`
      <html>
        <head>
          <title>Print</title>
          <style>
            /* Optional: Add styles for printed content */
                      @media print {
  img {
    display: block;
    width: 100px; /* Adjust width as needed */
    height: auto;
  }
              @page {
                margin: 0; /* Remove default margins */
              }
              body {
                margin: 0;
                padding: 0;
              }
              /* Hide headers and footers */
              header, footer {
                display: none; /* Hides header and footer */
              }
              /* You can also add styles to control the printed content */

            body {
              font-family: Arial, sans-serif;
              margin: 30px;
            }
          </style>
        </head>
        <body>
          ${content} 
        </body>
      </html>
    `);
    newWindow.document.close(); // Close the document to apply styles
    newWindow.print(); // Trigger the print dialog
  };
  function formatNumber(num) {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(num);
  }

  useEffect(() => {
    setUserRole(localStorage.getItem("user_role"));
  }, []);

  useEffect(() => {
    axios
      .get(
        `${baseURL}/${btoa("business/profile")}/${btoa(business_id)}/${btoa(
          user_id
        )}/${btoa(user_role)}`
      )
      .then((response) => {
        console.log(response);
        // console.log("response businesstype", response);
        setImageSource(response.data.business_data[0].image_file);
        setimageResponse(response.data.business_data[0]);
      })
      .catch((error) => {
        console.error("Error fetching business data:", error);
      });
    console.log(imageResponse);
  }, []);

    const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];
  const quarters = [
    { bName: "Q1", fName: "Q1 (Mar-May)" },
    { bName: "Q2", fName: "Q2 (June-Aug)" },
    { bName: "Q3", fName: "Q3 (Sep-Nov)" },
    { bName: "Q4", fName: "Q4 (Dec-Feb)" }
  ];

  const datePickerRef = useRef(null);

  const handleFuelChange = (event) => {
    setFuelGrade(event.target.value);
  };

   const handlePeriodChange = (event) => {
    const newPeriodType = event.target.value;
    setPeriodType(newPeriodType);

    if (newPeriodType === "Yearly") {
      setSelectedMonth("");
      setSelectedQuarter("");
    } else if (newPeriodType === "Monthly") {
      setSelectedQuarter("");
    } else if (newPeriodType === "Quarterly") {
      setSelectedMonth("");
    } else if (newPeriodType === "CustomRange") {
      setSelectedYear("");
      setSelectedMonth("");
      setSelectedQuarter("");
      setStartDate(null);
      setEndDate(null);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get(`${baseURL}/${btoa("product/view")}`)
      .then((response) => {
        setProductData(response.data.products_data);
      })
      .catch((error) => {
        console.error("Error fetching customer data:", error);
      });
  }, []);
  const [selectedText, setSelectedText] = useState("All Brands");
  const [selectedBrandName, setSelectedBrandName] = useState("all");
  const handleChangeFuelBrands = (e) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    setSelectedBrandName(selectedOption.value);
    setSelectedText(selectedOption.text);
  };

  const [years, setYears] = useState([]);
  useEffect(() => {
    setSelectedYear(new Date().getFullYear().toString());
  }, []);
 
  useEffect(() => {
    console.log("Fetching business profile data...");

    axios
      .get(
        `${baseURL}/${btoa("business/profile")}/${btoa(business_id)}/${btoa(
          user_id
        )}/${btoa(user_role)}`
      )
      .then((response) => {
        console.log("API response received");

        try {
          const addedOnDate = response.data.business_data[0].added_on;
          console.log("Raw added_on date:", addedOnDate);

          const startYear = parseInt(addedOnDate.substring(0, 4));
          console.log("Extracted start year:", startYear);

          if (isNaN(startYear) || startYear < 2000 || startYear > 2100) {
            console.error("Invalid year value:", startYear);
            return;
          }

          const currentYear = new Date().getFullYear();
          const yearArray = [];

          for (let year = currentYear; year >= startYear; year--) {
            yearArray.push(year);
          }

          console.log("Generated years array:", yearArray);

          setYears(yearArray);
        } catch (error) {
          console.error("Error processing business data:", error);
        }
      })
      .catch((error) => {
        console.error("Error fetching business data:", error);
      });
  }, []);
  // const handleBrandChange = (event) => {
  //   const selectedBrandId = event.target.value;
  //   console.log("Selected brand ID:", selectedBrandId);

  //   // First clear any previous selection
  //   if (selectedBrandId === "") {
  //     setBrandId("");
  //     setBrandName("");
  //     setIsBrandSelected(false);
  //     console.log("No brand selected");
  //     return;
  //   }

  //   // Handle "all" selection
  //   if (selectedBrandId === "all") {
  //     console.log("All brands selected");
  //     setBrandId("all");
  //     setBrandName("All Brands");
  //     setIsBrandSelected(true);
  //     // Remove automatic year selection
  //     // setSelectedYear(new Date().getFullYear().toString());
  //   } else {
  //     // For specific brand selection
  //     const selectedBrand = brands.find(
  //       (brand) => String(brand.id) === String(selectedBrandId)
  //     );

  //     if (selectedBrand) {
  //       console.log("Found selected brand:", selectedBrand.seller_name);
  //       setBrandId(selectedBrandId);
  //       setBrandName(selectedBrand.seller_name);
  //       setIsBrandSelected(true);
  //       // Remove automatic year selection
  //       // setSelectedYear(new Date().getFullYear().toString());
  //     } else {
  //       console.error("Brand not found for ID:", selectedBrandId);
  //       setBrandId("");
  //       setBrandName("");
  //       setIsBrandSelected(false);
  //     }
  //   }

  //   // Reset period selections but keep year setting null
  //   setPeriodType("");
  //   setSelectedYear("");
  //   setSelectedMonth("");
  //   setSelectedQuarter("");
  // };


  const handleBrandChange = (event) => {
    const selectedBrandId = event.target.value;
    console.log("Selected brand ID:", selectedBrandId);
  
    if (selectedBrandId === "") {
      setBrandId("");
      setBrandName("");
      setSelectedText(""); 
      setIsBrandSelected(false);
      console.log("No brand selected");
      return;
    }
  
    if (selectedBrandId === "all") {
      console.log("All brands selected");
      setBrandId("all");
      setBrandName("All Brands");
      setSelectedText("All Brands"); 
      setIsBrandSelected(true);
    } else {
      const selectedBrand = brands.find(
        (brand) => String(brand.id) === String(selectedBrandId)
      );
  
      if (selectedBrand) {
        console.log("Found selected brand:", selectedBrand.seller_name);
        setBrandId(selectedBrandId);
        const brandDisplayName = selectedBrand.seller_name.charAt(0).toUpperCase() + 
                                selectedBrand.seller_name.slice(1).toLowerCase();
        setBrandName(brandDisplayName);
        setSelectedText(brandDisplayName); 
        setIsBrandSelected(true);
      } else {
        console.error("Brand not found for ID:", selectedBrandId);
        setBrandId("");
        setBrandName("");
        setSelectedText(""); 
        setIsBrandSelected(false);
      }
    }
  
    setPeriodType("");
    setSelectedYear("");
    setSelectedMonth("");
    setSelectedQuarter("");
  };

  const formatDate = (date) => {
    if (!date) return "";

    if (date instanceof Date) {
      return `${(date.getMonth() + 1).toString().padStart(2, "0")}/${date
        .getDate()
        .toString()
        .padStart(2, "0")}/${date.getFullYear()}`;
    }

    if (typeof date === "string") {
      const parts = date.split("-");
      if (parts.length !== 3) return date;
      return `${parts[1]}/${parts[2]}/${parts[0]}`;
    }

    return String(date);
  };

  const formatDateForAPI = (date) => {
    if (!date || !(date instanceof Date)) return null;
    return `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
  };

  const handleRun = async () => {
    setRunClicked(true);
    setLoading(true); // Start loader
    setTempYear(selectedYear);
    setTempMonth(selectedMonth);
    setTempQuarter(selectedQuarter);
    settempPeriodType(periodType);
    settempFuelGrade(fuelGrade);
    setTempCustomer(brand);
    setNotFound(false);
    setTempSelectedBrandText(brandName);
    const formattedStartDate = startDate ? formatDateForAPI(startDate) : null;
    const formattedEndDate = endDate ? formatDateForAPI(endDate) : null;

    try {
      const response = await axios.post(
        `${baseURL}/${btoa("purchase/viewpurchasedinvoicesfilter")}`,
        {
          reportType: periodType,
          monthName: selectedMonth,
          quarterName: selectedQuarter,
          fuelGradeName: null,
          year: selectedYear,
          businessId: localStorage.getItem("business_id"),
          managerId: localStorage.getItem("user_id"),
          brandId: selectedBrandName,
          brandName: selectedText === "All Brands" ? "all" : selectedText,
          userRole: userRole,
          startDate: formattedStartDate,
          endDate: formattedEndDate
        }
      );

      console.log("response.....", response);
      console.log("response.....", response);
      setSalesReport(response);
    } catch (error) {
      console.error("Error posting details:", error);
    } finally {
      setLoading(false); // Stop loader after data fetch
    }
  };

  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        // Trigger background when scrolling starts
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

 
  const handleKeyPress = (e) => {
    if (e.key === "Enter" && isButtonEnabled()) {
      handleRun();
    }
  };
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
   const themeColor = "rgb(231 70 148)";
  const labelStyle =
    "block mb-2 text-[14px] font-medium text-gray-600 inline-flex items-center";
  const inputStyle =
    "border border-gray-300 text-sm font-medium rounded-lg focus:ring-grey-600 focus:border-primary-600 block w-full text-gray-500 py-1 px-2 h-[30px]";
  const buttonStyle = {
    backgroundColor: themeColor,
    color: "white",
    marginTop: "34px",
    height: "30px",
    "&:hover": {
      backgroundColor: "rgb(231 70 148)"
    }
  };

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        // Use the specific API endpoint for fetching brands
        const response = await axios.post(
          `${baseURL}/cmVwb3J0cy9yZXBvcnRicmFuZA==`,
          { businessId: businessId }
        );
        console.log("Fetched brands:", response.data);
        setBrands(response.data);
      } catch (error) {
        console.error("Error fetching brands:", error);
        dispatch(
          addToast({
            type: "error",
            message: `Error fetching brands: ${error.message}`
          })
        );
      }
    };
    fetchBrands();
  }, []);

   const isButtonEnabled = () => {
    if (!brandId) return false;

    if (!periodType) return false;

    if (periodType === "Yearly" && selectedYear) return true;
    if (periodType === "Monthly" && selectedYear && selectedMonth) return true;
    if (periodType === "Quarterly" && selectedYear && selectedQuarter)
      return true;

    if (periodType === "CustomRange") {
      if (!startDate || !endDate) return false;

      const diffTime = Math.abs(endDate - startDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      return diffDays <= 29;
    }

    return false;
  };

  const datepickerStyle =
    "text-center flex justify-center border border-gray-300 text-[14px] font-medium text-gray-600 rounded-lg focus:ring-grey-600 focus:border-primary-600 block w-full customReportDatePicker h-[30px] ";

  return (
    <div className="ml-5 ">
      <>
        <div
          className="flex justify-between items-center w-full  pt-4 mt-4 mb-5 "
          style={{
            maxWidth: "1200px",
            boxSizing: "border-box",
            marginLeft: "4px",
            color: "#444444" // Lighter black color
          }}
        >
          {/* <h1 className="font-bold text-xl ">Gas Purchase Report</h1> */}
          <nav aria-label="breadcrumb">
            <ol className="flex space-x-2 text-lg font-bold text-gray-700">
              <li>
                {/* <a  href="/operations" className="text-blue-600 hover:text-blue-800"> */}
                Reports
                {/* </a> */}
              </li>
              <li>
                <span className="text-gray-400">{">>"}</span>
              </li>
              <li>
                {/* <a href="/purchases" className="text-blue-600 hover:text-blue-800"> */}
                Purchases From Branded
                {/* </a> */}
              </li>
            </ol>
          </nav>
        </div>

        <div
          className="relative py-[12px] px-[8px] rounded-xl bg-white"
          style={{ boxShadow: "0 0 25px 0 rgba(0, 0, 0, 0.2)" }}
        >
          <div className="flex items-center justify-end">
            {/* Home button - bordered/unfilled by default, filled blue on hover */}
            <div
              className="absolute -top-4 flex items-center justify-center cursor-pointer rounded-full bg-[#cefafe] hover:bg-[#25caed] w-9 h-9 mb-0 mr-6 border-[0.5px] border-[#25caed] hover:border-[#25caed] hover:scale-110 shadow-md transition-all duration-200 group"
              onClick={() => {
                navigate("/");
              }}
              style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
            >
              <FaHome
                className="text-[#25caed] group-hover:text-white transition-colors duration-200"
                size={16}
              />
            </div>

            {/* Close Icon - bordered/unfilled by default, filled red on hover */}
            <div
              className="absolute -top-4 -right-2 bg-red-200 hover:bg-red-500 w-9 h-9 flex items-center justify-center rounded-full cursor-pointer hover:bg-red-700 border-[0.5px] border-red-500 hover:border-red-700 hover:scale-110 shadow-md transition-all duration-200 group"
              onClick={() => navigate("/HomeReports")}
            >
              <span className="text-red-500 group-hover:text-white text-xl font-bold mb-1.5 transition-colors duration-200">
                &times;
              </span>
            </div>
          </div>
        
          <Grid
            container
            spacing={3}
            style={{ display: "flex", alignItems: "center" }}
            className="pl-[10px] pr-6 "
          >
            <Grid item md={2.1}>
              <div className="flex-grow">
                <label
                  for="Select Year"
                  className="block mb-2 text-[14px] font-medium text-gray-600 inline-flex items-center "
                >
                  Select Brand
                  <span className="text-red-500 text-xl font-extrabold pl-1">
                    *
                  </span>
                </label>
                <select
                  id="brandId"
                  value={brandId}
                  onChange={handleBrandChange}
                  onKeyPress={handleKeyPress}
                  className={inputStyle}
                >
                  <option value="">--Select--</option>
                  <option value="all">All Brands</option>
                  {brands.map((brand) => (
                    <option key={brand.id} value={String(brand.id)}>
                      {brand.seller_name.charAt(0).toUpperCase() +
                        brand.seller_name.slice(1).toLowerCase()}
                    </option>
                  ))}
                </select>              
                   </div>
            </Grid>
            {isBrandSelected && (
              <Grid item md={2.4} xs={12}>
                <div>
                  <label
                    for="Select Report Type"
                    className="block mb-2 text-[14px] font-medium text-gray-600 inline-flex items-center  "
                  >
                    Duration
                    <span className="text-red-500 text-xl font-extrabold pl-1">
                      *
                    </span>
                  </label>
                  <select
                    id="Select Report Type"
                    name="Select Report Type"
                    required
                    onChange={handlePeriodChange}
                    onKeyPress={handleKeyPress}
                    value={periodType}
                    className=" border border-gray-300 text-sm font-medium rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full text-gray-500 py-1 px-2 h-[30px]"
                  >
                    <option value="">Select Report Type</option>
                    <option value="Monthly">Monthly</option>
                    <option value="Quarterly">Quarterly</option>
                    <option value="Yearly">Yearly</option>
                    <option value="CustomRange">Custom Range</option>
                  </select>
                </div>
              </Grid>
            )}
            {isBrandSelected && periodType === "Monthly" && (
              <Grid item md={2.1}>
                <div>
                  <label
                    for="Select Month"
                    className="block mb-2 text-[14px] font-medium text-gray-600 inline-flex items-center  "
                  >
                    Select Month
                    <span className="text-red-500 text-xl font-extrabold pl-1">
                      *
                    </span>
                  </label>
                  <select
                    id="Select Month"
                    name="Select Month"
                    required
                    value={selectedMonth}
                    onChange={(e) => {
                      setSelectedMonth(e.target.value);
                    }}
                    label="Select Month"
                    // className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
                    className=" border border-gray-300 text-sm font-medium rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full text-gray-500 py-1 px-2 h-[30px]"
                  >
                    <option value="">--Select Month--</option>
                    {/* <option value="">Select Month</option> */}

                    {months.map((month) => (
                      <option
                        key={month}
                        value={month}
                        sx={{ margin: 0, padding: "1px 16px" }}
                      >
                        {month}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
            )}
            {isBrandSelected && periodType === "Quarterly" && (
              <Grid item md={2.1}>
                <div>
                  <label
                    for="Select Quarter"
                    className="block mb-2 text-[14px] font-medium text-gray-600 inline-flex items-center  "
                  >
                    Select Quarter
                    <span className="text-red-500 text-xl font-extrabold pl-1">
                      *
                    </span>
                  </label>
                  <select
                    id="Select Quarter"
                    name="Select Quarter"
                    required
                    value={selectedQuarter}
                    onChange={(e) => {
                      setSelectedQuarter(e.target.value);
                    }}
                    label="Select Quarter"
                    className=" border border-gray-300 text-sm font-medium rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full text-gray-500 py-1 px-2 h-[30px]"
                  >
                    <option value="">--Select Quarter--</option>
                    {/* <option value="">Select Quarter</option> */}

                    {quarters.map((quarter) => (
                      <option
                        key={quarter.fName}
                        value={quarter.bName}
                        sx={{ margin: 0, padding: "1px 16px" }}
                      >
                        {quarter.fName}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
            )}
            {((isBrandSelected && periodType === "Yearly") ||
              (isBrandSelected && periodType === "Monthly") ||
              (isBrandSelected && periodType === "Quarterly")) && (
              <Grid item md={2.1}>
                <div>
                  <label
                    for="Select Year"
                    className="block mb-2 text-[14px] font-medium text-gray-600 inline-flex items-center  "
                  >
                    Select Year
                    <span className="text-red-500 text-xl font-extrabold pl-1">
                      *
                    </span>
                  </label>
                  <select
                    id="Select Year"
                    name="Select Year"
                    required
                    value={selectedYear}
                    onChange={(e) => {
                      setSelectedYear(e.target.value);
                    }}
                    label="Select Year"
                    className=" border border-gray-300 text-sm font-medium rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full text-gray-500 py-1 px-2 h-[30px]"
                  >
                    <option value="">--Select Year--</option>
                    {years.map((year) => (
                      <option
                        key={year}
                        value={year}
                        sx={{ margin: 0, padding: "1px 16px" }}
                      >
                        {year}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
            )}
        

            {isBrandSelected && periodType === "CustomRange" && (
              <>
                <Grid item md={2.4} xs={12}>
                  <div>
                    <label className={labelStyle}>
                      From Date{" "}
                      <span className="text-red-500 text-xl font-extrabold pl-1">
                        *
                      </span>
                    </label>
                    {/* <DatePicker
                      id="mypodatepicker"
                      value={startDate ? dayjs(startDate) : null}
                      format="MM/DD/YYYY"
                      className={datepickerStyle}
                      onChange={(date) => {
                        const newStartDate = date ? date.toDate() : null;
                        setStartDate(newStartDate);

                        // If end date exists, validate the range
                        if (newStartDate && endDate) {
                          // Calculate difference in days
                          const diffTime = Math.abs(endDate - newStartDate);
                          const diffDays = Math.ceil(
                            diffTime / (1000 * 60 * 60 * 24)
                          );

                          // If range exceeds 29 days, reset end date
                          if (diffDays > 29) {
                            setEndDate(null);
                          }
                        }
                      }}
                      allowClear={true}
                      placeholder="select date"
                    /> */}
                    <DatePicker
                      id="mypodatepicker"
                      value={startDate ? dayjs(startDate) : null}
                      format="MM/DD/YYYY"
                      className={datepickerStyle}
                      disabledDate={(current) => {
                        // Convert businessDate to dayjs object if it's not already
                        const businessDateObj = typeof businessDate === 'string' 
                          ? dayjs(businessDate) 
                          : dayjs(businessDate);
                        
                        // Disable dates before businessDate or after today
                        return (
                          current.isBefore(businessDateObj, 'day') || 
                          current.isAfter(today, 'day')
                        );
                      }}
                      onChange={(date) => {
                        // Ensure we get the exact date selected
                        const selectedDate = date
                          ? date.startOf("day").toDate()
                          : null;
                        setStartDate(selectedDate);
                    
                        // If end date exists and is now invalid (more than 29 days or before start date), reset it
                        if (endDate && selectedDate) {
                          const daysDiff = Math.floor(
                            (endDate - selectedDate) / (1000 * 60 * 60 * 24)
                          );
                          if (daysDiff > 29 || daysDiff < 0) {
                            setEndDate(null);
                          }
                        }
                      }}
                      placeholder="select date"
                    />
                  </div>
                </Grid>
                <Grid item md={2.4} xs={12}>
                  <div>
                    <label className={labelStyle}>
                      To Date{" "}
                      <span className="text-red-500 text-xl font-extrabold pl-1">
                        *
                      </span>
                    </label>
                    {/* <DatePicker
                      id="mypodatepicker"
                      value={endDate ? dayjs(endDate) : null}
                      format="MM/DD/YYYY"
                      className={datepickerStyle}
                      onChange={(date) => {
                        const newEndDate = date ? date.toDate() : null;

                        // Only set the end date if it's within 29 days of start date
                        if (newEndDate && startDate) {
                          const diffTime = Math.abs(newEndDate - startDate);
                          const diffDays = Math.ceil(
                            diffTime / (1000 * 60 * 60 * 24)
                          );

                          if (diffDays <= 29) {
                            setEndDate(newEndDate);
                          } else {
                            // Show a notification or alert about the 29-day limit
                            alert("Date range cannot exceed 29 days");
                          }
                        } else {
                          setEndDate(newEndDate);
                        }
                      }}
                      allowClear={true}
                      placeholder="select date"
                      disabledDate={(current) => {
                        // Disable dates before start date
                        if (!startDate) return false;

                        // Disable dates more than 29 days after start date
                        const startDateObj = dayjs(startDate);
                        const maxDateObj = startDateObj.add(29, "day");
                        return (
                          current &&
                          (current < startDateObj || current > maxDateObj)
                        );
                      }}
                    /> */}

<DatePicker
  id="mypodatepicker"
  value={endDate ? dayjs(endDate) : null}
  format="MM/DD/YYYY"
  className={datepickerStyle}
  onChange={(date) =>
    setEndDate(date ? date.startOf("day").toDate() : null)
  }
  placeholder="select date"
  disabledDate={(current) => {
    // First apply the same business date and today constraints
    const businessDateObj = typeof businessDate === 'string' 
      ? dayjs(businessDate) 
      : dayjs(businessDate);
    
    if (current.isBefore(businessDateObj, 'day') || current.isAfter(today, 'day')) {
      return true;
    }
    
    // Then apply the startDate and max range constraints
    if (!startDate || !current) {
      return false;
    }
    
    // Disable dates before start date
    const startDateObj = dayjs(startDate);
    if (current.isBefore(startDateObj, 'day')) {
      return true;
    }

    // Disable dates more than 29 days after start date
    const maxDate = startDateObj.add(29, 'day');
    return current.isAfter(maxDate, 'day');
  }}
/>


                  </div>
                </Grid>
              </>
            )}

            <Grid item>
              
              <Button
                variant="contained"
                disabled={!isButtonEnabled()} 
                sx={buttonStyle}
                endIcon={<SendIcon />}
                onClick={() => {
                  generateUSDateTime();
                  handleRun();
                }}
              >
                Run
              </Button>
            </Grid>
          </Grid>

          {/* </Paper> */}
          {/* </Box> */}
        </div>
        {RunClicked ? (
          <div id="runclicked">
            {RunClicked && (
              <div
                className="bg-white"
                style={{
                  borderRadius: "20px",
                  maxWidth: "100%",
                  overflowX: "auto",
                  margin: "0 auto",
                  flexDirection: "column",
                  boxShadow: "0 0 25px 0 rgba(0, 0, 0, 0.2)",
                  margin: "55px",
                  paddingBottom: "80px",
                  margin: "15px 0px 15px 0px",
                  marginTop: "45px",

                  padding: "20px",
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column"
                }}
              >
                {salesResponse.data &&
                salesResponse.data.purchasedInvoicesTotals &&
                salesResponse.data.purchasedInvoicesTotals.length > 0 ? (
                  <Grid container alignItems="end" justifyContent="end">
                    {/* PDF Download Icon */}
                    <Grid item xs={12} md={4}>
                      <Box p={2} className="font-bold text-md text-gray-400">
                        {RunClicked && <p>Report RunTime: {dateTime} </p>}
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <Grid container alignItems="end" justifyContent="end">
                        <Grid item>
                          <Tooltip
                            title="Pdf"
                            position="top"
                            trigger="mouseenter"
                          >
                            <IconButton
                              aria-label="Download PDF"
                              sx={{
                                backgroundColor: "transparent", // No background color initially
                                borderRadius: "50%", // Circular shape
                                padding: "12px", // Adjust padding for the circular button
                                "&:hover": {
                                  backgroundColor: "rgba(227, 140, 151, 0.3)" // Light background color on hover
                                },
                                transition: "background-color 0.3s ease" // Smooth transition effect
                              }}
                              onMouseEnter={() => setPdfHovered(true)}
                              onMouseLeave={() => setPdfHovered(false)}
                              onClick={handlePdfDownload}
                            >
                              {pdfhovered ? (
                                <FileDownloadIcon
                                  sx={{ fontSize: 20, color: "red" }}
                                />
                              ) : (
                                <PictureAsPdfIcon
                                  fontSize="small"
                                  color="error"
                                />
                              )}
                            </IconButton>
                          </Tooltip>
                        </Grid>
                        <Grid item>
                          <Tooltip
                            title="Print"
                            position="top"
                            trigger="mouseenter"
                          >
                            <IconButton
                              aria-label="Print"
                              onClick={handlePrint}
                              sx={{
                                backgroundColor: "transparent", // No background color initially
                                borderRadius: "50%", // Circular shape
                                padding: "12px", // Adjust padding for the circular button
                                "&:hover": {
                                  backgroundColor: "rgba(0, 0, 0, 0.1)" // Light background color on hover
                                },
                                transition: "background-color 0.3s ease" // Smooth transition effect
                              }}
                            >
                              <PrintIcon
                                fontSize="small"
                                sx={{ color: "black" }}
                              />
                            </IconButton>
                          </Tooltip>
                        </Grid>

                        {/* Tax File Icon */}
                        <Grid item>
                          <Tooltip
                            title="Excel"
                            position="top"
                            trigger="mouseenter"
                          >
                            {/* <IconButton aria-label="Tax File"
            sx={{
                backgroundColor: 'transparent', // No background color initially
                borderRadius: '50%', // Circular shape
                padding: '12px', // Adjust padding for the circular button
                '&:hover': {
                  backgroundColor: 'rgba(143, 191, 96, 0.3)', // Light background color on hover
                },
                transition: 'background-color 0.3s ease', // Smooth transition effect
              }}
              onMouseEnter={() => setTaxHovered(true)}
              onMouseLeave={() => setTaxHovered(false)}
        
        >
          {
            taxhovered ? <FileDownloadIcon sx={{ fontSize: 20, color: 'red' }} />
            :
            <DescriptionIcon fontSize="small" style={{ color: '#4CAF50' }} />

          }
        </IconButton> */}

                            <IconButton
                              aria-label="Tax File"
                              sx={{
                                backgroundColor: "transparent",
                                borderRadius: "50%",
                                padding: "12px",
                                "&:hover": {
                                  backgroundColor: "rgba(143, 191, 96, 0.3)"
                                },
                                transition: "background-color 0.3s ease"
                              }}
                              onMouseEnter={() => setTaxHovered(true)}
                              onMouseLeave={() => setTaxHovered(false)}
                            >
                              {taxhovered ? (
                                <FileDownloadIcon
                                  sx={{ fontSize: 20, color: "red" }}
                                />
                              ) : (
                                <DescriptionIcon
                                  fontSize="small"
                                  style={{ color: "#4CAF50" }}
                                />
                              )}
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  ""
                )}
                {/* <div className="flex justify-center"> */}

                <div
                  ref={contentRef}
                  style={{
                    width: "100%",
                    height: "auto",
                    justifyContent: "center",
                    alignSelf: "center",
                    borderRadius: "10px",
                    boxShadow: "0 0 20px 0 rgba(0, 0, 0, .1)",
                    padding: "20px",
                    marginTop: "20px"
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: "110px",
                      justifyContent: "space-between",
                      marginBottom: "20px",
                      backgroundColor: "rgba(180,180,180,0.1)"
                    }}
                  >
                    {/* Left Image */}
                    {/* <img
        src="https://via.placeholder.com/60"
        alt="Left Logo"
        style={{ width: '60px', // Set the size of the images
            height: '60px'}}
      /> */}
                    {/* <img src="/static/media/logo.0ea4b695a3030ea659a8.png" alt="flownia" className="w-max" ></img> */}
                    {userRole !== "1" && (
                      <div className="">
                        {imageSource && (
                          <img
                            style={{ width: "140px", height: "60px" }}
                            src={`${baseURL}uploads/img/business/${imageSource}`}
                            alt="Selected"
                          />
                        )}
                      </div>
                    )}

                    {/* Middle: Three Texts Stacked */}
                    <div style={{ textAlign: "center", marginLeft: "90px" }}>
                      <p>
                        <strong
                          style={{
                            fontWeight: "600",
                            fontSize: "18px",
                            margin: "5px"
                          }}
                        >
                          {imageResponse.business_name}
                        </strong>
                      </p>
                      <p>
                        <strong
                          style={{
                            fontWeight: "600",
                            fontSize: "18px",
                            margin: "5px"
                          }}
                        >
                          {imageResponse.business_address}
                        </strong>
                      </p>
                      <p>
                        <strong
                          style={{
                            fontWeight: "600",
                            fontSize: "18px",
                            margin: "5px"
                          }}
                        >
                          {imageResponse.business_city},{" "}
                          {imageResponse.business_state},{" "}
                          {imageResponse.business_zip_code}
                        </strong>
                      </p>
                    </div>

                    {/* Right Image */}
                    <img
                      src="/static/media/logo.0ea4b695a3030ea659a8.png"
                      alt="invoicefilelogo"
                      className="w-max"
                      style={{
                        width: "240px", // Set the size of the images
                        height: "200px"
                      }}
                    ></img>
                  </div>
                  
                  {/* <SalesReportTable salesResponse={salesResponse}/> */}
                  <>
                    {RunClicked && loading ? (
                      <FullScreenLoader /> // Show loader while fetching data
                    ) : RunClicked &&
                      salesResponse?.data?.purchasedInvoicesTotals?.length >
                        0 ? (
                          
                      <div style={{ marginTop: "14px" }}>
                        {/* {RunClicked && (
                    <div
                      style={{
                        color: "#1976d2",
                        textAlign: "center",
                        fontSize: "21px",
                        fontWeight: "bold",
                        marginTop: "50px"
                      }}
                      className="border-b border-gray-300 mb-1 mt-2"
                    >
                      {tempperiodType === "Yearly" &&
                        tempYear &&
                        RunClicked &&
                        `${tempYear} - Purchased - ${
                          salesResponse?.data?.brandName === "all"
                            ? "All "
                            : salesResponse?.data?.brandName
                        } Invoices `}
                      {tempperiodType === "Monthly" &&
                        tempYear &&
                        tempMonth &&
                        RunClicked &&
                        `${tempMonth} - ${tempYear} - Purchased - ${
                          salesResponse?.data?.brandName === "all"
                            ? "All "
                            : salesResponse?.data?.brandName
                        } Invoices `}
                      {tempperiodType === "Quarterly" &&
                        tempYear &&
                        tempQuarter &&
                        RunClicked &&
                        ` ${tempQuarter == "Q1" ? "Q1 (Mar-May)" : ""} ${
                          tempQuarter == "Q2" ? "Q2 (June-Aug)" : ""
                        } ${tempQuarter == "Q3" ? "Q3 (Sep-Nov)" : ""} ${
                          tempQuarter == "Q4" ? "Q4 (Dec-Feb)" : ""
                        } - Purchased - ${
                          salesResponse?.data?.brandName === "all"
                            ? "All "
                            : salesResponse?.data?.brandName
                        } Invoices  `}
                      
                      {tempperiodType === "CustomRange" &&
                        startDate &&
                        endDate &&
                        `${formatDate(startDate)} to ${formatDate(
                          endDate
                        )} - Purchased - ${
                          salesResponse?.data?.brandName === "all"
                            ? "All "
                            : salesResponse?.data?.brandName
                        } Invoices `}
                    </div>
                  )} */}


{RunClicked && (
  <div
    style={{
      color: "#1976d2",
      textAlign: "center",
      fontSize: "21px",
      fontWeight: "bold",
      marginTop: "50px"
    }}
    className="border-b border-gray-300 mb-1 mt-2"
  >
    {tempperiodType === "Yearly" &&
      tempYear && 
      `${tempYear} Year - Purchases - ${brandName}`}
      
    {tempperiodType === "Monthly" &&
      tempYear &&
      tempMonth &&
      `${tempMonth} ${tempYear} - Purchases - ${brandName}`}
      
    {tempperiodType === "Quarterly" &&
      tempYear &&
      tempQuarter &&
      `${quarters.find(q => q.bName === tempQuarter)?.fName || tempQuarter} ${tempYear} - Purchases - ${brandName}`}
      
    {tempperiodType === "CustomRange" &&
      startDate &&
      endDate &&
      `${formatDate(from)} to ${formatDate(to)} - Purchases - ${brandName}`}
  </div>
)}

                        <table
                          style={{
                            width: "50%",
                            maxWidth: "500px",
                            margin: "0px auto",
                            fontWeight: "normal",
                            borderCollapse: "collapse"
                          }}
                        >
                          {/* Header Row */}
                          <thead style={{ backgroundColor: "f5f5f5" }}>
                            <tr
                              style={{
                                color: "#1976d2",
                                padding: "10px",
                                textAlign: "left",
                                borderBottom: "1px solid #ddd",
                                maxWidth: "200px"
                              }}
                            >
                              <th
                                style={{
                                  padding: "10px",
                                  textAlign: "left",
                                  width: "150px",
                                  fontSize: "19px"
                                }}
                              >
                                Fuel Brand
                              </th>
                              <th
                                style={{
                                  padding: "10px",
                                  textAlign: "right",
                                  width: "150px",
                                  fontSize: "19px"
                                }}
                              >
                                Volume
                              </th>
                              <th
                                style={{
                                  padding: "10px",
                                  textAlign: "right",
                                  fontSize: "19px",
                                  width: "200px"
                                }}
                              >
                                Price
                              </th>
                            </tr>
                          </thead>

                          {/* Data Rows */}
                          <tbody>
                            {salesResponse.data.purchasedInvoicesTotals.map(
                              (item) => (
                                <tr
                                  key={item.product_id || item.product_name}
                                  style={{
                                    textAlign: "center",
                                    marginBottom: "10px"
                                  }}
                                >
                                  <td
                                    style={{
                                      padding: "10px",
                                      fontWeight: "lighter",
                                      textAlign: "left",
                                      fontSize: "15px", width: "100px",
                                    }}
                                  >
                                    {item.fuelgrade_name || ""}
                                  </td>
                                  <td
                                    style={{
                                      padding: "10px",
                                      fontWeight: "lighter",
                                      textAlign: "right",
                                      fontSize: "16px",
                                      width: "150px",
                                    }}
                                  >
                                    {`${formatNumberwithoutzeros(
                                      item.gross_quantity_total
                                    )} gl`}
                                  </td>
                                  <td
                                    style={{
                                      padding: "10px",
                                      fontWeight: "lighter",
                                      textAlign: "right",
                                      fontSize: "16px",
                                      whiteSpace: "nowrap",width: "200px"
                                    }}
                                  >
                                    $ {formatNumber(item.unit_total) || 0}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>

                          {/* Total Summary Row */}
                          <tfoot>
                            <tr
                              style={{
                                textAlign: "center",
                                padding: "10px",
                                margin: "20px auto",
                                borderRadius: "5px",
                                borderTop: "1px solid grey",
                                borderBottom: "1px solid grey",
                                backgroundColor: "#F5F5F5",width: "150px",
                              }}
                            >
                              <td
                                style={{
                                  padding: "10px",
                                  fontWeight: "lighter",
                                  textAlign: "left",
                                  fontSize: "16px", width: "100px",
                                }}
                              >
                                Total:
                              </td>
                              <td
                                style={{
                                  padding: "10px",
                                  fontWeight: "lighter",
                                  textAlign: "right",
                                  fontSize: "16px",
                                  whiteSpace: "nowrap",  width: "150px",
                                }}
                              >
                                {`${formatNumberwithoutzeros(
                                  salesResponse?.data.grossQuantitySum
                                )} gl`}
                              </td>
                              <td
                                style={{
                                  padding: "10px",
                                  fontWeight: "lighter",
                                  textAlign: "right",
                                  fontSize: "16px",width: "200px"
                                }}
                              >
                                ${" "}
                                {formatNumber(
                                  salesResponse?.data.unitTotalSum
                                ) || 0}
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    ) : (
                      RunClicked && !loading && (
                        <p className="text-red-600 text-lg font-bold text-center pt-5">No Data Available</p>
                      )
                    )}
                  </>
                </div>
              </div>
            )}
            <hr />
          </div>
        ) : (
          <div style={{ height: "300px" }}></div>
        )}
      </>
    </div>
  );
};

export default PurchaseReport;

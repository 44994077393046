// import React, { useState , useEffect} from 'react';
// import axios from 'axios';
// import { toast } from 'react-toastify';
// import { useNavigate } from 'react-router-dom';
// import {  FaEye, FaEyeSlash } from 'react-icons/fa';
// import config  from "../../config/config"
// import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';

// import { useDispatch, useSelector } from "react-redux";
// import { addToast } from './ToastBox/ToastBoxMessageSlice';

// const OwnerPasswordChangeModal = ({ isOpen, onClose }) => {
//     const dispatch = useDispatch();

//   const [currentPassword, setCurrentPassword] = useState('');
//   const [newPassword, setNewPassword] = useState('');
//   const [confirmNewPassword, setConfirmNewPassword] = useState('');

//   const [isPasswordVisible, setIsPasswordVisible] = useState(false);
//   const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false);
//   const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);
//   const [Toggle, setIsToggle] = useState(false);

//   const isValidInput = (value) => {
//     return /^[a-zA-Z0-9]{0,6}$/.test(value);
//   };
//   const [passwordMatch , setPasswordMatch] = useState()
//   const [ownerId , setOwnerId] = useState()

//     const navigate = useNavigate()
//     const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;

//   const business_id = localStorage.getItem("business_id")
//   const user_id = localStorage.getItem("user_id")
//   const user_role = localStorage.getItem("user_role")
//   console.log("user role is" , user_role);
//   console.log("bus id is " , business_id);
//   console.log("user id is " , user_id);
//   const handleCloseModal = () => {
//     console.log('Modal closed');
//     // setIsModalOpen(false);
//     setIsToggle(false)
//     document.body.style.overflow = '';
//   };

//   const CurrentPasswordTooltip = ({ content, show, marginLeft }) => {
//     return (
//       <div
//         style={{
//           display: show ? "block" : "none",
//           position: "absolute",
//           backgroundColor: "#ffffff",
//           color: "#fff",
//           padding: "5px",
//           borderRadius: "4px",
//           border: "1px solid red",
//           marginTop: "-92px",
//           marginLeft: `${marginLeft}px`,
//           boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
//         }}
//       >
//         <div
//           style={{
//             position: "absolute",
//             bottom: "-7px",
//             left: "50%",
//             marginLeft: "-10px",
//             width: "0",
//             height: "0",
//             borderLeft: "7px solid transparent",
//             borderRight: "7px solid transparent",
//             borderTop: "7px solid #f98080",
//           }}
//         ></div>
//         <p className="text-xs text-red-500">{content}</p>
//       </div>
//     );
//   };

//   const NewPasswordTooltip = ({ content, show, marginLeft }) => {
//     return (
//       <div
//         style={{
//           display: show ? "block" : "none",
//           position: "absolute",
//           backgroundColor: "#ffffff",
//           color: "#fff",
//           padding: "5px",
//           borderRadius: "4px",
//           border: "1px solid red",
//           marginTop: "-92px",
//           marginLeft: `${marginLeft}px`,
//           boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
//         }}
//       >
//         <div
//           style={{
//             position: "absolute",
//             bottom: "-7px",
//             left: "50%",
//             marginLeft: "-10px",
//             width: "0",
//             height: "0",
//             borderLeft: "7px solid transparent",
//             borderRight: "7px solid transparent",
//             borderTop: "7px solid #f98080",
//           }}
//         ></div>
//         <p className="text-xs text-red-500">{content}</p>
//       </div>
//     );
//   };

//   const ConfirmPasswordTooltip = ({ content, show, marginLeft }) => {
//     return (
//       <div
//         style={{
//           display: show ? "block" : "none",
//           position: "absolute",
//           backgroundColor: "#ffffff",
//           color: "#fff",
//           padding: "5px",
//           borderRadius: "4px",
//           border: "1px solid red",
//           marginTop: "-92px",
//           marginLeft: `${marginLeft}px`,
//           boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
//         }}
//       >
//         <div
//           style={{
//             position: "absolute",
//             bottom: "-7px",
//             left: "50%",
//             marginLeft: "-10px",
//             width: "0",
//             height: "0",
//             borderLeft: "7px solid transparent",
//             borderRight: "7px solid transparent",
//             borderTop: "7px solid #f98080",
//           }}
//         ></div>
//         <p className="text-xs text-red-500">{content}</p>
//       </div>
//     );
//   };

//   const ConfirmPasswordTooltip2 = ({ content, show, marginLeft }) => {
//     return (
//       <div
//         style={{
//           display: show ? "block" : "none",
//           position: "absolute",
//           backgroundColor: "#ffffff",
//           color: "#fff",
//           padding: "5px",
//           borderRadius: "4px",
//           border: "1px solid red",
//           marginTop: "-92px",
//           marginLeft: `${marginLeft}px`,
//           boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
//         }}
//       >
//         <div
//           style={{
//             position: "absolute",
//             bottom: "-7px",
//             left: "50%",
//             marginLeft: "-10px",
//             width: "0",
//             height: "0",
//             borderLeft: "7px solid transparent",
//             borderRight: "7px solid transparent",
//             borderTop: "7px solid #f98080",
//           }}
//         ></div>
//         <p className="text-xs text-red-500">{content}</p>
//       </div>
//     );
//   };

//   useEffect(() => {
//     axios
//       .get(`${baseURL}${btoa("business/profile")}/${btoa(business_id)}/${btoa(user_id)}/${btoa(user_role)}`)
//       .then((response) => {
//         console.log(response);
//         const businessData = response.data.business_data[0];
//         console.log(businessData);
    
//         setOwnerId(response.data.business_data[0].user_id)  
//       })
//       .catch((error) => {
//         console.error("Error fetching customer data:", error);
//       });
//   }, []);


//     const handleOwnerPasswordChange = async()=>{

//         if(newPassword !== confirmNewPassword){
//             setPasswordMatch("Password and confirm password does not match")
       
//             return
//         }

//         try{
//             const response = await axios.put(`${baseURL}${btoa("login/updateownerpassword")}`, {
//                 edit_ownerId: user_id,
//                 email : localStorage.getItem("userEmail"),
//                 edit_OldPassword: currentPassword,
//                 edit_NewPassword: newPassword
//             })
//             console.log(response);
//             navigate("/")
//             //  toast.success("Password changed, please login with the new password", {
//             //     duration: 2000,
//             //     autoClose : 2000

//             //     });
//             dispatch(addToast({ type: 'success', message: `Password changed, please login with the new password` }));        

//                 // Delay the page reload for 2 seconds
//                 setTimeout(() => {
//                     localStorage.clear();
//                     localStorage.clear();
//                     window.location.reload();
//                 }, 2000);
            
            
//         }catch(error){
//             console.log("error changing password" , error);
//         }
//     }

//     const isNumeric = (value) => {
//       return /^\d+$/.test(value);
//     };
//     const handlePasswordToggle =()=> {
//       setIsToggle((prev) => !prev);
    
//     }
//       const isSixDigits = (value) => {
//       return /^\d{0,6}$/.test(value);
//     };

//     return (
    
//          <div className="bg-white rounded shadow-md w-auto bg-white rounded-box rounded-xl m-8 mb-2 ">
//   <div className="flex items-center">
//               <SecurityOutlinedIcon className="text-gray-700 w-6 h-6 mr-2 mb-10" />
//               <h2 className="mb-10 text-xl font-bold text-gray-900 underline underline-offset-8 decoration-gray-300">
//                 Credentials
//               </h2>
//             </div>
//  <div className='flex'>

//             <br/>
//           <label className="block mb-2 text-gray-900" htmlFor="currentPassword">
//             Current Password:
//             </label>
         
        
//           </div>
//            {!Toggle &&
// <div className='flex'>
// <input
//    type={isPasswordVisible ? "text" : "text"}
//    pattern="[a-zA-Z0-9]{0,6}"
//    inputMode="numeric"
//   value="******"
//   // value={currentPassword}
//   // onChange={(e) => {
//   //   const inputValue = e.target.value;
//   //   if (isNumeric(inputValue) && isSixDigits(inputValue)) {
//   //     setCurrentPassword(inputValue);
//   //   } else if (inputValue === '') {
//   //     setCurrentPassword('');
//   //   }
//   // }}
//   className="w-full mb-4 p-2 border border-gray-300 bg-gray-300 rounded focus:outline-none focus:border-blue-500"
//   required
//   readOnly
// />
//    <label className="switch-toggle ml-4 flex items-center">
                
//    <input
//      type="checkbox"
//      onChange={handlePasswordToggle}
//      // checked={isSameAsAboveSelected}
//      id="sameAsAbove"
//      name="sameAsAbove"
//    />
//    <span className="slider-toggle round"></span>
//  </label>
//  </div>
// }
// {Toggle &&
//            <input
//               type={isPasswordVisible ? "text" : "password"}
//               pattern="[a-zA-Z0-9]{0,6}"
//               inputMode="numeric"
//              value={currentPassword}
//              onChange={(e) => {
//                const inputValue = e.target.value;
//                if (/^[a-zA-Z0-9]{0,6}$/.test(inputValue)) {
//                  setCurrentPassword(inputValue);
//                } else if (inputValue === '') {
//                  setCurrentPassword('');
//                }
//              }}
//              className="w-full mb-4 p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
//              required
//            />
//             }
//             {/* {currentPassword.length > 0  &&(
//                              <div
//                                className="absolute cursor-pointer"
//                                style={{
//                                  marginLeft : "355px",
//                                  marginTop : "-45px"
//                                }}
//                                onClick={() => setIsPasswordVisible(!isPasswordVisible)}
//                              >
//                                {isPasswordVisible ? <FaEyeSlash /> : <FaEye />}
//                              </div>
//              )} */}
 
//            {currentPassword.length > 0 && currentPassword.length < 6 && (
//            <CurrentPasswordTooltip
//            content="Password must be 6 digits"
//              show={true}
//              marginLeft={230}
//            />
//          )}
//          {currentPassword.length > 5 && !isValidInput (currentPassword) && (
//           <CurrentPasswordTooltip
//             content="Invalid password format"
//             show={true}
//             marginLeft={230}
//           />
//           // /^[0]{6}$/
//         )}
//  {Toggle &&
//  <>
//            <label className="block mb-2 text-gray-900" htmlFor="newPassword">
//              New Password:
//            </label>
//            <input
//               type={isNewPasswordVisible ? "text" : "password"}
//               pattern="[a-zA-Z0-9]{0,6}"
//               inputMode="numeric"
//              id="newPassword"
//              value={newPassword}
//              onChange={(e) => {
//                const inputValue = e.target.value;
//                if (/^[a-zA-Z0-9]{0,6}$/.test(inputValue)) {
//                  setNewPassword(inputValue);
//                } else if (inputValue === '') {
//                  setNewPassword('');
//                }
//              }}
//              className="w-full mb-4 p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
//              required
//            />
//            </>
//             }
//              {/* {newPassword.length > 0 &&(
//                              <div
//                                className="absolute cursor-pointer"
//                                style={{
//                                  marginLeft : "355px",
//                                  marginTop : "-45px"
//                                }}
//                                onClick={() => setIsNewPasswordVisible(!isNewPasswordVisible)}
//                              >
//                                {isNewPasswordVisible ? <FaEyeSlash /> : <FaEye />}
//                              </div>
//              )} */}
 
//            {newPassword.length > 0 && newPassword.length < 6 && (
//              <NewPasswordTooltip
//                content="Password must be 6 digits"
//                show={true}
//                marginLeft={230}
//              />
//            )}
//             {newPassword.length > 5 && !isValidInput (newPassword) && (
//             <NewPasswordTooltip
//               content="Invalid password format"
//               show={true}
//               marginLeft={230}
//             />
//           )}
//  {Toggle && <>
//            <label className="block mb-2space-x-4 text-gray-900" htmlFor="confirmNewPassword">
//              Confirm New Password:
//            </label>
//            <input
//                type={isConfirmPasswordVisible ? "text" : "password"}
//                pattern="[a-zA-Z0-9]{0,6}"
//                inputMode="numeric"
//               id="confirmNewPassword"
//              value={confirmNewPassword}
//              onChange={(e) => {
//               setPasswordMatch("")
//                const inputValue = e.target.value;
//                if (/^[a-zA-Z0-9]{0,6}$/.test(inputValue)) {
//                  setConfirmNewPassword(inputValue);
//                } else if (inputValue === '') {
//                  setConfirmNewPassword('');
//                }
//              }}
//              className="w-full mb-4 p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
//              required
//            />
//            </>}
//            {/* {confirmNewPassword.length > 0  &&(
//                              <div
//                                className="absolute cursor-pointer"
//                                style={{
//                                  marginLeft : "355px",
//                                  marginTop : "-45px"
//                                }}
//                                onClick={() => setIsConfirmPasswordVisible(!isConfirmPasswordVisible)}
//                              >
//                                {isConfirmPasswordVisible ? <FaEyeSlash /> : <FaEye />}
//                              </div>
//              )} */}
 
//            {confirmNewPassword.length > 0 && confirmNewPassword.length < 6 && (
//              <ConfirmPasswordTooltip
//                content="Password must be 6 digits"
//                show={true}
//                marginLeft={230}
//              />
//            )}
//             {confirmNewPassword.length > 5 && !isValidInput (confirmNewPassword) && (
//             <ConfirmPasswordTooltip
//               content="Invalid password format"
//               show={true}
//               marginLeft={230}
//             />
//           )}
//            {passwordMatch === "Password and confirm password does not match" ?
//              <ConfirmPasswordTooltip2
//                content="password does not match"
//                show={true}
//                marginLeft={230}
//              /> : ""
//            }
           
//  {Toggle &&  
//            <div className="flex justify-end space-x-3">
//            <button
//               onClick={handleOwnerPasswordChange}
//               className="hover:bg-[#0044AB] border-[#3479E0] border-2 text-gray-900 px-6 py-1.5 mt-4 sm:mt-6 text-sm font-semibold hover:border-transparent hover:shadow-lg hover:shadow-[#0044AB]/50 text-center hover:text-white  rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800"
//               disabled={!isValidInput (currentPassword) || !isValidInput (newPassword) || !isValidInput (confirmNewPassword)}
//              >
//                Save
//              </button>
//              <button
//                onClick={handleCloseModal}
//                className="hover:bg-red-500 border-[#3479E0] border-2 text-gray-900 px-6 py-1.5 mt-4 sm:mt-6 text-sm font-semibold hover:border-transparent hover:shadow-lg hover:shadow-red-500/60 text-center hover:text-white  rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800"
//              >
//                Cancel
//              </button>
          
//            </div>
//            }
//          </div>
//    );
// };

// export default OwnerPasswordChangeModal;


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import config from "../../config/config";
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import { useDispatch } from "react-redux";
import { addToast } from './ToastBox/ToastBoxMessageSlice';

const OwnerPasswordChangeModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // State for password fields
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  // State for password visibility
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);
  
  // State for toggle to enable/disable editing
  const [toggle, setToggle] = useState(false);
  
  // State for error messages
  const [currentPasswordError, setCurrentPasswordError] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;
  const user_id = localStorage.getItem("user_id");
  const business_id = localStorage.getItem("business_id");
  const user_role = localStorage.getItem("user_role");
  const email = localStorage.getItem("userEmail");

  // Custom tooltip component for error messages
  const PasswordTooltip = ({ content, show, marginLeft }) => {
    return (
      <div
        style={{
          display: show ? "block" : "none",
          position: "absolute",
          backgroundColor: "#ffffff",
          color: "#fff",
          padding: "5px",
          borderRadius: "4px",
          border: "1px solid red",
          marginTop: "-92px",
          marginLeft: `${marginLeft}px`,
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div
          style={{
            position: "absolute",
            bottom: "-7px",
            left: "50%",
            marginLeft: "-10px",
            width: "0",
            height: "0",
            borderLeft: "7px solid transparent",
            borderRight: "7px solid transparent",
            borderTop: "7px solid #f98080",
          }}
        ></div>
        <p className="text-xs text-red-500">{content}</p>
      </div>
    );
  };

  // Validation functions
  const isValidInput = (value) => {
    return /^[a-zA-Z0-9]{0,6}$/.test(value);
  };

  const isSixDigits = (value) => {
    return value.length === 6 && isValidInput(value);
  };

  // Effect to validate fields when they change and form has been submitted once
  useEffect(() => {
    if (formSubmitted) {
      validateForm();
    }
  }, [currentPassword, newPassword, confirmNewPassword, formSubmitted]);

  // Validation function
  const validateForm = () => {
    let isValid = true;

    // Validate current password
    if (!currentPassword) {
      setCurrentPasswordError('Current password is required');
      isValid = false;
    } else if (currentPassword.length < 6) {
      setCurrentPasswordError('Password must be 6 digits');
      isValid = false;
    } else if (!isValidInput(currentPassword)) {
      setCurrentPasswordError('Invalid password format');
      isValid = false;
    } else {
      setCurrentPasswordError('');
    }

    // Validate new password
    if (!newPassword) {
      setNewPasswordError('New password is required');
      isValid = false;
    } else if (newPassword.length < 6) {
      setNewPasswordError('Password must be 6 digits');
      isValid = false;
    } else if (!isValidInput(newPassword)) {
      setNewPasswordError('Invalid password format');
      isValid = false;
    } else if (newPassword === currentPassword) {
      setNewPasswordError('New password cannot be the same as current password');
      isValid = false;
    } else {
      setNewPasswordError('');
    }

    // Validate confirm password
    if (!confirmNewPassword) {
      setConfirmPasswordError('Please confirm your password');
      isValid = false;
    } else if (confirmNewPassword.length < 6) {
      setConfirmPasswordError('Password must be 6 digits');
      isValid = false;
    } else if (!isValidInput(confirmNewPassword)) {
      setConfirmPasswordError('Invalid password format');
      isValid = false;
    } else if (newPassword !== confirmNewPassword) {
      setConfirmPasswordError('Passwords do not match');
      isValid = false;
    } else {
      setConfirmPasswordError('');
    }

    return isValid;
  };

  const handlePasswordToggle = () => {
    setToggle(!toggle);
    // Reset fields when toggling
    if (!toggle) {
      setCurrentPassword('');
      setNewPassword('');
      setConfirmNewPassword('');
      setCurrentPasswordError('');
      setNewPasswordError('');
      setConfirmPasswordError('');
      setFormSubmitted(false);
    }
  };

  const handleCloseToggle = () => {
    setToggle(false);
    // Reset all fields when closing
    setCurrentPassword('');
    setNewPassword('');
    setConfirmNewPassword('');
    setCurrentPasswordError('');
    setNewPasswordError('');
    setConfirmPasswordError('');
    setFormSubmitted(false);
  };

  const handlePasswordChange = (setter, value) => {
    // Only allow alphanumeric characters up to 6 characters
    if (/^[a-zA-Z0-9]{0,6}$/.test(value) || value === '') {
      setter(value);
    }
  };

  const handleOwnerPasswordChange = async () => {
    setFormSubmitted(true);
    
    // Validate before submission
    if (!validateForm()) {
      dispatch(addToast({ 
        type: 'danger', 
        message: 'Please check errors' 
      }));
      return;
    }

    // Double-check that new password isn't the same as current
    if (newPassword === currentPassword) {
      setNewPasswordError('New password cannot be the same as current password');
      dispatch(addToast({ 
        type: 'danger', 
        message: 'New password cannot be the same as current password' 
      }));
      return;
    }

    setIsLoading(true);

    try {
      const response = await axios.put(`${baseURL}${btoa("login/updateownerpassword")}`, {
        edit_ownerId: user_id,
        email: email,
        edit_OldPassword: currentPassword,
        edit_NewPassword: newPassword
      });

      console.log('Password update response:', response);
      
      if (response.data && response.data.dataUpdatingStatus === true) {
        navigate("/")
        dispatch(addToast({ 
          type: 'success', 
          message: 'Password changed, please login with the new password' 
        }));
        
        // Reset the form
        setToggle(false);
        setCurrentPassword('');
        setNewPassword('');
        setConfirmNewPassword('');
        
        // Delay logout to show the success message
           setTimeout(() => {
                    localStorage.clear();
                    localStorage.clear();
                    window.location.reload();
                }, 2000);
      } else {
        // Handle specific error responses
        if (response.data && response.data.message) {
          const errorMessage = response.data.message.toLowerCase();
          
          if (errorMessage.includes('incorrect') || errorMessage.includes('invalid')) {
            setCurrentPasswordError('Current password is incorrect');
            dispatch(addToast({ 
              type: 'danger', 
              message: 'Current password is incorrect' 
            }));
          } else if (errorMessage.includes('same')) {
            setNewPasswordError('New password cannot be the same as current password');
            dispatch(addToast({ 
              type: 'danger', 
              message: 'New password cannot be the same as current password' 
            }));
          } else {
            dispatch(addToast({ 
              type: 'danger', 
              message: response.data.message 
            }));
          }
        } else {
          dispatch(addToast({ 
            type: 'danger', 
            message: 'An error occurred while changing your password' 
          }));
        }
      }
    } catch (error) {
      console.error('Error changing password:', error);
      
      // Handle error responses from server
      if (error.response && error.response.data && error.response.data.dataSavingStatus === "false") {
        const errorMessage = error.response.data.userStatus;
        
        if (errorMessage.includes('incorrect') || errorMessage.includes('invalid') || errorMessage == 'Old password do not match') {
          setCurrentPasswordError('Current password is incorrect');
          dispatch(addToast({ 
            type: 'danger', 
            message: 'Current password is incorrect' 
          }));
        } else if (errorMessage.includes('same')) {
          setNewPasswordError('New password cannot be the same as current password');
          dispatch(addToast({ 
            type: 'danger', 
            message: 'New password cannot be the same as current password' 
          }));
        } else {
          dispatch(addToast({ 
            type: 'danger', 
            message: error.response.data.message 
          }));
        }
      } else {
        dispatch(addToast({ 
          type: 'danger', 
          message: 'Current password is incorrect' 
        }));
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-white rounded shadow-md w-auto bg-white rounded-box rounded-xl m-8 mb-2">
      <div className="flex items-center">
        <SecurityOutlinedIcon className="text-gray-700 w-6 h-6 mr-2 mb-10" />
        <h2 className="mb-10 text-xl font-bold text-gray-900 underline underline-offset-8 decoration-gray-300">
          Credentials
        </h2>
      </div>
      
      <div className="flex">
        <label className="block mb-2 text-gray-900" htmlFor="currentPassword">
          Current Password:
        </label>
      </div>
      
      {!toggle ? (
        <div className="flex">
          <input
            type="text"
            value="******"
            className="w-full mb-4 p-2 border border-gray-300 bg-gray-200 rounded focus:outline-none focus:border-blue-500"
            readOnly
          />
          <label className="switch-toggle ml-4 flex items-center">
            <input
              type="checkbox"
              onChange={handlePasswordToggle}
              id="passwordToggle"
              name="passwordToggle"
              className="hidden"
            />
            <span className="slider-toggle round"></span>
          </label>
        </div>
      ) : (
        <>
          <div className="relative">
            <input
              type={isPasswordVisible ? "text" : "password"}
              value={currentPassword}
              onChange={(e) => handlePasswordChange(setCurrentPassword, e.target.value)}
              className="w-full mb-4 p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              required
            />
            {currentPassword.length > 0 && (
              <div
                className="absolute right-3 top-3 cursor-pointer"
                onClick={() => setIsPasswordVisible(!isPasswordVisible)}
              >
                {isPasswordVisible ? <FaEyeSlash /> : <FaEye />}
              </div>
            )}
          </div>
          
          {currentPasswordError && (
            <PasswordTooltip
              content={currentPasswordError}
              show={true}
              marginLeft={230}
            />
          )}
          
          <label className="block mb-2 text-gray-900" htmlFor="newPassword">
            New Password:
          </label>
          <div className="relative">
            <input
              type={isNewPasswordVisible ? "text" : "password"}
              value={newPassword}
              onChange={(e) => handlePasswordChange(setNewPassword, e.target.value)}
              className="w-full mb-4 p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              required
            />
            {newPassword.length > 0 && (
              <div
                className="absolute right-3 top-3 cursor-pointer"
                onClick={() => setIsNewPasswordVisible(!isNewPasswordVisible)}
              >
                {isNewPasswordVisible ? <FaEyeSlash /> : <FaEye />}
              </div>
            )}
          </div>
          
          {newPasswordError && (
            <PasswordTooltip
              content={newPasswordError}
              show={true}
              marginLeft={230}
            />
          )}
          
          <label className="block mb-2 text-gray-900" htmlFor="confirmNewPassword">
            Confirm New Password:
          </label>
          <div className="relative">
            <input
              type={isConfirmPasswordVisible ? "text" : "password"}
              value={confirmNewPassword}
              onChange={(e) => handlePasswordChange(setConfirmNewPassword, e.target.value)}
              className="w-full mb-4 p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              required
            />
            {confirmNewPassword.length > 0 && (
              <div
                className="absolute right-3 top-3 cursor-pointer"
                onClick={() => setIsConfirmPasswordVisible(!isConfirmPasswordVisible)}
              >
                {isConfirmPasswordVisible ? <FaEyeSlash /> : <FaEye />}
              </div>
            )}
          </div>
          
          {confirmPasswordError && (
            <PasswordTooltip
              content={confirmPasswordError}
              show={true}
              marginLeft={230}
            />
          )}
          
          <div className="flex justify-end space-x-3">
            <button
              onClick={handleOwnerPasswordChange}
              className={`hover:bg-[#0044AB] border-[#3479E0] border-2 text-gray-900 px-6 py-1.5 mt-4 sm:mt-6 text-sm font-semibold hover:border-transparent hover:shadow-lg hover:shadow-[#0044AB]/50 text-center hover:text-white rounded-lg focus:ring-4 focus:ring-primary-200 hover:bg-primary-800 ${
                isLoading ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              disabled={isLoading}
            >
              {isLoading ? 'Processing...' : 'Save'}
            </button>
            <button
              onClick={handleCloseToggle}
              className="hover:bg-red-500 border-[#3479E0] border-2 text-gray-900 px-6 py-1.5 mt-4 sm:mt-6 text-sm font-semibold hover:border-transparent hover:shadow-lg hover:shadow-red-500/60 text-center hover:text-white rounded-lg focus:ring-4 focus:ring-primary-200 hover:bg-primary-800"
            >
              Cancel
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default OwnerPasswordChangeModal;
// import React from "react";

// const NotificationUI = () => {
//   return (
//     <div className="flex justify-center  py-4"

//     >
//     <div className="flex flex-col items-start p-4 w-[880px]"
//      style={{
//         boxShadow: '0 0 20px 0 rgba(0, 0, 0,.3)',
//       }}
//     >
//       {/* Page Heading */}
//       <h1 className="text-xl font-semibold text-left mb-8">Customer Notifications</h1>

//       {/* Customer Dropdown */}
//       <div className="mb-4 w-full">
//         <select className="w-50 block border-gray-300 rounded-md shadow-sm p-2">
//           <option value="">-- Select Customer --</option>
//           {/* Add more customer options as needed */}
//         </select>
//       </div>

//       {/* Notifications List */}
//       <div className="flex flex-col gap-4 w-full">
//         {["Price change notification", "Past due notification", "Partial Pay notification"].map(
//           (notification, index) => (
//             <div
//               key={index}
//               className="flex items-center justify-between bg-white w-full p-4 rounded-lg"
//               style={{
//                 boxShadow: '0 0 20px 0 rgba(0, 0, 0,.1)',
//               }}
//             >
//               {/* Notification Text */}
//               <div className="flex-1   font-medium">
//                 {index + 1}. {notification}
//               </div>
//               {/* Actions */}
//               <div className="ml-4 flex gap-2">
//                 <button
//                   className="w-40 px-2 py-1 border-2 border-[#25caed]
//                     hover:font-bold rounded-md hover:bg-[#25caed] hover:shadow-[#25caed]-700/50
//                     uppercase duration-200 hover:text-white hover:shadow-lg hover:border-transparent active:scale-90"
//                 >
//                   SMS
//                 </button>
//                 <button
//                   className="w-40 px-2 py-1 border-2 border-purple-600
//                     hover:font-bold rounded-md hover:bg-purple-600 hover:shadow-purple-600/50
//                     uppercase duration-200 hover:text-white hover:shadow-lg hover:border-transparent active:scale-90"
//                 >
//                   Email
//                 </button>
//               </div>
//             </div>
//           )
//         )}
//       </div>
//     </div>
//   </div>

//   );
// };

// export default NotificationUI;



import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addToast } from "./ToastBox/ToastBoxMessageSlice";
import { useSelector } from "react-redux";
import { addCustomer } from "../../store/customerSlice";
import axios from "axios";
import config from "../../config/config";
import TabMenu from "./TabMenu";
import CustomCommonTab from "../reusable/CustomCommonTab";
// import CustomNavigation from "../reusable/CustomNavigationIcon";
import { FaHome } from "react-icons/fa";
import CustomNavigation from "../reusable/CustomNavigation";

const NotificationUI = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const businessId = localStorage.getItem("business_id");

  const baseURL =
    process.env.NODE_ENV === "production"
      ? config.production.baseURL
      : config.development.baseURL;
  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get(`${baseURL}Y3VzdG9tZXIvdmlldw==/${btoa(businessId)}`) //
      .then((response) => {
        const sortedCustomerData = response.data.customers_data.sort(
          (a, b) => b.customer_id - a.customer_id
        );
        dispatch(addCustomer(sortedCustomerData));
      })
      .catch((error) => {
        console.error("Error fetching customer data:", error);
      });
  }, []);

  const [highlightedNotification, setHighlightedNotification] = useState(null);

  useEffect(() => {
    if (location.state?.fromPastDue) {
      setHighlightedNotification("Past due notification");
    } else if (location.state?.fromPartialPay) {
      setHighlightedNotification("Partial Pay notification");
    } else if (location.state?.fromPriceBook) {
      setHighlightedNotification("Price change notification");
    } else {
      setHighlightedNotification(null);
    }
  }, [location.state]);

  const notifications = [
    "Price change notification",
    "Past due notification",
    "Partial Pay notification",
  ];
  const customersAll = useSelector((state) => state.customer.allCustomers);
  const [activeTab, setActiveTab] = useState("View");

  const tabs = [
    { key: "View", label: "View" }, // No separate component—InvoiceView itself is shown

    { key: "Cancel", label: "Cancel", navigationPath: "/NewPrice" },
  ];
  const menuItems = [{ name: "View" }];
  return (
    <>
    <CustomCommonTab items={menuItems} />
    <div className=" flex justify-center py-4">
   {/* <div className="relative flex-0 ">  <CustomNavigation /></div> */}
    
      <div
        className="relative flex flex-col rounded-lg items-start p-4 w-[880px]"
        style={{
          boxShadow: "0 0 20px 0 rgba(0, 0, 0,.3)",
        }}
      > 
        <div className="absolute top-[-10px] right-[-12px]">
        <div className="flex items-center justify-end ">
          {/* <div
            className={`absolute -top-4 flex items-center cursor-pointer rounded-full bg-[#25caed] w-10 h-10  px-2 py-2 mb-1 mr-4 ${
              activeItem === "Home"
                ? "border-b-2 border-green-500 text-green-600"
                : "text-gray-600"
            }  border-gray-300 mb-1 `}
            onClick={() => {
              handleMenuClick("Home");
              navigate("/");
            }}
            style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
          >
            {" "}
            <FaHome className="text-white ml-[2px] mb-1 mt-[2px]" size={20} />
          </div> */}
             {/* <CustomNavigation onCloseClick={() =>  navigate("/NewNotification")}  /> */}
             <div className="flex items-center justify-end">
               {/* Home button - bordered/unfilled by default, filled blue on hover */}
               <div
                 className="absolute -top-3 flex items-center justify-center cursor-pointer rounded-full bg-[#cefafe] hover:bg-[#25caed] w-9 h-9 mb-0 mr-8 border-[0.5px] border-[#25caed] hover:border-[#25caed] hover:scale-110 shadow-md transition-all duration-200 group"
                 onClick={() => {
                   navigate("/");
                 }}
                 style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
               >
                 <FaHome className="text-[#25caed] group-hover:text-white transition-colors duration-200" size={16}  />
               </div>
         
               {/* Close Icon - bordered/unfilled by default, filled red on hover */}
               <div
                 className="absolute -top-3 -right-2 bg-red-200 hover:bg-red-500 w-9 h-9 flex items-center justify-center rounded-full cursor-pointer hover:bg-red-700 border-[0.5px] border-red-500 hover:border-red-700 hover:scale-110 shadow-md transition-all duration-200 group"
                 onClick={() => navigate('/NewNotification')}
               >
                 <span className="text-red-500 group-hover:text-white text-xl font-bold mb-1.5 transition-colors duration-200">&times;</span>
               </div>
             </div>
          {/* <div
            className="absolute -top-4 -right-2 bg-red-500 w-10 h-10 flex items-center justify-center rounded-full cursor-pointer hover:bg-red-700"
            onClick={() => navigate(-1)}
          >
            <span className="text-white text-2xl font-bold mb-1">&times;</span>
          </div> */}
        </div>

    </div>
        {/* <h1 className="text-xl font-semibold text-left mb-8">
          Customer Notifications
        </h1> */}
        {/* <div className="flex justify-center items-center mx-auto">
          <TabMenu
            tabs={tabs}
            activeTab={activeTab}
            onTabChange={setActiveTab}
          />
        </div> */}
        {/* Customer Dropdown */}
        <div className="mb-4 w-full mt-2 m-auto flex justify-center items-center">
          <select className="border p-0 px-2 rounded-lg h-[32px] w-[200px] ">
            <option value="">-- Select Customer --</option>
            <option value="all">All customers</option>

            {/* Add more customer options as needed */}
            {customersAll.map((customer) => (
              <option key={customer.customer_id} value={customer.customer_id}>
                {customer.business_name}
              </option>
            ))}
          </select>
        </div>

        {/* Notifications List */}
        <div className="flex flex-col gap-4 w-full">
          {notifications.map((notification, index) => {
            const isHighlighted = notification === highlightedNotification;

            return (
              <div
                key={index}
                className={`flex items-center justify-between w-full p-4 rounded-lg ${
                  isHighlighted
                    ? "bg-blue-100 border-l-[6px] border-blue-600"
                    : "bg-white"
                }`}
                style={{
                  boxShadow: "0 0 20px 0 rgba(0, 0, 0,.1)",
                }}
              >
                {/* Notification Text */}
                <div
                  className={`flex-1 font-medium ${
                    isHighlighted ? "" : "text-gray-400"
                  }`}
                >
                  {index + 1}. {notification}
                </div>

                {/* Actions */}
                <div className="ml-4 flex gap-2">
                  <button
                    className={`w-40 px-2 py-1 border-2 ${
                      isHighlighted
                        ? "border-blue-600 text-blue-600 hover:bg-blue-600 hover:text-white  hover:shadow-blue-600/50 rounded-md"
                        : "border-gray-300 text-gray-400 cursor-not-allowed"
                    } rounded-md uppercase duration-200 hover:shadow-lg active:scale-90`}
                    disabled={!isHighlighted}
                    onClick={() =>
                      dispatch(
                        addToast({
                          type: "success",
                          message: `SMS Sent Successfully`,
                        })
                      )
                    }
                  >
                    SMS
                  </button>
                  <button
                    className={`w-40 px-2 py-1 border-2 ${
                      isHighlighted
                        ? "border-purple-600 text-purple-600 hover:bg-purple-600 hover:text-white hover:shadow-purple-600/50 rounded-md"
                        : "border-gray-300 text-gray-400 cursor-not-allowed"
                    } rounded-md uppercase duration-200 hover:shadow-lg active:scale-90`}
                    disabled={!isHighlighted}
                    onClick={() =>
                      dispatch(
                        addToast({
                          type: "approve",
                          message: `Email Sent Successfully`,
                        })
                      )
                    }
                  >
                    Email
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div></>
    
  );
};

export default NotificationUI;

// // // import React, { useState, useRef, useEffect } from 'react';
// // // import { Box, Grid, Button } from "@mui/material";
// // // import SendIcon from '@mui/icons-material/Send';
// // // // import CustomerPortalTable from "./CustomerPortalTable";
// // // import DatePicker from 'react-datepicker';
// // // import 'react-datepicker/dist/react-datepicker.css';
// // // // import config from '../../config/config';
// // // import axios from "axios";
// // // import config from '../../../config/config';
// // // import CustomerPortalTable from '../CustomerPortalTable';

// // // function AllInvoicesReports() {
// // //   const datePickerRef = useRef(null);

// // //   const [periodType, setPeriodType] = useState(""); // Tracks the selected period (Year, Month)
// // //   const [selectedYear, setSelectedYear] = useState(""); // Tracks the selected year
// // //   const [selectedMonth, setSelectedMonth] = useState(""); // Tracks the selected month
// // //   const [selectedQuarter, setSelectedQuarter] = useState("");

// // //   const [selectedDate, setSelectedDate] = useState(null); // Initialize as null
// // //   const [customerResponse, setCustomerResponse] = useState([]); // Initialize as null
// // // const[isRunClicked, setIsRunClicked] = useState(false);
// // // const [tempMonth, setTempMonth] = useState(""); // Tracks the selected year
// // // const [tempDate, setTempDate] = useState("");

// // // const [tempYear, setTempYear] = useState(""); // Tracks the selected year
// // // const [tempQuarterName, settempQuarterName] = useState(""); // Tracks the selected year

// // // const [tempPeriodType, setTempPeriodType] = useState(""); // Tracks the selected year

// // //   const years = ["2025","2024", "2023", "2022", "2021"];
// // //   const months = [
// // //     "January",
// // //     "February",
// // //     "March",
// // //     "April",
// // //     "May",
// // //     "June",
// // //     "July",
// // //     "August",
// // //     "September",
// // //     "October",
// // //     "November",
// // //     "December",
// // //   ];
// // //   const quarters = [{bName: "Q1",
// // //     fName:"Q1 (Mar-May)"
// // //   },{bName: "Q2",
// // //     fName:"Q2 (June-Aug)"},{bName: "Q3",
// // //         fName: "Q3 (Sep-Nov)"},{bName: "Q4",
// // //             fName: "Q4 (Dec-Feb)"}]
// // //   const handleDateChange = (date) => {
// // //     if (date) {
// // //       setSelectedDate(date); // Only set the date when the user actually picks a valid date
// // //     }
// // //   };
// // //   // Handles period type change (Year, Month)
// // //   const handlePeriodChange = (event) => {
// // //     setPeriodType(event.target.value);
// // //     setSelectedYear("");
// // //     setSelectedMonth("");
// // //     setSelectedDate('')
// // //   };
// // //   useEffect(() => {
// // //     if (periodType && datePickerRef.current) {
// // //       datePickerRef.current.setFocus(); // Focus on the DatePicker input to open the calendar
// // //     }
// // //   }, [periodType]);
// // //   // Enables the Run button only when valid selections are made
// // //   const isButtonEnabled = () => {
// // //     if (periodType === 'Yearly' && selectedYear) return true;
// // //     if (periodType === 'Monthly' && selectedYear && selectedMonth) return true;
// // //     if (periodType === 'dateWise' && selectedDate ) return true;
// // //     if (periodType === 'Quarterly' && selectedYear && selectedQuarter) return true;

// // //     return false;
// // //   };
// // //   const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;
// // //   const formattedDate = selectedDate ? selectedDate.toLocaleDateString('en-CA') : null;
// // //   function formatDatetodisplay(dateInput) {
// // //     const date = new Date(dateInput); // Convert the input to a Date object

// // //     const month = date.toLocaleString('default', { month: 'long' }); // Full month name
// // //     const day = date.getDate(); // Day of the month
// // //     const year = date.getFullYear(); // Year

// // //     return `${month} ${day} ${year}`
// // //   }
// // //   const formattedDateCustomer = selectedDate ? selectedDate.toLocaleDateString('en-CA') : null;

// // //   const handleRun = async() => {
// // //     console.log('runclicked')
// // //     setTempPeriodType(periodType)
// // //     setTempDate(selectedDate)
// // //     setTempMonth(selectedMonth)
// // //     setTempYear(selectedYear)
// // //     settempQuarterName(selectedQuarter)
// // //     setIsRunClicked(true)
// // //     // Handle the action when the "Run" button is clicked
// // //     try{
// // //       const response = await axios.post(`${baseURL}/${btoa("reports/viewinvoicecustportfilter")}` , {
// // //              reportType: periodType,
// // //           monthName: selectedMonth,
// // //           quarterName: selectedQuarter,
// // //           year: selectedYear,
// // //           selectDate: formattedDateCustomer,
// // //           businessId: localStorage.getItem('business_id'),
// // //           customerId: localStorage.getItem('customer_id'),
// // //           contactId: localStorage.getItem('contact_id'),
// // //         })
// // //       console.log('response.....', response)
// // //       setCustomerResponse(response)

// // //   }
// // //       catch(error){
// // //           console.log("error posting details" , error);
// // //         }
// // //   };
// // // useEffect(()=>{
// // //   setTempPeriodType('Monthly')
// // //   setTempMonth(months[new Date().getMonth()])
// // //   setTempYear(new Date().getFullYear())
// // // },[])

// // //   useEffect(() => {
// // //     setPeriodType('Monthly');
// // //      setSelectedMonth(months[new Date().getMonth()])
// // //      setSelectedYear(new Date().getFullYear())
// // //     const fetchInvoiceData = async () => {
// // //       try {
// // //         const response = await axios.post(`${baseURL}/${btoa("reports/viewinvoicecustportfilter")}`, {

// // //           reportType: 'Monthly',
// // //           monthName: months[new Date().getMonth()],
// // //           quarterName:null,
// // //           year: new Date().getFullYear(),
// // //           businessId: localStorage.getItem('business_id'),
// // //           customerId: localStorage.getItem('customer_id'),
// // //           contactId: localStorage.getItem('contact_id'),
// // //         });
// // //         console.log('response.....', response);
// // //         setCustomerResponse(response);
// // //       } catch (error) {
// // //         console.log("error posting details", error);
// // //       }
// // //     };

// // //     fetchInvoiceData();

// // //   }, []); // Add dependencies if needed

// // //   return (
// // //     <div style={{  borderRadius: "20px",
// // //       // maxWidth: '100%',
// // //       overflowX: 'auto',
// // //       margin: '0 auto',
// // //       flexDirection: 'column',
// // //                     boxShadow: '0 0 25px 0 rgba(0, 0, 0, 0.2)',
// // //                    margin:'25px',
// // //                    marginTop:'10px',
// // //                    paddingBottom:'80px',
// // //                    padding:'20px',
// // //                    justifyContent:'center',
// // //                    display:'flex',
// // //                    flexDirection:'column',
// // //                    backgroundColor:'white'
// // //           }}>
// // //       {/* <div
// // //   className="flex justify-between items-center w-full  pt-4 mt-4 "
// // //   style={{
// // //     maxWidth: '1200px',
// // //     boxSizing: 'border-box',
// // //     marginLeft: '40px',

// // //   }}
// // // >
// // //   <h1 className="font-bold text-xl" style={{borderBottom: '4px solid #3479E0', paddingBottom: '8px'}}>Customer Portal Report</h1>
// // // </div> */}
// // //     <Box
// // //       sx={{
// // //         display: "flex",
// // //         justifyContent: "start",
// // //         alignItems: "center",
// // //         padding: "20px",
// // //         marginLeft: "43px",
// // //         marginRight: '30px',
// // //         marginTop: "30px",
// // //         borderRadius: "20px",
// // //         boxShadow: '0 0 20px 0 rgba(0, 0, 0, .2)',
// // //         paddingBottom:'30px',
// // //         marginBottom:'30px'
// // //       }}
// // //     >
// // //       <Grid container spacing={4}>
// // //         <Grid item md={3}>
// // //           <div>
// // //             <label className="block mb-2 text-base font-medium text-gray-900" style={{whiteSpace:'nowrap'}}>
// // //               Select Report Type
// // //               <span className="text-red-500 text-xl font-extrabold pl-2 pt-3">*</span>
// // //             </label>
// // //             <select
// // //               required
// // //               onChange={handlePeriodChange}
// // //               value={periodType}
// // //               className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
// // //             >
// // //               <option value="">Select Report Type</option>
// // //               <option value="dateWise">Date</option>
// // //               <option value="Monthly">Monthly</option>
// // //               <option value="Quarterly">Quarterly</option>
// // //               <option value="Yearly">Yearly</option>
// // //             </select>
// // //           </div>
// // //         </Grid>
// // //         {(periodType === 'dateWise')  &&
// // //        <Grid item md= {3}>
// // //             <label
// // //             for="Select Date"
// // //             className="block mb-2 text-base font-medium text-gray-900 "
// // //           >
// // //             Select Date
// // //             <span className="text-red-500 text-xl font-extrabold pl-2 pt-3">*</span>
// // //           </label>
// // //           <DatePicker
// // //             ref={datePickerRef}
// // //             selected={selectedDate}
// // //             onChange={handleDateChange}
// // //             dateFormat="MM-dd-yyyy"
// // //             className="date-picker"
// // //             style={{ height:'43px', width:'100%'}}
// // //             maxDate={new Date()}
// // //           />
// // //       </Grid>
// // // }
// // //         {periodType === "Monthly" && (
// // //           <Grid item md={3}>
// // //             <div>
// // //               <label className="block mb-2 text-base font-medium text-gray-900">
// // //                 Select Month
// // //                 <span className="text-red-500 text-xl font-extrabold pl-2 pt-3">*</span>
// // //               </label>
// // //               <select
// // //                 required
// // //                 value={selectedMonth}
// // //                 onChange={(e) => {setSelectedMonth(e.target.value);}}
// // //                 className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
// // //               >
// // //                 <option value="">Select Month</option>
// // //                 {months.map((month) => (
// // //                   <option key={month} value={month}>
// // //                     {month}
// // //                   </option>
// // //                 ))}
// // //               </select>
// // //             </div>
// // //           </Grid>
// // //         )}
// // //          { (periodType === 'Quarterly') &&
// // //                      <Grid item md={3} >
// // //           <div>
// // //           <label
// // //             for="Select Quarter"
// // //             className="block mb-2 text-base font-medium text-gray-900 "
// // //           >
// // //             Select Quarter
// // //             <span className="text-red-500 text-xl font-extrabold pl-2 pt-3">*</span>
// // //           </label>
// // //           <select
// // //             id="Select Quarter"
// // //             name="Select Quarter"
// // //             required
// // //             value={selectedQuarter}
// // //             onChange={(e) => {setSelectedQuarter(e.target.value);}}
// // //             label="Select Quarter"
// // //             className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
// // //           >
// // //             <option value="">--Select Quarter--</option>
// // //             {quarters.map((quarter) => (
// // //             <option key={quarter.fName} value={quarter.bName} sx={{ margin: 0, padding: '1px 16px' }}>
// // //               {quarter.fName}
// // //             </option>
// // //           ))}
// // //           </select>
// // //         </div>
// // //         </Grid>
// // //       }

// // //         {(periodType === "Monthly" || periodType === "Yearly" || periodType === "Quarterly") && (
// // //           <Grid item md={3}>
// // //             <div>
// // //               <label className="block mb-2 text-base font-medium text-gray-900">
// // //                 Select Year
// // //                 <span className="text-red-500 text-xl font-extrabold pl-2 pt-3">*</span>
// // //               </label>
// // //               <select
// // //                 required
// // //                 value={selectedYear}
// // //                 onChange={(e) => {setSelectedYear(e.target.value);}}
// // //                 className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
// // //               >
// // //                 <option value="">Select Year</option>
// // //                 {years.map((year) => (
// // //                   <option key={year} value={year}>
// // //                     {year}
// // //                   </option>
// // //                 ))}
// // //               </select>
// // //             </div>
// // //           </Grid>
// // //         )}

// // //         <Grid item md={3}>
// // //           {/* Run Button */}
// // //           <Button
// // //             variant="contained"
// // //             sx={{
// // //               backgroundColor: 'rgb(231 70 148)', // Pink color
// // //               color: 'white',
// // //               marginTop: '36px',
// // //               height: '43px',
// // //               '&:hover': {
// // //                 backgroundColor: 'rgb(231 70 148)', // Darker shade of pink on hover
// // //               }
// // //             }}
// // //             endIcon={<SendIcon />}
// // //             disabled={!isButtonEnabled()}
// // //             onClick={handleRun}
// // //           >
// // //             Run
// // //           </Button>
// // //         </Grid>
// // //       </Grid>
// // //     </Box>
// // //     <>
// // //     {!isRunClicked &&

// // //     <div
// // //     style={{
// // //       color: '#1976d2',
// // //       fontSize: '20px',
// // //       fontWeight: 'bold',
// // //       display: 'flex',
// // //       justifyContent: 'center',
// // //       textAlign: 'center', // Center text within the div
// // //       marginBottom: '10px', // Added margin for separation from the second div
// // //       marginRight:'110px'
// // //     }}
// // //   >
// // //     {tempPeriodType === 'Monthly' && tempYear && tempMonth && `${tempMonth} - ${tempYear} - Invoices`}

// // //   </div>
// // // }
// // //   </>
// // //     {isRunClicked &&
// // //     <>
// // //     <div
// // //     style={{
// // //       color: '#1976d2',
// // //       fontSize: '20px',
// // //       fontWeight: 'bold',
// // //       display: 'flex',
// // //       justifyContent: 'center',
// // //       textAlign: 'center', // Center text within the div
// // //       marginBottom: '10px', // Added margin for separation from the second div
// // //       marginRight:'110px'
// // //     }}
// // //   >
// // //     {tempPeriodType === 'Yearly' && tempYear && isRunClicked && `${tempYear} - Invoices`}
// // //     {tempPeriodType === 'Monthly' && tempYear && tempMonth && `${tempMonth} - ${tempYear} - Invoices`}
// // //     {tempPeriodType === 'dateWise' && tempDate && isRunClicked && `${formatDatetodisplay(tempDate)} - Invoices`}
// // //     {(tempPeriodType === 'Quarterly' && tempYear && tempQuarterName && isRunClicked) && ` ${tempQuarterName == 'Q1' ? 'Q1 (Mar-May)' :''} ${tempQuarterName == 'Q2' ? 'Q2 (June-Aug)':'' } ${tempQuarterName == 'Q3' ? 'Q3 (Sep-Nov)' :'' } ${tempQuarterName == 'Q4' ? 'Q4 (Dec-Feb)' :'' } - ${tempYear} - Invoices`}

// // //   </div>
// // //   <hr className="border-t border-gray-300 mb-1 mt-2" />
// // //   </>

// // // }
// // //     <CustomerPortalTable customerResponse= {customerResponse}/>
// // //     </div>
// // //   );
// // // }

// // // export default AllInvoicesReports;

// // import React, { useState, useRef, useEffect } from "react";
// // import { Box, Grid, Button } from "@mui/material";
// // import SendIcon from "@mui/icons-material/Send";
// // // import CustomerPortalTable from "./CustomerPortalTable";
// // import DatePicker from "react-datepicker";
// // import "react-datepicker/dist/react-datepicker.css";
// // // import config from '../../config/config';
// // import axios from "axios";
// // import config from "../../../config/config";
// // import CustomerPortalTable from "../CustomerPortalTable";
// // import { FaHome } from "react-icons/fa";
// // import { useNavigate } from "react-router-dom";

// // function AllInvoicesReports() {
// //   const datePickerRef = useRef(null);
// // const navigate = useNavigate();
// //   const [periodType, setPeriodType] = useState(""); // Tracks the selected period (Year, Month)
// //   const [selectedYear, setSelectedYear] = useState(""); // Tracks the selected year
// //   const [selectedMonth, setSelectedMonth] = useState(""); // Tracks the selected month
// //   const [selectedQuarter, setSelectedQuarter] = useState("");

// //   const [selectedDate, setSelectedDate] = useState(null); // Initialize as null
// //   const [customerResponse, setCustomerResponse] = useState([]); // Initialize as null
// //   const [isRunClicked, setIsRunClicked] = useState(false);
// //   const [tempMonth, setTempMonth] = useState(""); // Tracks the selected year
// //   const [tempDate, setTempDate] = useState("");

// //   const [tempYear, setTempYear] = useState(""); // Tracks the selected year
// //   const [tempQuarterName, settempQuarterName] = useState(""); // Tracks the selected year

// //   const [tempPeriodType, setTempPeriodType] = useState(""); // Tracks the selected year

// //   // const years = ["2025","2024", "2023", "2022", "2021"];
// //   const months = [
// //     "January",
// //     "February",
// //     "March",
// //     "April",
// //     "May",
// //     "June",
// //     "July",
// //     "August",
// //     "September",
// //     "October",
// //     "November",
// //     "December"
// //   ];
// //   const user_id = localStorage.getItem("user_id");
// //   const user_role = localStorage.getItem("user_role");
// //   const business_id = localStorage.getItem("business_id");
// //   const [userRole, setUserRole] = useState("");

// //   const [years, setYears] = useState([]);
// //   // useEffect(() => {
// //   //   setSelectedYear(new Date().getFullYear().toString());
// //   // }, []);
// //   useEffect(() => {
// //     console.log("Fetching business profile data...");

// //     axios
// //       .get(
// //         `${baseURL}/${btoa("business/profile")}/${btoa(business_id)}/${btoa(
// //           user_id
// //         )}/${btoa(user_role)}`
// //       )
// //       .then((response) => {
// //         console.log("API response received");

// //         try {
// //           // Extract the added_on date string
// //           const addedOnDate = response.data.business_data[0].added_on;
// //           console.log("Raw added_on date:", addedOnDate);

// //           // Extract just the year from the date string (e.g., "2025-02-17" → 2025)
// //           const startYear = parseInt(addedOnDate.substring(0, 4));
// //           console.log("Extracted start year:", startYear);

// //           // Validate the year is a reasonable value
// //           if (isNaN(startYear) || startYear < 2000 || startYear > 2100) {
// //             console.error("Invalid year value:", startYear);
// //             return;
// //           }

// //           // Generate years array from startYear to current year
// //           const currentYear = new Date().getFullYear();
// //           const yearArray = [];

// //           // Add years in descending order (newest first)
// //           for (let year = currentYear; year >= startYear; year--) {
// //             yearArray.push(year);
// //           }

// //           console.log("Generated years array:", yearArray);

// //           // Update state with the generated years
// //           setYears(yearArray);

// //           // Set default selected year to current year
// //           // if (yearArray.length > 0) {
// //           //   setSelectedYear(currentYear.toString());
// //           // }
// //         } catch (error) {
// //           console.error("Error processing business data:", error);
// //         }
// //       })
// //       .catch((error) => {
// //         console.error("Error fetching business data:", error);
// //       });
// //   }, []);
// //   const quarters = [
// //     { bName: "Q1", fName: "Q1 (Mar-May)" },
// //     { bName: "Q2", fName: "Q2 (June-Aug)" },
// //     { bName: "Q3", fName: "Q3 (Sep-Nov)" },
// //     { bName: "Q4", fName: "Q4 (Dec-Feb)" }
// //   ];
// //   const handleDateChange = (date) => {
// //     if (date) {
// //       setSelectedDate(date); // Only set the date when the user actually picks a valid date
// //     }
// //   };

// //   const handleKeyPress = (e) => {
// //     if (e.key === "Enter" && isButtonEnabled()) {
// //       handleRun();
// //     }
// //   };

// //   // Handles period type change (Year, Month)
// //   const handlePeriodChange = (event) => {
// //     setPeriodType(event.target.value);
// //     setSelectedYear("");
// //     setSelectedMonth("");
// //     setSelectedDate("");
// //   };
// //   useEffect(() => {
// //     if (periodType && datePickerRef.current) {
// //       datePickerRef.current.setFocus(); // Focus on the DatePicker input to open the calendar
// //     }
// //   }, [periodType]);
// //   // Enables the Run button only when valid selections are made
// //   // const isButtonEnabled = () => {
// //   //   if (periodType === "Yearly" && selectedYear) return true;
// //   //   if (periodType === "Monthly" && selectedYear && selectedMonth) return true;
// //   //   if (periodType === "dateWise" && selectedDate) return true;
// //   //   if (periodType === "Quarterly" && selectedYear && selectedQuarter)
// //   //     return true;

// //   //   return false;
// //   // };
// //   const isButtonEnabled = () => {
// //     if (periodType === "Yearly" && selectedYear) return true;
// //     if (periodType === "Monthly" && selectedYear && selectedMonth) return true;
// //     if (periodType === "Quarterly" && selectedYear && selectedQuarter) return true;
    
// //     return false;
// //   };
// //   const baseURL =
// //     process.env.NODE_ENV === "production"
// //       ? config.production.baseURL
// //       : config.development.baseURL;
// //   const formattedDate = selectedDate
// //     ? selectedDate.toLocaleDateString("en-CA")
// //     : null;
// //   function formatDatetodisplay(dateInput) {
// //     const date = new Date(dateInput); // Convert the input to a Date object

// //     const month = date.toLocaleString("default", { month: "long" }); // Full month name
// //     const day = date.getDate(); // Day of the month
// //     const year = date.getFullYear(); // Year

// //     return `${month} ${day} ${year}`;
// //   }
// //   const formattedDateCustomer = selectedDate
// //     ? selectedDate.toLocaleDateString("en-CA")
// //     : null;

// //   const handleRun = async () => {
// //     console.log("runclicked");
// //     setTempPeriodType(periodType);
// //     setTempDate(selectedDate);
// //     setTempMonth(selectedMonth);
// //     setTempYear(selectedYear);
// //     settempQuarterName(selectedQuarter);
// //     setIsRunClicked(true);
// //     // Handle the action when the "Run" button is clicked
// //     try {
// //       const response = await axios.post(
// //         `${baseURL}/${btoa("reports/viewinvoicecustportfilter")}`,
// //         {
// //           reportType: periodType,
// //           monthName: selectedMonth,
// //           quarterName: selectedQuarter,
// //           year: selectedYear,
// //           selectDate: formattedDateCustomer,
// //           businessId: localStorage.getItem("business_id"),
// //           customerId: localStorage.getItem("customer_id"),
// //           contactId: localStorage.getItem("contact_id")
// //         }
// //       );
// //       console.log("response.....", response);
// //       setCustomerResponse(response);
// //     } catch (error) {
// //       console.log("error posting details", error);
// //     }
// //   };
// //   useEffect(() => {
// //     setTempPeriodType("");
// //     setTempMonth(months[new Date().getMonth()]);
// //     setTempYear(new Date().getFullYear());
// //   }, []);

// //   useEffect(() => {
// //     setPeriodType("");
// //     setSelectedMonth(months[new Date().getMonth()]);
// //     setSelectedYear(new Date().getFullYear());
// //     const fetchInvoiceData = async () => {
// //       try {
// //         const response = await axios.post(
// //           `${baseURL}/${btoa("reports/viewinvoicecustportfilter")}`,
// //           {
// //             reportType: "Monthly",
// //             monthName: months[new Date().getMonth()],
// //             quarterName: null,
// //             year: new Date().getFullYear(),
// //             businessId: localStorage.getItem("business_id"),
// //             customerId: localStorage.getItem("customer_id"),
// //             contactId: localStorage.getItem("contact_id")
// //           }
// //         );
// //         console.log("response.....", response);
// //         setCustomerResponse(response);
// //       } catch (error) {
// //         console.log("error posting details", error);
// //       }
// //     };

// //     fetchInvoiceData();
// //   }, []); // Add dependencies if needed

// //   return (
// //     <>
// //     {/* <Box
// //       sx={{
// //         display: "flex",
// //         justifyContent: "start",
// //         alignItems: "center",
// //         padding: "20px",
// //         marginLeft: "43px",
// //         marginRight: "30px",
// //         marginTop: "30px",
// //         borderRadius: "20px",
// //         boxShadow: "0 0 20px 0 rgba(0, 0, 0, .2)",
// //         paddingBottom: "30px",
// //         marginBottom: "30px"
// //       }}
// //     >
// //       <Grid container spacing={4}>
// //         <Grid item md={3}>
// //           <div>
// //             <label
// //               className="block mb-2 text-base font-medium text-gray-900"
// //               style={{ whiteSpace: "nowrap" }}
// //             >
// //               Select Report Type
// //               <span className="text-red-500 text-xl font-extrabold pl-2 pt-3">
// //                 *
// //               </span>
// //             </label>
// //             <select
// //               required
// //               onChange={handlePeriodChange}
// //               value={periodType}
// //               className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
// //             >
// //               <option value="">Select Report Type</option>
// //               <option value="dateWise">Date</option>
// //               <option value="Monthly">Monthly</option>
// //               <option value="Quarterly">Quarterly</option>
// //               <option value="Yearly">Yearly</option>
// //             </select>
// //           </div>
// //         </Grid>
// //         {periodType === "dateWise" && (
// //           <Grid item md={3}>
// //             <label
// //               for="Select Date"
// //               className="block mb-2 text-base font-medium text-gray-900 "
// //             >
// //               Select Date
// //               <span className="text-red-500 text-xl font-extrabold pl-2 pt-3">
// //                 *
// //               </span>
// //             </label>
// //             <DatePicker
// //               ref={datePickerRef}
// //               selected={selectedDate}
// //               onChange={handleDateChange}
// //               dateFormat="MM-dd-yyyy"
// //               className="date-picker"
// //               style={{ height: "43px", width: "100%" }}
// //               maxDate={new Date()} />
// //           </Grid>
// //         )}
// //         {periodType === "Monthly" && (
// //           <Grid item md={3}>
// //             <div>
// //               <label className="block mb-2 text-base font-medium text-gray-900">
// //                 Select Month
// //                 <span className="text-red-500 text-xl font-extrabold pl-2 pt-3">
// //                   *
// //                 </span>
// //               </label>
// //               <select
// //                 required
// //                 value={selectedMonth}
// //                 onChange={(e) => {
// //                   setSelectedMonth(e.target.value);
// //                 } }
// //                 className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
// //               >
// //                 <option value="">Select Month</option>
// //                 {months.map((month) => (
// //                   <option key={month} value={month}>
// //                     {month}
// //                   </option>
// //                 ))}
// //               </select>
// //             </div>
// //           </Grid>
// //         )}
// //         {periodType === "Quarterly" && (
// //           <Grid item md={3}>
// //             <div>
// //               <label
// //                 for="Select Quarter"
// //                 className="block mb-2 text-base font-medium text-gray-900 "
// //               >
// //                 Select Quarter
// //                 <span className="text-red-500 text-xl font-extrabold pl-2 pt-3">
// //                   *
// //                 </span>
// //               </label>
// //               <select
// //                 id="Select Quarter"
// //                 name="Select Quarter"
// //                 required
// //                 value={selectedQuarter}
// //                 onChange={(e) => {
// //                   setSelectedQuarter(e.target.value);
// //                 } }
// //                 label="Select Quarter"
// //                 className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
// //               >
// //                 <option value="">--Select Quarter--</option>
// //                 {quarters.map((quarter) => (
// //                   <option
// //                     key={quarter.fName}
// //                     value={quarter.bName}
// //                     sx={{ margin: 0, padding: "1px 16px" }}
// //                   >
// //                     {quarter.fName}
// //                   </option>
// //                 ))}
// //               </select>
// //             </div>
// //           </Grid>
// //         )}

// //         {(periodType === "Monthly" ||
// //           periodType === "Yearly" ||
// //           periodType === "Quarterly") && (
// //             <Grid item md={3}>
// //               <div>
// //                 <label className="block mb-2 text-base font-medium text-gray-900">
// //                   Select Year
// //                   <span className="text-red-500 text-xl font-extrabold pl-2 pt-3">
// //                     *
// //                   </span>
// //                 </label>
// //                 <select
// //                   required
// //                   value={selectedYear}
// //                   onChange={(e) => {
// //                     setSelectedYear(e.target.value);
// //                   } }
// //                   className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
// //                 >
// //                   <option value="">Select Year</option>
// //                   {years.map((year) => (
// //                     <option key={year} value={year}>
// //                       {year}
// //                     </option>
// //                   ))}
// //                 </select>
// //               </div>
// //             </Grid>
// //           )}

// //         <Grid item md={3}>
// //           {/* Run Button */}
// //           {/* <Button
// //             variant="contained"
// //             sx={{
// //               backgroundColor: "rgb(231 70 148)", // Pink color
// //               color: "white",
// //               marginTop: "36px",
// //               height: "43px",
// //               "&:hover": {
// //                 backgroundColor: "rgb(231 70 148)" // Darker shade of pink on hover
// //               }
// //             }}
// //             endIcon={<SendIcon />}
// //             disabled={!isButtonEnabled()}
// //             onClick={handleRun}
// //           >
// //             Run
// //           </Button>
// //         </Grid>
// //       </Grid>
// //     </Box> */} 
    
// //     <div className="ml-5">
// //     <>
// //       <div
// //         className="flex justify-between items-center w-full pt-4 mt-4 mb-5"
// //         style={{
// //           maxWidth: "1200px",
// //           boxSizing: "border-box",
// //           marginLeft: "4px",
// //           color: "#444444"
// //         }}
// //       >
// //           <nav aria-label="breadcrumb">
// //             <ol className="flex space-x-2 text-lg font-bold text-gray-700">
// //               <li>Reports</li>
// //               <li>
// //                 <span className="text-gray-400">{">>"}</span>
// //               </li>
// //               <li>All Invoices</li>
// //             </ol>
// //           </nav>
// //         </div>
// //     <div
// //   className="relative py-[12px] px-[8px] mt-4 rounded-xl bg-white"
// //   style={{ boxShadow: "0 0 25px 0 rgba(0, 0, 0, 0.2)" }}
// // >
// // <div className="flex items-center justify-end">
// //             {/* Home button - bordered/unfilled by default, filled blue on hover */}
// //             <div
// //               className="absolute -top-4 flex items-center justify-center cursor-pointer rounded-full bg-[#cefafe] hover:bg-[#25caed] w-9 h-9 mb-0 mr-6 border-[0.5px] border-[#25caed] hover:border-[#25caed] hover:scale-110 shadow-md transition-all duration-200 group"
// //               onClick={() => {
// //                 navigate("/");
// //               }}
// //               style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
// //             >
// //               <FaHome
// //                 className="text-[#25caed] group-hover:text-white transition-colors duration-200"
// //                 size={16}
// //               />
// //             </div>

// //             {/* Close Icon - bordered/unfilled by default, filled red on hover */}
// //             <div
// //               className="absolute -top-4 -right-2 bg-red-200 hover:bg-red-500 w-9 h-9 flex items-center justify-center rounded-full cursor-pointer hover:bg-red-700 border-[0.5px] border-red-500 hover:border-red-700 hover:scale-110 shadow-md transition-all duration-200 group"
// //               onClick={() => navigate("/customerreport")}
// //             >
// //               <span className="text-red-500 group-hover:text-white text-xl font-bold mb-1.5 transition-colors duration-200">
// //                 &times;
// //               </span>
// //             </div>
// //           </div>
// //   <Grid
// //     container
// //     spacing={3}
// //     alignItems="center"
// //     justifyContent="start"
// //     className="pl-[10px] pr-6"
// //   >
// //     {/* Report Type Selection */}
// //     <Grid item md={2.1}>
// //       <div>
// //         <label className="block mb-2 text-[14px] font-medium text-gray-600 inline-flex items-center">
// //           Duration
// //           <span className="text-red-500 text-xl font-extrabold pl-1">*</span>
// //         </label>
// //         <select
// //           id="periodType"
// //           value={periodType}
// //           onChange={handlePeriodChange}
// //           onKeyPress={handleKeyPress}
// //           className="border border-gray-300 text-sm font-medium rounded-lg focus:ring-grey-600 focus:border-primary-600 block w-full text-gray-500 py-1 px-2 h-[30px]"
// //         > <option value="">--Select--</option>
// //           <option value="Monthly">Monthly</option>
// //           <option value="Quarterly">Quarterly</option>
// //           <option value="Yearly">Yearly</option>
// //           <option value="CustomRange">Date</option>
// //         </select>
// //       </div>
// //     </Grid>

// //     {/* Monthly Fields */}
// //     {periodType === "Monthly" && (
// //       <>
// //         <Grid item md={2.4} xs={12}>
// //           <div>
// //             <label className="block mb-2 text-[14px] font-medium text-gray-600 inline-flex items-center">
// //               Select Month
// //               <span className="text-red-500 text-xl font-extrabold pl-1">*</span>
// //             </label>
// //             <select
// //               id="selectedMonth"
// //               value={selectedMonth}
// //               onChange={(e) => setSelectedMonth(e.target.value)}
// //               onKeyPress={handleKeyPress}
// //               className="border border-gray-300 text-sm font-medium rounded-lg focus:ring-grey-600 focus:border-primary-600 block w-full text-gray-500 py-1 px-2 h-[30px]"
// //             >
// //               <option value="">--Select Month--</option>
// //               {months.map((month) => (
// //                 <option key={month} value={month}>{month}</option>
// //               ))}
// //             </select>
// //           </div>
// //         </Grid>
// //         <Grid item md={2.4} xs={12}>
// //           <div>
// //             <label className="block mb-2 text-[14px] font-medium text-gray-600 inline-flex items-center">
// //               Select Year
// //               <span className="text-red-500 text-xl font-extrabold pl-1">*</span>
// //             </label>
// //             <select
// //               id="selectedYear"
// //               value={selectedYear}
// //               onChange={(e) => setSelectedYear(e.target.value)}
// //               onKeyPress={handleKeyPress}
// //               className="border border-gray-300 text-sm font-medium rounded-lg focus:ring-grey-600 focus:border-primary-600 block w-full text-gray-500 py-1 px-2 h-[30px]"
// //             >
// //               <option value="">--Select Year--</option>
// //               {years.map((year) => (
// //                 <option key={year} value={year}>{year}</option>
// //               ))}
// //             </select>
// //           </div>
// //         </Grid>
// //       </>
// //     )}

// //     {/* Quarterly Fields */}
// //     {periodType === "Quarterly" && (
// //       <>
// //         <Grid item md={2.4} xs={12}>
// //           <div>
// //             <label className="block mb-2 text-[14px] font-medium text-gray-600 inline-flex items-center">
// //               Select Quarter
// //               <span className="text-red-500 text-xl font-extrabold pl-1">*</span>
// //             </label>
// //             <select
// //               id="selectedQuarter"
// //               value={selectedQuarter}
// //               onChange={(e) => setSelectedQuarter(e.target.value)}
// //               onKeyPress={handleKeyPress}
// //               className="border border-gray-300 text-sm font-medium rounded-lg focus:ring-grey-600 focus:border-primary-600 block w-full text-gray-500 py-1 px-2 h-[30px]"
// //             >
// //               <option value="">--Select Quarter--</option>
// //               {quarters.map((quarter) => (
// //                 <option key={quarter.bName} value={quarter.bName}>{quarter.fName}</option>
// //               ))}
// //             </select>
// //           </div>
// //         </Grid>
// //         <Grid item md={2.4} xs={12}>
// //           <div>
// //             <label className="block mb-2 text-[14px] font-medium text-gray-600 inline-flex items-center">
// //               Select Year
// //               <span className="text-red-500 text-xl font-extrabold pl-1">*</span>
// //             </label>
// //             <select
// //               id="selectedYear"
// //               value={selectedYear}
// //               onChange={(e) => setSelectedYear(e.target.value)}
// //               onKeyPress={handleKeyPress}
// //               className="border border-gray-300 text-sm font-medium rounded-lg focus:ring-grey-600 focus:border-primary-600 block w-full text-gray-500 py-1 px-2 h-[30px]"
// //             >
// //               <option value="">--Select Year--</option>
// //               {years.map((year) => (
// //                 <option key={year} value={year}>{year}</option>
// //               ))}
// //             </select>
// //           </div>
// //         </Grid>
// //       </>
// //     )}

// //     {/* Yearly Fields */}
// //     {periodType === "Yearly" && (
// //       <Grid item md={2.4} xs={12}>
// //         <div>
// //           <label className="block mb-2 text-[14px] font-medium text-gray-600 inline-flex items-center">
// //             Select Year
// //             <span className="text-red-500 text-xl font-extrabold pl-1">*</span>
// //           </label>
// //           <select
// //             id="selectedYear"
// //             value={selectedYear}
// //             onChange={(e) => setSelectedYear(e.target.value)}
// //             onKeyPress={handleKeyPress}
// //             className="border border-gray-300 text-sm font-medium rounded-lg focus:ring-grey-600 focus:border-primary-600 block w-full text-gray-500 py-1 px-2 h-[30px]"
// //           >
// //             <option value="">--Select Year--</option>
// //             {years.map((year) => (
// //               <option key={year} value={year}>{year}</option>
// //             ))}
// //           </select>
// //         </div>
// //       </Grid>
// //     )}

   
// //     {/* Run Button */}
// //     <Grid item>
// //       <Button
// //         variant="contained"
// //         disabled={!isButtonEnabled()}
// //         sx={{
// //           backgroundColor: "rgb(231 70 148)",
// //           color: "white",
// //           marginTop: "34px",
// //           height: "30px",
// //           "&:hover": {
// //             backgroundColor: "rgb(231 70 148)"
// //           }
// //         }}
// //         endIcon={<SendIcon />}
// //         onClick={handleRun}
// //       >
// //         Run
// //       </Button>
// //     </Grid>
// //   </Grid>
// // </div>
    
// //     <div
// //       style={{
// //         borderRadius: "20px",
// //         // maxWidth: '100%',
// //         overflowX: "auto",
// //         margin: "0 auto",
// //         flexDirection: "column",
// //         boxShadow: "0 0 25px 0 rgba(0, 0, 0, 0.2)",
// //         margin: "25px",
// //         marginTop: "10px",
// //         paddingBottom: "80px",
// //         padding: "20px",
// //         justifyContent: "center",
// //         display: "flex",
// //         flexDirection: "column",
// //         backgroundColor: "white"
// //       }}
// //     >
// //         {/* <div
// // className="flex justify-between items-center w-full  pt-4 mt-4 "
// // style={{
// //   maxWidth: '1200px',
// //   boxSizing: 'border-box',
// //   marginLeft: '40px',

// // }}
// // >
// // <h1 className="font-bold text-xl" style={{borderBottom: '4px solid #3479E0', paddingBottom: '8px'}}>Customer Portal Report</h1>
// // </div> */}

// //         <>
// //           {!isRunClicked && (
// //             <div
// //               style={{
// //                 color: "#1976d2",
// //                 fontSize: "20px",
// //                 fontWeight: "bold",
// //                 display: "flex",
// //                 justifyContent: "center",
// //                 textAlign: "center", // Center text within the div
// //                 marginBottom: "10px", // Added margin for separation from the second div
// //                 marginRight: "110px"
// //               }}
// //             >
// //               {tempPeriodType === "Monthly" &&
// //                 tempYear &&
// //                 tempMonth &&
// //                 `${tempMonth} - ${tempYear} - Invoices`}
// //             </div>
// //           )}
// //         </>
// //         {isRunClicked && (
// //           <>
// //             <div
// //               style={{
// //                 color: "#1976d2",
// //                 fontSize: "20px",
// //                 fontWeight: "bold",
// //                 display: "flex",
// //                 justifyContent: "center",
// //                 textAlign: "center", // Center text within the div
// //                 marginBottom: "10px", // Added margin for separation from the second div
// //                 marginRight: "110px"
// //               }}
// //             >
// //               {tempPeriodType === "Yearly" &&
// //                 tempYear &&
// //                 isRunClicked &&
// //                 `${tempYear} - Invoices`}
// //               {tempPeriodType === "Monthly" &&
// //                 tempYear &&
// //                 tempMonth &&
// //                 `${tempMonth} - ${tempYear} - Invoices`}
// //               {tempPeriodType === "dateWise" &&
// //                 tempDate &&
// //                 isRunClicked &&
// //                 `${formatDatetodisplay(tempDate)} - Invoices`}
// //               {tempPeriodType === "Quarterly" &&
// //                 tempYear &&
// //                 tempQuarterName &&
// //                 isRunClicked &&
// //                 ` ${tempQuarterName == "Q1" ? "Q1 (Mar-May)" : ""} ${tempQuarterName == "Q2" ? "Q2 (June-Aug)" : ""} ${tempQuarterName == "Q3" ? "Q3 (Sep-Nov)" : ""} ${tempQuarterName == "Q4" ? "Q4 (Dec-Feb)" : ""} - ${tempYear} - Invoices`}
// //             </div>
// //             <hr className="border-t border-gray-300 mb-1 mt-2" />
// //           </>
// //         )}
// //         <div className="mt-8"> 
// //         <CustomerPortalTable customerResponse={customerResponse} />
// //         </div>
      
// //       </div> </>
// //       </div>
// //       </>
      
// //   );
// // }

// // export default AllInvoicesReports;




// import React, { useState, useRef, useEffect } from "react";
// import { Box, Grid, Button } from "@mui/material";
// import SendIcon from "@mui/icons-material/Send";
// // import CustomerPortalTable from "./CustomerPortalTable";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// // import config from '../../config/config';
// import axios from "axios";
// import config from "../../../config/config";
// import CustomerPortalTable from "../CustomerPortalTable";
// import { FaHome } from "react-icons/fa";
// import { useNavigate } from "react-router-dom";

// function AllInvoicesReports() {
//   const datePickerRef = useRef(null);
// const navigate = useNavigate();
//   const [periodType, setPeriodType] = useState(""); // Tracks the selected period (Year, Month)
//   const [selectedYear, setSelectedYear] = useState(""); // Tracks the selected year
//   const [selectedMonth, setSelectedMonth] = useState(""); // Tracks the selected month
//   const [selectedQuarter, setSelectedQuarter] = useState("");

//   const [selectedDate, setSelectedDate] = useState(null); // Initialize as null
//   const [customerResponse, setCustomerResponse] = useState([]); // Initialize as null
//   const [isRunClicked, setIsRunClicked] = useState(false);
//   const [tempMonth, setTempMonth] = useState(""); // Tracks the selected year
//   const [tempDate, setTempDate] = useState("");

//   const [tempYear, setTempYear] = useState(""); // Tracks the selected year
//   const [tempQuarterName, settempQuarterName] = useState(""); // Tracks the selected year

//   const [tempPeriodType, setTempPeriodType] = useState(""); // Tracks the selected year

//   // const years = ["2025","2024", "2023", "2022", "2021"];
//   const months = [
//     "January",
//     "February",
//     "March",
//     "April",
//     "May",
//     "June",
//     "July",
//     "August",
//     "September",
//     "October",
//     "November",
//     "December"
//   ];
//   const user_id = localStorage.getItem("user_id");
//   const user_role = localStorage.getItem("user_role");
//   const business_id = localStorage.getItem("business_id");
//   const [userRole, setUserRole] = useState("");

//   const [years, setYears] = useState([]);
//   // useEffect(() => {
//   //   setSelectedYear(new Date().getFullYear().toString());
//   // }, []);
//   useEffect(() => {
//     console.log("Fetching business profile data...");

//     axios
//       .get(
//         `${baseURL}/${btoa("business/profile")}/${btoa(business_id)}/${btoa(
//           user_id
//         )}/${btoa(user_role)}`
//       )
//       .then((response) => {
//         console.log("API response received");

//         try {
//           // Extract the added_on date string
//           const addedOnDate = response.data.business_data[0].added_on;
//           console.log("Raw added_on date:", addedOnDate);

//           // Extract just the year from the date string (e.g., "2025-02-17" → 2025)
//           const startYear = parseInt(addedOnDate.substring(0, 4));
//           console.log("Extracted start year:", startYear);

//           // Validate the year is a reasonable value
//           if (isNaN(startYear) || startYear < 2000 || startYear > 2100) {
//             console.error("Invalid year value:", startYear);
//             return;
//           }

//           // Generate years array from startYear to current year
//           const currentYear = new Date().getFullYear();
//           const yearArray = [];

//           // Add years in descending order (newest first)
//           for (let year = currentYear; year >= startYear; year--) {
//             yearArray.push(year);
//           }

//           console.log("Generated years array:", yearArray);

//           // Update state with the generated years
//           setYears(yearArray);

//           // Set default selected year to current year
//           // if (yearArray.length > 0) {
//           //   setSelectedYear(currentYear.toString());
//           // }
//         } catch (error) {
//           console.error("Error processing business data:", error);
//         }
//       })
//       .catch((error) => {
//         console.error("Error fetching business data:", error);
//       });
//   }, []);
//   const quarters = [
//     { bName: "Q1", fName: "Q1 (Mar-May)" },
//     { bName: "Q2", fName: "Q2 (June-Aug)" },
//     { bName: "Q3", fName: "Q3 (Sep-Nov)" },
//     { bName: "Q4", fName: "Q4 (Dec-Feb)" }
//   ];
//   const handleDateChange = (date) => {
//     if (date) {
//       setSelectedDate(date); // Only set the date when the user actually picks a valid date
//     }
//   };

//   const handleKeyPress = (e) => {
//     if (e.key === "Enter" && isButtonEnabled()) {
//       handleRun();
//     }
//   };

//   // Handles period type change (Year, Month)
//   const handlePeriodChange = (event) => {
//     setPeriodType(event.target.value);
//     setSelectedYear("");
//     setSelectedMonth("");
//     setSelectedDate("");
//   };
//   useEffect(() => {
//     if (periodType && datePickerRef.current) {
//       datePickerRef.current.setFocus(); // Focus on the DatePicker input to open the calendar
//     }
//   }, [periodType]);
//   // Enables the Run button only when valid selections are made
//   // const isButtonEnabled = () => {
//   //   if (periodType === "Yearly" && selectedYear) return true;
//   //   if (periodType === "Monthly" && selectedYear && selectedMonth) return true;
//   //   if (periodType === "dateWise" && selectedDate) return true;
//   //   if (periodType === "Quarterly" && selectedYear && selectedQuarter)
//   //     return true;

//   //   return false;
//   // };
//   const isButtonEnabled = () => {
//     if (periodType === "Yearly" && selectedYear) return true;
//     if (periodType === "Monthly" && selectedYear && selectedMonth) return true;
//     if (periodType === "Quarterly" && selectedYear && selectedQuarter) return true;
    
//     return false;
//   };
//   const baseURL =
//     process.env.NODE_ENV === "production"
//       ? config.production.baseURL
//       : config.development.baseURL;
//   const formattedDate = selectedDate
//     ? selectedDate.toLocaleDateString("en-CA")
//     : null;
//   function formatDatetodisplay(dateInput) {
//     const date = new Date(dateInput); // Convert the input to a Date object

//     const month = date.toLocaleString("default", { month: "long" }); // Full month name
//     const day = date.getDate(); // Day of the month
//     const year = date.getFullYear(); // Year

//     return `${month} ${day} ${year}`;
//   }
//   const formattedDateCustomer = selectedDate
//     ? selectedDate.toLocaleDateString("en-CA")
//     : null;

//   const handleRun = async () => {
//     console.log("runclicked");
//     setTempPeriodType(periodType);
//     setTempDate(selectedDate);
//     setTempMonth(selectedMonth);
//     setTempYear(selectedYear);
//     settempQuarterName(selectedQuarter);
//     setIsRunClicked(true);
//     // Handle the action when the "Run" button is clicked
//     try {
//       const response = await axios.post(
//         `${baseURL}/${btoa("reports/viewinvoicecustportfilter")}`,
//         {
//           reportType: periodType,
//           monthName: selectedMonth,
//           quarterName: selectedQuarter,
//           year: selectedYear,
//           selectDate: formattedDateCustomer,
//           businessId: localStorage.getItem("business_id"),
//           customerId: localStorage.getItem("customer_id"),
//           contactId: localStorage.getItem("contact_id")
//         }
//       );
//       console.log("response.....", response);
//       setCustomerResponse(response);
//     } catch (error) {
//       console.log("error posting details", error);
//     }
//   };
//   useEffect(() => {
//     setTempPeriodType("");
//     setTempMonth(months[new Date().getMonth()]);
//     setTempYear(new Date().getFullYear());
//   }, []);

//   useEffect(() => {
//     setPeriodType("");
//     setSelectedMonth(months[new Date().getMonth()]);
//     setSelectedYear(new Date().getFullYear());
//     const fetchInvoiceData = async () => {
//       try {
//         const response = await axios.post(
//           `${baseURL}/${btoa("reports/viewinvoicecustportfilter")}`,
//           {
//             reportType: "Monthly",
//             monthName: months[new Date().getMonth()],
//             quarterName: null,
//             year: new Date().getFullYear(),
//             businessId: localStorage.getItem("business_id"),
//             customerId: localStorage.getItem("customer_id"),
//             contactId: localStorage.getItem("contact_id")
//           }
//         );
//         console.log("response.....", response);
//         setCustomerResponse(response);
//       } catch (error) {
//         console.log("error posting details", error);
//       }
//     };

//     fetchInvoiceData();
//   }, []); // Add dependencies if needed

//   return (
//     <>
//     {/* <Box
//       sx={{
//         display: "flex",
//         justifyContent: "start",
//         alignItems: "center",
//         padding: "20px",
//         marginLeft: "43px",
//         marginRight: "30px",
//         marginTop: "30px",
//         borderRadius: "20px",
//         boxShadow: "0 0 20px 0 rgba(0, 0, 0, .2)",
//         paddingBottom: "30px",
//         marginBottom: "30px"
//       }}
//     >
//       <Grid container spacing={4}>
//         <Grid item md={3}>
//           <div>
//             <label
//               className="block mb-2 text-base font-medium text-gray-900"
//               style={{ whiteSpace: "nowrap" }}
//             >
//               Select Report Type
//               <span className="text-red-500 text-xl font-extrabold pl-2 pt-3">
//                 *
//               </span>
//             </label>
//             <select
//               required
//               onChange={handlePeriodChange}
//               value={periodType}
//               className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
//             >
//               <option value="">Select Report Type</option>
//               <option value="dateWise">Date</option>
//               <option value="Monthly">Monthly</option>
//               <option value="Quarterly">Quarterly</option>
//               <option value="Yearly">Yearly</option>
//             </select>
//           </div>
//         </Grid>
//         {periodType === "dateWise" && (
//           <Grid item md={3}>
//             <label
//               for="Select Date"
//               className="block mb-2 text-base font-medium text-gray-900 "
//             >
//               Select Date
//               <span className="text-red-500 text-xl font-extrabold pl-2 pt-3">
//                 *
//               </span>
//             </label>
//             <DatePicker
//               ref={datePickerRef}
//               selected={selectedDate}
//               onChange={handleDateChange}
//               dateFormat="MM-dd-yyyy"
//               className="date-picker"
//               style={{ height: "43px", width: "100%" }}
//               maxDate={new Date()} />
//           </Grid>
//         )}
//         {periodType === "Monthly" && (
//           <Grid item md={3}>
//             <div>
//               <label className="block mb-2 text-base font-medium text-gray-900">
//                 Select Month
//                 <span className="text-red-500 text-xl font-extrabold pl-2 pt-3">
//                   *
//                 </span>
//               </label>
//               <select
//                 required
//                 value={selectedMonth}
//                 onChange={(e) => {
//                   setSelectedMonth(e.target.value);
//                 } }
//                 className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
//               >
//                 <option value="">Select Month</option>
//                 {months.map((month) => (
//                   <option key={month} value={month}>
//                     {month}
//                   </option>
//                 ))}
//               </select>
//             </div>
//           </Grid>
//         )}
//         {periodType === "Quarterly" && (
//           <Grid item md={3}>
//             <div>
//               <label
//                 for="Select Quarter"
//                 className="block mb-2 text-base font-medium text-gray-900 "
//               >
//                 Select Quarter
//                 <span className="text-red-500 text-xl font-extrabold pl-2 pt-3">
//                   *
//                 </span>
//               </label>
//               <select
//                 id="Select Quarter"
//                 name="Select Quarter"
//                 required
//                 value={selectedQuarter}
//                 onChange={(e) => {
//                   setSelectedQuarter(e.target.value);
//                 } }
//                 label="Select Quarter"
//                 className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
//               >
//                 <option value="">--Select Quarter--</option>
//                 {quarters.map((quarter) => (
//                   <option
//                     key={quarter.fName}
//                     value={quarter.bName}
//                     sx={{ margin: 0, padding: "1px 16px" }}
//                   >
//                     {quarter.fName}
//                   </option>
//                 ))}
//               </select>
//             </div>
//           </Grid>
//         )}

//         {(periodType === "Monthly" ||
//           periodType === "Yearly" ||
//           periodType === "Quarterly") && (
//             <Grid item md={3}>
//               <div>
//                 <label className="block mb-2 text-base font-medium text-gray-900">
//                   Select Year
//                   <span className="text-red-500 text-xl font-extrabold pl-2 pt-3">
//                     *
//                   </span>
//                 </label>
//                 <select
//                   required
//                   value={selectedYear}
//                   onChange={(e) => {
//                     setSelectedYear(e.target.value);
//                   } }
//                   className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
//                 >
//                   <option value="">Select Year</option>
//                   {years.map((year) => (
//                     <option key={year} value={year}>
//                       {year}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             </Grid>
//           )}

//         <Grid item md={3}>
//           {/* Run Button */}
//           {/* <Button
//             variant="contained"
//             sx={{
//               backgroundColor: "rgb(231 70 148)", // Pink color
//               color: "white",
//               marginTop: "36px",
//               height: "43px",
//               "&:hover": {
//                 backgroundColor: "rgb(231 70 148)" // Darker shade of pink on hover
//               }
//             }}
//             endIcon={<SendIcon />}
//             disabled={!isButtonEnabled()}
//             onClick={handleRun}
//           >
//             Run
//           </Button>
//         </Grid>
//       </Grid>
//     </Box> */} 
    
//     <div className="ml-5">
//     <>
//       <div
//         className="flex justify-between items-center w-full pt-4 mt-4 mb-5"
//         style={{
//           maxWidth: "1200px",
//           boxSizing: "border-box",
//           marginLeft: "4px",
//           color: "#444444"
//         }}
//       >
//           <nav aria-label="breadcrumb">
//             <ol className="flex space-x-2 text-lg font-bold text-gray-700">
//               <li>Reports</li>
//               <li>
//                 <span className="text-gray-400">{">>"}</span>
//               </li>
//               <li>All Invoices</li>
//             </ol>
//           </nav>
//         </div>
//     <div
//   className="relative py-[12px] px-[8px] mt-4 rounded-xl bg-white"
//   style={{ boxShadow: "0 0 25px 0 rgba(0, 0, 0, 0.2)" }}
// >
// <div className="flex items-center justify-end">
//             {/* Home button - bordered/unfilled by default, filled blue on hover */}
//             <div
//               className="absolute -top-4 flex items-center justify-center cursor-pointer rounded-full bg-[#cefafe] hover:bg-[#25caed] w-9 h-9 mb-0 mr-6 border-[0.5px] border-[#25caed] hover:border-[#25caed] hover:scale-110 shadow-md transition-all duration-200 group"
//               onClick={() => {
//                 navigate("/");
//               }}
//               style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
//             >
//               <FaHome
//                 className="text-[#25caed] group-hover:text-white transition-colors duration-200"
//                 size={16}
//               />
//             </div>

//             {/* Close Icon - bordered/unfilled by default, filled red on hover */}
//             <div
//               className="absolute -top-4 -right-2 bg-red-200 hover:bg-red-500 w-9 h-9 flex items-center justify-center rounded-full cursor-pointer hover:bg-red-700 border-[0.5px] border-red-500 hover:border-red-700 hover:scale-110 shadow-md transition-all duration-200 group"
//               onClick={() => navigate("/customerreport")}
//             >
//               <span className="text-red-500 group-hover:text-white text-xl font-bold mb-1.5 transition-colors duration-200">
//                 &times;
//               </span>
//             </div>
//           </div>
//   <Grid
//     container
//     spacing={3}
//     alignItems="center"
//     justifyContent="start"
//     className="pl-[10px] pr-6"
//   >
//     {/* Report Type Selection */}
//     <Grid item md={2.1}>
//       <div>
//         <label className="block mb-2 text-[14px] font-medium text-gray-600 inline-flex items-center">
//           Duration
//           <span className="text-red-500 text-xl font-extrabold pl-1">*</span>
//         </label>
//         <select
//           id="periodType"
//           value={periodType}
//           onChange={handlePeriodChange}
//           onKeyPress={handleKeyPress}
//           className="border border-gray-300 text-sm font-medium rounded-lg focus:ring-grey-600 focus:border-primary-600 block w-full text-gray-500 py-1 px-2 h-[30px]"
//         > <option value="">--Select--</option>
//           <option value="Monthly">Monthly</option>
//           <option value="Quarterly">Quarterly</option>
//           <option value="Yearly">Yearly</option>
//           <option value="CustomRange">Date</option>
//         </select>
//       </div>
//     </Grid>

//     {/* Monthly Fields */}
//     {periodType === "Monthly" && (
//       <>
//         <Grid item md={2.4} xs={12}>
//           <div>
//             <label className="block mb-2 text-[14px] font-medium text-gray-600 inline-flex items-center">
//               Select Month
//               <span className="text-red-500 text-xl font-extrabold pl-1">*</span>
//             </label>
//             <select
//               id="selectedMonth"
//               value={selectedMonth}
//               onChange={(e) => setSelectedMonth(e.target.value)}
//               onKeyPress={handleKeyPress}
//               className="border border-gray-300 text-sm font-medium rounded-lg focus:ring-grey-600 focus:border-primary-600 block w-full text-gray-500 py-1 px-2 h-[30px]"
//             >
//               <option value="">--Select Month--</option>
//               {months.map((month) => (
//                 <option key={month} value={month}>{month}</option>
//               ))}
//             </select>
//           </div>
//         </Grid>
//         <Grid item md={2.4} xs={12}>
//           <div>
//             <label className="block mb-2 text-[14px] font-medium text-gray-600 inline-flex items-center">
//               Select Year
//               <span className="text-red-500 text-xl font-extrabold pl-1">*</span>
//             </label>
//             <select
//               id="selectedYear"
//               value={selectedYear}
//               onChange={(e) => setSelectedYear(e.target.value)}
//               onKeyPress={handleKeyPress}
//               className="border border-gray-300 text-sm font-medium rounded-lg focus:ring-grey-600 focus:border-primary-600 block w-full text-gray-500 py-1 px-2 h-[30px]"
//             >
//               <option value="">--Select Year--</option>
//               {years.map((year) => (
//                 <option key={year} value={year}>{year}</option>
//               ))}
//             </select>
//           </div>
//         </Grid>
//       </>
//     )}

//     {/* Quarterly Fields */}
//     {periodType === "Quarterly" && (
//       <>
//         <Grid item md={2.4} xs={12}>
//           <div>
//             <label className="block mb-2 text-[14px] font-medium text-gray-600 inline-flex items-center">
//               Select Quarter
//               <span className="text-red-500 text-xl font-extrabold pl-1">*</span>
//             </label>
//             <select
//               id="selectedQuarter"
//               value={selectedQuarter}
//               onChange={(e) => setSelectedQuarter(e.target.value)}
//               onKeyPress={handleKeyPress}
//               className="border border-gray-300 text-sm font-medium rounded-lg focus:ring-grey-600 focus:border-primary-600 block w-full text-gray-500 py-1 px-2 h-[30px]"
//             >
//               <option value="">--Select Quarter--</option>
//               {quarters.map((quarter) => (
//                 <option key={quarter.bName} value={quarter.bName}>{quarter.fName}</option>
//               ))}
//             </select>
//           </div>
//         </Grid>
//         <Grid item md={2.4} xs={12}>
//           <div>
//             <label className="block mb-2 text-[14px] font-medium text-gray-600 inline-flex items-center">
//               Select Year
//               <span className="text-red-500 text-xl font-extrabold pl-1">*</span>
//             </label>
//             <select
//               id="selectedYear"
//               value={selectedYear}
//               onChange={(e) => setSelectedYear(e.target.value)}
//               onKeyPress={handleKeyPress}
//               className="border border-gray-300 text-sm font-medium rounded-lg focus:ring-grey-600 focus:border-primary-600 block w-full text-gray-500 py-1 px-2 h-[30px]"
//             >
//               <option value="">--Select Year--</option>
//               {years.map((year) => (
//                 <option key={year} value={year}>{year}</option>
//               ))}
//             </select>
//           </div>
//         </Grid>
//       </>
//     )}

//     {/* Yearly Fields */}
//     {periodType === "Yearly" && (
//       <Grid item md={2.4} xs={12}>
//         <div>
//           <label className="block mb-2 text-[14px] font-medium text-gray-600 inline-flex items-center">
//             Select Year
//             <span className="text-red-500 text-xl font-extrabold pl-1">*</span>
//           </label>
//           <select
//             id="selectedYear"
//             value={selectedYear}
//             onChange={(e) => setSelectedYear(e.target.value)}
//             onKeyPress={handleKeyPress}
//             className="border border-gray-300 text-sm font-medium rounded-lg focus:ring-grey-600 focus:border-primary-600 block w-full text-gray-500 py-1 px-2 h-[30px]"
//           >
//             <option value="">--Select Year--</option>
//             {years.map((year) => (
//               <option key={year} value={year}>{year}</option>
//             ))}
//           </select>
//         </div>
//       </Grid>
//     )}

   
//     {/* Run Button */}
//     <Grid item>
//       <Button
//         variant="contained"
//         disabled={!isButtonEnabled()}
//         sx={{
//           backgroundColor: "rgb(231 70 148)",
//           color: "white",
//           marginTop: "34px",
//           height: "30px",
//           "&:hover": {
//             backgroundColor: "rgb(231 70 148)"
//           }
//         }}
//         endIcon={<SendIcon />}
//         onClick={handleRun}
//       >
//         Run
//       </Button>
//     </Grid>
//   </Grid>
// </div>
    
//     <div
//       style={{
//         borderRadius: "20px",
//         // maxWidth: '100%',
//         overflowX: "auto",
//         margin: "0 auto",
//         flexDirection: "column",
//         boxShadow: "0 0 25px 0 rgba(0, 0, 0, 0.2)",
//         margin: "25px",
//         marginTop: "10px",
//         paddingBottom: "80px",
//         padding: "20px",
//         justifyContent: "center",
//         display: "flex",
//         flexDirection: "column",
//         backgroundColor: "white"
//       }}
//     >
//         {/* <div
// className="flex justify-between items-center w-full  pt-4 mt-4 "
// style={{
//   maxWidth: '1200px',
//   boxSizing: 'border-box',
//   marginLeft: '40px',

// }}
// >
// <h1 className="font-bold text-xl" style={{borderBottom: '4px solid #3479E0', paddingBottom: '8px'}}>Customer Portal Report</h1>
// </div> */}

//         <>
//           {!isRunClicked && (
//             <div
//               style={{
//                 color: "#1976d2",
//                 fontSize: "20px",
//                 fontWeight: "bold",
//                 display: "flex",
//                 justifyContent: "center",
//                 textAlign: "center", // Center text within the div
//                 marginBottom: "10px", // Added margin for separation from the second div
//                 marginRight: "110px"
//               }}
//             >
//               {tempPeriodType === "Monthly" &&
//                 tempYear &&
//                 tempMonth &&
//                 `${tempMonth} - ${tempYear} - Invoices`}
//             </div>
//           )}
//         </>
//         {isRunClicked && (
//           <>
//             <div
//               style={{
//                 color: "#1976d2",
//                 fontSize: "20px",
//                 fontWeight: "bold",
//                 display: "flex",
//                 justifyContent: "center",
//                 textAlign: "center", // Center text within the div
//                 marginBottom: "10px", // Added margin for separation from the second div
//                 marginRight: "110px"
//               }}
//             >
//               {tempPeriodType === "Yearly" &&
//                 tempYear &&
//                 isRunClicked &&
//                 `${tempYear} - Invoices`}
//               {tempPeriodType === "Monthly" &&
//                 tempYear &&
//                 tempMonth &&
//                 `${tempMonth} - ${tempYear} - Invoices`}
//               {tempPeriodType === "dateWise" &&
//                 tempDate &&
//                 isRunClicked &&
//                 `${formatDatetodisplay(tempDate)} - Invoices`}
//               {tempPeriodType === "Quarterly" &&
//                 tempYear &&
//                 tempQuarterName &&
//                 isRunClicked &&
//                 ` ${tempQuarterName == "Q1" ? "Q1 (Mar-May)" : ""} ${tempQuarterName == "Q2" ? "Q2 (June-Aug)" : ""} ${tempQuarterName == "Q3" ? "Q3 (Sep-Nov)" : ""} ${tempQuarterName == "Q4" ? "Q4 (Dec-Feb)" : ""} - ${tempYear} - Invoices`}
//             </div>
//             <hr className="border-t border-gray-300 mb-1 mt-2" />
//           </>
//         )}
//         <div className="mt-8"> 
//         <CustomerPortalTable customerResponse={customerResponse} />
//         </div>
      
//       </div> 
//       </>
//       </div>
//       </>
      
//   );
// }

// export default AllInvoicesReports;






import React, { useState, useRef, useEffect } from "react";
import { Box, Grid, Button, IconButton } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PrintIcon from "@mui/icons-material/Print";
import DescriptionIcon from "@mui/icons-material/Description";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { Tooltip } from "react-tippy";
import config from "../../../config/config";
import CustomerPortalTable from "../CustomerPortalTable";
import { FaHome } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

function AllInvoicesReports() {
  const datePickerRef = useRef(null);
  const navigate = useNavigate();
  const contentRef = useRef();

  // State variables
  const [periodType, setPeriodType] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedQuarter, setSelectedQuarter] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  
  const [customerResponse, setCustomerResponse] = useState([]);
  const [isRunClicked, setIsRunClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  
  // UI states for reports view
  const [pdfhovered, setPdfHovered] = useState(false);
  const [taxhovered, setTaxHovered] = useState(false);
  const [dateTime, setDateTime] = useState("");

  // Temporary display variables
  const [tempMonth, setTempMonth] = useState("");
  const [tempDate, setTempDate] = useState("");
  const [tempYear, setTempYear] = useState("");
  const [tempQuarterName, setTempQuarterName] = useState("");
  const [tempPeriodType, setTempPeriodType] = useState("");

  // User info
  const user_id = localStorage.getItem("user_id");
  const user_role = localStorage.getItem("user_role");
  const business_id = localStorage.getItem("business_id");
  const [userRole, setUserRole] = useState("");
  const [years, setYears] = useState([]);
  const [imageSource, setImageSource] = useState();
  const [imageResponse, setImageResponse] = useState([]);

  // Options for selects
  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  
  const quarters = [
    { bName: "Q1", fName: "Q1 (Mar-May)" },
    { bName: "Q2", fName: "Q2 (June-Aug)" },
    { bName: "Q3", fName: "Q3 (Sep-Nov)" },
    { bName: "Q4", fName: "Q4 (Dec-Feb)" }
  ];

  // API base URL
  const baseURL =
    process.env.NODE_ENV === "production"
      ? config.production.baseURL
      : config.development.baseURL;

  // Enable button only when valid selections are made
  const isButtonEnabled = () => {
    if (periodType === "Yearly" && selectedYear) return true;
    if (periodType === "Monthly" && selectedYear && selectedMonth) return true;
    if (periodType === "Quarterly" && selectedYear && selectedQuarter) return true;
    return false;
  };

  // Handle key press for Enter key
  const handleKeyPress = (e) => {
    if (e.key === "Enter" && isButtonEnabled()) {
      handleRun();
    }
  };

  // Handle period type change
  const handlePeriodChange = (event) => {
    setPeriodType(event.target.value);
    setSelectedYear("");
    setSelectedMonth("");
    setSelectedQuarter("");
    setSelectedDate("");
  };

  // Handle date change
  const handleDateChange = (date) => {
    if (date) {
      setSelectedDate(date);
    }
  };

  // Format date for display
  function formatDateToDisplay(dateInput) {
    const date = new Date(dateInput);
    const month = date.toLocaleString("default", { month: "long" });
    const day = date.getDate();
    const year = date.getFullYear();
    
    return `${month} ${day} ${year}`;
  }

  // Format date for API
  const formattedDate = selectedDate
    ? selectedDate.toLocaleDateString("en-CA")
    : null;

  // Generate date/time for report
  const generateUSDateTime = () => {
    const currentDate = new Date();
    const formattedDateTime = currentDate.toLocaleString("en-US", {
      year: "numeric",
      month: "numeric",
      day: "numeric"
    });
    setDateTime(formattedDateTime);
  };

  // Run report - using your original logic
  const handleRun = async () => {
    console.log("runclicked");
    setTempPeriodType(periodType);
    setTempDate(selectedDate);
    setTempMonth(selectedMonth);
    setTempYear(selectedYear);
    setTempQuarterName(selectedQuarter);
    setIsRunClicked(true);
    setLoading(true);
    
    try {
      const response = await axios.post(
        `${baseURL}/${btoa("reports/viewinvoicecustportfilter")}`,
        {
          reportType: periodType,
          monthName: selectedMonth,
          quarterName: selectedQuarter,
          year: selectedYear,
          selectDate: formattedDate,
          businessId: localStorage.getItem("business_id"),
          customerId: localStorage.getItem("customer_id"),
          contactId: localStorage.getItem("contact_id")
        }
      );
      
      console.log("response.....", response);
      setCustomerResponse(response);
      setLoading(false);
    } catch (error) {
      console.log("error posting details", error);
      setLoading(false);
    }
  };

  // PDF Download handler
  const handlePdfDownload = async () => {
    // Implementation would go here similar to PoByBrandReport
    console.log("PDF download requested");
  };

  // Excel Download handler
  const handleExcelDownload = async () => {
    // Implementation would go here similar to PoByBrandReport
    console.log("Excel download requested");
  };

  // Print handler
  const handlePrint = () => {
    const content = contentRef.current.innerHTML;
    const newWindow = window.open("", "_blank");
    newWindow.document.write(`
      <html>
        <head>
          <title>Print Invoices</title>
          <style>
            @media print {
              img {
                display: block;
                width: 100px;
                height: auto;
              }
              @page {
                margin: 0.5cm;
              }
              body {
                margin: 0;
                padding: 0;
              }
              header, footer {
                display: none;
              }
            }
            body {
              font-family: Arial, sans-serif;
              margin: 30px;
            }
            table {
              width: 100%;
              border-collapse: collapse;
            }
            th, td {
              padding: 8px;
              text-align: left;
              border-bottom: 1px solid #ddd;
            }
            th {
              background-color: #f2f2f2;
            }
          </style>
        </head>
        <body>
          ${content}
        </body>
      </html>
    `);
    newWindow.document.close();
    newWindow.print();
  };

  // Fetch business profile data on component mount - using your original logic
  useEffect(() => {
    console.log("Fetching business profile data...");
    
    axios
      .get(
        `${baseURL}/${btoa("business/profile")}/${btoa(business_id)}/${btoa(
          user_id
        )}/${btoa(user_role)}`
      )
      .then((response) => {
        console.log("API response received");
        setImageSource(response.data.business_data[0].image_file);
        setImageResponse(response.data.business_data[0]);
        
        try {
          // Extract the added_on date string
          const addedOnDate = response.data.business_data[0].added_on;
          console.log("Raw added_on date:", addedOnDate);
          
          // Extract just the year from the date string (e.g., "2025-02-17" → 2025)
          const startYear = parseInt(addedOnDate.substring(0, 4));
          console.log("Extracted start year:", startYear);
          
          // Validate the year is a reasonable value
          if (isNaN(startYear) || startYear < 2000 || startYear > 2100) {
            console.error("Invalid year value:", startYear);
            return;
          }
          
          // Generate years array from startYear to current year
          const currentYear = new Date().getFullYear();
          const yearArray = [];
          
          // Add years in descending order (newest first)
          for (let year = currentYear; year >= startYear; year--) {
            yearArray.push(year);
          }
          
          console.log("Generated years array:", yearArray);
          
          // Update state with the generated years
          setYears(yearArray);
        } catch (error) {
          console.error("Error processing business data:", error);
        }
      })
      .catch((error) => {
        console.error("Error fetching business data:", error);
      });
  }, []);

  // Set default values on component mount - using your original logic
  useEffect(() => {
    setTempPeriodType("");
    setTempMonth(months[new Date().getMonth()]);
    setTempYear(new Date().getFullYear());
    setUserRole(localStorage.getItem("user_role"));
  }, []);

  // Fetch initial data on component mount - using your original logic
  useEffect(() => {
    setPeriodType("");
    setSelectedMonth(months[new Date().getMonth()]);
    setSelectedYear(new Date().getFullYear());
    
    const fetchInvoiceData = async () => {
      try {
        const response = await axios.post(
          `${baseURL}/${btoa("reports/viewinvoicecustportfilter")}`,
          {
            reportType: "Monthly",
            monthName: months[new Date().getMonth()],
            quarterName: null,
            year: new Date().getFullYear(),
            businessId: localStorage.getItem("business_id"),
            customerId: localStorage.getItem("customer_id"),
            contactId: localStorage.getItem("contact_id")
          }
        );
        
        console.log("response.....", response);
        setCustomerResponse(response);
      } catch (error) {
        console.log("error posting details", error);
      }
    };

    fetchInvoiceData();
  }, []);

  // Styles
  const labelStyle = "block mb-2 text-[14px] font-medium text-gray-600 inline-flex items-center";
  const inputStyle = "border border-gray-300 text-sm font-medium rounded-lg focus:ring-grey-600 focus:border-primary-600 block w-full text-gray-500 py-1 px-2 h-[30px]";
  const buttonStyle = {
    backgroundColor: "rgb(231 70 148)",
    color: "white",
    marginTop: "34px",
    height: "30px",
    "&:hover": {
      backgroundColor: "rgb(231 70 148)"
    }
  };

  return (
    <div className="ml-5">
      <>
        <div
          className="flex justify-between items-center w-full pt-4 mt-4 mb-5"
          style={{
            maxWidth: "1200px",
            boxSizing: "border-box",
            marginLeft: "4px",
            color: "#444444"
          }}
        >
          <nav aria-label="breadcrumb">
            <ol className="flex space-x-2 text-lg font-bold text-gray-700">
              <li>Reports</li>
              <li>
                <span className="text-gray-400">{">>"}</span>
              </li>
              <li>All Invoices</li>
            </ol>
          </nav>
        </div>
        
        {/* Filter Controls */}
        <div
          className="relative py-[12px] px-[8px] mt-4 rounded-xl bg-white"
          style={{ boxShadow: "0 0 25px 0 rgba(0, 0, 0, 0.2)" }}
        >
          <div className="flex items-center justify-end">
            {/* Home button */}
            <div
              className="absolute -top-4 flex items-center justify-center cursor-pointer rounded-full bg-[#cefafe] hover:bg-[#25caed] w-9 h-9 mb-0 mr-6 border-[0.5px] border-[#25caed] hover:border-[#25caed] hover:scale-110 shadow-md transition-all duration-200 group"
              onClick={() => navigate("/")}
              style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
            >
              <FaHome
                className="text-[#25caed] group-hover:text-white transition-colors duration-200"
                size={16}
              />
            </div>

            {/* Close Icon */}
            <div
              className="absolute -top-4 -right-2 bg-red-200 hover:bg-red-500 w-9 h-9 flex items-center justify-center rounded-full cursor-pointer hover:bg-red-700 border-[0.5px] border-red-500 hover:border-red-700 hover:scale-110 shadow-md transition-all duration-200 group"
              onClick={() => navigate("/customerreport")}
            >
              <span className="text-red-500 group-hover:text-white text-xl font-bold mb-1.5 transition-colors duration-200">
                &times;
              </span>
            </div>
          </div>
          
          <Grid
            container
            spacing={3}
            alignItems="center"
            justifyContent="start"
            className="pl-[10px] pr-6"
          >
            {/* Report Type Selection */}
            <Grid item md={2.1}>
              <div>
                <label className={labelStyle}>
                  Duration
                  <span className="text-red-500 text-xl font-extrabold pl-1">*</span>
                </label>
                <select
                  id="periodType"
                  value={periodType}
                  onChange={handlePeriodChange}
                  onKeyPress={handleKeyPress}
                  className={inputStyle}
                >
                  <option value="">--Select--</option>
                  <option value="Monthly">Monthly</option>
                  <option value="Quarterly">Quarterly</option>
                  <option value="Yearly">Yearly</option>
                </select>
              </div>
            </Grid>

            {/* Monthly Fields */}
            {periodType === "Monthly" && (
              <>
                <Grid item md={2.4} xs={12}>
                  <div>
                    <label className={labelStyle}>
                      Select Month
                      <span className="text-red-500 text-xl font-extrabold pl-1">*</span>
                    </label>
                    <select
                      id="selectedMonth"
                      value={selectedMonth}
                      onChange={(e) => setSelectedMonth(e.target.value)}
                      onKeyPress={handleKeyPress}
                      className={inputStyle}
                    >
                      <option value="">--Select Month--</option>
                      {months.map((month) => (
                        <option key={month} value={month}>{month}</option>
                      ))}
                    </select>
                  </div>
                </Grid>
                <Grid item md={2.4} xs={12}>
                  <div>
                    <label className={labelStyle}>
                      Select Year
                      <span className="text-red-500 text-xl font-extrabold pl-1">*</span>
                    </label>
                    <select
                      id="selectedYear"
                      value={selectedYear}
                      onChange={(e) => setSelectedYear(e.target.value)}
                      onKeyPress={handleKeyPress}
                      className={inputStyle}
                    >
                      <option value="">--Select Year--</option>
                      {years.map((year) => (
                        <option key={year} value={year}>{year}</option>
                      ))}
                    </select>
                  </div>
                </Grid>
              </>
            )}

            {/* Quarterly Fields */}
            {periodType === "Quarterly" && (
              <>
                <Grid item md={2.4} xs={12}>
                  <div>
                    <label className={labelStyle}>
                      Select Quarter
                      <span className="text-red-500 text-xl font-extrabold pl-1">*</span>
                    </label>
                    <select
                      id="selectedQuarter"
                      value={selectedQuarter}
                      onChange={(e) => setSelectedQuarter(e.target.value)}
                      onKeyPress={handleKeyPress}
                      className={inputStyle}
                    >
                      <option value="">--Select Quarter--</option>
                      {quarters.map((quarter) => (
                        <option key={quarter.bName} value={quarter.bName}>{quarter.fName}</option>
                      ))}
                    </select>
                  </div>
                </Grid>
                <Grid item md={2.4} xs={12}>
                  <div>
                    <label className={labelStyle}>
                      Select Year
                      <span className="text-red-500 text-xl font-extrabold pl-1">*</span>
                    </label>
                    <select
                      id="selectedYear"
                      value={selectedYear}
                      onChange={(e) => setSelectedYear(e.target.value)}
                      onKeyPress={handleKeyPress}
                      className={inputStyle}
                    >
                      <option value="">--Select Year--</option>
                      {years.map((year) => (
                        <option key={year} value={year}>{year}</option>
                      ))}
                    </select>
                  </div>
                </Grid>
              </>
            )}

            {/* Yearly Fields */}
            {periodType === "Yearly" && (
              <Grid item md={2.4} xs={12}>
                <div>
                  <label className={labelStyle}>
                    Select Year
                    <span className="text-red-500 text-xl font-extrabold pl-1">*</span>
                  </label>
                  <select
                    id="selectedYear"
                    value={selectedYear}
                    onChange={(e) => setSelectedYear(e.target.value)}
                    onKeyPress={handleKeyPress}
                    className={inputStyle}
                  >
                    <option value="">--Select Year--</option>
                    {years.map((year) => (
                      <option key={year} value={year}>{year}</option>
                    ))}
                  </select>
                </div>
              </Grid>
            )}

            {/* Run Button */}
            <Grid item>
              <Button
                variant="contained"
                disabled={!isButtonEnabled()}
                sx={buttonStyle}
                endIcon={<SendIcon />}
                onClick={handleRun}
              >
                Run
              </Button>
            </Grid>
          </Grid>
        </div>
        
        {/* Results Display */}
        <div
          style={{
            borderRadius: "20px",
            overflowX: "auto",
            margin: "0 auto",
            flexDirection: "column",
            boxShadow: "0 0 25px 0 rgba(0, 0, 0, 0.2)",
            margin: "25px",
            marginTop: "10px",
            paddingBottom: "80px",
            padding: "20px",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "white"
          }}
        >
          {/* Header with title */}
          {!isRunClicked && (
            <div
              style={{
                color: "#1976d2",
                fontSize: "20px",
                fontWeight: "bold",
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
                marginBottom: "10px",
                marginRight: "110px"
              }}
            >
              {tempPeriodType === "Monthly" &&
                tempYear &&
                tempMonth &&
                `${tempMonth} - ${tempYear} - Invoices`}
            </div>
          )}

          {isRunClicked && (
            <>
              <div
                style={{
                  color: "#1976d2",
                  fontSize: "20px",
                  fontWeight: "bold",
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  marginBottom: "10px",
                  marginRight: "110px"
                }}
              >
                {tempPeriodType === "Yearly" &&
                  tempYear &&
                  isRunClicked &&
                  `${tempYear} - Invoices`}
                {tempPeriodType === "Monthly" &&
                  tempYear &&
                  tempMonth &&
                  `${tempMonth} - ${tempYear} - Invoices`}
                {tempPeriodType === "dateWise" &&
                  tempDate &&
                  isRunClicked &&
                  `${formatDateToDisplay(tempDate)} - Invoices`}
                {tempPeriodType === "Quarterly" &&
                  tempYear &&
                  tempQuarterName &&
                  isRunClicked &&
                  ` ${tempQuarterName == "Q1" ? "Q1 (Mar-May)" : ""} ${tempQuarterName == "Q2" ? "Q2 (June-Aug)" : ""} ${tempQuarterName == "Q3" ? "Q3 (Sep-Nov)" : ""} ${tempQuarterName == "Q4" ? "Q4 (Dec-Feb)" : ""} - ${tempYear} - Invoices`}
              </div>
              <hr className="border-t border-gray-300 mb-1 mt-2" />
            </>
          )}
          
          {/* Data table */}
          <div className="mt-8" ref={contentRef}>
            {loading ? (
              <div className="flex justify-center items-center h-40">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-purple-500"></div>
              </div>
            ) : customerResponse && customerResponse.data && customerResponse.data.length === 0 ? (
              <div className="flex justify-center items-center py-8">
                <p className="text-red-500 text-xl font-semibold px-6 py-3 bg-red-50 rounded-lg border border-red-200">
                  No Data Available
                </p>
              </div>
            ) : (
              <CustomerPortalTable customerResponse={customerResponse} />
            )}
          </div>
        </div>
      </>
    </div>
  );
}

export default AllInvoicesReports;





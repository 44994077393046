// import React, { useState } from "react";
// import { FiSettings, FiShoppingCart, FiFileText, FiDollarSign, FiBarChart } from "react-icons/fi";
// // import Operations from "./Operations";
// import Sales from "./SalesReports/Sales";
// import Billing from "./BillingReports/Billings";
// // import Sales from "./Sales";
// // import Billing from "./Billing";
// // import Accounting from "./Accounting";
// // import Analytics from "./Analytics";
// // import {  MdOutlineBusinessCenter } from "react-icons/md";
// // import { FaBalanceScale } from 'react-icons/fa';
// // import Trending from './Trending'
// // import { TrendingUp } from "lucide-react";


// const menuItems = [
//   // { key: "operations", label: "Operations", icon: <MdOutlineBusinessCenter />, component: <Operations /> },
//   { key: "sales", label: "Sales", icon: <FiShoppingCart />, component: <Sales /> },
//   { key: "billing", label: "Billing", icon: <FiFileText />, component: <Billing /> },
//   // { key: "accounting", label: "Accounting", icon: <FaBalanceScale />, component: <Accounting /> },
//   // { key: "Trending", label: "Trending", icon: <TrendingUp />, component: <Trending /> },

//   // { key: "analytics", label: "Analytics", icon: <FiBarChart />, component: <Analytics /> },
// ];

// const CustomerReport = () => {
//   const [activeTab, setActiveTab] = useState(menuItems[0].key);

//   return (
//     <div className="flex flex-col">
//       {/* Tab Menu */}
//       <div className="flex mb-[60px] justify-center p-4 rounded-md font-bold text-md items-center w-full mx-auto pb-2 pt-0 custombgColor">
//         {/* {menuItems.map((tab) => (
//           <div
//             key={tab.key}
//             // className={`flex items-center cursor-pointer px-6 py-2  ${
//             //   activeTab === tab.key
//             //     ? "border-b-2 border-green-500 text-green-600 font-semibold"
//             //     : "text-gray-600"
//             // } border-b border-gray-300`}

//             className={`flex items-center cursor-pointer px-4 py-1 ${
//               activeTab === tab.key ? "border-b-2 border-green-500 text-green-600" : "text-gray-600"
//             } border-b border-gray-300  `}

//             onClick={() => setActiveTab(tab.key)}
//           >
//             <span className="mr-2 text-lg">{tab.icon}</span>
//             <span>{tab.label}</span>
//           </div>
//         ))} */}
//       </div>

//       {/* Dynamic Content */}
//       <div className="flex-1 px-6">
//         {/* {menuItems.find((tab) => tab.key === activeTab)?.component} */}
//       </div>
//     </div>
//   );
// };

// export default CustomerReport;




import React, { useState } from 'react';
import { FiChevronRight, FiShoppingCart, FiFileText } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

const CustomerReport = () => {
  const navigate = useNavigate();
  const [activeItem, setActiveItem] = useState(null);
  const [hoveredItem, setHoveredItem] = useState(null);
  const itemsWithNavigation = ["801", "601"]; // Updated to only include items with actual navigation
  
  const categories = [
    // {
    //   title: 'Payments',
    //   icon: <FiShoppingCart className="w-6 h-6 text-blue-600" />,
    //   color: "border-blue-200",
    //   items: [
    //     { code: '701', label: "Business PayIn's" },
    //     { code: '702', label: "Business PayOut's" },
    //     { code: '703', label: 'Payments From Customer' },
    //     { code: '704', label: 'Payments To Customers' },
    //   ]
    // },
    {
      title: "Invoices",
      icon: <FiFileText className="w-6 h-6 text-blue-600" />,
      color: "border-blue-200",
      items: [
        { code: "601", label: "All Invoice Types" },
        // { code: "602", label: "Invoice By Customer" }
      ]
    },
    {
      title: 'Statements',
      icon: <FiFileText className="w-6 h-6 text-blue-600" />,
      color: "border-blue-200",
      items: [
        { code: '801', label: 'All Statements' },
        // { code: '802', label: 'Statements By Customer' }
      ]
    },
    
  ];

  const handleNavigation = (item) => {
    // Only set active state and navigate for items with navigation
    if (itemsWithNavigation.includes(item.code)) {
      setActiveItem(item.code);
      
      // Navigate based on the item code
      switch (item.code) {
        case "801":
          navigate(`/allStatementReports`);
          break;
          case "601":
            navigate(`/allInvoiceReports`);
            break;
        default:
          console.log(`No navigation defined for item code: ${item.code}`);
      }
    } else {
      // For non-navigable items, don't update the active state
      console.log(`Item ${item.code} has no navigation function`);
      // Ensure we don't set an active state for non-navigable items
      setActiveItem(null);
    }
  };

  return (
    <div className="w-full px-6 py-4 mt-5 bg-gray-50">
      <div className="max-w-6xl mx-auto">
        <div className="grid grid-cols-4 gap-6">
          {categories.map((category, idx) => (
            <div key={idx}>
              <div className="flex items-center space-x-3 mb-5 pb-2 border-b">
                <div className="p-2 bg-blue-50 rounded-full">
                  {category.icon}
                </div>
                <h2 className="text-lg font-semibold text-gray-900">
                  {category.title}
                </h2>
              </div>
              <div className="space-y-3">
                {category.items.map((item, itemIdx) => {
                  const isActive = activeItem === item.code;
                  const isHovered = hoveredItem === `${item.code}-${idx}`;
                  const hasNavigation = itemsWithNavigation.includes(item.code);
                  return (
                    <div
                      key={itemIdx}
                      onClick={() => handleNavigation(item)}
                      onMouseEnter={() => setHoveredItem(`${item.code}-${idx}`)}
                      onMouseLeave={() => setHoveredItem(null)}
                      style={{
                        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                        transform: isHovered ? "translateY(-2px)" : "none",
                        transition: "all 0.3s ease"
                      }}
                      className={`
                        border rounded-md p-3 text-left
                        flex items-center cursor-pointer
                        ${
                          hasNavigation && isActive
                            ? `bg-white border-l-4 border-l-blue-600`
                            : `bg-white ${
                                isHovered ? "border-l-4 border-l-blue-600" : ""
                              }`
                        }
                      `}
                    >
                      <div className={`w-10 font-mono text-sm font-medium ${
                        isHovered || (hasNavigation && isActive)
                          ? "text-black-500"
                          : "text-black-700"
                      }`}>
                        {item.code}
                      </div>
                      <div className={`flex-grow text-sm font-medium ${
                        isHovered || (hasNavigation && isActive) 
                          ? 'text-gray-500' 
                          : 'text-gray-700'
                      }`}>
                        {item.label}
                      </div>
                      <FiChevronRight 
                        className={`w-5 h-5 ${
                          (hasNavigation && isActive && !isHovered) 
                            ? "text-blue-500 opacity-100" 
                            : isHovered
                            ? "text-blue-500 opacity-100"
                            : "opacity-0"
                        }`}
                        style={{ transition: 'all 0.2s ease' }} 
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CustomerReport;
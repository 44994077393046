import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../config/config";
import { useSelector, useDispatch } from "react-redux";
import { addCustomer } from "../../store/customerSlice";
import { addToast } from "./ToastBox/ToastBoxMessageSlice";
import CustomNavigation from "../reusable/CustomNavigation";
import { FaHome } from "react-icons/fa";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";

const CustomerTax = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const baseURL =
    process.env.NODE_ENV === "production"
      ? config.production.baseURL
      : config.development.baseURL;
          const [isOpen, setIsOpen] = useState(false);
          const toggleInput = () => {
            setIsOpen((prev) => !prev);
          };
      const [termsAndConditions, setTermsAndConditions] = useState("");

  const [taxesNames, setTaxNames] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [customerIndividualData, setCustomerIndividualData] = useState(null);
  const [invoiceCustomerData, setInvoiceCustomerData] = useState([]);
  const [latestPurchaseInvoices, setLatestPurchaseInvoices] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [freightCharge, setFreightCharge] = useState('');
  const [freightCharges, setFreightCharges] = useState({
    NY: '0',
    CT: '0'
  });

  const [anyTaxSelected, setAnyTaxSelected] = useState(false);
  // const taxesNames1 = [
  //   {
  //       "id": "1",
  //       "fuelbrand_taxes": "Federal Oil Spill Tax Cost Recovery - E10",
  //       "po_1006tabletax_name": "federal_oil_spill_tax_cost_recovery_eten",
  //       "customer_taxes": "Federal Oil Spill",
  //       "federal_oil_spill_tax_cost_recovery_eten": "0.000000",
  //       "state_code": "NY"
  //   },
  //   {
  //       "id": "2",
  //       "fuelbrand_taxes": "Federal Gasoline Tax",
  //       "po_1006tabletax_name": "federal_gasoline_tax",
  //       "customer_taxes": "Federal Excise Tax",
  //       "federal_gasoline_tax": "0.000000",
  //       "state_code": "NY"
  //   },
  //   {
  //       "id": "3",
  //       "fuelbrand_taxes": "Fed Haz Substance Superfund Recovery Fee - E10",
  //       "po_1006tabletax_name": "fedhaz_subsup_recoveryfee_eten",
  //       "customer_taxes": "Federal Superfund Exise Tax",
  //       "fedhaz_subsup_recoveryfee_eten": "0.000000",
  //       "state_code": "NY"
  //   },
  //   {
  //       "id": "4",
  //       "fuelbrand_taxes": "NY Motor Fuel Excise Tax",
  //       "po_1006tabletax_name": "ny_motor_fuel_excise_tax",
  //       "customer_taxes": "NYS Excise",
  //       "ny_motor_fuel_excise_tax": "0.000000",
  //       "state_code": "NY"
  //   },
  //   {
  //       "id": "5",
  //       "fuelbrand_taxes": "NY Oil Spill Surcharge",
  //       "po_1006tabletax_name": "ny_oilspill_surcharge",
  //       "customer_taxes": "NYS Spill Recovery",
  //       "ny_oilspill_surcharge": "0.003270",
  //       "state_code": "NY"
  //   },
  //   {
  //       "id": "6",
  //       "fuelbrand_taxes": "NY PBT - Gasoline",
  //       "po_1006tabletax_name": "ny_pbt_gasoline",
  //       "customer_taxes": "NY Petroleum Bus Rcvry",
  //       "ny_pbt_gasoline": "0.000000",
  //       "state_code": "NY"
  
  //   },
  //   {
  //       "id": "7",
  //       "fuelbrand_taxes": "NY Motor Fuel Testing Fee",
  //       "po_1006tabletax_name": "ny_motor_fuel_testing_fee",
  //       "customer_taxes": "NYS Petroleum Testing Fee",
  //       "ny_motor_fuel_testing_fee": "0.000000",
  //       "state_code": "NY"
  
  //   },
  //   {
  //       "id": "8",
  //       "fuelbrand_taxes": "NY Reg 3 PPD Sales Tax - Gasoline",
  //       "po_1006tabletax_name": "nys_prepaid_gasoline",
  //       "customer_taxes": "NYS Prepaid",
  //       "nys_prepaid_gasoline": "0.000000",
  //       "state_code": "NY"
  
  //   },
  //   {
  //       "id": "9",
  //       "fuelbrand_taxes": "Federal Oil Spill Tax Cost Recovery",
  //       "po_1006tabletax_name": "federal_oil_spill_tax_cost_recovery_diesel",
  //       "customer_taxes": "Federal Oil Spill",
  //       "federal_oil_spill_tax_cost_recovery_diesel": "0.002143",
  //       "state_code": "NY"
  
  //   },
  //   {
  //       "id": "10",
  //       "fuelbrand_taxes": "Federal Diesel Tax",
  //       "po_1006tabletax_name": "federal_diesel_tax",
  //       "customer_taxes": "Federal Excise Tax",
  //       "federal_diesel_tax": "0.243000",
  //       "state_code": "NY"
  
  //   },
  //   {
  //       "id": "11",
  //       "fuelbrand_taxes": "Fed Haz Substance Superfund Recovery Fee",
  //       "po_1006tabletax_name": "fedhaz_subsup_recoveryfee_diesel",
  //       "customer_taxes": "Federal Superfund Exise Tax",
  //       "fedhaz_subsup_recoveryfee_diesel": "0.004050",
  //       "state_code": "NY"
  
  //   },
  //   {
  //       "id": "12",
  //       "fuelbrand_taxes": "NY Diesel Fuel Tax",
  //       "po_1006tabletax_name": "ny_diesel_fuel_tax",
  //       "customer_taxes": "NYS Excise",
  //       "ny_diesel_fuel_tax": "0.080000",
  //       "state_code": "NY"
  
  //   },
  //   {
  //       "id": "13",
  //       "fuelbrand_taxes": "NYS Spill Recovery",
  //       "po_1006tabletax_name": "ny_oil_spill_tax_recvry_diesel",
  //       "customer_taxes": "NYS Spill Recovery",
  //       "ny_oil_spill_tax_recvry_diesel": "0.000000",
  //       "state_code": "NY"
  
  //   },
  //   {
  //       "id": "14",
  //       "fuelbrand_taxes": "NY PBT - Clear Diesel/BIO",
  //       "po_1006tabletax_name": "ny_pbt_diesel",
  //       "customer_taxes": "NY Petroleum Bus Rcvry",
  //       "ny_pbt_diesel": "0.155500",
  //       "state_code": "NY"
  
  //   },
  //   {
  //       "id": "15",
  //       "fuelbrand_taxes": "NY Reg 3 PPD Sales Tax - Clear Product",
  //       "po_1006tabletax_name": "nys_prepaid_diesel",
  //       "customer_taxes": "NYS Prepaid",
  //       "nys_prepaid_diesel": "0.170000",
  //       "state_code": "NY"
  
  //   },
  //   {
  //       "id": "16",
  //       "fuelbrand_taxes": "CT Motor Fuel Tax",
  //       "po_1006tabletax_name": "ct_motor_fuel_tax",
  //       "customer_taxes": "CT Motor Fuel Tax",
  //       "ct_motor_fuel_tax": "0.000000",
  //       "state_code": "NY"
  
  //   },
  //   {
  //       "id": "17",
  //       "fuelbrand_taxes": "CT Special Fuel Tax",
  //       "po_1006tabletax_name": "ct_special_fuel_tax",
  //       "customer_taxes": "CT Special Fuel Tax",
  //       "ct_special_fuel_tax": "0.000000",
  //        "state_code": "NY"
  //   },
  //   {
  //     "id": "18",
  //     "fuelbrand_taxes": "Federal Oil Spill Tax Cost Recovery - E10",
  //     "po_1006tabletax_name": "federal_oil_spill_tax_cost_recovery_eten",
  //     "customer_taxes": "Federal Oil Spill",
  //     "federal_oil_spill_tax_cost_recovery_eten": "0.000000",
  //     "state_code": "CT"
  // },
  // {
  //     "id": "19",
  //     "fuelbrand_taxes": "Federal Gasoline Tax",
  //     "po_1006tabletax_name": "federal_gasoline_tax",
  //     "customer_taxes": "Federal Excise Tax",
  //     "federal_gasoline_tax": "0.000000",
  //     "state_code": "CT"
  // },
  // {
  //     "id": "20",
  //     "fuelbrand_taxes": "Fed Haz Substance Superfund Recovery Fee - E10",
  //     "po_1006tabletax_name": "fedhaz_subsup_recoveryfee_eten",
  //     "customer_taxes": "Federal Superfund Exise Tax",
  //     "fedhaz_subsup_recoveryfee_eten": "0.000000",
  //     "state_code": "CT"
  // },
  // {
  //     "id": "21",
  //     "fuelbrand_taxes": "NY Motor Fuel Excise Tax",
  //     "po_1006tabletax_name": "ny_motor_fuel_excise_tax",
  //     "customer_taxes": "NYS Excise",
  //     "ny_motor_fuel_excise_tax": "0.000000",
  //     "state_code": "CT"
  // },
  // {
  //     "id": "22",
  //     "fuelbrand_taxes": "NY Oil Spill Surcharge",
  //     "po_1006tabletax_name": "ny_oilspill_surcharge",
  //     "customer_taxes": "NYS Spill Recovery",
  //     "ny_oilspill_surcharge": "0.003270",
  //     "state_code": "CT"
  // },
  // {
  //     "id": "23",
  //     "fuelbrand_taxes": "NY PBT - Gasoline",
  //     "po_1006tabletax_name": "ny_pbt_gasoline",
  //     "customer_taxes": "NY Petroleum Bus Rcvry",
  //     "ny_pbt_gasoline": "0.000000",
  //     "state_code": "CT"
  
  // },
  // {
  //     "id": "24",
  //     "fuelbrand_taxes": "NY Motor Fuel Testing Fee",
  //     "po_1006tabletax_name": "ny_motor_fuel_testing_fee",
  //     "customer_taxes": "NYS Petroleum Testing Fee",
  //     "ny_motor_fuel_testing_fee": "0.000000",
  //     "state_code": "CT"
  
  // },
  // {
  //     "id": "25",
  //     "fuelbrand_taxes": "NY Reg 3 PPD Sales Tax - Gasoline",
  //     "po_1006tabletax_name": "nys_prepaid_gasoline",
  //     "customer_taxes": "NYS Prepaid",
  //     "nys_prepaid_gasoline": "0.000000",
  //     "state_code": "CT"
  
  // },
  // {
  //     "id": "26",
  //     "fuelbrand_taxes": "Federal Oil Spill Tax Cost Recovery",
  //     "po_1006tabletax_name": "federal_oil_spill_tax_cost_recovery_diesel",
  //     "customer_taxes": "Federal Oil Spill",
  //     "federal_oil_spill_tax_cost_recovery_diesel": "0.002143",
  //     "state_code": "CT"
  
  // },
  // {
  //     "id": "27",
  //     "fuelbrand_taxes": "Federal Diesel Tax",
  //     "po_1006tabletax_name": "federal_diesel_tax",
  //     "customer_taxes": "Federal Excise Tax",
  //     "federal_diesel_tax": "0.243000",
  //     "state_code": "CT"
  
  // },
  // {
  //     "id": "28",
  //     "fuelbrand_taxes": "Fed Haz Substance Superfund Recovery Fee",
  //     "po_1006tabletax_name": "fedhaz_subsup_recoveryfee_diesel",
  //     "customer_taxes": "Federal Superfund Exise Tax",
  //     "fedhaz_subsup_recoveryfee_diesel": "0.004050",
  //     "state_code": "CT"
  
  // },
  // {
  //     "id": "29",
  //     "fuelbrand_taxes": "NY Diesel Fuel Tax",
  //     "po_1006tabletax_name": "ny_diesel_fuel_tax",
  //     "customer_taxes": "NYS Excise",
  //     "ny_diesel_fuel_tax": "0.080000",
  //     "state_code": "CT"
  
  // },
  // {
  //     "id": "30",
  //     "fuelbrand_taxes": "NYS Spill Recovery",
  //     "po_1006tabletax_name": "ny_oil_spill_tax_recvry_diesel",
  //     "customer_taxes": "NYS Spill Recovery",
  //     "ny_oil_spill_tax_recvry_diesel": "0.000000",
  //     "state_code": "CT"
  
  // },
  // {
  //     "id": "31",
  //     "fuelbrand_taxes": "NY PBT - Clear Diesel/BIO",
  //     "po_1006tabletax_name": "ny_pbt_diesel",
  //     "customer_taxes": "NY Petroleum Bus Rcvry",
  //     "ny_pbt_diesel": "0.155500",
  //     "state_code": "CT"
  
  // },
  // {
  //     "id": "32",
  //     "fuelbrand_taxes": "NY Reg 3 PPD Sales Tax - Clear Product",
  //     "po_1006tabletax_name": "nys_prepaid_diesel",
  //     "customer_taxes": "NYS Prepaid",
  //     "nys_prepaid_diesel": "0.170000",
  //     "state_code": "CT"
  
  // },
  // {
  //     "id": "33",
  //     "fuelbrand_taxes": "CT Motor Fuel Tax",
  //     "po_1006tabletax_name": "ct_motor_fuel_tax",
  //     "customer_taxes": "CT Motor Fuel Tax",
  //     "ct_motor_fuel_tax": "0.000000",
  //     "state_code": "CT"
  
  // },
  // {
  //     "id": "34",
  //     "fuelbrand_taxes": "CT Special Fuel Tax",
  //     "po_1006tabletax_name": "ct_special_fuel_tax",
  //     "customer_taxes": "CT Special Fuel Tax",
  //     "ct_special_fuel_tax": "0.000000",
  //      "state_code": "CT"
  // }
  // ]
  // Store previously submitted taxes by tax ID
  const [previouslySubmittedTaxes, setPreviouslySubmittedTaxes] = useState([]);
  const [taxSubmissionHistory, setTaxSubmissionHistory] = useState({
    NY: [],
    CT: []
  });
  // Store which state each previously submitted tax belongs to
  const [submittedTaxStateMap, setSubmittedTaxStateMap] = useState({});
  
  // Track currently selected (but not yet submitted) taxes by state
  const [stateSelections, setStateSelections] = useState({
    NY: {},
    CT: {}
  });

  // State for expanded sections - only one state can be expanded at a time
  const [expandedState, setExpandedState] = useState(null); // 'NY', 'CT', or null

  const customersAll = useSelector((state) => state.customer.allCustomers);
  const businessId = localStorage.getItem("business_id");

  // Fetch customers and save to Redux store
  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get(`${baseURL}Y3VzdG9tZXIvdmlldw==/${btoa(businessId)}`)
      .then((response) => {
        const sortedCustomerData = response.data.customers_data.sort(
          (a, b) => b.customer_id - a.customer_id
        );
        dispatch(addCustomer(sortedCustomerData));
      })
      .catch((error) => {
        console.error("Error fetching customer data:", error);
      });
  }, [dispatch, baseURL, businessId]);

  useEffect(() => {
    if (selectedCustomer) {
      setLoading(true);
      const encodedId = btoa(selectedCustomer);
      const businessId = localStorage.getItem("business_id");
      
      axios
        .get(`${baseURL}Y3VzdG9tZXIvZWRpdA==/${encodedId}/${btoa(businessId)}`)
        .then((response) => {
          const fuelGradedata = response.data.customersFuelsData;
          const customerIndividualData = response.data.customers_data[0];
          setCustomerIndividualData(customerIndividualData);
          setLatestPurchaseInvoices(response.data.purchasedInvoiceNumbers);
          setInvoiceCustomerData(fuelGradedata);
          const initialFreightCharge = customerIndividualData?.freigt_charge?.toString() || '0';
          setFreightCharges({
            NY: initialFreightCharge,
            CT: initialFreightCharge
          });          setLoading(false);
          
          setExpandedState(null);
        })
        .catch((error) => {
          console.error("Error fetching customer details:", error);
          setLoading(false);
        });
  
      const fetchCustomerTaxes = async () => {
        try {
          const response = await axios.get(
            `${baseURL}${btoa(
              "customersalestax/singlecustomeralltaxes"
            )}/${btoa(businessId)}/${btoa(selectedCustomer)}`
          );
      
          const customerTaxes = response.data.singleCustomeralltaxesData || [];
          
          const selectedTaxNames = customerTaxes.map(tax => tax.po_customertax_name);
          setPreviouslySubmittedTaxes(selectedTaxNames);
          
       
          const storedHistory = localStorage.getItem(`taxHistory_${selectedCustomer}`);
          let history = { NY: [], CT: [] };
          
          if (storedHistory) {
            try {
              history = JSON.parse(storedHistory);
            } catch (e) {
              console.error("Error parsing stored tax history:", e);
            }
          }
          
          setTaxSubmissionHistory(history);
          
          const stateMap = {};
          
          selectedTaxNames.forEach(taxId => {
            if (history.NY.includes(taxId)) {
              stateMap[taxId] = 'NY';
            } else if (history.CT.includes(taxId)) {
              stateMap[taxId] = 'CT';
            } else {
              const stateGuess = guessTaxState(taxId);
              stateMap[taxId] = stateGuess;
            }
          });
          
          setSubmittedTaxStateMap(stateMap);
          
          setAnyTaxSelected(selectedTaxNames.length > 0);
          
          // if (selectedTaxNames.length > 0) {
          //   const nyCounts = Object.values(stateMap).filter(state => state === 'NY').length;
          //   const ctCounts = Object.values(stateMap).filter(state => state === 'CT').length;
            
          //   if (ctCounts > nyCounts) {
          //     setExpandedState('CT');
          //   } else if (nyCounts > 0) {
          //     setExpandedState('NY');
          //   } else if (ctCounts > 0) {
          //     setExpandedState('CT');
          //   } else {
          //     // setExpandedState('NY');
          //   }
          // }
        } catch (error) {
          console.error("Error fetching customer taxes:", error);
        }
      };
  
      fetchCustomerTaxes();
    }
  }, [selectedCustomer, baseURL]);

  const determineStateFromTaxes = (taxNames) => {
    if (!taxNames || taxNames.length === 0) return null;
    
    const nySpecificTaxes = [
      'ny_motor_fuel_excise_tax',
      'ny_oilspill_surcharge',
      'ny_pbt_gasoline',
      'ny_motor_fuel_testing_fee',
      'nys_prepaid_gasoline',
      'ny_diesel_fuel_tax',
      'ny_oil_spill_tax_recvry_diesel',
      'ny_pbt_diesel',
      'nys_prepaid_diesel'
    ];
    
    const ctSpecificTaxes = [
      'ct_motor_fuel_tax',
      'ct_special_fuel_tax'
    ];
    
    const hasNYTaxes = taxNames.some(taxName => 
      nySpecificTaxes.some(nyTax => taxName === nyTax)
    );
    
    const hasCTTaxes = taxNames.some(taxName => 
      ctSpecificTaxes.some(ctTax => taxName === ctTax)
    );
    
    if (hasNYTaxes && hasCTTaxes) {
      console.warn("Found taxes from both NY and CT states - defaulting to NY");
      return 'NY';
    }
    
    if (hasNYTaxes) return 'NY';
    if (hasCTTaxes) return 'CT';
    
    const etenTaxes = taxNames.filter(name => name.includes('eten'));
    const dieselTaxes = taxNames.filter(name => 
      name.includes('diesel') && !name.includes('eten')
    );
    
    if (etenTaxes.length > 0) {
      return 'NY';
    } else if (dieselTaxes.length > 0) {
      return 'NY';
    }
    
    return 'NY';
  };

  useEffect(() => {
    const fetchBusinessDetails = async () => {
      try {
        const businessId = localStorage.getItem("business_id");
        const response = await axios.get(
          `${baseURL}/${btoa("salestax/fetchallfuelbrandtaxes")}/${btoa(businessId)}`
        );
        
        const enrichedData = response.data.map((item) => ({
          ...item,
          disabled: !item.po_1006tabletax_name,
          value: item.po_1006tabletax_name ? item[item.po_1006tabletax_name] || "0.000000" : "0.000000"
        }));
        
        setTaxNames(enrichedData);
      } catch (error) {
        console.error("Error fetching tax details", error);
      }
    };

    fetchBusinessDetails();
  }, [baseURL]);

  useEffect(() => {
    if (selectedCustomer) {
      setAnyTaxSelected(false);
      setPreviouslySubmittedTaxes([]);
      setSubmittedTaxStateMap({});
      setStateSelections({ NY: {}, CT: {} });
      setExpandedState(null);
      setTaxSubmissionHistory({ NY: [], CT: [] });
    }
  }, [selectedCustomer]);

  const handleCheckboxChange = (taxId, state) => {
    if (!taxId || !state) return;
    
    const newStateSelections = { ...stateSelections };
    
    newStateSelections[state][taxId] = !newStateSelections[state][taxId];
    
    setStateSelections(newStateSelections);
    
    const anyPreviouslySubmitted = previouslySubmittedTaxes.length > 0;
    const anyNewlySelected = Object.values(newStateSelections).some(stateObj => 
      Object.values(stateObj).some(isSelected => isSelected)
    );
    
    setAnyTaxSelected(anyPreviouslySubmitted || anyNewlySelected);
  };

  const handleSelectAllTaxes = (fuelType, state) => {
    const taxData = getTaxesByFuelTypeAndState(fuelType, state);
    
    const selectableTaxes = taxData.filter(tax => 
      !isPreviouslySubmittedForState(tax.po_1006tabletax_name, state) && 
      !tax.disabled && 
      tax.po_1006tabletax_name
    );
    
    if (selectableTaxes.length === 0) return;
    
    const allSelected = selectableTaxes.every(tax => 
      stateSelections[state][tax.po_1006tabletax_name]
    );
    
    const newStateSelections = { ...stateSelections };
    
    selectableTaxes.forEach(tax => {
      newStateSelections[state][tax.po_1006tabletax_name] = !allSelected;
    });
    
    setStateSelections(newStateSelections);
    
    const anyPreviouslySubmitted = previouslySubmittedTaxes.length > 0;
    const anyNewlySelected = Object.values(newStateSelections).some(stateObj => 
      Object.values(stateObj).some(isSelected => isSelected)
    );
    
    setAnyTaxSelected(anyPreviouslySubmitted || anyNewlySelected);
  };

  const handleFreightChargeChange = (state, value) => {
    if (value === "") {
      setFreightCharges(prev => ({
        ...prev,
        [state]: ""
      }));
      return;
    }
    
    if (/^(\d*\.?\d{0,2}|100)$/.test(value)) {
      const numValue = parseFloat(value);
      
      if (numValue > 100) {
        setFreightCharges(prev => ({
          ...prev,
          [state]: "100"
        }));
      } else {
        setFreightCharges(prev => ({
          ...prev,
          [state]: value
        }));
      }
    }
  };
  const toggleStateExpand = (state) => {
    if (expandedState === state) {
      setExpandedState(null);
    } else {
      setExpandedState(state);
    }
  };

  const isPreviouslySubmittedForState = (taxId, state) => {
  
    return previouslySubmittedTaxes.includes(taxId) && submittedTaxStateMap[taxId] === state;
  };

  const isTaxSelected = (taxId, state) => {
    if (!taxId || !state) return false;
    
    if (isPreviouslySubmittedForState(taxId, state)) {
      return true;
    }
    
    return !!stateSelections[state][taxId];
  };
  const getSelectedTaxesForSubmission = (state) => {
    if (!state) return [];
    
    const selectedTaxes = [];
    
    previouslySubmittedTaxes.forEach(taxId => {
      if (submittedTaxStateMap[taxId] === state) {
        selectedTaxes.push(taxId);
      }
    });
    
    Object.entries(stateSelections[state]).forEach(([taxId, isSelected]) => {
      if (isSelected && !selectedTaxes.includes(taxId)) {
        selectedTaxes.push(taxId);
      }
    });
    
    return selectedTaxes;
  };
  const getTaxesByFuelTypeAndState = (fuelType, state) => {
    if (!state || !taxesNames || !taxesNames.length) return [];
    
    const nyRegularTaxBrands = [
      "Federal Gasoline Tax", 
      "Federal Oil Spill Tax Cost Recovery - E10", 
      "Fed Haz Substance Superfund Recovery Fee - E10", 
      "NY Reg 3 PPD Sales Tax - Gasoline",
      "NY Motor Fuel Excise Tax", 
      "NY PBT - Gasoline", 
      "NY Motor Fuel Testing Fee", 
      "NY Oil Spill Surcharge"
    ];

    const nyDieselTaxBrands = [
      "Federal Diesel Tax", 
      "Federal Oil Spill Tax Cost Recovery", 
      "Fed Haz Substance Superfund Recovery Fee", 
      "NY Reg 3 PPD Sales Tax - Clear Product", 
      "NY Diesel Fuel Tax", 
      "NY PBT - Clear Diesel/BIO",
      "NYS Spill Recovery"
    ];

    const ctRegularTaxBrands = [
      "Fed Haz Substance Superfund Recovery Fee - E10", 
      "Federal Gasoline Tax", 
      "Federal Oil Spill Tax Cost Recovery - E10", 
      "CT Motor Fuel Tax"
    ];

    const ctDieselTaxBrands = [
      "Fed Haz Substance Superfund Recovery Fee", 
      "Federal Diesel Tax", 
      "Federal Oil Spill Tax Cost Recovery", 
      "CT Special Fuel Tax"
    ];

    let taxBrandNames = [];
    if (state.toUpperCase() === 'NY') {
      taxBrandNames = fuelType.toLowerCase() === 'diesel' ? nyDieselTaxBrands : nyRegularTaxBrands;
    } else if (state.toUpperCase() === 'CT') {
      taxBrandNames = fuelType.toLowerCase() === 'diesel' ? ctDieselTaxBrands : ctRegularTaxBrands;
    } else {
      return [];
    }

    const relevantTaxes = taxBrandNames
      .map(brandName => {
        const matchingTax = taxesNames.find(tax => tax.fuelbrand_taxes === brandName);
        
        if (matchingTax) {
          return matchingTax;
        } else {
          return {
            fuelbrand_taxes: brandName,
            customer_taxes: "",
            disabled: true,
            po_1006tabletax_name: "",
            value: "0.000000"
          };
        }
      });
      
    return relevantTaxes;
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!selectedCustomer) {
      dispatch(
        addToast({ type: "warning", message: "Please select a customer first!" })
      );
      return;
    }
    
    if (!expandedState) {
      dispatch(
        addToast({ type: "warning", message: "Please select a state (NY or CT) first!" })
      );
      return;
    }
    
    const selectedTaxesForSubmission = getSelectedTaxesForSubmission(expandedState);

    if (selectedTaxesForSubmission.length === 0) {
      dispatch(
        addToast({ type: "warning", message: "Please select at least one tax!" })
      );
      return;
    }
  
  
    const updatedStateMap = {...submittedTaxStateMap};
    selectedTaxesForSubmission.forEach(taxId => {
      updatedStateMap[taxId] = expandedState;
    });
    setSubmittedTaxStateMap(updatedStateMap);
    
    const updatedHistory = {...taxSubmissionHistory};
    
    updatedHistory.NY = updatedHistory.NY.filter(id => !selectedTaxesForSubmission.includes(id));
    updatedHistory.CT = updatedHistory.CT.filter(id => !selectedTaxesForSubmission.includes(id));
    
    updatedHistory[expandedState] = [
      ...updatedHistory[expandedState],
      ...selectedTaxesForSubmission.filter(id => !updatedHistory[expandedState].includes(id))
    ];
    
    setTaxSubmissionHistory(updatedHistory);
    
    localStorage.setItem(`taxHistory_${selectedCustomer}`, JSON.stringify(updatedHistory));
    const stateFreightCharge = freightCharges[expandedState];

    const data = {
      businessId: localStorage.getItem("business_id"),
      customerId: selectedCustomer,
      loggedInUserId: localStorage.getItem("user_id"),
      customerTaxesSelected: selectedTaxesForSubmission,
      // freigtCharge: freightCharge === "" ? 0 : parseFloat(freightCharge),
      freigtCharge: stateFreightCharge === "" ? 0 : parseFloat(stateFreightCharge),

      stateCode: expandedState
    };
  
    try {
      const response = await axios.post(
        `${baseURL}${btoa("customersalestax/createcustomertaxes")}`,
        data
      );
      if (response.data.success) {
        console.log(`Taxes submitted for ${expandedState}:`, selectedTaxesForSubmission);
        console.log("Updated history:", updatedHistory);
        
        dispatch(
          addToast({ type: "success", message: "Tax Created Successfully!" })
        );
        navigate("/salesTaxCustomers");
      }
    } catch (error) {
      console.error("Error submitting customer taxes:", error);
      dispatch(
        addToast({ type: "danger", message: "Failed to Create Customer Taxes!" })
      );
    }
  };
  const guessTaxState = (taxId) => {
    if (taxId.startsWith('ny_') || 
        taxId.includes('_ny_') ||
        taxId.includes('nys_')) {
      return 'NY';
    }
    
    if (taxId.startsWith('ct_') || 
        taxId.includes('_ct_')) {
      return 'CT';
    }
    
    return 'NY';
  };
  const [activeItem, setActiveItem] = useState("View");

  const handleMenuClick = (item) => {
    setActiveItem(item);
  };

  const getTaxValue = (taxItem) => {
  
    return taxItem[taxItem.po_1006tabletax_name] || "0.000000";
  };

  const hasCustomerFuelType = (fuelType) => {
    if (!selectedCustomer || invoiceCustomerData.length === 0) {
      return true;
    }
    
    if (fuelType.toLowerCase() === "diesel") {
      return invoiceCustomerData.some(fuel => 
        fuel.product_name?.toLowerCase().includes("diesel")
      );
    } else {
      return invoiceCustomerData.some(fuel => 
        ["regular", "premium", "ultra"].some(type => 
          fuel.product_name?.toLowerCase().includes(type)
        )
      );
    }
  };

  const renderTaxTable = (fuelType, state) => {
    if (!hasCustomerFuelType(fuelType)) {
      return null;
    }
    
    const taxData = getTaxesByFuelTypeAndState(fuelType, state);
    
    if (taxData.length === 0) return null;
    
    let productNames = "";
    
    if (invoiceCustomerData.length > 0) {
      productNames = invoiceCustomerData
        .filter(fuel => {
          if (fuelType.toLowerCase() === "diesel") {
            return fuel.product_name?.toLowerCase().includes("diesel");
          } else {
            return ["regular", "premium", "ultra"].some(type => 
              fuel.product_name?.toLowerCase().includes(type)
            );
          }
        })
        .map(fuel => fuel.product_name?.replace(/\b\w/g, l => l.toUpperCase()))
        .join("/");
    } else {
      productNames = fuelType.toLowerCase() === "diesel" ? "Diesel" : "Regular/Premium/Ultra";
    }
    
    const selectableTaxes = taxData.filter(tax => 
      !isPreviouslySubmittedForState(tax.po_1006tabletax_name, state) && 
      !tax.disabled && 
      tax.po_1006tabletax_name
    );
    
    const selectedCount = selectableTaxes.filter(tax => 
      stateSelections[state][tax.po_1006tabletax_name]
    ).length;
    
   
    const allSelected = selectableTaxes.length > 0 && 
      selectedCount === selectableTaxes.length;
    
    const totalPreviouslySubmitted = taxData.filter(tax => 
      isPreviouslySubmittedForState(tax.po_1006tabletax_name, state)
    ).length;
    
    const totalSelectableTaxes = taxData.filter(tax => !tax.disabled && tax.po_1006tabletax_name).length;
    
    const isAllSelected = (selectedCount + totalPreviouslySubmitted) === totalSelectableTaxes;
    
    return (
      <div className="mb-4">
        <div className="bg-gray-200 p-2 font-semibold">
          {productNames || fuelType.toUpperCase()}
        </div>
        <table className="w-full bg-white mb-4">
          <thead className="bg-blue-100 border-blue-600 text-blue-600">
            <tr>
              <th className="px-4 py-2 text-left">Tax Items from Branding PO's</th>
              <th className="px-4 py-2 text-left">Tax Name</th>
              <th className="px-4 py-2 text-left">Values</th>
              <th className="px-4 py-2 text-center">
                <div className="flex items-center justify-center">
                  Apply Tax
                  <input
                    type="checkbox"
                    checked={allSelected || isAllSelected}
                    onChange={() => handleSelectAllTaxes(fuelType, state)}
                    disabled={selectableTaxes.length === 0} 
                    className={selectableTaxes.length === 0 ? "ml-2 cursor-not-allowed opacity-50 bg-gray-200" : "ml-2"}
                  />
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {taxData.map((tax, idx) => {
              const isPreviouslySubmitted = isPreviouslySubmittedForState(tax.po_1006tabletax_name, state);
              
              const isDisabled = tax.disabled || !tax.po_1006tabletax_name || isPreviouslySubmitted;
              
              const isChecked = isPreviouslySubmitted || 
                (tax.po_1006tabletax_name && stateSelections[state][tax.po_1006tabletax_name]);
              
              return (
                <tr key={idx} className={`border-b ${isPreviouslySubmitted ? 'bg-gray-100' : ''}`}>
                  <td className="px-4 py-1 text-left text-gray-600 w-[420px]">{tax.fuelbrand_taxes}</td>
                  <td className="px-4 py-1 text-left text-gray-600">{tax.customer_taxes}</td>
                  <td className="px-4 py-1 text-left text-gray-600">
                    {getTaxValue(tax)}
                  </td>
                  <td className="px-4 py-1 text-center">
                    <input
                      type="checkbox"
                      checked={isChecked}
                      disabled={isDisabled}
                      className={isDisabled ? 'cursor-not-allowed opacity-50 bg-gray-200' : ''}
                      onChange={() => !isDisabled && handleCheckboxChange(tax.po_1006tabletax_name, state)}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  const renderStateSection = (state, stateName) => {
    const isExpanded = expandedState === state;
    
    // Determine if this state has previously submitted taxes
    const hasSubmittedTaxesForState = previouslySubmittedTaxes.some(taxId => 
      submittedTaxStateMap[taxId] === state
    );
    
    // Determine button style - highlight the selected state
    const buttonStyle = isExpanded 
      ? 'bg-[#d7f3f8] text-black' 
      : hasSubmittedTaxesForState
        ? 'bg-gray-200'
        : 'bg-gray-50';
    
    return (
      <div className="mb-4 border border-gray-300 ">
        <div 
          className={`flex justify-between items-center p-3 cursor-pointer hover:bg-[#d7f3f8] hover:text-black ${buttonStyle}`}
          onClick={() => toggleStateExpand(state)}
        >
          <h3 className="font-bold">{stateName} Taxes</h3>
          <div className="transform transition-transform duration-200">
            {isExpanded ? (
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clipRule="evenodd" />
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            )}
          </div>
        </div>
        
        {isExpanded && (
          <div className="p-4 bg-white">
            {/* <div className="mb-4 bg-gray-100 p-3 rounded-lg">
              <div className="flex justify-start items-center">
                <h3 className="font-bold mr-4">Freight Charge</h3>
              
                <input
  type="number"
  min="0"
  max="100"
  value={freightCharges[state]}
  onChange={(e) => handleFreightChargeChange(state, e.target.value)}
  placeholder="Enter Freight Charge"
  className="block w-44 rounded-md py-1 px-2 h-[35px] text-center"
/>
              </div>
            </div> */}
            
            {renderTaxTable("regular", state)}
            {renderTaxTable("diesel", state)}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="p-6">
      <div className="flex mb-6 justify-center p-4 rounded-md font-bold text-md items-center w-full mx-auto pb-2 pt-0">
        <div
          className={`flex items-center cursor-pointer px-4 py-1 ${
            activeItem === "View" ? "border-b-2 border-green-500 text-green-600" : "text-gray-600"
          } border-b border-gray-300`}
          onClick={() => {handleMenuClick("View");}}
        >
          <span>Create Customer tax</span>
        </div>
      </div>
      <div className="py-6 rounded-lg relative bg-white max-w-5xl m-auto" 
       style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.3)" }}
      >


<div className="flex items-center justify-end">
               {/* Home button - bordered/unfilled by default, filled blue on hover */}
               <div
                 className="absolute -top-4 flex items-center justify-center cursor-pointer rounded-full bg-[#cefafe] hover:bg-[#25caed] w-9 h-9 mb-0 mr-8 border-[0.5px] border-[#25caed] hover:border-[#25caed] hover:scale-110 shadow-md transition-all duration-200 group"
                 onClick={() => {
                   navigate("/");
                 }}
                 style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
               >
                 <FaHome className="text-[#25caed] group-hover:text-white transition-colors duration-200" size={16}  />
               </div>
         
               {/* Close Icon - bordered/unfilled by default, filled red on hover */}
               <div
                 className="absolute -top-4 -right-3 bg-red-200 hover:bg-red-500 w-9 h-9 flex items-center justify-center rounded-full cursor-pointer hover:bg-red-700 border-[0.5px] border-red-500 hover:border-red-700 hover:scale-110 shadow-md transition-all duration-200 group"
                 onClick={() => navigate('/salesTaxCustomers')}
               >
                 <span className="text-red-500 group-hover:text-white text-xl font-bold mb-1.5 transition-colors duration-200">&times;</span>
               </div>
             </div>

           {/* <CustomNavigation onCloseClick={() =>  navigate("/salesTaxCustomers")}  /> */}
      <div className="mx-10">
        <label htmlFor="customer-select" className="block font-medium mb-2 custombgColor">
          Select Customer
        </label>
        <select
          id="customer-select"
          className="block w-60 rounded-md py-0 h-[35px]"
          onChange={(e) => setSelectedCustomer(e.target.value)}
          value={selectedCustomer}
        >
          <option value="">Select Customer</option>
          {customersAll.map((customer) => (
            <option key={customer.customer_id} value={customer.customer_id}>
              {customer.business_name}
            </option>
          ))}
        </select>
      </div>
          
 
      {loading ? (
        <div className="text-center py-4">Loading...</div>
      ) : (
        <div className="mx-10 mt-6">
          {renderStateSection("NY", "New York")}
          {renderStateSection("CT", "Connecticut")}
          
          <div className=" mb-2 ">
          {!isOpen ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            cursor: "pointer",
                            // border: "1px solid #ccc",
                            padding: "10px",
                            // boxShadow: "0 2px 10px rgba(0, 0, 0, 0.3)"
                          }}
                          className="w-full mb-2 bg-gray-100"
                          onClick={toggleInput}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            Terms & Conditions
                          </span>
      
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            style={{
                              transform: isOpen ? "rotate(90deg)" : "rotate(0deg)",
                              transition: "transform 0.2s ease",
                            }}
                          >
                            <polyline points="9 18 15 12 9 6"></polyline>
                          </svg>
                        </div>
                      ) : (
                        <div className="relative bg-gray-100 px-2 rounded-md mb-2">
                          <div className="flex items-center justify-between">
                            <div className="flex items-center">
                              <LightbulbOutlinedIcon
                                className="text-black bg-white absolute left-2 top-1/2 transform -translate-y-1/2"
                                style={{ borderRadius: "50%", fontSize: "28px" }}
                              />
                              <div className="pl-8 pt-1 text-md font-semibold">
                                Terms & Conditions
                              </div>
                            </div>
      
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              onClick={toggleInput}
                              style={{
                                transform: isOpen ? "rotate(90deg)" : "rotate(0deg)",
                                transition: "transform 0.2s ease",
                                cursor: "pointer",
                              }}
                            >
                              <polyline points="9 18 15 12 9 6"></polyline>
                            </svg>
                          </div>
      
                          <textarea
                            className="w-full pl-8 pt-0 pb-0 rounded-md focus:outline-none focus:ring-0 focus:border-none bg-gray-100 border-0 resize-none"
                            placeholder="Enter terms and conditions here..."
                            rows={1}
                            value={
                              termsAndConditions === null ||
                                termsAndConditions === "null"
                                ? ""
                                : termsAndConditions
                            }
                            onChange={(e) => setTermsAndConditions(e.target.value)}
                            maxLength={600}
                          />
                        </div>
                      )}
                      </div>

          <div className="mt-6 text-right">
          <button
  className={`px-4 py-2 rounded-md ${
    !selectedCustomer || 
    (expandedState && !freightCharges[expandedState]) || 
    !anyTaxSelected || 
    !expandedState
      ? "bg-gray-300 text-black cursor-not-allowed"
      : "bg-pink-500 text-white hover:bg-pink-600"
  }`}
  onClick={handleSubmit}
  disabled={
    !selectedCustomer || 
    (expandedState && !freightCharges[expandedState]) || 
    !anyTaxSelected || 
    !expandedState
  }
>
  Submit
</button>
          </div>
        </div>
      )}

      </div>
    </div>
  );
};

export default CustomerTax;
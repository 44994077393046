// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import config from '../../config/config';
// import { useNavigate, useParams } from 'react-router-dom';
// import { showToastForDeleteVendor, showToastForUpdateVendor } from '../toasts/toastForVendor';
// import { useDispatch } from 'react-redux';
// import { deleteAllVendors } from '../../store/vendorSlice';
// import { addToast } from '../pages/ToastBox/ToastBoxMessageSlice';
// import { FaCheckCircle, FaHome, FaTimesCircle } from "react-icons/fa";
// import CustomCommonTab from '../reusable/CustomCommonTab';
// const ViewVendor = () => {
//   // editcomponent
//   const navigate = useNavigate();
//   const dispatch = useDispatch();

//   const {vendorid} = useParams()
//   const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;

//   const [formData, setFormData] = useState({
//     vendorName: '',
//     einNumber: '',
//     contactName: '',
//     contactNumber: '',
//     contactEmail: '',
//     businessId: localStorage.getItem("business_id"),
//     ownerId: localStorage.getItem("user_id"),
//     edit_Id : '',
//   });
  
//  const [vendorNameError , setVendorNameError] = useState("")
//    const [einNumberError , setEinNumberError] = useState("")
//    const [contactNameError , setContactNameError] = useState("")
//    const [contactNumberError , setContactNumberError] = useState("")
//    const [contactEmailError , setContactEmailError] = useState("")
//    const [originalData, setOriginalData] = useState(null);
//    const noError =
//         !vendorNameError &&
//         !einNumberError &&
//         !contactNameError &&
//         !contactNumberError &&
//         !contactEmailError;

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const hasFormChanged = () => {
//     if (!originalData) return false;
    
//     return (
//       formData.vendorName !== originalData.vendorName ||
//       formData.einNumber !== originalData.einNumber ||
//       formData.contactName !== originalData.contactName ||
//       formData.contactNumber !== originalData.contactNumber ||
//       formData.contactEmail !== originalData.contactEmail
//     );
//   };

//   // useEffect(()=>{
//   //   async function fetchData(){
//   //       try {
//   //           const response = await axios.get(`${baseURL}${btoa("vendor/viewvendorbyid")}/${btoa(vendorid)}`);
//   //           console.log("view", response);
//   //           const data = response.data.vendor_data[0]
//   //           setFormData({
//   //               ...formData,
//   //               vendorName: data.truck_company_name,
//   //               einNumber: data.ein_number,
//   //               contactName: data.contact_name,
//   //               contactNumber: data.contact_no,
//   //               contactEmail: data.contact_email,
//   //               edit_Id : data.id
//   //           })
//   //       } catch (error) {
//   //           console.log("error fetching details" , error);
//   //       }
//   //   }
//   //   fetchData()
//   // },[])


//   useEffect(() => {
//     async function fetchData(){
//       try {
//         const response = await axios.get(`${baseURL}${btoa("vendor/viewvendorbyid")}/${btoa(vendorid)}`);
//         console.log("view", response);
//         const data = response.data.vendor_data[0];
        
//         const initialData = {
//           vendorName: data.truck_company_name,
//           einNumber: data.ein_number,
//           contactName: data.contact_name,
//           contactNumber: data.contact_no,
//           contactEmail: data.contact_email,
//         };
        
//         // Store original data for comparison
//         setOriginalData(initialData);
        
//         setFormData({
//           ...formData,
//           vendorName: data.truck_company_name,
//           einNumber: data.ein_number,
//           contactName: data.contact_name,
//           contactNumber: data.contact_no,
//           contactEmail: data.contact_email,
//           edit_Id: data.id
//         });
//       } catch (error) {
//         console.log("error fetching details", error);
//       }
//     }
//     fetchData();
//   }, []);

//   const areAllFieldsFilled = () => {
//     return Object.entries(formData)
//       .filter(([key]) => !['businessId', 'managerId'].includes(key))
//       .every(([key, value]) => value?.trim() !== '');
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await axios.put(`${baseURL}/${btoa("vendor/update")}`, formData);
//       // console.log("res", response);
//       dispatch(deleteAllVendors())
//       // showToastForUpdateVendor(`${formData.vendorName} details updated successfully`, "success" , "")
//       dispatch(addToast({ type: 'approve', message: `${formData.vendorName} details updated successfully` }));        

//       navigate('/vendors');
//     } catch (error) {
//       console.log("error updating vendor", error);
//       showToastForDeleteVendor('Error updating vendor details' , "error" , "")
//     }
//   };
//  const menuItems = [{ name: `Edit Carrier` }];
//   const CustomTooltip1 = ({ content, show, marginLeft }) => {
//     return (
//       <div
//         style={{
//           display: show ? 'block' : 'none',
//           position: 'absolute',
//           backgroundColor: '#ffffff',
//           color: '#fff',
//           padding: '5px',
//           borderRadius: '4px',
//           border:'1px solid red',
//           marginTop: '-35px',
//           marginLeft: `${marginLeft}px`,  // Use the provided marginLeft prop
//           boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
//         }}
//       >
//         <div
//           style={{
//             position: 'absolute',
//             bottom: '-7px',
//             left: '50%',
//             marginLeft: '-10px',
//             width: '0',
//             height: '0',
//             borderLeft: '7px solid transparent',
//             borderRight: '7px solid transparent',
//             borderTop: '7px solid #f98080',
//           }}
//         ></div>
//         <p className="text-xs text-red-500">{content}</p>
//       </div>
//     );
//   };
  
//   function formatPhoneNumber(phoneNumberString) {
//     var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
//     cleaned = cleaned.slice(0, 10);
//     var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
//     if (match) {
//       var intlCode = (match[1] ? '+1 ' : '');
//       return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
//     }
//     return cleaned; // Return cleaned input if no match
//   }
//       const [activeTab, setActiveTab] = useState("View");

//   // const tabs = [
//   //   { key: "View", label: "View" }, // No separate component—InvoiceView itself is shown
//   //   { key: "Edit", label: "Edit", component: <EditInvoice /> },
//   //   { key: "Delete", label: "Delete", onClick: handleDeleteModalInvoice }, // Calls function on click

//   //   { key: "Cancel", label: "Cancel", navigationPath: "/invoice" },
//   // ];

//   return (
//     <div className="pt-0 h-screen">
//       <div className="flex-1 flex justify-center">
//     <CustomCommonTab items={menuItems} />
//   </div>
//       {/* <h1 className="mx-8 font-bold mb-4 text-gray-400 text-lg mt-[-20px]">
//         Carrier {">>"} Edit Carrier
//       </h1> */}
//         {/* <TabMenu tabs={tabs} activeTab={activeTab} onTabChange={setActiveTab} /> */}

     
//       <form onSubmit={handleSubmit}>
//         <div className="relative w-auto bg-white rounded-box mx-8 rounded-xl" style={{ marginTop: "0px" }}>
//         <div className="absolute -top-5 -right-4 flex items-center justify-end space-x-2 z-10">


  
// <div
//   className="flex items-center justify-center cursor-pointer rounded-full bg-[#cefafe] hover:bg-[#25caed] w-9 h-9 border-[0.5px] border-[#25caed] hover:border-[#25caed] hover:scale-110 shadow-md transition-all duration-200 group"
//   onClick={() => {
//     navigate("/");
//   }}
//   style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
// >
//   <FaHome className="text-[#25caed] group-hover:text-white transition-colors duration-200" size={16} />
// </div>

// <div
//   className="bg-red-200 hover:bg-red-500 w-9 h-9 flex items-center justify-center rounded-full cursor-pointer hover:bg-red-700 border-[0.5px] border-red-500 hover:border-red-700 hover:scale-110 shadow-md transition-all duration-200 group"
//   onClick={() => navigate(-1)}
// >
//   <span className="text-red-500 group-hover:text-white text-2xl font-bold mb-1 transition-colors duration-200">&times;</span>
// </div>
// </div>
//           <h2 className="mb-10 text-xl font-bold text-gray-900 underline underline-offset-8 decoration-gray-300">
//             Edit Carrier Info
//           </h2>
//           <div className="grid gap-4 sm:grid-cols-3 sm:gap-6">
//             <div className="w-full">
//               <label
//                 htmlFor="vendorName"
//                 className="block mb-2 text-sm font-medium text-gray-900"
//               >
//                 Carrier Name{" "}
//                 <span className="text-red-500 text-xl font-extrabold">*</span>
//               </label>
//               {vendorNameError && (
//                 <CustomTooltip1
//                     content={vendorNameError}
//                     show={vendorNameError}
//                     marginLeft={120}
//                 />
//                 )}
//               <input
//                 type="text"
//                 id="vendorName"
//                 name="vendorName"
//                 value={formData.vendorName}
//                 // onChange={handleChange}
//                 onChange={(e) => {
//                   const inputValue = e.target.value.replace(/^[^a-zA-Z0-9]|(?<=[a-zA-Z0-9])[^a-zA-Z0-9\s]/g, '');
//                     if(inputValue.length < 3){
//                         setVendorNameError("Name should be at least 3 char's")
//                     }else{
//                         setVendorNameError(null)
//                     }
//                     // const inputValue = e.target.value;
//                     const sanitizedValue = inputValue.replace(/[^A-Za-z0-9\s]/g, "");
//                     const capitalizedValue = sanitizedValue.charAt(0).toUpperCase() + sanitizedValue.slice(1);
//                       // .toLowerCase()
//                       // .replace(/(?:^|\s)\S/g, (char) => char.toUpperCase());
//                     handleChange({
//                       target: {
//                         name: "vendorName",
//                         value: capitalizedValue,
//                       },
//                     });
//                   }}
//                 required
//                 className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
//                 placeholder="Enter Vendor Name"
//               />
//             </div>

//             <div className="w-full">
//               <label
//                 htmlFor="einNumber"
//                 className="block mb-2 text-sm font-medium text-gray-900"
//               >
//                 EIN Number{" "}
//                 <span className="text-red-500 text-xl font-extrabold">*</span>
//               </label>
//               <input
//   type="text"
//   id="einNumber"
//   name="einNumber"
//   value={formData.einNumber}
//   onChange={(e) => {
//     let inputValue = e.target.value;
  
//     // Remove any non-numeric characters
//     inputValue = inputValue.replace(/[^0-9]/g, "");
  
//     // Check if the input is valid (9 digits)
//     if (inputValue.length > 0 && inputValue.length < 9) {
//       setEinNumberError("EIN must be 9 digits");
//     } else {
//       setEinNumberError(""); // Clear error if valid or empty
//     }
  
//     // Limit to 9 digits and format
//     if (inputValue.length > 9) {
//       inputValue = inputValue.slice(0, 9);
//     }
    
//     // Format as XX-XXXXXXX if 9 digits are entered
//     if (inputValue.length === 9) {
//       inputValue = inputValue.slice(0, 2) + "-" + inputValue.slice(2);
//     }
  
//     // Update the form value
//     handleChange({
//       target: {
//         name: "einNumber",
//         value: inputValue,
//       },
//     });
//   }}
//   required
//   className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
//   placeholder="Enter EIN Number"
//   maxLength={10}
// />

// {einNumberError && (
//   <CustomTooltip1
//     content={einNumberError}
//     show={einNumberError}
//     marginLeft={120}
//   />
// )}


//             </div>

//             <div className="w-full">
//               <label
//                 htmlFor="contactName"
//                 className="block mb-2 text-sm font-medium text-gray-900"
//               >
//                 Contact Name{" "}
//                 <span className="text-red-500 text-xl font-extrabold">*</span>
//               </label>
//               {contactNameError && (
//                 <CustomTooltip1
//                     content={contactNameError}
//                     show={contactNameError}
//                     marginLeft={120}
//                 />
//                 )}
//               <input
//                 type="text"
//                 id="contactName"
//                 name="contactName"
//                 value={formData.contactName}
//                 onChange={(e) => {
//                   const inputValue = e.target.value.replace(/^[^a-zA-Z0-9]|(?<=[a-zA-Z0-9])[^a-zA-Z0-9\s]/g, '');

//                     if(inputValue.length < 3){
//                         setContactNameError("Name should be at least 3 char's")
//                     }else{
//                         setContactNameError(null)
//                     }
//                     // const inputValue = e.target.value;
//                     const sanitizedValue = inputValue.replace(/[^A-Za-z\s]/g, "");
//                     const capitalizedValue = sanitizedValue.charAt(0).toUpperCase() + sanitizedValue.slice(1);
//                       // .toLowerCase()
//                       // .replace(/(?:^|\s)\S/g, (char) => char.toUpperCase());
//                     handleChange({
//                       target: {
//                         name: "contactName",
//                         value: capitalizedValue,
//                       },
//                     });
//                   }}
//                 required
//                 className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
//                 placeholder="Enter Contact Name"
//               />
//             </div>

//             <div className="w-full">
//               <label
//                 htmlFor="contactNumber"
//                 className="block mb-2 text-sm font-medium text-gray-900"
//               >
//                 Contact Number{" "}
//                 <span className="text-red-500 text-xl font-extrabold">*</span>
//               </label>
//               {contactNumberError && (
//                 <CustomTooltip1
//                     content={contactNumberError}
//                     show={contactNumberError}
//                     marginLeft={120}
//                 />
//                 )}
//               <input
//                 type="text"
//                 id="contactNumber"
//                 name="contactNumber"
//                 value={formData.contactNumber}
//                 onChange={(e) => {
//                   const inputPhoneNumber = e.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
//                   const formattedValue = formatPhoneNumber(inputPhoneNumber); // Format the phone number
                  
//                   // Check if the phone number is less than 10 digits
//                   if (inputPhoneNumber.length < 10) {
//                     setContactNumberError("Phone number must be at least 10 digits");
//                   } else {
//                     // If the phone number has 10 or more digits, clear the error
//                     setContactNumberError("");
//                   }
                
//                   // Optional: If you still want to check for non-zero digits
//                   const containsNonZero = /[1-9]/.test(formattedValue);
//                   if (!containsNonZero && inputPhoneNumber.length > 0) {
//                     setContactNumberError("Invalid number");
//                   }
                
//                   if (formattedValue === "") {
//                     setContactNumberError(""); // Clear error if the input is empty
//                   }
                
//                   handleChange({
//                     target: {
//                       name: "contactNumber",
//                       value: formattedValue,
//                     }
//                   });
//                 }}
                
//                 required
//                 className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
//                 placeholder="Enter Contact Number"
//               />
//             </div>

//             <div className="w-full">
//               <label
//                 htmlFor="contactEmail"
//                 className="block mb-2 text-sm font-medium text-gray-900"
//               >
//                 Contact Email{" "}
//                 <span className="text-red-500 text-xl font-extrabold">*</span>
//               </label>
//               {contactEmailError && (
//                 <CustomTooltip1
//                     content={contactEmailError}
//                     show={contactEmailError}
//                     marginLeft={120}
//                 />
//                 )}
//               <input
//                 type="text"
//                 id="contactEmail"
//                 name="contactEmail"
//                 value={formData.contactEmail}
//                 onChange={(e) => {
//                   let value = e.target.value;
                
//                   // Ensure the first letter is uppercase and the rest is lowercase
//                   if (value.length > 1) {
//                     value = value[0] + value.slice(1).toLowerCase();
//                   }
                
//                   // Basic email format validation using regex
//                   const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                  
//                   // Check if the email matches the regex pattern
//                   if (value.length > 0 && !emailRegex.test(value)) {
//                     setContactEmailError("Invalid email");
//                   } else {
//                     setContactEmailError(null); // Clear error if the email is valid
//                   }
                
//                   // Update the form value
//                   handleChange({
//                     target: {
//                       name: "contactEmail",
//                       value: value
//                     }
//                   });
//                 }}
                
//                 required
//                 className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
//                 placeholder="Enter Contact Email"
//               />
//             </div>
//           </div>
//           {areAllFieldsFilled() && noError && (
            
//             <div className="flex justify-end space-x-3">
            
//               <button
//                 type="submit"
//                 className="hover:bg-[#0044AB] border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-sm font-semibold hover:border-transparent hover:shadow-lg hover:shadow-[#0044AB]/50 text-center hover:text-white rounded-lg focus:ring-4 focus:ring-primary-200 hover:bg-primary-800"
//               >
//                 Done
//               </button>
//               <button
//                 type="button"
//                 onClick={() => navigate('/vendors')}
//                 className="hover:bg-red-500 border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-sm font-semibold hover:border-transparent hover:shadow-lg hover:shadow-red-500/60 text-center hover:text-white rounded-lg focus:ring-4 focus:ring-primary-200 hover:bg-primary-800"
//               >
//                 Cancel
//               </button>
//             </div>
//           )}
//         </div>
//       </form>
//     </div>
//   );
// };

// export default ViewVendor;



import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../../config/config';
import { useNavigate, useParams } from 'react-router-dom';
import { showToastForDeleteVendor, showToastForUpdateVendor } from '../toasts/toastForVendor';
import { useDispatch } from 'react-redux';
import { deleteAllVendors } from '../../store/vendorSlice';
import { addToast } from '../pages/ToastBox/ToastBoxMessageSlice';
import { FaCheckCircle, FaHome, FaTimesCircle } from "react-icons/fa";
import CustomCommonTab from '../reusable/CustomCommonTab';

const ViewVendor = () => {
  // editcomponent
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {vendorid} = useParams()
  const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;

  // State to track original data for comparison
  const [originalData, setOriginalData] = useState(null);
  
  const [formData, setFormData] = useState({
    vendorName: '',
    einNumber: '',
    contactName: '',
    contactNumber: '',
    contactEmail: '',
    businessId: localStorage.getItem("business_id"),
    ownerId: localStorage.getItem("user_id"),
    edit_Id : '',
  });
  
  const [vendorNameError, setVendorNameError] = useState("");
  const [einNumberError, setEinNumberError] = useState("");
  const [contactNameError, setContactNameError] = useState("");
  const [contactNumberError, setContactNumberError] = useState("");
  const [contactEmailError, setContactEmailError] = useState("");

  const noError =
    !vendorNameError &&
    !einNumberError &&
    !contactNameError &&
    !contactNumberError &&
    !contactEmailError;

  // Function to check if form data has changed from original
  const hasFormChanged = () => {
    if (!originalData) return false;
    
    return (
      formData.vendorName !== originalData.vendorName ||
      formData.einNumber !== originalData.einNumber ||
      formData.contactName !== originalData.contactName ||
      formData.contactNumber !== originalData.contactNumber ||
      formData.contactEmail !== originalData.contactEmail
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    async function fetchData(){
      try {
        const response = await axios.get(`${baseURL}${btoa("vendor/viewvendorbyid")}/${btoa(vendorid)}`);
        console.log("view", response);
        const data = response.data.vendor_data[0];
        
        const initialData = {
          vendorName: data.truck_company_name,
          einNumber: data.ein_number,
          contactName: data.contact_name,
          contactNumber: data.contact_no,
          contactEmail: data.contact_email,
        };
        
        // Store original data for comparison
        setOriginalData(initialData);
        
        setFormData({
          ...formData,
          vendorName: data.truck_company_name,
          einNumber: data.ein_number,
          contactName: data.contact_name,
          contactNumber: data.contact_no,
          contactEmail: data.contact_email,
          edit_Id: data.id
        });
      } catch (error) {
        console.log("error fetching details", error);
      }
    }
    fetchData();
  }, []);

  const areAllFieldsFilled = () => {
    return Object.entries(formData)
      .filter(([key]) => !['businessId', 'managerId', 'ownerId', 'edit_Id'].includes(key))
      .every(([key, value]) => value?.trim() !== '');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(`${baseURL}/${btoa("vendor/update")}`, formData);
      // console.log("res", response);
      dispatch(deleteAllVendors())
      // showToastForUpdateVendor(`${formData.vendorName} details updated successfully`, "success" , "")
      dispatch(addToast({ type: 'approve', message: `${formData.vendorName} details updated successfully` }));        

      navigate('/vendors');
    } catch (error) {
      console.log("error updating vendor", error);
      showToastForDeleteVendor('Error updating vendor details', "error", "")
    }
  };
  
  const menuItems = [{ name: `Edit Carrier` }];
  
  const CustomTooltip1 = ({ content, show, marginLeft }) => {
    return (
      <div
        style={{
          display: show ? 'block' : 'none',
          position: 'absolute',
          backgroundColor: '#ffffff',
          color: '#fff',
          padding: '5px',
          borderRadius: '4px',
          border:'1px solid red',
          marginTop: '-35px',
          marginLeft: `${marginLeft}px`,  // Use the provided marginLeft prop
          boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        }}
      >
        <div
          style={{
            position: 'absolute',
            bottom: '-7px',
            left: '50%',
            marginLeft: '-10px',
            width: '0',
            height: '0',
            borderLeft: '7px solid transparent',
            borderRight: '7px solid transparent',
            borderTop: '7px solid #f98080',
          }}
        ></div>
        <p className="text-xs text-red-500">{content}</p>
      </div>
    );
  };
  
  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    cleaned = cleaned.slice(0, 10);
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = (match[1] ? '+1 ' : '');
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return cleaned; // Return cleaned input if no match
  }
  
  const [activeTab, setActiveTab] = useState("View");

  return (
    <div className="pt-0 h-screen">
      <div className="flex-1 flex justify-center">
        <CustomCommonTab items={menuItems} />
      </div>
      
      <form onSubmit={handleSubmit}>
        <div className="relative w-auto bg-white rounded-box mx-8 rounded-xl" style={{ marginTop: "0px" }}>
          <div className="absolute -top-5 -right-4 flex items-center justify-end space-x-2 z-10">
            <div
              className="flex items-center justify-center cursor-pointer rounded-full bg-[#cefafe] hover:bg-[#25caed] w-9 h-9 border-[0.5px] border-[#25caed] hover:border-[#25caed] hover:scale-110 shadow-md transition-all duration-200 group"
              onClick={() => {
                navigate("/");
              }}
              style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
            >
              <FaHome className="text-[#25caed] group-hover:text-white transition-colors duration-200" size={16} />
            </div>

            <div
              className="bg-red-200 hover:bg-red-500 w-9 h-9 flex items-center justify-center rounded-full cursor-pointer hover:bg-red-700 border-[0.5px] border-red-500 hover:border-red-700 hover:scale-110 shadow-md transition-all duration-200 group"
              onClick={() => navigate(-1)}
            >
              <span className="text-red-500 group-hover:text-white text-2xl font-bold mb-1 transition-colors duration-200">&times;</span>
            </div>
          </div>
          <h2 className="mb-10 text-xl font-bold text-gray-900 underline underline-offset-8 decoration-gray-300">
            Edit Carrier Info
          </h2>
          <div className="grid gap-4 sm:grid-cols-3 sm:gap-6">
            <div className="w-full">
              <label
                htmlFor="vendorName"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Carrier Name{" "}
                <span className="text-red-500 text-xl font-extrabold">*</span>
              </label>
              {vendorNameError && (
                <CustomTooltip1
                  content={vendorNameError}
                  show={vendorNameError}
                  marginLeft={120}
                />
              )}
              <input
                type="text"
                id="vendorName"
                name="vendorName"
                value={formData.vendorName}
                onChange={(e) => {
                  const inputValue = e.target.value.replace(/^[^a-zA-Z0-9]|(?<=[a-zA-Z0-9])[^a-zA-Z0-9\s]/g, '');
                  if(inputValue.length < 3){
                    setVendorNameError("Name should be at least 3 char's")
                  }else{
                    setVendorNameError(null)
                  }
                  // const inputValue = e.target.value;
                  const sanitizedValue = inputValue.replace(/[^A-Za-z0-9\s]/g, "");
                  const capitalizedValue = sanitizedValue.charAt(0).toUpperCase() + sanitizedValue.slice(1);
                    // .toLowerCase()
                    // .replace(/(?:^|\s)\S/g, (char) => char.toUpperCase());
                  handleChange({
                    target: {
                      name: "vendorName",
                      value: capitalizedValue,
                    },
                  });
                }}
                required
                className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                placeholder="Enter Vendor Name"
              />
            </div>

            <div className="w-full">
              <label
                htmlFor="einNumber"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                EIN Number{" "}
                <span className="text-red-500 text-xl font-extrabold">*</span>
              </label>
              <input
                type="text"
                id="einNumber"
                name="einNumber"
                value={formData.einNumber}
                onChange={(e) => {
                  let inputValue = e.target.value;
                
                  // Remove any non-numeric characters
                  inputValue = inputValue.replace(/[^0-9]/g, "");
                
                  // Check if the input is valid (9 digits)
                  if (inputValue.length > 0 && inputValue.length < 9) {
                    setEinNumberError("EIN must be 9 digits");
                  } else {
                    setEinNumberError(""); // Clear error if valid or empty
                  }
                
                  // Limit to 9 digits and format
                  if (inputValue.length > 9) {
                    inputValue = inputValue.slice(0, 9);
                  }
                  
                  // Format as XX-XXXXXXX if 9 digits are entered
                  if (inputValue.length === 9) {
                    inputValue = inputValue.slice(0, 2) + "-" + inputValue.slice(2);
                  }
                
                  // Update the form value
                  handleChange({
                    target: {
                      name: "einNumber",
                      value: inputValue,
                    },
                  });
                }}
                required
                className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                placeholder="Enter EIN Number"
                maxLength={10}
              />

              {einNumberError && (
                <CustomTooltip1
                  content={einNumberError}
                  show={einNumberError}
                  marginLeft={120}
                />
              )}
            </div>

            <div className="w-full">
              <label
                htmlFor="contactName"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Contact Name{" "}
                <span className="text-red-500 text-xl font-extrabold">*</span>
              </label>
              {contactNameError && (
                <CustomTooltip1
                  content={contactNameError}
                  show={contactNameError}
                  marginLeft={120}
                />
              )}
              <input
                type="text"
                id="contactName"
                name="contactName"
                value={formData.contactName}
                onChange={(e) => {
                  const inputValue = e.target.value.replace(/^[^a-zA-Z0-9]|(?<=[a-zA-Z0-9])[^a-zA-Z0-9\s]/g, '');

                  if(inputValue.length < 3){
                    setContactNameError("Name should be at least 3 char's")
                  }else{
                    setContactNameError(null)
                  }
                  // const inputValue = e.target.value;
                  const sanitizedValue = inputValue.replace(/[^A-Za-z\s]/g, "");
                  const capitalizedValue = sanitizedValue.charAt(0).toUpperCase() + sanitizedValue.slice(1);
                    // .toLowerCase()
                    // .replace(/(?:^|\s)\S/g, (char) => char.toUpperCase());
                  handleChange({
                    target: {
                      name: "contactName",
                      value: capitalizedValue,
                    },
                  });
                }}
                required
                className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                placeholder="Enter Contact Name"
              />
            </div>

            <div className="w-full">
              <label
                htmlFor="contactNumber"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Contact Number{" "}
                <span className="text-red-500 text-xl font-extrabold">*</span>
              </label>
              {contactNumberError && (
                <CustomTooltip1
                  content={contactNumberError}
                  show={contactNumberError}
                  marginLeft={120}
                />
              )}
              <input
                type="text"
                id="contactNumber"
                name="contactNumber"
                value={formData.contactNumber}
                onChange={(e) => {
                  const inputPhoneNumber = e.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
                  const formattedValue = formatPhoneNumber(inputPhoneNumber); // Format the phone number
                  
                  // Check if the phone number is less than 10 digits
                  if (inputPhoneNumber.length < 10) {
                    setContactNumberError("Phone number must be at least 10 digits");
                  } else {
                    // If the phone number has 10 or more digits, clear the error
                    setContactNumberError("");
                  }
                
                  // Optional: If you still want to check for non-zero digits
                  const containsNonZero = /[1-9]/.test(formattedValue);
                  if (!containsNonZero && inputPhoneNumber.length > 0) {
                    setContactNumberError("Invalid number");
                  }
                
                  if (formattedValue === "") {
                    setContactNumberError(""); // Clear error if the input is empty
                  }
                
                  handleChange({
                    target: {
                      name: "contactNumber",
                      value: formattedValue,
                    }
                  });
                }}
                
                required
                className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                placeholder="Enter Contact Number"
              />
            </div>

            <div className="w-full">
              <label
                htmlFor="contactEmail"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Contact Email{" "}
                <span className="text-red-500 text-xl font-extrabold">*</span>
              </label>
              {contactEmailError && (
                <CustomTooltip1
                  content={contactEmailError}
                  show={contactEmailError}
                  marginLeft={120}
                />
              )}
              <input
                type="text"
                id="contactEmail"
                name="contactEmail"
                value={formData.contactEmail}
                onChange={(e) => {
                  let value = e.target.value;
                
                  // Ensure the first letter is uppercase and the rest is lowercase
                  if (value.length > 1) {
                    value = value[0] + value.slice(1).toLowerCase();
                  }
                
                  // Basic email format validation using regex
                  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                  
                  // Check if the email matches the regex pattern
                  if (value.length > 0 && !emailRegex.test(value)) {
                    setContactEmailError("Invalid email");
                  } else {
                    setContactEmailError(null); // Clear error if the email is valid
                  }
                
                  // Update the form value
                  handleChange({
                    target: {
                      name: "contactEmail",
                      value: value
                    }
                  });
                }}
                
                required
                className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                placeholder="Enter Contact Email"
              />
            </div>
          </div>
          
          {/* Always show buttons but enable/disable based on form status */}
          <div className="flex justify-end space-x-3">
            <button
              type="submit"
              disabled={!hasFormChanged() || !areAllFieldsFilled() || !noError}
              className={`border-[#3479E0] border-2 px-6 py-1.5 mt-4 sm:mt-6 text-sm font-semibold rounded-lg focus:ring-4 focus:ring-primary-200 transition-all duration-200 ${
                hasFormChanged() && areAllFieldsFilled() && noError
                  ? "hover:bg-[#0044AB] text-black hover:border-transparent hover:shadow-lg hover:shadow-[#0044AB]/50 hover:text-white"
                  : "bg-gray-100 text-gray-400 cursor-not-allowed border-gray-300"
              }`}
            >
              Done
            </button>
            <button
              type="button"
              disabled={!hasFormChanged()}
              onClick={() => navigate('/vendors')}
              className={`border-2 px-6 py-1.5 mt-4 sm:mt-6 text-sm font-semibold rounded-lg focus:ring-4 focus:ring-primary-200 transition-all duration-200 ${
                hasFormChanged()
                  ? "hover:bg-red-500 border-[#3479E0] text-black hover:border-transparent hover:shadow-lg hover:shadow-red-500/60 hover:text-white"
                  : "bg-gray-100 text-gray-400 cursor-not-allowed border-gray-300"
              }`}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ViewVendor;
import React, { useState, useRef, useEffect } from "react";
import { Box, Paper } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import { useSelector, useDispatch } from "react-redux";
import { Grid, IconButton } from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PrintIcon from "@mui/icons-material/Print";
import DescriptionIcon from "@mui/icons-material/Description";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DatePicker from "react-datepicker";
import axios from "axios";
import { Tooltip } from "react-tippy";

import config from "../../config/config";
import FullScreenLoader from "../pages/FullScreenLoader";

const POByStateReport = () => {
  const businessId = localStorage.getItem("business_id");
  const managerId = localStorage.getItem("user_id");
  const baseURL =
    process.env.NODE_ENV === "production"
      ? config.production.baseURL
      : config.development.baseURL;
  const [periodType, setPeriodType] = useState(""); // Tracks the selected period (Year, Month, Quarterly)
  const [tempperiodType, settempPeriodType] = useState(""); // Tracks the selected period (Year, Month, Quarterly)

  const [selectedYear, setSelectedYear] = useState("2025"); // Tracks the selected year
  const [tempYear, setTempYear] = useState(""); // Tracks the selected year

  const [selectedMonth, setSelectedMonth] = useState("January"); // Tracks the selected month
  const [tempMonth, setTempMonth] = useState(""); // Tracks the selected year

  const [selectedQuarter, setSelectedQuarter] = useState("Q1");
  const [tempQuarter, setTempQuarter] = useState(""); // Tracks the selected year

  const [brand, setBrand] = useState("");
  const [tempcustomer, setTempCustomer] = useState("");

  const [fuelGrade, setFuelGrade] = useState("");
  
  // For custom date range
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const customersAll = useSelector((state) => state.customer.allCustomers);
  const dispatch = useDispatch();
  const [dateTime, setDateTime] = useState("");
  const [salesResponse, setSalesReport] = useState([]);
  const [imageSource, setImageSource] = useState();
  const [imageResponse, setimageResponse] = useState([]);
  const [loading, setLoading] = useState(true); // Add a loading state
  const [pdfhovered, setPdfHovered] = useState(false);
  const [taxhovered, setTaxHovered] = useState(false);
  const [productData, setProductData] = useState([]);

  const [RunClicked, setRunClicked] = useState(false); // Tracks the selected quarter
  const user_id = localStorage.getItem("user_id");
  const user_role = localStorage.getItem("user_role");
  const business_id = localStorage.getItem("business_id");
  const contentRef = useRef();
  const [userRole, setUserRole] = useState("");
  const from = salesResponse.data?.from;
  const to = salesResponse.data?.to;
  const isButtonEnabled = () => {
    if (periodType === "Yearly" && selectedYear && selectedText) return true;
    if (
      periodType === "Monthly" &&
      selectedYear &&
      selectedMonth &&
      selectedText
    )
      return true;
    if (
      periodType === "Quarterly" &&
      selectedYear &&
      selectedQuarter &&
      selectedText
    )
      return true;
    if (
      periodType === "Custom Range" &&
      startDate &&
      endDate &&
      selectedText
    )
      return true;
    return false;
  };

  const [sellerNames, setSellerNames] = useState([]);
  const [poNumber, setPoNumber] = useState("");

  // Fetch seller names on component mount
  useEffect(() => {
    const fetchSellersNames = async () => {
      try {
        const response = await axios.get(
          `${baseURL}${btoa("invoice/allsellers")}`
        );
        setSellerNames(response.data);
      } catch (error) {
        console.log("error fetching seller names", error);
      }
    };
    fetchSellersNames();
  }, []);

  const formatToTwoDecimalsWithCommas = (num) => {
    const parts = num.toString().split(".");
    const integerPartWithCommas = parseInt(parts[0], 10).toLocaleString(
      "en-US"
    ); 
    const decimalPart = parts[1] ? parts[1].substring(0, 2) : "";

    return decimalPart
      ? `${integerPartWithCommas}.${decimalPart}`
      : integerPartWithCommas;
  };

  const generateUSDateTime = () => {
    setRunClicked(true);
    const currentDate = new Date();

    // Format date and time in US format
    const formattedDateTime = currentDate.toLocaleString("en-US", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
     
    });

    setDateTime(formattedDateTime);
  };

  function formatNumberwithoutzeros(num) {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(num);
  }

  const handlePdfDownload = async () => {
    const brandNamePdf = selectedText === "All Brands" ? "all" : selectedText;
    const selectedMonthpdf = selectedMonth === "" ? null : selectedMonth;
    const selectedQuarterpdf = selectedQuarter === "" ? null : selectedQuarter;
    const datepdf = new Date().toISOString().slice(0, 19).replace("T", " ");

    try {
      const response = await axios.get(
        `${baseURL}/${btoa("purchase/download_purchasedinvoicespdf")}/${btoa(
          businessId
        )}/${btoa(managerId)}/${btoa(selectedBrandName)}/${btoa(
          brandNamePdf
        )}/${btoa(null)}/${btoa(periodType)}/${btoa(selectedYear)}/${btoa(
          selectedMonthpdf
        )}/${btoa(selectedQuarterpdf)}`,
        { responseType: "blob" }
      );
      
      const filename = `${
        brandNamePdf === "all" ? "All Brands" : brandNamePdf
      } ${selectedMonth} ${selectedQuarter} ${selectedYear} Invoices ${datepdf}.pdf`;
      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

    } catch (error) {
      console.log("Error downloading invoice:", error);
    }
  };

  const handlePrint = () => {
    const content = contentRef.current.innerHTML;
    const newWindow = window.open("", "_blank");
    newWindow.document.write(`
      <html>
        <head>
          <title>Print</title>
          <style>
            @media print {
              img {
                display: block;
                width: 100px;
                height: auto;
              }
              @page {
                margin: 0;
              }
              body {
                margin: 0;
                padding: 0;
              }
              header, footer {
                display: none;
              }
            }
            body {
              font-family: Arial, sans-serif;
              margin: 30px;
            }
          </style>
        </head>
        <body>
          ${content} 
        </body>
      </html>
    `);
    newWindow.document.close();
    newWindow.print();
  };

  function formatNumber(num) {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(num);
  }

  useEffect(() => {
    setUserRole(localStorage.getItem("user_role"));
  }, []);

  useEffect(() => {
    axios
      .get(
        `${baseURL}/${btoa("business/profile")}/${btoa(business_id)}/${btoa(
          user_id
        )}/${btoa(user_role)}`
      )
      .then((response) => {
        setImageSource(response.data.business_data[0].image_file);
        setimageResponse(response.data.business_data[0]);
      })
      .catch((error) => {
        console.error("Error fetching business data:", error);
      });
  }, []);

  // Years and Months for dropdowns
  const years = ["2025", "2024", "2023", "2022", "2021"];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];
  const quarters = [
    { bName: "Q1", fName: "Q1 (Mar-May)" },
    { bName: "Q2", fName: "Q2 (June-Aug)" },
    { bName: "Q3", fName: "Q3 (Sep-Nov)" },
    { bName: "Q4", fName: "Q4 (Dec-Feb)" }
  ];

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && isButtonEnabled()) {
      handleRun();
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get(`${baseURL}/${btoa("product/view")}`)
      .then((response) => {
        setProductData(response.data.products_data);
      })
      .catch((error) => {
        console.error("Error fetching customer data:", error);
      });
  }, []);

  const [selectedText, setSelectedText] = useState("All Brands");
  const [selectedBrandName, setSelectedBrandName] = useState("all");

  const handleChangeFuelBrands = (e) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    setSelectedBrandName(selectedOption.value);
    setSelectedText(selectedOption.text);
  };

  const handlePeriodChange = (event) => {
    setPeriodType(event.target.value);
    // Reset any fields that might not be needed for the new period type
    if (event.target.value === "Yearly") {
      setSelectedMonth("");
      setSelectedQuarter("");
      setStartDate(new Date());
      setEndDate(new Date());
    } else if (event.target.value === "Monthly") {
      setSelectedQuarter("");
      setStartDate(new Date());
      setEndDate(new Date());
    } else if (event.target.value === "Quarterly") {
      setSelectedMonth("");
      setStartDate(new Date());
      setEndDate(new Date());
    } else if (event.target.value === "Custom Range") {
      setSelectedYear("");
      setSelectedMonth("");
      setSelectedQuarter("");
    }
  };

  const handleRun = async () => {
    setRunClicked(true);
    setLoading(true); // Start loader
    setTempYear(selectedYear);
    setTempMonth(selectedMonth);
    setTempQuarter(selectedQuarter);
    settempPeriodType(periodType);
    setTempCustomer(brand);

    try {
      let requestData = {
        reportType: periodType,
        businessId: localStorage.getItem("business_id"),
        managerId: localStorage.getItem("user_id"),
        brandId: selectedBrandName,
        brandName: selectedText === "All Brands" ? "all" : selectedText,
        userRole: userRole,
        fuelGradeName: null
      };

      if (periodType === "Monthly") {
        requestData.monthName = selectedMonth;
        requestData.year = selectedYear;
      } else if (periodType === "Quarterly") {
        requestData.quarterName = selectedQuarter;
        requestData.year = selectedYear;
      } else if (periodType === "Yearly") {
        requestData.year = selectedYear;
      } else if (periodType === "Custom Range") {
        requestData.startDate = startDate.toISOString().split('T')[0];
        requestData.endDate = endDate.toISOString().split('T')[0];
      }

      const response = await axios.post(
        `${baseURL}/${btoa("purchase/viewpurchasedinvoicesfilter")}`,
        requestData
      );

      setSalesReport(response);
    } catch (error) {
      console.error("Error posting details:", error);
    } finally {
      setLoading(false); // Stop loader after data fetch
    }
  };

  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // CSS styles
  const themeColor = "rgb(231 70 148)";
  const labelStyle = "block mb-2 text-[14px] font-medium text-gray-600 inline-flex items-center";
  const inputStyle = "border border-gray-300 text-sm font-medium rounded-lg focus:ring-grey-600 focus:border-primary-600 block w-full text-gray-500 py-1 px-2 h-[30px]";
  const buttonStyle = {
    backgroundColor: themeColor,
    color: "white",
    marginTop: "34px",
    height: "30px",
    "&:hover": {
      backgroundColor: "rgb(231 70 148)"
    }
  };

  return (
    <div className="ml-5">
      <>
        <div
          className="flex justify-between items-center w-full pt-4 mt-4 mb-5"
          style={{
            maxWidth: "1200px",
            boxSizing: "border-box",
            marginLeft: "4px",
            color: "#444444"
          }}
        >
          <nav aria-label="breadcrumb">
            <ol className="flex space-x-2 text-lg font-bold text-gray-700">
              <li>Reports</li>
              <li>
                <span className="text-gray-400">{">>"}</span>
              </li>
              <li>PO's Order Report</li>
            </ol>
          </nav>
        </div>

        <div
          className="py-[12px] px-[8px] rounded-xl bg-white"
          style={{ boxShadow: "0 0 25px 0 rgba(0, 0, 0, 0.2)" }}
        >
          <Grid
            container
            spacing={3}
            style={{ display: "flex", alignItems: "center" }}
            className="pl-[10px] pr-6"
          >
            {/* Report Type Dropdown - Always visible */}
            <Grid item md={2.1}>
              <div>
                <label className={labelStyle}>
                 Duration                  
                  <span className="text-red-500 text-xl font-extrabold pl-1">*</span>
                </label>
                <select
                  id="Select"
                  name="Select"
                  required
                  onChange={handlePeriodChange}
                  value={periodType}
                  className={inputStyle}
                  style={{ borderColor: "grey" }}
                >
                  <option value="">--Select--</option>
                  <option value="Monthly">Monthly</option>
                  <option value="Quarterly">Quarterly</option>
                  <option value="Yearly">Yearly</option>
                  <option value="Custom Range">Custom Range</option>
                </select>
              </div>
            </Grid>

            {/* Display Brand Dropdown when Report Type is selected */}
            {periodType && (
              <Grid item md={2.1}>
                <div className="flex-grow">
                  <label className={labelStyle}>
                    Select Brand
                    <span className="text-red-500 text-xl font-extrabold pl-1">*</span>
                  </label>
                  <select
                    id="Select Brand"
                    name="Select Brand"
                    required
                    onChange={handleChangeFuelBrands}
                    className={inputStyle}
                    style={{ borderColor: "grey" }}
                  >
                    <option value="all">All Brands</option>
                    {sellerNames.map((seller) => (
                      <option key={seller.id} value={seller.id}>
                        {seller.seller_name.charAt(0).toUpperCase() +
                          seller.seller_name.slice(1).toLowerCase()}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
            )}

            {/* Monthly Specific Fields */}
            {periodType === "Monthly" && (
              <>
                <Grid item md={2.1}>
                  <div>
                    <label className={labelStyle}>
                      Select Month
                      <span className="text-red-500 text-xl font-extrabold pl-1">*</span>
                    </label>
                    <select
                      id="Select Month"
                      name="Select Month"
                      required
                      value={selectedMonth}
                      onChange={(e) => setSelectedMonth(e.target.value)}
                      className={inputStyle}
                      style={{ borderColor: "grey" }}
                    >
                      {months.map((month) => (
                        <option key={month} value={month}>
                          {month}
                        </option>
                      ))}
                    </select>
                  </div>
                </Grid>
                <Grid item md={2.1}>
                  <div>
                    <label className={labelStyle}>
                      Select Year
                      <span className="text-red-500 text-xl font-extrabold pl-1">*</span>
                    </label>
                    <select
                      id="Select Year"
                      name="Select Year"
                      required
                      value={selectedYear}
                      onChange={(e) => setSelectedYear(e.target.value)}
                      className={inputStyle}
                      style={{ borderColor: "grey" }}
                    >
                      {years.map((year) => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      ))}
                    </select>
                  </div>
                </Grid>
              </>
            )}

            {/* Quarterly Specific Fields */}
            {periodType === "Quarterly" && (
              <>
                <Grid item md={2.1}>
                  <div>
                    <label className={labelStyle}>
                      Select Quarter
                      <span className="text-red-500 text-xl font-extrabold pl-1">*</span>
                    </label>
                    <select
                      id="Select Quarter"
                      name="Select Quarter"
                      required
                      value={selectedQuarter}
                      onChange={(e) => setSelectedQuarter(e.target.value)}
                      className={inputStyle}
                      style={{ borderColor: "grey" }}
                    >
                      {quarters.map((quarter) => (
                        <option key={quarter.fName} value={quarter.bName}>
                          {quarter.fName}
                        </option>
                      ))}
                    </select>
                  </div>
                </Grid>
                <Grid item md={2.1}>
                  <div>
                    <label className={labelStyle}>
                      Select Year
                      <span className="text-red-500 text-xl font-extrabold pl-1">*</span>
                    </label>
                    <select
                      id="Select Year"
                      name="Select Year"
                      required
                      value={selectedYear}
                      onChange={(e) => setSelectedYear(e.target.value)}
                      className={inputStyle}
                      style={{ borderColor: "grey" }}
                    >
                      {years.map((year) => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      ))}
                    </select>
                  </div>
                </Grid>
              </>
            )}

            {/* Yearly Specific Fields */}
            {periodType === "Yearly" && (
              <Grid item md={2.1}>
                <div>
                  <label className={labelStyle}>
                    Select Year
                    <span className="text-red-500 text-xl font-extrabold pl-1">*</span>
                  </label>
                  <select
                    id="Select Year"
                    name="Select Year"
                    required
                    value={selectedYear}
                    onChange={(e) => setSelectedYear(e.target.value)}
                    className={inputStyle}
                    style={{ borderColor: "grey" }}
                  >
                    {years.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
            )}

            {/* Custom Range Specific Fields */}
            {periodType === "Custom Range" && (
              <>
                <Grid item md={2.1}>
                  <div>
                    <label className={labelStyle}>
                      From Date
                      <span className="text-red-500 text-xl font-extrabold pl-1">*</span>
                    </label>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      className={inputStyle}
                      dateFormat="MM/dd/yyyy"
                      style={{ borderColor: "grey" }}
                    />
                  </div>
                </Grid>
                <Grid item md={2.1}>
                  <div>
                    <label className={labelStyle}>
                      To Date
                      <span className="text-red-500 text-xl font-extrabold pl-1">*</span>
                    </label>
                    <DatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      className={inputStyle}
                      dateFormat="MM/dd/yyyy"
                      minDate={startDate}
                      style={{ borderColor: "grey" }}
                    />
                  </div>
                </Grid>
              </>
            )}

            {/* Run Button */}
            {periodType && (
              <Grid item>
                <Button
                  variant="contained"
                  disabled={!isButtonEnabled()}
                  sx={buttonStyle}
                  endIcon={<SendIcon />}
                  onClick={() => {
                    generateUSDateTime();
                    handleRun();
                  }}
                >
                  Run
                </Button>
              </Grid>
            )}
          </Grid>
        </div>

        {RunClicked ? (
          <div id="runclicked">
            {RunClicked && (
              <div
                className="bg-white"
                style={{
                  borderRadius: "20px",
                  maxWidth: "100%",
                  overflowX: "auto",
                  margin: "0 auto",
                  flexDirection: "column",
                  boxShadow: "0 0 25px 0 rgba(0, 0, 0, 0.2)",
                  margin: "15px 0px 15px 0px",
                  marginTop: "45px",
                  padding: "20px",
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column"
                }}
              >
                {salesResponse.data &&
                salesResponse.data.purchasedInvoicesTotals &&
                salesResponse.data.purchasedInvoicesTotals.length > 0 ? (
                  <Grid container alignItems="end" justifyContent="end">
                    {/* PDF Download Icon */}
                    <Grid item xs={12} md={4}>
                      <Box p={2} className="font-bold text-md text-gray-400">
                        {RunClicked && <p>Report RunTime: {dateTime} </p>}
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <Grid container alignItems="end" justifyContent="end">
                        <Grid item>
                          <Tooltip
                            title="Pdf"
                            position="top"
                            trigger="mouseenter"
                          >
                            <IconButton
                              aria-label="Download PDF"
                              sx={{
                                backgroundColor: "transparent",
                                borderRadius: "50%",
                                padding: "12px",
                                "&:hover": {
                                  backgroundColor: "rgba(227, 140, 151, 0.3)"
                                },
                                transition: "background-color 0.3s ease"
                              }}
                              onMouseEnter={() => setPdfHovered(true)}
                              onMouseLeave={() => setPdfHovered(false)}
                              onClick={handlePdfDownload}
                            >
                              {pdfhovered ? (
                                <FileDownloadIcon
                                  sx={{ fontSize: 20, color: themeColor }}
                                />
                              ) : (
                                <PictureAsPdfIcon
                                  fontSize="small"
                                  sx={{ color: themeColor }}
                                />
                              )}
                            </IconButton>
                          </Tooltip>
                        </Grid>
                        <Grid item>
                          <Tooltip
                            title="Print"
                            position="top"
                            trigger="mouseenter"
                          >
                            <IconButton
                              aria-label="Print"
                              onClick={handlePrint}
                              sx={{
                                backgroundColor: "transparent",
                                borderRadius: "50%",
                                padding: "12px",
                                "&:hover": {
                                  backgroundColor: "rgba(0, 0, 0, 0.1)"
                                },
                                transition: "background-color 0.3s ease"
                              }}
                            >
                              <PrintIcon
                                fontSize="small"
                                sx={{ color: "black" }}
                              />
                            </IconButton>
                          </Tooltip>
                        </Grid>

                        {/* Excel File Icon */}
                        <Grid item>
                          <Tooltip
                            title="Excel"
                            position="top"
                            trigger="mouseenter"
                          >
                            <IconButton
                              aria-label="Excel File"
                              sx={{
                                backgroundColor: "transparent",
                                borderRadius: "50%",
                                padding: "12px",
                                "&:hover": {
                                  backgroundColor: "rgba(143, 191, 96, 0.3)"
                                },
                                transition: "background-color 0.3s ease"
                              }}
                              onMouseEnter={() => setTaxHovered(true)}
                              onMouseLeave={() => setTaxHovered(false)}
                            >
                              {taxhovered ? (
                                <FileDownloadIcon
                                  sx={{ fontSize: 20, color: themeColor }}
                                />
                              ) : (
                                <DescriptionIcon
                                  fontSize="small"
                                  style={{ color: "#4CAF50" }}
                                />
                              )}
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  ""
                )}

                <div
                  ref={contentRef}
                  style={{
                    width: "100%",
                    height: "auto",
                    justifyContent: "center",
                    alignSelf: "center",
                    borderRadius: "10px",
                    boxShadow: "0 0 20px 0 rgba(0, 0, 0, .1)",
                    padding: "20px",
                    marginTop: "20px"
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: "110px",
                      justifyContent: "space-between",
                      marginBottom: "20px",
                      backgroundColor: "rgba(180,180,180,0.1)"
                    }}
                  >
                    {/* Business Logo */}
                    {userRole !== "1" && (
                      <div className="">
                        {imageSource && (
                          <img
                            style={{ width: "140px", height: "60px" }}
                            src={`${baseURL}uploads/img/business/${imageSource}`}
                            alt="Business Logo"
                          />
                        )}
                      </div>
                    )}

                    {/* Business Info */}
                    <div style={{ textAlign: "center", marginLeft: "90px" }}>
                      <p>
                        <strong
                          style={{
                            fontWeight: "600",
                            fontSize: "18px",
                            margin: "5px"
                          }}
                        >
                          {imageResponse.business_name}
                        </strong>
                      </p>
                      <p>
                        <strong
                          style={{
                            fontWeight: "600",
                            fontSize: "18px",
                            margin: "5px"
                          }}
                        >
                          {imageResponse.business_address}
                        </strong>
                      </p>
                      <p>
                        <strong
                          style={{
                            fontWeight: "600",
                            fontSize: "18px",
                            margin: "5px"
                          }}
                        >
                          {imageResponse.business_city},{" "}
                          {imageResponse.business_state},{" "}
                          {imageResponse.business_zip_code}
                        </strong>
                      </p>
                    </div>

                    {/* System Logo */}
                    <img
                      src="/static/media/logo.0ea4b695a3030ea659a8.png"
                      alt="invoicefilelogo"
                      className="w-max"
                      style={{
                        width: "240px", // Set the size of the images
                        height: "200px"
                      }}
                    ></img>
                  </div>
                  {RunClicked && (
                    <div
                      style={{
                        color: themeColor,
                        textAlign: "center",
                        fontSize: "21px",
                        fontWeight: "bold",
                        marginTop: "50px"
                      }}
                      className="border-b border-gray-300 mb-1 mt-2"
                    >
                      {tempperiodType === "Yearly" &&
                        tempYear &&
                        RunClicked &&
                        `${tempYear} - Purchased - ${
                          salesResponse?.data?.brandName === "all"
                            ? "All "
                            : salesResponse?.data?.brandName
                        } Invoices `}
                      {tempperiodType === "Monthly" &&
                        tempYear &&
                        tempMonth &&
                        RunClicked &&
                        `${tempMonth} - ${tempYear} - Purchased - ${
                          salesResponse?.data?.brandName === "all"
                            ? "All "
                            : salesResponse?.data?.brandName
                        } Invoices `}
                      {tempperiodType === "Quarterly" &&
                        tempYear &&
                        tempQuarter &&
                        RunClicked &&
                        ` ${tempQuarter == "Q1" ? "Q1 (Mar-May)" : ""} ${
                          tempQuarter == "Q2" ? "Q2 (June-Aug)" : ""
                        } ${tempQuarter == "Q3" ? "Q3 (Sep-Nov)" : ""} ${
                          tempQuarter == "Q4" ? "Q4 (Dec-Feb)" : ""
                        } - Purchased - ${
                          salesResponse?.data?.brandName === "all"
                            ? "All "
                            : salesResponse?.data?.brandName
                        } Invoices  `}
                      {tempperiodType === "Custom Range" &&
                        RunClicked &&
                        `${startDate.toLocaleDateString()} to ${endDate.toLocaleDateString()} - Purchased - ${
                          salesResponse?.data?.brandName === "all"
                            ? "All "
                            : salesResponse?.data?.brandName
                        } Invoices`}
                    </div>
                  )}

                  {/* Report Table */}
                  <>
                    {RunClicked && loading ? (
                      <FullScreenLoader />
                    ) : RunClicked &&
                      salesResponse?.data?.purchasedInvoicesTotals?.length > 0 ? (
                      <div style={{ marginTop: "14px" }}>
                        <table
                          style={{
                            width: "60%",
                            maxWidth: "700px",
                            margin: "0px auto",
                            fontWeight: "normal",
                            borderCollapse: "collapse"
                          }}
                        >
                          {/* Header Row */}
                          <thead style={{ backgroundColor: "transparent" }}>
                            <tr
                              style={{
                                textAlign: "center",
                                marginBottom: "10px",
                                color: themeColor
                              }}
                            >
                              <th
                                style={{
                                  padding: "10px",
                                  textAlign: "left",
                                  width: "300px",
                                  fontSize: "19px"
                                }}
                              >
                                Fuel Grade
                              </th>
                              <th
                                style={{
                                  padding: "10px",
                                  textAlign: "right",
                                  width: "80px",
                                  fontSize: "19px"
                                }}
                              >
                                Gallons
                              </th>
                              <th
                                style={{
                                  padding: "10px",
                                  textAlign: "right",
                                  fontSize: "19px"
                                }}
                              >
                                Amount
                              </th>
                            </tr>
                          </thead>

                          {/* Data Rows */}
                          <tbody>
                            {salesResponse.data.purchasedInvoicesTotals.map(
                              (item) => (
                                <tr
                                  key={item.product_id || item.product_name}
                                  style={{
                                    textAlign: "center",
                                    marginBottom: "10px"
                                  }}
                                >
                                  <td
                                    style={{
                                      padding: "10px",
                                      fontWeight: "lighter",
                                      textAlign: "left",
                                      fontSize: "15px"
                                    }}
                                  >
                                    {item.fuelgrade_name || ""}
                                  </td>
                                  <td
                                    style={{
                                      padding: "10px",
                                      fontWeight: "lighter",
                                      textAlign: "right",
                                      fontSize: "16px"
                                    }}
                                  >
                                    {`${formatNumberwithoutzeros(
                                      item.gross_quantity_total
                                    )} gl`}
                                  </td>
                                  <td
                                    style={{
                                      padding: "10px",
                                      fontWeight: "lighter",
                                      textAlign: "right",
                                      fontSize: "16px",
                                      whiteSpace: "nowrap"
                                    }}
                                  >
                                    $ {formatNumber(item.unit_total) || 0}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>

                          {/* Total Summary Row */}
                          <tfoot>
                            <tr
                              style={{
                                textAlign: "center",
                                padding: "10px",
                                margin: "20px auto",
                                borderRadius: "5px",
                                borderTop: "1px solid grey",
                                borderBottom: "1px solid grey",
                                backgroundColor: "#F5F5F5"
                              }}
                            >
                              <td
                                style={{
                                  padding: "10px",
                                  fontWeight: "lighter",
                                  textAlign: "left",
                                  fontSize: "16px"
                                }}
                              >
                                Total:
                              </td>
                              <td
                                style={{
                                  padding: "10px",
                                  fontWeight: "lighter",
                                  textAlign: "right",
                                  fontSize: "16px",
                                  whiteSpace: "nowrap"
                                }}
                              >
                                {`${formatNumberwithoutzeros(
                                  salesResponse?.data.grossQuantitySum
                                )} gl`}
                              </td>
                              <td
                                style={{
                                  padding: "10px",
                                  fontWeight: "lighter",
                                  textAlign: "right",
                                  fontSize: "16px"
                                }}
                              >
                                $ {formatNumber(salesResponse?.data.unitTotalSum) || 0}
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    ) : (
                      RunClicked &&
                      !loading && (
                        <p
                          style={{
                            color: "red",
                            fontSize: "18px",
                            fontWeight: "bold",
                            textAlign: "center",
                            paddingTop: "20px"
                          }}
                        >
                          {poNumber ? 
                            `Sorry, "${poNumber}" PO is not in the system` : 
                            "No Data Available"
                          }
                        </p>
                      )
                    )}
                  </>
                </div>
              </div>
            )}
            <hr />
          </div>
        ) : (
          <div style={{ height: "600px" }}></div>
        )}
      </>
    </div>
  );
};

export default POByStateReport;
import React, { useState, useRef, useEffect } from "react";
import { Box, Paper } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import "../Sales/sales.css"; // Add custom styles if needed
import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import { useSelector, useDispatch } from "react-redux";
import { Grid, IconButton } from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PrintIcon from "@mui/icons-material/Print";
import DescriptionIcon from "@mui/icons-material/Description";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import axios from "axios";
import { Tooltip } from "react-tippy";

import { addCustomer } from "../../../store/customerSlice";
import config from "../../../config/config";
import Loader from "./../Loader";
import FullScreenLoader from "../FullScreenLoader";
import { FaHome } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
// import SalesReportTable from './SalesReportTable';

const Sales = () => {
  const businessId = localStorage.getItem("business_id");
  const managerId = localStorage.getItem("user_id");
  const baseURL =
    process.env.NODE_ENV === "production"
      ? config.production.baseURL
      : config.development.baseURL;
  const [periodType, setPeriodType] = useState(""); // Tracks the selected period (Year, Month, Quarterly)
  const [tempperiodType, settempPeriodType] = useState(""); // Tracks the selected period (Year, Month, Quarterly)
  const navigate = useNavigate();
  const [selectedYear, setSelectedYear] = useState(""); // Tracks the selected year
  const [tempYear, setTempYear] = useState(""); // Tracks the selected year

  const [selectedMonth, setSelectedMonth] = useState(""); // Tracks the selected month
  const [tempMonth, setTempMonth] = useState(""); // Tracks the selected year
  const [tempFuelGrade, settempFuelGrade] = useState(""); // Tracks the selected year

  const [selectedQuarter, setSelectedQuarter] = useState("");
  const [tempQuarter, setTempQuarter] = useState(""); // Tracks the selected year

  const [customer, setCustomer] = useState("");
  const [tempcustomer, setTempCustomer] = useState("");

  const [fuelGrade, setFuelGrade] = useState("");

  const customersAll = useSelector((state) => state.customer.allCustomers);
  const dispatch = useDispatch();
  const [dateTime, setDateTime] = useState("");
  const [salesResponse, setSalesReport] = useState([]);
  const [imageSource, setImageSource] = useState();
  const [imageResponse, setimageResponse] = useState([]);
  const [loading, setLoading] = useState(true); // Add a loading state
  const [pdfhovered, setPdfHovered] = useState(false);
  const [taxhovered, setTaxHovered] = useState(false);
  const [productData, setProductData] = useState([]);

  const [RunClicked, setRunClicked] = useState(false); // Tracks the selected quarter
  const user_id = localStorage.getItem("user_id");
  const user_role = localStorage.getItem("user_role");
  const business_id = localStorage.getItem("business_id");
  const contentRef = useRef();
  const [userRole, setUserRole] = useState("");
  const from = salesResponse.data?.from;
  const to = salesResponse.data?.to;
  // const isButtonEnabled = () => {
  //   if (periodType === 'Yearly' && selectedYear && customer && fuelGrade) return true;
  //   if (periodType === 'Monthly' && selectedYear && selectedMonth && customer && fuelGrade) return true;
  //   if (periodType === 'Quarterly' && selectedYear && selectedQuarter && customer && fuelGrade) return true;
  //   return false;
  // };
  const isButtonEnabled = () => {
    if (
      periodType === "Yearly" &&
      selectedYear &&
      selectedYear !== "" &&
      customer &&
      fuelGrade
    )
      return true;
    if (
      periodType === "Monthly" &&
      selectedYear &&
      selectedYear !== "" &&
      selectedMonth &&
      customer &&
      fuelGrade
    )
      return true;
    if (
      periodType === "Quarterly" &&
      selectedYear &&
      selectedYear !== "" &&
      selectedQuarter &&
      customer &&
      fuelGrade
    )
      return true;
    return false;
  };
  const formatToTwoDecimalsWithCommas = (num) => {
    const parts = num.toString().split(".");
    const integerPartWithCommas = parseInt(parts[0], 10).toLocaleString(
      "en-US"
    ); // Adds commas to the integer part
    const decimalPart = parts[1] ? parts[1].substring(0, 2) : ""; // Limits to two decimal places without rounding

    return decimalPart
      ? `${integerPartWithCommas}.${decimalPart}`
      : integerPartWithCommas;
  };
  const generateUSDateTime = () => {
    setRunClicked(true);
    const currentDate = new Date();

    // Format date and time in US format
    const formattedDateTime = currentDate.toLocaleString("en-US", {
      year: "numeric",
      month: "numeric",
      day: "numeric"
      // hour: 'numeric',
      // minute: 'numeric',
      // second: 'numeric', // Optional: remove if seconds aren't needed
      // hour12: true, // 12-hour format with AM/PM
      // timeZone: 'America/New_York' // You can change the timezone if necessary
    });

    setDateTime(formattedDateTime);
  };
  function formatNumberwithoutzeros(num) {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(num);
  }
  const handlePrint = () => {
    const content = contentRef.current.innerHTML; // Get the HTML content
    const newWindow = window.open("", "_blank"); // Open a new window
    newWindow.document.write(`
      <html>
        <head>
          <title>Print</title>
          <style>
            /* Optional: Add styles for printed content */
                      @media print {
  img {
    display: block;
    width: 100px; /* Adjust width as needed */
    height: auto;
  }
              @page {
                margin: 0; /* Remove default margins */
              }
              body {
                margin: 0;
                padding: 0;
              }
              /* Hide headers and footers */
              header, footer {
                display: none; /* Hides header and footer */
              }
              /* You can also add styles to control the printed content */

            body {
              font-family: Arial, sans-serif;
              margin: 60px;
            }
          </style>
        </head>
        <body>
          ${content} 
        </body>
      </html>
    `);
    newWindow.document.close(); // Close the document to apply styles
    newWindow.print(); // Trigger the print dialog
  };
  function formatNumber(num) {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(num);
  }

  const Sub_total_amount = () => {
    return salesResponse.data && salesResponse.data.totalAmount;
  };
  const Due_amount = () => {
    return salesResponse.data.sales_data.length > 0
      ? salesResponse.data.sales_data.reduce((accumulator, currentValue) => {
          return accumulator + +currentValue.due_amount; // Sum the gallons property
        }, 0)
      : "";
  };
  const Quantity = () => {
    return salesResponse.data && salesResponse.data.totalGallons;
  };
  useEffect(() => {
    setUserRole(localStorage.getItem("user_role"));
  }, []);

  useEffect(() => {
    axios
      .get(
        `${baseURL}/${btoa("business/profile")}/${btoa(business_id)}/${btoa(
          user_id
        )}/${btoa(user_role)}`
      )
      .then((response) => {
        console.log(response);
        // console.log("response businesstype", response);
        setImageSource(response.data.business_data[0].image_file);
        setimageResponse(response.data.business_data[0]);
      })
      .catch((error) => {
        console.error("Error fetching business data:", error);
      });
    console.log(imageResponse);
  }, []);

  // Years and Months for dropdowns
  // const years = ["2025","2024", "2023", "2022", "2021"];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];
  const quarters = [
    { bName: "Q1", fName: "Q1 (Mar-May)" },
    { bName: "Q2", fName: "Q2 (June-Aug)" },
    { bName: "Q3", fName: "Q3 (Sep-Nov)" },
    { bName: "Q4", fName: "Q4 (Dec-Feb)" }
  ];

  // console.log('customersAll', customersAll);
  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get(`${baseURL}Y3VzdG9tZXIvdmlldw==/${btoa(businessId)}`)
      .then((response) => {
        const sortedCustomerData = response.data.customers_data.sort(
          (a, b) => b.customer_id - a.customer_id
        );
        // console.log("sor" , sortedCustomerData);
        dispatch(addCustomer(sortedCustomerData));
      })
      .catch((error) => {
        console.error("Error fetching customer data:", error);
      });
  }, []);

  // Ref for DatePicker to programmatically open it
  const datePickerRef = useRef(null);

  const handleCustomerChange = (event) => {
    setCustomer(event.target.value);
    // setSelectedDate(null); // Reset selected date
  };
  const handleFuelChange = (event) => {
    setFuelGrade(event.target.value);
    // setSelectedDate(null); // Reset selected date
  };

  const handlePeriodChange = (event) => {
    // setRunClicked(false)
    setPeriodType(event.target.value);
    // setSelectedDate(null); // Reset selected date
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get(`${baseURL}/${btoa("product/view")}`)
      .then((response) => {
        // console.log("prod" , response.data.products_data);

        setProductData(response.data.products_data);
      })
      .catch((error) => {
        console.error("Error fetching customer data:", error);
      });
  }, []);
  const handleRun = async () => {
    setRunClicked(true); // Ensure RunClicked is true

    setLoading(true); // Show loader
    setTempYear(selectedYear);
    setTempMonth(selectedMonth);
    setTempQuarter(selectedQuarter);
    settempPeriodType(periodType);
    settempFuelGrade(fuelGrade);
    setTempCustomer(customer);

    try {
      const response = await axios.post(
        `${baseURL}/${btoa("reports/salesreport")}`,
        {
          reportType: periodType,
          monthName: selectedMonth,
          quarterName: selectedQuarter,
          fuelGradeId: fuelGrade,
          year: selectedYear,
          businessId: localStorage.getItem("business_id"),
          managerId: localStorage.getItem("user_id"),
          customerId: customer,
          userRole: userRole
        }
      );

      console.log("response.....", response);
      setSalesReport(response); // Update state with the response
    } catch (error) {
      console.log("Error posting details:", error);
    } finally {
      setLoading(false); // Hide loader
    }
  };

  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        // Trigger background when scrolling starts
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const [years, setYears] = useState([]);
  // useEffect(()=> {
  //   setSelectedYear(new Date().getFullYear().toString());
  //   }, []);

  // useEffect(() => {
  //   console.log("Fetching business profile data...");

  //   axios
  //     .get(`${baseURL}/${btoa("business/profile")}/${btoa(business_id)}/${btoa(user_id)}/${btoa(user_role)}`)
  //     .then((response) => {
  //       console.log("API response received");

  //       try {
  //         // Extract the added_on date string
  //         const addedOnDate = response.data.business_data[0].added_on;
  //         console.log("Raw added_on date:", addedOnDate);

  //         // Extract just the year from the date string (e.g., "2025-02-17" → 2025)
  //         const startYear = parseInt(addedOnDate.substring(0, 4));
  //         console.log("Extracted start year:", startYear);

  //         // Validate the year is a reasonable value
  //         if (isNaN(startYear) || startYear < 2000 || startYear > 2100) {
  //           console.error("Invalid year value:", startYear);
  //           return;
  //         }

  //         // Generate years array from startYear to current year
  //         const currentYear = new Date().getFullYear();
  //         const yearArray = [];

  //         // Add years in descending order (newest first)
  //         for (let year = currentYear; year >= startYear; year--) {
  //           yearArray.push(year);
  //         }

  //         console.log("Generated years array:", yearArray);

  //         // Update state with the generated years
  //         setYears(yearArray);

  //         // Set default selected year to current year
  //         if (yearArray.length > 0) {
  //           setSelectedYear(currentYear.toString());
  //         }
  //       } catch (error) {
  //         console.error("Error processing business data:", error);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching business data:", error);
  //     });
  // }, []);

  useEffect(() => {
    console.log("Fetching business profile data...");

    axios
      .get(
        `${baseURL}/${btoa("business/profile")}/${btoa(business_id)}/${btoa(
          user_id
        )}/${btoa(user_role)}`
      )
      .then((response) => {
        console.log("API response received");

        try {
          // Extract the added_on date string
          const addedOnDate = response.data.business_data[0].added_on;
          console.log("Raw added_on date:", addedOnDate);

          // Extract just the year from the date string (e.g., "2025-02-17" → 2025)
          const startYear = parseInt(addedOnDate.substring(0, 4));
          console.log("Extracted start year:", startYear);

          // Validate the year is a reasonable value
          if (isNaN(startYear) || startYear < 2000 || startYear > 2100) {
            console.error("Invalid year value:", startYear);
            return;
          }

          // Generate years array from startYear to current year
          const currentYear = new Date().getFullYear();
          const yearArray = [];

          // Add years in descending order (newest first)
          for (let year = currentYear; year >= startYear; year--) {
            yearArray.push(year);
          }

          console.log("Generated years array:", yearArray);

          // Update state with the generated years
          setYears(yearArray);

          // Remove this line to prevent default year selection
          // setSelectedYear(currentYear.toString());
        } catch (error) {
          console.error("Error processing business data:", error);
        }
      })
      .catch((error) => {
        console.error("Error fetching business data:", error);
      });
  }, []);
  return (
    <div className="ml-5 ">
      {/* {loading && <FullScreenLoader/>} */}
      <>
        <div
          className="flex justify-between items-center w-full  pt-4 mt-4 mb-5"
          style={{
            maxWidth: "1200px",
            boxSizing: "border-box",
            marginLeft: "4px",
            color: "#444444" // Lighter black color
          }}
        >
          {/* <h1 className="font-bold text-xl ">Gas Sales Report</h1> */}
          <nav aria-label="breadcrumb">
            <ol className="flex space-x-2 text-lg font-bold text-gray-700">
              <li>
                {/* <a  href="/operations" className="text-blue-600 hover:text-blue-800"> */}
                Reports
                {/* </a> */}
              </li>
              <li>
                <span className="text-gray-400">{">>"}</span>
              </li>
              <li>
                {/* <a href="/purchases" className="text-blue-600 hover:text-blue-800"> */}
                Sales
                {/* </a> */}
              </li>
            </ol>
          </nav>
        </div>

        <div
          className="relative py-[12px]  rounded-xl bg-white"
          style={{ boxShadow: "0 0 25px 0 rgba(0, 0, 0, 0.2)" }}
        >
          <div className="flex items-center justify-end">
            {/* Home button - bordered/unfilled by default, filled blue on hover */}
            <div
              className="absolute -top-4 flex items-center justify-center cursor-pointer rounded-full bg-[#cefafe] hover:bg-[#25caed] w-9 h-9 mb-0 mr-8 border-[0.5px] border-[#25caed] hover:border-[#25caed] hover:scale-110 shadow-md transition-all duration-200 group"
              onClick={() => {
                navigate("/");
              }}
              style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
            >
              <FaHome
                className="text-[#25caed] group-hover:text-white transition-colors duration-200"
                size={16}
              />
            </div>

            {/* Close Icon - bordered/unfilled by default, filled red on hover */}
            <div
              className="absolute -top-4 -right-2 bg-red-200 hover:bg-red-500 w-9 h-9 flex items-center justify-center rounded-full cursor-pointer hover:bg-red-700 border-[0.5px] border-red-500 hover:border-red-700 hover:scale-110 shadow-md transition-all duration-200 group"
              // onClick={() => navigate("/HomeReports")}
              onClick={() => navigate("/HomeReports", { state: { activeTab: "sales" } })}

            >
              <span className="text-red-500 group-hover:text-white text-xl font-bold mb-1.5 transition-colors duration-200">
                &times;
              </span>
            </div>
          </div>
          {/* <Box sx={{ padding: 1,paddingTop:0,paddingBottom:1,  maxWidth: '100%', backgroundColor: '#ffff', marginTop:'20px' }}> */}
          {/* <Paper elevation={3}
             style={{
              padding: '40px',
              paddingTop: '20px',
              display: 'flex',
              justifyContent: 'center',  // Centers horizontally
              alignItems: 'center',      // Centers vertically
            }}
         sx={{ padding: 5,paddingTop:'0px', borderRadius: 4,alignItems:'center', boxShadow: '0 0 20px 0 rgba(0, 0, 0, .2)',
 }}> */}
          <Grid container spacing={3} className="pl-[20px] pr-6 ">
            {/* Customer Dropdown */}
            {/* <Grid item md={2.1} >
              <div>
            <label
              for="bill_to"
              className="block mb-2 text-[14px] font-medium text-gray-600 inline-flex items-center "
            >
              Select Customer
              <span className="text-red-500 text-xl font-extrabold pl-1">*</span>

            </label>
            <select
              id="Select Customer"
              name="Select Customer"
              required
              onChange={handleCustomerChange}
              value={customer}
              // className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
             className=" border border-gray-300 text-sm font-medium rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full text-gray-500 h-[30px] py-1 px-2"
           >
            <option value="">--Select Customer--</option>            
              <option value="all">All Customers</option>         
                 {
                customersAll.map((customer)=>{
                  return  <option value={customer.customer_id} >{customer.business_name}</option>

                })
               }
               
            </select>
          </div>
            </Grid> */}
            {/* <Grid item md={2.1} >
              <div>
            <label
              for="bill_to"
              className="block mb-2 text-[14px] font-medium text-gray-600 inline-flex items-center "
            >
              Select Fuel Grade
              <span className="text-red-500 text-xl font-extrabold pl-1">*</span>
            </label>           
            <select
              id="Select Customer"
              name="Select Customer"
              required
              onChange={handleFuelChange}
              value={fuelGrade}
              // className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
              className=" border border-gray-300 text-sm font-medium rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full text-gray-500 h-[30px] py-1 px-2" >
              <option value="">--Select Fuel Grade--</option>
              <option value="all">All Fuel Grades</option>
                         {productData?.map((selectedProduct)=>{
                          return (
                            <option
                              key={selectedProduct.product_id}
                              value={selectedProduct.product_id}
                            >
                              {selectedProduct.product_name}
                            </option>)
                         })}
               
            </select>
          </div>
            </Grid> */}

            <Grid item md={2.1}>
              <div>
                <label
                  htmlFor="bill_to"
                  className="block mb-2 text-[14px] font-medium text-gray-600 inline-flex items-center"
                >
                  Select Customer
                  <span className="text-red-500 text-xl font-extrabold pl-1">
                    *
                  </span>
                </label>
                <select
                  id="Select Customer"
                  name="Select Customer"
                  required
                  onChange={handleCustomerChange}
                  value={customer}
                  className="border border-gray-300 text-sm font-medium rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full text-gray-500 h-[30px] py-1 px-2"
                >
                  <option value="">--Select Customer--</option>
                  <option value="all">All Customers</option>
                  {customersAll.map((customer) => {
                    return (
                      <option
                        key={customer.customer_id}
                        value={customer.customer_id}
                      >
                        {customer.business_name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </Grid>

            {/* Fuel Grade Dropdown */}
            <Grid item md={2.1}>
              <div>
                <label
                  htmlFor="bill_to"
                  className="block mb-2 text-[14px] font-medium text-gray-600 inline-flex items-center"
                >
                  Select Fuel Grade
                  <span className="text-red-500 text-xl font-extrabold pl-1">
                    *
                  </span>
                </label>
                <select
                  id="Select Fuel Grade"
                  name="Select Fuel Grade"
                  required
                  onChange={handleFuelChange}
                  value={fuelGrade}
                  className="border border-gray-300 text-sm font-medium rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full text-gray-500 h-[30px] py-1 px-2"
                >
                  <option value="">--Select Fuel Grade--</option>
                  <option value="all">All Fuel Grades</option>
                  {productData?.map((selectedProduct) => {
                    return (
                      <option
                        key={selectedProduct.id}
                        value={selectedProduct.id}
                      >
                        {selectedProduct.product_name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </Grid>

            {/* Invoice Type Dropdown */}
            <Grid item md={2.1}>
              <div>
                <label
                  for="Select Report Type"
                  className="block mb-2 text-[14px] font-medium text-gray-600 inline-flex items-center "
                >
                  Select Report Type
                  <span className="text-red-500 text-xl font-extrabold pl-1">
                    *
                  </span>
                </label>
                <select
                  id="Select Report Type"
                  name="Select Report Type"
                  required
                  onChange={handlePeriodChange}
                  value={periodType}
                  // className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
                  className=" border border-gray-300 text-sm font-medium rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full text-gray-500 h-[30px] py-1 px-2"
                >
                  <option value="">--Select Report Type--</option>
                  <option value="Monthly">Monthly</option>
                  <option value="Quarterly">Quarterly</option>
                  <option value="Yearly">Yearly</option>
                </select>
              </div>
            </Grid>
            {periodType === "Monthly" && (
              <Grid item md={2.1}>
                <div>
                  <label
                    for="Select Month"
                    className="block mb-2 text-[14px] font-medium text-gray-600 inline-flex items-center  "
                  >
                    Select Month
                    <span className="text-red-500 text-xl font-extrabold pl-1">
                      *
                    </span>
                  </label>
                  <select
                    id="Select Month"
                    name="Select Month"
                    required
                    value={selectedMonth}
                    onChange={(e) => {
                      setSelectedMonth(e.target.value);
                    }}
                    label="Select Month"
                    // className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
                    className=" border border-gray-300 text-sm font-medium rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full text-gray-500 h-[30px] py-1 px-2"
                  >
                    <option value="">--Select Month--</option>
                    {months.map((month) => (
                      <option
                        key={month}
                        value={month}
                        sx={{ margin: 0, padding: "1px 16px" }}
                      >
                        {month}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
            )}
            {periodType === "Quarterly" && (
              <Grid item md={2.1}>
                <div>
                  <label
                    for="Select Quarter"
                    className="block mb-2 text-[14px] font-medium text-gray-600 inline-flex items-center  "
                  >
                    Select Quarter
                    <span className="text-red-500 text-xl font-extrabold pl-1">
                      *
                    </span>
                  </label>
                  <select
                    id="Select Quarter"
                    name="Select Quarter"
                    required
                    value={selectedQuarter}
                    onChange={(e) => {
                      setSelectedQuarter(e.target.value);
                    }}
                    label="Select Quarter"
                    // className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
                    className=" border border-gray-300 text-sm font-medium rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full text-gray-500 h-[30px] py-1 px-2"
                  >
                    <option value="">--Select Quarter--</option>
                    {quarters.map((quarter) => (
                      <option
                        key={quarter.fName}
                        value={quarter.bName}
                        sx={{ margin: 0, padding: "1px 16px" }}
                      >
                        {quarter.fName}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
            )}
            {(periodType === "Yearly" ||
              periodType === "Monthly" ||
              periodType === "Quarterly") && (
              <Grid item md={2.1}>
                <div>
                  <label
                    for="Select Year"
                    className="block mb-2 text-[14px] font-medium text-gray-600 inline-flex items-center  "
                  >
                    Select Year
                    <span className="text-red-500 text-xl font-extrabold pl-1">
                      *
                    </span>
                  </label>
                  <select
                    id="Select Year"
                    name="Select Year"
                    required
                    value={selectedYear}
                    onChange={(e) => {
                      setSelectedYear(e.target.value);
                    }}
                    label="Select Year"
                    // className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
                    className=" border border-gray-300 text-sm font-medium rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full text-gray-500 h-[30px] py-1 px-2"
                  >
                    <option value="">--Select Year--</option>
                    {years.map((year) => (
                      <option
                        key={year}
                        value={year}
                        sx={{ margin: 0, padding: "1px 16px" }}
                      >
                        {year}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
            )}
            <Grid item>
              <Button
                variant="contained"
                disabled={!isButtonEnabled()}
                sx={{
                  backgroundColor: "rgb(231 70 148)", // Pink color
                  color: "white",
                  marginTop: "34px",
                  height: "30px",
                  //  paddingTop:'10px',
                  "&:hover": {
                    backgroundColor: "rgb(231 70 148)" // Darker shade of pink on hover
                  }
                }}
                endIcon={<SendIcon />}
                onClick={() => {
                  generateUSDateTime();
                  handleRun();
                }}
              >
                Run
              </Button>
            </Grid>
          </Grid>

          {/* </Paper> */}
          {/* </Box> */}
        </div>
        {RunClicked ? (
          <div id="runclicked">
            {RunClicked && (
              <div
                className="bg-white"
                style={{
                  borderRadius: "20px",
                  maxWidth: "100%",
                  overflowX: "auto",
                  margin: "0 auto",
                  flexDirection: "column",
                  boxShadow: "0 0 25px 0 rgba(0, 0, 0, 0.2)",
                  margin: "55px",
                  paddingBottom: "80px",
                  margin: "15px 0px 15px 0px",
                  marginTop: "45px",
                  padding: "20px",
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column"
                }}
              >
                {salesResponse && salesResponse.data ? (
                  <Grid container alignItems="end" justifyContent="end">
                    {/* PDF Download Icon */}
                    <Grid item xs={12} md={4}>
                      <Box p={2} className="font-bold text-md text-gray-400">
                        {RunClicked && <p>Report RunTime: {dateTime} </p>}
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <Grid container alignItems="end" justifyContent="end">
                        <Grid item>
                          <Tooltip
                            title="Pdf"
                            position="top"
                            trigger="mouseenter"
                          >
                            <IconButton
                              aria-label="Download PDF"
                              sx={{
                                backgroundColor: "transparent", // No background color initially
                                borderRadius: "50%", // Circular shape
                                padding: "12px", // Adjust padding for the circular button
                                "&:hover": {
                                  backgroundColor: "rgba(227, 140, 151, 0.3)" // Light background color on hover
                                },
                                transition: "background-color 0.3s ease" // Smooth transition effect
                              }}
                              onMouseEnter={() => setPdfHovered(true)}
                              onMouseLeave={() => setPdfHovered(false)}
                            >
                              {pdfhovered ? (
                                <FileDownloadIcon
                                  sx={{ fontSize: 20, color: "red" }}
                                />
                              ) : (
                                <PictureAsPdfIcon
                                  fontSize="small"
                                  color="error"
                                />
                              )}
                            </IconButton>
                          </Tooltip>
                        </Grid>
                        <Grid item>
                          <Tooltip
                            title="Print"
                            position="top"
                            trigger="mouseenter"
                          >
                            <IconButton
                              aria-label="Print"
                              onClick={handlePrint}
                              sx={{
                                backgroundColor: "transparent", // No background color initially
                                borderRadius: "50%", // Circular shape
                                padding: "12px", // Adjust padding for the circular button
                                "&:hover": {
                                  backgroundColor: "rgba(0, 0, 0, 0.1)" // Light background color on hover
                                },
                                transition: "background-color 0.3s ease" // Smooth transition effect
                              }}
                            >
                              <PrintIcon
                                fontSize="small"
                                sx={{ color: "black" }}
                              />
                            </IconButton>
                          </Tooltip>
                        </Grid>

                        {/* Tax File Icon */}
                        <Grid item>
                          <Tooltip
                            title="Excel"
                            position="top"
                            trigger="mouseenter"
                          >
                            <IconButton
                              aria-label="Tax File"
                              sx={{
                                backgroundColor: "transparent", // No background color initially
                                borderRadius: "50%", // Circular shape
                                padding: "12px", // Adjust padding for the circular button
                                "&:hover": {
                                  backgroundColor: "rgba(143, 191, 96, 0.3)" // Light background color on hover
                                },
                                transition: "background-color 0.3s ease" // Smooth transition effect
                              }}
                              onMouseEnter={() => setTaxHovered(true)}
                              onMouseLeave={() => setTaxHovered(false)}
                            >
                              {taxhovered ? (
                                <FileDownloadIcon
                                  sx={{ fontSize: 20, color: "red" }}
                                />
                              ) : (
                                <DescriptionIcon
                                  fontSize="small"
                                  style={{ color: "#4CAF50" }}
                                />
                              )}
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  ""
                )}
                {/* <div className="flex justify-center"> */}

                <div
                  ref={contentRef}
                  style={{
                    width: "100%",
                    height: "auto",
                    justifyContent: "center",
                    alignSelf: "center",
                    borderRadius: "10px",
                    boxShadow: "0 0 20px 0 rgba(0, 0, 0, .1)",
                    padding: "20px",
                    marginTop: "20px"
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: "110px",
                      justifyContent: "space-between",
                      marginBottom: "20px",
                      backgroundColor: "rgba(180,180,180,0.1)"
                    }}
                  >
                    {/* Left Image */}
                    {/* <img
        src="https://via.placeholder.com/60"
        alt="Left Logo"
        style={{ width: '60px', // Set the size of the images
            height: '60px'}}
      /> */}
                    {/* <img src="/static/media/logo.0ea4b695a3030ea659a8.png" alt="flownia" className="w-max" ></img> */}
                    {userRole !== "1" && (
                      <div className="">
                        {imageSource && (
                          <img
                            style={{ width: "140px", height: "60px" }}
                            src={`${baseURL}uploads/img/business/${imageSource}`}
                            alt="Selected"
                          />
                        )}
                      </div>
                    )}

                    {/* Middle: Three Texts Stacked */}
                    <div style={{ textAlign: "center", marginLeft: "90px" }}>
                      <p>
                        <strong
                          style={{
                            fontWeight: "600",
                            fontSize: "18px",
                            margin: "5px"
                          }}
                        >
                          {imageResponse.business_name}
                        </strong>
                      </p>
                      <p>
                        <strong
                          style={{
                            fontWeight: "600",
                            fontSize: "18px",
                            margin: "5px"
                          }}
                        >
                          {imageResponse.business_address}
                        </strong>
                      </p>
                      <p>
                        <strong
                          style={{
                            fontWeight: "600",
                            fontSize: "18px",
                            margin: "5px"
                          }}
                        >
                          {imageResponse.business_city},{" "}
                          {imageResponse.business_state},{" "}
                          {imageResponse.business_zip_code}
                        </strong>
                      </p>
                    </div>

                    {/* Right Image */}
                    <img
                      src="/static/media/logo.0ea4b695a3030ea659a8.png"
                      alt="invoicefilelogo"
                      className="w-max"
                      style={{
                        width: "240px", // Set the size of the images
                        height: "200px"
                      }}
                    ></img>
                  </div>
             

                  {/* <SalesReportTable salesResponse={salesResponse}/> */}
                  {RunClicked && loading ? (
                    <FullScreenLoader /> // Show loader while fetching data
                  ) : RunClicked &&
                    salesResponse &&
                    salesResponse.data &&
                    salesResponse.data.sales_data?.length > 0 ? (
                    <div style={{ marginTop: "14px" }}>
                           {RunClicked && (
                    <div
                      style={{
                        color: "#1976d2",
                        textAlign: "center",
                        fontSize: "21px",
                        fontWeight: "bold",
                        marginTop: "50px"
                      }}
                      className="    className='border-b border-gray-300 mb-1 mt-2'
"
                    >
                      {tempperiodType === "Yearly" &&
                        tempYear &&
                        RunClicked &&
                        `${tempYear} - Sales - ${
                          salesResponse?.data?.customerName === "all"
                            ? "All Customers"
                            : salesResponse?.data?.customerName
                        } - ${
                          salesResponse?.data?.fuelGrade === "all"
                            ? "All Fuel Grades"
                            : salesResponse?.data?.fuelGrade
                        }  `}
                      {tempperiodType === "Monthly" &&
                        tempYear &&
                        tempMonth &&
                        RunClicked &&
                        `${tempMonth} - ${tempYear} - Sales - ${
                          salesResponse?.data?.customerName === "all"
                            ? "All Customers"
                            : salesResponse?.data?.customerName
                        } - ${
                          salesResponse?.data?.fuelGrade === "all"
                            ? "All Fuel Grades"
                            : salesResponse?.data?.fuelGrade
                        }  `}
                      {tempperiodType === "Quarterly" &&
                        tempYear &&
                        tempQuarter &&
                        RunClicked &&
                        ` ${tempQuarter == "Q1" ? "Q1 (Mar-May)" : ""} ${
                          tempQuarter == "Q2" ? "Q2 (June-Aug)" : ""
                        } ${tempQuarter == "Q3" ? "Q3 (Sep-Nov)" : ""} ${
                          tempQuarter == "Q4" ? "Q4 (Dec-Feb)" : ""
                        } - ${tempYear} - Sales - ${
                          salesResponse?.data?.customerName === "all"
                            ? "All Customers"
                            : salesResponse?.data?.customerName
                        } - ${
                          salesResponse?.data?.fuelGrade === "all"
                            ? "All Fuel Grades"
                            : salesResponse?.data?.fuelGrade
                        } `}
                      <hr className="border-t border-gray-300 mb-1 mt-2" />
                    </div>
                  )}
                      <table
                        style={{
                          width: "45%",
                          maxWidth: "600px",
                          margin: "0 auto",
                          fontWeight: "bold",
                          borderCollapse: "collapse"
                        }}
                      >
                        {/* Header Row */}
                        <thead style={{ backgroundColor: "transparent" }}>
                          <tr
                            style={{
                              textAlign: "center",
                              marginBottom: "10px",
                              color: "#3479E0",
                                backgroundColor: "#F5F5F5"
                            }}
                          >
                            <th
                              style={{
                                padding: "10px",
                                textAlign: "left",
                                width: "120px",
                                fontSize: "19px"
                              }}
                            >
                              Fuel Grade
                            </th>
                            <th
                              style={{
                                padding: "10px",
                                textAlign: "right",
                                width: "210px",
                                fontSize: "19px"
                              }}
                            >
                              Gallons
                            </th>
                            <th
                              style={{
                                padding: "10px",
                                textAlign: "right",
                                fontSize: "19px"
                              }}
                            >
                              Amount
                            </th>
                          </tr>
                        </thead>

                        {/* Data Rows */}
                        <tbody>
                          {salesResponse.data.sales_data.map((item) => (
                            <tr
                              key={item.product_id || item.product_name}
                              style={{
                                textAlign: "center",
                                marginBottom: "10px"
                              }}
                            >
                              <td
                                style={{
                                  padding: "10px",
                                  fontWeight: "normal",
                                  textAlign: "left",
                                  fontSize: "15px"
                                }}
                              >
                                {item.product_name || ""}
                              </td>
                              <td
                                style={{
                                  padding: "10px",
                                  fontWeight: "normal",
                                  textAlign: "right",
                                  fontSize: "15px"
                                }}
                              >
                                {`${formatNumberwithoutzeros(
                                  item.total_gallons
                                )} gl`}
                              </td>
                              <td
                                style={{
                                  padding: "10px",
                                  fontWeight: "normal",
                                  textAlign: "right",
                                  fontSize: "15px"
                                }}
                              >
                                $ {formatNumber(item.total_amount) || 0}
                              </td>
                            </tr>
                          ))}
                        </tbody>

                        {/* Total Summary Row */}
                        <tfoot>
                          <tr
                            style={{
                              textAlign: "center",
                              padding: "10px",
                              margin: "20px auto",
                              borderRadius: "5px",
                              borderTop: "1px solid grey",
                              borderBottom: "1px solid grey",
                              backgroundColor: "#F5F5F5"
                            }}
                          >
                            <td
                              style={{
                                padding: "10px",
                                fontWeight: "normal",
                                textAlign: "left",
                                fontSize: "16px"
                              }}
                            >
                              Total:
                            </td>
                            <td
                              style={{
                                padding: "10px",
                                fontWeight: "normal",
                                textAlign: "right",
                                fontSize: "16px"
                              }}
                            >
                              {`${formatNumberwithoutzeros(
                                salesResponse.data.totalGallons || 0
                              )} gl`}
                            </td>
                            <td
                              style={{
                                padding: "10px",
                                fontWeight: "normal",
                                textAlign: "right",
                                fontSize: "16px"
                              }}
                            >
                              ${" "}
                              {formatNumber(
                                salesResponse.data.totalAmount || 0
                              )}
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  ) : (
                    RunClicked &&
                    !loading && (
                      <p
                        style={{
                          color: "red",
                          fontSize: "18px",
                          fontWeight: "bold",
                          textAlign: "center",
                          paddingTop: "20px"
                        }}
                      >
                        No Data Available
                      </p>
                    )
                  )}
                </div>
              </div>
            )}
            <hr />
          </div>
        ) : (
          <div style={{ height: "300px" }}></div>
        )}
      </>
    </div>
  );
};

export default Sales;

import React, { useState } from "react";
import PurchaseImagesViewModel from '../../models/PurchaseImagesViewModel';
import {
  flexRender,
} from "@tanstack/react-table";
import FullScreenLoader from "../FullScreenLoader";

const InvoiceReportTable = ({ tableInstance, invoiceResponse, RunClicked, loading}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Ensure the table instance is defined
  if (!tableInstance) {
    return <div>Loading...</div>; // or an appropriate loading indicator
  }

  // Check if tableInstance has necessary properties
  const { getHeaderGroups, getRowModel, options } = tableInstance;
  const rows = getRowModel()?.rows || []; // Safely access rows

  return (
    <>
     {RunClicked && loading ? (
      <FullScreenLoader /> // Show loader while fetching data
    ) :
      <div>
  {RunClicked && rows.length > 0 ? (
    <table className="table-auto bg-transparent rounded-xl overflow-hidden" 
    style={{
      borderCollapse: "collapse",
      textAlign:'center',
      width: "100%",
      margin: "auto",
     }}>
      <thead className="bg-gray-500/20 text-gray-500">
        {getHeaderGroups().map((headerEl) => (
          <tr key={headerEl.id} className="text-center text-xs">
            {headerEl.headers.map((columnEl, index) => {
              const isFirstHeader = index === 0;
              const isLastHeader = index === headerEl.headers.length - 1;
              const headerClasses = [
                'p-3 font-bold uppercase',
                isFirstHeader ? 'rounded-l-xl' : '',
                isLastHeader ? 'rounded-r-xl' : '',
              ];
              return (
                <th
                  key={columnEl.id}
                  colSpan={columnEl.colSpan}
                  className={headerClasses.join(' ')}
                 onClick={columnEl.column.getToggleSortingHandler()}

                >
                  {columnEl.isPlaceholder ? null : flexRender(columnEl.column.columnDef.header, columnEl.getContext())}
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody>
        {rows.map((rowEl) => (
          <tr key={rowEl.id} className="cursor-pointer text-center hover:bg-gray-500/10">
            {rowEl.getVisibleCells().map((cellEl) => (
              <td key={cellEl.id} className="p-4 font-semibold text-[#081159] text-[14px]">
                {flexRender(cellEl.column.columnDef.cell, cellEl.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  ) : (
    RunClicked && !loading &&
    <p style={{ color: 'red', fontSize: '18px', fontWeight: 'bold', textAlign: 'center', paddingTop:'20px' }}>
    No Data Available
  </p>
  )}
</div>

}
    </>
  );
};

export default InvoiceReportTable;

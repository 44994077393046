
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeToast } from './ToastBoxMessageSlice';
import '../ToastBox/ToastNotificationBox.css';

const ToastNotificationBox = () => {
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.toastBox.notifications);
  const [exitingToasts, setExitingToasts] = useState([]);

  useEffect(() => {
    // Automatically remove toasts after 3 seconds
    notifications.forEach((toast) => {
      // Set timeout for removing toast after 3 seconds
      const timer = setTimeout(() => {
        setExitingToasts((prev) => [...prev, toast.id]); // Add toast to exiting list
        setTimeout(() => {
          dispatch(removeToast({ id: toast.id })); // Dispatch removal after fade-out
        }, 500); // Allow 500ms for the fade-out animation
      }, 3000);

      return () => clearTimeout(timer); // Clear timeout if unmounted
    });
  }, [notifications, dispatch]);

  return (
    <>
              <div className="toast-container">

      {(notifications.length) > 0 &&
        notifications.map((toast) => (

          <div
          key={toast.id}
          className={`toasts ${toast.type} ${exitingToasts.includes(toast.id) ? 'exit' : 'enter'} flex items-center `}
        >
          {/* Circle with tick mark */}
          <div
            className={`w-5 h-5 flex items-center justify-center rounded-full text-white
              ${toast.type === 'info' && 'bg-green-500'}
              ${toast.type === 'danger' && 'bg-red-500'}
              ${toast.type === 'success' && 'bg-green-500'}
               ${toast.type === 'approve' && 'bg-[#2b88de]'}
            `}
          >
            <span className="text-sm">&#10003;</span>
          </div>
        
          {/* Message */}
          <span className='ml-3 mr-3 capitalize'>{toast.message}</span>
        </div>
        
          
          
        ))}

        
                  </div>

        </>
  );
};

export default ToastNotificationBox;


import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import { ChevronLeft, ChevronRight } from 'lucide-react';
import {
  FaChevronLeft,
  FaChevronRight,
  FaDownload,
  FaEdit,
  FaPen,
} from "react-icons/fa";
import { DatePicker, Space } from "antd";
import TextField from "@mui/material/TextField"; // Make sure this import is at the top
import axios from "axios";
import config from "../../config/config";
import dayjs from "dayjs";
import { addCustomer } from "../../store/customerSlice";
import { useSelector, useDispatch } from "react-redux";
import { addToast } from "./ToastBox/ToastBoxMessageSlice";
import PriceUpdateForm from "./PriceUpdateForm";
import { useLocation , useNavigation} from "react-router-dom";

const PricingView = ({ invoiceDate,customerName,customerId , customer_id}) => {
  // console.log(businessDate,customer_id, 'businessDate')
  const [newPrice, setNewPrice] = useState("");
  const dispatch = useDispatch();
const location = useLocation();
const businessDate = location.state?.businessDate || "";
  useEffect(() => {
    setCustomerIdProvided(!!customer_id);
    
    // If customer_id is null, we should enable "All Customers" mode
    if (!customer_id) {
      setIsAllCustomers(true);
      setSelectedCustomer("");
    } else {
      setIsAllCustomers(false);
      setSelectedCustomer(customer_id);
    }
  }, [customer_id]);
  
  const [customerIdProvided, setCustomerIdProvided] = useState(!!customer_id);

  // Update this state whenever customer_id changes
  useEffect(() => {
    setCustomerIdProvided(!!customer_id);
    console.log("Customer ID provided:", !!customer_id);
  }, [customer_id]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [ispriceExists, setIspriceExists] = useState(false);
  // const [editRow, setEditRow] = useState(null); // Track which row is being edited
  const [selectedCustomer, setSelectedCustomer] = useState(customer_id || "");
  const [isAllCustomers, setIsAllCustomers] = useState(!customer_id);

  const customersAll = useSelector((state) => state.customer.allCustomers);
  const businessId = localStorage.getItem("business_id");

  // Fetch customers and save to Redux store
  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get(`${baseURL}Y3VzdG9tZXIvdmlldw==/${btoa(businessId)}`) //
      .then((response) => {
        const sortedCustomerData = response.data.customers_data.sort(
          (a, b) => b.customer_id - a.customer_id
        );
        dispatch(addCustomer(sortedCustomerData));
      })
      .catch((error) => {
        console.error("Error fetching customer data:", error);
      });
  }, []);
  const [regularPrice, setRegularPrice] = useState("");
  const today = dayjs(); // Get today's date

  const [selectedDate, setSelectedDate] = useState(today); // Initialize as null

  const [premiumPrice, setPremiumPrice] = useState("");

  const [ultraPrice, setUltraPrice] = useState("");

  const [dieselPrice, setDieselPrice] = useState("");
  const [editRows, setEditRows] = useState([]); // Track multiple editable rows

  const handleEditClick = (productId) => {
    setEditRows((prevRows) => {
      if (prevRows.includes(productId)) {
        return prevRows.filter((id) => id !== productId); // Remove from edit mode
      } else {
        return [...prevRows, productId]; // Add to edit mode
      }
    });
  };
  const navigate = useNavigate();
  const baseURL =
    process.env.NODE_ENV === "production"
      ? config.production.baseURL
      : config.development.baseURL;
  const handleCancel = () => {
    navigate("/pricing");
  };

  const handleNotifyCustomers = () => {
    navigate("/HomeNotifications", { state: { fromPriceBook: true } });
  };

  const handleNoNotification = () => {
    navigate("/");
  };

  const formattedDate = selectedDate
    ? selectedDate.toDate().toLocaleDateString("en-CA")
    : null;

  const [activeItem, setActiveItem] = useState("View");
  const [fuelPrices, setFuelPrices] = useState([]);
  const [updatedPrices, setUpdatedPrices] = useState({});




  const handleMenuClick = (item) => {
    setActiveItem(item);
  };


  const handleDateChange = (date) => {
    if (date) {
      // setRunClicked(false)
      setSelectedDate(date); // Only set the date when the user actually picks a valid date
    }
  };
  // const handlePrevDay = () => {
  //   setSelectedDate((prevDate) => prevDate.subtract(1, "day"));
  // };
  const handlePrevDay = () => {
    setSelectedDate((prevDate) => {
      // Convert businessDate to dayjs object if it's not already
      const businessDateObj = typeof businessDate === 'string' 
        ? dayjs(businessDate) 
        : dayjs(businessDate);
        
      // Check if going back one day would make the date earlier than businessDate
      if (prevDate.subtract(1, 'day').isBefore(businessDateObj, 'day')) {
        return businessDateObj; // Don't go earlier than businessDate
      }
      return prevDate.subtract(1, 'day'); // Otherwise subtract one day as normal
    });
  };
  const disabledDate = (current) => {
    // Allow only the invoiceDate to be selected
    // Convert invoiceDate to dayjs object if it's not already
    const invoiceDateObj = typeof invoiceDate === 'string' 
      ? dayjs(invoiceDate) 
      : dayjs(invoiceDate);
    
    // Disable all dates except the invoiceDate
    return !current.isSame(invoiceDateObj, 'day');
  };
  const handleNextDay = () => {
    setSelectedDate((prevDate) => {
      if (prevDate.isBefore(today, "day")) {
        return prevDate.add(1, "day");
      }
      return prevDate; // Prevent exceeding today's date
    });
  };

  const handlePriceChange = (productName, value) => {
    setUpdatedPrices((prev) => ({
      ...prev,
      [productName]: value,
    }));
  };

  const todayDate = dayjs().format("YYYY-MM-DD");
  console.log(
    selectedDate.format("YYYY-MM-DD"),
    todayDate,
    selectedDate,
    ispriceExists,
    "selectedDateselectedDate",
    dayjs(selectedDate).format("YYYY-MM-DD") === todayDate,
    typeof selectedDate,
    typeof todayDate
  );


useEffect(()=>{
  setSelectedCustomer(customer_id)

}, [])

// Force an immediate fetch on component mount
useEffect(() => {
  const fetchInitialData = async () => {
    try {
      console.log("Initial fetch with customer_id:", customer_id);
      const requestData = {
        businessId: localStorage.getItem("business_id"),
        priceDate: selectedDate.format("YYYY-MM-DD"),
        customerId: customer_id || 'allCustomers'
      };

      console.log("Initial fetch request data:", requestData);
      
      const response = await axios.post(
        `${baseURL}${btoa("businessfuelgradeprices/oldfuelgradeprice")}`,
        requestData,
        { headers: { "Content-Type": "application/json" } }
      );

      console.log("Initial fetch response:", response.data);
      
      setFuelPrices(response.data.oldPricesData);
      setIspriceExists(response.data.priceExistStatus);
      
      // Reset states on new data fetch
      setUpdatedPrices({});
      setIsSubmitted(false);
      setEditRows([]);
    } catch (error) {
      console.error("Error in initial data fetch:", error);
    }
  };

  // Fetch immediately on mount
  fetchInitialData();
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, []); // Empty dependency array for component mount only

// This useEffect will handle subsequent customer or date changes
useEffect(() => {
  // Skip the initial render since we're handling it separately
  const fetchData = async () => {
    try {
      console.log("Fetching with selectedCustomer:", selectedCustomer, "isAllCustomers:", isAllCustomers);
      
      const requestData = {
        businessId: localStorage.getItem("business_id"),
        priceDate: selectedDate.format("YYYY-MM-DD"),
        customerId: isAllCustomers ? 'allCustomers' : (selectedCustomer || 'allCustomers')
      };

      console.log("Regular fetch request data:", requestData);
      
      const response = await axios.post(
        `${baseURL}${btoa("businessfuelgradeprices/oldfuelgradeprice")}`,
        requestData,
        { headers: { "Content-Type": "application/json" } }
      );

      console.log("Regular fetch response:", response.data);
      
      setFuelPrices(response.data.oldPricesData);
      setIspriceExists(response.data.priceExistStatus);
      
      // Reset states on new data fetch
      setUpdatedPrices({});
      setIsSubmitted(false);
      setEditRows([]);
    } catch (error) {
      console.error("Error fetching fuel prices:", error);
    }
  };

  // Only fetch if the component has mounted
  fetchData();
}, [selectedDate, selectedCustomer, isAllCustomers]);

// Update the All Customers button handler
const handleAllCustomersClick = () => {
  setIsAllCustomers(true);
  setSelectedCustomer(""); // Clear dropdown selection
};

// Update the dropdown change handler
const handleDropdownChange = (e) => {
  const value = e.target.value;
  setSelectedCustomer(value);
  setIsAllCustomers(false); // Unselect "All Customers" if dropdown is used
};

  const handleClearSelection = () => {
    setSelectedCustomer("");
    setIsAllCustomers(false);
  };
  return (
//     <div
//       className="max-w-4xl mx-auto p-6 bg-white"
     
//     >
//       <div className="flex justify-evenly items-center bg-white ">
//         <div className="flex items-center justify-center">
//           <button className="flex items-center">
//             <FaChevronLeft className="mr-2" onClick={handlePrevDay} />
//           </button>
//           <div>
//           <Space direction="vertical" style={{ height: "100%" }}>
//   <DatePicker 
//     label="date"
//     disabled={isSubmitted} // This will disable the date picker after submission
//     disabledDate={disabledDate} // Function to disable specific dates
//     format="MM-DD-YYYY"
//     views={["year", "month", "day"]}
//     value={dayjs(invoiceDate)}
//     onChange={handleDateChange}
//     className="w-[170px] h-[30px]"
//     placeholder="Date"
//     renderInput={(params) => (
//       <TextField 
//         {...params}
//         sx={{
//           "& .MuiOutlinedInput-root": {
//             height: "32px",
//             display: "flex",
//             alignItems: "center",
//             fontSize: "1rem",
//             borderRadius: "6px",
//             "&:hover": {
//               // Hover styles
//             },
//             "&.Mui-focused": {
//               borderColor: "blue",
//               boxShadow: "0 0 8px rgba(0, 0, 255, 0.5)",
//             },
//           },
//         }}
//       />
//     )}
//   />
// </Space>
//           </div>
//           <button className="flex items-center">
//             <FaChevronRight className="ml-2" onClick={handleNextDay} />
//           </button>
//         </div>
//         <button
//   disabled={isSubmitted || customerIdProvided} // Disable if customer_id was provided
//   className={`px-4 py-0.5 border rounded-lg ${
//     isAllCustomers
//       ? `bg-blue-500 border-blue-500 w-[170px] ${(isSubmitted || customerIdProvided) ? 'text-gray-600' : 'text-white'}`
//       : "hover:bg-gray-200 border-gray-500 text-black-500 w-[170px]"
//   } ${(isSubmitted || customerIdProvided) ? 'bg-gray-100 border-gray-500 text-black-500 w-[170px]' : 'w-[170px]'}`}
//   onClick={handleAllCustomersClick}
// >
//   All Customers
// </button>

//         <div className="flex flex-col mx-4">
//           {/* <label htmlFor="customer-select" className="font-medium mb-1 text-gray-700">
//           Select Customer
//         </label> */}
//       <select
//   id="customer-select"
//   className={`h-[30px] py-0.5 rounded-lg border-grey-500 w-[170px] ${(isSubmitted || customerIdProvided || isAllCustomers) ? 'bg-gray-100 text-gray-600' : ''}`}
//   value={selectedCustomer}
//   onChange={handleDropdownChange}
//   disabled={isSubmitted || customerIdProvided || isAllCustomers} // Also disable when "All Customers" is selected
// >
//   <option value="">Select Customer</option>
//   {customersAll.map((customer) => (
//     <option key={customer.customer_id} value={customer.customer_id}>
//       {customer.business_name}
//     </option>
//   ))}
// </select>
//         </div>
//         {/* <button className="px-4 py-2 bg-gray-400 text-white" onClick={handleClearSelection}>
//           Clear Selection
//         </button> */}
//       </div>
//       <div className=" rounded p-4">
//         <table className="w-full table-auto text-left bg-white">
//           <thead>
//             <tr className="bg-white mb-3">
//               <th className="py-2">Fuel Grade</th>
//               <th className="py-2">Old Price</th>
//               <th className="py-2">Current Price</th>
//               {/* {dayjs(selectedDate).format("YYYY-MM-DD") === todayDate &&
//                 !ispriceExists && <th className="py-2">New Price</th>} */}
//             </tr>
//           </thead>

//           <tbody>
//             {fuelPrices.map((item) => {
//               const isSubmittedForToday =
//                 dayjs(item.added_on).format("YYYY-MM-DD") === todayDate;
//               console.log(
//                 isSubmittedForToday,
//                 typeof item.added_on,
//                 typeof todayDate,
//                 "isSubmittedForToday"
//               );

//               return (
//                 <tr key={item.product_id}>
//                   <td className="py-2">{item.product_name}</td>
//                   <td className="py-2">
//                     $
//                     {item.old_unit_price
//                       ? parseFloat(item.old_unit_price).toFixed(2)
//                       : 0}
//                   </td>
//                   <td className="py-2 flex items-center justify-center">
//                     $
//                     {item.new_unit_price
//                       ? parseFloat(item.new_unit_price).toFixed(2)
//                       : "0.00"}
//                     {/* {!ispriceExists &&
//                       dayjs(selectedDate).format("YYYY-MM-DD") === todayDate &&
//                       dayjs(item.added_on).format("YYYY-MM-DD") ===
//                         todayDate && (
//                         <FaPen
//                           className="ml-2 text-blue-500 cursor-pointer"
//                           onClick={() => handleEditClick(item.product_id)}
//                         />
//                       )} */}
//                   </td>
//                   {/* {dayjs(selectedDate).format("YYYY-MM-DD") === todayDate &&
//                     !ispriceExists &&
//                     (dayjs(item.added_on).format("YYYY-MM-DD") !== todayDate ||
//                       editRows.includes(item.product_id)) && (
//                       <td className="py-2">
//                         <input
//                           type="number"
//                           value={updatedPrices[item.product_name] || ""}
//                           disabled={isSubmitted} // Add this line to disable the input after submission
                         
//                           onChange={(e) =>
//                             handlePriceChange(item.product_name, e.target.value)
//                           }
//                           className={`border p-2 rounded h-[30px] w-[130px] ${isSubmitted ? 'bg-gray-100' : ''}`}
//                         />
//                       </td>
//                     )} */}
//                 </tr>
//               );
//             })}
//           </tbody>
//         </table>
//       </div>
//       <div className="flex justify-end space-x-4 mt-6">
//         {/* {dayjs(selectedDate).format("YYYY-MM-DD") === todayDate &&
//           !ispriceExists && (
//             <div className="flex justify-end space-x-4 mt-6">
     
//               <button
//                 onClick={
//                   Object.keys(updatedPrices).length > 0 ? handleSubmit : null
//                 }
//                 disabled={
//                   isSubmitted ||
//                   fuelPrices.some(
//                     (item) =>
//                       (item.new_unit_price <= 0 &&
//                         !updatedPrices[item.product_name]) || // Ensure missing prices are entered
//                       (updatedPrices[item.product_name] &&
//                         parseFloat(updatedPrices[item.product_name]) <= 0) // Ensure entered values are > 0
//                   ) ||
//                   fuelPrices.every(
//                     (item) =>
//                       !updatedPrices[item.product_name] ||
//                       parseFloat(updatedPrices[item.product_name]) <= 0 // Ensure entered values are > 0 or not empty
//                   )
//                 }
//                 className={`px-3 py-1 rounded ${
//                   isSubmitted ||
//                   fuelPrices.some(
//                     (item) =>
//                       (item.new_unit_price <= 0 &&
//                         !updatedPrices[item.product_name]) ||
//                       (updatedPrices[item.product_name] &&
//                         parseFloat(updatedPrices[item.product_name]) <= 0)
//                   ) ||
//                   fuelPrices.every(
//                     (item) =>
//                       !updatedPrices[item.product_name] ||
//                       parseFloat(updatedPrices[item.product_name]) <= 0 // Ensure entered values are > 0 or not empty
//                   )
//                     ? "bg-gray-300 text-black cursor-not-allowed"
//                     : "hover:bg-blue-500 hover:text-white text-blue-600 border border-blue-600"
//                 }`}
//               >
//                 {editRows.length > 0 && Object.keys(updatedPrices).length > 0
//                   ? "Save"
//                   : "Submit"}
//               </button>

//               <button
//                 onClick={handleCancel}
//                 className="px-3 py-1 rounded border border-gray-600 text-black hover:bg-gray-600 hover:text-white"
//               >
//                 Cancel
//               </button>

//               <button
//                 onClick={handleNotifyCustomers}
//                 disabled={!isSubmitted}
//                 className={`px-3 py-1 rounded ${
//                   isSubmitted
//                     ? "hover:bg-green-500 hover:text-white text-green-600 border border-green-600"
//                     : "bg-gray-300 text-black"
//                 }`}
//               >
//                 Notify Customers
//               </button>

//               <button
//                 onClick={handleNoNotification}
//                 disabled={!isSubmitted}
//                 className={`px-3 py-1 rounded ${
//                   isSubmitted
//                     ? "hover:bg-yellow-500 hover:text-white text-yellow-600 border border-yellow-500"
//                     : "bg-gray-300 text-black"
//                 }`}
//               >
//                 No Notification
//               </button>
//             </div>
//           )} */}
//       </div>
//     </div>
<div className="mt-[30px]">
<PriceUpdateForm
// sendDataToParent={handleChildData}
businessDate={businessDate}
/>
</div>
  );
};

export default PricingView;

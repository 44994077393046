// import React, { useState, useRef, useEffect } from "react";
// import { Box, Paper } from "@mui/material";
// import "react-datepicker/dist/react-datepicker.css";
// import SendIcon from "@mui/icons-material/Send";
// import Button from "@mui/material/Button";
// import { useSelector, useDispatch } from "react-redux";
// import { Grid, IconButton, Typography } from "@mui/material";
// import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
// import PrintIcon from "@mui/icons-material/Print";
// import DescriptionIcon from "@mui/icons-material/Description";
// import FileDownloadIcon from "@mui/icons-material/FileDownload";
// import SearchIcon from "@mui/icons-material/Search";
// // import { AiFillEye } from "react-icons/ai"; // Added AiFillEye import
// import axios from "axios";
// import { Tooltip } from "react-tippy";
// import config from "../../config/config";
// import FullScreenLoader from "../pages/FullScreenLoader";
// import { Col, Row } from "react-bootstrap";
// import { addToast } from "../pages/ToastBox/ToastBoxMessageSlice";
// import { useNavigate } from "react-router-dom";
// import PurchaseImagesViewModel from "../models/PurchaseImagesViewModel";
// // import PurchaseImagesViewModel from "./PurchaseImagesViewModel"; // Import the modal component

// const SearchPoReport = () => {
//   const businessId = localStorage.getItem("business_id");
//   const managerId = localStorage.getItem("user_id");
//   const baseURL =
//     process.env.NODE_ENV === "production"
//       ? config.production.baseURL
//       : config.development.baseURL;
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const [POnumber, setPoNumber] = useState("");
//   const [dateTime, setDateTime] = useState("");
//   const [searchResult, setSearchResult] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const [runClicked, setRunClicked] = useState(false);
//   const [notFound, setNotFound] = useState(false);

//   const [pdfhovered, setPdfHovered] = useState(false);
//   const [taxhovered, setTaxHovered] = useState(false);
//   const [imageSource, setImageSource] = useState();
//   const [imageResponse, setImageResponse] = useState([]);
//   const [isSubmitting, setIsSubmitting] = useState(false);

//   // Add modal state variables
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [selectedImagePath, setSelectedImagePath] = useState("");
//   const [selectedInvoiceNumberforModel, setSelectedInvoiceNumberforModel] =
//     useState("");

//   const user_id = localStorage.getItem("user_id");
//   const user_role = localStorage.getItem("user_role");
//   const business_id = localStorage.getItem("business_id");
//   const contentRef = useRef();
//   const [userRole, setUserRole] = useState("");

//   const isButtonEnabled = () => {
//     return POnumber.trim() !== "";
//   };

//   const formatToTwoDecimalsWithCommas = (num) => {
//     const parts = num.toString().split(".");
//     const integerPartWithCommas = parseInt(parts[0], 10).toLocaleString(
//       "en-US"
//     );
//     const decimalPart = parts[1] ? parts[1].substring(0, 2) : "";

//     return decimalPart
//       ? `${integerPartWithCommas}.${decimalPart}`
//       : integerPartWithCommas;
//   };

//   const generateUSDateTime = () => {
//     setRunClicked(true);
//     const currentDate = new Date();

//     const formattedDateTime = currentDate.toLocaleString("en-US", {
//       year: "numeric",
//       month: "numeric",
//       day: "numeric"
//     });

//     setDateTime(formattedDateTime);
//   };

//   function formatNumberwithoutzeros(num) {
//     return new Intl.NumberFormat("en-US", {
//       minimumFractionDigits: 0,
//       maximumFractionDigits: 0
//     }).format(num);
//   }

//   function formatNumber(num) {
//     return new Intl.NumberFormat("en-US", {
//       minimumFractionDigits: 2,
//       maximumFractionDigits: 2
//     }).format(num);
//   }

//   useEffect(() => {
//     setUserRole(localStorage.getItem("user_role"));
//   }, []);

//   useEffect(() => {
//     axios
//       .get(
//         `${baseURL}/${btoa("business/profile")}/${btoa(business_id)}/${btoa(
//           user_id
//         )}/${btoa(user_role)}`
//       )
//       .then((response) => {
//         setImageSource(response.data.business_data[0].image_file);
//         setImageResponse(response.data.business_data[0]);
//       })
//       .catch((error) => {
//         console.error("Error fetching business data:", error);
//       });
//   }, []);

//   const handleRun = async () => {
//     setRunClicked(true);
//     setLoading(true);
//     setNotFound(false);
//     generateUSDateTime();

//     try {
//       const requestData = {
//         POnumber: POnumber,
//         businessId: businessId,
//         managerId: managerId,
//         userRole: userRole
//       };

//       const response = await axios.post(
//         `${baseURL}/cmVwb3J0L3NlYXJjaFBP`,
//         requestData
//       );

//       // if (
//       //   response.data &&
//       //   response.data.report_data &&
//       //   response.data.report_data.length > 0
//       // ) {
//       //   const poData = response.data.report_data[0];
//       //   console.log(">>>>ponumber", poData.POnumber);
//       //   const formattedData = {
//       //     id: poData.id,
//       //     POnumber: poData.brand_invoice_number,
//       //     date: formatDate(poData.brand_invoice_date),
//       //     brand: poData.seller_name || poData.brand_file,
//       //     brandLogo: poData.brand_logo,
//       //     gallons: poData.total_gallons,
//       //     amount: parseFloat(poData.total_amount),
//       //     serialNumber: poData.img_file,
//       //     dueDate: formatDate(poData.brand_invoice_duedate)
//       //   };
//       //   console.log("requestData", requestData);
//       //   console.log("requestData", response);
//       //   console.log("brandLogo", poData.brand_logo);
//       //   setSearchResult(formattedData);
//       //   setNotFound(false);
//       // } else {
//       //   setSearchResult(null);
//       //   setNotFound(true);
//       //   console.log("requestData", requestData);
//       // }

//       // Replace this section in the handleRun function:

// if (
//   response.data &&
//   response.data.report_data &&
//   response.data.report_data.length > 0
// ) {
//   const poData = response.data.report_data[0];
//   console.log(">>>>ponumber", poData.POnumber);
//   const formattedData = {
//     id: poData.id,
//     poNumber: poData.POnumber,
//     date: formatDate(poData.brand_invoice_date),
//     brand: poData.seller_name || poData.brand_file,
//     brandLogo: poData.brand_logo,
//     gallons: poData.total_gallons,
//     amount: parseFloat(poData.total_amount),
//     serialNumber: poData.img_file,
//     dueDate: formatDate(poData.brand_invoice_duedate)
//   };
//   console.log("poNumber", poData.POnumber);
//   console.log("requestData", requestData);
//   console.log("requestData", response);
//   console.log("brandLogo", poData.brand_logo);
//   console.log("poData", poData);
//   setSearchResult(formattedData);
//   setNotFound(false);
// } else {
//   setSearchResult(null);
//   setNotFound(true);
//   console.log("requestData", requestData);
// }
//       console.log("requestData", requestData);
//     } catch (error) {
//       console.error("Error searching PO:", error);
//       dispatch(
//         addToast({
//           type: "error",
//           message: `Error searching PO: ${error.message}`
//         })
//       );
//       setSearchResult(null);
//       setNotFound(true);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const formatDate = (dateString) => {
//     if (!dateString) return "";

//     const date = new Date(dateString);
//     if (isNaN(date)) return dateString;

//     const month = String(date.getMonth() + 1).padStart(2, "0");
//     const day = String(date.getDate()).padStart(2, "0");
//     const year = date.getFullYear();

//     return `${month}/${day}/${year}`;
//   };

//   const handleViewPO = (poId) => {
//     window.open(`/purchases/view/${poId}`, "_blank");
//   };

//   // Close modal function
//   const closeModal = () => {
//     setIsModalOpen(false);
//   };

//   // Function to handle view image
//   const handleView = (fileName) => {
//     setSelectedInvoiceNumberforModel(POnumber);
//     setSelectedImagePath(fileName);
//     setIsModalOpen(true);
//   };

//   const handleKeyPress = (e) => {
//     if (e.key === "Enter" && isButtonEnabled()) {
//       handleRun();
//     }
//   };

//   const themeColor = "rgb(231 70 148)";
//   const labelStyle =
//     "block mb-2 text-[14px] font-medium text-gray-600 inline-flex items-center";
//   const inputStyle =
//     "border border-gray-300 text-sm font-medium rounded-lg focus:ring-grey-600 focus:border-primary-600 block w-full text-gray-500 py-1 px-2 h-[30px]";
//   const buttonStyle = {
//     backgroundColor: themeColor,
//     color: "white",
//     // marginTop: "34px",
//     marginLeft: "14px",
//     height: "30px",
//     "&:hover": {
//       backgroundColor: "rgb(231 70 148)"
//     }
//   };

//   return (
//     <div className="ml-5">
//       <>
//         <div
//           className="flex justify-between items-center w-full pt-4 mt-4 mb-5"
//           style={{
//             maxWidth: "1200px",
//             boxSizing: "border-box",
//             marginLeft: "4px",
//             color: "#444444"
//           }}
//         >
//           <nav aria-label="breadcrumb">
//             <ol className="flex space-x-2 text-lg font-bold text-gray-700">
//               <li>Reports</li>
//               <li>
//                 <span className="text-gray-400">{">>"}</span>
//               </li>
//               <li>Search Po Report</li>
//             </ol>
//           </nav>
//         </div>
//         <div
//           className="py-[12px] px-[8px] rounded-xl bg-white"
//           style={{ boxShadow: "0 0 25px 0 rgba(0, 0, 0, 0.2)" }}
//         >
//           {/* <Box
//             sx={{
//               padding: 1,
//               paddingTop: 0,
//               paddingBottom: 1,
//               maxWidth: "100%",
//               backgroundColor: "#ffff",
//               marginTop: "20px"
//             }}
//           > */}
//           <Grid
//             container
//             spacing={6}
//             style={{ display: "flex", alignItems: "center" }}
//             gap={5}
//           >
//             <Grid item md={3}>
//               <label className="block mb-2 text-[14px] font-medium text-gray-600 inline-flex items-center ">
//                 Enter Po #
//                 <span className="text-red-500 text-xl font-extrabold pl-1">
//                   *
//                 </span>
//               </label>
//               <div className="flex items-center ">
//                 <input
//                   type="number"
//                   placeholder="Enter PO to search"
//                   value={POnumber}
//                   required
//                   onChange={(e) => setPoNumber(e.target.value)}
//                   onKeyPress={handleKeyPress}
//                   className="border border-gray-300 text-sm font-medium rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full text-gray-500 p-1.5"
//                   style={{ flex: 1 }}
//                 />
//                 <Button
//                   variant="contained"
//                   disabled={!isButtonEnabled()}
//                   sx={buttonStyle}
//                   endIcon={<SendIcon />}
//                   onClick={handleRun}
//                 >
//                   Run
//                 </Button>
//               </div>
//             </Grid>
//           </Grid>
//           {/* </Box> */}
//         </div>

//         {runClicked && (
//           <div id="runclicked">
//             <div
//               className="bg-white"
//               style={{
//                 borderRadius: "20px",
//                 maxWidth: "100%",
//                 overflowX: "auto",
//                 margin: "0 auto",
//                 boxShadow: "0 0 25px 0 rgba(0, 0, 0, 0.2)",
//                 marginTop: "45px",
//                 padding: "20px",
//                 justifyContent: "center",
//                 display: "flex",
//                 flexDirection: "column",
//                 paddingBottom: "80px"
//               }}
//             >
//               {searchResult && (
//                 <Grid container alignItems="end" justifyContent="end">
//                   <Grid item xs={12} md={4}>
//                     <Box p={2} className="font-bold text-md text-gray-400">
//                       {runClicked && <p>Report RunTime: {dateTime}</p>}
//                     </Box>
//                   </Grid>
//                   <Grid item xs={12} md={8}>
//                     <Grid container alignItems="end" justifyContent="end">
//                       <Grid item>
//                         <Tooltip
//                           title="Pdf"
//                           position="top"
//                           trigger="mouseenter"
//                         >
//                           <IconButton
//                             aria-label="Download PDF"
//                             sx={{
//                               backgroundColor: "transparent",
//                               borderRadius: "50%",
//                               padding: "12px",
//                               "&:hover": {
//                                 backgroundColor: "rgba(227, 140, 151, 0.3)"
//                               },
//                               transition: "background-color 0.3s ease"
//                             }}
//                             onMouseEnter={() => setPdfHovered(true)}
//                             onMouseLeave={() => setPdfHovered(false)}
//                           >
//                             {pdfhovered ? (
//                               <FileDownloadIcon
//                                 sx={{ fontSize: 20, color: "red" }}
//                               />
//                             ) : (
//                               <PictureAsPdfIcon
//                                 fontSize="small"
//                                 color="error"
//                               />
//                             )}
//                           </IconButton>
//                         </Tooltip>
//                       </Grid>
//                       <Grid item>
//                         <Tooltip
//                           title="Print"
//                           position="top"
//                           trigger="mouseenter"
//                         >
//                           <IconButton
//                             aria-label="Print"
//                             sx={{
//                               backgroundColor: "transparent",
//                               borderRadius: "50%",
//                               padding: "12px",
//                               "&:hover": {
//                                 backgroundColor: "rgba(0, 0, 0, 0.1)"
//                               },
//                               transition: "background-color 0.3s ease"
//                             }}
//                           >
//                             <PrintIcon
//                               fontSize="small"
//                               sx={{ color: "black" }}
//                             />
//                           </IconButton>
//                         </Tooltip>
//                       </Grid>
//                       <Grid item>
//                         <Tooltip
//                           title="Excel"
//                           position="top"
//                           trigger="mouseenter"
//                         >
//                           <IconButton
//                             aria-label="Tax File"
//                             sx={{
//                               backgroundColor: "transparent",
//                               borderRadius: "50%",
//                               padding: "12px",
//                               "&:hover": {
//                                 backgroundColor: "rgba(143, 191, 96, 0.3)"
//                               },
//                               transition: "background-color 0.3s ease"
//                             }}
//                             onMouseEnter={() => setTaxHovered(true)}
//                             onMouseLeave={() => setTaxHovered(false)}
//                           >
//                             {taxhovered ? (
//                               <FileDownloadIcon
//                                 sx={{ fontSize: 20, color: "red" }}
//                               />
//                             ) : (
//                               <DescriptionIcon
//                                 fontSize="small"
//                                 style={{ color: "#4CAF50" }}
//                               />
//                             )}
//                           </IconButton>
//                         </Tooltip>
//                       </Grid>
//                     </Grid>
//                   </Grid>
//                 </Grid>
//               )}

//               <div
//                 ref={contentRef}
//                 style={{
//                   width: "100%",
//                   height: "auto",
//                   justifyContent: "center",
//                   alignSelf: "center",
//                   borderRadius: "10px",
//                   boxShadow: "0 0 20px 0 rgba(0, 0, 0, .1)",
//                   padding: "20px",
//                   marginTop: "20px"
//                 }}
//               >
//                 <div
//                   style={{
//                     display: "flex",
//                     alignItems: "center",
//                     height: "110px",
//                     justifyContent: "space-between",
//                     marginBottom: "20px",
//                     backgroundColor: "rgba(180,180,180,0.1)",
//                     padding: "0 20px"
//                   }}
//                 >
//                   {userRole !== "1" && (
//                     <div className="">
//                       {imageSource && (
//                         <img
//                           style={{
//                             width: "140px",
//                             height: "60px"
//                           }}
//                           src={`${baseURL}uploads/img/business/${imageSource}`}
//                           alt="Selected"
//                         />
//                       )}
//                     </div>
//                   )}

//                   <div
//                     style={{
//                       textAlign: "center",
//                       marginLeft: "auto",
//                       marginRight: "auto"
//                     }}
//                   >
//                     <p>
//                       <strong
//                         style={{
//                           fontWeight: "600",
//                           fontSize: "18px",
//                           margin: "5px"
//                         }}
//                       >
//                         {imageResponse.business_name}
//                       </strong>
//                     </p>
//                     <p>
//                       <strong
//                         style={{
//                           fontWeight: "600",
//                           fontSize: "18px",
//                           margin: "5px"
//                         }}
//                       >
//                         {imageResponse.business_address}
//                       </strong>
//                     </p>
//                     <p>
//                       <strong
//                         style={{
//                           fontWeight: "600",
//                           fontSize: "18px",
//                           margin: "5px"
//                         }}
//                       >
//                         {imageResponse.business_city},{" "}
//                         {imageResponse.business_state},{" "}
//                         {imageResponse.business_zip_code}
//                       </strong>
//                     </p>
//                   </div>

//                   <img
//                     src="/static/media/logo.0ea4b695a3030ea659a8.png"
//                     alt="invoicefilelogo"
//                     className="w-max"
//                     style={{
//                       width: "240px",
//                       height: "200px"
//                     }}
//                   />
//                 </div>

//                 {runClicked && (
//                   <div
//                     style={{
//                       color: "#1976d2",
//                       textAlign: "center",
//                       fontSize: "24px",
//                       fontWeight: "bold",
//                       marginTop: "50px"
//                     }}
//                     className="mb-1"
//                   >
//                     Purchase Order Details
//                     <hr className="border-t border-gray-300 mb-1 mt-2" />
//                   </div>
//                 )}

//                 {runClicked && loading ? (
//                   <FullScreenLoader />
//                 ) : runClicked && searchResult ? (
//                   <Row
//                     style={{
//                       borderRadius: "10px",
//                       boxShadow: "0 4px 6px rgba(0, 0, 0.1, 0.1)",
//                       padding: "24px",
//                       marginBottom: "20px",
//                       margin: "auto",
//                       marginTop: "20px",
//                       border: "1px solid #e0e0e0",
//                       display: "flex",
//                       alignItems: "center",
//                       justifyContent: "space-evenly"
//                     }}
//                   >
//                     <Col style={{ marginBottom: "0px", paddingBottom: "10px" }}>
//                       <div
//                         style={{
//                           fontWeight: "bold",
//                           fontSize: "16px",
//                           color: "#444",
//                           marginBottom: "5px"
//                         }}
//                       >
//                         PO #:
//                       </div>
//                       <div style={{ fontSize: "16px", paddingLeft: "0px" }}>
//                         <Tooltip
//                           title="View"
//                           position="top"
//                           trigger="mouseenter"
//                         >
//                           <a
//                             href="#"
//                             onClick={(e) => {
//                               e.preventDefault();
//                               handleView(searchResult.serialNumber);
//                             }}
//                             style={{
//                               color: "#1976d2",
//                               textDecoration: "underline",
//                               fontWeight: "500",
//                               display: "flex",
//                               alignItems: "center"
//                             }}
//                           >
//                             {searchResult.POnumber}
//                             {/* <AiFillEye size={18} style={{ marginLeft: "5px" }} /> */}
//                           </a>
//                         </Tooltip>
//                       </div>
//                     </Col>

//                     {/* Date */}
//                     {/* <Col style={{ marginBottom: "0px", paddingBottom: "10px" }}>
//                       <div style={{ fontWeight: "bold", fontSize: "16px", color: "#444", marginBottom: "5px" }}>
//                         Date:
//                       </div>
//                       <div style={{ fontSize: "16px", paddingLeft: "0px" }}>
//                         {searchResult.date}
//                       </div>
//                     </Col>
//                      */}

//                     <Col style={{ marginBottom: "0px", paddingBottom: "10px" }}>
//                       <div
//                         style={{
//                           fontWeight: "bold",
//                           fontSize: "16px",
//                           color: "#444",
//                           marginBottom: "5px"
//                         }}
//                       >
//                         Date:
//                       </div>
//                       <div style={{ fontSize: "16px", paddingLeft: "0px" }}>
//                         {
//                           new Date(searchResult.date)
//                             .toISOString()
//                             .split("T")[0]
//                         }
//                       </div>
//                     </Col>

//                     {/* Brand Logo */}
//                     <Col style={{ marginBottom: "0px", paddingBottom: "10px" }}>
//                       <div
//                         style={{
//                           fontWeight: "bold",
//                           fontSize: "16px",
//                           color: "#444",
//                           marginBottom: "5px"
//                         }}
//                       >
//                         Brand Logo:
//                       </div>
//                       <div
//                         style={{
//                           fontSize: "16px",
//                           paddingLeft: "0px",
//                           display: "flex",
//                           justifyContent: "center"
//                         }}
//                       >
//                         {searchResult.brandLogo ? (
//                           <img
//                             src={`${baseURL}img/${searchResult.brandLogo}`}
//                             alt={`${searchResult.brand} Logo`}
//                             style={{
//                               width: "80px",
//                               height: "30px",
//                               objectFit: "contain"
//                             }}
//                           />
//                         ) : (
//                           <span style={{ color: "#666", fontStyle: "italic" }}>
//                             No Logo
//                           </span>
//                         )}
//                       </div>
//                     </Col>

//                     {/* Brand */}
//                     <Col style={{ marginBottom: "0px", paddingBottom: "10px" }}>
//                       <div
//                         style={{
//                           fontWeight: "bold",
//                           fontSize: "16px",
//                           color: "#444",
//                           marginBottom: "5px"
//                         }}
//                       >
//                         Brand:
//                       </div>
//                       <div
//                         style={{
//                           fontSize: "16px",
//                           paddingLeft: "0px",
//                           textTransform: "capitalize"
//                         }}
//                       >
//                         {searchResult.brand}
//                       </div>
//                     </Col>

//                     {/* Gallons */}
//                     <Col style={{ marginBottom: "0px", paddingBottom: "10px" }}>
//                       <div
//                         style={{
//                           fontWeight: "bold",
//                           fontSize: "16px",
//                           color: "#444",
//                           marginBottom: "5px"
//                         }}
//                       >
//                         Gallons:
//                       </div>
//                       <div style={{ fontSize: "16px", paddingLeft: "0px" }}>
//                         {formatNumberwithoutzeros(searchResult.gallons)} gl
//                       </div>
//                     </Col>

//                     {/* Amount */}
//                     <Col style={{ marginBottom: "0px", paddingBottom: "10px" }}>
//                       <div
//                         style={{
//                           fontWeight: "bold",
//                           fontSize: "16px",
//                           color: "#444",
//                           marginBottom: "5px"
//                         }}
//                       >
//                         Amount:
//                       </div>
//                       <div
//                         style={{
//                           fontSize: "16px",
//                           paddingLeft: "0px",
//                           fontWeight: "500"
//                         }}
//                       >
//                         $ {formatNumber(searchResult.amount)}
//                       </div>
//                     </Col>
//                   </Row>
//                 ) : (
//                   runClicked &&
//                   notFound && (
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "center",
//                         marginTop: "40px",
//                         marginBottom: "40px"
//                       }}
//                     >
//                       <p
//                         style={{
//                           color: "red",
//                           fontSize: "18px",
//                           fontWeight: "bold",
//                           textAlign: "center",
//                           padding: "15px 25px",
//                           backgroundColor: "rgba(255, 0, 0, 0.05)",
//                           borderRadius: "8px",
//                           border: "1px solid rgba(255, 0, 0, 0.2)"
//                         }}
//                       >
//                         Sorry, "{POnumber}" PO is not in the system
//                       </p>
//                     </div>
//                   )
//                 )}
//               </div>
//             </div>
//           </div>
//         )}

//         {/* Modal Component */}
//         <PurchaseImagesViewModel
//           isModalOpen={isModalOpen}
//           closeModal={closeModal}
//           selectedImagePath={selectedImagePath}
//           selectedInvoiceNumberforModel={selectedInvoiceNumberforModel}
//         />
//       </>
//     </div>
//   );
// };

// export default SearchPoReport;

import React, { useState, useRef, useEffect } from "react";
import { Box, Paper } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import { useSelector, useDispatch } from "react-redux";
import { Grid, IconButton, Typography } from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PrintIcon from "@mui/icons-material/Print";
import DescriptionIcon from "@mui/icons-material/Description";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import SearchIcon from "@mui/icons-material/Search";
// import { AiFillEye } from "react-icons/ai"; // Added AiFillEye import
import axios from "axios";
import { Tooltip } from "react-tippy";
import config from "../../config/config";
import FullScreenLoader from "../pages/FullScreenLoader";
import { Col, Row } from "react-bootstrap";
import { addToast } from "../pages/ToastBox/ToastBoxMessageSlice";
import { useNavigate } from "react-router-dom";
import PurchaseImagesViewModel from "../models/PurchaseImagesViewModel";
import { FaHome } from "react-icons/fa";
// import PurchaseImagesViewModel from "./PurchaseImagesViewModel"; // Import the modal component

const SearchPoReport = () => {
  const businessId = localStorage.getItem("business_id");
  const managerId = localStorage.getItem("user_id");
  const baseURL =
    process.env.NODE_ENV === "production"
      ? config.production.baseURL
      : config.development.baseURL;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [inputPoNumber, setInputPoNumber] = useState(""); // Changed variable name to be more descriptive
  const [dateTime, setDateTime] = useState("");
  const [searchResult, setSearchResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [runClicked, setRunClicked] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [searchedPoNumber, setSearchedPoNumber] = useState(""); // Added to track the PO number that was searched

  const [pdfhovered, setPdfHovered] = useState(false);
  const [taxhovered, setTaxHovered] = useState(false);
  const [imageSource, setImageSource] = useState();
  const [imageResponse, setImageResponse] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Add modal state variables
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImagePath, setSelectedImagePath] = useState("");
  const [selectedInvoiceNumberforModel, setSelectedInvoiceNumberforModel] =
    useState("");

  const user_id = localStorage.getItem("user_id");
  const user_role = localStorage.getItem("user_role");
  const business_id = localStorage.getItem("business_id");
  const contentRef = useRef();
  const [userRole, setUserRole] = useState("");

  const isButtonEnabled = () => {
    return inputPoNumber.trim() !== "";
  };

  const formatToTwoDecimalsWithCommas = (num) => {
    const parts = num.toString().split(".");
    const integerPartWithCommas = parseInt(parts[0], 10).toLocaleString(
      "en-US"
    );
    const decimalPart = parts[1] ? parts[1].substring(0, 2) : "";

    return decimalPart
      ? `${integerPartWithCommas}.${decimalPart}`
      : integerPartWithCommas;
  };

  const generateUSDateTime = () => {
    setRunClicked(true);
    const currentDate = new Date();

    const formattedDateTime = currentDate.toLocaleString("en-US", {
      year: "numeric",
      month: "numeric",
      day: "numeric"
    });

    setDateTime(formattedDateTime);
  };

  function formatNumberwithoutzeros(num) {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(num);
  }

  function formatNumber(num) {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(num);
  }

  useEffect(() => {
    setUserRole(localStorage.getItem("user_role"));
  }, []);

  useEffect(() => {
    axios
      .get(
        `${baseURL}/${btoa("business/profile")}/${btoa(business_id)}/${btoa(
          user_id
        )}/${btoa(user_role)}`
      )
      .then((response) => {
        setImageSource(response.data.business_data[0].image_file);
        setImageResponse(response.data.business_data[0]);
      })
      .catch((error) => {
        console.error("Error fetching business data:", error);
      });
  }, []);

  const handleRun = async () => {
    setRunClicked(true);
    setLoading(true);
    setNotFound(false);
    setSearchedPoNumber(inputPoNumber); // Store the searched PO number
    generateUSDateTime();

    try {
      const requestData = {
        POnumber: inputPoNumber, // Use inputPoNumber instead of POnumber
        businessId: businessId,
        managerId: managerId,
        userRole: userRole
      };

      const response = await axios.post(
        `${baseURL}/cmVwb3J0L3NlYXJjaFBP`,
        requestData
      );

      if (
        response.data &&
        response.data.report_data &&
        response.data.report_data.length > 0
      ) {
        const poData = response.data.report_data[0];
        console.log(">>>>ponumber", poData.POnumber);
        const formattedData = {
          id: poData.id,
          POnumber: poData.brand_invoice_number, // Use the API response PO number
          date: formatDate(poData.brand_invoice_date),
          brand: poData.seller_name || poData.brand_file,
          brandLogo: poData.brand_logo,
          gallons: poData.total_gallons,
          amount: parseFloat(poData.total_amount),
          serialNumber: poData.img_file,
          dueDate: formatDate(poData.brand_invoice_duedate)
        };
        console.log("requestData", requestData);
        console.log("requestData", response);
        console.log("brandLogo", poData.brand_logo);
        setSearchResult(formattedData);
        setNotFound(false);
      } else {
        setSearchResult(null);
        setNotFound(true);
        console.log("requestData", requestData);
      }
      console.log("requestData", requestData);
    } catch (error) {
      console.error("Error searching PO:", error);
      dispatch(
        addToast({
          type: "error",
          message: `Error searching PO: ${error.message}`
        })
      );
      setSearchResult(null);
      setNotFound(true);
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";

    const date = new Date(dateString);
    if (isNaN(date)) return dateString;

    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
  };

  const handleViewPO = (poId) => {
    window.open(`/purchases/view/${poId}`, "_blank");
  };

  // Close modal function
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Function to handle view image
  const handleView = (fileName) => {
    setSelectedInvoiceNumberforModel(searchResult.POnumber); // Use searchResult.POnumber
    setSelectedImagePath(fileName);
    setIsModalOpen(true);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && isButtonEnabled()) {
      handleRun();
    }
  };

  const themeColor = "rgb(231 70 148)";
  const labelStyle =
    "block mb-2 text-[14px] font-medium text-gray-600 inline-flex items-center";
  const inputStyle =
    "border border-gray-300 text-sm font-medium rounded-lg focus:ring-grey-600 focus:border-primary-600 block w-full text-gray-500 py-1 px-2 h-[30px]";
  const buttonStyle = {
    backgroundColor: themeColor,
    color: "white",
    // marginTop: "34px",
    marginLeft: "14px",
    height: "30px",
    "&:hover": {
      backgroundColor: "rgb(231 70 148)"
    }
  };

  return (
    <div className="ml-5">
      <>
        <div
          className="flex justify-between items-center w-full pt-4 mt-4 mb-5"
          style={{
            maxWidth: "1200px",
            boxSizing: "border-box",
            marginLeft: "4px",
            color: "#444444"
          }}
        >
          <nav aria-label="breadcrumb">
            <ol className="flex space-x-2 text-lg font-bold text-gray-700">
              <li>Reports</li>
              <li>
                <span className="text-gray-400">{">>"}</span>
              </li>
              <li>Search Po</li>
            </ol>
          </nav>
        </div>
        <div
          className="relative py-[12px] px-[8px] rounded-xl bg-white"
          style={{ boxShadow: "0 0 25px 0 rgba(0, 0, 0, 0.2)" }}
        > <div className="flex items-center justify-end">
        {/* Home button - bordered/unfilled by default, filled blue on hover */}
        <div
          className="absolute -top-4 flex items-center justify-center cursor-pointer rounded-full bg-[#cefafe] hover:bg-[#25caed] w-9 h-9 mb-0 mr-6 border-[0.5px] border-[#25caed] hover:border-[#25caed] hover:scale-110 shadow-md transition-all duration-200 group"
          onClick={() => {
            navigate("/");
          }}
          style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
        >
          <FaHome
            className="text-[#25caed] group-hover:text-white transition-colors duration-200"
            size={16}
          />
        </div>

        {/* Close Icon - bordered/unfilled by default, filled red on hover */}
        <div
          className="absolute -top-4 -right-2 bg-red-200 hover:bg-red-500 w-9 h-9 flex items-center justify-center rounded-full cursor-pointer hover:bg-red-700 border-[0.5px] border-red-500 hover:border-red-700 hover:scale-110 shadow-md transition-all duration-200 group"
          onClick={() => navigate("/HomeReports")}
        >
          <span className="text-red-500 group-hover:text-white text-xl font-bold mb-1.5 transition-colors duration-200">
            &times;
          </span>
        </div>
      </div>
          <Grid
            container
            spacing={6}
            style={{ display: "flex", alignItems: "center" }}
            gap={5}
          >
            <Grid item md={3}>
              <label className="block mb-2 text-[14px] font-medium text-gray-600 inline-flex items-center ">
                Enter Po #
                <span className="text-red-500 text-xl font-extrabold pl-1">
                  *
                </span>
              </label>
              <div className="flex items-center ">
                <input
                  type="number"
                  placeholder="Enter PO to search"
                  value={inputPoNumber}
                  required
                  onChange={(e) => setInputPoNumber(e.target.value)}
                  onKeyPress={handleKeyPress}
                  className="border border-gray-300 text-sm font-medium rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full text-gray-500 p-1.5"
                  style={{ flex: 1 }}
                />
                <Button
                  variant="contained"
                  disabled={!isButtonEnabled()}
                  sx={buttonStyle}
                  endIcon={<SendIcon />}
                  onClick={handleRun}
                >
                  Run
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>

        {runClicked && (
          <div id="runclicked">
            <div
              className="bg-white"
              style={{
                borderRadius: "20px",
                maxWidth: "100%",
                overflowX: "auto",
                margin: "0 auto",
                boxShadow: "0 0 25px 0 rgba(0, 0, 0, 0.2)",
                marginTop: "45px",
                padding: "20px",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
                paddingBottom: "80px"
              }}
            >
              {searchResult && (
                <Grid container alignItems="end" justifyContent="end">
                  <Grid item xs={12} md={4}>
                    <Box p={2} className="font-bold text-md text-gray-400">
                      {runClicked && <p>Report RunTime: {dateTime}</p>}
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Grid container alignItems="end" justifyContent="end">
                      <Grid item>
                        <Tooltip
                          title="Pdf"
                          position="top"
                          trigger="mouseenter"
                        >
                          <IconButton
                            aria-label="Download PDF"
                            sx={{
                              backgroundColor: "transparent",
                              borderRadius: "50%",
                              padding: "12px",
                              "&:hover": {
                                backgroundColor: "rgba(227, 140, 151, 0.3)"
                              },
                              transition: "background-color 0.3s ease"
                            }}
                            onMouseEnter={() => setPdfHovered(true)}
                            onMouseLeave={() => setPdfHovered(false)}
                          >
                            {pdfhovered ? (
                              <FileDownloadIcon
                                sx={{ fontSize: 20, color: "red" }}
                              />
                            ) : (
                              <PictureAsPdfIcon
                                fontSize="small"
                                color="error"
                              />
                            )}
                          </IconButton>
                        </Tooltip>
                      </Grid>
                      <Grid item>
                        <Tooltip
                          title="Print"
                          position="top"
                          trigger="mouseenter"
                        >
                          <IconButton
                            aria-label="Print"
                            sx={{
                              backgroundColor: "transparent",
                              borderRadius: "50%",
                              padding: "12px",
                              "&:hover": {
                                backgroundColor: "rgba(0, 0, 0, 0.1)"
                              },
                              transition: "background-color 0.3s ease"
                            }}
                          >
                            <PrintIcon
                              fontSize="small"
                              sx={{ color: "black" }}
                            />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                      <Grid item>
                        <Tooltip
                          title="Excel"
                          position="top"
                          trigger="mouseenter"
                        >
                          <IconButton
                            aria-label="Tax File"
                            sx={{
                              backgroundColor: "transparent",
                              borderRadius: "50%",
                              padding: "12px",
                              "&:hover": {
                                backgroundColor: "rgba(143, 191, 96, 0.3)"
                              },
                              transition: "background-color 0.3s ease"
                            }}
                            onMouseEnter={() => setTaxHovered(true)}
                            onMouseLeave={() => setTaxHovered(false)}
                          >
                            {taxhovered ? (
                              <FileDownloadIcon
                                sx={{ fontSize: 20, color: "red" }}
                              />
                            ) : (
                              <DescriptionIcon
                                fontSize="small"
                                style={{ color: "#4CAF50" }}
                              />
                            )}
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}

              <div
                ref={contentRef}
                style={{
                  width: "100%",
                  height: "auto",
                  justifyContent: "center",
                  alignSelf: "center",
                  borderRadius: "10px",
                  boxShadow: "0 0 20px 0 rgba(0, 0, 0, .1)",
                  padding: "20px",
                  marginTop: "20px"
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: "110px",
                    justifyContent: "space-between",
                    marginBottom: "20px",
                    backgroundColor: "rgba(180,180,180,0.1)",
                    padding: "0 20px"
                  }}
                >
                  {userRole !== "1" && (
                    <div className="">
                      {imageSource && (
                        <img
                          style={{
                            width: "140px",
                            height: "60px"
                          }}
                          src={`${baseURL}uploads/img/business/${imageSource}`}
                          alt="Selected"
                        />
                      )}
                    </div>
                  )}

                  <div
                    style={{
                      textAlign: "center",
                      marginLeft: "auto",
                      marginRight: "auto"
                    }}
                  >
                    <p>
                      <strong
                        style={{
                          fontWeight: "600",
                          fontSize: "18px",
                          margin: "5px"
                        }}
                      >
                        {imageResponse.business_name}
                      </strong>
                    </p>
                    <p>
                      <strong
                        style={{
                          fontWeight: "600",
                          fontSize: "18px",
                          margin: "5px"
                        }}
                      >
                        {imageResponse.business_address}
                      </strong>
                    </p>
                    <p>
                      <strong
                        style={{
                          fontWeight: "600",
                          fontSize: "18px",
                          margin: "5px"
                        }}
                      >
                        {imageResponse.business_city},{" "}
                        {imageResponse.business_state},{" "}
                        {imageResponse.business_zip_code}
                      </strong>
                    </p>
                  </div>

                  <img
                    src="/static/media/logo.0ea4b695a3030ea659a8.png"
                    alt="invoicefilelogo"
                    className="w-max"
                    style={{
                      width: "240px",
                      height: "200px"
                    }}
                  />
                </div>

                {runClicked && (
                  <div
                    style={{
                      color: "#1976d2",
                      textAlign: "center",
                      fontSize: "24px",
                      fontWeight: "bold",
                      marginTop: "50px"
                    }}
                    className="mb-1"
                  >
                   Search PO Details
                    <hr className="border-t border-gray-300 mb-1 mt-2" />
                  </div>
                )}

                {runClicked && loading ? (
                  <FullScreenLoader />
                ) : runClicked && searchResult ? (
                  // <Row
                  //   style={{
                  //     borderRadius: "10px",
                  //     boxShadow: "0 4px 6px rgba(0, 0, 0.1, 0.1)",
                  //     padding: "24px",
                  //     marginBottom: "20px",
                  //     margin: "auto",
                  //     marginTop: "20px",
                  //     border: "1px solid #e0e0e0",
                  //     display: "flex",
                  //     alignItems: "center",
                  //     justifyContent: "space-evenly"
                  //   }}
                  // >
                  //   <Col style={{ marginBottom: "0px", paddingBottom: "10px" }}>
                  //     <div
                  //       style={{
                  //         fontWeight: "bold",
                  //         fontSize: "16px",
                  //         color: "#444",
                  //         marginBottom: "5px"
                  //       }}
                  //     >
                  //       PO #:
                  //     </div>
                  //     <div style={{ fontSize: "16px", paddingLeft: "0px" }}>
                  //       <Tooltip
                  //         title="View"
                  //         position="top"
                  //         trigger="mouseenter"
                  //       >
                  //         <a
                  //           href="#"
                  //           onClick={(e) => {
                  //             e.preventDefault();
                  //             handleView(searchResult.serialNumber);
                  //           }}
                  //           style={{
                  //             color: "#1976d2",
                  //             textDecoration: "underline",
                  //             fontWeight: "500",
                  //             display: "flex",
                  //             alignItems: "center"
                  //           }}
                  //         >
                  //           {searchResult.POnumber}
                  //           {/* <AiFillEye size={18} style={{ marginLeft: "5px" }} /> */}
                  //         </a>
                  //       </Tooltip>
                  //     </div>
                  //   </Col>

                  //   <Col style={{ marginBottom: "0px", paddingBottom: "10px" }}>
                  //     <div
                  //       style={{
                  //         fontWeight: "bold",
                  //         fontSize: "16px",
                  //         color: "#444",
                  //         marginBottom: "5px"
                  //       }}
                  //     >
                  //       Date:
                  //     </div>
                  //     <div style={{ fontSize: "16px", paddingLeft: "0px" }}>
                  //       {
                  //         new Date(searchResult.date)
                  //           .toISOString()
                  //           .split("T")[0]
                  //       }
                  //     </div>
                  //   </Col>

                  //   {/* Brand Logo */}
                  //   <Col style={{ marginBottom: "0px", paddingBottom: "10px" }}>
                  //     <div
                  //       style={{
                  //         fontWeight: "bold",
                  //         fontSize: "16px",
                  //         color: "#444",
                  //         marginBottom: "5px"
                  //       }}
                  //     >
                  //       Brand Logo:
                  //     </div>
                  //     <div
                  //       style={{
                  //         fontSize: "16px",
                  //         paddingLeft: "0px",
                  //         display: "flex",
                  //         justifyContent: "center"
                  //       }}
                  //     >
                  //       {searchResult.brandLogo ? (
                  //         <img
                  //           src={`${baseURL}img/${searchResult.brandLogo}`}
                  //           alt={`${searchResult.brand} Logo`}
                  //           style={{
                  //             width: "80px",
                  //             height: "30px",
                  //             objectFit: "contain"
                  //           }}
                  //         />
                  //       ) : (
                  //         <span style={{ color: "#666", fontStyle: "italic" }}>
                  //           No Logo
                  //         </span>
                  //       )}
                  //     </div>
                  //   </Col>

                  //   {/* Brand */}
                  //   <Col style={{ marginBottom: "0px", paddingBottom: "10px" }}>
                  //     <div
                  //       style={{
                  //         fontWeight: "bold",
                  //         fontSize: "16px",
                  //         color: "#444",
                  //         marginBottom: "5px"
                  //       }}
                  //     >
                  //       Brand:
                  //     </div>
                  //     <div
                  //       style={{
                  //         fontSize: "16px",
                  //         paddingLeft: "0px",
                  //         textTransform: "capitalize"
                  //       }}
                  //     >
                  //       {searchResult.brand}
                  //     </div>
                  //   </Col>

                  //   {/* Gallons */}
                  //   <Col style={{ marginBottom: "0px", paddingBottom: "10px" }}>
                  //     <div
                  //       style={{
                  //         fontWeight: "bold",
                  //         fontSize: "16px",
                  //         color: "#444",
                  //         marginBottom: "5px"
                  //       }}
                  //     >
                  //       Gallons:
                  //     </div>
                  //     <div style={{ fontSize: "16px", paddingLeft: "0px" }}>
                  //       {formatNumberwithoutzeros(searchResult.gallons)} gl
                  //     </div>
                  //   </Col>

                  //   {/* Amount */}
                  //   <Col style={{ marginBottom: "0px", paddingBottom: "10px" }}>
                  //     <div
                  //       style={{
                  //         fontWeight: "bold",
                  //         fontSize: "16px",
                  //         color: "#444",
                  //         marginBottom: "5px"
                  //       }}
                  //     >
                  //       Amount:
                  //     </div>
                  //     <div
                  //       style={{
                  //         fontSize: "16px",
                  //         paddingLeft: "0px",
                  //         fontWeight: "500"
                  //       }}
                  //     >
                  //       $ {formatNumber(searchResult.amount)}
                  //     </div>
                  //   </Col>
                  // </Row>
                  <div style={{ marginTop: "14px" }}>
                    <table
                      style={{
                        width: "50%",
                        maxWidth: "600px",
                        margin: "0px auto",
                        fontWeight: "normal",
                        borderCollapse: "collapse"
                      }}
                    >
                      {/* Header Row */}
                      <thead>
                        <tr style={{ backgroundColor: "#f5f5f5" }}>
                          <th
                            style={{
                              color: "#1976d2",
                              padding: "10px",
                              textAlign: "left",
                              borderBottom: "1px solid #ddd",
                              fontWeight: "700",
                              width: "200px"
                            }}
                          >
                            PO #
                          </th>
                          <th
                            style={{
                              color: "#1976d2",
                              padding: "10px",
                              textAlign: "left",
                              borderBottom: "1px solid #ddd",
                              width: "200px",
                              fontWeight: "700",
                              textAlign: "center"
                            }}
                          >
                            Date
                          </th>
                          {/* <th
                            style={{
                              padding: "10px",
                              textAlign: "center",
                              borderBottom: "1px solid #ddd"
                            }}
                          >
                            Brand
                          </th> */}
                          <th
                            style={{
                              color: "#1976d2",
                              padding: "10px",
                              textAlign: "right",
                              borderBottom: "1px solid #ddd",
                              width: "200px",
                              fontWeight: "700"
                            }}
                          >
                            Brand
                          </th>
                          {/* <th
                            style={{
                              padding: "10px",
                              textAlign: "right",
                              borderBottom: "1px solid #ddd"
                            }}
                          >
                            Gallons
                          </th>
                          <th
                            style={{
                              padding: "10px",
                              textAlign: "right",
                              borderBottom: "1px solid #ddd"
                            }}
                          >
                            Amount
                          </th> */}
                        </tr>
                      </thead>

                      {/* Data Rows */}
                      <tbody>
                        <tr
                          style={{ textAlign: "center", marginBottom: "10px" }}
                        >
                          {/* <td
                          style={{
                            padding: "10px",
                            fontWeight: "lighter",
                            textAlign: "left",
                            fontSize: "15px"
                          }}
                        >
                          PO Number
                        </td> */}
                          <td
                            style={{
                              padding: "10px",
                              fontWeight: "lighter",
                              textAlign: "left",
                              // fontSize: "16px"
                            }}
                          >
                            <a
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                handleView(searchResult.serialNumber);
                              }}
                              style={{
                                color: "#1976d2",
                                textDecoration: "underline",
                                fontWeight: "500"
                              }}
                            >
                              {searchResult.POnumber}
                            </a>
                          </td>
                          <td
                            style={{
                              padding: "10px",
                              fontWeight: "lighter",
                              textAlign: "center",
                              // fontSize: "16px"
                            }}
                          >
                            {searchResult.date}
                          </td>

                          {/* <td
                              style={{ padding: "10px", textAlign: "center" }}
                            >
                              {searchResult.brand_logo ? (
                               <img
                               src={`${baseURL}img/${searchResult.brandLogo}`}
                               alt={`${searchResult.brand} Logo`}
                               style={{
                                 maxWidth: "150px",
                                 maxHeight: "60px",
                                 margin: "0 auto",
                                 objectFit: "contain"
                               }}
                             />
                              ) : (
                                <span
                                  style={{ color: "#666", fontStyle: "italic" }}
                                >
                                  No Logo
                                </span>
                              )}
                            </td> */}
                          {searchResult.brandLogo && (
                            <td
                              style={{
                                // margin: "30px auto",
                                display: 'flex',
                                alignItems: "center",
                                justifyContent: "flex-end",
                                maxWidth: "200px"
                              }}
                            >
                              <img
                                src={`${baseURL}img/${searchResult.brandLogo}`}
                                alt={`${searchResult.brand} Logo`}
                                style={{
                                  width: "50px",
                                    height: "30px",
                                  // margin: "0 auto",
                                  objectFit: "contain"
                                }}
                              />
                            </td>
                          )}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ) : (
                  runClicked &&
                  notFound && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "40px",
                        marginBottom: "40px"
                      }}
                    >
                      <p
                        style={{
                          color: "red",
                          fontSize: "18px",
                          fontWeight: "bold",
                          textAlign: "center",
                          padding: "15px 25px",
                          backgroundColor: "rgba(255, 0, 0, 0.05)",
                          borderRadius: "8px",
                          border: "1px solid rgba(255, 0, 0, 0.2)"
                        }}
                      >
                        Sorry, "{searchedPoNumber}" PO is not in the system
                      </p>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        )}

        {/* Modal Component */}
        <PurchaseImagesViewModel
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          selectedImagePath={selectedImagePath}
          selectedInvoiceNumberforModel={selectedInvoiceNumberforModel}
        />
      </>
    </div>
  );
};

export default SearchPoReport;


import React, { useEffect } from "react";
import { useState } from "react";
import { FaEnvelope, FaLock, FaSignInAlt } from "react-icons/fa";
// import img1 from "../../assets/images/Login.jpg"
// import img2 from "../../assets/images/snapedit_1701426729284.png";
import { IoPerson } from "react-icons/io5";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup"
import Header from "../Headerside";
import { RiLoginCircleFill } from "react-icons/ri";
import {  FaEye, FaEyeSlash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import App from "../../App";
import { MdAttachEmail, MdBusinessCenter } from "react-icons/md";
import config  from "../../config/config"
import PersonIcon from "@mui/icons-material/Person";
import InvoiceFileLogo from "../../assets/images/logoHeader.png";
import InvoiceFileLogo1 from "../../assets/images/InvoiceFileLogo.png";
import bgImage from '../../assets/images/backgroundcustomer.jpeg';
import EmailIcon from '@mui/icons-material/Email';

import { useDispatch } from "react-redux";
import { addToast } from "../pages/ToastBox/ToastBoxMessageSlice";


const CustomerPortalHome = ({ onLogin , handleSignUpClick  ,handleShowOTP , openForgotPassword}) => {
  const [email, setEmail] = useState("");
  const dispatch = useDispatch()
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [formData, setFormData] = useState({
    email: "",
  });
  const [showMaskedEmail, setShowMaskedEmail] = useState(false);

  const formatEmail = (email) => {
    // Check if email is undefined, null, or empty string
    if (!email || typeof email !== 'string') {
      return '';
    }
  
    // Check if email contains @ symbol
    if (!email.includes('@')) {
      return email; // Return original value if it's not a valid email format
    }
  
    const [localPart, domain] = email.split('@');
    
    // Additional check to make sure domain exists
    if (!domain) {
      return email;
    }
    
    const maskedLocalPart = localPart.slice(0, 2) + "*****";
    
    // Make sure domain has a dot before trying to slice
    const lastDotIndex = domain.lastIndexOf('.');
    if (lastDotIndex === -1) {
      // Domain has no dot, mask the whole domain
      return `${maskedLocalPart}@${"*".repeat(domain.length)}`;
    }
    
    const domainName = domain.substring(0, lastDotIndex);
    const tld = domain.substring(lastDotIndex);
    const domainTLD = domain.slice(domain.lastIndexOf('.'));
    const maskedDomain = domainName.charAt(0) + "*".repeat(Math.max(3, domainName.length - 1));
    
    return `${maskedLocalPart}${domainTLD}`;
  };
  function capitalizeFirstLetter(email) {
    return email.charAt(0).toUpperCase() + email.slice(1).toLowerCase();
  }
  const [passwordVisible, setPasswordVisible] = useState(false);


  const [showSignUp , setShowSignUp] = useState(false)

  const handleLocalSignUpClick =()=>{
    setShowSignUp(!showSignUp)
  }



  const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;


  const CustomTooltip = ({ content, styles }) => {
    return (
      <div
        style={{
          display: content ? "block" : "none",
          position: "absolute",
          backgroundColor: "#ffffff",
          color: "#fff",
          padding: "5px",
          borderRadius: "4px",
          border: `1px solid ${styles.borderColor || "red"}`,
          marginTop: "-35px",
          marginLeft: "175px",
          marginRight: "auto",
          ...styles,
        }}
      >
        <div
          style={{
            position: "absolute",
            bottom: "-7px",
            left: "50%",
            marginLeft: "-10px",
            width: "0",
            height: "0",
            borderLeft: "7px solid transparent",
            borderRight: "7px solid transparent",
            borderTop: `7px solid ${styles.arrowStyle?.borderTopColor || "red"}`,
          }}
        ></div>
        <p className="text-xs text-red-500">{content}</p>
      </div>
    );
  };
  

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email("Invalid email address")
        .test({
          name: 'custom-email-validation',
          test: (value) => {
            return /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/.test(value);
          },
          message: 'Invalid email address',
        }),
    }),
    initialTouched: { 
      email: true,
    },
  });

  const handleLogin = () => {

  };

  let navigate = useNavigate();

  const handleForgotPassword = () => {
    openForgotPassword()
  };

 

  const handleLoginClick = async (e) => {
    e.preventDefault()

    if (!validateEmail(formik.values.email)) {
      setEmailError("Email / Password is invalid ");
      return;
    }
    setEmailError("");

    console.log("email:", formik.values.email, "password:", password , "domainName :" , formik.values.domainName);

    // onLogin( formik.values.email, password);

    try{
      const response = await axios.post(`${baseURL}/${btoa("login/managerlogin")}` , {
        submit : "true" , email: formik.values.email , password : password ,
        domainName : 'Invoicefile', userStatus: 5     
      }
    )
      console.log("response :" , response);
      localStorage.setItem("userStatus" , response.data.userStatus)
      localStorage.setItem("userOtp" , response.data.otp)
     
      const userData = response.data 
      
      // console.log("userData" ,userData);
      // console.log("status" ,userData.login_status);
      // console.log("token" ,userData.token);
    
    
      if (userData.otpSentStatus === true) {
        // alert("true")
        handleShowOTP(true, true);
        onLogin(formik.values.email, password);
        // toast.success('Login successful!', {
        //   position: 'top-right',
        //   autoClose: 3000, // 3 seconds
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        // });
        // <Header contact_email={userData.contact_email} contact_name={userData.contact_name}  />
      } else {
        
        setEmailError("Email / Password is invalid ");
      }
     if(userData.oldIpAddress === true){
      localStorage.setItem('user_role' , response.data.userStatus);
      // window.location.reload()
      localStorage.setItem("token" , response.data.token)
      localStorage.setItem("userEmail" , response.data.loggedin[0].contact_email)
      // localStorage.setItem('customer_id', response.data.loggedin[0].customer_id )
      localStorage.setItem('customer_id', response.data.loggedin[0].customer_id )
      localStorage.setItem('userName', response.data.loggedin[0].contact_name )

      localStorage.setItem("business_id", response.data.loggedin[0].business_id)
      localStorage.setItem("contact_id", response.data.loggedin[0].contact_id)

      if(response.data.loggedin[0].userStatus === 5 ){
        localStorage.setItem("business_id", response.data.loggedin[0].business_id)
          localStorage.setItem("contact_id", response.data.loggedin[0].contact_id)
          localStorage.setItem('customer_id', response.data.loggedin[0].customer_id )
      }
      navigate("/")

     }
    
      const token = userData.token
      // localStorage.setItem("token", token)
      axios.defaults.headers.common['Authorization'] = `Bearer ${userData.token}`
      
      navigate("/")
    }
    catch(error){
      console.log("error posting details" , error);
      if (error.response.data.emailPassStatus === "Email or password is not correct") {
        setEmailError("Email / Password is invalid")
         
      } 
      else if (error.response.data.domainStatus === "Domain name does not exist") {
        // toast.error("Invalid Domain Name" , {autoClose : 2000})
       dispatch(addToast({ type: 'danger', message:"Invalid Domain Name" }));       
        
        setEmailError("")
      } 
      else if(error.response.data.domainStatus === "Domain name does not exist" && error.response.data.login_status === false){
        setEmailError("Email / Password is invalid ")
      }
      // if (error.response.data.login_status === false && error.response.data.domainStatus === "Domain name does not exist") {
      //   setEmailError("Email / Password is invalid ")
      //   toast.error("Invalid Domain Name" , {autoClose : 2000})
      //   return
      // } 
    }
  };

  const validateEmail =(email)=>{
    const emailRegex = /^.+@.+\..+$/
    return emailRegex.test(email)
  }

  const showTooltip = () => {
    const isInvalidEmail = !validateEmail(formik.values.email);
    return (
      formik.touched.email &&
      formik.values.email.length > 0 &&
      isInvalidEmail && {
        borderColor: "red",
        arrowStyle: {
          borderTopColor: "red",
        },
      }
    );
  };

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   formik.handleChange(e);
  //   formik.setFieldValue(name, value.trim());
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [name]: value.trim(), 
  //   }));
  //   console.log("Email value:", value.trim());
  // };


  const [realEmail, setRealEmail] = useState("");
const [emailInputStyles, setEmailInputStyles] = useState(
  'pl-10 w-full px-3 py-2 rounded-lg focus:ring-blue-500 focus:border-blue-500 pr-10'
);
const [passwordInputStyles, setPasswordInputStyles] = useState(
  'pl-10 w-full px-3 py-2 rounded-lg focus:ring-blue-500 focus:border-blue-500 pr-10'
);
const [isFocused, setIsFocused] = useState(false);

// Replace the handleInputChange function
const handleInputChange = (e) => {
  const { name, value } = e.target;
  const trimmedValue = value.trim();
  setRealEmail(trimmedValue);
  formik.setFieldValue(name, trimmedValue);
  
  // Update input style based on content
  if (trimmedValue && validateEmail(trimmedValue)) {
    setEmailInputStyles('pl-10 w-full px-3 py-2 border-2 rounded-lg bg-blue-100 text-blue-800 border-blue-800 pr-10');
  } else {
    setEmailInputStyles('pl-10 w-full px-3 py-2 rounded-lg focus:ring-blue-500 focus:border-blue-500 pr-10');
  }
};

// Add these new handler functions
const handleEmailBlur = (e) => {
  formik.handleBlur(e);
  if (realEmail && realEmail.includes('@')) {
    setShowMaskedEmail(true);
    
    // Apply blue background style if valid
    if (validateEmail(realEmail)) {
      setEmailInputStyles('pl-10 w-full px-3 py-2 border-2 rounded-lg bg-blue-100 text-blue-800 border-blue-800 pr-10');
    }
  }
  setIsFocused(false);
};

const handleEmailFocus = () => {
  setShowMaskedEmail(false);
  setIsFocused(true);
  // Use regular style when focused
  setEmailInputStyles('pl-10 w-full px-3 py-2 rounded-lg focus:ring-blue-500 focus:border-blue-500 pr-10');
};

// Modify the showTooltip function to use realEmail
// const showTooltip = () => {
//   const isInvalidEmail = !validateEmail(realEmail);
//   return (
//     formik.touched.email &&
//     realEmail.length > 0 &&
//     isInvalidEmail && {
//       borderColor: "red",
//       arrowStyle: {
//         borderTopColor: "red",
//       },
//     }
//   );
// };

  return (
    <>
  
      <div className="relative flex h-screen w-screen "
 style={{
  backgroundImage: `linear-gradient(rgba(37,202,237,0.9), rgba(0,68,171,0.9)), url(${bgImage})`,
  backgroundSize: "100% 100%", 

}}      >

      {/* Left Section (3/4 width) */}
      <div className=" w-3/5  flex flex-col justify-center items-center z-10 ">
        <div className="text-center text-white max-w-[500px] px-8">
        <h1 className="text-5xl font-bold m-auto ml-[50px]">
          <img
             style={{ width: '300px',
              height: '80px'}}
              src={ InvoiceFileLogo1}
              alt="InvoiceFile"
              className={`${ "w-max"}`}
            />
  
          </h1>
          <p className="text-xl font-normal mb-14 text-white-300">Smart Invoice Processing</p>
          <h2 className="text-[28px] font-bold mb-4">
            Create Professional Invoices to Send to Your Customers
          </h2>
          <p className="text-base leading-relaxed ">
            Streamline your billing process with 
          </p>
          <p className="text-md leading-relaxed ">
          professional, easy-to-use
          templates.
          </p>
        </div>
      </div>
  
      {/* Right Section (1/4 width) */}
      <div className="w-2/5 flex justify-center items-center z-10 ">
        <div className="w-full max-w-sm px-8 py-1 bg-white rounded-lg">
        <h1 className="text-5xl font-bold m-auto ml-[50px] mb-[0px]">
          <img
             style={{ width: '200px',
              height: '90px'}}
              src={ InvoiceFileLogo}
              alt="InvoiceFile"
              className={`${ "w-max"}`}
            />
  
          </h1>
          <div className="text-center mb-3">
            <div className="w-24 h-24 bg-gray-200 rounded-full m-auto flex items-center justify-center">
        <PersonIcon style={{ fontSize: 50, color: "gray" }} />
  
            </div>
            <h2 className="text-2xl text-gray-600 font-bold mt-2">Customer Login</h2>
          </div>
  
          <form className="space-y-3"  onSubmit={formik.handleSubmit}>
          {/* {showTooltip() && (
                    <CustomTooltip content="Invalid email address" styles={showTooltip()} />
                  )} */}
            <div className="relative">
              <label htmlFor="email" className=" text-sm font-medium text-gray-700">
                Email
              </label>
              {showTooltip() && (
                  <CustomTooltip content="Invalid email address" styles={showTooltip()} />
                )}
              <div className="relative">
  
              <span className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              {/* <EmailIcon
                        className="text-gray-500 absolute top-1/2 transform -translate-y-1/2 left-3"
                        style={{  fontSize: "18px" }}
                      /> */}

                      <MdAttachEmail
                                            className="text-gray-500 absolute top-1/2 transform -translate-y-1/2 left-3"
                                            style={{ fontSize: "18px" }}
                                          />
      </span>
      {/* <input
  type="text"
  name="email"
  id="email"
  // Show masked email only after blur if showMaskedEmail is true
  value={showMaskedEmail && formik.values.email 
    ? formatEmail(formik.values.email) 
    : capitalizeFirstLetter(formik.values.email || '')}
  onChange={(e) => {
    // When user starts typing again, show the actual email
    setShowMaskedEmail(false);
    handleInputChange(e);
    formik.setFieldValue('email', e.target.value.trim());
  }}
  required
  // When focus leaves the input, mask the email
  onBlur={(e) => {
    formik.handleBlur(e);
    if (formik.values.email && formik.values.email.includes('@')) {
      setShowMaskedEmail(true);
    }
  }}
  // When user focuses on the input, show the actual email for editing
  onFocus={() => {
    setShowMaskedEmail(false);
  }}
  placeholder="Enter your email address"
  className={`pl-10 w-full px-3 py-2 border rounded-lg focus:ring-blue-500 focus:border-blue-500 ${
    showTooltip() ? "border-red-500" : ""
  } `}
/> */}


<input
  type="text"
  name="email"
  id="email"
  autoComplete="off"
  value={showMaskedEmail && realEmail ? formatEmail(realEmail) : capitalizeFirstLetter(realEmail || '')}
  onChange={handleInputChange}
  onBlur={handleEmailBlur}
  onFocus={handleEmailFocus}
  required
  placeholder="Enter your email address"
  className={`${emailInputStyles} ${showTooltip() ? "border-red-500" : ""}`}
/>
                          </div>
  
            </div>
            <div className="relative ">
              <div  className="flex justify-between items-baseline">
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                Password
             
              </label>
          
              </div>
              <div className="relative mb-8">
  
  <span className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
  <FaLock
                        className="text-gray-500 absolute top-1/2 transform -translate-y-1/2 left-3"
                      />
  </span>
              {/* <input
                    id="password"
                    name="password"
                    type={passwordVisible ? 'text' : 'password'}
                    value={password}
                    onChange={(e) => {
                      // Ensure only numeric values are entered
                      
                      // const sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
                      const sanitizedValue = e.target.value.replace(/[^a-zA-Z0-9]/g, '');

                      // Ensure only six digits are considered
                      const truncatedValue = sanitizedValue.slice(0, 6);
                      setPassword(truncatedValue);
                    }}
                    required
                placeholder="Enter your password"
                className="pl-10 w-full px-3 py-2 border rounded-lg focus:ring-blue-500 focus:border-blue-500 pr-10"
              /> */}
            

            <input
  id="password"
  name="password"
  type={passwordVisible ? 'text' : 'password'}
  value={password}
  onChange={(e) => {
    const sanitizedValue = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
    const truncatedValue = sanitizedValue.slice(0, 6);
    setPassword(truncatedValue);
    
    // Update input style based on content
    if (truncatedValue) {
      setPasswordInputStyles('pl-10 w-full px-3 py-2 border-2 rounded-lg bg-blue-100 text-blue-800 border-blue-800 pr-10');
    } else {
      setPasswordInputStyles('pl-10 w-full px-3 py-2 rounded-lg focus:ring-blue-500 focus:border-blue-500 pr-10');
    }
  }}
  onBlur={() => {
    if (password) {
      setPasswordInputStyles('pl-10 w-full px-3 py-2 border-2 rounded-lg bg-blue-100 text-blue-800 border-blue-800 pr-10');
    } else {
      setPasswordInputStyles('pl-10 w-full px-3 py-2 rounded-lg focus:ring-blue-500 focus:border-blue-500 pr-10');
    }
  }}
  onFocus={() => {
    setPasswordInputStyles('pl-10 w-full px-3 py-2 rounded-lg focus:ring-blue-500 focus:border-blue-500 pr-10');
  }}
  required
  placeholder="Enter your password"
  className={passwordInputStyles}
/>


              </div>
              {/* <div className="text-right mt-1" onClick={handleForgotPassword}>
                <a href="#" className="text-blue-500 text-sm hover:underline">
                  Forgot Password?
                </a>
              </div> */}
                   {password && password.length > 0 &&(
                              <div
                                className="absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer text-gray-500 pt-[30px]"
                                onClick={() => setPasswordVisible(!passwordVisible)}
                              >
                                {passwordVisible ? <FaEye /> : <FaEyeSlash /> }
                              </div>
                            )}
            </div>
            { <div style={{color:"red"}} className="m-auto h-[30px] flex justify-center items-center">{emailError}</div>}
  
            <button
              type="submit"
              className="w-full bg-[#25caed] text-white font-bold py-2 rounded-lg hover:bg-[#25caed] transition mt-8"
              onClick={handleLoginClick}us
            >
              LOGIN
            </button>
            {/* <button
              type="submit"
              className="w-full bg-blue-600 text-white font-bold py-2 rounded-lg hover:bg-blue-700 transition"
              onClick={()=>handleSignUpClick()}
              >
              SIGN UP
            </button> */}
            {/* <div className="flex justify-between">
            <div className="text-left " onClick={handleSignUpClick}>
                <a href="#" className="text-blue-500 text-sm hover:underline hover:bg-gray-200 p-2">
                Sign Up
                </a>
              </div>
              <div className="text-right " onClick={handleForgotPassword}>
                <a href="#" className="text-blue-500 text-sm hover:underline hover:bg-gray-200 p-2">
                  Forgot Password?
                </a>
              </div>
              </div> */}
       
          </form>
  
          <div className="mt-[20px] mb-[30px] text-center ">
            <div className="flex items-center text-[#26a9e1] text-sm mb-3">
        <div className="flex-grow border-t border-[#26a9e1]"></div>
        <span className="px-2 font-bold">Follow Us</span>
        <div className="flex-grow border-t border-[#26a9e1]"></div>
      </div>
            
      <div className="flex justify-center space-x-1">
  <a
   
    className="text-[#26a9e1] hover:bg-blue-100 w-10 h-10 flex items-center justify-center rounded-full"
  >
    <i className="fab fa-facebook-f  text-md"></i>
  </a>
  <a
   
    className="text-[#26a9e1] hover:bg-blue-100 w-10 h-10 flex items-center justify-center rounded-full"
  >
    <i className="fab fa-instagram  text-md"></i>
  </a>
  <a
   
    className="text-[#26a9e1] hover:bg-blue-100 w-10 h-10 flex items-center justify-center rounded-full"
  >
    <i className="fab fa-twitter  text-md"></i>
  </a>
  <a
   
    className="text-[#26a9e1] hover:bg-blue-100 w-10 h-10 flex items-center justify-center rounded-full"
  >
    <i className="fab fa-linkedin-in  text-md"></i>
  </a>
</div>
          </div>
  
        </div>
      </div>
    </div>
    </>
  );
};

export default CustomerPortalHome;

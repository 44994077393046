import React, { useState } from "react";
import { BiPurchaseTag } from "react-icons/bi";
import {
  FiChevronRight,
  FiDollarSign,
  FiBriefcase,
  FiTrendingDown,
} from "react-icons/fi";
import { useNavigate } from "react-router-dom";

const Trending = () => {
  const navigate = useNavigate();
  const [activeItem, setActiveItem] = useState(null);
  const [hoveredItem, setHoveredItem] = useState(null);
  
  // Define which item codes have navigation functions
  const itemsWithNavigation = ["1005", "1006", "1007", "1008", "1009",];
  
  const categories = [
    {
      title: "Charts",
      icon: <BiPurchaseTag className="w-6 h-6 text-blue-600" />,
      color: "border-blue-200",
      items: [
        { code: "1005", label: "PO Charts" },
        { code: "1006", label: "Sales Charts" },
        { code: "1007", label: "Invoices Charts" },
        { code: "1008", label: "Revenue Charts" },
        { code: "1009", label: "Sales tax Charts" },
      ],
    },
 
  ];

  const handleNavigation = (item) => {
    // Only set active state for items with navigation
    if (itemsWithNavigation.includes(item.code)) {
      setActiveItem(item.code);
      
      // Navigate based on the item code
      switch(item.code) {
        case "1005":
          navigate(`/PoCharts`);
          break;
        case "202":
          navigate(`/purchaseUnBrandedReport`);
          break;
        case "203":
          navigate(`/purchaseFromTerminalReport`);
          break;
        case "204":
          navigate(`/purchaseByFuelGradeReport`);
          break;
        case "101":
          navigate(`/searchPoReport`);
          break;
        case "102":
          navigate(`/myPoReport`);
          break;
        case "103":
          navigate(`/poByBrandReport`);
          break;
        case "104":
          navigate(`/poByUnBrandReport`);
          break;
        default:
          console.log(`No navigation defined for item code: ${item.code}`);
      }
    } else {
      console.log(`Item ${item.code} has no navigation function`);
    }
  };

  return (
    <div className="w-full px-6 py-4 mt-5 bg-gray-50">
      <div className="max-w-6xl mx-auto">
        
        <div className="grid grid-cols-4 gap-6">
          {categories.map((category, idx) => (
            <div key={idx}>
              <div className="flex items-center space-x-3 mb-5 pb-2 border-b">
                <div className="p-2 bg-blue-50 rounded-full">
                  {category.icon}
                </div>
                <h2 className="text-lg font-semibold text-gray-900">
                  {category.title}
                </h2>
              </div>
              <div className="space-y-3">
                {category.items.map((item, itemIdx) => {
                  const isActive = activeItem === item.code;
                  const isHovered = hoveredItem === `${item.code}-${idx}`;
                  const hasNavigation = itemsWithNavigation.includes(item.code);
                  
                  return (
                    <div
                      key={itemIdx}
                      onClick={() => handleNavigation(item)}
                      onMouseEnter={() => setHoveredItem(`${item.code}-${idx}`)}
                      onMouseLeave={() => setHoveredItem(null)}
                      style={{ 
                        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                        transform: isHovered ? 'translateY(-2px)' : 'none',
                        transition: 'all 0.3s ease'
                      }}
                      className={`
                        border rounded-md p-3 text-left
                        flex items-center cursor-pointer
                        ${isActive 
                          ? `bg-white border-l-4 border-l-blue-600`
                          : `bg-white hover:border-l-4 hover:border-l-blue-600 ${isHovered ? 'border-l-4 border-l-blue-600' : ''}`
                        }
                      `}
                    >
                      <div className={`w-10 font-mono text-sm font-medium ${isHovered || isActive ? 'text-black-500' : 'text-black-700'}`}>
                        {item.code}
                      </div>
                      <div className={`flex-grow text-sm font-medium ${isHovered || isActive ? 'text-gray-500' : 'text-gray-700'}`}>
                        {item.label}
                      </div>
                      <FiChevronRight 
                        className={`w-5 h-5 ${isActive ? 'text-blue-500 opacity-100' : isHovered ? 'text-blue-500 opacity-100' : 'opacity-0'}`}
                        style={{ transition: 'all 0.2s ease' }} 
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Trending;
import React, {useState} from 'react'
import { useNavigate, Link } from "react-router-dom";
import PurchaseReport from './PurchaseReport';
import Sales from './Sales/Sales';
import InvoiceReport from './Invoice/InvoiceReport';
import SalesTaxCustomers from './SalesTaxCustomers';
import Vendors from './Vendors';
import Customer from './Customer';
import UsersDashBoard from '../Admin/UsersDashBoard';
import UsersDashBoardScreen from './UserDashBoard';

function HomeSetup() {
      const [activeItem, setActiveItem] = useState("View");

      const renderPage = () => {
        switch (activeItem) {
          case "View":
            return <SalesTaxCustomers/>;
          case "Download":
            return <Vendors/>;
          case "Invoice":
            return <Customer/>;
            case "Users":
                return <UsersDashBoardScreen/>;
          default:
            return <SalesTaxCustomers/>;
        }
      };
    
          const handleMenuClick = (item) => {
            setActiveItem(item);
          };

  return (
    <div>
       <div className="flex mb-6 justify-center p-4 rounded-md font-bold text-md items-center
        w-full mx-auto pb-2 pt-0 bg-grey-100">
      {/* View */}
      <div
        className={`flex items-center cursor-pointer px-4 py-1 ${
          activeItem === "View" ? "border-b-2 border-green-500 text-green-600" : "text-gray-600"
        } border-b border-gray-300  `}
        onClick={() => {handleMenuClick("View"); }}
      >
        {/* <FaEye className="mr-2" /> */}
        <span>Sales tax</span>
      </div>

      <div
        className={`flex items-center cursor-pointer px-4 py-1 ${
          activeItem === "Download" ? "border-b-2 border-green-500 text-green-600" : "text-gray-600"
        } border-b border-gray-300 `}
        onClick={() =>{handleMenuClick("Download"); }}
      >
        {/* <FaDownload className="mr-2" /> */}
        <span>Vendors</span>
      </div>
      <div
        className={`flex items-center cursor-pointer px-4 py-1 ${
          activeItem === "Invoice" ? "border-b-2 border-green-500 text-green-600" : "text-gray-600"
        } border-b border-gray-300 `}
        onClick={() =>{handleMenuClick("Invoice");}}
      >
        {/* <FaDownload className="mr-2" /> */}
        <span>Customers</span>
      </div>
      <div
        className={`flex items-center cursor-pointer px-4 py-1 ${
          activeItem === "Users" ? "border-b-2 border-green-500 text-green-600" : "text-gray-600"
        } border-b border-gray-300 `}
        onClick={() =>{handleMenuClick("Users");}}
      >
        {/* <FaDownload className="mr-2" /> */}
        <span>Users</span>
      </div>

    </div>

      {/* Main Content */}
      <div className="w-full p-4">
        {renderPage()}
      </div>
    </div>
  )
}

export default HomeSetup

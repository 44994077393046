import React, { useState, useEffect } from "react";
import { BiSolidUser } from "react-icons/bi";
import { useNavigate, useParams, useLocation, useSearchParams } from "react-router-dom";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import { addToast } from "./ToastBox/ToastBoxMessageSlice";
import { useDispatch } from "react-redux";
import config from "../../config/config";
import ToastNotificationBox from "./ToastBox/ToastNotificationBox";
import { Modal, Button } from "flowbite-react";
import FullScreenLoader from './FullScreenLoader';

const SalesTaxViewEditPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { userId: paramuserid } = useParams();
  const user_id = paramuserid;
 
  const [loading, setLoading] = useState(false);
  const [activeItem, setActiveItem] = useState("View");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [invalidPhoneNumber, setInvalidPhoneNumber] = useState("");
  const [userData, setUserData] = useState(null);
  const [email_id, setEmail_id] = useState(""); // State to store email_id
  const [searchParams] = useSearchParams();
  
  const baseURL = process.env.NODE_ENV === "production" 
    ? config.production.baseURL 
    : config.development.baseURL;

  const phoneRegExp = /^\(\d{3}\) \d{3}-\d{4}$/;
  const emailRegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const formatPhoneNumber = (value) => {
    if (!value) return "";
    const cleaned = ('' + value).replace(/\D/g, '').slice(0, 10);
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    return match ? `(${match[1]}) ${match[2]}-${match[3]}` : cleaned;
  };

  const handlePhoneNumberChange = (e) => {
    const phoneNumber = e.target.value.replace(/[-()]/g, '').slice(0, 10);
    const formattedValue = formatPhoneNumber(phoneNumber);

    if (formattedValue === "") {
      setInvalidPhoneNumber("");
    } else if (!/[1-9]/.test(phoneNumber)) {
      setInvalidPhoneNumber("Invalid number");
    } else if (formattedValue.length < 14) {
      setInvalidPhoneNumber("Phone number must be at least 10 characters");
    } else {
      setInvalidPhoneNumber("");
    }

    formik.setFieldValue("mobileNumber", formattedValue);
  };

  const formik = useFormik({
    initialValues: {
      userName: "",
      email: "",
      mobileNumber: "",
    },
    validationSchema: Yup.object({
      userName: Yup.string().required("User Name is required"),
      email: Yup.string()
        .matches(emailRegExp, "Invalid email address")
        .required("Email is required"),
      mobileNumber: Yup.string()
        .required("Contact Number is required"),
    }),
    onSubmit: async (values) => {
      try {
        setLoading(true);
        // Use the updated API endpoint - calling the handleEditUser function
        handleEditUser(values);
      } catch (error) {
        console.error("Error in formik submit:", error);
        dispatch(addToast({ type: 'danger', message: 'An error occurred during submission' }));
        setLoading(false);
      }
    },
  });

  const handleMenuClick = (item) => {
    setActiveItem(item);
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      const businessId = localStorage.getItem("business_id");

      const response = await axios.delete(
        `${baseURL}/${btoa("user/deleteuser")}/${btoa(businessId)}/${btoa(user_id)}`
      );

      if (response.data) {
        dispatch(addToast({ type: 'danger', message: 'User deleted successfully' }));
        navigate("/salesTaxCustomers");
      }
    } catch (error) {
      dispatch(addToast({ type: 'danger', message: 'Failed to delete user' }));
    } finally {
      setLoading(false);
    }
  };

  const fetchUserData = async () => {
    try {
      setLoading(true);
      const businessId = localStorage.getItem("business_id");
      const response = await axios.get(
        `${baseURL}/${btoa("user/viewuser")}/${btoa(businessId)}/${btoa(user_id)}`
      );

      if (response.data && response.data.userData && response.data.userData.length > 0) {
        const userInfo = response.data.userData[0];
        setUserData(userInfo);
        
        // Store email_id for edit functionality
        setEmail_id(userInfo.contact_email || "");
        
        formik.setValues({
          userName: userInfo.contact_name || "",
          email: userInfo.contact_email || "",
          mobileNumber: formatPhoneNumber(userInfo.contact_no || ""),
        });
        console.log('>>>>>>userData', userInfo);
      } else {
        dispatch(addToast({ type: 'warning', message: 'No user data found' }));
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      dispatch(addToast({ type: 'danger', message: 'Failed to fetch user data' }));
    } finally {
      setLoading(false);
    }
  };

  const handleEditUser = async (values) => {
    try {
      const endpoint = `${baseURL}/${btoa("user/update")}`;
      
      // Prepare data according to API requirements - using the email_id from state
      const requestData = {
        edit_userId: user_id,
        email_db: email_id, // Use the email_id from state
        edit_contactPersonName: values.userName,
        edit_contactPersonEmail: values.email,
        edit_contactPersonPhoneNumber: values.mobileNumber
      };
      
      console.log("Sending update request to:", endpoint);
      console.log("With data:", requestData);
      
      // Make the API call
      const response = await axios.post(endpoint, requestData);
      
      console.log("Update response:", response);
      
      if (response.data && response.data.success) {
        // Success case
        const successMessage = `${values.userName}, details updated successfully`;
        dispatch(addToast({ type: 'success', message: successMessage }));
        setActiveItem("View");
        // Refresh user data
        fetchUserData();
      } else {
        // API returned but with error
        const errorMsg = response.data?.message || 'User update failed';
        dispatch(addToast({ type: 'danger', message: errorMsg }));
        navigate("/salesTaxCustomers");
      }
    } catch (error) {
      console.error("Error updating user:", error);
      const errorMessage = error.response?.data?.message || 'Failed to update user';
      dispatch(addToast({ type: 'danger', message: errorMessage }));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user_id) {
      fetchUserData();
    }
  }, [user_id]);

  return (
    <>
      {loading && <FullScreenLoader />}
      
      <nav aria-label="breadcrumb">
        <ol className="flex space-x-2 text-md font-bold text-gray-700 mt-[20px] font-[15px]">
          <li className="font-[15px]">Sales Tax</li>
          <li><span className="text-gray-400 font-[15px]">{">>"}</span></li>
          <li className="font-[15px]">
            {activeItem === "View" ? "View Sales Tax" : activeItem === "Edit" ? "Edit Sales Tax" : "Sales Tax Details"}
          </li>
        </ol>
      </nav>

      <form className="space-y-3" onSubmit={formik.handleSubmit}>
        <div className="flex mb-6 justify-center rounded-md font-bold text-md items-center w-full mx-auto">
          <div
            className={`flex items-center cursor-pointer px-4 py-1 ${
              activeItem === "View" ? "border-b-2 border-green-500 text-green-600" : "text-gray-600"
            } border-b border-gray-300`}
            onClick={() => handleMenuClick("View")}
          >
            <span>View</span>
          </div>

          <div
            className={`flex items-center cursor-pointer px-4 py-1 ${
              activeItem === "Edit" ? "border-b-2 border-green-500 text-green-600" : "text-gray-600"
            } border-b border-gray-300`}
            onClick={() => handleMenuClick("Edit")}
          >
            <span>Edit</span>
          </div>

          <div
            className={`flex items-center cursor-pointer px-4 py-1 ${
              activeItem === "Delete" ? "border-b-2 border-green-500 text-green-600" : "text-gray-600"
            } border-b border-gray-300`}
            onClick={() => setIsDeleteModalOpen(true)}
          >
            <span>Delete</span>
          </div>
        </div>

        <div className="flex flex-col items-center justify-center mt-5">
          <ToastNotificationBox className="z-58880" />

          <div className="flex justify-between bg-white rounded-lg p-8 w-[880px] mx-auto" 
               style={{ boxShadow: "0 2px 10px rgba(0, 0, 0, 0.3)" }}>
            <div className="flex flex md:flex gap-8">
              <div className="flex flex-col items-center justify-center space-y-4 w-[300px]">
                <div className="w-16 h-16 bg-emerald-500 rounded-full flex items-center justify-center">
                  <BiSolidUser className="text-green-500 rounded-full p-2" size={80} />
                </div>
                <h2 className="text-lg font-medium text-gray-700">
                  {activeItem === "View" ? "User Information" : "Edit User"}
                </h2>
              </div>

              <div className="space-y-4">
                {activeItem === "View" && userData ? (
                  <>
                    <div className="space-y-1">
                      <label className="block text-sm font-medium text-gray-700">
                        User Name
                      </label>
                      <input
                        type="text"
                        className="w-full p-1 border border-gray-300 rounded-md"
                        value={userData.contact_name || ""}
                        disabled={true}
                      />
                    </div>
                    
                    <div className="space-y-1">
                      <label className="block text-sm font-medium text-gray-700">
                        Contact Email
                      </label>
                      <input
                        type="text"
                        className="w-full p-1 border border-gray-300 rounded-md"
                        value={userData.contact_email || ""}
                        disabled={true}
                      />
                    </div>
                    
                    <div className="space-y-1">
                      <label className="block text-sm font-medium text-gray-700">
                        Contact Number
                      </label>
                      <input
                        type="text"
                        className="w-full p-1 border border-gray-300 rounded-md"
                        value={formatPhoneNumber(userData.contact_no) || ""}
                        disabled={true}
                      />
                    </div>
                                    
                  </>
                ) : (
                  <>
                    <div className="space-y-1">
                      <label className="block text-sm font-medium text-gray-700">
                        User Name <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="userName"
                        placeholder="Enter User Name"
                        className="w-full p-1 border border-gray-300 rounded-md"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.userName}
                        disabled={activeItem === "View"}
                      />
                      {formik.touched.userName && formik.errors.userName && (
                        <p className="text-red-500 text-sm">{formik.errors.userName}</p>
                      )}
                    </div>

                    <div className="space-y-1">
                      <label className="block text-sm font-medium text-gray-700">
                        Contact Email <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="email"
                        name="email"
                        placeholder="Enter Contact Email"
                        className="w-full p-1 border border-gray-300 rounded-md"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                        disabled={activeItem === "View"}
                      />
                      {formik.touched.email && formik.errors.email && (
                        <p className="text-red-500 text-sm">{formik.errors.email}</p>
                      )}
                    </div>

                    <div className="space-y-1">
                      <label className="block text-sm font-medium text-gray-700">
                        Contact Number <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="mobileNumber"
                        placeholder="(XXX) XXX-XXXX"
                        className="w-full p-1 border border-gray-300 rounded-md"
                        onChange={handlePhoneNumberChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.mobileNumber}
                        maxLength={14}
                        disabled={activeItem === "View"}
                      />
                      {formik.touched.mobileNumber && formik.errors.mobileNumber && (
                        <p className="text-red-500 text-sm">{formik.errors.mobileNumber}</p>
                      )}
                      {invalidPhoneNumber && (
                        <p className="text-red-500 text-sm">{invalidPhoneNumber}</p>
                      )}
                    </div>
                  </>
                )}

                {activeItem !== "View" && (
                  <div className="flex justify-end gap-4 mt-10">
                    <button
                      type="submit"
                      disabled={!formik.isValid || !formik.dirty || invalidPhoneNumber !== ""}
                      className={`w-40 px-12 py-1 border-2 border-blue-500 text-blue-500 hover:font-bold rounded-md
                        hover:bg-blue-700 hover:text-white uppercase ${
                          !formik.isValid || !formik.dirty || invalidPhoneNumber !== ""
                            ? 'bg-gray-100 text-gray-500 hover:bg-gray-100 hover:text-gray-500 focus:none focus:outline-none font-bold hover:none'
                            : ''
                        }`}
                    >
                      {loading ? "Saving..." : "SAVE"}
                    </button>
                    <button
                      type="button"
                      onClick={() => navigate('/UserDashBoard')}
                      className="w-40 px-12 py-1 border-2 border-red-500 text-red-500 hover:font-bold hover:text-white rounded-md hover:bg-red-700 uppercase"
                    >
                      CANCEL
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>

      {/* Delete Confirmation Modal */}
      <Modal
        show={isDeleteModalOpen}
        size="md"
        popup
        onClose={() => setIsDeleteModalOpen(false)}
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <h3 className="mb-5 text-lg font-normal text-gray-500">
              Are you sure you want to delete
              <span className="block uppercase p-2 font-semibold text-red-700 underline underline-offset-8">
                "{userData?.contact_name || formik.values.userName}"
              </span>
            </h3>
            <div className="flex justify-center gap-4">
              <Button color="failure" onClick={() => handleDelete()} disabled={loading}>
                {loading ? "Deleting..." : "Yes, I'm sure"}
              </Button>
              <Button color="gray" onClick={() => setIsDeleteModalOpen(false)}>
                No, cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SalesTaxViewEditPage;
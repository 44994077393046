import React, { useState, useMemo, useEffect, useRef } from "react";
// import DatePicker from 'react-datepicker';
// import "react-datepicker/dist/react-datepicker.css";
import "../../components/pages/Css/viewbusinessusers.css";

// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DatePicker, Space } from "antd";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FaChevronLeft, FaChevronRight, FaDownload } from "react-icons/fa";
import { Tooltip } from "react-tippy";
import { AiFillEye } from "react-icons/ai";
import { FiDownload } from "react-icons/fi";
import dayjs from "dayjs";
import { FaArrowDown } from "react-icons/fa"; // Import the down arrow icon
import { MdFilterList } from "react-icons/md";

import TextField from "@mui/material/TextField"; // Make sure this import is at the top
import { FaSearch, FaCalendarAlt } from "react-icons/fa";
import {
  createColumnHelper,
  useReactTable,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  // getPaginationRowModel,
  getSortedRowModel
} from "@tanstack/react-table";
import { useSelector, useDispatch } from "react-redux";
import { FaArrowDownLong, FaArrowUpLong } from "react-icons/fa6";
import IndeterminateCheckbox from "./IndeterminateCheckbox";
import { MdNavigateNext } from "react-icons/md";
import { MdNavigateBefore } from "react-icons/md";
import { FaEye, FaTrashAlt } from "react-icons/fa";
import config from "../../config/config";
import axios from "axios";
import axiosInstance from "../../authentication/axiosInstance";
import { BorderColor, Receipt } from "@mui/icons-material";
import { toast } from "react-toastify";
import { addPurchases, deleteAllPurchases } from "../../store/purchasesSlice";
import PurchaseImagesViewModel from "../models/PurchaseImagesViewModel";
import PopUpModalForDeletePurchases from "../models/PurchasesDeleteModel";
import ScrollingMessage from "./ScrollingMessage";
import FileUploadModal from "./FileUploadModal";
import { useNavigate, Link } from "react-router-dom";
import FullScreenLoader from "./FullScreenLoader";
import outlined from "@material-tailwind/react/theme/components/timeline/timelineIconColors/outlined";
import { number } from "yup";
import { addToast } from "./ToastBox/ToastBoxMessageSlice";
import { FaHome } from "react-icons/fa";

const Purchases = () => {
  const navigate = useNavigate();
  const baseURL =
    process.env.NODE_ENV === "production"
      ? config.production.baseURL
      : config.development.baseURL;
      const [deletionCount, setDeletionCount] = useState(0);

      const handleSuccessfulDeletion = () => {
        setDeletionCount(prev => prev + 1);
      };
  const columnHelper = createColumnHelper();
  const [isModalOpencard, setIsModalOpencard] = useState(false);
  const [activeItem, setActiveItem] = useState("View");
  const [activeTab, setActiveTab] = useState("total gallons");

  const handleMenuClick = (item) => {
    setActiveItem(item);
  };

  const [isCostToolTip, setIsCostToolTip] = useState(false);

  const [amountClicked, setamountClicked] = useState(false);
  const [costClicked, setcostClicked] = useState(false);
  const [isSubtotalExpanded, setSubtotalExpanded] = useState(false);
  const [isTaxesExpanded, setTaxesExpanded] = useState(false);

  const [productSubTotal, setProductSubTotal] = useState(0);
  const toggleSubtotal = (e) => {
    e.preventDefault();
    setSubtotalExpanded(!isSubtotalExpanded); // Toggle Subtotal
    setTaxesExpanded(false); // Ensure Taxes is closed
  };

  const toggleTaxes = (e) => {
    e.preventDefault();
    setTaxesExpanded(!isTaxesExpanded); // Toggle Taxes
    setSubtotalExpanded(false); // Ensure Subtotal is closed
  };

  // const [fuelInputStyles, setFuelInputStyles] = useState('');
  // const [dateinputstyles, setdateInputstyles] = useState('');
  // const [enterinvoiceinputstyles, setenterinputinvoicestyles] = useState('');
  const [isfilterOpen, setIsfilterOpen] = useState(false);

  // Data for the modal
  const gallonDetails = [
    { name: "Sunoco", value: 5 },
    { name: "Gulf", value: 7 },
    { name: "Citgo", value: 8 }
  ];
  const [selectedFuelBrand, setSelectedFuelBrand] = useState("");
  const [selectedBrandName, setSelectedBrandName] = useState("all");
  const [productOptions, setProductOptions] = useState([]);
  const [filteredStatus, setFilteredStatus] = useState("all");
  const [selectedInvoiceNumber, setSelectedInvoiceNumber] = useState("");
  const [clicked, setClicked] = useState(null);

  const [selectedInvoiceNumberforModel, setSelectedInvoiceNumberforModel] =
    useState("");

  const [value, setValue] = React.useState("draft");
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);

  const handleChange = (newValue) => {
    // if(newValue === "overdue") return;
    setValue(newValue);
    setFilteredStatus(newValue);
  };
  const [selectedDateFrom, setSelectedDateFrom] = useState(dayjs());
  const [error, setError] = useState(false);
  const handleDateChangeFrom = (from) => {
    if (!from || !dayjs(from).isValid()) {
      // If the date is invalid or null, clear the state
      setSelectedDateFrom(null);
      return;
    }
    setSelectedDateFrom(from);
    setError(false);
  };
  const handleIconClick = (ref) => {
    if (ref.current) {
      ref.current.setFocus();
    }
  };
  const [selectedDateTo, setSelectedDateTo] = useState(null);

  const handleDateChangeTo = (to) => {
    if (!to || !dayjs(to).isValid()) {
      // If the date is invalid or null, clear the state
      setSelectedDateTo(null);
      return;
    }
    console.log(to, "selectedDateTo.......");

    setSelectedDateTo(to);
  };
  console.log(selectedDateTo, "selectedDateTo");
  const datePickerFromRef = useRef(null);
  const datePickerToRef = useRef(null);

  // This function will be passed to the child to handle the selected fuel brand
  const handleFuelBrandChange = (fuelBrand) => {
    setSelectedFuelBrand(fuelBrand.value);
    setSelectedText(fuelBrand.text);
  };
  const [createdDate, setCreatedOnDate] = useState(new Date());
  const [uploadModal, setUploadModal] = useState(false);

  const [currentMonthYear, setCurrentMonthYear] = useState("");
  const [fileName, setFileName] = useState(null);
  const [file, setFile] = useState(null);
  const [rowSelection, setRowSelection] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [sorting, setSorting] = React.useState([]);
  const [filtering, setFiltering] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploadCount, setUploadCount] = useState(0);
  const fileInputRef = useRef(null);
  const [selectedImagePath, setSelectedImagePath] = useState("");
  const [imagesByDate, setImagesByDate] = useState([]);
  const [openDeleteModalId, setOpenDeleteModalId] = useState(null);
  const [columnFilters, setColumnFilters] = useState("");
  const businessId = localStorage.getItem("business_id");
  const [errorMessage, setErrorMessage] = useState("");
  const managerId = localStorage.getItem("user_id");
  const userRole = localStorage.getItem("user_role");
  const dispatch = useDispatch();
  const [selectedBrandId, setSelectedBrandId] = useState("");

  const allPurchases = useSelector((state) => state.purchase.allPurchases);
  console.log("all purchases", allPurchases);
  const handleChangeFuelBrands = (e) => {
    // const selectedOption = e.target.options[e.target.selectedIndex];
    // setSelectedBrandName(selectedOption.value);
    const selectedOption = e.target.options[e.target.selectedIndex];
    const brandId = selectedOption.value;
    setSelectedBrandName(brandId);
    
    // Store the brand ID separately
    
    // setSelectedText(selectedOption.text);
    setSelectedText(
      selectedOption.value !== "" ? selectedOption.text.toLowerCase() : ""
    );
  };
console.log(selectedBrandId, "selectedBrandId");
  useEffect(() => {
    async function fetchPurchases() {
      setloading(true);
      try {
        const response = await axios.post(
          `${baseURL}${btoa("purchase/view")}`,
          {
            businessId: businessId,
            managerId: managerId,
            brandId: selectedBrandName,
            selectedBrandID: selectedBrandId,
            // fromMdy:(selectedDateFrom) === null ? '' : formatDatefordatepicker(selectedDateFrom) ,
            //   toMdy:(selectedDateTo) === null ? '': formatDatefordatepicker(selectedDateTo),
            monthName: formattedMonthName(),
            year: formattedYear(),
            brandName: selectedText,
            userRole: userRole
          }
        );
        console.log(response, "purchase response");
        dispatch(deleteAllPurchases());
        dispatch(addPurchases(response.data.receiptmages));
        setSellerNames(response.data.brandsAndTotals);
        setrightcardata(response.data);
        setSelectedBrandId(response.data.brandsAndTotals[0].brand_id);
        setMonthaname(response.data.monthName);
        setYear(response.data.year);
        setSunocoGallons(response.data.sunoco_totals[0].total_gallons);
        setGulfGallons(response.data.gulf_totals[0].total_gallons);
        console.log("fetch images", response);
      } catch (error) {
        console.error("Error fetching receipt images :", error);
      } finally {
        setloading(false);
      }
    }
    fetchPurchases();
  }, [uploadCount, selectedBrandName, selectedDateFrom, selectedInvoiceNumber, deletionCount]);

  const expandedRowRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      const expandedRowElement = document.querySelector(
        '[data-expanded="true"]'
      );
      if (expandedRowElement && !expandedRowElement.contains(event.target)) {
        setExpandedRow(null); // Close the expanded row
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    async function fetchPurchases() {
      // setloading(true)

      try {
        const response = await axios.post(
          `${baseURL}${btoa("purchase/view")}`,
          {
            businessId: businessId,
            managerId: managerId,
            brandId: "all",
            selectedBrandID: selectedBrandId,
            // fromMdy:(selectedDateFrom) === null ? '' : formatDatefordatepicker(selectedDateFrom) ,
            //   toMdy:(selectedDateTo) === null ? '': formatDatefordatepicker(selectedDateTo),
            monthName: null,
            year: null,
            brandName: "all",
            userRole: userRole
          }
        );
        console.log(response, "purchase response");
        dispatch(deleteAllPurchases());
        dispatch(addPurchases(response.data.receiptmages));
        setSellerNames(response.data.brandsAndTotals);
        setrightcardata(response.data);
        setMonthaname(response.data.monthName);
        setYear(response.data.year);
        setSunocoGallons(response.data.sunoco_totals[0].total_gallons);
        setGulfGallons(response.data.gulf_totals[0].total_gallons);
        console.log("fetch images", response);
      } catch (error) {
        console.error("Error fetching receipt images :", error);
      } finally {
        setloading(false);
      }
    }
    fetchPurchases();
  }, [uploadCount]);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const formattedUploadDate =
    createdDate instanceof Date && !isNaN(createdDate.getTime())
      ? createdDate.toISOString()
      : new Date().toISOString();

  useEffect(() => {
    const currentDate = new Date();
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];
    const currentMonth = monthNames[currentDate.getMonth()];
    const currentYear = currentDate.getFullYear();
    setCurrentMonthYear(`${currentMonth} ${currentYear}`);
  }, []);

  const handleDateChange = (date) => {
    setCreatedOnDate(date);
  };

  const handlePrevDay = () => {
    setCreatedOnDate(new Date(createdDate.setDate(createdDate.getDate() - 1)));
  };

  const handleNextDay = () => {
    const today = new Date();
    const isToday =
      createdDate.getDate() === today.getDate() &&
      createdDate.getMonth() === today.getMonth() &&
      createdDate.getFullYear() === today.getFullYear();
    if (isToday) {
      return;
    }
    setCreatedOnDate(new Date(createdDate.setDate(createdDate.getDate() + 1)));
  };

  function formatDatefordatepicker(date) {
    // const day = date.getDate();
    // const month = date.getMonth() + 1; // Months are zero-based
    // const year = date.getFullYear().toString(); // Extract last two digits of year
    // return `${month}-${day}-${year}`;
    const year = date.$y;
    const month = String(date.$M + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(date.$D).padStart(2, "0");
    return `${month}-${day}-${year}`;
  }

  const formatDate = (dateStr) => {
    const [year, month, day] = dateStr.split("-");
    return `${month}/${day}/${year}`;
  };
  const formatDateForApi = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const capitalizeFirstLetterWordWords = (str) => {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };
  const lowercaseAllWords = (str) => {
    return str.toLowerCase();
  };

  const handleUploadImage = async (e) => {
    if (isClicked) return;
    setSelectedDateFrom(dayjs());

    console.log("upload image", file);
    // console.log("date date" , formatDateForApi(createdDate));
    e.preventDefault();
    if (!file) {
      alert("Please select a file first!");
      return;
    }
    const formData = new FormData();
    formData.append("receipt", file);
    formData.append("businessId", localStorage.getItem("business_id"));
    formData.append("brandId", selectedFuelBrand);

    formData.append("managerId", localStorage.getItem("user_id"));
    formData.append("userRole", localStorage.getItem("user_role"));
    formData.append("selectedDate", formatDateForApi(createdDate));
    formData.append("businessName", "Hp Gas");

    setloading(true);
    setIsClicked(true);

    try {
      const response = await axiosInstance.post(
        `${baseURL}${btoa("purchase/create")}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
            // 'Authorization': `${token}`,
          }
        }
      );
      console.log(response, "response");
      if (response.data.success === true) {
        setFile(null);
        setFileName("");
        fileInputRef.current.value = "";
        // toast.success(`${response.data.message}` , {autoClose : 2500})
        dispatch(
          addToast({ type: "success", message: `${response.data.message}` })
        );

        // dispatch(addToast({ type: 'success', message: `${response.data.message}` }));
        setUploadCount((prevCount) => prevCount + 1);
        setCreatedOnDate(new Date());
        // setSelectedDateFrom(dayjs())
      }
      // if(response.data.success === false){
      //   setFile(null);
      //   setFileName('');
      //   toast.error(`Image uploading failed` , {autoClose : 2500})
      // }

      // console.log("upload image response", response);,
    } catch (error) {
      if (error.response.data.success === false) {
        setFile(null);
        setFileName("");
        // toast.error(`${error.response.data.message}` , {autoClose : 2500})
        dispatch(
          addToast({
            type: "danger",
            message: `${error.response.data.message}`
          })
        );
      }
      console.log("upload image failed", error);
    } finally {
      setUploadModal(false);
      setloading(false);
      setIsClicked(false);
    }
  };

  const tableRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (tableRef.current && !tableRef.current.contains(event.target)) {
        setExpandedRow(null); // Close all expanded rows
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const data = useMemo(() => {
    return allPurchases.map((purchase, index) => ({
      ...purchase
      // sl_no: index + 1,
      // brand_invoice_number: formatDate(purchase.brand_invoice_number),
      // image_name: capitalizeFirstLetterWordWords(purchase.image_name),
      // contact_name : capitalizeFirstLetterWordWords(vehicle.contact_name),
      // contact_email : lowercaseAllWords(vehicle.contact_email)
    }));
  }, [allPurchases]);
  console.log(data, "dataforpurchase table");
  useEffect(() => {
    // alert(formatDateForApi(createdDate))
    async function fetchImageByDate() {
      try {
        const response = await axios.get(
          `${baseURL}${btoa("purchase/fetchimagebydate")}/${btoa(
            formatDateForApi(createdDate)
          )}/${btoa(businessId)}}`
        );
        setImagesByDate(response.data.image_data);
        // console.log("images by date" , response.data.image_data);
      } catch (error) {
        console.error("error fetching images by date", error);
      }
    }
    fetchImageByDate();
  }, [createdDate, uploadCount]);

  const handleView = (row) => {
    // console.log('View action for row:', row);
    // console.log('Constructed Image Path:', imagePath);
    setSelectedInvoiceNumberforModel(row);
    setSelectedImagePath(row);
    setIsModalOpen(true);

    // Add your view logic here
  };
  const [deletedRow, selectedDeleteRow] = useState("");
  const handleDelete = (row, row1) => {
    setOpenDeleteModalId(row);
    selectedDeleteRow(row1);
    console.log("Delete action for row:", row1);
  };

  const handleDownload = (fileName) => {
    const businessName = localStorage.getItem('business_name').replace(/\s+/g, '_')

    const downloadUrl = `${baseURL}uploads/docs/receipts/${businessName}/${fileName}.pdf`;
    window.open(downloadUrl, "_blank");
  };

  const isRowCheckboxDisabled = (row) => {
    if (rowSelection[row.id]) {
      return false;
    } else {
      // If the row is not selected, check if any other rows are selected
      return Object.values(rowSelection).some((selected) => selected);
    }
  };
  const formatNumberWithCommas = (number) => {
    return new Intl.NumberFormat("en-US").format(number);
  };
  const columns = [
    // {
    //   id: "checkbox",
    //   accessor: "",
    //   cell: ({ row }) => (
    //     <IndeterminateCheckbox
    //       {...{
    //         checked: row.getIsSelected(),
    //         disabled: isRowCheckboxDisabled(row),
    //         indeterminate: row.getIsSomeSelected(),
    //         onChange: row.getToggleSelectedHandler(),
    //         className: isRowCheckboxDisabled(row) ? "disabled-checkbox" : "",
    //       }}
    //     />
    //   ),
    //   enableColumnFilter: false, // Disable filter for checkbox column
    //   enableSorting: false, // Disable sorting for checkbox column
    // },
    {
      accessorFn: (row, i) => (row ? i + 1 : ""),
      header: "#"
    },

    // columnHelper.accessor("brand_logo", {
    //   header: <div className="text-center">Brand </div>,
    //   cell: (props) => {
    //     // const brandId = props.row.original.brand_id;
    //     // const brandLogo = sellerNames[brandId-1]?.brand_logo;

    //     return (
    //       <div className="flex items-center justify-center">
    //         {props.row.original.brand_logo ? (
    //           <img
    //             src={`${baseURL}/img/${props.row.original.brand_logo}`}
    //             alt={` ${props.row.original.seller_name} Logo`}
    //             className="w-14 h-18 object-contain"
    //           />
    //         ) : (
    //           <span className="text-gray-500 italic">No Logo</span> // Fallback in case logo doesn't exist
    //         )}
    //       </div>
    //     );
    //   },
    //   enableColumnFilter: false,
    //   enableSorting: true
    // }),
    columnHelper.accessor("brand_logo", {
      header: <div className="text-center">Brand </div>,
      cell: (props) => {
        return (
          <div className="flex items-center justify-center h-8 w-full"> {/* Fixed container height */}
            {props.row.original.brand_logo ? (
              <img
                src={`${baseURL}/img/${props.row.original.brand_logo}`}
                alt={` ${props.row.original.seller_name} Logo`}
                className="max-h-8 w-14 object-contain" // Use max-h and w-auto to maintain aspect ratio
              />
            ) : (
              <span className="text-gray-500 italic">No Logo</span>
            )}
          </div>
        );
      },
      enableColumnFilter: false,
      enableSorting: true
    }),
    columnHelper.accessor("brand_invoice_number", {
      header: <div className="text-center ml-[10px]"> PO #</div>,
      cell: (props) => (
        <div className="text-center ml-[10px]">
          {props.row.original.brand_invoice_number}
        </div>
      ),
      enableColumnFilter: false,
      enableSorting: true
    }),
    // columnHelper.accessor("brand_invoice_date", {
    //   header: <div className="text-center"> Invoice Date</div>,
    //   cell: (props) => (
    //     <div className="text-center">
    //       { (props.row.original.brand_invoice_date)
    //       }
    //     </div>
    //   ),
    //   enableColumnFilter: false,
    //   enableSorting: true,
    // }),

    // columnHelper.accessor("total_gallons", {
    //   header: <div className="text-center">Gallons</div>,
    //   cell: (props) => (
    //     <div className="text-center">
    //       { (props.row.original.total_gallons)
    //       }
    //     </div>
    //   ),
    //   enableColumnFilter: false,
    //   enableSorting: true,
    // }),

    columnHelper.accessor("brand_invoice_date", {
      header: <div className="text-right"> Po Date</div>,
      cell: (props) => {
        const indianDate = props.row.original.brand_invoice_date;
        const [day, month, year] = indianDate.split("/");
        const date = new Date(`${year}-${month}-${day}`);
        const formattedDate = `${date.toLocaleDateString("en-US", {
          month: "short"
        })}-${date.toLocaleDateString("en-US", {
          day: "2-digit"
        })}-${date.toLocaleDateString("en-US", { year: "numeric" })}`;

        return <div className="">{formattedDate}</div>;
      },
      enableColumnFilter: false,
      enableSorting: true
    }),
    //   columnHelper.accessor("brand_invoice_duedate", {
    //     header: "Due Date",
    //     cell: (props) => {
    //       const indianDate = props.row.original.brand_invoice_duedate;

    // if (!indianDate) {
    //   // If the date is null or undefined, return an empty string
    //   return <div></div>;
    // }
    //       const [day, month, year] = indianDate.split('/');
    //       const date = new Date(`${year}-${month}-${day}`);
    //   const formattedDate = `${date.toLocaleDateString("en-US", { month: 'short' })}-${date.toLocaleDateString("en-US", { day: '2-digit' })}-${date.toLocaleDateString("en-US", { year: 'numeric' })}`;

    // return (
    //         <div >{formattedDate}</div>
    //       );

    //     },
    //     enableColumnFilter: false,
    //     enableSorting: true,
    //   }),
    columnHelper.accessor("total_gallons", {
      header: <div className="text-center">Gallons</div>,
      cell: (props) => (
        <div className="text-center">
          {formatNumberWithCommas(props.row.original.total_gallons)} gl
        </div>
      ),
      enableColumnFilter: false,
      enableSorting: true
    }),
    columnHelper.accessor("total_amount", {
      header: <div className="text-center">Po Amount</div>,
      cell: (props) => (
        <div className="text-center">
          ${" "}
          {Number(props.row.original.total_amount).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}
        </div>
      ),
      enableColumnFilter: false,
      enableSorting: true
    })

    // columnHelper.accessor("image_name", {
    //   header: <div className="text-center">Image Name</div>,
    //   cell: () => <div className="text-center">Fuel Purchased Image</div>,  // Set the cell value to "Fuel Purchase Image"
    // }),
    // columnHelper.accessor('contact_email', {
    //   header: <div className="text-center">Action</div>,
    //   cell: ({ row }) => {
    //     const { img_file, id } = row.original;
    //     const isPdf = img_file.endsWith('.pdf');

    //     return (
    //       <div className="flex justify-center space-x-3">
    //         {isPdf ? (
    //           <button
    //             onClick={() => handleDownload(img_file)}
    //             className="text-gray-500 hover:text-blue-700 hover:bg-blue-200 p-2 rounded-full cursor-pointer"
    //           >
    //             <FaDownload size={20} />
    //           </button>
    //         ) : (
    //           <button
    //             onClick={() => handleView(img_file)}
    //             className="text-gray-500 hover:text-blue-700 hover:bg-blue-200 p-2 rounded-full cursor-pointer"
    //           >
    //             <FaEye size={20} />
    //           </button>
    //         )}
    //         <button
    //           onClick={() => handleDelete(id)}
    //           className="text-gray-500 hover:text-red-700 hover:bg-red-200 p-2 rounded-full cursor-pointer"
    //         >
    //           <FaTrashAlt size={18} />
    //         </button>
    //       </div>
    //     );
    //   },
    //   enableColumnFilter: false,
    //   enableSorting: false,
    // })
  ];

  const handleSortingChange = (columnId) => {
    setSorting((old) => {
      if (old.length && old[0].id === columnId) {
        // Toggle sorting direction
        return [
          {
            id: columnId,
            desc: !old[0].desc
          }
        ];
      }
      // Default sorting to descending initially
      return [{ id: columnId, desc: true }];
    });
  };
  const [sellerNames, setSellerNames] = useState([]);

  const [rightcardata, setrightcardata] = useState([]);

  const [selectedText, setSelectedText] = useState("");
  const [sunocoGallons, setSunocoGallons] = useState("");
  const [GulfGallons, setGulfGallons] = useState("");
  const [monthName, setMonthaname] = useState("");
  const [yearName, setYear] = useState("");

  const [loading, setloading] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const formattedDateTo = selectedDateTo ? selectedDateTo : "";
  const formattedDatefrom = selectedDateFrom ? selectedDateFrom : "";
  // const [year, month, day] = value.split('-');
  const formattedMonthName = () => {
    let [month] = formattedDatefrom.format("MMMM-YYYY").split("-");
    return month;
  };
  const formattedYear = () => {
    let [, year] = formattedDatefrom.format("MMMM-YYYY").split("-");
    return year;
  };

  const colors = [
    "red-500",
    "green-500",
    "yellow-500",
    "purple-500",
    "green-500",
    "[#25caed]"
  ];

  const cardsData =
    sellerNames.length > 0
      ? sellerNames.map((sellerName, index) => ({
          id: sellerName.brand_id,
          brandName: sellerName.seller_name, // Assuming brand_name is a property
          logo: sellerName.brand_logo,
          gallons: sellerName.total_gallons, // Adjust gallons data as needed
          borderColor: colors[index % colors.length],
          total_amount: sellerName.total_amount
        }))
      : [];
  console.log(sellerNames, "sellerNames");

  const finalData = React.useMemo(() => {
    return allPurchases.length > 0 ? allPurchases : [];
  }, [allPurchases]);
  const [expandedRow, setExpandedRow] = useState(null);

  const toggleRow = (id) => {
    setExpandedRow((prevRow) => (prevRow === id ? null : id));
  };

  const table = useReactTable({
    data: finalData || [],
    columns: columns,
    state: {
      rowSelection: rowSelection,
      globalFilter: filtering,
      sorting: sorting
    },
    onSortingChange: setSorting,
    onGlobalFilterChange: setFiltering,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onRowSelectionChange: setRowSelection,
    onColumnFiltersChange: setColumnFilters,
    enableRowSelection: true,
    enableColumnFilter: true
  });
  // const currentPage = table.options.state.pagination.pageIndex;
  const isDeleteDisabled = (row) => {
    // If no fuel grades, don't disable
    if (!row.original.fuelgrades || row.original.fuelgrades.length === 0) {
      return false;
    }

    // Check each fuel grade to see if purchased_gallons !== gross_quantity
    return row.original.fuelgrades.some((fuelgrade) => {
      const purchasedGallons = Number(fuelgrade.purchased_gallons);
      const grossQuantity = Number(fuelgrade.gross_quantity);

      // Return true if they don't match (which means delete should be disabled)
      return purchasedGallons !== grossQuantity;
    });
  };
  return (
    <div className="pb-10 ">
      {loading && <FullScreenLoader />}

      {/* <div className="w-full ml-10">
        <ScrollingMessage />
        </div> */}
      {/* <div>
      <h1 className="font-bold text-xl mt-6"   style={{ 
    maxWidth: '1200px', 
    boxSizing: 'border-box', 
    marginLeft: '42px', 
    color: '#444444' // Lighter black color
  }}>Purchases</h1>
  </div> */}
      <div
        className=" p-4 rounded-lg ml-4 mt-[8px]"
        style={{ display: "none" }}
      >
        <nav aria-label="breadcrumb">
          <ol className="flex space-x-2 text-lg font-bold text-gray-700">
            <li>
              {/* <a  href="/operations" className="text-blue-600 hover:text-blue-800"> */}
              {/* Operations */}
              {/* </a> */}
            </li>
            <li>{/* <span className="text-gray-400">{'>>'}</span> */}</li>
            <li>
              {/* <a href="/purchases" className="text-blue-600 hover:text-blue-800"> */}
              {/* Gas Purchases */}
              {/* </a> */}
            </li>
          </ol>
        </nav>
      </div>
      {/* <div className="flex mb-3 justify-center p-4 rounded-md font-bold text-md items-center w-full mx-auto pt-0 pb-2 custombgColor">
        <div
          className={`flex items-center cursor-pointer px-4 py-1 ${
            activeItem === "View"
              ? "border-b-2 border-green-500 text-green-600"
              : "text-gray-600"
          } border-b border-gray-300`}
          onClick={() => handleMenuClick("View")}
        >
          <span>Purchases</span>
        </div>

        <div
          className={`flex items-center cursor-pointer px-4 py-1 ${
            activeItem === "Download"
              ? "border-b-2 border-green-500 text-green-600"
              : "text-gray-600"
          } border-b border-gray-300`}
          onClick={() => handleMenuClick("Download")}
        >
          <span>Expenses</span>
        </div>

        <div
          className={`flex items-center cursor-pointer px-4 py-1 ${
            activeItem === "Edit"
              ? "border-b-2 border-green-500 text-green-600"
              : "text-gray-600"
          } border-b border-gray-300`}
          onClick={() => handleMenuClick("Edit")}
        >
          <span>Losses</span>
        </div>
        <div
          className={`flex items-center cursor-pointer px-4 py-[7px] ${
            activeItem === "Home"
              ? "border-b-2 border-green-500 text-green-600"
              : "text-gray-600"
          } border-b border-gray-300`}
          onClick={() => {
            handleMenuClick("Home");
            navigate("/");
          }}
        >
          <FaHome className="text-lg" />
        </div>
      </div> */}
      <div className="flex justify-start space-x-4 mt-[20px] ">
        {cardsData.map((card, index) => (
          <div
            key={card.id}
            className={`flex flex-col items-start justify-between w-1/6 bg-white rounded-lg p-4 relative 
      hover:scale-105 hover:border-l-[6px] hover:border-${
        card.borderColor
      } transition-all duration-300 
      ${
        clicked === `card${index + 1}`
          ? `border-l-[6px] border-${card.borderColor}`
          : ""
      }`}
            style={{
              boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.2)",
              height: "90px"
            }}
       
          >
            <div className="flex justify-start w-full">
              <span className="text-sm text-gray-500 font-bold">Gallons</span>
            </div>
            <div className="flex items-center justify-center relative space-x-2">
              {" "}
              {/* Added flex layout here */}
              <h2 className={`text-sm font-bold text-${card.borderColor}`}>
                {formatNumberWithCommas(card.gallons)}
              </h2>
              {/* Down Arrow Icon */}
              <FaArrowDown
                className={`text-${card.borderColor} text-sm mt-1`}
              />
            </div>
            <div className="absolute top-0 right-0 rounded-tl-full flex items-center justify-center">
              <img
                src={`${baseURL}/img/${card.logo}`}
                alt={card.brandName}
                className={`${card.id === "1" && "w-14 h-12"} 
        ${card.id === "2" && "w-12 h-14"} 
        ${card.id === "3" && "w-[80px] h-16 mt-[-8px]"} 
        ${card.id === "4" && "w-14 h-18 "} 
        ${card.id === "5" && "w-14 h-18 mt-2"} 
        ${card.id === "6" && "w-14 h-18"} 
        ${card.id === "7" && "w-14 h-18"} 
        ${card.id === "8" && "w-14 h-18"}
        ${card.id === "9" && "w-14 h-18"}
        object-contain mb-2 mr-3`}
              />
            </div>
          </div>
        ))}
        {/* { userRole === '2' && 
    <>
      <h3 className="text-lg font-semibold text-green-600 mb-4  underline absolute top-[80px] right-[320px] ">
      {monthName && yearName ? `${yearName} ${monthName} Summary` : ''}
    </h3>
    <div className="flex items-end justify-end border border-green-600 rounded-lg absolute right-[320px]">
  <div className="bg-green-100 rounded-lg shadow-md p-4 w-80 text-center">

    <div
      className="relative flex justify-between text-md mb-2 group cursor-pointer text-green-600 w-full"
      onMouseEnter={() => {
        setIsModalOpencard(true);
      }}
      onMouseLeave={() => {
        setIsModalOpencard(false);
      }}

    >
      <span className="font-bold ">Total Gallons:</span>
      <span className="font-bold">
        {formatNumberWithCommas(rightcardata.brandGallonsTotal ? Number(rightcardata.brandGallonsTotal):0)} gl
      
      </span>

      {isModalOpencard &&
      <div className="text-orange-600 border border-orange-600 bg-orange-100 absolute top-[90px] left-[-15px] mb-2 w-[320px] text-black text-sm rounded-lg p-4 shadow-lg   duration-300 z-50">
        <p className="mb-2 text-center font-bold underline ">Gallons Details</p>
        <ul className="space-y-1">
          {cardsData.map((detail, index) => (
            <li key={index} className="flex justify-between">
              <span className='capitalize'>{detail.brandName}:</span>
              <span className="font-normal">
                {formatNumberWithCommas(detail.gallons)} gl
              </span>
            </li>
          ))}
          <li className="flex justify-between font-normal mt-2 border-t border-orange-100 pt-2">
            <span>Total Gallons:</span>
            <span className="border-t border-orange-600 text-right">
            
                      {formatNumberWithCommas(rightcardata.brandGallonsTotal ? Number(rightcardata.brandGallonsTotal) : 0)} gl

            </span>
          </li>
        </ul>
      </div>
      }
    </div>

    <div
      className="relative flex justify-between text-md group cursor-pointer text-green-600"
      onMouseEnter={() => {
        setIsCostToolTip(true);
      }}
      onMouseLeave={() => {
        setIsCostToolTip(false);
      }}
    >
      <span className="font-bold ">Total Cost:</span>
      <span className="font-bold">
        $ {Number(rightcardata.brandAmountsTotal? Number(rightcardata.brandAmountsTotal): 0).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
         
      </span>

      {isCostToolTip && 
      <div className="text-orange-600 border border-orange-600 bg-orange-100 absolute top-[60px] left-[-15px] mb-2 w-[320px] text-black text-sm rounded-lg p-4 shadow-lg duration-300 z-50">
        <p className="mb-2 text-center font-bold underline">Cost Details</p>
        <ul className="space-y-1">
          {cardsData.map((detail, index) => (
            <li key={index} className="flex justify-between">
              <span className='capitalize'>{detail.brandName}:</span>
              <span className="font-normal">
                $ {Number(detail.total_amount).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </span>
            </li>
          ))}
          <li className="flex justify-between font-normal mt-2 border-t border-orange-100 pt-2">
            <span>Total Cost:</span>
            <span className="border-t border-orange-600 text-right">
            
                $ {Number(rightcardata.brandAmountsTotal ? Number(rightcardata.brandAmountsTotal): 0).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
            </span>
          </li>
        </ul>
      </div>
      }
    </div>
  </div>
</div>

</>
} */}
        {/* {userRole === "2" && 

  <div className='flex flex-col w-1/2'>
    <div>
    <h3 className="text-lg font-semibold text-[#25caed] mb-4  underline absolute top-[120px] right-[6%] ">
      {monthName && yearName ? `${yearName} ${monthName ? monthName.substring(0, 3).toUpperCase() : ''} Purchases` : ''}
    </h3>
    </div>
  <div className="lg:w-[18%] rounded-md p-4 mt-[20px]  ml-auto absolute top-[140px] right-[50px] bg-gray-100 border border-gray-300">
    <div className="flex justify-between items-center mb-2">
      <div className="flex items-center gap-2">
        <button onClick={toggleSubtotal} className="text-blue-500">
          {isSubtotalExpanded ? '▼' :  <span className="inline-block text-xl leading-none">►</span>}
        </button>
   
        <span className="text-gray-800 font-medium">Total Gallons</span>
      </div>
      <span className="text-gray-800 font-medium">
        {formatNumberWithCommas(rightcardata.brandGallonsTotal ? Number(rightcardata.brandGallonsTotal) : 0)} gl
      </span>
    </div>

    {isSubtotalExpanded && (
      <>
        <div className="ml-6 mb-4">
          {cardsData.map((detail, index) => (
            <div className="flex justify-between" key={detail.brandName}>
              <span className="capitalize">{detail.brandName}:</span>
              <span className="font-normal">
                {formatNumberWithCommas(detail.gallons)} gl
              </span>
            </div>
          ))}

     
        <li className="flex justify-between font-normal pt-2">
          <span>Total Gallons:</span>
          <span className="border-t border-black  text-right">
          {formatNumberWithCommas(rightcardata.brandGallonsTotal ? Number(rightcardata.brandGallonsTotal) : 0)} gl

          </span>
        </li>
        </div>

      </>
    )}

    <div className=" flex justify-between items-center">
      <div className="flex items-center gap-2">
        <button onClick={toggleTaxes} className="text-blue-500">
          {isTaxesExpanded ? '▼' :     <span className="inline-block text-xl leading-none">►</span>
          }
        </button>
        <span className="text-gray-800 font-medium">Total Cost</span>
      </div>
      <span className="text-gray-800 font-medium">
        $ {Number(rightcardata.brandAmountsTotal ? Number(rightcardata.brandAmountsTotal) : 0).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </span>
    </div>

    {isTaxesExpanded && (
      <div className="ml-6 mt-2">
        {cardsData.map((detail, index) => (
          <div className="flex justify-between" key={detail.po_customertax_name}>
            <span className="capitalize">{detail.brandName}:</span>
            <span className="font-normal">
              $ {Number(detail.total_amount).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </span>
          </div>
        ))}
        <li className="flex justify-between font-normal pt-2">
          <span>Total Cost:</span>
          <span className="border-t border-black  text-right">
            $ {Number(rightcardata.brandAmountsTotal ? Number(rightcardata.brandAmountsTotal) : 0).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </span>
        </li>
      </div>
    )}
  </div>
</div>

} */}
      </div>

      {/* <div className="relative flex justify-end mt-[80px]">
  <button
    className="relative flex items-center font-bold mr-[28px] w-55 border-transparent border-2 text-white bg-[#25caed] hover:scale-105 transition-transform duration-300 ease-in-out focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-base px-4 focus:outline-none"
    type="button"
    onClick={() => setUploadModal(true)}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={3}
      stroke="currentColor"
      className="w-5 h-9 mr-3"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 6v12m6-6H6"
      />
    </svg>
    Upload PO
  </button>
</div> */}

      <div className=" max-w-md mx-auto p-4 pb-1 custombgColor rounded-xl mt-[100px] relative">
        {/* Tabs */}
        {/* <div className="relative flex items-center justify-center space-x-4"> */}
        {/* Full Width Horizontal Line - Now Fully Connected */}
        {/* <div className="absolute inset-0 flex items-center">
      <div className="w-full border-t border-gray-300"></div>
    </div> */}

        {/* Tab Buttons */}
        {/* <div className="relative flex gap-2 bg-white p-1 rounded-full z-10"  style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }} >
            {["Total Gallons", "Total Cost"].map((tab) => (
              <button
                key={tab}
                onClick={() => setActiveTab(tab.toLowerCase())}
                className={`w-32 px-4 py-2 text-sm font-medium rounded-full ${
                  activeTab === tab.toLowerCase()
                    ? "bg-[#25caed] text-white shadow-md"
                    : "text-[#25caed] hover:bg-[#25caed] hover:text-white border border-[#25caed]"
                }`}
              >
                {tab}
              </button>
            ))}
          </div> */}
        {/* </div> */}

        {/* Cash Flow Values */}
        {/* {activeTab.toLowerCase() === "total gallons" && (
          <div className="rounded-full p-2 flex items-center justify-center text-center space-x-6">
            {cardsData.map((detail, index) => (
              <React.Fragment key={index}>
                <div className="flex flex-col items-center ">
                  <p
                    className={`text-md font-bold capitalize ${
                      index === 0
                        ? "text-gray-500"
                        : index === 1
                        ? "text-green-500"
                        : index === 2
                        ? "text-yellow-500"
                        : "text-gray-500"
                    }`}
                  >
                    {detail.brandName}
                  </p>

                  <p
                    className={`text-md font-bold ${
                      index === 0
                        ? "text-gray-500"
                        : index === 1
                        ? "text-green-500"
                        : index === 2
                        ? "text-yellow-500"
                        : "text-gray-500"
                    }`}
                  >
                    {formatNumberWithCommas(Number(detail.gallons) || 0)} gl
                  </p>
                </div>

                <p className="text-2xl font-semibold text-gray-700 flex items-center">
                  {index === cardsData.length - 1 ? "=" : "+"}
                </p>
              </React.Fragment>
            ))}

            <div className="flex flex-col items-center">
              <p className="text-md text-gray-500 font-bold">Total Gallons</p>
              <p className="text-md font-bold text-gray-500">
                {formatNumberWithCommas(
                  rightcardata.brandGallonsTotal
                    ? Number(rightcardata.brandGallonsTotal)
                    : 0
                )}{" "}
                gl
              </p>
            </div>
          </div>
        )} */}

        {/* Total Amount Section */}
        {activeTab.toLowerCase() === "total cost" && (
          <div className="rounded-full p-2 flex items-center justify-center text-center space-x-6">
            {cardsData.map((detail, index) => (
              <React.Fragment key={index}>
                <div className="flex flex-col items-center">
                  {/* Brand Name with Conditional Color */}
                  <p
                    className={`text-md font-bold capitalize ${
                      index === 0
                        ? "text-gray-500"
                        : index === 1
                        ? "text-green-600"
                        : index === 2
                        ? "text-yellow-600"
                        : "text-gray-500"
                    }`}
                  >
                    {detail.brandName}
                  </p>

                  {/* Total Amount with the Same Color */}
                  <p
                    className={`text-md font-bold ${
                      index === 0
                        ? "text-gray-500"
                        : index === 1
                        ? "text-green-600"
                        : index === 2
                        ? "text-yellow-600"
                        : "text-gray-500"
                    }`}
                  >
                    ${" "}
                    {Number(detail.total_amount).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}
                  </p>
                </div>

                {/* Display "+" for all except the last item, which gets "=" */}
                <p className="text-2xl font-semibold text-gray-700 flex items-center">
                  {index === cardsData.length - 1 ? "=" : "+"}
                </p>
              </React.Fragment>
            ))}

            {/* Total Gallons Block */}
            <div className="flex flex-col items-center">
              <p className="text-md text-gray-500 font-bold">Total Amt</p>
              <p className="text-md font-bold text-gray-500">
                ${" "}
                {Number(
                  rightcardata.brandAmountsTotal
                    ? Number(rightcardata.brandAmountsTotal)
                    : 0
                ).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
              </p>
            </div>
          </div>
        )}
      </div>

      <div
        className="relative flex justify-between  mt-10  gap-2 items-center w-full rounded-t-xl py-[8px] px-3 "
        style={{ height: "58px" }}
      >
        {/* Fuel Brand Dropdown */}
        <div className="flex-grow" style={{ width: "250px" }}>
          {/* {isfilterOpen && ( */}
            <select
              id="customerId"
              className="border border-gray-400 text-sm font-medium rounded-lg block
         w-full h-[35px] px-2 text-gray-500 placeholder:text-gray-100"
              onChange={(e) => {
                handleChangeFuelBrands(e);
                // setSelectedText(e.target.value !== "");
              }}
            >
              <option value="">--Select Fuel Brand--</option>
              <option value="all">All Fuel Brands</option>
              {sellerNames.map((seller) => (
                <option key={seller.id} value={seller.id}>
                  {seller.seller_name.charAt(0).toUpperCase() +
                    seller.seller_name.slice(1).toLowerCase()}
                </option>
              ))}
            </select>
          {/* )} */}
        </div>

        {/* Invoice Search */}
        {/* {isfilterOpen && ( */}
          <div className="flex-grow items-center border border-gray-300 rounded-lg ">
            <div className="relative w-full h-full">
              <input
                type="text"
                id="search-dropdown"
                className="block p-2.5 w-full h-[35px] text-sm text-gray-900 rounded-lg border border-gray-400"
                placeholder="Enter Po #"
                value={filtering}
                onChange={(e) => setFiltering(e.target.value)}
              />
              <button
                type="submit"
                className="absolute bottom-[2px] right-0 p-2.5 mr-[1px] mb-[-2px] text-sm font-medium h-[35px] text-white bg-gray-300 rounded-lg border focus:ring-0"
              >
                <svg
                  className="w-3 h-3 text-black"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
                <span className="sr-only">Search</span>
              </button>
            </div>
          </div>
        {/* )} */}
        {/* Date Picker */}
        {/* {isfilterOpen && ( */}
          <div className="h-fit font-normal cursor-pointer border-2  hover:border-[#3479E0] rounded-lg focus-within:border-[#3479E0] flex items-center justify-between">
            <Space
              direction="vertical "
              className="w-full"
              // style={{ height: "100%" }}
            >
              <DatePicker
                label="Select Month"
                id="purchase"
                picker="month"
                format="MMMM-YYYY"
                views={["year", "month"]}
                value={formattedDatefrom}
                disabledDate={(current) =>
                  current && current > dayjs().endOf("month").startOf("month")
                } // Disable future months
                onChange={handleDateChangeFrom}
                placeholder="Select Month"
                className="border-date-picker" // Add custom class for styling
                style={{ border: "1px solid #ccc", borderRadius: "6px" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={
                      formattedDatefrom
                        ? formattedDatefrom.format("MMMM-YYYY")
                        : ""
                    }
                    className="date-picker-field"
                    // sx={{
                    //   "& .MuiOutlinedInput-root": {
                    //     // height: "35px",
                    //     display: "flex",
                    //     alignItems: "center",
                    //     fontSize: "1rem",
                    //     backgroundColor: formattedDatefrom
                    //       ? "#d7f3f8"
                    //       : "transparent",
                    //     border: `1px solid ${
                    //       formattedDatefrom ? "#25caed" : "#ccc"
                    //     }`,
                    //     borderRadius: "6px",
                    //     "&:hover": {
                    //       borderColor: formattedDatefrom ? "#25caed" : "#aaa",
                    //     },
                    //     "&.Mui-focused": {
                    //       borderColor: "blue",
                    //       boxShadow: "0 0 8px rgba(0, 0, 255, 0.5)",
                    //     },
                    //   },
                    // }}
                    sx={{
                      width: "100%",
                      "& .MuiInputBase-root": {
                        height: "35px",
                        border: "1px solid #ccc !important",
                        "&:hover": {
                          border: "1px solid #3479E0 !important"
                        }
                      },
                      "& .MuiOutlinedInput-root": {
                        height: "35px",
                        display: "flex",
                        alignItems: "center",
                        fontSize: "0.875rem",
                        "& fieldset": {
                          border: "1px solid #9ca3af !important",
                          borderRadius: "6px"
                        },
                        "&:hover fieldset": {
                          border: "1px solid #3479E0 !important"
                        },
                        "&.Mui-focused fieldset": {
                          border: "2px solid #3479E0 !important"
                        }
                      },
                      "& .MuiInputLabel-root": {
                        fontSize: "0.875rem"
                      }
                    }}
                  />
                )}
              />
            </Space>
          </div>
          
        {/* )} */}

        {/* Filters Button */}
        {/* <div
          className="bg-white flex items-center justify-center bg-white p-2 h-[30px] rounded-lg shadow-lg cursor-pointer"
          // onClick={() => setIsfilterOpen(!isfilterOpen)}
          style={{ boxShadow: "0 2px 10px rgba(0, 0, 0, 0.3)" }}
        >
          <MdFilterList className="w-5 h-[16px] mr-2" />
          {!isfilterOpen && <span className="text-sm">Filters</span>}
        </div> */}

        {/* Upload PO Button */}
        <div className="flex justify-end bg-white ">
          <button
            className="text-white bg-[#25caed] flex items-center font-bold w-55 h-[35px] hover:scale-105 transition-transform duration-300 ease-in-out focus:ring-4 focus:ring-primary-300 rounded-lg text-base px-4 "
            type="button"
            // onClick={() => setUploadModal(true)}
            // onClick={() => navigate('/Fileupload')}
            onClick={() => {
              localStorage.setItem("cardsData", JSON.stringify(cardsData));
              navigate("/Fileupload");
            }}
            // onClick={() =>
            //   navigate("/Fileupload", { state: { cardsData: cardsData } })
            // }
            style={{ whiteSpace: "nowrap" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={3}
              stroke="currentColor"
              className="w-5 h-5 mr-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 6v12m6-6H6"
              />
            </svg>
            New PO
          </button>
        </div>
      </div>

      {/* Modal */}
      {/* {isModalOpencard && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"  onClick={()=> setIsModalOpencard(false)}>
  <div className="relative bg-white rounded-lg p-6 w-96 shadow-lg">
    <button
      className="absolute top-3 right-3 text-gray-400 hover:text-gray-600"
      onClick={()=> setIsModalOpencard(false)} // Closes modal on click
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={2}
        stroke="currentColor"
        className="w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    </button>

    {amountClicked &&
    <h4 className="text-lg font-bold mb-4 text-center">Gallons Details</h4>
  }
    {costClicked &&

    <h4 className="text-lg font-bold mb-4 text-center">Cost Details</h4>
  }

    <ul className="space-y-2">
  {amountClicked &&
    cardsData.map((detail, index) => (
      <li key={index} className="flex justify-between text-gray-700">
        <span>{detail.brandName}:</span>
        <span className="font-semibold">{formatNumberWithCommas(detail.gallons)}</span>
      </li>
    ))}

  {costClicked &&
    cardsData.map((detail, index) => (
      <li key={index} className="flex justify-between text-gray-700">
        <span>{detail.brandName}:</span>
        <span className="font-semibold">{Number(detail.total_amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
      </li>
    ))}
</ul>

  </div>
</div>
      )} */}
      {uploadModal && (
        <FileUploadModal
          onClose={() => setUploadModal(false)}
          // handleFileChange={handleFileChange}
          fileInputRef={fileInputRef}
          fileName={fileName}
          setFileName={setFileName}
          selectedFuelBrand={selectedFuelBrand}
          onFuelBrandChange={handleFuelBrandChange}
          handleUploadImage={handleUploadImage}
          errorMessage={errorMessage}
        />
      )}

      {/* <nav className="bg-gray-50 px-4 py-0 pt-3  flex items-center justify-between">

        <div className="flex-grow flex justify-center w-full  mt-4 pb-4 space-x-4">
          <button onClick={handlePrevDay} className="">
            <FaChevronLeft size={24} className="text-gray-700" />
            </button>
          <div 
            className="h-11 text-gray-700 font-normal w-48 border border-gray-300 border-b-4 border-b-blue-400 rounded-t-md rounded-b-md flex items-center shadow"
          >
           <div 
              className='flex justify-between items-center cursor-pointer'
              onClick={() => document.getElementById('created_on').focus()}
            >
              <DatePicker
                id="created_on"
                name="created_on"
                selected={createdDate}
                required
                maxDate={new Date()}
                onChange={handleDateChange}
                dateFormat="MMMM dd, yyyy"
                placeholderText="From"
                className="w-full outline-none border-none rounded-r-lg cursor-pointer focus:outline-none focus:ring-0 bg-transparent"
                onKeyDown={(e) => { e.preventDefault(); }}
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-5 h-5 mr-2"
              >
                <path d="M12.75 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM7.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM8.25 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM9.75 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM10.5 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM12.75 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM14.25 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 13.5a.75.75 0 100-1.5.75.75 0 000 1.5z" />
                <path
                  fillRule="evenodd"
                  d="M6.75 2.25A.75.75 0 017.5 3v1.5h9V3A.75.75 0 0118 3v1.5h.75a3 3 0 013 3v11.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V7.5a3 3 0 013-3H6V3a.75.75 0 01.75-.75zm13.5 9a1.5 1.5 0 00-1.5-1.5H5.25a1.5 1.5 0 00-1.5 1.5v7.5a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5v-7.5z"
                  clipRule="evenodd"
                />
              </svg>
            </div>

          </div>
          <button onClick={handleNextDay} className="ml-2">
            <FaChevronRight size={24} className="text-gray-700" />
          </button>
        </div>
      </nav> */}

      {/* <div className="p-0"> */}

      {/* <div className="flex flex-col items-center">
        {imagesByDate.map((image) => (
          <div key={image.id} className="flex items-center justify-between w-80 space-x-2 mb-4 p-3 pt-1.5 pb-1.5 border border-gray-300 rounded-lg">
            <span className="font-semibold">Fuel purchased image</span>
            <div className="relative group">
              <button
                onClick={() => handleView(image.img_file)}
                className="text-gray-500 hover:text-blue-700 hover:bg-blue-200 p-2 rounded-full cursor-pointer"
              >
                <FaEye size={20} />
              </button>
            </div>
          </div>
        ))}
      </div> */}

      {/* <hr className="border-t border-gray-300 mt-4 w-full max-w-md mx-auto" /> */}

      {/* <div className="flex flex-col items-start w-80 max-w-md mx-auto my-5">
      <label className="mb-2 text-lg font-bold" htmlFor="fileInput">
        Upload Invoice*
      </label>

      <div className="flex items-center w-full relative">
        <input
          type="file"
          id="fileInput"
          className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
          onChange={handleFileChange}
          ref={fileInputRef}
          accept="image/pdf/*" // Allow all image file types
        />
        <div className="flex w-full">
          <input
            type="text"
            placeholder="Choose file"
            className="w-full p-2 border border-gray-300 rounded-l-lg cursor-pointer"
            value={fileName}
            readOnly
          />
          <label
            htmlFor="fileInput"
            className="bg-blue-500 text-white py-2 px-4 rounded-r-lg cursor-pointer mb-0"
          >
            Browse
          </label>
        </div>
      </div>
      {errorMessage && (
        <p className="text-red-500 font-medium text-sm">{errorMessage}</p>
      )}
      <button 
        onClick={handleUploadImage}
        className="w-full mt-4 bg-white border-2 font-semibold border-blue-500 text-blue-500 py-2 cursor-pointer rounded-lg hover:bg-blue-500 hover:text-white">
        Submit
      </button>
    </div> */}

      {/* </div> */}

      {/* <nav className="flex items-center justify-between p-4 bg-gray-500 text-white mr-6 ml-6">
      <div className="flex items-center">
        <span className="text-lg font-semibold">Purchases</span>
      </div>
          <div className="flex-grow flex justify-center">
             <span className="text-lg font-semibold">{currentMonthYear}</span>
          </div>
      <div className="flex items-center space-x-2">
        <button className="p-2 bg-white text-gray-500 rounded">
          <FaSearch />
        </button>
        <button className="p-2 bg-white text-gray-500 rounded">
          <FaCalendarAlt />
        </button>
      </div>
      </nav> */}
      {/* <div className="flex justify-between w-full ml-[45px] mt-5"> */}
      {/* <span className="text-xl text-[#25caed] font-bold">
  {monthName && yearName ? `${monthName} - ${yearName} Purchases` : ''}
</span> */}
      {/* </div> */}
      <div
        className="px-3 pt-3 rounded-b-xl pb-5 bg-white rounded-tl-[12px] rounded-tr-[12px] shadow-[4px_4px_8px_rgba(0,0,0,0.1),-4px_4px_8px_rgba(0,0,0,0.1),4px_-4px_8px_rgba(0,0,0,0.1),-4px_-4px_8px_rgba(0,0,0,0.1)]
"
      >
        <table ref={tableRef} className="w-full table-auto bg-transparent ">
          {/* <thead className="bg-blue-900 text-white mx-3"> */}
          <thead className="bg-blue-900 text-white mx-3">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="text-center text-xs">
                {headerGroup.headers.map((header, index) => {
                  const isFirstHeader = index === 0;
                  const isLastHeader = index === headerGroup.headers.length - 1;
                  const headerClasses = [
                    "p-3 font-bold uppercase",
                    isFirstHeader ? "rounded-l-xl" : "",
                    isLastHeader ? "rounded-r-xl" : ""
                  ];

                  const isSorted = header.column.getIsSorted();
                  const showSortingIcons =
                    header.column.columnDef.enableSorting !== false;

                  return (
                    <th
                      key={header.id}
                      className={headerClasses.join(" ")}
                      colSpan={header.colSpan}
                      onClick={() =>
                        showSortingIcons &&
                        handleSortingChange(header.column.id)
                      }
                    >
                      <div className="flex items-center justify-center gap-2">
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                        {showSortingIcons && (
                          <div className="flex items-center">
                            <FaArrowUpLong
                              className={`${
                                isSorted === "asc"
                                 ? 'text-[#06b6d4]' : 'text-white'
                              }`}
                              size={12}
                            />
                            <FaArrowDownLong
                              className={`${
                                isSorted === "desc"
                                  ? 'text-[#06b6d4]' : 'text-white'
                              }`}
                              size={12}
                            />
                          </div>
                        )}
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody className="bg-white">
            {table.getRowModel().rows.length > 0 ? (
              table.getRowModel().rows.map((row) => (
                <>
              <tr
  key={row.id}
  className="cursor-pointer text-center hover:shadow-[0_4px_8px_rgba(0,0,0,0.1)] transition-shadow h-8" // Add fixed height here
  style={{ borderBottom: "0.3px solid #e0e0e0" }}
  onClick={() => toggleRow(row.id)}
>
                    
                    {row.getVisibleCells().map((cell) => (
                      <td
                        key={cell.id}
                        className="p-3 font-semibold text-[#081159] text-[14px]"
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>

                  {expandedRow === row.id && (
                    <tr className="bg-gray-100 border-t-[2px] border-[#25caed]">
                      {/* First column for spacing/alignment */}
                      <td></td>

                      {/* Fuel grades aligned dynamically from 2nd column */}
                      {row.original.fuelgrades?.length > 0 ? (
                        row.original.fuelgrades.map((fuelgrade, fuelIndex) => (
                          <td
                            key={fuelIndex}
                            className="p-3 py-1 text-center font-[8px]"
                          >
                            <div
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                fontSize: "14px"
                              }}
                            >
                              {fuelgrade.fuelgrade_name}
                            </div>
                            <div className="text-gray-600 mt-1 text-[14px]">
                              {formatNumberWithCommas(
                                isNaN(fuelgrade.purchased_gallons)
                                  ? 0
                                  : fuelgrade.purchased_gallons
                              )}{" "}
                              gl / $
                              {Number(fuelgrade.unit_total).toLocaleString(
                                undefined,
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2
                                }
                              )}
                            </div>
                          </td>
                        ))
                      ) : (
                        <td
                          colSpan={
                            table.getHeaderGroups()[0].headers.length - 2
                          }
                          className="text-gray-500 text-center"
                        >
                          No fuel grades available.
                        </td>
                      )}

                      {/* Empty TDs to maintain structure up to the last column */}
                      {[
                        ...Array(
                          table.getHeaderGroups()[0].headers.length -
                            row.original.fuelgrades.length -
                            2
                        )
                      ].map((_, index) => (
                        <td key={index}></td>
                      ))}

                      {/* Last column: Icons (View, Download, Delete) */}
                      <td className="p-3 flex items-center justify-evenly">
                        <Tooltip
                          title="View"
                          position="top"
                          trigger="mouseenter"
                        >
                          <button
                            className="text-sm text-black p-1"
                            onClick={() => handleView(row.original.img_file)}
                          >
                            <AiFillEye size={20} />
                          </button>
                        </Tooltip>
                        <Tooltip
                          title="Download"
                          position="top"
                          trigger="mouseenter"
                        >
                          <button
                            className="text-sm text-blue-800 p-1"
                            onClick={() =>
                              handleDownload(row.original.img_file)
                            }
                          >
                            <FiDownload size={20} />
                          </button>
                        </Tooltip>
                        {/* <Tooltip title="Delete" position="top" trigger="mouseenter">
        <button
          className="text-sm text-red-600 p-1"
          onClick={() => handleDelete(row.original.id, row)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-5 h-5"
          >
            <path
              fillRule="evenodd"
              d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </Tooltip> */}
                        <Tooltip
                          title={
                            isDeleteDisabled(row)
                              ? "Po is already used"
                              : "Delete"
                          }
                          position="top"
                          trigger="mouseenter"
                        >
                          <button
                            className={`text-sm ${
                              isDeleteDisabled(row)
                                ? "text-gray-400 cursor-not-allowed"
                                : "text-red-600 cursor-pointer"
                            } p-1`}
                            onClick={(e) => {
                              if (!isDeleteDisabled(row)) {
                                e.stopPropagation();
                                handleDelete(row.original.id, row);
                              }
                            }}
                            disabled={isDeleteDisabled(row)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                              className="w-5 h-5"
                            >
                              <path
                                fillRule="evenodd"
                                d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </button>
                        </Tooltip>
                      </td>
                    </tr>
                  )}
                </>
              ))
            ) : (
              // Show empty state if no rows
              <tr>
                <td colSpan={columns.length} className="text-center py-12">
                  <div className="text-gray-500 text-lg">No data available</div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* <div className="flex items-center mr-7 mt-6 gap-2 justify-end">
                  <button
                    className={`px-4 py-2 rounded-l-lg focus:outline-none flex items-center ${
                      table.getCanPreviousPage()
                        ? " hover:bg-[#114FFF] hover:text-white cursor-pointer"
                        : " cursor-not-allowed"
                    }`}
                    onClick={() => table.previousPage()}
                    disabled={!table.getCanPreviousPage()}
                  >
                    <MdNavigateBefore className="w-6 h-6" />
                    Prev
                  </button>
                  {[...Array(table.getPageCount()).keys()].map((page) => (
                    <button
                      key={page}
                      onClick={() => table.setPageIndex(Number(page))}
                      className={`px-4 py-2  focus:outline-none ${
                        currentPage === page
                          ? " rounded-full bg-[#25caed] text-white "
                          : " rounded-full bg-white dark:text-black hover:bg-[#25caed]/50 hover:text-white"
                      }`}
                    >
                      {page + 1}
                    </button>
                  ))}
                  <button
                    className={`px-4 py-2 rounded-r-lg  focus:outline-none flex items-center justify-center${
                      table.getCanNextPage()
                        ? " hover:bg-[#114FFF] hover:text-white cursor-pointer"
                        : "bg-gray-300 cursor-not-allowed"
                    }`}
                    onClick={() => table.nextPage()}
                    disabled={!table.getCanNextPage()}
                  >
                    Next
                    <MdNavigateNext className="w-6 h-6" />
                  </button>
                </div> */}

      <PurchaseImagesViewModel
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        selectedImagePath={selectedImagePath}
        selectedInvoiceNumberforModel={selectedInvoiceNumberforModel}
      />
      {openDeleteModalId && (
        <PopUpModalForDeletePurchases
          onClose={() => setOpenDeleteModalId(null)}
          id={openDeleteModalId}
          imageName={"Fuel Purchased Image"}
          resetRowSelection={table.resetRowSelection}
          brandInvoiceNumber={deletedRow?.original?.brand_invoice_number}
          onSuccessfulDeletion={handleSuccessfulDeletion}
        />
      )}
    </div>
  );
};

export default Purchases;

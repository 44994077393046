import React, { useState, useRef, useEffect } from "react";
import { Box, Paper } from "@mui/material";
import { DatePicker, Space } from "antd";
import dayjs from "dayjs";
// import "react-datepicker/dist/react-datepicker.css";
import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import { useSelector, useDispatch } from "react-redux";
import { Grid, IconButton } from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PrintIcon from "@mui/icons-material/Print";
import DescriptionIcon from "@mui/icons-material/Description";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
// import SearchIcon from "@mui/icons-material/Search";
// import DatePicker from "react-datepicker";
import axios from "axios";
import { Tooltip } from "react-tippy";
import config from "../../config/config";
import FullScreenLoader from "../pages/FullScreenLoader";
import { Col, Row } from "react-bootstrap";
import { addToast } from "../pages/ToastBox/ToastBoxMessageSlice";
import PurchaseImagesViewModel from "../models/PurchaseImagesViewModel";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import "../pages/Css/viewbusinessusers.css";
import { FaHome } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
const MyPoReport = () => {
  const businessId = localStorage.getItem("business_id");
  const managerId = localStorage.getItem("user_id");
  const today = dayjs(); 
  const baseURL =
    process.env.NODE_ENV === "production"
      ? config.production.baseURL
      : config.development.baseURL;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const businessDate = localStorage.getItem('business_date')
  // Report Type and Date Selection States
  const [periodType, setPeriodType] = useState("");
  const [tempperiodType, settempPeriodType] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [tempYear, setTempYear] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [tempMonth, setTempMonth] = useState("");
  const [selectedQuarter, setSelectedQuarter] = useState("");
  const [tempQuarter, setTempQuarter] = useState("");
  // const [startDate, setStartDate] = useState(new Date(""));
  // const [endDate, setEndDate] = useState(new Date(""));
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  // UI States
  const [dateTime, setDateTime] = useState("");
  const [imageSource, setImageSource] = useState();
  const [imageResponse, setImageResponse] = useState([]);
  const [loading, setLoading] = useState(false);
  const [runClicked, setRunClicked] = useState(false);
  const [pdfhovered, setPdfHovered] = useState(false);
  const [taxhovered, setTaxHovered] = useState(false);
  const [notFound, setNotFound] = useState(false);

  // Modal States
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImagePath, setSelectedImagePath] = useState("");
  const [selectedInvoiceNumberforModel, setSelectedInvoiceNumberforModel] =
    useState("");

  // Data States
  const [poData, setPoData] = useState({});
  const [sellerNames, setSellerNames] = useState([]);
  const [brandId, setBrandId] = useState("");

  // User Info
  const user_id = localStorage.getItem("user_id");
  const user_role = localStorage.getItem("user_role");
  const business_id = localStorage.getItem("business_id");
  const [userRole, setUserRole] = useState("");

  const contentRef = useRef();

  // Select Options
  // const years = ["2025", "2024", "2023", "2022", "2021"];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];
  const quarters = [
    { bName: "Q1", fName: "Q1 (Mar-May)" },
    { bName: "Q2", fName: "Q2 (June-Aug)" },
    { bName: "Q3", fName: "Q3 (Sep-Nov)" },
    { bName: "Q4", fName: "Q4 (Dec-Feb)" }
  ];

  // Validate if button should be enabled
  const isButtonEnabled = () => {
    if (periodType === "Yearly" && selectedYear) return true;
    if (periodType === "Monthly" && selectedYear && selectedMonth) return true;
    if (periodType === "Quarterly" && selectedYear && selectedQuarter)
      return true;
    if (periodType === "CustomRange" && startDate && endDate) return true;
    return false;
  };

  // Formatting functions
  const formatToTwoDecimalsWithCommas = (num) => {
    if (!num) return "0.00";
    const parts = num.toString().split(".");
    const integerPartWithCommas = parseInt(parts[0], 10).toLocaleString(
      "en-US"
    );
    const decimalPart = parts[1] ? parts[1].substring(0, 2) : "";
    return decimalPart
      ? `${integerPartWithCommas}.${decimalPart}`
      : integerPartWithCommas;
  };

  function formatNumberwithoutzeros(num) {
    if (!num) return "0";
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(num);
  }

  function formatNumber(num) {
    if (!num) return "0.00";
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(num);
  }

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const parts = dateString.split("-");
    if (parts.length !== 3) return dateString;
    return `${parts[1]}/${parts[2]}/${parts[0]}`;
  };

  // Generate current date/time in US format
  const generateUSDateTime = () => {
    setRunClicked(true);
    const currentDate = new Date();
    const formattedDateTime = currentDate.toLocaleString("en-US", {
      year: "numeric",
      month: "numeric",
      day: "numeric"
    });
    setDateTime(formattedDateTime);
  };

  // Set user role when component mounts
  useEffect(() => {
    setUserRole(localStorage.getItem("user_role") || "2");
  }, []);

  // Fetch business profile data
  useEffect(() => {
    axios
      .get(
        `${baseURL}/${btoa("business/profile")}/${btoa(business_id)}/${btoa(
          user_id
        )}/${btoa(user_role)}`
      )
      .then((response) => {
        setImageSource(response.data.business_data[0].image_file);
        setImageResponse(response.data.business_data[0]);
      })
      .catch((error) => {
        console.error("Error fetching business data:", error);
      });
  }, []);

  const disabledDate = (current) => {
    // Convert businessDate to dayjs object if it's not already
    const businessDateObj = typeof businessDate === 'string' 
      ? dayjs(businessDate) 
      : dayjs(businessDate);
    
    // Disable dates before businessDate or after today
    return (
      current.isBefore(businessDateObj, 'day') || 
      current.isAfter(today, 'day')
    );
  };


  const [years, setYears] = useState([]);
  useEffect(() => {
    setSelectedYear(new Date().getFullYear().toString());
  }, []);
  useEffect(() => {
    console.log("Fetching business profile data...");

    axios
      .get(
        `${baseURL}/${btoa("business/profile")}/${btoa(business_id)}/${btoa(
          user_id
        )}/${btoa(user_role)}`
      )
      .then((response) => {
        console.log("API response received");

        try {
          // Extract the added_on date string
          const addedOnDate = response.data.business_data[0].added_on;
          console.log("Raw added_on date:", addedOnDate);

          // Extract just the year from the date string (e.g., "2025-02-17" → 2025)
          const startYear = parseInt(addedOnDate.substring(0, 4));
          console.log("Extracted start year:", startYear);

          // Validate the year is a reasonable value
          if (isNaN(startYear) || startYear < 2000 || startYear > 2100) {
            console.error("Invalid year value:", startYear);
            return;
          }

          // Generate years array from startYear to current year
          const currentYear = new Date().getFullYear();
          const yearArray = [];

          // Add years in descending order (newest first)
          for (let year = currentYear; year >= startYear; year--) {
            yearArray.push(year);
          }

          console.log("Generated years array:", yearArray);

          // Update state with the generated years
          setYears(yearArray);

          // Set default selected year to current year
          if (yearArray.length > 0) {
            setSelectedYear(currentYear.toString());
          }
        } catch (error) {
          console.error("Error processing business data:", error);
        }
      })
      .catch((error) => {
        console.error("Error fetching business data:", error);
      });
  }, []);

  // const handleBrandChange = (event) => {
  //   const selectedBrandId = event.target.value;
  //   console.log("Selected brand ID:", selectedBrandId);

  //   // First clear any previous selection
  //   if (selectedBrandId === "") {
  //     setBrandId("");
  //     setBrandName("");
  //     setIsBrandSelected(false);
  //     console.log("No brand selected");
  //     return;
  //   }

  //   // Handle "all" selection
  //   if (selectedBrandId === "all") {
  //     console.log("All brands selected");
  //     setBrandId("all");
  //     setBrandName("All Brands");
  //     setIsBrandSelected(true);
  //     // Set default year to current year
  //     setSelectedYear(new Date().getFullYear().toString());
  //   } else {
  //     // For specific brand selection
  //     const selectedBrand = brands.find(
  //       (brand) => String(brand.id) === String(selectedBrandId)
  //     );

  //     if (selectedBrand) {
  //       console.log("Found selected brand:", selectedBrand.seller_name);
  //       setBrandId(selectedBrandId);
  //       setBrandName(selectedBrand.seller_name);
  //       setIsBrandSelected(true);
  //       // Set default year to current year
  //       setSelectedYear(new Date().getFullYear().toString());
  //     } else {
  //       console.error("Brand not found for ID:", selectedBrandId);
  //       setBrandId("");
  //       setBrandName("");
  //       setIsBrandSelected(false);
  //     }
  //   }

  //   // Reset period selections but keep year
  //   setPeriodType("");
  //   setSelectedMonth("");
  //   setSelectedQuarter("");
  // };

  // Fetch seller names when component mounts
  useEffect(() => {
    const fetchSellersNames = async () => {
      try {
        const response = await axios.get(
          `${baseURL}/${btoa("invoice/allsellers")}`
        );
        setSellerNames(response.data);
      } catch (error) {
        console.log("Error fetching seller names:", error);
      }
    };
    fetchSellersNames();
  }, []);

  // Handle period type change
  const handlePeriodChange = (event) => {
    const newPeriodType = event.target.value;
    setPeriodType(newPeriodType);

    // Reset all values first
    setSelectedYear("");
    setSelectedMonth("");
    setSelectedQuarter("");

    // Set default values based on report type
    // if (newPeriodType === "Monthly") {
    //   setSelectedMonth("January");
    // } else if (newPeriodType === "Quarterly") {
    //   setSelectedQuarter("Q1");
    // }
  };

  // Handle the Run button click
  // const handleRun = async () => {
  //   setRunClicked(true);
  //   setLoading(true);
  //   setNotFound(false);
  //   generateUSDateTime();

  //   settempPeriodType(periodType);
  //   setTempYear(selectedYear);
  //   setTempMonth(selectedMonth);
  //   setTempQuarter(selectedQuarter);

  //   try {
  //     // Initialize request data with all null/empty values
  //     let requestData = {
  //       reportType: periodType,
  //       businessId: businessId,
  //       managerId: managerId,
  //       userRole: userRole,
  //       brandId: "",
  //       year: "",
  //       StartDate: "",
  //       EndDate: "",
  //       monthName: "",
  //       quarterName: ""
  //     };
  //     console.log("requestData", requestData);
  //     // Set specific values based on report type
  //     if (periodType === "Monthly") {
  //       requestData.monthName = selectedMonth;
  //       requestData.year = selectedYear;
  //     } else if (periodType === "Quarterly") {
  //       requestData.quarterName = selectedQuarter;
  //       requestData.year = selectedYear;
  //     } else if (periodType === "Yearly") {
  //       requestData.year = selectedYear;
  //     } else if (periodType === "CustomRange" && startDate && endDate) {
  //       // Ensure dates are valid before formatting
  //       if (
  //         startDate instanceof Date &&
  //         !isNaN(startDate.getTime()) &&
  //         endDate instanceof Date &&
  //         !isNaN(endDate.getTime())
  //       ) {
  //         requestData.StartDate = startDate.toISOString().split("T")[0];
  //         requestData.EndDate = endDate.toISOString().split("T")[0];
  //       } else {
  //         throw new Error("Invalid date range selected");
  //       }
  //     }

  //     console.log("requestData:", requestData);

  //     const response = await axios.post(
  //       `${baseURL}/cmVwb3J0L015UE9yZXBvcnQ=`,
  //       requestData
  //     );

  //     console.log("API Response:", response.data);

  //     if (
  //       response.data &&
  //       response.data.PO_data &&
  //       response.data.PO_data.length > 0
  //     ) {
  //       setPoData(response.data);
  //       setNotFound(false);
  //     } else {
  //       setPoData({});
  //       setNotFound(true);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching PO data:", error);
  //     dispatch(
  //       addToast({
  //         type: "error",
  //         message: `Error fetching PO data: ${error.message}`
  //       })
  //     );
  //     setPoData({});
  //     setNotFound(true);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // This code fixes the handleRun function to properly handle date formats

  const handleRun = async () => {
    setRunClicked(true);
    setLoading(true);
    setNotFound(false);
    generateUSDateTime();

    settempPeriodType(periodType);
    setTempYear(selectedYear);
    setTempMonth(selectedMonth);
    setTempQuarter(selectedQuarter);

    try {
      // Initialize request data with all null/empty values
      let requestData = {
        reportType: periodType,
        businessId: businessId,
        managerId: managerId,
        userRole: userRole,
        brandId: "",
        year: "",
        StartDate: "",
        EndDate: "",
        monthName: "",
        quarterName: ""
      };

      // Set specific values based on report type
      if (periodType === "Monthly") {
        requestData.monthName = selectedMonth;
        requestData.year = selectedYear;
      } else if (periodType === "Quarterly") {
        requestData.quarterName = selectedQuarter;
        requestData.year = selectedYear;
      } else if (periodType === "Yearly") {
        requestData.year = selectedYear;
      } else if (periodType === "CustomRange" && startDate && endDate) {
        // Format dates correctly to YYYY-MM-DD format
        // Use toISOString() to get proper date without timezone issues
        // and split at 'T' to get just the date part
        const formatDate = (date) => {
          // Ensure we're working with a date object
          if (!(date instanceof Date) || isNaN(date.getTime())) {
            throw new Error("Invalid date object");
          }

          // Create a new date at midnight in the local timezone to avoid timezone shifts
          const localDate = new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate()
          );

          // Format as YYYY-MM-DD
          const year = localDate.getFullYear();
          // Month is 0-based, so add 1 and pad with leading zero if needed
          const month = String(localDate.getMonth() + 1).padStart(2, "0");
          // Pad day with leading zero if needed
          const day = String(localDate.getDate()).padStart(2, "0");

          return `${year}-${month}-${day}`;
        };

        try {
          requestData.StartDate = formatDate(startDate);
          requestData.EndDate = formatDate(endDate);

          console.log("Formatted dates:", {
            startDate: requestData.StartDate,
            endDate: requestData.EndDate
          });
        } catch (dateError) {
          console.error("Error formatting dates:", dateError);
          throw new Error("Invalid date range selected");
        }
      }

      console.log("requestData:", requestData);

      const response = await axios.post(
        `${baseURL}/cmVwb3J0L015UE9yZXBvcnQ=`,
        requestData
      );

      console.log("API Response:", response.data);

      if (
        response.data &&
        response.data.PO_data &&
        response.data.PO_data.length > 0
      ) {
        setPoData(response.data);
        setNotFound(false);
      } else {
        setPoData({});
        setNotFound(true);
      }
    } catch (error) {
      console.error("Error fetching PO data:", error);
      dispatch(
        addToast({
          type: "error",
          message: `Error fetching PO data: ${error.message}`
        })
      );
      setPoData({});
      setNotFound(true);
    } finally {
      setLoading(false);
    }
  };

  // Handle PDF download
  // const handlePdfDownload = async () => {
  //   try {
  //     // Create the request data based on the current selection
  //     let startDateParam = "";
  //     let endDateParam = "";
  //     let yearParam = "";
  //     let monthParam = "";
  //     let quarterParam = "";

  //     if (periodType === "CustomRange" && startDate && endDate) {
  //       startDateParam = startDate.toISOString().split("T")[0];
  //       endDateParam = endDate.toISOString().split("T")[0];
  //     } else if (periodType === "Yearly" && selectedYear) {
  //       yearParam = selectedYear;
  //     } else if (periodType === "Monthly" && selectedYear && selectedMonth) {
  //       yearParam = selectedYear;
  //       monthParam = selectedMonth;
  //     } else if (
  //       periodType === "Quarterly" &&
  //       selectedYear &&
  //       selectedQuarter
  //     ) {
  //       yearParam = selectedYear;
  //       quarterParam = selectedQuarter;
  //     } else {
  //       dispatch(
  //         addToast({
  //           type: "error",
  //           message:
  //             "Please complete all required fields for the selected period type"
  //         })
  //       );
  //       return;
  //     }

  //     const response = await axios.get(
  //       `${baseURL}/${btoa("purchase/download_purchasedinvoicespdf")}/${btoa(
  //         businessId
  //       )}/${btoa(managerId)}/${btoa(brandId)}/${btoa("all")}/${btoa(
  //         null
  //       )}/${btoa(periodType)}/${btoa(yearParam)}/${btoa(monthParam)}/${btoa(
  //         quarterParam
  //       )}`,
  //       { responseType: "blob" }
  //     );

  //     const filename = `PO_Report_${new Date().toISOString()}.pdf`;
  //     const blob = new Blob([response.data], { type: "application/pdf" });
  //     const url = window.URL.createObjectURL(blob);
  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.download = filename;
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //     window.URL.revokeObjectURL(url);
  //   } catch (error) {
  //     console.error("Error downloading PDF:", error);
  //     dispatch(
  //       addToast({
  //         type: "error",
  //         message: `Error downloading PDF: ${error.message}`
  //       })
  //     );
  //   }
  // };

  const formatDateForApi = (date) => {
    if (!(date instanceof Date) || isNaN(date.getTime())) {
      return "";
    }

    // Create a new date at midnight in the local timezone
    const localDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );

    // Format as YYYY-MM-DD
    const year = localDate.getFullYear();
    const month = String(localDate.getMonth() + 1).padStart(2, "0");
    const day = String(localDate.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  // Handle printing
  const handlePrint = () => {
    const content = contentRef.current.innerHTML;
    const newWindow = window.open("", "_blank");
    newWindow.document.write(`
      <html>
        <head>
          <title>Print Purchase Orders</title>
          <style>
            @media print {
              img {
                display: block;
                width: 100px;
                height: auto;
              }
              @page {
                margin: 0.5cm;
              }
              body {
                margin: 0;
                padding: 0;
              }
              header, footer {
                display: none;
              }
            }
            body {
              font-family: Arial, sans-serif;
              margin: 30px;
            }
            table {
              width: 100%;
              border-collapse: collapse;
            }
            th, td {
              padding: 8px;
              text-align: left;
              border-bottom: 1px solid #ddd;
            }
            th {
              background-color: #f2f2f2;
            }
          </style>
        </head>
        <body>
          ${content} 
        </body>
      </html>
    `);
    newWindow.document.close();
    newWindow.print();
  };

  // Handle Excel download
  // const handleExcelDownload = async () => {
  //   try {
  //     // Create the request data based on the current selection
  //     let startDateParam = "";
  //     let endDateParam = "";
  //     let yearParam = "";
  //     let monthParam = "";
  //     let quarterParam = "";

  //     if (periodType === "CustomRange") {
  //       startDateParam = startDate.toISOString().split("T")[0];
  //       endDateParam = endDate.toISOString().split("T")[0];
  //     } else if (periodType === "Yearly") {
  //       yearParam = selectedYear;
  //     } else if (periodType === "Monthly") {
  //       yearParam = selectedYear;
  //       monthParam = selectedMonth;
  //     } else if (periodType === "Quarterly") {
  //       yearParam = selectedYear;
  //       quarterParam = selectedQuarter;
  //     }

  //     const response = await axios.get(
  //       `${baseURL}/${btoa("purchase/download_purchasedinvoicesexcel")}/${btoa(
  //         businessId
  //       )}/${btoa(managerId)}/${btoa(brandId)}/${btoa("all")}/${btoa(
  //         null
  //       )}/${btoa(periodType)}/${btoa(yearParam)}/${btoa(monthParam)}/${btoa(
  //         quarterParam
  //       )}`,
  //       { responseType: "blob" }
  //     );

  //     const filename = `PO_Report_${new Date().toISOString()}.xlsx`;
  //     const blob = new Blob([response.data], {
  //       type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  //     });
  //     const url = window.URL.createObjectURL(blob);
  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.download = filename;
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //     window.URL.revokeObjectURL(url);
  //   } catch (error) {
  //     console.error("Error downloading Excel:", error);
  //     dispatch(
  //       addToast({
  //         type: "error",
  //         message: `Error downloading Excel: ${error.message}`
  //       })
  //     );
  //   }
  // };

  const handleExcelDownload = async () => {
    try {
      // Create the request data based on the current selection
      let startDateParam = "";
      let endDateParam = "";
      let yearParam = "";
      let monthParam = "";
      let quarterParam = "";

      if (periodType === "CustomRange" && startDate && endDate) {
        startDateParam = formatDateForApi(startDate);
        endDateParam = formatDateForApi(endDate);
      } else if (periodType === "Yearly" && selectedYear) {
        yearParam = selectedYear;
      } else if (periodType === "Monthly" && selectedYear && selectedMonth) {
        yearParam = selectedYear;
        monthParam = selectedMonth;
      } else if (
        periodType === "Quarterly" &&
        selectedYear &&
        selectedQuarter
      ) {
        yearParam = selectedYear;
        quarterParam = selectedQuarter;
      } else {
        dispatch(
          addToast({
            type: "error",
            message:
              "Please complete all required fields for the selected period type"
          })
        );
        return;
      }

      const response = await axios.get(
        `${baseURL}/${btoa("purchase/download_purchasedinvoicesexcel")}/${btoa(
          businessId
        )}/${btoa(managerId)}/${btoa(brandId)}/${btoa("all")}/${btoa(
          null
        )}/${btoa(periodType)}/${btoa(yearParam)}/${btoa(monthParam)}/${btoa(
          quarterParam
        )}`,
        { responseType: "blob" }
      );

      const filename = `PO_Report_${new Date().toISOString()}.xlsx`;
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading Excel:", error);
      dispatch(
        addToast({
          type: "error",
          message: `Error downloading Excel: ${error.message}`
        })
      );
    }
  };

  // Handle image view
  const handleView = (fileName, invoiceNumber) => {
    setSelectedInvoiceNumberforModel(invoiceNumber);
    setSelectedImagePath(fileName);
    setIsModalOpen(true);
  };

  // Close modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Handle key press for Enter key
  const handleKeyPress = (e) => {
    if (e.key === "Enter" && isButtonEnabled()) {
      handleRun();
    }
  };

  const themeColor = "rgb(231 70 148)";
  const labelStyle =
    "block mb-2 text-[14px] font-medium text-gray-600 inline-flex items-center";
  const inputStyle =
    "border border-gray-300 text-sm font-medium rounded-lg focus:ring-grey-600 focus:border-primary-600 block w-full text-gray-500 py-1 px-2 h-[30px] ";
  const datepickerStyle =
    "text-center flex justify-center border border-gray-300 text-[14px] font-medium text-gray-600 rounded-lg focus:ring-grey-600 focus:border-primary-600 block w-full customReportDatePicker h-[30px] ";

  const buttonStyle = {
    backgroundColor: themeColor,
    color: "white",
    marginTop: "34px",
    height: "30px",
    "&:hover": {
      backgroundColor: "rgb(231 70 148)"
    }
  };

  return (
    <div className="ml-5">
      <>
        <div
          className="flex justify-between items-center w-full pt-4 mt-4 mb-5"
          style={{
            maxWidth: "1200px",
            boxSizing: "border-box",
            marginLeft: "4px",
            color: "#444444"
          }}
        >
          <nav aria-label="breadcrumb">
            <ol className="flex space-x-2 text-lg font-bold text-gray-700">
              <li>Reports</li>
              <li>
                <span className="text-gray-400">{">>"}</span>
              </li>
              <li>My Po</li>
            </ol>
          </nav>
        </div>
        <div
          className="relative py-[12px] px-[8px] rounded-xl bg-white"
          style={{ boxShadow: "0 0 25px 0 rgba(0, 0, 0, 0.2)" }}
        >
          <div className="flex items-center justify-end">
            {/* Home button - bordered/unfilled by default, filled blue on hover */}
            <div
              className="absolute -top-4 flex items-center justify-center cursor-pointer rounded-full bg-[#cefafe] hover:bg-[#25caed] w-9 h-9 mb-0 mr-6 border-[0.5px] border-[#25caed] hover:border-[#25caed] hover:scale-110 shadow-md transition-all duration-200 group"
              onClick={() => {
                navigate("/");
              }}
              style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
            >
              <FaHome
                className="text-[#25caed] group-hover:text-white transition-colors duration-200"
                size={16}
              />
            </div>

            {/* Close Icon - bordered/unfilled by default, filled red on hover */}
            <div
              className="absolute -top-4 -right-2 bg-red-200 hover:bg-red-500 w-9 h-9 flex items-center justify-center rounded-full cursor-pointer hover:bg-red-700 border-[0.5px] border-red-500 hover:border-red-700 hover:scale-110 shadow-md transition-all duration-200 group"
              onClick={() => navigate("/HomeReports")}
            >
              <span className="text-red-500 group-hover:text-white text-xl font-bold mb-1.5 transition-colors duration-200">
                &times;
              </span>
            </div>
          </div>

          {/* <Box
            sx={{
              padding: 1,
              paddingTop: 0,
              paddingBottom: 1,
              maxWidth: "100%",
              backgroundColor: "#ffff",
              marginTop: "20px"
            }}
          > */}
          <Grid
            container
            spacing={3}
            alignItems="center"
            justifyContent="start"
            className="pl-[10px] pr-6"
          >
            {/* Report Type Selection */}
            <Grid item md={2.1}>
              <div>
                <label className={labelStyle}>
                  Duration
                  <span className="text-red-500 text-xl font-extrabold pl-1">
                    *
                  </span>{" "}
                </label>
                <select
                  id="periodType"
                  value={periodType}
                  onChange={handlePeriodChange}
                  onKeyPress={handleKeyPress}
                  className={inputStyle}
                >
                  <option value="">--Select--</option>
                  <option value="Monthly">Monthly</option>
                  <option value="Quarterly">Quarterly</option>
                  <option value="Yearly">Yearly</option>
                  <option value="CustomRange">Custom Range</option>
                </select>
              </div>
            </Grid>

            {/* Monthly Fields */}
            {periodType === "Monthly" && (
              <>
                <Grid item md={2.4} xs={12}>
                  <div>
                    <label className={labelStyle}>
                      Select Month
                      <span className="text-red-500 text-xl font-extrabold pl-1">
                        *
                      </span>
                    </label>
                    <select
                      id="selectedMonth"
                      value={selectedMonth}
                      onChange={(e) => setSelectedMonth(e.target.value)}
                      onKeyPress={handleKeyPress}
                      className={inputStyle}
                    >
                      {" "}
                      <option value="">--Select Month--</option>
                      {months.map((month) => (
                        <option key={month} value={month}>
                          {month}
                        </option>
                      ))}
                    </select>
                  </div>
                </Grid>
                <Grid item md={2.4} xs={12}>
                  <div>
                    <label className={labelStyle}>
                      Select Year
                      <span className="text-red-500 text-xl font-extrabold pl-1">
                        *
                      </span>
                    </label>
                    <select
                      id="selectedYear"
                      value={selectedYear}
                      onChange={(e) => setSelectedYear(e.target.value)}
                      onKeyPress={handleKeyPress}
                      className={inputStyle}
                    >
                      <option value="">--Select Year--</option>
                      {years.map((year) => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      ))}
                    </select>
                  </div>
                </Grid>
              </>
            )}

            {/* Quarterly Fields */}
            {periodType === "Quarterly" && (
              <>
                <Grid item md={2.4} xs={12}>
                  <div>
                    <label className={labelStyle}>
                      Select Quarter
                      <span className="text-red-500 text-xl font-extrabold pl-1">
                        *
                      </span>
                    </label>
                    <select
                      id="selectedQuarter"
                      value={selectedQuarter}
                      onChange={(e) => setSelectedQuarter(e.target.value)}
                      onKeyPress={handleKeyPress}
                      className={inputStyle}
                    >
                      {" "}
                      <option value="">--Select Quarter--</option>
                      {quarters.map((quarter) => (
                        <option key={quarter.bName} value={quarter.bName}>
                          {quarter.fName}
                        </option>
                      ))}
                    </select>
                  </div>
                </Grid>
                <Grid item md={2.4} xs={12}>
                  <div>
                    <label className={labelStyle}>
                      Select Year
                      <span className="text-red-500 text-xl font-extrabold pl-1">
                        *
                      </span>
                    </label>
                    <select
                      id="selectedYear"
                      value={selectedYear}
                      onChange={(e) => setSelectedYear(e.target.value)}
                      onKeyPress={handleKeyPress}
                      className={inputStyle}
                    >
                      <option value="">--Select Year--</option>
                      {years.map((year) => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      ))}
                    </select>
                  </div>
                </Grid>
              </>
            )}

            {/* Yearly Fields */}
            {periodType === "Yearly" && (
              <Grid item md={2.4} xs={12}>
                <div>
                  <label className={labelStyle}>
                    Select Year
                    <span className="text-red-500 text-xl font-extrabold pl-1">
                      *
                    </span>
                  </label>
                  <select
                    id="selectedYear"
                    value={selectedYear}
                    onChange={(e) => setSelectedYear(e.target.value)}
                    onKeyPress={handleKeyPress}
                    className={inputStyle}
                  >
                    <option value="">--Select Year--</option>
                    {years.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
            )}

            {/* Custom Range Fields */}

            {periodType === "CustomRange" && (
              <>
                <Grid item md={2.4} xs={12}>
                  <div>
                    <label className={labelStyle}>
                      From Date{" "}
                      <span className="text-red-500 text-xl font-extrabold pl-1">
                        *
                      </span>
                    </label>
                    {/* <DatePicker
                      id="mypodatepicker"
                      value={startDate ? dayjs(startDate) : null}
                      format="MM/DD/YYYY"
                      className={datepickerStyle}
                       disabledDate={disabledDate}
                      onChange={(date) => {
                        // Ensure we get the exact date selected
                        const selectedDate = date
                          ? date.startOf("day").toDate()
                          : null;
                        setStartDate(selectedDate);

                        // If end date exists and is now invalid (more than 29 days or before start date), reset it
                        if (endDate && selectedDate) {
                          const daysDiff = Math.floor(
                            (endDate - selectedDate) / (1000 * 60 * 60 * 24)
                          );
                          if (daysDiff > 29 || daysDiff < 0) {
                            setEndDate(null);
                          }
                        }
                      }}
                      placeholder="select date"
                    /> */}

<DatePicker
  id="mypodatepicker"
  value={startDate ? dayjs(startDate) : null}
  format="MM/DD/YYYY"
  className={datepickerStyle}
  disabledDate={(current) => {
    // Convert businessDate to dayjs object if it's not already
    const businessDateObj = typeof businessDate === 'string' 
      ? dayjs(businessDate) 
      : dayjs(businessDate);
    
    // Disable dates before businessDate or after today
    return (
      current.isBefore(businessDateObj, 'day') || 
      current.isAfter(today, 'day')
    );
  }}
  onChange={(date) => {
    // Ensure we get the exact date selected
    const selectedDate = date
      ? date.startOf("day").toDate()
      : null;
    setStartDate(selectedDate);

    // If end date exists and is now invalid (more than 29 days or before start date), reset it
    if (endDate && selectedDate) {
      const daysDiff = Math.floor(
        (endDate - selectedDate) / (1000 * 60 * 60 * 24)
      );
      if (daysDiff > 29 || daysDiff < 0) {
        setEndDate(null);
      }
    }
  }}
  placeholder="select date"
/>
                  </div>
                </Grid>
                <Grid item md={2.4} xs={12}>
                  <div>
                    <label className={labelStyle}>
                      To Date{" "}
                      <span className="text-red-500 text-xl font-extrabold pl-1">
                        *
                      </span>
                    </label>
                    {/* <DatePicker
                      id="mypodatepicker"
                      value={endDate ? dayjs(endDate) : null}
                      format="MM/DD/YYYY"
                      className={datepickerStyle}
                      onChange={(date) =>
                        setEndDate(date ? date.startOf("day").toDate() : null)
                      }
                      placeholder="select date"
                      // disabledDate={(current) => {
                      //   if (!startDate || !current) {
                      //     return false;
                      //   }
                      //   {disabledDate}
                      //   // Disable dates before start date
                      //   const startDateObj = dayjs(startDate);
                      //   if (current.isBefore(startDateObj, "day")) {
                      //     return true;
                      //   }

                      //   // Disable dates more than 29 days after start date
                      //   const maxDate = startDateObj.add(29, "day");
                      //   return current.isAfter(maxDate, "day");
                      // }}
                      disabledDate={disabledDate}
                    /> */}

<DatePicker
  id="mypodatepicker"
  value={endDate ? dayjs(endDate) : null}
  format="MM/DD/YYYY"
  className={datepickerStyle}
  onChange={(date) =>
    setEndDate(date ? date.startOf("day").toDate() : null)
  }
  placeholder="select date"
  disabledDate={(current) => {
    // First apply the same business date and today constraints
    const businessDateObj = typeof businessDate === 'string' 
      ? dayjs(businessDate) 
      : dayjs(businessDate);
    
    if (current.isBefore(businessDateObj, 'day') || current.isAfter(today, 'day')) {
      return true;
    }
    
    // Then apply the startDate and max range constraints
    if (!startDate || !current) {
      return false;
    }
    
    // Disable dates before start date
    const startDateObj = dayjs(startDate);
    if (current.isBefore(startDateObj, 'day')) {
      return true;
    }

    // Disable dates more than 29 days after start date
    const maxDate = startDateObj.add(29, 'day');
    return current.isAfter(maxDate, 'day');
  }}
/>

                  </div>
                </Grid>
              </>
            )}

            {/* {periodType === "CustomRange" && (
              <>
                <Grid item md={2.4} xs={12}>
                  <div>
                    <label className={labelStyle}>
                      From Date{" "}
                      <span className="text-red-500 text-xl font-extrabold pl-1">
                        *
                      </span>
                    </label>
                    {/* <DatePicker
                      id="mypodatepicker"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      className={datepickerStyle}
                      dateFormat="MM/dd/yyyy"
                     
                    /> */}
            {/* <DatePicker
    id="mypodatepicker"
    value={startDate ? dayjs(startDate) : null}
    format="MM/DD/YYYY"
    className={datepickerStyle}
    onChange={(date) => setStartDate(date ? date.toDate() : null)}
    placeholder="select date"
  />
                  </div>
                </Grid>
                <Grid item md={2.4} xs={12}>
                  <div>
                    <label className={labelStyle}>
                      To Date{" "}
                      <span className="text-red-500 text-xl font-extrabold pl-1">
                        *
                      </span>
                    </label> */}
            {/* <DatePicker
                    id="mypodatepicker"
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      className={datepickerStyle}
                      dateFormat="MM/dd/yyyy"
                      minDate={startDate}
                    /> */}

            {/* <DatePicker
    id="mypodatepicker"
    value={endDate ? dayjs(endDate) : null}
    format="MM/DD/YYYY"
    className={datepickerStyle}
    onChange={(date) => setEndDate(date ? date.toDate() : null)}
   placeholder="select date"
    disabledDate={(current) => {
      return startDate ? current && current < dayjs(startDate) : false;
    }}
  />

                  </div>
                </Grid> */}

            {/* <Grid item md={2.4} xs={12}>
                  <div>
                    <label className={labelStyle}>
                      From Date
                      <span className="text-red-500 text-xl font-extrabold pl-1">
                        *
                      </span>
                    </label> */}
            {/* <DatePicker                                          
                                            selected={startDate}
                                            format="MM-DD-YYYY"
                                            className={datepickerStyle}
                                            // value={startDateParam}
                                            onChange={(date) => setStartDate(date)}
                                            placeholder="MM-DD-YYYY"
                                          /> */}
            {/* <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      className={inputStyle}
                      dateFormat="MM/dd/yyyy"
                      placeholder
                      // value={startDateParam}
                    /> */}
            {/* <DatePicker
                      selected={startDate}
                      dateFormat="MM-dd-yyyy"
                      className={datepickerStyle}
                      onChange={(date) => setStartDate(date)}
                      placeholderText="MM-DD-YYYY"
                      popperClassName="z-50"
                    /> */}
            {/* <DatePicker
  value={startDate ? dayjs(startDate) : null}
  format="MM-DD-YYYY"
  // id="mypodatepicker"
  className={datepickerStyle}
  onChange={(date) => setStartDate(date)}
  placeholder="MM-DD-YYYY"
/>
                  </div>
                </Grid>
                <Grid item md={2.1}>
                  <div>
                    <label>
                      To Date
                      <span className="text-red-500 text-xl font-extrabold pl-1">
                        *
                      </span>
                    </label> */}
            {/* <DatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      className={inputStyle}
                      dateFormat="MM/dd/yyyy"
                      minDate={startDate}
                      // value={endDateParam}
                    /> */}
            {/* <DatePicker
                                            // id="invoiceDate"
                                            selected={endDate}
                                            format="MM-DD-YYYY"
                                            className={datepickerStyle} 
                                            // value={startDateParam}
                                            onChange={(date) => setEndDate(date)}
                                            minDate={startDate}
                                            placeholder="MM-DD-YYYY"
                                          /> */}
            {/* <DatePicker
                      selected={startDate}
                      dateFormat="MM-dd-yyyy"
                      className={datepickerStyle}
                      onChange={(date) => setStartDate(date)}
                      placeholderText="MM-DD-YYYY"
                      popperClassName="z-50"
                    /> */}
            {/* <DatePicker
  value={endDate ? dayjs(endDate) : null}
  format="MM-DD-YYYY"
  className={datepickerStyle}
  placeholder="MM-DD-YYYY"
  onChange={(date) => setEndDate(date)}
  minDate={startDate}
/> */}
            {/* </div>
                </Grid> */}
            {/* </> */}
            {/* )} */}

            {/* Run Button */}
            <Grid item>
              {/* <div > */}
              <Button
                variant="contained"
                disabled={!isButtonEnabled()}
                sx={buttonStyle}
                endIcon={<SendIcon />}
                onClick={handleRun}
              >
                Run
              </Button>
              {/* </div> */}
            </Grid>
          </Grid>
          {/* </Box> */}
        </div>

        {/* Results Display */}
        {runClicked && (
          <div id="runclicked">
            <div
              className="bg-white"
              style={{
                borderRadius: "20px",
                maxWidth: "100%",
                overflowX: "auto",
                margin: "0 auto",
                boxShadow: "0 0 25px 0 rgba(0, 0, 0, 0.2)",
                marginTop: "45px",
                padding: "20px",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
                paddingBottom: "80px"
              }}
            >
              {!notFound && poData.PO_data && poData.PO_data.length > 0 && (
                <Grid container alignItems="end" justifyContent="end">
                  <Grid item xs={12} md={4}>
                    <Box p={2} className="font-bold text-md text-gray-400">
                      {runClicked && <p>Report RunTime: {dateTime}</p>}
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Grid container alignItems="end" justifyContent="end">
                      {/* PDF Button */}
                      <Grid item>
                        <Tooltip
                          title="PDF"
                          position="top"
                          trigger="mouseenter"
                        >
                          <IconButton
                            aria-label="Download PDF"
                            sx={{
                              backgroundColor: "transparent",
                              borderRadius: "50%",
                              padding: "12px",
                              "&:hover": {
                                backgroundColor: "rgba(227, 140, 151, 0.3)"
                              },
                              transition: "background-color 0.3s ease"
                            }}
                            onMouseEnter={() => setPdfHovered(true)}
                            onMouseLeave={() => setPdfHovered(false)}
                            // onClick={handlePdfDownload}
                          >
                            {pdfhovered ? (
                              <FileDownloadIcon
                                sx={{ fontSize: 20, color: "red" }}
                              />
                            ) : (
                              <PictureAsPdfIcon
                                fontSize="small"
                                color="error"
                              />
                            )}
                          </IconButton>
                        </Tooltip>
                      </Grid>

                      {/* Print Button */}
                      <Grid item>
                        <Tooltip
                          title="Print"
                          position="top"
                          trigger="mouseenter"
                        >
                          <IconButton
                            aria-label="Print"
                            sx={{
                              backgroundColor: "transparent",
                              borderRadius: "50%",
                              padding: "12px",
                              "&:hover": {
                                backgroundColor: "rgba(0, 0, 0, 0.1)"
                              },
                              transition: "background-color 0.3s ease"
                            }}
                            onClick={handlePrint}
                          >
                            <PrintIcon
                              fontSize="small"
                              sx={{ color: "black" }}
                            />
                          </IconButton>
                        </Tooltip>
                      </Grid>

                      {/* Excel Button */}
                      <Grid item>
                        <Tooltip
                          title="Excel"
                          position="top"
                          trigger="mouseenter"
                        >
                          <IconButton
                            aria-label="Excel"
                            sx={{
                              backgroundColor: "transparent",
                              borderRadius: "50%",
                              padding: "12px",
                              "&:hover": {
                                backgroundColor: "rgba(143, 191, 96, 0.3)"
                              },
                              transition: "background-color 0.3s ease"
                            }}
                            onMouseEnter={() => setTaxHovered(true)}
                            onMouseLeave={() => setTaxHovered(false)}
                            onClick={handleExcelDownload}
                          >
                            {taxhovered ? (
                              <FileDownloadIcon
                                sx={{ fontSize: 20, color: themeColor }}
                              />
                            ) : (
                              <DescriptionIcon
                                fontSize="small"
                                style={{ color: "#4CAF50" }}
                              />
                            )}
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}

              <div
                ref={contentRef}
                style={{
                  width: "100%",
                  height: "auto",
                  justifyContent: "center",
                  alignSelf: "center",
                  borderRadius: "10px",
                  boxShadow: "0 0 20px 0 rgba(0, 0, 0, .1)",
                  padding: "20px",
                  marginTop: "20px"
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: "110px",
                    justifyContent: "space-between",
                    marginBottom: "20px",
                    backgroundColor: "rgba(180,180,180,0.1)",
                    padding: "0 20px"
                  }}
                >
                  {/* Business Logo */}
                  {userRole !== "1" && (
                    <div className="">
                      {imageSource && (
                        <img
                          style={{ width: "140px", height: "60px" }}
                          src={`${baseURL}uploads/img/business/${imageSource}`}
                          alt="Business Logo"
                        />
                      )}
                    </div>
                  )}

                  {/* Business Info */}
                  <div
                    style={{
                      textAlign: "center",
                      marginLeft: "auto",
                      marginRight: "auto"
                    }}
                  >
                    <p>
                      <strong
                        style={{
                          fontWeight: "600",
                          fontSize: "18px",
                          margin: "5px"
                        }}
                      >
                        {imageResponse.business_name}
                      </strong>
                    </p>
                    <p>
                      <strong
                        style={{
                          fontWeight: "600",
                          fontSize: "18px",
                          margin: "5px"
                        }}
                      >
                        {imageResponse.business_address}
                      </strong>
                    </p>
                    <p>
                      <strong
                        style={{
                          fontWeight: "600",
                          fontSize: "18px",
                          margin: "5px"
                        }}
                      >
                        {imageResponse.business_city},{" "}
                        {imageResponse.business_state},{" "}
                        {imageResponse.business_zip_code}
                      </strong>
                    </p>
                  </div>

                  {/* System Logo */}
                  <img
                    src="/static/media/logo.0ea4b695a3030ea659a8.png"
                    alt="invoicefilelogo"
                    className="w-max"
                    style={{
                      width: "240px",
                      height: "200px"
                    }}
                  />
                </div>

                {/* {runClicked && (
                  <div
                    style={{
                      color: "#1976d2",
                      textAlign: "center",
                      fontSize: "24px",
                      fontWeight: "bold",
                      marginTop: "50px"
                    }}
                    className="mb-1"
                  >
                    PO's Report Order Details
                    <hr className="border-t border-gray-300 mb-1 mt-2" />
                  </div>
                )} */}

                {/* Data Display */}
                {runClicked && loading ? (
                  <FullScreenLoader />
                ) : runClicked &&
                  poData?.PO_data &&
                  poData.PO_data.length > 0 ? (
                  <div>
                    {/* Period Information */}
                    <div
                      style={{
                        color: "#1976d2",
                        textAlign: "center",
                        fontSize: "21px",
                        fontWeight: "bold",
                        marginTop: "50px"
                      }}
                      className="border-b border-gray-300 mb-1 mt-2"
                    >
                      {poData.reportType === "Yearly" &&
                        poData.year &&
                        `${poData.year} - PO's Report ${
                          poData.brandId === "all" ? "All" : poData.brandId
                        } `}

                      {poData.reportType === "Monthly" &&
                        poData.year &&
                        poData.monthName &&
                        `${poData.monthName} - ${poData.year} - PO's Report ${
                          poData.brandId === "all" ? "All" : poData.brandId
                        } `}

                      {poData.reportType === "Quarterly" &&
                        poData.year &&
                        poData.quarterName &&
                        `${poData.quarterName === "Q1" ? "Q1 (Mar-May)" : ""} ${
                          poData.quarterName === "Q2" ? "Q2 (June-Aug)" : ""
                        } ${
                          poData.quarterName === "Q3" ? "Q3 (Sep-Nov)" : ""
                        } ${
                          poData.quarterName === "Q4" ? "Q4 (Dec-Feb)" : ""
                        } - ${poData.year} - PO's Report ${
                          poData.brandId === "all" ? "" : poData.brandId
                        } `}

                      {poData.reportType === "CustomRange" &&
                        poData.from &&
                        poData.to &&
                        `${formatDate(poData.from)} to ${formatDate(
                          poData.to
                        )} - PO's Report ${
                          poData.brandId === "all" ? "" : poData.brandId
                        } `}
                    </div>
                    {/* <div style={{ textAlign: "center", margin: "20px 0" }}>
                      <p style={{ fontSize: "18px", fontWeight: "500" }}>
                        {periodType === "CustomRange" &&
                          `Date Range: ${formatDate(
                            poData.from
                          )} to ${formatDate(poData.to)}`}
                        {periodType === "Monthly" &&
                          `Month: ${selectedMonth} ${selectedYear}`}
                        {periodType === "Quarterly" &&
                          `Quarter: ${selectedQuarter} ${selectedYear}`}
                        {periodType === "Yearly" && `Year: ${selectedYear}`}
                      </p>
                    </div> */}

                    {/* PO Data Table */}
                    <table
                      style={{
                        width: "50%",
                        maxWidth: "600px",
                        margin: "0px auto",
                        fontWeight: "normal",
                        borderCollapse: "collapse"
                      }}
                    >
                      <thead>
                        <tr style={{ backgroundColor: "#f5f5f5" }}>
                          <th
                            style={{
                              color: "#1976d2",
                              padding: "10px",
                              textAlign: "left",
                              borderBottom: "1px solid #ddd",
                              fontWeight: "700",
                              width: "200px"
                            }}
                          >
                            PO #
                          </th>
                          <th
                            style={{
                              color: "#1976d2",
                              padding: "10px",
                              textAlign: "left",
                              borderBottom: "1px solid #ddd",
                              width: "200px",
                              fontWeight: "700",
                              textAlign: "center"
                            }}
                          >
                            Date
                          </th>
                          {/* <th
                            style={{
                              padding: "10px",
                              textAlign: "center",
                              borderBottom: "1px solid #ddd"
                            }}
                          >
                            Brand
                          </th> */}
                          <th
                            style={{
                              color: "#1976d2",
                              padding: "10px",
                              textAlign: "right",
                              borderBottom: "1px solid #ddd",
                              width: "200px",
                              fontWeight: "700"
                            }}
                          >
                            Brand
                          </th>
                          {/* <th
                            style={{
                              padding: "10px",
                              textAlign: "right",
                              borderBottom: "1px solid #ddd"
                            }}
                          >
                            Gallons
                          </th>
                          <th
                            style={{
                              padding: "10px",
                              textAlign: "right",
                              borderBottom: "1px solid #ddd"
                            }}
                          >
                            Amount
                          </th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {poData.PO_data.map((po) => (
                          <tr
                            key={po.id}
                            style={{ borderBottom: "1px solid #eee" }}
                          >
                            <td style={{ padding: "10px", textAlign: "left" }}>
                              <a
                                href="#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleView(
                                    po.img_file,
                                    po.brand_invoice_number
                                  );
                                }}
                                style={{
                                  color: "#1976d2",
                                  textDecoration: "underline",
                                  fontWeight: "500"
                                }}
                              >
                                {po.brand_invoice_number}
                              </a>
                            </td>
                            <td
                              style={{ padding: "10px", textAlign: "center" }}
                            >
                              {formatDate(po.brand_invoice_date)}
                            </td>
                            {/* <td
                              style={{
                                padding: "10px",
                                textAlign: "center",
                                textTransform: "capitalize"
                              }}
                            >
                              {po.seller_name}
                            </td> */}
                            <td
                              style={{
                                padding: "10px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                                maxWidth: "200px"
                              }}
                            >
                              {po.brand_logo ? (
                                <img
                                  src={`${baseURL}img/${po.brand_logo}`}
                                  alt={`${po.seller_name} Logo`}
                                  style={{
                                    width: "50px",
                                    height: "30px",
                                    objectFit: "contain"
                                    // margin: "0 auto"
                                  }}
                                />
                              ) : (
                                <span
                                  style={{ color: "#666", fontStyle: "italic" }}
                                >
                                  No Logo
                                </span>
                              )}
                            </td>
                            {/* <td style={{ padding: "10px", textAlign: "right" }}>
                              {formatNumberwithoutzeros(po.total_gallons)} gl
                            </td>
                            <td style={{ padding: "10px", textAlign: "right" }}>
                               $ {formatNumber(po.total_amount)}
                            </td> */}
                          </tr>
                        ))}
                      </tbody>
                      {/* <tfoot>
                        <tr style={{ backgroundColor: "#f5f5f5", fontWeight: "bold" }}>
                          <td colSpan="4" style={{ padding: "10px", textAlign: "right", borderTop: "2px solid #ddd" }}>Total:</td>
                          <td style={{ padding: "10px", textAlign: "right", borderTop: "2px solid #ddd" }}>{formatNumberwithoutzeros(totalGallons)} gl</td>
                          <td style={{ padding: "10px", textAlign: "right", borderTop: "2px solid #ddd" }}>$ {formatNumber(totalAmount)}</td>
                        </tr>
                      </tfoot> */}
                    </table>
                  </div>
                ) : (
                  runClicked &&
                  !loading && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "40px",
                        marginBottom: "40px"
                      }}
                    >
                      <p
                        style={{
                          color: "red",
                          fontSize: "18px",
                          fontWeight: "bold",
                          textAlign: "center",
                          padding: "15px 25px",
                          backgroundColor: "rgba(255, 0, 0, 0.05)",
                          borderRadius: "8px",
                          border: "1px solid rgba(255, 0, 0, 0.2)"
                        }}
                      >
                        No Data Available
                      </p>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        )}

        {/* Modal Component */}
        <PurchaseImagesViewModel
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          selectedImagePath={selectedImagePath}
          selectedInvoiceNumberforModel={selectedInvoiceNumberforModel}
        />
      </>
    </div>
  );
};
export default MyPoReport;

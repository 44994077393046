// import React, { useState } from 'react';
// import DescriptionIcon from '@mui/icons-material/Description';
// import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
// import StarIcon from '@mui/icons-material/Star';
// import BoltIcon from '@mui/icons-material/Bolt';

// // Dummy data for fuel cards
// const fuelCardsData = [
//   {
//     id: 1,
//     fuelType: "Regular",
//     gallons: 11456,
//     amount: 34324.59,
//     color: "gray",
//     icon: "DescriptionIcon"
//   },
//   {
//     id: 2,
//     fuelType: "Diesel",
//     gallons: 8752,
//     amount: 29845.36,
//     color: "blue",
//     icon: "LocalGasStationIcon"
//   },
//   {
//     id: 3,
//     fuelType: "Premium",
//     gallons: 5324,
//     amount: 21567.89,
//     color: "green",
//     icon: "StarIcon"
//   },
//   {
//     id: 4,
//     fuelType: "Ultra",
//     gallons: 3245,
//     amount: 15234.47,
//     color: "purple",
//     icon: "BoltIcon"
//   }
// ];

// const FuelCards = () => {
//   const [clicked, setClicked] = useState('Regular');
//   const [filteredStatus, setFilteredStatus] = useState('Regular');

//   // Function to get the appropriate icon based on fuel type
//   const getIcon = (iconName, color) => {
//     switch (iconName) {
//       case 'DescriptionIcon':
//         return <DescriptionIcon sx={{ fontSize: 30 }} className={`text-${color}-500 mt-2`} />;
//       case 'LocalGasStationIcon':
//         return <LocalGasStationIcon sx={{ fontSize: 30 }} className={`text-${color}-500 mt-2`} />;
//       case 'StarIcon':
//         return <StarIcon sx={{ fontSize: 30 }} className={`text-${color}-500 mt-2`} />;
//       case 'BoltIcon':
//         return <BoltIcon sx={{ fontSize: 30 }} className={`text-${color}-500 mt-2`} />;
//       default:
//         return <DescriptionIcon sx={{ fontSize: 30 }} className={`text-${color}-500 mt-2`} />;
//     }
//   };

//   // Function to format numbers with commas
//   const formatNumber = (number) => {
//     return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
//   };

//   // Function to format currency
//   const formatCurrency = (amount) => {
//     return new Intl.NumberFormat('en-US', {
//       style: 'currency',
//       currency: 'USD',
//       minimumFractionDigits: 2
//     }).format(amount);
//   };

//   return (
//     <div className="flex justify-start space-x-6 mt-[20px]">
//       {fuelCardsData.map((card) => (
//         <div
//           key={card.id}
//           className={`flex flex-col items-center justify-between w-[200px] bg-white rounded-lg p-4 relative hover:scale-105 hover:border-l-[6px] hover:border-${card.color}-500 transition-all duration-300 ${
//             clicked === card.fuelType ? `border-l-[6px] border-${card.color}-500` : ''
//           }`}
//           style={{
//             boxShadow: '0 0 20px 0 rgba(0, 0, 0,.2)',
//             height: '90px',
//           }}
//           onClick={() => { 
//             setFilteredStatus(card.fuelType); 
//             setClicked(card.fuelType); 
//           }}
//         >
//           <div className="flex justify-between w-full">
//             <span className={`text-sm text-${card.color}-500 font-bold`}>{card.fuelType}</span>
//           </div>
//           <div className="flex flex-col">
//             <h2 className={`absolute bottom-2 left-4 text-2xl font-bold text-${card.color}-500 mt-2`}>
//               {formatNumber(card.gallons)} gl
//             </h2>
//             <span className="text-xs text-gray-400 absolute bottom-8 left-4">
//               {formatCurrency(card.amount)}
//             </span>
//           </div>
//           <div className="absolute bottom-0 right-0 w-[100px] h-[55px] bg-gradient-to-tl from-[#ffffff] to-transparent rounded-tl-full flex items-center justify-center">
//             {getIcon(card.icon, card.color)}
//           </div>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default FuelCards;



import React, { useState } from 'react';
import DescriptionIcon from '@mui/icons-material/Description';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import StarIcon from '@mui/icons-material/Star';
import BoltIcon from '@mui/icons-material/Bolt';
import TruckIcon from '../../src/assets/images/TruckIcon.png';
// Fuel card data
const fuelCardsData = [
  {
    id: 1,
    fuelType: "Regular",
    gallons: 11456,
    amount: 34324.59,
    color: "gray",
    icon: "TruckIcon"
  },
  {
    id: 2,
    fuelType: "Diesel",
    gallons: 8752,
    amount: 29845.36,
    color: "blue",
    icon: "LocalGasStationIcon"
  },
  {
    id: 3,
    fuelType: "Premium",
    gallons: 5324,
    amount: 21567.89,
    color: "green",
    icon: "StarIcon"
  },
  {
    id: 4,
    fuelType: "Ultra",
    gallons: 3245,
    amount: 15234.47,
    color: "purple",
    icon: "BoltIcon"
  }
];

const FuelCards = () => {
  const [clicked, setClicked] = useState('Regular');

  // Function to return appropriate icon
  const getIcon = (iconName, color) => {
    switch (iconName) {
      case 'TruckIcon':
        return <img src={TruckIcon} width={70} height={40} sx={{ fontSize: 50 }} className={`text-${color}-500`} />;
      case 'LocalGasStationIcon':
        return <LocalGasStationIcon sx={{ fontSize: 30 }} className={`text-${color}-500`} />;
      case 'StarIcon':
        return <StarIcon sx={{ fontSize: 30 }} className={`text-${color}-500`} />;
      case 'BoltIcon':
        return <BoltIcon sx={{ fontSize: 30 }} className={`text-${color}-500`} />;
      default:
        return <DescriptionIcon sx={{ fontSize: 30 }} className={`text-${color}-500`} />;
    }
  };

  // Format numbers with commas
  const formatNumber = (number) => number.toLocaleString();

  // Format currency as USD
  // const formatCurrency = (amount) => 
  //   new Intl.NumberFormat('en-US', {
  //     style: 'currency',
  //     currency: 'USD',
  //     minimumFractionDigits: 2
  //   }).format(amount);

// Format currency as USD with space after $ symbol
const formatCurrency = (amount) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  });
  
  return formatter.format(amount).replace('$', '$ ');
};

  return (
    <div className="flex justify-start gap-4">
      {fuelCardsData.map((card) => (
        <div
          key={card.id}
          className={`relative flex flex-col w-[210px] bg-white rounded-lg p-4 transition-all duration-300 
                      shadow-md hover:scale-105 hover:border-l-[6px] hover:border-${card.color}-500
                      ${clicked === card.fuelType ? `border-l-[6px] border-${card.color}-500` : ''}`}
          style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)', height: '100px' }}
          onClick={() => setClicked(card.fuelType)}
        >
          {/* Fuel Type Header */}
          <div className="flex items-center justify-between">
            <span className={`text-sm font-semibold text-${card.color}-500`}>{card.fuelType}</span>
            <h2 className={`text-sm font-bold text-${card.color}-500 mt-1`}>
              {formatNumber(card.gallons)} gl
            </h2>
          </div>
          {/* <div className="flex items-center justify-between">
            <div className="absolute bottom-0 right-0 w-[80px] h-[90px]   rounded-tl-full flex items-center justify-center">
            {getIcon(card.icon, card.color)}
          </div>
          <div className="mt-1">
            <span className="text-gray-400 text-sm font-bold">{formatCurrency(card.amount)}</span>            
          </div>
          </div> */}
<div className="flex items-center justify-between">
  {/* Icon Container - Now on the left */}
  <div className="w-[110px] h-[70px] flex items-center justify-start mr-2">
    {getIcon(card.icon, card.color)}
  </div>

  {/* Gallons & Amount Section - Now on the right */}
  <div className="mt-1 text-right">
    <span className="text-gray-400 text-sm font-bold gap-3" style={{whiteSpace:'nowrap'}}> {formatCurrency(card.amount)}</span>
  </div>
</div>

          
        </div>
      ))}
    </div>
  );
};

export default FuelCards;

// // // // // // import React, {useState} from 'react'
// // // // // // import { useNavigate, Link } from "react-router-dom";
// // // // // // import PurchaseReport from './PurchaseReport';
// // // // // // import Sales from './Sales/Sales';
// // // // // // import InvoiceReport from './Invoice/InvoiceReport';

// // // // // // function HomeReports() {
// // // // // //       const [activeItem, setActiveItem] = useState("View");

// // // // // //       const renderPage = () => {
// // // // // //         switch (activeItem) {
// // // // // //           case "View":
// // // // // //             return <PurchaseReport/>;
// // // // // //           case "Download":
// // // // // //             return <Sales/>;
// // // // // //           case "Invoice":
// // // // // //             return <InvoiceReport/>;
// // // // // //           default:
// // // // // //             return <PurchaseReport/>;
// // // // // //         }
// // // // // //       };

// // // // // //           const handleMenuClick = (item) => {
// // // // // //             setActiveItem(item);
// // // // // //           };

// // // // // //   return (
// // // // // //     <div>
// // // // // //        <div className="flex mb-6 justify-center p-4 rounded-md font-bold text-md items-center w-full mx-auto py-2 bg-white mt-3 ">
// // // // // //       {/* View */}
// // // // // //       <div
// // // // // //         className={`flex items-center cursor-pointer px-4 py-1 ${
// // // // // //           activeItem === "View" ? "border-b-2 border-green-500 text-green-600" : "text-gray-600"
// // // // // //         } border-b border-gray-300  `}
// // // // // //         onClick={() => {handleMenuClick("View"); }}
// // // // // //       >
// // // // // //         {/* <FaEye className="mr-2" /> */}
// // // // // //         <span>Purchases</span>
// // // // // //       </div>

// // // // // //       <div
// // // // // //         className={`flex items-center cursor-pointer px-4 py-1 ${
// // // // // //           activeItem === "Download" ? "border-b-2 border-green-500 text-green-600" : "text-gray-600"
// // // // // //         } border-b border-gray-300 `}
// // // // // //         onClick={() =>{handleMenuClick("Download"); }}
// // // // // //       >
// // // // // //         {/* <FaDownload className="mr-2" /> */}
// // // // // //         <span>Sales</span>
// // // // // //       </div>
// // // // // //       <div
// // // // // //         className={`flex items-center cursor-pointer px-4 py-1 ${
// // // // // //           activeItem === "Invoice" ? "border-b-2 border-green-500 text-green-600" : "text-gray-600"
// // // // // //         } border-b border-gray-300 `}
// // // // // //         onClick={() =>{handleMenuClick("Invoice");}}
// // // // // //       >
// // // // // //         {/* <FaDownload className="mr-2" /> */}
// // // // // //         <span>Invoice</span>
// // // // // //       </div>

// // // // // //     </div>

// // // // // //       {/* Main Content */}
// // // // // //       <div className="w-full p-4">
// // // // // //         {renderPage()}
// // // // // //       </div>
// // // // // //     </div>
// // // // // //   )
// // // // // // }

// // // // // // export default HomeReports

// // // // import React from "react";
// // // // import { BiPurchaseTag } from "react-icons/bi";
// // // // import {
// // // //   FiChevronRight,
// // // //   FiDollarSign,
// // // //   FiBriefcase,
// // // //   FiTrendingDown,
// // // // } from "react-icons/fi";
// // // // import { useNavigate } from "react-router-dom";

// // // // const Operations = () => {
// // // //   const navigate = useNavigate();
// // // //   const categories = [
// // // //     {
// // // //       title: "PO’s",
// // // //       icon: <BiPurchaseTag className="w-5 h-5 text-blue-600 mt-2" />,
// // // //       borderColor: "border-blue-200",
// // // //       items: [
// // // //         { code: "101", label: "Search PO" },
// // // //         { code: "102", label: "My PO’s" },
// // // //         { code: "103", label: "POs By Branded" },
// // // //         { code: "104", label: "Pos By unbranded" },
// // // //         // { code: "105", label: "POs by State" },
        
// // // //       ],
// // // //     },
// // // //     {
// // // //       title: "Purchases",
// // // //       icon: <FiDollarSign className="w-5 h-5 text-blue-600 mt-2" />,
// // // //       borderColor: "border-blue-200",
// // // //       items: [
// // // //         { code: "201", label: "Purchases From Branded" },
// // // //         { code: "202", label: "Purchases From Unbranded" },
// // // //         { code: "203", label: "Purchases From Terminal" },
// // // //         // { code: "204", label: "Purchases by State" },
// // // //         { code: "204", label: "Purchases By Fuel Grades" },
// // // //       ],
// // // //     },
// // // //     {
// // // //       title: "Expenses",
// // // //       icon: <FiBriefcase className="w-5 h-5 text-blue-600 mt-2" />,
// // // //       borderColor: "border-emerald-200",
// // // //       items: [
// // // //         { code: "301", label: "Office Expenses" },
// // // //         // { code: "302", label: "Insurance" },
// // // //         // { code: "303", label: "Utility" },
// // // //       ],
// // // //     },
// // // //     {
// // // //       title: "Losses",
// // // //       icon: <FiTrendingDown className="w-5 h-5 text-blue-600 mt-2" />,
// // // //       borderColor: "border-amber-200",
// // // //       items: [{ code: "401", label: "Losses" }],
// // // //     },
// // // //   ];

// // // //   return (
// // // //     <div className="w-full px-6 py-4">
// // // //       <div className="max-w-6xl mx-auto space-y-8">
// // // //         {categories.map((category, idx) => (
// // // //           <div key={idx} className="space-y-2">
// // // //             <div className="flex items-center space-x-2 space-y-2 text-gray-900 font-semibold text-lg">
// // // //               {category.icon}
// // // //               <h2 className="text-base font-semibold text-gray-900">
// // // //                 {category.title}
// // // //               </h2>
// // // //             </div>
// // // //             <div className="grid grid-cols-3 gap-2 mt-1 mx-20">
// // // //               {category.items.map((item, itemIdx) => (
// // // //                 <div
// // // //                   key={itemIdx}
// // // //                   onClick={() => {
// // // //                     if (item.code === "201") {
// // // //                     navigate(`/PurchaseReport`); 
// // // //                     }
// // // //                     if (item.code === "202") {
// // // //                     navigate(`/purchaseUnBrandedReport`); 
// // // //                     }
// // // //                     if (item.code === "203") {
// // // //                     navigate(`/purchaseFromTerminalReport`); 
// // // //                     }
// // // //                     // if (item.code === "204") {
// // // //                     // navigate(`/purchaseByStateReport`); 
// // // //                     // }
// // // //                     if (item.code === "204") {
// // // //                     navigate(`/purchaseByFuelGradeReport`); 
// // // //                     }
// // // //                     if (item.code === "101") {
// // // //                     navigate(`/searchPoReport`); 
// // // //                     }
// // // //                     if (item.code === "102") {
// // // //                     navigate(`/myPoReport`); 
// // // //                     }
// // // //                     if (item.code === "103") {
// // // //                     navigate(`/poByBrandReport`); 
// // // //                     }
// // // //                     if (item.code === "104") {
// // // //                     navigate(`/poByUnBrandReport`); 
// // // //                     }
// // // //                     // if (item.code === "105") {
// // // //                     // navigate(`/poByStateReport`); 
// // // //                     // }
// // // //                     }}
// // // //                   className="flex items-center bg-white rounded-md p-3 hover:bg-white hover:shadow-sm transition-all duration-200 cursor-pointer group"
// // // //                 >
// // // //                   <div className="w-12 text-gray-500 font-mono text-sm font-medium">
// // // //                     {item.code}
// // // //                   </div>
// // // //                   <div className="flex-grow text-gray-700 text-sm">
// // // //                     {item.label}
// // // //                   </div>
// // // //                   <FiChevronRight className="w-4 h-4 text-gray-400 opacity-0 group-hover:opacity-100 transition-opacity" />{" "}
                 
// // // //                 </div>
// // // //               ))}
// // // //             </div>
// // // //           </div>
// // // //         ))}
// // // //       </div>
// // // //     </div>
// // // //   );
// // // // };

// // // // export default Operations;



// // // import React, { useState } from "react";
// // // import { BiPurchaseTag } from "react-icons/bi";
// // // import {
// // //   FiChevronRight,
// // //   FiDollarSign,
// // //   FiBriefcase,
// // //   FiTrendingDown,
// // // } from "react-icons/fi";
// // // import { useNavigate } from "react-router-dom";

// // // const Operations = () => {
// // //   const navigate = useNavigate();
// // //   const [activeItem, setActiveItem] = useState(null);
  
// // //   const categories = [
// // //     {
// // //       title: "PO's",
// // //       icon: <BiPurchaseTag className="w-5 h-5 text-blue-600" />,
// // //       color: "border-blue-200",
// // //       items: [
// // //         { code: "101", label: "Search PO" },
// // //         { code: "102", label: "My PO's" },
// // //         { code: "103", label: "POs By Branded" },
// // //         { code: "104", label: "Pos By unbranded" },
// // //         // { code: "105", label: "POs by State" },
// // //       ],
// // //     },
// // //     {
// // //       title: "Purchases",
// // //       icon: <FiDollarSign className="w-5 h-5 text-blue-600" />,
// // //       color: "border-blue-200",
// // //       items: [
// // //         { code: "201", label: "Purchases From Branded" },
// // //         { code: "202", label: "Purchases From Unbranded" },
// // //         { code: "203", label: "Purchases From Terminal" },
// // //         // { code: "204", label: "Purchases by State" },
// // //         { code: "204", label: "Purchases By Fuel Grades" },
// // //       ],
// // //     },
// // //     {
// // //       title: "Expenses",
// // //       icon: <FiBriefcase className="w-5 h-5 text-blue-600" />,
// // //       color: "border-emerald-200",
// // //       items: [
// // //         { code: "301", label: "Office Expenses" },
// // //         // { code: "302", label: "Insurance" },
// // //         // { code: "303", label: "Utility" },
// // //       ],
// // //     },
// // //     {
// // //       title: "Losses",
// // //       icon: <FiTrendingDown className="w-5 h-5 text-blue-600" />,
// // //       color: "border-amber-200",
// // //       items: [{ code: "401", label: "Losses" }],
// // //     },
// // //   ];

// // //   const handleNavigation = (item) => {
// // //     setActiveItem(item.code);
    
// // //     if (item.code === "201") {
// // //       navigate(`/PurchaseReport`);
// // //     }
// // //     if (item.code === "202") {
// // //       navigate(`/purchaseUnBrandedReport`);
// // //     }
// // //     if (item.code === "203") {
// // //       navigate(`/purchaseFromTerminalReport`);
// // //     }
// // //     if (item.code === "204") {
// // //       navigate(`/purchaseByFuelGradeReport`);
// // //     }
// // //     if (item.code === "101") {
// // //       navigate(`/searchPoReport`);
// // //     }
// // //     if (item.code === "102") {
// // //       navigate(`/myPoReport`);
// // //     }
// // //     if (item.code === "103") {
// // //       navigate(`/poByBrandReport`);
// // //     }
// // //     if (item.code === "104") {
// // //       navigate(`/poByUnBrandReport`);
// // //     }
// // //   };

// // //   return (
// // //     <div className="w-full px-6 py-4 mt-5">
// // //       <div className="max-w-6xl mx-auto">
// // //         <h2 className="text-xl font-bold mb-4">Operations</h2>
        
// // //         <div className="flex items-start gap-4 justify-between">
// // //           {categories.map((category, idx) => (
// // //             <div 
// // //               key={idx} 
// // //               // className="bg-white rounded-lg overflow-hidden w-full"
// // //               // style={{ boxShadow: '0 0 8px rgba(0, 0, 0, 0.2)' }}
// // //             >
// // //               <div className="p-4">
// // //                 <div className="flex items-center space-x-3 mb-4">
// // //                   {category.icon}
// // //                   <h2 className="text-base font-semibold text-gray-900">
// // //                     {category.title}
// // //                   </h2>
// // //                 </div>
// // //                 <div className="space-y-2 " >
// // //                   {category.items.map((item, itemIdx) => (
// // //                     <div
// // //                       key={itemIdx}
// // //                       onClick={() => handleNavigation(item)}
// // //                       style={{ boxShadow: '0 0 8px rgba(0, 0, 0, 0.2)' }}
// // //                       className={`
// // //                         border rounded-md p-2 text-left transition-all duration-200 
// // //                         flex items-center cursor-pointer
// // //                         ${activeItem === item.code 
// // //                           ? " text-white border-l-4 border-l-blue-600 active:scale-95" 
// // //                           : "bg-white hover:bg-gray-50 hover:border-l-4 hover:border-l-blue-600  hover:scale-105"
// // //                         }
// // //                       `}
// // //                     >
// // //                       <div className={`w-10 font-mono text-sm font-medium ${activeItem === item.code ? 'text-white' : 'text-gray-500'}`}>
// // //                         {item.code}
// // //                       </div>
// // //                       <div className={`flex-grow text-sm ${activeItem === item.code ? 'text-white' : 'text-gray-700'}`}>
// // //                         {item.label}
// // //                       </div>
// // //                       <FiChevronRight className={`w-4 h-4 text-gray-400 opacity-0 group-hover:opacity-100 transition-opacity ${activeItem === item.code ? 'text-white' : 'text-gray-400'}`} />
// // //                     </div>
// // //                   ))}
// // //                 </div>
// // //               </div>
// // //             </div>
// // //           ))}
// // //         </div>
// // //       </div>
// // //     </div>
// // //   );
// // // };

// // // export default Operations;




// // import React, { useState } from "react";
// // import { BiPurchaseTag } from "react-icons/bi";
// // import {
// //   FiChevronRight,
// //   FiDollarSign,
// //   FiBriefcase,
// //   FiTrendingDown,
// // } from "react-icons/fi";
// // import { useNavigate } from "react-router-dom";

// // const Operations = () => {
// //   const navigate = useNavigate();
// //   const [activeItem, setActiveItem] = useState(null);
// //   const [hoveredItem, setHoveredItem] = useState(null);
  
// //   const categories = [
// //     {
// //       title: "PO's",
// //       icon: <BiPurchaseTag className="w-6 h-6 text-blue-600" />,
// //       color: "border-blue-200",
// //       items: [
// //         { code: "101", label: "Search PO" },
// //         { code: "102", label: "My PO's" },
// //         { code: "103", label: "POs By Branded" },
// //         { code: "104", label: "Pos By unbranded" },
// //         // { code: "105", label: "POs by State" },
// //       ],
// //     },
// //     {
// //       title: "Purchases",
// //       icon: <FiDollarSign className="w-6 h-6 text-blue-600" />,
// //       color: "border-blue-200",
// //       items: [
// //         { code: "201", label: "Purchases From Branded" },
// //         { code: "202", label: "Purchases From Unbranded" },
// //         { code: "203", label: "Purchases From Terminal" },
// //         // { code: "204", label: "Purchases by State" },
// //         { code: "204", label: "Purchases By Fuel Grades" },
// //       ],
// //     },
// //     {
// //       title: "Expenses",
// //       icon: <FiBriefcase className="w-6 h-6 text-blue-600" />,
// //       color: "border-emerald-200",
// //       items: [
// //         { code: "301", label: "Office Expenses" },
// //         // { code: "302", label: "Insurance" },
// //         // { code: "303", label: "Utility" },
// //       ],
// //     },
// //     {
// //       title: "Losses",
// //       icon: <FiTrendingDown className="w-6 h-6 text-blue-600" />,
// //       color: "border-amber-200",
// //       items: [{ code: "401", label: "Losses" }],
// //     },
// //   ];

// //   const handleNavigation = (item) => {
// //     setActiveItem(item.code);
    
// //     if (item.code === "201") {
// //       navigate(`/PurchaseReport`);
// //     }
// //     if (item.code === "202") {
// //       navigate(`/purchaseUnBrandedReport`);
// //     }
// //     if (item.code === "203") {
// //       navigate(`/purchaseFromTerminalReport`);
// //     }
// //     if (item.code === "204") {
// //       navigate(`/purchaseByFuelGradeReport`);
// //     }
// //     if (item.code === "101") {
// //       navigate(`/searchPoReport`);
// //     }
// //     if (item.code === "102") {
// //       navigate(`/myPoReport`);
// //     }
// //     if (item.code === "103") {
// //       navigate(`/poByBrandReport`);
// //     }
// //     if (item.code === "104") {
// //       navigate(`/poByUnBrandReport`);
// //     }
// //   };

// //   return (
// //     <div className="w-full px-6 py-4 mt-5 bg-gray-50">
// //       <div className="max-w-6xl mx-auto">
// //         {/* <h2 className="text-xl font-bold mb-6">Operations</h2> */}
        
// //         <div className="grid grid-cols-4 gap-6">
// //           {categories.map((category, idx) => (
// //             <div 
// //               key={idx} 
// //               // className="bg-white rounded-lg overflow-hidden shadow-md p-5"
// //             >
// //               <div className="flex items-center space-x-3 mb-5 pb-2 border-b">
// //                 <div className="p-2 bg-blue-50 rounded-full">
// //                   {category.icon}
// //                 </div>
// //                 <h2 className="text-lg font-semibold text-gray-900">
// //                   {category.title}
// //                 </h2>
// //               </div>
// //               <div className="space-y-3">
// //                 {category.items.map((item, itemIdx) => {
// //                   const isActive = activeItem === item.code;
// //                   const isHovered = hoveredItem === `${item.code}-${idx}`;
                  
// //                   return (
// //                     <div
// //                       key={itemIdx}
// //                       onClick={() => handleNavigation(item)}
// //                       onMouseEnter={() => setHoveredItem(`${item.code}-${idx}`)}
// //                       onMouseLeave={() => setHoveredItem(null)}
// //                       style={{ 
// //                         boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
// //                         transform: isHovered ? 'translateY(-2px)' : 'none',
// //                         transition: 'all 0.3s ease'
// //                       }}
// //                       className={`
// //                         border rounded-md p-3 text-left
// //                         flex items-center cursor-pointer
// //                         ${isActive 
// //                           ? "bg-[#25caed] text-white border-l-4 border-l-blue-600" 
// //                           : `bg-white hover:border-l-4 hover:border-l-blue-600 ${isHovered ? 'border-l-4 border-l-blue-600' : ''}`
// //                         }
// //                       `}
// //                     >
// //                       <div className={`w-12 font-mono text-sm font-medium ${isActive ? 'text-white' : 'text-gray-500'}`}>
// //                         {item.code}
// //                       </div>
// //                       <div className={`flex-grow text-sm font-medium ${isActive ? 'text-white' : 'text-gray-700'}`}>
// //                         {item.label}
// //                       </div>
// //                       <FiChevronRight 
// //                         className={`w-5 h-5 ${isActive ? 'text-white' : isHovered ? 'text-blue-500' : 'text-gray-300'}`} 
// //                       />
// //                     </div>
// //                   );
// //                 })}
// //               </div>
// //             </div>
// //           ))}
// //         </div>
// //       </div>
// //     </div>
// //   );
// // };

// // export default Operations;








// import React, { useState } from "react";
// import { BiPurchaseTag } from "react-icons/bi";
// import {
//   FiChevronRight,
//   FiDollarSign,
//   FiBriefcase,
//   FiTrendingDown,
// } from "react-icons/fi";
// import { useNavigate } from "react-router-dom";

// const Operations = () => {
//   const navigate = useNavigate();
//   const [activeItem, setActiveItem] = useState(null);
//   const [hoveredItem, setHoveredItem] = useState(null);
  
//   const categories = [
//     {
//       title: "PO's",
//       icon: <BiPurchaseTag className="w-6 h-6 text-blue-600" />,
//       color: "border-blue-200",
//       items: [
//         { code: "101", label: "Search PO" },
//         { code: "102", label: "My PO's" },
//         { code: "103", label: "PO's By Branded" },
//         { code: "104", label: "PO's By Unbranded" },
//         // { code: "105", label: "POs by State" },
//       ],
//     },
//     {
//       title: "Purchases",
//       icon: <FiDollarSign className="w-6 h-6 text-blue-600" />,
//       color: "border-blue-200",
//       items: [
//         { code: "201", label: "Purchases From Branded" },
//         { code: "202", label: "Purchases From Unbranded" },
//         { code: "203", label: "Purchases From Terminal" },
//         // { code: "204", label: "Purchases by State" },
//         { code: "204", label: "Purchases By Fuel Grades" },
//       ],
//     },
//     {
//       title: "Expenses",
//       icon: <FiBriefcase className="w-6 h-6 text-blue-600" />,
//       color: "border-emerald-200",
//       items: [
//         { code: "301", label: "Office Expenses" },
//         // { code: "302", label: "Insurance" },
//         // { code: "303", label: "Utility" },
//       ],
//     },
//     {
//       title: "Losses",
//       icon: <FiTrendingDown className="w-6 h-6 text-blue-600" />,
//       color: "border-amber-200",
//       items: [{ code: "401", label: "Losses" }],
//     },
//   ];

//   const handleNavigation = (item) => {
//     setActiveItem(item.code);
    
//     if (item.code === "201") {
//       navigate(`/PurchaseReport`);
//     }
//     if (item.code === "202") {
//       navigate(`/purchaseUnBrandedReport`);
//     }
//     if (item.code === "203") {
//       navigate(`/purchaseFromTerminalReport`);
//     }
//     if (item.code === "204") {
//       navigate(`/purchaseByFuelGradeReport`);
//     }
//     if (item.code === "101") {
//       navigate(`/searchPoReport`);
//     }
//     if (item.code === "102") {
//       navigate(`/myPoReport`);
//     }
//     if (item.code === "103") {
//       navigate(`/poByBrandReport`);
//     }
//     if (item.code === "104") {
//       navigate(`/poByUnBrandReport`);
//     }
//   };

//   return (
//     <div className="w-full px-6 py-4 mt-5 bg-gray-50">
//       <div className="max-w-6xl mx-auto">
        
//         <div className="grid grid-cols-4 gap-6">
//           {categories.map((category, idx) => (
//             <div 
//               key={idx} 
//               // className="bg-white rounded-lg overflow-hidden shadow-md p-5"
//             >
//               <div className="flex items-center space-x-3 mb-5 pb-2 border-b">
//                 <div className="p-2 bg-blue-50 rounded-full">
//                   {category.icon}
//                 </div>
//                 <h2 className="text-lg font-semibold text-gray-900">
//                   {category.title}
//                 </h2>
//               </div>
//               <div className="space-y-3">
//                 {category.items.map((item, itemIdx) => {
//                   // const isActive = activeItem === item.code;
//                   // const isHovered = hoveredItem === `${item.code}-${idx}`;
//                   const isActive = activeItem === item.code;
//                   const isHovered = hoveredItem === `${item.code}-${idx}`;
//                   const hasNavigation = itemsWithNavigation.includes(item.code);
//                   return (
//                     // <div
//                     //   key={itemIdx}
//                     //   onClick={() => handleNavigation(item)}
//                     //   onMouseEnter={() => setHoveredItem(`${item.code}-${idx}`)}
//                     //   onMouseLeave={() => setHoveredItem(null)}
//                     //   style={{ 
//                     //     boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
//                     //     transform: isHovered ? 'translateY(-2px)' : 'none',
//                     //     transition: 'all 0.3s ease'
//                     //   }}
//                     //   className={`
//                     //     border rounded-md p-3 text-left
//                     //     flex items-center cursor-pointer
//                     //     ${isActive 
//                     //       ? `bg-white hover:border-l-4 hover:border-l-blue-600 ${isHovered ? 'border-l-4 border-l-blue-600' : ''}`
//                     //       : `bg-white hover:border-l-4 hover:border-l-blue-600 ${isHovered ? 'border-l-4 border-l-blue-600' : ''}`
//                     //     }
//                     //   `}
//                     // >
//                     <div
//                     key={itemIdx}
//                     onClick={() => handleNavigation(item)}
//                     onMouseEnter={() => setHoveredItem(`${item.code}-${idx}`)}
//                     onMouseLeave={() => setHoveredItem(null)}
//                     style={{ 
//                       boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
//                       transform: isHovered ? 'translateY(-2px)' : 'none',
//                       transition: 'all 0.3s ease'
//                     }}
//                     className={`
//                       border rounded-md p-3 text-left
//                       flex items-center cursor-pointer
//                       ${isActive 
//                         ? `bg-white border-l-4 border-l-blue-600`
//                         : `bg-white hover:border-l-4 hover:border-l-blue-600 ${isHovered ? 'border-l-4 border-l-blue-600' : ''}`
//                       }
//                     `}
//                   >
//                       {/* <div className={`w-10 font-mono text-sm font-medium ${isHovered ? 'text-black-500' : 'text-black-700'}`}>
//                         {item.code}
//                       </div>
//                       <div className={`flex-grow text-sm font-medium ${isHovered ? 'text-gray-500' : 'text-gray-700'}`}>
//                         {item.label}
//                       </div> */}
//                         <div className={`w-10 font-mono text-sm font-medium ${isHovered || isActive ? 'text-black-500' : 'text-black-700'}`}>
//                         {item.code}
//                       </div>
//                       <div className={`flex-grow text-sm font-medium ${isHovered || isActive ? 'text-gray-500' : 'text-gray-700'}`}>
//                         {item.label}
//                       </div>
//                       {/* <FiChevronRight 
//                         className={`w-5 h-5 ${isActive ? 'text-white text-blue-500 opacity-100' : isHovered ? 'text-blue-500 opacity-100' : 'opacity-0'}`}
//                         style={{ transition: 'all 0.2s ease' }} 
//                       /> */}
//                        <FiChevronRight 
//                         className={`w-5 h-5 ${isActive ? 'text-blue-500 opacity-100' : isHovered ? 'text-blue-500 opacity-100' : 'opacity-0'}`}
//                         style={{ transition: 'all 0.2s ease' }} 
//                       />
//                     </div>
//                   );
//                 })}
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Operations;



import React, { useState } from "react";
import { BiPurchaseTag } from "react-icons/bi";
import {
  FiChevronRight,
  FiDollarSign,
  FiBriefcase,
  FiTrendingDown,
} from "react-icons/fi";
import { useNavigate } from "react-router-dom";

const Operations = () => {
  const navigate = useNavigate();
  const [activeItem, setActiveItem] = useState(null);
  const [hoveredItem, setHoveredItem] = useState(null);
  
  // Define which item codes have navigation functions
  const itemsWithNavigation = ["101", "102", "103", "104", "201", "202", "203", "204"];
  
  const categories = [
    {
      title: "PO's",
      icon: <BiPurchaseTag className="w-6 h-6 text-blue-600" />,
      color: "border-blue-200",
      items: [
        { code: "101", label: "Search PO" },
        { code: "102", label: "My PO's" },
        { code: "103", label: "PO's By Branded" },
        { code: "104", label: "PO's By Unbranded" },
        // { code: "105", label: "POs by State" },
      ],
    },
    {
      title: "Purchases",
      icon: <FiDollarSign className="w-6 h-6 text-blue-600" />,
      color: "border-blue-200",
      items: [
        { code: "201", label: "Purchases From Branded" },
        { code: "202", label: "Purchases From Unbranded" },
        { code: "203", label: "Purchases From Terminal" },
        // { code: "204", label: "Purchases by State" },
        { code: "204", label: "Purchases By Fuel Grades" },
      ],
    },
    {
      title: "Expenses",
      icon: <FiBriefcase className="w-6 h-6 text-blue-600" />,
      color: "border-emerald-200",
      items: [
        { code: "301", label: "Office Expenses" },
        // { code: "302", label: "Insurance" },
        // { code: "303", label: "Utility" },
      ],
    },
    {
      title: "Losses",
      icon: <FiTrendingDown className="w-6 h-6 text-blue-600" />,
      color: "border-amber-200",
      items: [{ code: "401", label: "Losses" }],
    },
  ];

  const handleNavigation = (item) => {
    // Only set active state for items with navigation
    if (itemsWithNavigation.includes(item.code)) {
      setActiveItem(item.code);
      
      // Navigate based on the item code
      switch(item.code) {
        case "201":
          navigate(`/PurchaseReport`);
          break;
        case "202":
          navigate(`/purchaseUnBrandedReport`);
          break;
        case "203":
          navigate(`/purchaseFromTerminalReport`);
          break;
        case "204":
          navigate(`/purchaseByFuelGradeReport`);
          break;
        case "101":
          navigate(`/searchPoReport`);
          break;
        case "102":
          navigate(`/myPoReport`);
          break;
        case "103":
          navigate(`/poByBrandReport`);
          break;
        case "104":
          navigate(`/poByUnBrandReport`);
          break;
        default:
          console.log(`No navigation defined for item code: ${item.code}`);
      }
    } else {
      console.log(`Item ${item.code} has no navigation function`);
    }
  };

  return (
    <div className="w-full px-6 py-4 mt-5 bg-gray-50">
      <div className="max-w-6xl mx-auto">
        
        <div className="grid grid-cols-4 gap-6">
          {categories.map((category, idx) => (
            <div key={idx}>
              <div className="flex items-center space-x-3 mb-5 pb-2 border-b">
                <div className="p-2 bg-blue-50 rounded-full">
                  {category.icon}
                </div>
                <h2 className="text-lg font-semibold text-gray-900">
                  {category.title}
                </h2>
              </div>
              <div className="space-y-3">
                {category.items.map((item, itemIdx) => {
                  const isActive = activeItem === item.code;
                  const isHovered = hoveredItem === `${item.code}-${idx}`;
                  const hasNavigation = itemsWithNavigation.includes(item.code);
                  
                  return (
                    <div
                      key={itemIdx}
                      onClick={() => handleNavigation(item)}
                      onMouseEnter={() => setHoveredItem(`${item.code}-${idx}`)}
                      onMouseLeave={() => setHoveredItem(null)}
                      style={{ 
                        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                        transform: isHovered ? 'translateY(-2px)' : 'none',
                        transition: 'all 0.3s ease'
                      }}
                      className={`
                        border rounded-md p-3 text-left
                        flex items-center cursor-pointer
                        ${isActive 
                          ? `bg-white border-l-4 border-l-blue-600`
                          : `bg-white hover:border-l-4 hover:border-l-blue-600 ${isHovered ? 'border-l-4 border-l-blue-600' : ''}`
                        }
                      `}
                    >
                      <div className={`w-10 font-mono text-sm font-medium ${isHovered || isActive ? 'text-black-500' : 'text-black-700'}`}>
                        {item.code}
                      </div>
                      <div className={`flex-grow text-sm font-medium ${isHovered || isActive ? 'text-gray-500' : 'text-gray-700'}`}>
                        {item.label}
                      </div>
                      <FiChevronRight 
                        className={`w-5 h-5 ${isActive ? 'text-blue-500 opacity-100' : isHovered ? 'text-blue-500 opacity-100' : 'opacity-0'}`}
                        style={{ transition: 'all 0.2s ease' }} 
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Operations;
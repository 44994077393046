// // import React from 'react';
// // import { FiChevronRight, FiShoppingCart, FiFileText, FiChevronDown } from 'react-icons/fi';

// // const Billing = () => {
// //   const categories = [
// //      {
// //           title: 'Payments',
// //           icon: <FiShoppingCart className="w-5 h-5 text-blue-600" />,
// //           // color: 'bg-gradient-to-br from-blue-50 to-blue-100',
// //           color: 'bg-gray-100',
// //           borderColor: 'border-blue-200',
// //           items: [
// //             { code: '701', label: 'Business Pay In’s' },
// //             { code: '702', label: 'Business Pay Out’s' },
// //             { code: '703', label: 'Payments From Customer' },
// //             { code: '704', label: 'Payments To Customers' },
// //           ]
// //         },
// //     {
// //       title: 'Statements',
// //       icon: <FiFileText className="w-5 h-5 text-blue-600" />,
// //       // color: 'bg-gradient-to-br from-blue-50 to-blue-100',
// //       color: 'bg-gray-100',
// //       borderColor: 'border-blue-200',
// //       items: [
// //         { code: '801', label: 'All Statements' },
// //         { code: '802', label: 'Statements By Customer' }
// //       ]
// //     }
// //   ];

// //   return (
// //     <div className="w-full px-6 py-4">
// //       <div className="max-w-6xl mx-auto space-y-8">
// //         {categories.map((category, idx) => (
// //           <div 
// //             key={idx} className="space-y-2"
// //             // className="flex-none basis-1/3 max-w-[33%] bg-white rounded-lg border border-gray-100 overflow-hidden"
// //           >
// //             <div className={`p-4 custombgColor ${category.color}`}>
// //             <div className="flex items-center space-x-2 space-y-2 text-gray-900 font-semibold text-lg">
// //                 {category.icon}
// //                 <h2 className="text-base font-semibold text-gray-900">{category.title}</h2>
// //               </div>
// //               <div className="grid grid-cols-3 gap-2 mt-1 mx-20">
// //                 {category.items.map((item, itemIdx) => (
// //                   <div 
// //                     key={itemIdx}
// //                     // className="flex items-center bg-white border border-gray-200 rounded-md p-3 hover:bg-gray-100 transition cursor-pointer"

// //                     className="flex items-center bg-white rounded-md p-3 hover:bg-white hover:shadow-sm transition-all duration-200 cursor-pointer group"
// //                   >
// //                     <div className="w-10 text-gray-500 font-mono text-sm font-medium">{item.code}</div>
// //                     <div className="flex-grow text-gray-700 text-sm">{item.label}</div>
// //  <FiChevronRight className="w-4 h-4 text-gray-400 opacity-0 group-hover:opacity-100 transition-opacity" />  {/* 🟢 react-icons */}              
// //                </div>
// //                 ))}
// //               </div>
// //             </div>
// //           </div>
// //         ))}
        
// //         {/* Placeholder for third column when only two items exist */}
// //         {categories.length === 2 && (
// //           <div className="flex-none basis-1/3 max-w-[33%] bg-white rounded-lg shadow-sm border border-gray-100" style={{visibility:'hidden'}}></div>
// //         )}
// //       </div>
// //     </div>
// //   );
// // };

// // export default Billing;




// import React, { useState } from 'react';
// import { FiChevronRight, FiShoppingCart, FiFileText } from 'react-icons/fi';
// import { useNavigate } from 'react-router-dom';

// const Billing = () => {
//   const navigate = useNavigate();
//   const [activeItem, setActiveItem] = useState(null);
//   const [hoveredItem, setHoveredItem] = useState(null);
//   const itemsWithNavigation = ["701", "702", "703", "704", "801", "802"];
//   const categories = [
//     {
//       title: 'Payments',
//       icon: <FiShoppingCart className="w-6 h-6 text-blue-600" />,
//       color: "border-blue-200",
//       items: [
//         { code: '701', label: "Business PayIn's" },
//         { code: '702', label: "Business PayOut's" },
//         { code: '703', label: 'Payments From Customer' },
//         { code: '704', label: 'Payments To Customers' },
//       ]
//     },
//     {
//       title: 'Statements',
//       icon: <FiFileText className="w-6 h-6 text-blue-600" />,
//       color: "border-blue-200",
//       items: [
//         { code: '801', label: 'All Statements' },
//         { code: '802', label: 'Statements By Customer' }
//       ]
//     }
//   ];

//   // const handleNavigation = (item) => {
//   //   setActiveItem(item.code);
//   //   // Add your navigation logic here if needed
//   // };


//   const handleNavigation = (item) => {
//     // Only set active state for items with navigation
//     if (itemsWithNavigation.includes(item.code)) {
//       setActiveItem(item.code);
      
//       // Navigate based on the item code
//       switch (item.code) {
//         case "801":
//           navigate(`/allStatements`);
//           break;
//         case "802":
//           navigate(`/statementByCustomer`);
//           break;

       
//         default:
//           console.log(`No navigation defined for item code: ${item.code}`);
//       }
//     } else {
//       console.log(`Item ${item.code} has no navigation function`);
//     }
//   };

//   return (
//     <div className="w-full px-6 py-4 mt-5 bg-gray-50">
//       <div className="max-w-6xl mx-auto">
//         <div className="grid grid-cols-4 gap-6">
//           {categories.map((category, idx) => (
//             <div key={idx}>
//               <div className="flex items-center space-x-3 mb-5 pb-2 border-b">
//                 <div className="p-2 bg-blue-50  rounded-full">
//                   {category.icon}
//                 </div>
//                 <h2 className="text-lg font-semibold text-gray-900">
//                   {category.title}
//                 </h2>
//               </div>
//               <div className="space-y-3">
//                 {category.items.map((item, itemIdx) => {
//                   const isActive = activeItem === item.code;
//                   const isHovered = hoveredItem === `${item.code}-${idx}`;
//                   const hasNavigation = itemsWithNavigation.includes(item.code);                  return (
//                     <div
//                       // key={itemIdx}
//                       // onClick={() => handleNavigation(item)}
//                       // onMouseEnter={() => setHoveredItem(`${item.code}-${idx}`)}
//                       // onMouseLeave={() => setHoveredItem(null)}
//                       // style={{ 
//                       //   boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
//                       //   transform: isHovered ? 'translateY(-2px)' : 'none',
//                       //   transition: 'all 0.3s ease'
//                       // }}
//                       // className={`
//                       //   border rounded-md p-3 text-left
//                       //   flex items-center cursor-pointer
//                       //   ${isActive 
//                       //     ? `bg-white hover:border-l-4 hover:border-l-blue-600 ${isHovered ? 'border-l-4 border-l-blue-600' : ''}`
//                       //     : `bg-white hover:border-l-4 hover:border-l-blue-600 ${isHovered ? 'border-l-4 border-l-blue-600' : ''}`
//                       //   }
//                       // `}
//                       key={itemIdx}
//                       onClick={() => handleNavigation(item)}
//                       onMouseEnter={() => setHoveredItem(`${item.code}-${idx}`)}
//                       onMouseLeave={() => setHoveredItem(null)}
//                       style={{
//                         boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
//                         transform: isHovered ? "translateY(-2px)" : "none",
//                         transition: "all 0.3s ease"
//                       }}
//                       className={`
//                         border rounded-md p-3 text-left
//                         flex items-center cursor-pointer
//                         ${
//                           isActive
//                             ? `bg-white border-l-4 border-l-blue-600`
//                             : `bg-white hover:border-l-4 hover:border-l-blue-600 ${
//                                 isHovered ? "border-l-4 border-l-blue-600" : ""
//                               }`
//                         }
//                       `}
//                     >
//                                             <div className={`w-10 font-mono text-sm font-medium ${
//                           isHovered || isActive
//                             ? "text-black-500"
//                             : "text-black-700"
//                         }`}>
//                         {item.code}
//                       </div>
//                       <div className={`flex-grow text-sm font-medium ${isHovered || isActive ? 'text-gray-500' : 'text-gray-700'}`}>
//                         {item.label}
//                       </div>
//                       {/* <div className={`w-10 font-semibold text-sm font-medium ${isHovered ? 'text-black-500' : 'text-black-700'}`}>
//                         {item.code}
//                       </div>
//                       <div className={`flex-grow text-sm font-medium ${isHovered ? 'text-gray-500' : 'text-gray-700'}`}>
//                         {item.label}
//                       </div> */}
//                       {/* <FiChevronRight 
//                         className={`w-5 h-5 ${isActive ? 'text-white' : isHovered ? 'text-blue-500 opacity-100' : 'opacity-0'}`}
//                         style={{ transition: 'all 0.2s ease' }} 
//                       /> */}
//                     <FiChevronRight 
//                                             className={`w-5 h-5 ${isActive ? 'text-blue-500 opacity-100' : isHovered ? 'text-blue-500 opacity-100' : 'opacity-0'}`}
//                                             style={{ transition: 'all 0.2s ease' }} 
//                                           />
//                     </div>
//                   );
//                 })}
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Billing;



import React, { useState } from 'react';
import { FiChevronRight, FiShoppingCart, FiFileText } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

const Billing = () => {
  const navigate = useNavigate();
  const [activeItem, setActiveItem] = useState(null);
  const [hoveredItem, setHoveredItem] = useState(null);
  const itemsWithNavigation = ["801", "802"]; // Updated to only include items with actual navigation
  
  const categories = [
    {
      title: 'Payments',
      icon: <FiShoppingCart className="w-6 h-6 text-blue-600" />,
      color: "border-blue-200",
      items: [
        { code: '701', label: "Business PayIn's" },
        { code: '702', label: "Business PayOut's" },
        { code: '703', label: 'Payments From Customer' },
        { code: '704', label: 'Payments To Customers' },
      ]
    },
    {
      title: 'Statements',
      icon: <FiFileText className="w-6 h-6 text-blue-600" />,
      color: "border-blue-200",
      items: [
        { code: '801', label: 'All Statements' },
        { code: '802', label: 'Statements By Customer' }
      ]
    }
  ];

  const handleNavigation = (item) => {
    // Only set active state and navigate for items with navigation
    if (itemsWithNavigation.includes(item.code)) {
      setActiveItem(item.code);
      
      // Navigate based on the item code
      switch (item.code) {
        case "801":
          navigate(`/allStatements`);
          break;
        case "802":
          navigate(`/statementByCustomer`);
          break;
        default:
          console.log(`No navigation defined for item code: ${item.code}`);
      }
    } else {
      // For non-navigable items, don't update the active state
      console.log(`Item ${item.code} has no navigation function`);
      // Ensure we don't set an active state for non-navigable items
      setActiveItem(null);
    }
  };

  return (
    <div className="w-full px-6 py-4 mt-5 bg-gray-50">
      <div className="max-w-6xl mx-auto">
        <div className="grid grid-cols-4 gap-6">
          {categories.map((category, idx) => (
            <div key={idx}>
              <div className="flex items-center space-x-3 mb-5 pb-2 border-b">
                <div className="p-2 bg-blue-50 rounded-full">
                  {category.icon}
                </div>
                <h2 className="text-lg font-semibold text-gray-900">
                  {category.title}
                </h2>
              </div>
              <div className="space-y-3">
                {category.items.map((item, itemIdx) => {
                  const isActive = activeItem === item.code;
                  const isHovered = hoveredItem === `${item.code}-${idx}`;
                  const hasNavigation = itemsWithNavigation.includes(item.code);
                  return (
                    <div
                      key={itemIdx}
                      onClick={() => handleNavigation(item)}
                      onMouseEnter={() => setHoveredItem(`${item.code}-${idx}`)}
                      onMouseLeave={() => setHoveredItem(null)}
                      style={{
                        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                        transform: isHovered ? "translateY(-2px)" : "none",
                        transition: "all 0.3s ease"
                      }}
                      className={`
                        border rounded-md p-3 text-left
                        flex items-center cursor-pointer
                        ${
                          hasNavigation && isActive
                            ? `bg-white border-l-4 border-l-blue-600`
                            : `bg-white ${
                                isHovered ? "border-l-4 border-l-blue-600" : ""
                              }`
                        }
                      `}
                    >
                      <div className={`w-10 font-mono text-sm font-medium ${
                        isHovered || (hasNavigation && isActive)
                          ? "text-black-500"
                          : "text-black-700"
                      }`}>
                        {item.code}
                      </div>
                      <div className={`flex-grow text-sm font-medium ${
                        isHovered || (hasNavigation && isActive) 
                          ? 'text-gray-500' 
                          : 'text-gray-700'
                      }`}>
                        {item.label}
                      </div>
                      <FiChevronRight 
                        className={`w-5 h-5 ${
                          (hasNavigation && isActive && !isHovered) 
                            ? "text-blue-500 opacity-100" 
                            : isHovered
                            ? "text-blue-500 opacity-100"
                            : "opacity-0"
                        }`}
                        style={{ transition: 'all 0.2s ease' }} 
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Billing;
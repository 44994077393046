import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import PriceUpdateForm from "./PriceUpdateForm";
import config from "../../config/config";
import { FaHome } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import CustomCommonTab from "../reusable/CustomCommonTab";

const NewPrice = () => {
  const navigate = useNavigate();
  const [filteredStatus, setFilteredStatus] = useState("all");
  const location = useLocation();
  const businessDate = location.state?.businessDate;
  
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [fuelData, setFuelData] = useState([
    {
      id: 1,
      fuelBrand: "Brand A",
      fuelGrade: "Grade 1",
      oldSalesPrice: 3.5,
      newPoPrice: 3.8,
      newSalesPrice: "",
    },
    {
      id: 2,
      fuelBrand: "Brand B",
      fuelGrade: "Grade 2",
      oldSalesPrice: 4.0,
      newPoPrice: 4.3,
      newSalesPrice: "",
    },
    {
      id: 3,
      fuelBrand: "Brand C",
      fuelGrade: "Grade 3",
      oldSalesPrice: 3.75,
      newPoPrice: 4.0,
      newSalesPrice: "",
    },
  ]);
  const [data, setData] = useState(""); // State to store data from child

  // Function to receive data from the child
  const handleChildData = (childValue) => {
    console.log("Received from child:", childValue);
    setData(childValue);
  };
  const handleNewSalesPriceChange = (id, value) => {
    setFuelData((prevData) =>
      prevData.map((item) =>
        item.id === id ? { ...item, newSalesPrice: value } : item
      )
    );
  };
  const [clicked, setClicked] = useState(null);

  const [isSubtotalExpanded, setSubtotalExpanded] = useState(false);
  const [isTaxesExpanded, setTaxesExpanded] = useState(false);

  const toggleSubtotal = (e) => {
    e.preventDefault();
    setSubtotalExpanded(!isSubtotalExpanded); // Toggle Subtotal
    setTaxesExpanded(false); // Ensure Taxes is closed
  };

  const toggleTaxes = (e) => {
    e.preventDefault();
    setTaxesExpanded(!isTaxesExpanded); // Toggle Taxes
    setSubtotalExpanded(false); // Ensure Subtotal is closed
  };
  const userRole = localStorage.getItem("user_role");
  const [activeItem, setActiveItem] = useState("View");

  const handleMenuClick = (item) => {
    setActiveItem(item);
  };
  const colors = [
    "red-500",
    "blue-500",
    "yellow-500",
    "purple-500",
    "green-500",
    "[#25caed]",
  ];
  const baseURL =
    process.env.NODE_ENV === "production"
      ? config.production.baseURL
      : config.development.baseURL;

  const menuItems = [{ name: "Price Book" }];

  return (
    <div className="p-6 pt-0">
      {/* <div className="flex mb-6 justify-center p-4 rounded-md font-bold text-md items-center w-full mx-auto pb-2 pt-0"> */}
      {/* View */}
      <CustomCommonTab items={menuItems} />

      {/* </div> */}
      <div className="flex flex-wrap gap-4 mb-[60px] justify-start">
        {Object.entries(data).map(([brandKey, brandDetails]) => (
          <>
            <div
              key={brandKey}
              className="flex flex-col items-start justify-between w-1/5 bg-white rounded-lg p-4 relative hover:scale-105 hover:border-l-[6px] hover:border-blue-500 transition-all duration-300"
              style={{
                boxShadow: "0 0 20px 0 rgba(0, 0, 0,.2)",
                height: "auto",
                position: "relative",
              }}
            >
              {/* Brand Name */}
              <div className="flex justify-between w-full">
                <span className="text-[18px] text-gray-500 font-bold capitalize">
                  {brandDetails.seller_name}
                </span>
              </div>

              {/* Fuel Grades & Prices */}
              {brandDetails.fuel_prices.length > 0 ? (
                <div className="flex flex-col w-full mt-1">
                  {brandDetails.fuel_prices.flat().map((details, index) => (
                    <div
                      key={index}
                      className="flex justify-between items-center w-full"
                    >
                      <div className="flex flex-1">
                        <span className="text-[16px] text-blue-500 capitalize font-semibold">
                          {details.fuelgrade_name}
                        </span>
                      </div>
                      <div className="flex flex-1 justify-end items-center">
                        <span className="text-[16px] text-blue-500 font-bold">
                          $ {" "}
                          {Number(details.unit_price).toLocaleString(
                             undefined,
                            {
                              minimumFractionDigits: 5,
                              maximumFractionDigits: 5,
                            }
                          )}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <span className="text-gray-500 text-sm mt-2">{""}</span>
              )}

              {/* Logo (Bottom Right) */}
              <img
                src={`${baseURL}/img/${brandDetails.brand_logo}`}
                alt="Business Logo"
                className="w-12 h-12 absolute top-[-20px] right-2 object-contain"
              />
            </div>
          </>
        ))}
      </div>

      <PriceUpdateForm
        sendDataToParent={handleChildData}
        businessDate={businessDate}
      />
    </div>
  );
};

export default NewPrice;

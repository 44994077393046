import React, { useEffect, useMemo, useState, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AiOutlineSearch } from "react-icons/ai";
import { createColumnHelper ,
  useReactTable, 
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel, } from "@tanstack/react-table";
  import { MdFilterList } from "react-icons/md";
  import { AiFillEye } from "react-icons/ai";

import Table from "./Table";
import Search from "../Search/Search";
import { useDispatch, useSelector } from "react-redux";
import config from "../../config/config";
import { Tooltip } from "react-tippy";
import PopUpModalForDeleteVendor from "./VendorDeleteModel";
import { addVendor } from "../../store/vendorSlice";
import { FaArrowDownLong , FaArrowUpLong} from "react-icons/fa6";
import IndeterminateCheckbox from "./IndeterminateCheckbox";
import { MdNavigateNext } from "react-icons/md";
import { MdNavigateBefore } from "react-icons/md";
import ScrollingMessage from "./ScrollingMessage";
import { showToastForAddVendor } from "../toasts/toastForVendor";
import ToastNotificationBox from "./ToastBox/ToastNotificationBox";
import FullScreenLoader from './FullScreenLoader'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DescriptionIcon from '@mui/icons-material/Description';  // For document symbol
import ViewVendor from "../gasForms/ViewVendor";
import TabMenu from "./TabMenu";
import { useLocation } from 'react-router-dom';
import EditInvoice from "../forms/EditInvoice";
import VendorView from "../gasForms/VendorView";
import CustomNavigationButtons from "../reusable/CustomNavigationWithEditDelete";
import { FaHome, FaTimes } from "react-icons/fa";
import CustomCommonTab from "../reusable/CustomCommonTab";


function capitalizeFirstLetter(email) {
  return email.charAt(0).toUpperCase() + email.slice(1).toLowerCase();
}

const VendorScreen = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const allVendors = useSelector(state => state.vendor.allVendors);
  console.log(allVendors);
  const [loading, setLoading] = useState(false)
  const [expandedRow, setExpandedRow] = useState(null);
    const [isSubtotalExpanded, setSubtotalExpanded] = useState(false);
        const [isTaxesExpanded, setTaxesExpanded] = useState(false);
        
        const [TruckNumber, setTruckNumber] = useState('');
 
    
        const toggleSubtotal = (e) => {
          e.preventDefault();
          setSubtotalExpanded(!isSubtotalExpanded); // Toggle Subtotal
          setTaxesExpanded(false); // Ensure Taxes is closed
        };
        
        const toggleTaxes = (e) => {
          e.preventDefault();
          setTaxesExpanded(!isTaxesExpanded); // Toggle Taxes
          setSubtotalExpanded(false); // Ensure Subtotal is closed
        };
  const [clicked, setClicked] = useState(null);
  const [filteredStatus, setFilteredStatus] = useState("all");
  const userRole = localStorage.getItem("user_role")

   const [activeItem, setActiveItem] = useState("View");
  
        const handleMenuClick = (item) => {
          setActiveItem(item);
        };
  const toggleRow = (id) => {
    setExpandedRow((prevRow) => (prevRow === id ? id : id));
  };
  const formatNumberWithCommas = (number) => {
    return new Intl.NumberFormat('en-US').format(number);
  };
  const [vehiclesNames, setVehiclesNames] = useState([]);
  const baseURL =
    process.env.NODE_ENV === "production"
      ? config.production.baseURL
      : config.development.baseURL;

    const [rowSelection, setRowSelection] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const isEditActive = selectedRows.length === 1;
  const isDeleteActive = selectedRows.length > 0;
  const [searchValue, setSearchValue] = useState("");
  const resetRowSelection = useRef(null);
  const [openDeleteModalId, setOpenDeleteModalId] = useState(null);
  const [sorting , setSorting] = React.useState([])
  const [filtering , setFiltering] = useState("")
  const [columnFilters, setColumnFilters] = useState("")

  const navigate = useNavigate();
  const columnHelper = createColumnHelper();

  const formatDate = (dateStr) => {
    const [year, month, day] = dateStr.split("-");
    return `${month}/${day}/${year}`;
  };
  const capitalizeFirstLetterWordWords = (str) => {
    return str
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };
  const lowercaseAllWords = (str) => {
    return str
      .toLowerCase()
  };

  const data = useMemo(() => {
    return allVendors.map((vehicle, index) => ({
      ...vehicle,
      sl_no: index + 1,
      added_on: formatDate(vehicle.added_on),
      truck_company_name: capitalizeFirstLetterWordWords(vehicle.truck_company_name),
      contact_name : capitalizeFirstLetterWordWords(vehicle.contact_name),
      contact_email : capitalizeFirstLetter(vehicle.contact_email)
    }));
  }, [allVendors]);

// console.log(data);

 

  const isRowCheckboxDisabled = (row) => {
    if (rowSelection[row.id]) {
      return false;
    } else {
      return Object.values(rowSelection).some((selected) => selected);
    }
  };
  useEffect(() => {
    const selected= table.getSelectedRowModel().flatRows[0]?.original;
    if (selected) {
      setSelectedRows(selected);
    }
  }, [rowSelection , selectedRows ]);

  const columns = [ 
      columnHelper.accessor("sl_no", {
        header: <div className="text-center">#</div>,
      }),
      columnHelper.accessor("truck_company_name", {
        header: <div className="text-center">Vendor Name</div>,
      }),
      // columnHelper.accessor("ein_number", {
      //   header: <div className="text-center">EIN Number</div>,
      // }),
      columnHelper.accessor("contact_name", {
        header: <div className="text-center">Contact Name</div>,
      }),
      columnHelper.accessor("contact_no", {
        header: <div className="text-center">Contact No</div>,
      }),
      // columnHelper.accessor("contact_email", {
      //   header: <div className="text-center">Contact Email</div>,
      // }),
  ]

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };
  const { vendorId, truckcompanyname } = location.state || {};

  const handleDeleteModalItem = (vendorId, truckCompanyName, row) => {
    setTruckNumber(truckCompanyName)
      setOpenDeleteModalId(vendorId);
  };

  
  useEffect(() => {
    const fetchVehiclesNames = async () => {
        setLoading(true)

      try {
        const response = await axios.get(
          `${baseURL}${btoa("invoice/allvehicles")}`
        );
        dispatch(addVendor(response.data.vehicles_data))
        // setVehiclesNames(response.data.vehicles_data);
      } catch (error) {
        console.log("Error fetching vehicles names", error);
      } finally{
        setLoading(false)
      }
    };
    fetchVehiclesNames();
  }, [baseURL]);

  const handleSortingChange = (columnId) => {
    setSorting((old) => {
      if (old.length && old[0].id === columnId) {
        // Toggle sorting direction
        return [{
          id: columnId,
          desc: !old[0].desc,
        }];
      }
      // Default sorting to descending initially
      return [{ id: columnId, desc: true }];
    });
  };
  
  const table = useReactTable({
    data: data || [],
    columns: columns,
    state: {
      rowSelection: rowSelection,
      globalFilter: filtering,
      sorting: sorting,
    },
    onSortingChange: setSorting,
    onGlobalFilterChange: setFiltering,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onRowSelectionChange: setRowSelection,
    onColumnFiltersChange: setColumnFilters,
    enableRowSelection: true,
    enableColumnFilter: true,
  });
  const currentPage = table.options.state.pagination.pageIndex;
  const expandedRowRef = useRef(null);
  const handleClickOutside = (event) => {
    if (expandedRowRef.current && !expandedRowRef.current.contains(event.target)) {
      setExpandedRow(null); // Close the expanded row
    }
  };
  
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
      const [activeTab, setActiveTab] = useState("View");

  const tabs = [
    { key: "View", label: "View", component: <VendorView vendorid={vendorId} handledelete = {()=>handleDeleteModalItem(vendorId, truckcompanyname)}   />}, // No separate component—InvoiceView itself is shown
    // { key: "Edit", label: "Edit", component: <ViewVendor vendorid={vendorId} /> },
    // { key: "Delete", label: "Delete", onClick: () => handleDeleteModalItem(vendorId, truckcompanyname) }, // Calls function on click

    // { key: "Cancel", label: "Cancel", navigationPath: "/vendors" },
  ];
  const menuItems = [
    { name: "View Carrier" }
  ];
  return (
    <div>
          {loading && <FullScreenLoader/> }
          <CustomCommonTab items={menuItems} />
          {activeTab === "View" && <div>{tabs.find((tab) => tab.key === "View")?.component}</div>}


          {activeTab === "Edit" && <div>{tabs.find((tab) => tab.key === "Edit")?.component}</div>}


      <div className="relative w-full rounded-tl-sm rounded-tr-sm mt-4 pl-8 pr-10">
      {/* <div className="absolute -top-5 -right-1 flex items-center space-x-2 z-10">
              <CustomNavigationButtons type="edit" />
              <CustomNavigationButtons type="delete" onClick={() => handleDeleteModalItem(vendorId, truckcompanyname)}  />
              <div 
                className="rounded-full bg-[#25caed] w-10 h-10 flex items-center justify-center hover:scale-110 shadow-md cursor-pointer"
                onClick={() => navigate("/")}
                style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
              >
                <FaHome className="text-white" size={20} />
              </div>
              <div 
                className="rounded-full bg-red-500 w-10 h-10 flex items-center justify-center hover:scale-110 shadow-md cursor-pointer"
                onClick={() => navigate("/UserDashBoard")}
                style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
              >
                <FaTimes className="text-white" size={20} />
              </div>
            </div> */}
            {openDeleteModalId && (
              <PopUpModalForDeleteVendor
                onClose={() => setOpenDeleteModalId(null)}
                vendorId={openDeleteModalId}
                truckCompanyName={TruckNumber}
                // resetRowSelection={table.resetRowSelection}
              />
            )}

      </div>
    </div>
  );
};

export default VendorScreen;

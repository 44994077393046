
import React, { useEffect, useMemo, useState, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Table from "../pages/Table";
import Search from "../Search/Search";
import { useDispatch, useSelector } from "react-redux";
import config from "../../config/config";
import { Tooltip } from "react-tippy";
import PopUpModalForDeleteVendor from "../pages/VendorDeleteModel";

const UsersDashBoard = () => {
  const dispatch = useDispatch();



  const baseURL =
    process.env.NODE_ENV === "production"
      ? config.production.baseURL
      : config.development.baseURL;

  const [selectedRows, setSelectedRows] = useState([]);
  const isEditActive = selectedRows.length === 1;
  const isDeleteActive = selectedRows.length > 0;
  const [searchValue, setSearchValue] = useState("");
  const resetRowSelection = useRef(null);
  const [openDeleteModalId, setOpenDeleteModalId] = useState(null);
  const [ownerDetails, setOwnerDetails] = useState([]);
  const navigate = useNavigate();
  
  const formatDate = (dateStr) => {
    const [year, month, day] = dateStr.split("-");
    return `${month}/${day}/${year}`;
  };
  const capitalizeFirstLetterWordWords = (str) => {
    return str
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };
  const lowercaseAllWords = (str) => {
    return str
      .toLowerCase()
  };

  // const data = useMemo(() => {
  //   return allVendors.map((vehicle, index) => ({
  //     ...vehicle,
  //     sl_no: index + 1,
  //     added_on: formatDate(vehicle.added_on),
  //     truck_company_name: capitalizeFirstLetterWordWords(vehicle.truck_company_name),
  //     contact_name : capitalizeFirstLetterWordWords(vehicle.contact_name),
  //     contact_email : lowercaseAllWords(vehicle.contact_email)
  //   }));
  // }, [allVendors]);

// console.log(data);

    //  @type import('@tanstack/react-table').ColumnDef<any> 

  const columns = [
    {
      header: "Business Name",
      accessorKey: "business_name",
    },
    {
      header: "Owner",
      accessorKey: "contact_name",
    },
    {
      header: "Email",
      accessorKey: "contact_email",
    },
    {
      header: "Phone",
      accessorKey: "contact_no",
    },
  ];

    useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${baseURL}/${btoa("owner/view")}`
        );
        console.log("owner fetched details is ", response);
      
        setOwnerDetails(response.data.owners_list)
      } catch (error) {
        console.error("Error fetching owner details", error);
      }
    };
    fetchData();
  }, []);

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };


  

  return (
    <div>
      <div className="flex justify-between items-center mt-10 mr-8">
      <h1 className="mx-8 font-bold mb-3 text-gray-400 text-lg">
          Customers Dashboard - {`${ownerDetails.length} Active Customers`}    
        </h1>

        <div>
            <button
             className="flex items-center justify-center font-bold mr-3 w-55 border-transparent border-2 text-white bg-[#25caed] hover:scale-105 transition-transform duration-300 ease-in-out focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-base px-4 py-1.5 focus:outline-none"
            type="button"
            onClick={() => navigate(`/createowner`)}
          >
             <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={3}
              stroke="currentColor"
              className="w-5 h-5 mr-3"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 6v12m6-6H6"
              />
            </svg>
            New Customer
          </button>

        </div>
      </div>

     
   
      <div className="rounded-tl-sm rounded-tr-sm mt-6 ml-9 mr-9 p-6">
   
        <div className="flex items-end justify-end gap-3">
            <span className="w-full">
              <Search
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                  handleSearchChange={handleSearchChange}
                />{" "}
            </span>
          {isEditActive && (
            <Tooltip title="Edit" position="top" trigger="mouseenter">
              <button
                className="items-center text-sm font-medium text-center focus:outline-none text-gray-500 hover:text-blue-700 rounded-lg"
                type="button"
                onClick={() => {
                  const id = selectedRows[0].user_id;
                  // alert(id)
                  navigate(`/editadminowner/${id}`);
                }}
              >
                <div className="hover:rounded-full rounded-full p-2 hover:bg-blue-200">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-5 h-5"
                  >
                    <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32l8.4-8.4z" />
                    <path d="M5.25 5.25a3 3 0 00-3 3v10.5a3 3 0 003 3h10.5a3 3 0 003-3V13.5a.75.75 0 00-1.5 0v5.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5V8.25a1.5 1.5 0 011.5-1.5h5.25a.75.75 0 000-1.5H5.25z" />
                  </svg>
                </div>
              </button>
            </Tooltip>
          )}

          {isDeleteActive && (
            <Tooltip title="Delete" position="top" trigger="mouseenter">
              <button
                className="items-center text-sm font-medium text-center focus:outline-none text-gray-500 hover:text-red-700 rounded-lg"
                type="button"
                // onClick={() => {
                //   const vendorId = selectedRows[0].id;
                //   const truckCompanyName = selectedRows[0].truck_company_name
                //   handleDeleteModalItem(vendorId ,truckCompanyName )
                //   // Add your delete logic here
                // }}
              >
                <div className="rounded-full p-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-5 h-5"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              </button>
            </Tooltip>
          )}

        </div>

        <Table
          tableData={ownerDetails}
          columns={columns}
          enableRowSelection={true}
          selectedRows={setSelectedRows}
          searchInput={searchValue}
          resetRowSelection={resetRowSelection}
        />

        {openDeleteModalId && (
          <PopUpModalForDeleteVendor
            onClose={() => setOpenDeleteModalId(null)}
            vendorId={openDeleteModalId}
            truckCompanyName={selectedRows[0].truck_company_name}
            resetRowSelection={() => resetRowSelection.current()}
          />
        )}

      </div>
    </div>
  );
};

export default UsersDashBoard;

import React, { useState } from "react";
import { FiSettings, FiShoppingCart, FiFileText, FiDollarSign, FiBarChart } from "react-icons/fi";
import Operations from "./Operations";
import Sales from "./Sales";
import Billing from "./Billing";
import Accounting from "./Accounting";
import Analytics from "./Analytics";
import {  MdOutlineBusinessCenter } from "react-icons/md";
import { FaBalanceScale } from 'react-icons/fa';
import Trending from './Trending'
import { TrendingUp } from "lucide-react";
import { useLocation } from "react-router-dom";


const menuItems = [
  { key: "operations", label: "Operations", icon: <MdOutlineBusinessCenter />, component: <Operations /> },
  { key: "sales", label: "Sales", icon: <FiShoppingCart />, component: <Sales /> },
  { key: "billing", label: "Billing", icon: <FiFileText />, component: <Billing /> },
  { key: "accounting", label: "Accounting", icon: <FaBalanceScale />, component: <Accounting /> },
  { key: "Trending", label: "Trending", icon: <TrendingUp />, component: <Trending /> },

  { key: "analytics", label: "Analytics", icon: <FiBarChart />, component: <Analytics /> },
];

const HomeReport = () => {
  // const [activeTab, setActiveTab] = useState(menuItems[0].key);
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(
    location.state?.activeTab || menuItems[0].key
  );
  return (
    <div className="flex flex-col">
      {/* Tab Menu */}
      <div className="flex mb-[60px] justify-center p-4 rounded-md font-bold text-md items-center w-full mx-auto pb-2 pt-0 custombgColor">
        {menuItems.map((tab) => (
          <div
            key={tab.key}
            // className={`flex items-center cursor-pointer px-6 py-2  ${
            //   activeTab === tab.key
            //     ? "border-b-2 border-green-500 text-green-600 font-semibold"
            //     : "text-gray-600"
            // } border-b border-gray-300`}

            className={`flex items-center cursor-pointer px-4 py-1 ${
              activeTab === tab.key ? "border-b-2 border-green-500 text-green-600" : "text-gray-600"
            } border-b border-gray-300  `}

            onClick={() => setActiveTab(tab.key)}
          >
            <span className="mr-2 text-lg">{tab.icon}</span>
            <span>{tab.label}</span>
          </div>
        ))}
      </div>

      {/* Dynamic Content */}
      <div className="flex-1 px-6">
        {menuItems.find((tab) => tab.key === activeTab)?.component}
      </div>
    </div>
  );
};

export default HomeReport;

// import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// // import { ChevronLeft, ChevronRight } from 'lucide-react';
// import {
//   FaChevronLeft,
//   FaChevronRight,
//   FaDownload,
//   FaEdit,
//   FaHome,
//   FaPen,
// } from "react-icons/fa";
// import { DatePicker, Space } from "antd";
// import TextField from "@mui/material/TextField"; // Make sure this import is at the top
// import axios from "axios";
// import config from "../../config/config";
// import dayjs from "dayjs";
// import { addCustomer } from "../../store/customerSlice";
// import { useSelector, useDispatch } from "react-redux";
// import { addToast } from "./ToastBox/ToastBoxMessageSlice";
// import CustomNavigation from "../reusable/CustomNavigationIcon";
// import { setNavigationState } from '../../components/Navigation/navigationSlice';

// const PriceUpdateForm = ({ sendDataToParent ,}) => {
//   // console.log(businessDate, 'businessDate')
//   const [newPrice, setNewPrice] = useState("");
//   const dispatch = useDispatch();
//   const businessDate = localStorage.getItem('business_date')
//   useEffect(() => {
//     setIsAllCustomers(true);
//   }, []);

//   const [isSubmitted, setIsSubmitted] = useState(false);
//   const [ispriceExists, setIspriceExists] = useState(false);
//   // const [editRow, setEditRow] = useState(null); // Track which row is being edited
//   const [selectedCustomer, setSelectedCustomer] = useState("");

//   const customersAll = useSelector((state) => state.customer.allCustomers);
//   const businessId = localStorage.getItem("business_id");

//   // Fetch customers and save to Redux store
//   useEffect(() => {
//     window.scrollTo(0, 0);
//     axios
//       .get(`${baseURL}Y3VzdG9tZXIvdmlldw==/${btoa(businessId)}`) //
//       .then((response) => {
//         const sortedCustomerData = response.data.customers_data.sort(
//           (a, b) => b.customer_id - a.customer_id
//         );
//         dispatch(addCustomer(sortedCustomerData));
//       })
//       .catch((error) => {
//         console.error("Error fetching customer data:", error);
//       });
//   }, []);
//   const [regularPrice, setRegularPrice] = useState("");
//   const today = dayjs(); // Get today's date

//   const [selectedDate, setSelectedDate] = useState(today); // Initialize as null

//   const [premiumPrice, setPremiumPrice] = useState("");

//   const [ultraPrice, setUltraPrice] = useState("");

//   const [dieselPrice, setDieselPrice] = useState("");
//   const [editRows, setEditRows] = useState([]); // Track multiple editable rows

//   const handleEditClick = (productId) => {
//     setEditRows((prevRows) => {
//       if (prevRows.includes(productId)) {
//         return prevRows.filter((id) => id !== productId); // Remove from edit mode
//       } else {
//         return [...prevRows, productId]; // Add to edit mode
//       }
//     });
//   };
//   const navigate = useNavigate();
//   const baseURL =
//     process.env.NODE_ENV === "production"
//       ? config.production.baseURL
//       : config.development.baseURL;
//   const handleCancel = () => {
//     navigate("/pricing");
//   };

//   const handleNotifyCustomers = () => {
//     const navigationData = {
//       fromPriceBook: true,
//     };
//     navigate("/HomeNotifications");
//   dispatch(setNavigationState(navigationData));
    
//   };

//   const handleNoNotification = () => {
//     navigate("/Pricing");
//   };

//   const formattedDate = selectedDate
//     ? selectedDate.toDate().toLocaleDateString("en-CA")
//     : null;

//   const [activeItem, setActiveItem] = useState("View");
//   const [fuelPrices, setFuelPrices] = useState([]);
//   const [updatedPrices, setUpdatedPrices] = useState({});
//   const [cardsData, setCardsData] = useState([]);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const requestData = {
//           businessId: localStorage.getItem("business_id"),
//           priceDate: selectedDate.format("YYYY-MM-DD"),
//           customerId:selectedCustomer ? selectedCustomer : 'allCustomers'
//         };

//         const response = await axios.post(
//           `${baseURL}${btoa("businessfuelgradeprices/oldfuelgradeprice")}`,
//           requestData,
//           { headers: { "Content-Type": "application/json" } }
//         );

//         setFuelPrices(response.data.oldPricesData);
//         setIspriceExists(response.data.priceExistStatus);
//         sendDataToParent(response.data.brandsfuelgradeslatestprices);

//         // Reset states on new data fetch
//         setUpdatedPrices({});
//         setIsSubmitted(false);
//         setEditRows([]);
//       } catch (error) {
//         console.error("Error fetching fuel prices:", error);
//       }
//     };

//     fetchData();
//   }, [selectedDate, selectedCustomer]);

//   const handleMenuClick = (item) => {
//     setActiveItem(item);
//   };

//   const handleSubmit = async () => {
//     setIsSubmitted(true);

//     // Collecting the product IDs and new unit prices from updatedPrices state
//     const fuelPricesData = fuelPrices.map((item) => {
//       return {
//         productId: item.product_id,
//         unitPrice: updatedPrices[item.product_name] || item.new_unit_price, // Use the entered price from updatedPrices, fallback to the current price if not entered
//       };
//     });

//     // Preparing the request data
//     const requestData = {
//       businessId: localStorage.getItem("business_id"),
//       priceDate: formattedDate,
//       loggedInUserId: localStorage.getItem("user_id"),
//       userRole: localStorage.getItem("user_role"),
//       productId: fuelPricesData.map((item) => item.productId),
//       productUnitPrice: fuelPricesData.map((item) => item.unitPrice),
//       buttonOne: isAllCustomers ? "allCustomers" : null,
//       buttonTwo: selectedCustomer,
//     };

//     try {
//       // Sending POST request to the API
//       const response = await axios.post(
//         `${baseURL}${btoa("businessfuelgradeprices/create")}`,
//         requestData,
//         {
//           headers: {
//             "Content-Type": "application/json",
//           },
//         }
//       );

//       // Handle the response
//       if (response.status === 200) {
//         const data = response.data;
//         console.log("API response:", data);
//         dispatch(
          
//           addToast({ type: "success", message: `${editRows.length > 0 && Object.keys(updatedPrices).length > 0 ? 'FuelGrade prices Updated Succesfully' : response.data.message}` })
//         );
//         // setIsSubmitted(true); // Update the submission state
//       } else {
//         console.error("Failed to submit:", response.statusText);
//       }
//     } catch (error) {
//       console.error("Error during API request:", error);
//     }
//   };

//   const handleDateChange = (date) => {
//     if (date) {
//       // setRunClicked(false)
//       setSelectedDate(date); // Only set the date when the user actually picks a valid date
//     }
//   };
//   // const handlePrevDay = () => {
//   //   setSelectedDate((prevDate) => prevDate.subtract(1, "day"));
//   // };
//   const handlePrevDay = () => {
//     setSelectedDate((prevDate) => {
//       // Convert businessDate to dayjs object if it's not already
//       const businessDateObj = typeof businessDate === 'string' 
//         ? dayjs(businessDate) 
//         : dayjs(businessDate);
        
//       // Check if going back one day would make the date earlier than businessDate
//       if (prevDate.subtract(1, 'day').isBefore(businessDateObj, 'day')) {
//         return businessDateObj; // Don't go earlier than businessDate
//       }
//       return prevDate.subtract(1, 'day'); // Otherwise subtract one day as normal
//     });
//   };
//   const disabledDate = (current) => {
//     // Convert businessDate to dayjs object if it's not already
//     const businessDateObj = typeof businessDate === 'string' 
//       ? dayjs(businessDate) 
//       : dayjs(businessDate);
    
//     // Disable dates before businessDate or after today
//     return (
//       current.isBefore(businessDateObj, 'day') || 
//       current.isAfter(today, 'day')
//     );
//   };
//   const handleNextDay = () => {
//     setSelectedDate((prevDate) => {
//       if (prevDate.isBefore(today, "day")) {
//         return prevDate.add(1, "day");
//       }
//       return prevDate; // Prevent exceeding today's date
//     });
//   };

//   const handlePriceChange = (productName, value) => {
//     setUpdatedPrices((prev) => ({
//       ...prev,
//       [productName]: value,
//     }));
//   };

//   const todayDate = dayjs().format("YYYY-MM-DD");
//   console.log(
//     selectedDate.format("YYYY-MM-DD"),
//     todayDate,
//     selectedDate,
//     ispriceExists,
//     "selectedDateselectedDate",
//     dayjs(selectedDate).format("YYYY-MM-DD") === todayDate,
//     typeof selectedDate,
//     typeof todayDate
//   );

//   const [isAllCustomers, setIsAllCustomers] = useState(false);
//   const handleAllCustomersClick = () => {
//     setIsAllCustomers(true);
//     setSelectedCustomer(""); // Clear dropdown selection
//   };

//   const handleDropdownChange = (e) => {
//     setSelectedCustomer(e.target.value);
//     setIsAllCustomers(false); // Unselect "All Customers" if dropdown is used
//   };

//   const handleClearSelection = () => {
//     setSelectedCustomer("");
//     setIsAllCustomers(false);
//   };
//   return (
//     <div
//       className="relative max-w-4xl mx-auto p-6 bg-white"
//       style={{
//         boxShadow: "0 0 20px 0 rgba(0, 0, 0,.2)",
//       }}
//     >
//        <div className="flex items-center justify-end ">
//           {/* <div
//             className={`absolute -top-4 flex items-center cursor-pointer rounded-full bg-[#25caed] w-10 h-10  px-2 py-2 mb-1 mr-4 ${
//               activeItem === "Home"
//                 ? "border-b-2 border-green-500 text-green-600"
//                 : "text-gray-600"
//             }  border-gray-300 mb-1 `}
//             onClick={() => {
//               handleMenuClick("Home");
//               navigate("/");
//             }}
//             style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
//           >
//             {" "}
//             <FaHome className="text-white ml-[2px] mb-1 mt-[2px]" size={20} />
//           </div> */}
//           {/* <CustomNavigation/> */}
//        {/* <CustomNavigation /> */}
//        <div className="flex items-center justify-end">
//       {/* Home button - bordered/unfilled by default, filled blue on hover */}
//       <div
//         className="absolute -top-5 flex items-center justify-center cursor-pointer rounded-full bg-[#cefafe] hover:bg-[#25caed] w-9 h-9 mb-0 mr-1 border-[0.5px] border-[#25caed] hover:border-[#25caed] hover:scale-110 shadow-md transition-all duration-200 group"
//         onClick={() => {
//           navigate("/");
//         }}
//         style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
//       >
//         <FaHome className="text-[#25caed] group-hover:text-white transition-colors duration-200" size={16}  />
//       </div>

//       {/* Close Icon - bordered/unfilled by default, filled red on hover */}
//       <div
//         className="absolute -top-5 -right-4 bg-red-200 hover:bg-red-500 w-9 h-9 flex items-center justify-center rounded-full cursor-pointer hover:bg-red-700 border-[0.5px] border-red-500 hover:border-red-700 hover:scale-110 shadow-md transition-all duration-200 group"
//         onClick={() => navigate('/pricing')}
//       >
//         <span className="text-red-500 group-hover:text-white text-xl font-bold mb-1.5 transition-colors duration-200">&times;</span>
//       </div>
//     </div>
//           {/* <div
//             className="absolute -top-4 -right-2 bg-red-500 w-10 h-10 flex items-center justify-center rounded-full cursor-pointer hover:bg-red-700"
//             onClick={() => navigate(-1)}
//           >
//             <span className="text-white text-2xl font-bold mb-1">&times;</span>
//           </div> */}
//         </div>

//       <div className="flex justify-evenly items-center bg-white ">
//         <div className="flex items-center justify-center">
//           <button className="flex items-center">
//             <FaChevronLeft className="mr-2" onClick={handlePrevDay} />
//           </button>
//           <div>
//             <Space direction="vertical" style={{ height: "100%" }}>
//               <DatePicker
//                 label="date"
//                 disabled={isSubmitted} // Add this line to disable the input after submission
//                 disabledDate={disabledDate}
//                 allowClear={false}
//                 // picker="month"
//                 format="MM-DD-YYYY"
//                 // format="MMMM-YYYY"
//                 views={["year", "month", "day"]}
//                 value={selectedDate}
//                 // onChange={handleDateChangeFrom}
//                 onChange={handleDateChange}
//                 className="w-[130px] h-[30px]"
//                 placeholder="Date"
//                 renderInput={(params) => (
//                   <TextField
//                     {...params}
//                     // value={formattedDatefrom ? formattedDatefrom.format("MMMM-YYYY") : ""}
//                     sx={{
//                       "& .MuiOutlinedInput-root": {
//                         height: "32px",
//                         display: "flex",
//                         alignItems: "center",
//                         textAlign:'center',
//                         fontSize: "1rem",
//                         // backgroundColor: formattedDatefrom ? "#d7f3f8" : "transparent",
//                         // border: `1px solid ${formattedDatefrom ? "#25caed" : "#ccc"}`,
//                         borderRadius: "6px",
//                         "&:hover": {
//                           // borderColor: formattedDatefrom ? "#25caed" : "#aaa",
//                         },
//                         "&.Mui-focused": {
//                           borderColor: "blue",
//                           boxShadow: "0 0 8px rgba(0, 0, 255, 0.5)",
//                         },
//                       },
//                     }}
//                   />
//                 )}
//               />
//             </Space>
//           </div>
//           <button className="flex items-center">
//             <FaChevronRight className="ml-2" onClick={handleNextDay} />
//           </button>
//         </div>
//         <button
//                     disabled={isSubmitted} // Add this line to disable the input after submission

//           className={`px-4 py-0.5 border rounded-lg ${
//             isAllCustomers
//               ? `bg-blue-500 border-blue-500 w-[170px] ${isSubmitted ? 'text-gray-600' : 'text-white'}`
//               : "hover:bg-gray-200 border-gray-500 text-black-500 w-[170px]"
//           } ${isSubmitted ? 'bg-gray-100 border-gray-500 text-black-500 w-[170px]' : 'w-[170px]'}`}
//           onClick={handleAllCustomersClick}
//         >
//           All Customers
//         </button>

//         <div className="flex flex-col mx-4">
//           {/* <label htmlFor="customer-select" className="font-medium mb-1 text-gray-700">
//           Select Customer
//         </label> */}
//           <select
//             id="customer-select"
//             className={`h-[30px] py-0.5 rounded-lg border-grey-500 w-[170px] ${isSubmitted ? 'bg-gray-100 text-gray-600' : ''}`}
//             value={selectedCustomer}
//             onChange={handleDropdownChange}
//             disabled={isSubmitted} // Add this line to disable the input after submission

//           >
//             <option value="">Select Customer</option>
//             {customersAll.map((customer) => (
//               <option key={customer.customer_id} value={customer.customer_id}>
//                 {customer.business_name}
//               </option>
//             ))}
//           </select>
//         </div>
//         {/* <button className="px-4 py-2 bg-gray-400 text-white" onClick={handleClearSelection}>
//           Clear Selection
//         </button> */}
//       </div>
//       <div className="overflow-hidden rounded-lg shadow-sm mt-8">
//       <table className="w-full border-separate border-spacing-0">
//   <thead className="bg-gray-200 rounded-b-lg mb-2">
//     <tr className="rounded-b-lg">
//       <th className="py-2 first:rounded-bl-lg last:rounded-br-lg">Fuel Grade</th>
//       <th className="py-2">Old Price</th>
//       <th className="py-2">Current Price</th>
//       {dayjs(selectedDate).format("YYYY-MM-DD") === todayDate &&
//         !ispriceExists && (
//           <th className="py-2 last:rounded-br-lg">New Price</th>
//         )}
//     </tr>
//   </thead>

//           <tbody className="">
//             {fuelPrices.map((item) => {
//               const isSubmittedForToday =
//                 dayjs(item.added_on).format("YYYY-MM-DD") === todayDate;
//               console.log(
//                 isSubmittedForToday,
//                 typeof item.added_on,
//                 typeof todayDate,
//                 "isSubmittedForToday"
//               );

//               return (
//                 <tr key={item.product_id}>
//                   <td className="py-2">{item.product_name}</td>
//                   <td className="py-2">
//                     $
//                     {item.old_unit_price
//                       ? parseFloat(item.old_unit_price).toFixed(2)
//                       : 0}
//                   </td>
//                   <td className="py-2 flex items-center justify-center">
//                     $
//                     {item.new_unit_price
//                       ? parseFloat(item.new_unit_price).toFixed(2)
//                       : "0.00"}
//                     {!ispriceExists &&
//                       dayjs(selectedDate).format("YYYY-MM-DD") === todayDate &&
//                       dayjs(item.added_on).format("YYYY-MM-DD") ===
//                         todayDate && (
//                         <FaPen
//                           className="ml-2 text-blue-500 cursor-pointer"
//                           onClick={() => handleEditClick(item.product_id)}
//                         />
//                       )}
//                   </td>
//                   {dayjs(selectedDate).format("YYYY-MM-DD") === todayDate &&
//                     !ispriceExists &&
//                     (dayjs(item.added_on).format("YYYY-MM-DD") !== todayDate ||
//                       editRows.includes(item.product_id)) && (
//                       <td className="py-2">
//                         <input
//                           type="number"
//                           value={updatedPrices[item.product_name] || ""}
//                           disabled={isSubmitted} // Add this line to disable the input after submission
                         
//                           onChange={(e) =>
//                             handlePriceChange(item.product_name, e.target.value)
//                           }
//                           className={`border p-2 rounded h-[30px] w-[130px] ${isSubmitted ? 'bg-gray-100' : ''}`}
//                         />
//                       </td>
//                     )}
//                 </tr>
//               );
//             })}
//           </tbody>
//         </table>
//       </div>
//       <div className="flex justify-end space-x-4 mt-6">
//         {dayjs(selectedDate).format("YYYY-MM-DD") === todayDate &&
//           !ispriceExists && (
//             <div className="flex justify-end space-x-4 mt-6">
//               {/* <button
//       onClick={Object.keys(updatedPrices).length > 0  ? handleSubmit : null}
//       disabled={isSubmitted || Object.values(updatedPrices).some(price => !price) || 
//         !Object.values(updatedPrices).every(price => price > 0)}
//       className={`px-4 py-2 rounded ${
//         isSubmitted || Object.values(updatedPrices).some(price => !price)
//           ? "bg-gray-300 text-black"
//           : "hover:bg-blue-500 hover:text-white text-blue-600 border border-blue-600"
//       }`}
//     >
//       {editRows.length > 0   ? "Save" : "Submit"}
//     </button> */}
//               {/* <button
//   onClick={Object.keys(updatedPrices).length > 0 ? handleSubmit : null}
//   disabled={
//     isSubmitted || 
//     (editRows.length === 0 && Object.keys(updatedPrices).length !== fuelPrices.length) ||  // "Submit" needs all fields
//     Object.values(updatedPrices).some(price => price === "" || price === undefined) || 
//     !Object.values(updatedPrices).every(price => parseFloat(price) > 0) // Ensure all values > 0
//   }
//   className={`px-4 py-2 rounded ${
//     isSubmitted || 
//     (editRows.length === 0 && Object.keys(updatedPrices).length !== fuelPrices.length) || 
//     Object.values(updatedPrices).some(price => price === "" || price === undefined) || 
//     !Object.values(updatedPrices).every(price => parseFloat(price) > 0)
//       ? "bg-gray-300 text-black cursor-not-allowed"
//       : "hover:bg-blue-500 hover:text-white text-blue-600 border border-blue-600"
//   }`}
// >
//   {editRows.length > 0 && Object.keys(updatedPrices).length > 0 ? "Save" : "Submit"}
// </button> */}
//               <button
//                 onClick={
//                   Object.keys(updatedPrices).length > 0 ? handleSubmit : null
//                 }
//                 disabled={
//                   isSubmitted ||
//                   fuelPrices.some(
//                     (item) =>
//                       (item.new_unit_price <= 0 &&
//                         !updatedPrices[item.product_name]) || // Ensure missing prices are entered
//                       (updatedPrices[item.product_name] &&
//                         parseFloat(updatedPrices[item.product_name]) <= 0) // Ensure entered values are > 0
//                   ) ||
//                   fuelPrices.every(
//                     (item) =>
//                       !updatedPrices[item.product_name] ||
//                       parseFloat(updatedPrices[item.product_name]) <= 0 // Ensure entered values are > 0 or not empty
//                   )
//                 }
//                 className={`px-3 py-1 rounded ${
//                   isSubmitted ||
//                   fuelPrices.some(
//                     (item) =>
//                       (item.new_unit_price <= 0 &&
//                         !updatedPrices[item.product_name]) ||
//                       (updatedPrices[item.product_name] &&
//                         parseFloat(updatedPrices[item.product_name]) <= 0)
//                   ) ||
//                   fuelPrices.every(
//                     (item) =>
//                       !updatedPrices[item.product_name] ||
//                       parseFloat(updatedPrices[item.product_name]) <= 0 // Ensure entered values are > 0 or not empty
//                   )
//                     ? "bg-gray-300 text-black cursor-not-allowed"
//                     : "hover:bg-blue-500 hover:text-white text-blue-600 border border-blue-600"
//                 }`}
//               >
//                 {editRows.length > 0 && Object.keys(updatedPrices).length > 0
//                   ? "Save"
//                   : "Submit"}
//               </button>

//               {/* <button
//                 onClick={handleCancel}
//                 className="px-3 py-1 rounded border border-gray-600 text-black hover:bg-gray-600 hover:text-white"
//               >
//                 Cancel
//               </button> */}

//               <button
//                 onClick={handleNotifyCustomers}
//                 disabled={!isSubmitted}
//                 className={`px-3 py-1 rounded ${
//                   isSubmitted
//                     ? "hover:bg-green-500 hover:text-white text-green-600 border border-green-600"
//                     : "bg-gray-300 text-black"
//                 }`}
//               >
//                 Notify Customers
//               </button>

//               <button
//                 onClick={handleNoNotification}
//                 disabled={!isSubmitted}
//                 className={`px-3 py-1 rounded ${
//                   isSubmitted
//                     ? "hover:bg-yellow-500 hover:text-white text-yellow-600 border border-yellow-500"
//                     : "bg-gray-300 text-black"
//                 }`}
//               >
//                 No Notification
//               </button>
//             </div>
//           )}
//       </div>
//     </div>
//   );
// };

// export default PriceUpdateForm;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  FaChevronLeft,
  FaChevronRight,
  FaDownload,
  FaEdit,
  FaHome,
  FaPen,
} from "react-icons/fa";
import { DatePicker, Space } from "antd";
import TextField from "@mui/material/TextField";
import axios from "axios";
import config from "../../config/config";
import dayjs from "dayjs";
import { addCustomer } from "../../store/customerSlice";
import { useSelector, useDispatch } from "react-redux";
import { addToast } from "./ToastBox/ToastBoxMessageSlice";
import CustomNavigation from "../reusable/CustomNavigationIcon";
import { setNavigationState } from '../../components/Navigation/navigationSlice';

const PriceUpdateForm = ({ sendDataToParent ,}) => {
  const [newPrice, setNewPrice] = useState("");
  const dispatch = useDispatch();
  const businessDate = localStorage.getItem('business_date')
  useEffect(() => {
    setIsAllCustomers(true);
  }, []);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [ispriceExists, setIspriceExists] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState("");

  const customersAll = useSelector((state) => state.customer.allCustomers);
  const businessId = localStorage.getItem("business_id");

  // Fetch customers and save to Redux store
  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get(`${baseURL}Y3VzdG9tZXIvdmlldw==/${btoa(businessId)}`)
      .then((response) => {
        const sortedCustomerData = response.data.customers_data.sort(
          (a, b) => b.customer_id - a.customer_id
        );
        dispatch(addCustomer(sortedCustomerData));
      })
      .catch((error) => {
        console.error("Error fetching customer data:", error);
      });
  }, []);
  const [regularPrice, setRegularPrice] = useState("");
  const today = dayjs(); // Get today's date

  const [selectedDate, setSelectedDate] = useState(today); // Initialize as null

  const [premiumPrice, setPremiumPrice] = useState("");
  const [ultraPrice, setUltraPrice] = useState("");
  const [dieselPrice, setDieselPrice] = useState("");
  
  const navigate = useNavigate();
  const baseURL =
    process.env.NODE_ENV === "production"
      ? config.production.baseURL
      : config.development.baseURL;
  const handleCancel = () => {
    navigate("/pricing");
  };
useEffect(()=> {
  const navigationData = {
    fromPriceBook: true, 
  };
  dispatch(setNavigationState(navigationData));
},[])
  const handleNotifyCustomers = () => {
    const navigationData = {
      fromPriceBook: true,
    };
    localStorage.setItem('navigationState', JSON.stringify(navigationData));

    navigate("/HomeNotifications");
    dispatch(setNavigationState(navigationData));
  };

  const handleNoNotification = () => {
    navigate("/Pricing");
  };

  const formattedDate = selectedDate
    ? selectedDate.toDate().toLocaleDateString("en-CA")
    : null;

  const [activeItem, setActiveItem] = useState("View");
  const [fuelPrices, setFuelPrices] = useState([]);
  const [updatedPrices, setUpdatedPrices] = useState({});
  const [cardsData, setCardsData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const requestData = {
          businessId: localStorage.getItem("business_id"),
          priceDate: selectedDate.format("YYYY-MM-DD"),
          customerId: selectedCustomer ? selectedCustomer : 'allCustomers'
        };

        const response = await axios.post(
          `${baseURL}${btoa("businessfuelgradeprices/oldfuelgradeprice")}`,
          requestData,
          { headers: { "Content-Type": "application/json" } }
        );

        setFuelPrices(response.data.oldPricesData);
        setIspriceExists(response.data.priceExistStatus);
        sendDataToParent(response.data.brandsfuelgradeslatestprices);

        // Reset states on new data fetch
        setUpdatedPrices({});
        setIsSubmitted(false);
      } catch (error) {
        console.error("Error fetching fuel prices:", error);
      }
    };

    fetchData();
  }, [selectedDate, selectedCustomer]);

  const handleMenuClick = (item) => {
    setActiveItem(item);
  };

  const handleSubmit = async () => {
    setIsSubmitted(true);

    // Collecting the product IDs and new unit prices from updatedPrices state
    const fuelPricesData = fuelPrices.map((item) => {
      return {
        productId: item.product_id,
        unitPrice: updatedPrices[item.product_name] || item.new_unit_price, // Use the entered price from updatedPrices, fallback to the current price if not entered
      };
    });

    // Preparing the request data
    const requestData = {
      businessId: localStorage.getItem("business_id"),
      priceDate: formattedDate,
      loggedInUserId: localStorage.getItem("user_id"),
      userRole: localStorage.getItem("user_role"),
      productId: fuelPricesData.map((item) => item.productId),
      productUnitPrice: fuelPricesData.map((item) => item.unitPrice),
      buttonOne: isAllCustomers ? "allCustomers" : null,
      buttonTwo: selectedCustomer,
    };

    try {
      // Sending POST request to the API
      const response = await axios.post(
        `${baseURL}${btoa("businessfuelgradeprices/create")}`,
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // Handle the response
      if (response.status === 200) {
        const data = response.data;
        console.log("API response:", data);
        dispatch(
          addToast({ 
            type: "success", 
            message: `${(isUpdatingExistingPrices) ? "FuelGrade prices Updated successfully."
 : response.data.message}` 
          })
        );
      } else {
        console.error("Failed to submit:", response.statusText);
      }
    } catch (error) {
      console.error("Error during API request:", error);
    }
  };

  const handleDateChange = (date) => {
    if (date) {
      setSelectedDate(date); // Only set the date when the user actually picks a valid date
    }
  };
  
  const handlePrevDay = () => {
    setSelectedDate((prevDate) => {
      // Convert businessDate to dayjs object if it's not already
      const businessDateObj = typeof businessDate === 'string' 
        ? dayjs(businessDate) 
        : dayjs(businessDate);
        
      // Check if going back one day would make the date earlier than businessDate
      if (prevDate.subtract(1, 'day').isBefore(businessDateObj, 'day')) {
        return businessDateObj; // Don't go earlier than businessDate
      }
      return prevDate.subtract(1, 'day'); // Otherwise subtract one day as normal
    });
  };
  
  const disabledDate = (current) => {
    // Convert businessDate to dayjs object if it's not already
    const businessDateObj = typeof businessDate === 'string' 
      ? dayjs(businessDate) 
      : dayjs(businessDate);
    
    // Disable dates before businessDate or after today
    return (
      current.isBefore(businessDateObj, 'day') || 
      current.isAfter(today, 'day')
    );
  };
  
  const handleNextDay = () => {
    setSelectedDate((prevDate) => {
      if (prevDate.isBefore(today, "day")) {
        return prevDate.add(1, "day");
      }
      return prevDate; // Prevent exceeding today's date
    });
  };

  const handlePriceChange = (productName, value) => {
    setUpdatedPrices((prev) => ({
      ...prev,
      [productName]: value,
    }));
  };

  const todayDate = dayjs().format("YYYY-MM-DD");
  
  const [isAllCustomers, setIsAllCustomers] = useState(false);
  const handleAllCustomersClick = () => {
    setIsAllCustomers(true);
    setSelectedCustomer(""); // Clear dropdown selection
  };

  const handleDropdownChange = (e) => {
    setSelectedCustomer(e.target.value);
    setIsAllCustomers(false); // Unselect "All Customers" if dropdown is used
  };

  const handleClearSelection = () => {
    setSelectedCustomer("");
    setIsAllCustomers(false);
  };
  
  // Check if today's date is selected and prices can be modified
  const canModifyPrices = dayjs(selectedDate).format("YYYY-MM-DD") === todayDate && !ispriceExists;
  
  // Check if this is an update to existing prices (second time for the day)
  // If prices already exist for today (some already have a new_unit_price with today's date)
  const isUpdatingExistingPrices = fuelPrices.some(item => 
    dayjs(item.added_on).format("YYYY-MM-DD") === todayDate && 
    item.new_unit_price && 
    parseFloat(item.new_unit_price) > 0
  );
  
  return (
    <div
      className="relative max-w-4xl mx-auto p-6 bg-white"
      style={{
        boxShadow: "0 0 20px 0 rgba(0, 0, 0,.2)",
      }}
    >
      <div className="flex items-center justify-end">
        <div className="flex items-center justify-end">
          <div
            className="absolute -top-5 flex items-center justify-center cursor-pointer rounded-full bg-[#cefafe] hover:bg-[#25caed] w-9 h-9 mb-0 mr-1 border-[0.5px] border-[#25caed] hover:border-[#25caed] hover:scale-110 shadow-md transition-all duration-200 group"
            onClick={() => {
              navigate("/");
            }}
            style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
          >
            <FaHome className="text-[#25caed] group-hover:text-white transition-colors duration-200" size={16}  />
          </div>

          <div
            className="absolute -top-5 -right-4 bg-red-200 hover:bg-red-500 w-9 h-9 flex items-center justify-center rounded-full cursor-pointer hover:bg-red-700 border-[0.5px] border-red-500 hover:border-red-700 hover:scale-110 shadow-md transition-all duration-200 group"
            onClick={() => navigate('/pricing')}
          >
            <span className="text-red-500 group-hover:text-white text-xl font-bold mb-1.5 transition-colors duration-200">&times;</span>
          </div>
        </div>
      </div>

      <div className="flex justify-evenly items-center bg-white ">
        <div className="flex items-center justify-center">
          <button className="flex items-center">
            <FaChevronLeft className="mr-2" onClick={handlePrevDay} />
          </button>
          <div>
            <Space direction="vertical" style={{ height: "100%" }}>
              <DatePicker
                label="date"
                disabled={isSubmitted}
                disabledDate={disabledDate}
                allowClear={false}
                format="MM-DD-YYYY"
                views={["year", "month", "day"]}
                value={selectedDate}
                onChange={handleDateChange}
                className="w-[130px] h-[30px]"
                placeholder="Date"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        height: "32px",
                        display: "flex",
                        alignItems: "center",
                        textAlign:'center',
                        fontSize: "1rem",
                        borderRadius: "6px",
                        "&.Mui-focused": {
                          borderColor: "blue",
                          boxShadow: "0 0 8px rgba(0, 0, 255, 0.5)",
                        },
                      },
                    }}
                  />
                )}
              />
            </Space>
          </div>
          <button className="flex items-center">
            <FaChevronRight className="ml-2" onClick={handleNextDay} />
          </button>
        </div>
        <button
          disabled={isSubmitted}
          className={`px-4 py-0.5 border rounded-lg ${
            isAllCustomers
              ? `bg-blue-500 border-blue-500 w-[170px] ${isSubmitted ? 'text-gray-600' : 'text-white'}`
              : "hover:bg-gray-200 border-gray-500 text-black-500 w-[170px]"
          } ${isSubmitted ? 'bg-gray-100 border-gray-500 text-black-500 w-[170px]' : 'w-[170px]'}`}
          onClick={handleAllCustomersClick}
        >
          All Customers
        </button>

        <div className="flex flex-col mx-4">
          <select
            id="customer-select"
            className={`h-[30px] py-0.5 rounded-lg border-grey-500 w-[170px] ${isSubmitted ? 'bg-gray-100 text-gray-600' : ''}`}
            value={selectedCustomer}
            onChange={handleDropdownChange}
            disabled={isSubmitted}
          >
            <option value="">Select Customer</option>
            {customersAll.map((customer) => (
              <option key={customer.customer_id} value={customer.customer_id}>
                {customer.business_name}
              </option>
            ))}
          </select>
        </div>
      </div>
      
      <div className="overflow-hidden rounded-lg shadow-sm mt-8">
        <table className="w-full border-separate border-spacing-0">
          <thead className="bg-gray-200 rounded-b-lg mb-2">
            <tr className="rounded-b-lg">
              <th className="py-2 first:rounded-bl-lg">Fuel Grade</th>
              <th className="py-2">Old Price</th>
              <th className="py-2">Current Price</th>
              {/* Always show New Price column when today's date is selected and prices can be modified */}
              {canModifyPrices && (
                <th className="py-2 last:rounded-br-lg">New Price</th>
              )}
            </tr>
          </thead>

          <tbody className="">
            {fuelPrices.map((item) => {
              return (
                <tr key={item.product_id}>
                  <td className="py-2">{item.product_name}</td>
                  <td className="py-2">
                  ${" "}
                    {item.old_unit_price
                      ? parseFloat(item.old_unit_price).toFixed(2)
                      : "0.00"}
                  </td>
                  <td className="py-2 text-center">
                    ${" "}
                    {item.new_unit_price
                      ? parseFloat(item.new_unit_price).toFixed(2)
                      : "0.00"}
                  </td>
                  {/* Always show input field when today's date is selected and prices can be modified */}
                  {canModifyPrices && (
                    <td className="py-2">
                      <input
                        type="number"
                        value={updatedPrices[item.product_name] || ""}
                        disabled={isSubmitted}
                        onChange={(e) =>
                          handlePriceChange(item.product_name, e.target.value)
                        }
                        className={`border p-2 rounded h-[30px] w-[130px] ${isSubmitted ? 'bg-gray-100' : ''}`}
                      />
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      
      <div className="flex justify-end space-x-4 mt-6">
        {canModifyPrices && (
          <div className="flex justify-end space-x-4 mt-6">
            <button
              onClick={handleSubmit}
              disabled={
                isSubmitted ||
                fuelPrices.some(
                  (item) =>
                    (item.new_unit_price <= 0 &&
                      !updatedPrices[item.product_name]) ||
                    (updatedPrices[item.product_name] &&
                      parseFloat(updatedPrices[item.product_name]) <= 0)
                ) ||
                fuelPrices.every(
                  (item) =>
                    !updatedPrices[item.product_name] ||
                    parseFloat(updatedPrices[item.product_name]) <= 0
                )
              }
              className={`px-3 py-1 rounded ${
                isSubmitted ||
                fuelPrices.some(
                  (item) =>
                    (item.new_unit_price <= 0 &&
                      !updatedPrices[item.product_name]) ||
                    (updatedPrices[item.product_name] &&
                      parseFloat(updatedPrices[item.product_name]) <= 0)
                ) ||
                fuelPrices.every(
                  (item) =>
                    !updatedPrices[item.product_name] ||
                    parseFloat(updatedPrices[item.product_name]) <= 0
                )
                  ? "bg-gray-300 text-black cursor-not-allowed"
                  : "hover:bg-blue-500 hover:text-white text-blue-600 border border-blue-600"
              }`}
            >
              {/* Use "Save" if updating existing prices for today, otherwise "Submit" */}
              {isUpdatingExistingPrices ? "Save" : "Submit"}
            </button>

            <button
              onClick={handleNotifyCustomers}
              disabled={!isSubmitted}
              className={`px-3 py-1 rounded ${
                isSubmitted
                  ? "hover:bg-green-500 hover:text-white text-green-600 border border-green-600"
                  : "bg-gray-300 text-black"
              }`}
            >
              Notify Customers
            </button>

            <button
              onClick={handleNoNotification}
              disabled={!isSubmitted}
              className={`px-3 py-1 rounded ${
                isSubmitted
                  ? "hover:bg-yellow-500 hover:text-white text-yellow-600 border border-yellow-500"
                  : "bg-gray-300 text-black"
              }`}
            >
              No Notification
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PriceUpdateForm;